import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';
import MiniGraph from '../../components/workout/miniGraph';
import ScrollIntoView from '../../components/util/scrollIntoView';

import mu from '../../lib/musher-util';

@observer
class WorkoutTypeHeader extends Component {
    async loadWorkoutSummary({ props = this.props, type }) {
		const { userStore, workoutStore } = props.stores;

		const currentDay = new Date().getDate();
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear } = props;

		const prevMonthDate = new Date(currentYear, currentMonth - 2, 1);
		const prevMonth = prevMonthDate.getMonth() + 1;
        const prevYear = prevMonthDate.getFullYear();

		// const { currentTeam } = workoutStore;
		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const workoutType = type === 'all' ? null : type;

        await userStore.getWorkoutSummary({
			team: currentTeam.id,
			year,
			month,
			type: workoutType,
			daysBack: 365,
		});
		// await userStore.getWorkoutSummaryDay({
		// 	year: currentYear,
		// 	month: currentMonth,
		// 	day: currentDay,
		// 	team: currentTeam.id,
		// 	keyname: 'workoutSummaryDaysCurrentMonth',
        //     type: workoutType,
		// });
		// await userStore.getWorkoutSummaryDay({
		// 	year: prevYear,
		// 	month: prevMonth,
		// 	team: currentTeam.id,
		// 	keyname: 'workoutSummaryDaysCurrentMonthPrevMonth',
        //     type: workoutType,
		// });
		// await userStore.getWorkoutSummaryDay({
		// 	year: currentYear - 1,
		// 	month: currentMonth,
		// 	team: currentTeam.id,
		// 	keyname: 'workoutSummaryDaysCurrentMonthPrevSeason',
        //     type: workoutType,
		// });
		await userStore.getWorkoutSummaryDay({
			daysBack: 500,
			team: currentTeam.id,
            type: workoutType,
		});
    }

    chooseWorkoutType = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
		const { type } = e.target.closest('button').dataset;
        appState.setWorkoutType(type);
        this.loadWorkoutSummary({ props: this.props, type: parseInt(type, 10) });
    }

    chooseTeam = () => {
		const { appState } = this.props.stores;
        appState.openDrawer3('chooseTeam', {});
	}

    render() {
        const { userStore, appState } = this.props.stores;
        const { workoutType = 'all' } = appState;

        const { user } = userStore;
        const { language = 'en' } = user;

        const {
            workoutDistinctTypes,
		} = userStore;

        return (<>
            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                <div class='w-100 overflow-hidden'>
                    {workoutDistinctTypes && workoutDistinctTypes.length > 0 && <>
                        <div
                            class='d-flex flex-row flex-nowrap pb-2 px-1'
                            style='overflow: auto; scroll-snap-type: x mandatory;'
                        >
                            <div class='col px-0 pt-2'>
                                <button
                                    type='button'
                                    class={`btn btn-block ${workoutDistinctTypes.length === 0 ? 'rounded-pill-both' : 'rounded-pill-left'} rounded-none ${workoutType === 'all' ? 'btn-info' : 'btn-outline-info'} text-nowrap`}
                                    style='text-overflow: ellipsis; overflow: hidden;'
                                    onClick={this.chooseWorkoutType}
                                    data-type={'all'}
                                ><i class={``} /> <Text id='workout.all'>All</Text></button>
                            </div>
                            {workoutDistinctTypes && workoutDistinctTypes.map((type, idx) => {
                                const isFirst = idx === 0;
                                const isLast = idx === workoutDistinctTypes.length - 1;
                                const woType = userStore.findWorkoutType({ type, language });
                                const woTypeIcon = userStore.findWorkoutType({ type, language, field: 'icon' });
                                return (<>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block ${isLast && 'rounded-pill-right'} rounded-none ${workoutType == type ? 'btn-info' : 'btn-outline-info'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            onClick={this.chooseWorkoutType}
                                            data-type={type}
                                        ><i class={woTypeIcon} /> {woType}</button>
                                    </div>
                                </>);
                            })}
                        </div>
                    </>}
                </div>

                {!workoutDistinctTypes || workoutDistinctTypes?.length === 0 && <div class='col-12 mb-1 text-center pt-1 pb-1'>
                    <h1 class='text-muted'><i class='fas fa-running' /></h1>
                    <h5 class='text-muted font-weight-lighter'>
                        <Text id='workouts.no-activities-found'>No activities found for this team...</Text>
                    </h5>
                    <button
                        type='button'
                        onClick={this.chooseTeam}
                        class='btn btn-primary mt-3'
                    >
                        <Text id='workouts.choose-team'>Choose team</Text>
                    </button>
                </div>}
            </div>
        </>);
    }
}

export default WorkoutTypeHeader;
