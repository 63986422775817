import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import { toJS } from 'mobx';
import Markdown from 'preact-markdown';

import PubSub, { topics } from '../../lib/pubsub';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

const MAX_LOADINDEX = mu.getMaxLoadIndex();
const RELOAD_INTERVAL_IN_SEC = 60;

import Map from '../../components/map/mapbox';
import ImageSlider from '../../components/imageSlider';
import ImageScroller from '../../components/imageScroller';
import Dog from '../../components/dog';
import Musher from '../../components/user/musher';
import DogSimple from '../../components/dog/simple';
import WorkoutInfoGraph from '../../components/workout/infoGraph';
import WorkoutComments from '../../components/workout/comments';
import WorkoutAddComment from '../../components/workout/commentAdd';
import Gauge from '../../components/gauge';
import Ad from '../../components/ad/';
import Images from '../../components/form/images';

import Overlay from '../../components/overlay/';
import TeamsPublicDetail from '../../routes/teams/publicDetail';
import DogPublicDetail from '../../routes/dogs/publicDetail';

import LikesAndComments from '../../components/feedback/likesAndComments';
import Views from '../../components/feedback/views';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

import States from '../../components/states';

import ScrollIntoView from '../../components/util/scrollIntoView';
import Field from '../../components/util/field';

function getWorkoutColor(workout) {
	switch (workout.type) {
		case 1:
			return 'border-primary';
		case 2:
			return 'border-danger';
		case 3:
			return 'border-success';
		case 4:
			return 'border-info';
		default:
			return 'border-warning';
	}
}

function getWorkoutIcon(workout) {
	switch (workout.type) {
		case 1:
			return 'fas fa-running';
		case 2:
			return 'fas fa-flag-checkered';
		case 3:
			return 'fas fa-hiking';
		case 4:
			return 'fas fa-futbol';
		default:
			return 'fas fa-hiking';
	}
}

function getStateColor(state) {
	switch (state) {
		case 'running':
			return 'primary';
		case 'rest':
			return 'warning';
		case 'restTrail':
			return 'danger';
	}
}

function getStateIcon(state) {
	switch (state) {
		case 'running':
			return 'fas fa-running';
		case 'rest':
			return 'fas fa-male';
		case 'restTrail':
			return 'fas fa-bed';
	}
}

function displayDistance(distanceKm) {
	if (distanceKm < 5) {
		return util.format(distanceKm, 2);
	}
	if (distanceKm < 10) {
		return util.format(distanceKm, 1);
	}
	return util.format(distanceKm, 1);
}

const initialState = {
    newDog: {},
	submenu: 'totals',
	showAddComment: false,
	mapHeight: '400px',
	mapMaxHeight: '700px',
	reloadInSec: null,
	reloadLastEpoch: null,
	reloadIsActive: false,
};

@observer
class Input extends Component {
    onInputDuration = (e) => {
        const { field, updateField, workout } = this.props;
        const value = parseInt(e.target.value, 10);
        const name = e.target.name;
        let hh = Math.floor(workout[field] / 3600) || 0;
        let mi = Math.floor(workout[field] / 3600) || 0;
        if (name.match(/-hours/)) {
            hh = value;
        }
        if (name.match(/-minutes/)) {
            mi = value;
        }
        const combinedValue = (hh * 3600) + (mi * 60);
        updateField({ id: workout.id, field, value: combinedValue });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { workoutStore } = this.props.stores;
        const { workout } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        workoutStore.removeImage({
            id: workout.id,
            name: imageName,
        });
    }

    removeMainImage = () => {
        const { workout, field, updateField } = this.props;
        updateField({ id: workout.id, field, value: {} });
    }

    setDateToNow = () => {
        const { field, updateField, workout } = this.props;
        const value = util.isoDate(undefined, false, false, true);
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        updateField({ id: workout.id, field, value });
    }

    setTimeToNow = () => {
        const { field, updateField, workout } = this.props;
        const value = util.isoTime();
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        updateField({ id: workout.id, field, value });
    }

    setValue = (e) => {
        const value = e.target.parentElement.dataset.value || e.target.dataset.value;
        if (value) {
            const { field, updateField, workout } = this.props;
            // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
            updateField({ id: workout.id, field, value });
        }
    }

    setRightValue = () => {
        const { field, updateField, workout, rightValueReal } = this.props;
        updateField({ id: workout.id, field, value: rightValueReal });
    }

    render() {
        const { saved, workout, type, datalist = [], min, max, step, elementtype, field, title, icon, help, onInput, updateField, rightValue } = this.props;
        let value = toJS(workout[field]);
        let maxValue = max;
        if (type === 'date') {
            if (value) {
                value = util.isoDate(workout[field], false, false, true);
            }
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        // if (type === 'time') {
        //     if (value) {
        //         value = util.isoTime(workout[field]);
        //     }
        // }
        let displayValue;
        let insertValue;
        if (field === 'duration' || field === 'rest') {
            displayValue = util.secToHms(value, value > 3600);
        }
        if (field === 'weather' && util.checkNested(workout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(workout.yr[0].instant.details);
            const yrDate = util.isoDate(workout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(workout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${mu.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
                insertValue = displayValue;
            }
        }
        if (field === 'temperature' && util.checkNested(workout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(workout.yr[0].instant.details);
            const yrDate = util.isoDate(workout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(workout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C`;
                insertValue = util.asNumber(yr.air_temperature);
            }
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Files saved={saved} object={workout} field={field} updateField={updateField} />
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'image') {
            return (
                <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='container-fluid'>
                        <div class='row'>
                            {workout.image && workout.image.s3SmallLink && <div class='col-3 p-1'>
                                <img loading='lazy' src={workout.image.s3SmallLink} class='img-fluid border rounded-lg' onError={this.handleImageErrored} />
                                <button class='btn btn-sm btn-link'
                                    onClick={this.removeMainImage}
                                    data-name={workout.image.name}
                                ><i class='fas fa-trash' /> Del</button>
                                <span class='badge badge-success'>Main</span>
                            </div>}
                            {workout.images && workout.images.map(img => (
                                <div class='col-3 p-1'>
                                    <img loading='lazy' src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                    <button class='btn btn-sm btn-link'
                                        onClick={this.removeImage}
                                        data-name={img.name}
                                    ><i class='fas fa-trash' /> Del</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Images saved={saved} object={workout} field={field} updateField={updateField} autoOpen={false} stores={this.props.stores} />
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'>Endringene er lagret</div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={workout.id}
                        data-field={field}
                        onInput={onInput}
                    />
                    <datalist id={`list-${field}`} >
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'duration') {
            const hh = Math.floor(workout[field] / 3600);
            const mi = Math.floor(workout[field] % 3600 / 60);
            // const ss = Math.floor(newWorkout[field] % 3600 % 60);

            return (
                <div class={`form-group mb-5 pb-1 border-bottom`}>
                    <span class='float-right'>
                        <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                    </span>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='form-row'>
                        <div class='form-group col-3 offset-3'>
                            <input class={`form-control text-right`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-hours`}
                                value={hh}
                                type='number'
                                min='0'
                                max='24'
                            />
                        </div>
                        <div class='form-group col-3'>
                            <input class={`form-control`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-minutes`}
                                value={mi}
                                type='number'
                                min='0'
                                max='59'
                            />
                        </div>
                    </div>
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            );
        }
        if (type === 'toggle') {
            return (
                <div class={`form-group ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='custom-control custom-switch'>
                        <input type='checkbox'
                            class='custom-control-input'
                            id={`${field}Input`}
                            value={value === 1 ? 0 : 1}
                            data-id={workout.id}
                            data-field={field}
                            onInput={onInput}
                            checked={value == 1 ? 'checked' : ''}
                        />
                        <label class='custom-control-label' for={`${field}Input`} />
                    </div>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }

        return (
            <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                {type === 'date' && <span class='float-right text-muted font-weight-lighter' onClick={this.setDateToNow}>I dag <i class='far fa-check-circle' /></span>}
                {type === 'time' && <span class='float-right text-muted font-weight-lighter' onClick={this.setTimeToNow}>Nå <i class='far fa-check-circle' /></span>}
                {rightValue && <span class='float-right text-muted font-weight-lighter' onClick={this.setRightValue}>{rightValue}</span>}

                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                {displayValue && <span class='float-right'>
                    <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                </span>}
                <input type={type} class={`form-control ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                    min={min}
                    max={maxValue}
                    step={step}
                    value={value}
                    data-id={workout.id}
                    data-field={field}
                    data-type={type}
                    data-elementtype={elementtype}
                    onInput={onInput}
                />
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class AdminInfo extends Component {
    render() {
		const { workoutStore } = this.props.stores;
		const { workout = {} } = workoutStore;

        const firstPoint = mu.getFirstPoint(workout);
        const midPoint = mu.getMidPoint(workout, 0.5);
        const endPoint = mu.getMidPoint(workout, 1);

		const { tracks = [] } = workout;
		const firstTrackPoint = tracks[0];
		const lastTrackPoint = tracks[tracks.length - 1];

		let duration;
		if (firstTrackPoint && lastTrackPoint) {
			const startDate = new Date(firstTrackPoint.timestamp);
			const startTime = startDate.getTime() / 1000;
			const endDate = new Date(lastTrackPoint.timestamp);
			const endTime = endDate.getTime() / 1000;
			duration = endTime - startTime;
		}

        return (
            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                <div class='alert alert-warning'>
                    <h5>Admin info</h5>
					<xmp>
                        firstPoint: {firstPoint.join(', ')}<br />
                        midPoint: {midPoint.join(', ')}<br />
                        endPoint: {endPoint.join(', ')}<br />
						<br />
						duration: {util.secToHms(workout.duration)}<br />
						<br />
						<strong>Tracking info</strong><br />
						duration: {util.secToHms(duration)}<br />
						<br />
					</xmp>
					<div class='d-flex align-content-start flex-wrap'>
						{tracks && tracks.map(t => <span class='ml-1'>
							{t.coords.accuracy}
						</span>)}
					</div>
					<xmp>
						<br />
						firstTrackPoint:<br />
						{JSON.stringify(firstTrackPoint, null, 4)}<br />
						<br />
						lastTrackPoint:<br />
						{JSON.stringify(lastTrackPoint, null, 4)}<br />
						<br />
					</xmp>
                </div>
            </div>
        );
    }
}

@observer
class WorkoutDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            submenu: props.submenu || 'totals',
        };
        this.updateTimer = {};
		this.inputRef = {};
    }

    loadWorkout = async (props = this.props) => {
		const { reload } = this.props;
		const { workoutStore } = this.props.stores;
		const { showAdminInfo } = this.state;
		const addData = ['publicTeams', 'publicUsers'];
		// if (showAdminInfo) {
		// 	addData.push('geocluster');
		// }
		// console.log({ reload, workout: props.workout });
		await workoutStore.load(props.workout, {}, {
			addData,
			query: { reload },
		});
		// const { workout } = workoutStore;
		workoutStore.plotGeocluster();

		// if (workout.isInProgress > 0) {
		// 	const reloadLastEpoch = Math.floor(new Date().getTime() / 1000);
		// 	this.setState({
		// 		reloadIsActive: true,
		// 		reloadLastEpoch,
		// 	}, () => this.tickReloadTimeer());
		// 	// Set reload timer
		// 	this.updateTimer.reload = setTimeout(async () => {
		// 		const reloadLastEpoch = Math.floor(new Date().getTime() / 1000);
		// 		this.setState({ reloadLastEpoch });
		// 		this.loadWorkout();
		// 	}, RELOAD_INTERVAL_IN_SEC * 1000);
		// }
    }

	tickReloadTimeer() {
		const { reloadIsActive, reloadLastEpoch } = this.state;
        if (reloadIsActive) {
			const now = Math.floor(new Date().getTime() / 1000);
			const reloadInSec = RELOAD_INTERVAL_IN_SEC - (now - reloadLastEpoch);
			this.setState({ reloadInSec });
			setTimeout(() => this.tickReloadTimeer(), 1000);
        }
		return false;
    }

    updateField = (e) => {
        const { id, field, type, elementtype, isNew } = e.target.dataset;
        const value = e.target.value;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
    }

    updateFieldValue = async ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        const { workoutStore } = this.props.stores;
        let newValue = value;
        let localObjectValue = value;
        const { workout } = workoutStore;
        if (field === 'images') {
            if (Array.isArray(workout.images) && Array.isArray(value)) {
                localObjectValue = [...workout.images, ...value];
            }
        }
        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }
        if (isNew) {
            workoutStore.updateObjectKeyValue('newWorkout', field, value);
        } else {
            workoutStore.updateField(id, field, localObjectValue);
            if (skipTimer) {
                await this.doUpdateField(id, field, newValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue);
                }, 500);
            }
        }

        // GPX update stuff
        if (field === 'gpx' && workout && util.checkNested(workout, 'gpx', 'gpxInfo')) {
            const { totalDistance, maxSpeed, avgSpeed, ascent, decent, duration, rest, startLatlng, endLatlng } = workout.gpx.gpxInfo;
            const updateObject = {};
            if (duration) {
                workoutStore.updateField(id, 'duration', duration);
                updateObject.duration = duration;
            }
            if (rest) {
                workoutStore.updateField(id, 'rest', rest);
                updateObject.rest = rest;
            }
            if (totalDistance) {
                workoutStore.updateField(id, 'distanceKm', util.roundTo(totalDistance, 10));
                updateObject.distanceKm = util.roundTo(totalDistance, 10);
            }
            if (maxSpeed) {
                workoutStore.updateField(id, 'speedmax', util.roundTo(maxSpeed, 10));
                updateObject.speedmax = util.roundTo(maxSpeed, 10);
            }
            if (avgSpeed) {
                workoutStore.updateField(id, 'speedAvg', util.roundTo(avgSpeed, 10));
                updateObject.speedAvg = util.roundTo(avgSpeed, 10);
            }
            if (ascent) {
                workoutStore.updateField(id, 'elevation', ascent);
                updateObject.elevation = ascent;
            }
            if (decent) {
                workoutStore.updateField(id, 'decent', decent);
                updateObject.decent = decent;
            }
            if (startLatlng) {
                workoutStore.updateField(id, 'startLatlng', startLatlng);
                updateObject.startLatlng = startLatlng;
            }
            if (endLatlng) {
                workoutStore.updateField(id, 'endLatlng', endLatlng);
                updateObject.endLatlng = endLatlng;
            }
            await workoutStore.save(updateObject, workout.id);
        }
    }

    async doUpdateField(id, field, value) {
        const { workoutStore } = this.props.stores;
        if (id && field) {
            await workoutStore.saveField(id, field, value);
        }
    }

    // loadWorkoutDetail = async ({ workout }) => {
	// 	const { workoutStore } = this.props.stores;
    //     workoutStore.load(workout.id);
    // }

	showModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const img = e.target.dataset.image;
        const imgXXL = e.target.dataset.imagexxl;
		const thumb = e.target.dataset.thumb;
        const name = e.target.dataset.name;
        const title = e.target.dataset.title;
        const date = e.target.dataset.date;
        this.setState({
            displayModal: true,
            currentImage: img,
            currentImageXXL: imgXXL,
            thumb,
            name,
            title,
            date,
        });
    }

    closeModal = () => {
        this.setState({
            displayModal: false,
        });
    }

	deleteWorkout = async () => {
		const { onDelete = () => {} } = this.props;
		const { workoutStore, appState } = this.props.stores;
		const { workout } = workoutStore;
        await workoutStore.saveField(workout.id, 'offline', 1);
		PubSub.publish(topics.STATUS_MESSAGE, {
			message: 'Workout was deleted',
			type: 'success',
			icon: 'fas fa-check',
		});
		onDelete();
		// appState.toggleDrawer3();
        // route(`/workouts/activities`);
	}

	duplicateWorkout = async () => {
		const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		const response = await workoutStore.duplicateWorkout({ id: workout.id });
		if (response && response.id) {
			PubSub.publish(topics.STATUS_MESSAGE, {
				message: 'Workout was duplicated successfully',
				type: 'success',
				icon: 'fas fa-check',
			});
			route(`/workouts/${response.id}`);
		}
	}

	makeTrack = async () => {
		const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		const response = await workoutStore.makeTrackFromWorkout({ id: workout.id });
		if (response && response.id) {
			PubSub.publish(topics.STATUS_MESSAGE, {
				message: 'Track was created successfully',
				type: 'success',
				icon: 'fas fa-check',
			});
			route(`/tracks/edit/${response.id}`);
		}
	}

	likeWorkout = async () => {
		const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		const response = await workoutStore.likeWorkout({ id: workout.id });

        if (response && response.status === 200) {
			// this.loadWorkoutDetail({ workout });
		}
	}

	toggleAddComment = (e) => {
		const workoutId = e.target.parentElement.dataset.id || e.target.dataset.id;
		const { showAddComment } = this.state;
		this.setState({
			showAddComment: !showAddComment,
		}, () => {
			if (this.inputRef[workoutId]) {
				this.inputRef[workoutId].focus();
			}
		});
	}

	toggleAdminInfo = () => {
		const { userStore } = this.props.stores;
		const { isAdmin } = userStore;
		if (isAdmin) {
			const { showAdminInfo } = this.state;
			this.setState({
				showAdminInfo: !showAdminInfo,
			}, () => {
				this.loadWorkout();
			});
		}
	}

	chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

	showStateDetail = (e) => {
		const data = e.target.closest('div').dataset;
		this.setState({
			stateDetail: {
				...data,
			},
		});
	}

	reimportStravaWorkout = async () => {
        const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		await workoutStore.reimportStravaWorkout(workout.stravaId, workout.id);
        this.loadWorkout();
    }

	reimportGarminWorkout = async () => {
        const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		await workoutStore.reimportGarminWorkout(workout.garminId, workout.id);
        this.loadWorkout();
    }

	reCalculateGpxData = async () => {
		const { recalculated } = this.state;
		if (recalculated) {
			return true;
		}
		const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;
		this.setState({ recalculated: true });
        await workoutStore.reLoad(workout.id);
    }

	toggleMapSize = () => {
		const { mapHeight } = this.state;
		if (mapHeight === initialState.mapHeight) {
			this.setState({ mapHeight: initialState.mapMaxHeight });
		} else {
			this.setState({ mapHeight: initialState.mapHeight });
		}
	}

	finishWorkout = async () => {
		const { workoutStore } = this.props.stores;
		await workoutStore.cordovaSaveTracking();
		const { workout } = workoutStore;
        const updateObject = {
            isInProgress: 0,
        };
        await workoutStore.save(updateObject, workout.id);
		this.loadWorkout();
	}

	sendGpxFile = async () => {
		const { workoutStore } = this.props.stores;
		const { workout } = workoutStore;

		await workoutStore.sendGpxFile(workout.id);
		this.setState({
			gpxFileSent: true,
		});
	}

	shareLink = (e) => {
        const { url, title, text } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        appState.shareUrl({ url, title, text });
    }

	// Overlay functions
    onTeamClick = (e, teamid) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showTeam: teamid,
        });
        return false;
    }

    hideTeam = () => {
        this.setState({
            showTeam: null,
        });
    }

    onUserClick = (e, userid) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    // onDogClick = (e, dogid, dogs) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     this.setState({
    //         showDog: dogid,
    //         dogs,
    //     });
    //     return false;
    // }

	onMapClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
		const { id } = e.target.closest('button').dataset;
        // const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        appState.openDrawer3('workoutMap', { id, height: drawerHeightMedium });
	}

	onEditClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
		const { id } = e.target.closest('button').dataset;
        // const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('workoutEdit', {
			id,
			height: drawerHeightMedium,
		});
	}

    hideDog = () => {
        this.setState({
            showDog: null,
        });
    }

    back = () => {
		route(`/workouts/activities`);
        // const { history } = this.props.stores;
        // history.goBack();
    }

    componentDidMount() {
        this.loadWorkout();
		// this.addNavigation();
    }

	componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.workout !== this.props.workout) {
            this.loadWorkout(nextProps);
        }
    }

	componentWillUnmount() {
		clearTimeout(this.updateTimer.reload);
	}

    //     const { workoutStore } = this.props.stores;
	// 	workoutStore.cleanupMemory();
    // }

	// // Swipe navigation
	// addNavigation = () => {
	// 	console.log('addNavigation');
    //     const { appState, workoutStore } = this.props.stores;
	// 	appState.setSwipeRight(() => {
	// 		console.log('swipeRight');
	// 		const { workoutStore } = this.props.stores;
	// 		const { workout } = this.props;
	// 		const workoutNavigation = workoutStore.getWorkoutNavigation(parseInt(workout, 10)) || {};
	// 		route(`/workouts/${workoutNavigation.previous.id}`);
	// 	});
	// 	appState.setSwipeLeft(() => {
	// 		console.log('swipeLeft');
	// 		const { workoutStore } = this.props.stores;
	// 		const { workout } = this.props;
	// 		const workoutNavigation = workoutStore.getWorkoutNavigation(parseInt(workout, 10)) || {};
	// 		route(`/workouts/${workoutNavigation.next.id}`);
	// 	});
	// }

	// componentWillUnmount() {
	// 	console.log('removeNavigation');
	// 	const { appState } = this.props.stores;
	// 	appState.setSwipeRight();
	// 	appState.setSwipeLeft();
	// }
	// // /Swipe navigation

	render() {
		const { workoutStore, userStore, appState } = this.props.stores;
		const { onLinkClick = () => {}, marginTop, marginBottom, showBackButton = true, onDogClick = () => {} } = this.props;
		const { mapColorMode, mapRangeMin, mapRangeMax, isCordova, hasShareApi, viewmode } = appState;
		const { darkmode } = appState;
		const { user, isAdmin } = userStore;
		const { submenu, showAddComment, showAdminInfo, mapHeight, reloadInSec, reloadLastEpoch, stateDetail, gpxFileSent,
            showTeam,
            showDog,
			recalculated,
		} = this.state;
		const reloadPercent = Math.floor(util.normalizeBetween(reloadInSec, 0, RELOAD_INTERVAL_IN_SEC, 0, 100));
		const { workout, saved, waypoints2 = [], waypoints3 = [], currentWeatherMarkers } = workoutStore;
		const { likes = [], comments = [] } = workout;
        const { language = 'en' } = user;
		const team = userStore.findTeam(workout.team, true) || {};
		const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        const teamType = userStore.findTeamType({ type: team.type, language });
        const workoutType = userStore.findWorkoutType({ type: workout.type, language });
		const workoutTypeIcon = userStore.findWorkoutType({ type: workout.type, field: 'icon' });
		const track = userStore.findTrack(workout.track);
		const workoutEquipment = userStore.findEquipment({ equipment: workout.equipment, language });
		const workoutEquipmentIcon = userStore.findEquipment({ equipment: workout.equipment, field: 'icon' });

		const dogs = userStore.findDogs(workout.dogs);
		const mushers = userStore.findMushers(workout.mushers);
		const intensity = userStore.findIntensity({ code: workout.intensity, language }) || {};
		const workoutNavigation = workoutStore.getWorkoutNavigation(workout.id) || {};
		const hasLikedWorkout = workout.likes && workout.likes?.findIndex(e => e.user === user.id) > -1;
		const { gpxInfo = {}, gpx = { gpxInfo: {} } } = workout;
		const { weightedTotalKcal = 0, loadIndex = 0 } = gpxInfo;
		const loadMultiplier = loadIndex / 100;
		const kcalMultiplier = gpxInfo.totalKcalRelated / 100;
        const teamNames = workout.teams ? workout.teams.map(e => userStore.findTeam(e)) : [];
        let batteryUsage;
        if (workout && workout.gpxInfo && workout.gpxInfo.batteryLevelStart) {
            batteryUsage = (workout.gpxInfo.batteryLevelStart - workout.gpxInfo.battegryLevelEnd) * 100;
        }

		const images = [];
        if (workout.mapImage) {
            images.push({
                ...workout.mapImage,
                type: 'map',
            });
        }
        if (workout.images) {
            const imgs = toJS(workout.images);
            images.push(...imgs);
        }

		let showReimport = false;

		const views = mu.getViews(workout.statistics);

		const musherImages = [];
        if (workout.mushers && workout.mushers.length > 1) {
            workout.mushers.slice(1, 10).map((musher) => {
				const u = userStore.findMusher(musher);
                if (u && u.images && u.images[0]) {
                    musherImages.push(u.images[0]);
                }
            });
        }

        let image;
		if (workout.mushers && workout.mushers[0]) {
			let firstMusher = userStore.findMusher(workout.mushers[0]);
			if (firstMusher && firstMusher.images && firstMusher.images[0]) {
				image = firstMusher.images[0];
			}
        } else if (team.image && team.image.s3SmallLink) {
            image = team.image;
        } else if (team.images && team.images[0] && team.images[0].s3SmallLink) {
            image = team.images[0];
        } else if (workout.image && workout.image.s3SmallLink) {
            image = workout.image;
        }

		return (<>

            {showTeam && <Overlay>
                <TeamsPublicDetail stores={this.props.stores} teamid={showTeam} marginTop={'0px;'} marginBottom={'0px;'} back={this.hideTeam} hideTeam={this.hideTeam} />
            </Overlay>}

            {showDog && <Overlay>
                <DogPublicDetail stores={this.props.stores} dogid={showDog} marginTop={'0px;'} marginBottom={'0px;'} back={this.hideDog} dogs={dogs} />
            </Overlay>}

			<div class='container-fluid' style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <div class='d-flex justify-content-between mb-2'>
                    {showBackButton && <div>
						<button
							class='btn btn-link float-left pl-0 fixed-top text-primary'
							type='button'
                            style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
							onClick={this.back}
						>
							<i class='fas fa-arrow-circle-left' />
						</button>
                    </div>}
                    <div>
                        <h5 class='text-muted'>&nbsp;</h5>
                    </div>
                    <div>
                        {workout.isLocked === 1 ? <>
							<i class='fas fa-lock text-danger' style='font-size: 1.5em;' />
							{isAdmin && <>
								<button
									type='button'
									class='btn btn-primary float-right'
									data-id={workout.id}
									onClick={this.onEditClick}
								>
									<i class='fas fa-edit' /> <Text id='workouts.admin-edit'>Admin edit</Text>
								</button>
							</>}
						</> : <>
							<button
								type='button'
								class='btn btn btn-primary float-right mt-1 mr-5'
								data-id={workout.id}
								onClick={this.onEditClick}
							>
								<i class='fas fa-edit' /> <Text id='workouts.edit'>Endre</Text>
							</button>
						</>}
                    </div>
                </div>

				{showBackButton && <div class='d-flex justify-content-between mb-2'>
                    <div class='text-muted'>
						{workoutNavigation && workoutNavigation.previous && <a
							href={`/workouts/${workoutNavigation.previous.id}`}
							class='text-muted'
							onClick={onLinkClick}
						>
							<i class='fas fa-arrow-circle-left' />
						</a>}
					</div>
					<div class='text-muted'>
						{workoutNavigation.total > 0 && <>
							{workoutNavigation.idx + 1} / {workoutNavigation.total}
						</>}
					</div>
					<div class='text-muted'>
						{workoutNavigation && workoutNavigation.next && <a
							href={`/workouts/${workoutNavigation.next.id}`}
							class='text-muted'
							onClick={onLinkClick}
						>
							<i class='fas fa-arrow-circle-right' />
						</a>}
					</div>
				</div>}

				<div class='row'>
					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
						<div class='d-flex mb-2'>
							<div class={`position-relative text-center border rounded-circle imageRounded ${getWorkoutColor(workout)}`}
                                style={`${image ? `background-image: url("${image.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
							>
								{!image && <i class={`${getWorkoutIcon(workout)} text-${intensity.class ? intensity.class : 'muted'} mt-3`} style='font-size: 40px;' />}

                                {musherImages && musherImages.map((img, idx) => <>
                                    <div
                                        class='position-absolute rounded-circle imageRounded border imageRoundedThumb border-white'
                                        style={`
                                            background-image: url("${img.s3SmallLink}");
                                            background-size: cover;
                                            bottom: -3px;
                                            left: ${-5 + (idx * 10)}px;
                                        `}
                                    />
                                </>)}
							</div>
							<div class='flex-grow-1 pl-3'>
								<h5 class='my-0'>
									<Field value={workout.name} darkmode={darkmode} />
									{workout.isInProgress > 0 && <>
										<span class='position-relative ml-3'>
											{workout.isInProgress === 1 && <div class='spinner-grow text-success position-absolute' style='top: -5px; left: -6px;' role='status' />}
											{/* {workout.isInProgress === 2 && <i class='fas fa-stop-circle text-warning position-absolute' style='top: 0px; left: 0px;' />} */}
											{/* {reloadInSec > 0 && <div class='position-absolute' style='top: -5px; left: -5px;'>
												<Gauge key={`gauge-reload-page`}
													color='primary'
													radius={15}
													showPercentText={false}
													percent={reloadPercent}
												/>
											</div>} */}
										</span>
										<i class='fas fa-stop-circle float-right text-warning' style='font-size: 1.5em; cursor: pointer; pointer-events: inherit;' onClick={this.finishWorkout} />
									</>}

									{isCordova && showAdminInfo && isAdmin && <>
										<a
											href={`/workouts/tracking/${workout.id}`}
											class='ml-4'
											onClick={onLinkClick}
										>
											<i class='far fa-play-circle' />
											<Text id='footer.workouts-continue'>Continue</Text>
										</a>
									</>}
								</h5>
								{workout.garminId && <div class={`float-right ml-2 text-center border rounded-circle imageRounded imageRoundedIcon`} style={`background-image: url('./assets/garmin-connect.png');`} />}
								{workout.stravaId && <div class={`float-right ml-2 text-center border rounded-circle imageRounded imageRoundedIcon`} style={`background-image: url('./assets/strava-icon.png');`} />}

								{workout.team && userStore.findTeam(workout.team)}
								{teamNames && teamNames.length > 0 && <small class='font-weight-lighter'>
                                    , {teamNames.map((name, idx) => <>{idx > 0 ? ', ' : ''}{name}</>)}
                                </small>}
								<br />

								<small class='text-muted'>
									<Field value={workout.date} darkmode={darkmode} style='width: 50%;'>
										{util.isoDate(workout.date, false, false, false)}
									</Field>
								</small>
								{intensity.class && <span class={`badge badge-${intensity.class} ml-2`}>{intensity.name}</span>}
								{workout.sledWeight > 0 && <small class='ml-3 text-muted'>
									<i class='fas fa-sleigh' /> <Text id='workouts.sledWeight'>Sledevekt</Text>: <Weight stores={this.props.stores} value={workout.sledWeight} />
								</small>}
								{batteryUsage > 0 && <small class='text-muted ml-3'><i class='fas fa-battery-three-quarters' /> {util.format(batteryUsage, 1)}% used</small>}
							</div>
						</div>
					</div>

					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
						{workout.comment && <div class='mb-3'>
							<Field value={workout.comment} darkmode={darkmode}>
								<Markdown markdown={mu.replaceImages(workout.comment, workout.images, language)} markedOpts={mu.getMarkdownOptions()} />
							</Field>
						</div>}
					</div>

					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
						<div class='row'>
							<div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-0 px-1'
									style='overflow: auto; scroll-snap-type: x mandatory; line-height: 1.0em; height: 56px;'
                                >
									{workout.isInProgress > 0 && <div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'current' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='current'
											onClick={this.chooseSubmenu}
										><i class='fas fa-tachometer-alt' /> <Text id='workouts.current'>Akkurat nå</Text></button>
                                        {/* {submenu === 'current' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>}
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block ${workout.isInProgress > 0 ? '' : 'rounded-pill-left'} rounded-none ${submenu === 'totals' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='totals'
											onClick={this.chooseSubmenu}
										><i class='fas fa-tachometer-alt' /> <Text id='workouts.totals'>Totaler</Text></button>
                                        {/* {submenu === 'totals' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'map' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='map'
											onClick={this.chooseSubmenu}
										><i class='fas fa-tachometer-alt' /> <Text id='workouts.map'>Map</Text></button>
                                        {/* {submenu === 'map' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'analysis' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='analysis'
											onClick={this.chooseSubmenu}
										><i class='fas fa-chart-area' /> <Text id='workouts.analysis'>Analyser</Text></button>
                                        {/* {submenu === 'analysis' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'images' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='images'
											onClick={this.chooseSubmenu}
										><i class='fas fa-images' /> <Text id='workouts.images'>Bilder</Text></button>
                                        {/* {submenu === 'images' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'dogs' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='dogs'
											onClick={this.chooseSubmenu}
										><i class='fas fa-dog' /> <Text id='workouts.dogs'>Hunder</Text></button>
                                        {/* {submenu === 'dogs' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'handlers' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='handlers'
											onClick={this.chooseSubmenu}
										><i class='fas fa-user' /> <Text id='workouts.handlers'>Handlere</Text></button>
                                        {/* {submenu === 'handlers' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-none ${submenu === 'gpx' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='gpx'
											onClick={this.chooseSubmenu}
										><i class='fas fa-user' /> <Text id='workouts.gpx'>GPX</Text></button>
                                        {/* {submenu === 'gpx' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
									<div class='col px-0 pt-0'>
										<button
											type='button'
											class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='advanced'
											onClick={this.chooseSubmenu}
										><i class='fas fa-cogs' /> <Text id='workouts.advanced'>advanced</Text></button>
                                        {/* {submenu === 'advanced' && <ScrollIntoView horizontal={'center'}  />} */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-3'>
						{isAdmin && <>
							<button
								type='button'
								class='btn btn-link btn-block'
								onClick={this.toggleAdminInfo}
							>
								Show admin details
							</button>
						</>}
					</div>

					{submenu === 'map' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-3'>
							{util.checkNested(workout, 'gpxInfo', 'centerCoords') ? <>
{/* <xmp>{JSON.stringify(workout.startMarker, null, 4)}</xmp>
<xmp>{JSON.stringify(currentWeatherMarkers, null, 4)}</xmp> */}
								<button type='button' class='btn btn-sm btn-link float-right' onClick={this.onMapClick} data-id={workout.id}>
									Toggle map size
								</button>
								{/* startLatlng: <xmp>{JSON.stringify(workout, null, 4)}</xmp> */}
								<div class='clearfix'>
									<div class='' style={`height: ${mapHeight};`}>
										<Map
											stores={this.props.stores}
											mapId={`${workout.id}-${mapHeight}`}
											// geojson={workout.gpx.geoJSON}
											geojsonExternal={workout.uuidv4}
											geojsonExternalTrack={workout.track}
											// geojsonExternalTrack={'fd6ef622-2848-49ab-8bc8-2e9d4d830624'}
											skipGeojson={false}
											center={workout.gpxInfo?.centerCoords}
											startMarker={workout.startMarker}
											endMarker={workout.endMarker}
											markers={currentWeatherMarkers}
											gpxInfo={workout.gpxInfo}
											height={mapHeight}
											mapColorMode={mapColorMode}
											mapRangeMin={mapRangeMin}
											mapRangeMax={mapRangeMax}
											// waypoints2={waypoints2}
											// waypoints3={waypoints3}
											key={`workout-map-${workout.id}`}
											fullscreenMap={true}
											showRange={true}
											show3dMap={false}
										/>
									</div>
								</div>
							</> : <>No gps data</>}
							{track && track.gpx && !(workout && workout.gpx) && <>
								<div class='clearfix' style={`height: ${mapHeight}px;`}>
									<Map
										stores={this.props.stores}
										mapId={`${track.id}-${mapHeight}`}
										geojson={track.gpx?.geoJSON}
										skipGeojson={false}
										gpxInfo={track.gpx?.gpxInfo}
										height={mapHeight}
										mapColorMode={mapColorMode}
										mapRangeMin={mapRangeMin}
										mapRangeMax={mapRangeMax}
										key={`workout-map-${reloadLastEpoch}`}
	                                    fullscreenMap={true}
										show3dMap={false}
									/>
								</div>
							</>}
							{!util.checkNested(workout, 'gpxInfo', 'centerCoords') && <>
								<div class='text-center text-muted'>
									<div class='display-1'>
										<i class='fas fa-map-marked-alt' />
									</div>
									<h3 class='font-weight-lighter'>
										<Text id='workout.no-gps-data'>No GPS data. If you use a GPS to track your workouts you will get a nice map here and more correct analysis of the energy usage.</Text>
									</h3>
								</div>
							</>}

							<div class='text-center'>
								<small class='text-muted'>
									{workout.weather}
									{!workout.weather && <>
										{util.format(workout.temperature, 1)}°C
									</>}
								</small>
							</div>
						</div>
					</>}

					{submenu === 'current' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-clock ml-2' /> <Text id='workouts.duration'>Vargihet</Text></small>
									<h3>
										{util.secToHms(workout.duration)}
									</h3>
								</div>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-clock ml-2' /> <Text id='workouts.rest'>Hvile</Text></small>
									<h3 class='text-muted'>
										{util.secToHms(workout.rest)}
									</h3>
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-tachometer-alt ml-2' /> <Text id='workouts.avgspeed'>Gj.fart</Text></small>
									<h3>
										<Speed stores={this.props.stores} value={workout.speedAvg} />
									</h3>
								</div>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-tachometer-alt ml-2' /> <Text id='workouts.currentspeed'>Fart</Text></small>
									<h3>
										<Speed stores={this.props.stores} value={workout.currentSpeed} />
									</h3>
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-road ml-2' /> <Text id='workouts.distance'>Distanse</Text></small>
									<h3>
										<Distance stores={this.props.stores} value={workout.distanceKm} />
									</h3>
									{gpxInfo.calculatedTotalDistance > 0 && Math.abs(workout.distanceKm - gpxInfo.calculatedTotalDistance) > 1 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(gpxInfo.calculatedTotalDistance, 1)} km <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedTotalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedTotalDistance: {util.format(gpxInfo.calculatedTotalDistance, 2)} km <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.totalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.totalDistance: {util.format(gpx.gpxInfo.totalDistance, 2)} km <br />
									</small>}
								</div>
								<div class='col  text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-mountain ml-2' /> <Text id='workouts.ascend'>Stigning</Text></small>
									<h3>
										<Elevation stores={this.props.stores} value={workout.elevation} />
									</h3>
									{gpxInfo.calculatedAscend > 0 && Math.abs(workout.elevation - gpxInfo.calculatedAscend) > 50 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(gpxInfo.calculatedAscend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedAscend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedAscend: {util.format(gpxInfo.calculatedAscend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedDescend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedDescend: {util.format(gpxInfo.calculatedDescend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.ascent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.ascent: {util.format(gpx.gpxInfo.ascent, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.descent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.descent: {util.format(gpx.gpxInfo.descent, 0)} m <br />
									</small>}
								</div>
							</div>
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu === 'totals' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							{isAdmin && showAdminInfo && <div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-user-shield ml-2' /> <Text id='workouts.admin-info'>Admin info</Text></small>
									<h3>Admin</h3>
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.startTime > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.startTime: {util.isoDate(gpx.gpxInfo.startTime)} <br />
										gpx.gpxInfo.endTime: {util.isoDate(gpx.gpxInfo.endTime)} <br />
									</small>}
								</div>
							</div>}
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-clock ml-2' /> <Text id='workouts.duration'>Vargihet</Text></small>
									<h3>
										{util.secToHms(workout.duration)}
									</h3>
									{isAdmin && showAdminInfo && gpxInfo.minEpoch > 0 && gpxInfo.maxEpoch > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.maxEpoch-minEpoch: {util.secToHms(gpxInfo.maxEpoch - gpxInfo.minEpoch)} <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.duration > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.duration: {util.secToHms(gpxInfo.duration)} <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.endTime > 0 && gpx.gpxInfo.startTime > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.endTime-startTime: {util.secToHms((gpx.gpxInfo.endTime - gpx.gpxInfo.startTime) / 1000)} <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.duration > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.duration: {util.secToHms(gpx.gpxInfo.duration)} <br />
									</small>}
								</div>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-clock ml-2' /> <Text id='workouts.rest'>Hvile</Text></small>
									<h3 class='text-muted'>
										{util.secToHms(workout.rest)}
									</h3>
									{isAdmin && showAdminInfo && gpxInfo.minEpoch > 0 && gpxInfo.maxEpoch > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.maxEpoch-minEpoch: {util.secToHms(gpxInfo.rest)} <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.rest > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.rest: {util.secToHms(gpxInfo.rest)} <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.rest > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.rest: {util.secToHms(gpx.gpxInfo.rest)} <br />
									</small>}
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-tachometer-alt ml-2' /> <Text id='workouts.avgspeed'>Gj.fart</Text></small>
									<h3>
										{/* {gpxInfo && gpxInfo.avgGpsMovingSpeed > 0 ? <>
											<Speed stores={this.props.stores} value={gpxInfo.avgMovingSpeeds * 3.6} />
										</> : <> */}
										<Speed stores={this.props.stores} value={workout.speedAvg} />
										{/* </>} */}
									</h3>
									{/* avgGpsMovingSpeed */}
									{isAdmin && showAdminInfo && gpxInfo.avgMovingSpeeds > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.avgMovingSpeeds {util.format(gpxInfo.avgMovingSpeeds * 3.6, 2)} km/t <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.avgGpsSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.avgGpsSpeed {util.format(gpxInfo.avgGpsSpeed * 3.6, 2)} km/t <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.avgGpsMovingSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.avgGpsMovingSpeed {util.format(gpxInfo.avgGpsMovingSpeed * 3.6, 2)} km/t <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.avgSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.avgSpeed {util.format(gpx.gpxInfo.avgSpeed * 3.6, 2)} km/t <br />
									</small>}
								</div>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class={`${workoutTypeIcon}`} /> <Text id='workouts.type'>Type</Text></small>
									<h3>
										 {workoutType}
									</h3>
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class={`${workoutEquipmentIcon}`} /> <Text id='workouts.equipment'>Utstyr</Text></small>
									<h3>
										{workoutEquipment}
									</h3>
								</div>
								<div class='col text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-dog ml-2' /> <Text id='workouts.dogs'>Hunder</Text></small>
									<h3>
										{workout.dogs && <span>{workout.dogs.length}</span>}
									</h3>
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-road ml-2' /> <Text id='workouts.distance'>Distanse</Text></small>
									<h3>
										<Distance stores={this.props.stores} value={workout.distanceKm} />
									</h3>
									{gpxInfo.calculatedTotalDistance > 0 && Math.abs(workout.distanceKm - gpxInfo.calculatedTotalDistance) > 1 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(gpxInfo.calculatedTotalDistance, 1)} km <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedTotalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedTotalDistance: {util.format(gpxInfo.calculatedTotalDistance, 2)} km <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.totalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.totalDistance: {util.format(gpx.gpxInfo.totalDistance, 2)} km <br />
									</small>}
								</div>
								<div class='col  text-center' style='line-height: 1.0em;'>
									<small class='text-muted'><i class='fas fa-mountain ml-2' /> <Text id='workouts.ascend'>Stigning</Text></small>
									<h3>
				                        <Elevation stores={this.props.stores} value={workout.elevation} />
									</h3>
									{gpxInfo.calculatedAscend > 0 && Math.abs(workout.elevation - gpxInfo.calculatedAscend) > 50 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(gpxInfo.calculatedAscend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedAscend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedAscend: {util.format(gpxInfo.calculatedAscend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpxInfo.calculatedDescend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpxInfo.calculatedDescend: {util.format(gpxInfo.calculatedDescend, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.ascent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.ascent: {util.format(gpx.gpxInfo.ascent, 0)} m <br />
									</small>}
									{isAdmin && showAdminInfo && gpx.gpxInfo && gpx.gpxInfo.descent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
										gpx.gpxInfo.descent: {util.format(gpx.gpxInfo.descent, 0)} m <br />
									</small>}

								</div>
							</div>
							<div class='row mt-2'>
								<div class='col text-center' style='line-height: 1.0em;'>
									{workout.isLocked === 1 ? <>
										<i class='fas fa-lock text-danger' style='font-size: 1.5em;' />
									</> : <>
										{workout.stravaId && !gpxInfo.reimported && <button class='btn btn-sm btn-link text-muted'
											style='font-size: 0.8em;'
											onClick={this.reimportStravaWorkout}
										>
											{showReimport = true}
											<i class='fas fa-sync' /> Reimport Strava data
										</button>}
										{workout.garminId && !gpxInfo.reimported && <button class='btn btn-sm btn-link text-muted'
											style='font-size: 0.8em;'
											onClick={this.reimportGarminWorkout}
										>
											{showReimport = true}
											<i class='fas fa-sync' /> Reimport Garmin data
										</button>}
										{(isAdmin || !showReimport) && <button class={`btn btn-sm btn-link text-muted`}
											style='font-size: 0.8em;'
											onClick={this.reCalculateGpxData}
										>
											<i class='fas fa-sync' /> Recalculate GPX data
										</button>}
									</>}
								</div>
							</div>
							<States stores={this.props.stores} states={gpxInfo.states} duration={workout.duration} />
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu === 'analysis' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							{(util.checkNested(workout, 'gpxInfo', 'elevations') || util.checkNested(workout, 'gpx', 'gpxInfo', 'elevations')) ? <>
								<WorkoutInfoGraph stores={this.props.stores} key={`workout-elevation-${workout.id}`} workout={workout} />
							</> : <>
								<div class='text-center text-muted'>
									<div class='display-1'>
										<i class='fas fa-chart-area' />
									</div>
									<h3 class='font-weight-lighter'>
										<Text id='workout.no-elevation-data'>No elevation data. If you use a GPS to track your workouts you will get a nice graph here and more correct analysis of the energy usage.</Text>
									</h3>
								</div>
							</>}
						</div>

						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							{loadIndex > 0 && <div class='row mt-4'>
								<div class='col text-center'>
									<h5 onClick={this.toggleAdminInfo}>
										<Text id='workouts.load-index'>Belastningsindex</Text>
									</h5>
									<Gauge key={`gauge-${loadIndex}`}
										radius={100}
										showPercentText={true}
										color={mu.getLoadIndexColor(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
										percent={Math.floor(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
										completed={mu.getLoadIndexText(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
										prefix=''
									/>
									<div>
										{teamType && <a href={`/settings/team/${workout.team}`} onClick={onLinkClick}><span class='badge badge-pill badge-secondary'>{teamType}</span></a>}
										{teamAmbition && <a href={`/settings/team/${workout.team}`} onClick={onLinkClick}><span class='badge badge-pill badge-secondary ml-2'>{teamAmbition}</span></a>}<br />
									</div>

									{workout.stravaId && !gpxInfo.reimported && <button class='btn btn-sm btn-link text-muted'
										style='font-size: 0.8em;'
										onClick={this.reimportStravaWorkout}
									>
										<i class='fas fa-sync' /> <Text id='workouts.reimport-strava'>Reimport Strava data</Text>
									</button>}
									{workout.garminId && !gpxInfo.reimported && <button class='btn btn-sm btn-link text-muted'
										style='font-size: 0.8em;'
										onClick={this.reimportGarminWorkout}
									>
										<i class='fas fa-sync' /> <Text id='workouts.reimport-garmin'>Reimport Garmin data</Text>
									</button>}

									{isAdmin && showAdminInfo && <div>
										<small>
											<span class='badge badge-secondary mr-2'>loadIndex: {gpxInfo.loadIndex}</span>
											<span class='badge badge-secondary mr-2'>Max loadIndex: {MAX_LOADINDEX}</span>
											<span class='badge badge-secondary mr-2'>totalKcalRelated: {util.format(gpxInfo.totalKcalRelated, 1)}</span>
											<span class='badge badge-secondary mr-2'>typeFactor: {gpxInfo.typeFactor}</span>
											<span class='badge badge-secondary mr-2'>ambitionFactor: {gpxInfo.ambitionFactor}</span>
											<span class='badge badge-secondary mr-2'>normalSpeed: {gpxInfo.normalSpeed}</span>
											<span class='badge badge-secondary mr-2'>speedFactor: {util.format(gpxInfo.speedFactor, 2)}</span>
											<span class='badge badge-secondary mr-2'>normalDuration: {gpxInfo.normalDuration}</span>
											<span class='badge badge-secondary mr-2'>durationFactor: {util.format(gpxInfo.durationFactor, 2)}</span>
											<span class='badge badge-secondary mr-2'>intensityFactor: {util.format(gpxInfo.intensityFactor, 2)}</span>
											<div>
												<small class='text-muted'>
													loadIndex = Math.floor(totalKcalRelated * durationFactor * speedFactor * typeFactor * ambitionFactor);
												</small>
											</div>
										</small>
									</div>}
								</div>
							</div>}
						</div>

						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							<div class='row mt-4'>
								<div class='col text-center'>
									<small class='text-muted'>
										<Text id='workouts.energy-consumption-help'>Energiforbruk er regnet ut fra 1,25 kcal / kg kroppsvekt / km.</Text>
									</small>
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-balance-scale ml-2' /> <Text id='workouts.energy-consumption-total'>Energiforbruk totalt</Text></small>
									<h3>
										{util.format(workout.kcalBurnedTotal, 0)} <Text id='workout.kcal'>kcal</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalBurnedTotal * kcalMultiplier, 0)} <Text id='workout.kcal'>kcal</Text> <br />
									</small>}
								</div>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-balance-scale ml-2' /> <Text id='workouts.energy-consumption'>Energiforbruk pr hund</Text></small>
									<h3>
										{util.format(workout.kcalBurned, 0)} kcal
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(weightedTotalKcal, 0)} <Text id='workout.kcal'>kcal</Text> <br />
									</small>}
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-piggy-bank ml-2' /> <Text id='workouts.energy-consumption-fat-total'>Fett totalt</Text></small>
									<h3>
										{util.format(workout.kcalFatGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalFatGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-piggy-bank ml-2' /> <Text id='workouts.energy-consumption-fat'>Fett pr hund</Text></small>
									<h3>
										{util.format(workout.kcalFatGrams / 1000, 1)} <Text id='workout.kg'>kg</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalFatGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-drumstick-bite ml-2' /> <Text id='workouts.energy-consumption-meat-total'>Kjøtt totalt</Text></small>
									<h3>
										{util.format(workout.kcalMeatGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalMeatGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-drumstick-bite ml-2' /> <Text id='workouts.energy-consumption-meat'>Kjøtt pr hund</Text></small>
									<h3>
										{util.format(workout.kcalMeatGrams / 1000, 1)} kg
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalMeatGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
							</div>
							<div class='row mt-2'>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-fish ml-2' /> <Text id='workouts.energy-consumption-salmon-total'>Kjøtt totalt</Text></small>
									<h3>
										{util.format(workout.kcalSalmonGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalSalmonGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
								<div class='col  text-center'>
									<small class='text-muted'><i class='fas fa-fish ml-2' /> <Text id='workouts.energy-consumption-salmon'>Kjøtt pr hund</Text></small>
									<h3>
										{util.format(workout.kcalSalmonGrams / 1000, 1)} <Text id='workout.kg'>kg</Text>
									</h3>
									{kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
										<Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalSalmonGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
									</small>}
								</div>
							</div>
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu === 'images' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
							<div class='row'>
								<ImageScroller stores={this.props.stores} images={images} />
							</div>
						</div>

						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}

						{/* <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-2 mt-5 bg-light py-4 border rounded-lg'>
							<Input saved={saved} workout={workout} field='image' type='image'
								stores={this.props.stores}
								title={<Text id='workouts.photos'>Bilder</Text>}
								help={<Text id='workouts.photos-help'>Legg til bilde fra treningen.</Text>}
								onInput={this.updateField}
								updateField={this.updateFieldValue}
							/>
						</div> */}
					</>}

					{submenu === 'dogs' && <>
						{workout.dogsLeft && <div class='offset-0 col-12 mt-4'>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
								<h5><Text id='workouts.dogs-positions'>Posisjoner i spannet</Text></h5>
							</div>
							<div class='row'>
								<div class='col-6 p-0'>
									{workout.dogsLeft && workout.dogsLeft.map(dogId => {
										const dog = userStore.findDog(dogId);
										return <DogSimple stores={this.props.stores} dog={dog} onDogClick={onDogClick} className='ml-auto' />;
									})}
								</div>
								<div class='col-6 p-0'>
									{workout.dogsRight && workout.dogsRight.map(dogId => {
										const dog = userStore.findDog(dogId);
										return <DogSimple stores={this.props.stores} dog={dog} onDogClick={onDogClick} className='mr-auto' />;
									})}
								</div>
							</div>
						</div>}
					</>}

					{submenu === 'dogs' && <>
						<div class='offset-0 col-12 mt-4'>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
								<h5><Text id='workouts.all-dogs' fields={{ total: dogs.length }}>Hundene - {dogs.length} stk</Text></h5>
							</div>
							{dogs && dogs.map(dog => (
								<Dog stores={this.props.stores} dog={dog} onDogClick={onDogClick} />
							))}
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu === 'handlers' && <>
						<div class='offset-0 col-12 mt-4'>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
								<h5><Text id='workouts.all-mushers' fields={{ total: mushers.length }}>Handlere - {mushers.length} stk</Text></h5>
							</div>
							{mushers && mushers.map(musher => (
								<Musher stores={this.props.stores} musher={musher} />
							))}
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu === 'gpx' && <>
						<div class='offset-0 col-12 mt-4'>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0 mb-3'>
								<h5><Text id='workouts.gpx-title'>GPX</Text></h5>
								<Text id='workouts.gpx-description'>Send GPX-file to your email address.</Text>
							</div>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0 mb-5'>
								{gpxFileSent ? <>
									<i class='fas fa-check text-success' /> <Text id='workouts.gpx-file-sent'>GPX file sent via email.</Text>
								</> : <>
									<button class='btn btn-success' onClick={this.sendGpxFile}>
										<Text id='workouts.gpx-send'>Send GPX-file</Text>
									</button>
								</>}
							</div>
						</div>
						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}


					 {submenu === 'advanced' && <>
						<div class='offset-0 col-12 mt-4'>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0 mb-3'>
								<h5><Text id='workouts.advanced'>Advanced</Text></h5>
							</div>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0 mb-5'>
								<div class='row mt-2'>
									<div class='col text-center' style='line-height: 1.0em;'>
										<small class='text-muted'>
											<i class='fas fa-cloud-sun-rain ml-2' /> <Text id='workouts.weather'>The weather during this workout</Text>
										</small>
										<h3 class='font-weight-lighter'>
											{workout.weather}
										</h3>
									</div>
								</div>
								<div class='row mt-4'>
									<div class='col text-center' style='line-height: 1.0em;'>
										<small class='text-muted'>
											<i class='fas fa-temperature-low ml-2' /> <Text id='workouts.temperature'>Temperature</Text>
										</small>
										<h3>
											<Temperature stores={this.props.stores} value={workout.temperature} />
										</h3>
									</div>

									{workout.sunPosition && <div class='col text-center' style='line-height: 1.0em;'>
										<small class='text-muted'>
											<i class='fas fa-sun ml-2' /> <Text id='workouts.periode-of-day'>Periode of day</Text>
										</small>
										<h3>
											{workout.sunPosition.start} - {workout.sunPosition.end}
										</h3>
									</div>}
								</div>
								<div class='row mt-4'>
									{workout.moonPosition && workout.moonPosition.start && workout.moonPosition.start.altitudeDeg > 0 && <div class='col text-center' style='line-height: 1.0em;'>
										<small class='text-muted'>
											{workout.moonIllumination && workout.moonIllumination.phaseIcon && <>{workout.moonIllumination.phaseIcon}</>} <Text id='workouts.moon-position'>Moon position</Text>
										</small>
										<h3>
											{util.format(workout.moonPosition.start.altitudeDeg, 0)}° {workout.moonPosition.end && workout.moonPosition.end.altitudeDeg > 0 && <> - {util.format(workout.moonPosition.end.altitudeDeg, 0)}°</>}
										</h3>
										{workout.moonIllumination && workout.moonIllumination.fraction && <>
											<div class='progress'>
												<div
													class='progress-bar bg-warning'
													role='progressbar'
													style={`width: ${workout.moonIllumination.fraction * 100}%`}
													aria-valuenow={workout.moonIllumination.fraction * 100}
													aria-valuemin='0'
													aria-valuemax='100'>{util.format(workout.moonIllumination.fraction * 100, 0)}%</div>
											</div>
										</>}
									</div>}
								</div>

								{['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
									<div class='row mt-4'>
										<div class='col text-center' style='line-height: 1.0em;'>
											<small class='text-muted'>
												<i class='fas fa-dice ml-2' /> <Text id='workouts.rating'>Workout rating</Text>
											</small>
											<h3>
												{util.format(workout.rating, 0)}
											</h3>
										</div>
										<div class='col text-center' style='line-height: 1.0em;'>
											<small class='text-muted'>
												<i class='fas fa-dumbbell ml-2' /> <Text id='workouts.intensity'>Workout intensity</Text>
											</small>
											<h3>
												{intensity.class && <span class={`badge badge-${intensity.class} ml-2`}>{intensity.name}</span>}
											</h3>
										</div>
									</div>
								</>}

							</div>
						</div>

						{isAdmin && showAdminInfo && <>
							<AdminInfo stores={this.props.stores} />
						</>}

						{/* <div class='col-12'>
							<Ad stores={this.props.stores} url={this.props.url} section={submenu} />
						</div> */}
					</>}

					{submenu !== 'advanced' && <>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
							<LikesAndComments
								stores={this.props.stores}
								likes={likes}
								comments={comments}
								type='workout'
								onTeamClick={this.onTeamClick}
								onUserClick={this.onUserClick}
							/>
						</div>

						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-2 mb-3'>
							<div class='row'>
								<div class='col text-center'>
									<button type='button' class={`btn btn-outline-${hasLikedWorkout ? 'primary' : 'secondary'} btn-sm btn-block ${hasLikedWorkout ? 'text-primary' : ''}`}
										onClick={this.likeWorkout}
										// disabled={hasLikedWorkout}
									>
										<i class={`${hasLikedWorkout ? 'text-primary fas' : 'far'} fa-thumbs-up`} /> <Text id='workouts.like'>Like</Text>
									</button>
								</div>
								<div class='col text-center'>
									<button type='button' class='btn btn-outline-secondary btn-sm btn-block'
										data-id={workout.id}
										onClick={this.toggleAddComment}
									>
										<i class='fas fa-comment' /> <Text id='workouts.comment'>Comment</Text>
									</button>
								</div>
								{workout.private ? <>
									<div class='col text-center'>
										<Text id='workouts.is-private'>Workout is private</Text>
									</div>
								</> : <>
									{hasShareApi ? <>
										<div class='col text-center'>
											<button class='btn btn-outline-secondary btn-sm btn-block text-overflow'
												onClick={this.shareLink}
												data-url={`https://themusher.app/share/workout/${workout.uuidv4}`}
												data-title={`title: ${workout.title}`}
											>
												<i class='fas fa-share' /> <Text id='story.share'>Del</Text>
											</button>
										</div>
									</> : <>
										<div class='col text-center'>
											<a class='btn btn-outline-secondary btn-sm btn-block text-overflow'
												href={`https://themusher.app/share/workout/${workout.uuidv4}`}
												target='_blank'
												rel='noreferrer'
												data-native
											>
												<i class='fas fa-share' /> <Text id='story.share'>Del</Text>
											</a>
										</div>
									</>}
								</>}
							</div>
						</div>

						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-2 mb-3'>
							{showAddComment && <WorkoutAddComment
								stores={this.props.stores}
								workout={workout}
								// callback={this.loadWorkoutDetail}
								inputRef={c => this.inputRef[workout.id] = c}
								inputRefObject={this.inputRef}
							/>}
							<WorkoutComments
								stores={this.props.stores}
								workout={workout}
								// callback={this.loadWorkoutDetail}
							/>

							{views > 0 && <>
								<div class='mt-2'>
									<Views
										stores={this.props.stores}
										views={views}
										object={workout}
										type='workout'
										isTeamAdmin={true}
										isAdmin={false}
										onTeamClick={this.onTeamClick}
										onUserClick={this.onUserClick}
									/>
								</div>
							</>}
						</div>
					</>}

					{workout.isLocked === 1 ? <>

					</> : <>
						{submenu === 'advanced' && <>
							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-5 mt-5 border-top'>
								<h5 class='mt-5'><Text id='workouts.recalculate-title'>Recalculate data</Text></h5>
								<Text id='workouts.recalculate-help'>
									Click the button below if you want to recalculate this workout.<br />
									Please don't overuse this function. If the calculations is not changed after 1 try,
									then it's not going to change the next time either.
								</Text>

								<button class={`btn btn-block btn-warning mt-2 float-left ${recalculated ? 'disabled': ''}`}
									style='font-size: 0.8em;'
									onClick={this.reCalculateGpxData}
								>
									<i class='fas fa-sync' /> <Text id='workouts.recalculate'>Recalculate data</Text>
								</button><br />
							</div>

							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-5 mt-5 border-top'>
								<h5 class='mt-5'><Text id='workouts.duplicate-title'>Duplicate workout</Text></h5>
								<Text id='workouts.duplicate-help'>
									Click the button below if you want to duplicate this workout.
									After you have duplicated this workout you can edit it as a regular workout.
								</Text>
								<button class='btn btn-block btn-secondary mt-2 float-left'
									onClick={this.duplicateWorkout}
								>
									<i class='fas fa-copy' /> <Text id='workouts.duplicate'>Duplicate workout</Text>
								</button><br />
							</div>

							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-5 mt-5 border-top'>
								<h5 class='mt-5'><Text id='workouts.make-track-title'>Make track</Text></h5>
								<Text id='workouts.make-track-help'>
									Use this workout to make a track. This track can shared and downloaded as a regular track.
								</Text>
								<button class='btn btn-block btn-info mt-2 float-left'
									onClick={this.makeTrack}
								>
									<i class='fas fa-map-marked-alt' /> <Text id='workouts.make-track'>Make track</Text>
								</button>
							</div>

							<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-5 border-top'>
								<h5 class='mt-5'><Text id='workouts.delete-title'>Delete workout</Text></h5>
								<Text id='workouts.delete-help'>
									Click the button below to delete this workout. All data related to this workout will be lost. All team and dog statistics will also be affected.
								</Text>
								<button class='btn btn-block btn-sm btn-danger mt-2 float-right'
									onClick={this.deleteWorkout}
								>
									<i class='fas fa-trash' /> <Text id='workouts.delete'>Delete</Text> {workout.name}
								</button>
							</div>
						</>}
					</>}

				</div>
			</div>
		</>);
	}
}

export default WorkoutDetail;
