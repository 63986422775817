import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer, withText } from 'preact-i18n';

import Gauge from '../gauge';
import Field from '../../components/util/field';

import mu from '../../lib/musher-util';

// const STOP_STATUSES = [2, 9];
// const WARN_STATUSES = [4, 8];

function getFatigueLevel(wo, dog) {
    return 'secondary';
    // if (dog) {
    //     if (STOP_STATUSES.indexOf(dog.status) > -1) {
    //         return 'danger';
    //     } else if (WARN_STATUSES.indexOf(dog.status) > -1) {
    //         return 'warning';
    //     }
    // }
    // if (wo) {
    //     if (wo.distanceKm > 50) {
    //         return 'danger';
    //     } else if (wo.distanceKm > 30) {
    //         return 'warning';
    //     }
    // }
    // return 'success';
}

@observer
class Dog extends Component {
    render() {
        const { dog, showWorkout, showInfo, hideTeam = false, fullWidth = false, daysBack = 7, filterBy = '' } = this.props;
		const { userStore, dogStore, workoutPlanStore, appState } = this.props.stores;
        const { darkmode } = appState;
        if (!dog) {
            return '';
        }
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;

        const dogStatus = userStore.findDogstatus({ status: dog.status, language });
        const dogStatuses = dog.statuses ? dog.statuses.map(e => userStore.findDogstatus({ status: e, language })) : [];

        const dogPosition = userStore.findDogposition({ position: dog.position, language });

        const workoutSummary = dogStore.findCurrentWorkoutSummary(dog.id) || {};

        const currentWeek = util.getWeek(new Date());
		const currentYear = util.getYear();
        const currentMonth = new Date().getMonth() + 1;
        const seasonStart = parseInt(currentMonth < 8 ? currentYear - 1 : currentYear, 10);
		const { week = currentWeek, year = seasonStart } = this.props;
		const planWeek = workoutPlanStore.sumWorkoutPlanDaysBack(daysBack) || 0;
        const completedWeek = util.format(workoutSummary.distanceKm || 0, 0);
        const hasBirthday = mu.hasBirthday(dog.birth);

        let hasWorkoutPlan = false;
        let percentComplete = 0;
        if (completedWeek && planWeek) {
            percentComplete = completedWeek / planWeek * 100;
            hasWorkoutPlan = true;
        }
        return (
            <div class={`${fullWidth ? '' : 'col-4'} mb-3 ${hasWorkoutPlan ? 'mt-2' : ''} position-relative`}>
                {/* {JSON.stringify(dog, null, 4)} */}
                <Field value={dog.name} darkmode={darkmode} width={100} height={120} viewBox={'0 0 100 120'} type='dogSimple'>
                    <div
                        class={`text-center border border-${getFatigueLevel(workoutSummary, dog)} rounded-circle imageRounded mx-auto text-muted pt-2 position-relative`}
                        style={`${dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''} border-width: 2px !important;`}
                    >
                        {/* {!dog.image && <i class='fas fa-dog mt-3' style='font-size: 40px;' />} */}
                        {!dog.image && <span class='font-weight-lighter' style='font-size: 35px;'>{util.ucfirst(dog.name, true)}</span>}
                        {hasWorkoutPlan && <div class='position-absolute' style='top: -12px; left: -12px;'>
                            <Gauge key={`gauge-${percentComplete}`}
                                radius={48}
                                heightAdd={15}
                                showPercentText={false}
                                percent={percentComplete}
                                prefix='%'
                                total={`${planWeek} km`}
                                totalY='95%'
                                completed={`${completedWeek} km`}
                                completedY='85%'
                            />
                        </div>}
                    </div>
                    <div class={`${hasWorkoutPlan ? 'mt-4' : ''}`} style='line-height: 1.0em;'>
                        <small>
                            <span class='float-right'>
                                {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                            </span>
                            <a class='stretched-link' href={`/dogs/${dog.id}`}>
                                <h5 class='mb-1 font-weight-light' style='line-height: 1.0em; font-size: 1.3em;'>
                                    {dog.shortname || dog.name}
                                    {hasBirthday && <>
                                        <i class='fas fa-birthday-cake ml-3' />
                                    </>}
                                </h5>
                            </a>
                            {!hideTeam && <><span class='font-weight-light'>{userStore.findTeam(dog.team)}</span><br /></>}
                        </small>

                            {filterBy && dog.positions && dog.positions.lead && <div class=''>
                                {filterBy === 'leadOnly' && dog.positions.lead.distanceKm > 0 && <span class='badge badge-success mr-2'>
                                    <span><Text id='dogs.sort-lead'>Lead</Text>:</span> {util.format(dog.positions.lead.distanceKm, 0)} km
                                </span>}
                                {filterBy === 'pointOnly' && dog.positions.point.distanceKm > 0 && <span class='badge badge-success mr-2'>
                                    <span><Text id='dogs.sort-point'>Point</Text>:</span> {util.format(dog.positions.point.distanceKm, 0)} km
                                </span>}
                                {filterBy === 'teamOnly' && dog.positions.team.distanceKm > 0 && <span class='badge badge-success mr-2'>
                                    <span><Text id='dogs.sort-team'>Team</Text>:</span> {util.format(dog.positions.team.distanceKm, 0)} km
                                </span>}
                                {filterBy === 'wheelOnly' && dog.positions.wheel.distanceKm > 0 && <span class='badge badge-success mr-2'>
                                    <span><Text id='dogs.sort-wheel'>Wheel</Text>:</span> {util.format(dog.positions.wheel.distanceKm, 0)} km
                                </span>}
                            </div>}

                        <small>
                            {showWorkout && <small class='text-muted'>
                                {workoutSummary && workoutSummary.distanceKm > 0 && <span class='mr-2'><i class='fas fa-road' /> {util.format(workoutSummary.distanceKm, 0)}km</span>}
                                {workoutSummary && workoutSummary.elevation > 0 && <span class='mr-2'><i class='fas fa-mountain' /> {util.format(workoutSummary.elevation, 0)}m</span>}
                                {workoutSummary && workoutSummary.duration > 0 && <span class='mr-2'><i class='fas fa-clock' /> {util.secToHms(workoutSummary.duration, true)}</span>}
                                {workoutSummary && workoutSummary.speedAvg > 0 && <span class='mr-2'><i class='fas fa-tachometer-alt' /> {util.format(workoutSummary.speedAvg, 1)}km/t</span>}
                            </small>}

                            {showInfo && <small class='text-muted'>
                                {dog.weight && <><i class='fas fa-balance-scale ml-2' /> {util.format(dog.weight, 1)} kg</>}
                                {dog.bodyScore && <><i class='fas fa-dog ml-2' /> {util.format(dog.bodyScore, 1)}</>}
                            </small>}

                            <div class='clearfix pb-0 pt-1'>
                                {dogPosition && dogPosition.class && <span class={`badge badge-${dogPosition.class || 'primary'} mr-2`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>}
                                {dog.specialNeeds && <span class={`badge badge-warning mr-2`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                {dogStatus && dogStatus.class && <span class={`badge badge-${dogStatus.class} mr-2 mt-1`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                                {dogStatuses && dogStatuses.map(status => <>
                                    {status && status.class && <span class={`badge badge-${status.class} mr-2 mt-1`}>{status.icon && <i class={status.icon} />} {status.name}</span>}
                                </>)}
                            </div>
                        </small>
                    </div>
                </Field>
            </div>
        );
    }
}

export default Dog;
