import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import Modal from '../modal';
import ImageSlider from '../imageSlider';
import ImageScroller from '../imageScroller';


import style from '../style.css';

const VACCINE_TYPES = mu.getVaccineTypes();

@observer
class DogHistoryLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDate: false,
        };
    }

    showModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const img = e.target.dataset.image;
        const imgXXL = e.target.dataset.imagexxl;
        const thumb = e.target.dataset.thumb;
        const name = e.target.dataset.name;
        const title = e.target.dataset.title;
        const date = e.target.dataset.date;
        this.setState({
            displayModal: true,
            currentImage: img,
            currentImageXXL: imgXXL,
            thumb,
            name,
            title,
            date,
        });
    }

    closeModal = () => {
        this.setState({
            displayModal: false,
        });
    }

    toggleDate = () => {
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

    touchStart = (e) => {
        const { eventFired } = this.state;
        if (!eventFired)  {
            this.setState({
                startY: e.touches[0].pageY,
                startX: e.touches[0].pageX,
                eventFired: false,
                diffY: undefined,
                diffX: undefined,
                deleteVerify: false,
            });
        }
    }

    touchEnd = () => {
        const { eventFired } = this.state;
        if (eventFired) {
            this.setState({
                startY: undefined,
                startX: undefined,
            });
        } else {
            this.setState({
                startY: undefined,
                startX: undefined,
                eventFired: false,
                diffY: undefined,
                diffX: undefined,
                deleteVerify: false,
            });
        }
    }

    touchMove = (e) => {
        const { eventFired } = this.state;
        if (eventFired) {
            return false;
        }
        const { startY, startX } = this.state;
        const scrollDistance = 130;
        const y = e.touches[0].pageY;
        const x = e.touches[0].pageX;
        const diffY = y - startY;
        const diffX = x - startX;
        this.setState({
            diffY,
            diffX,
        });
        if (y > (startY + scrollDistance)) {
            // console.log('swiping down', y, '>', startY + scrollDistance);
        } else if (y < (startY - scrollDistance)) {
            // console.log('swiping up', y, '<', startY + scrollDistance);
        } else if (x > (startX + scrollDistance)) {
            // console.log('swiping right', startX, x);
        } else if (x < (startX - scrollDistance)) {
            this.setState({
                eventFired: true,
            });
        }
    }

    reset = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({
            deleted: false,
            startY: undefined,
            startX: undefined,
            eventFired: false,
            diffY: undefined,
            diffX: undefined,
            deleteVerify: false,
        });
    }

    deleteHistoryRequest = async (e) => {
        e.stopPropagation();
        this.setState({
            deleteVerify: true,
        });
    }

    deleteHistory = async (e) => {
        e.stopPropagation();
        const { dogStore } = this.props.stores;
        const { line } = this.props;
        await dogStore.save({
			id: line.dogId,
			$pull: {
                history: { _id: line.id || line._id },
			},
		});
        this.setState({
            deleted: true,
            startY: undefined,
            startX: undefined,
            eventFired: false,
            diffY: undefined,
            diffX: undefined,
            deleteVerify: false,
        });
    }

    swipeLeft = (e) => {
        const { eventFired } = this.state;
        e.stopPropagation();
        if (eventFired) {
            this.reset();
        } else {
            this.setState({
                eventFired: true,
                diffX: -130,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.line.id !== this.props.line.id) {
            this.reset();
        }
    }

    render() {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en' } = user;
        const HISTORY_TYPE_MAP = mu.getHistoryMap(language);

        const { displayModal, currentImage, currentImageXXL, showDate, thumb, name, title, date } = this.state;
        const { line } = this.props;
        const { diffX, deleted, deleteVerify } = this.state;
        const hasImage = line.images && line.images.length > 0;
        const isBeeingEdited = diffX < 0;
        return (
            <div class={`w-100 mb-2 position-relative ${style.hoverElement}`}
                onTouchstart={deleted ? () => {} : this.touchStart}
                onTouchend={deleted ? () => {} : this.touchEnd}
                onTouchmove={deleted ? () => {} : this.touchMove}
                onClick={!deleted && isBeeingEdited ? this.reset : () => {}}
                style={`opacity: ${deleted ? 0.3 : 1};`}
            >
                {!isBeeingEdited && !deleted && <div class={`position-absolute ${style.hoverelementonly}`} style='top: 0px; right: 0px; z-index: 1000;'>
                    <button class='btn btn-sm btn-secondary' type='button'
                        onClick={this.swipeLeft}
                    >
                        <i class='fas fa-ellipsis-h' />
                    </button>
                </div>}
                {isBeeingEdited && !deleted && <div class='position-absolute' style={`right: 0px; top: 10%; overflow: hidden; width: ${Math.abs(diffX) || 0}px; line-height: 0.8em;`}>
                    {!line.grouped && <>
                        {deleteVerify ?
                            <>
                                <button class='btn btn-sm btn-secondary float-right ml-3 mb-3'
                                    onClick={this.reset}
                                >
                                    <i class='fas fa-times' /> <Text id='dog.history.no'>Nei</Text>
                                </button>
                                <button class='btn btn-sm btn-success float-right'
                                    onClick={this.deleteHistory}
                                >
                                    <i class='fas fa-check' /> <Text id='dog.history.yes'>Ja</Text>
                                </button>
                            </>:
                            <button class='btn btn-sm btn-danger float-right'
                                onClick={this.deleteHistoryRequest}
                            >
                                <i class='fas fa-trash' /> <Text id='dog.history.delete'>Slett</Text>
                            </button>
                        }
                    </>}
                    {line.grouped && <small>
                        <Text id='dog.history.cant-delete-groups'>Kan ikke slette grupperinger. Gå inn på hundene og slett der.</Text><br />
                        <button class='btn btn-sm btn-success float-right'
                            onClick={this.reset}
                        >
                            <i class='fas fa-check' /> Ok
                        </button>
                    </small>}
                </div>}
                <div class='row position-relative' style={`left: ${isBeeingEdited ? diffX : 0}px;`}>
                    <div class='col-12 d-flex mb-0 position-relative'>
                        {/* {JSON.stringify(dog, null, 4)} */}
                        {line.dogImage ? <div class={`text-center border rounded-circle imageRounded imageRoundedMedium`} style={line.dogImage ? `background-image: url("${line.dogImage.s3SmallLink}"); background-size: cover;` : ''}>
                            &nbsp;
                        </div> : <div class={`text-center border rounded-circle imageRounded imageRoundedMedium`} style={line.images && line.images[0] ? `background-image: url("${line.images[0].s3SmallLink}"); background-size: cover;` : ''}>
                            {!hasImage && <i class='fas fa-rss text-muted mt-2' style='font-size: 20px;' />}
                        </div>}
                        <div class='flex-grow-1 pl-3 pr-2' style='line-height: 1.2em;'>
                            {!line.grouped && line.dogId && <a class='stretched-link' href={`/dogs/${line.dogId}`}>
                                <strong class='mr-2'>{line.dogShortname || line.dogName}: </strong>
                            </a>}
                            {line.title}<br />

                            {line.grouped && (
                                <>
                                    <div class='w-100 clearfix mt-2 d-flex flex-wrap'>
                                        {line.grouped.map(el => (
                                            <div class='px-2 pb-2'>
                                                <a href={`/dogs/${el.dogId}`}>
                                                    {el.dogImage ? (
                                                        <div class={`mx-auto text-center border rounded-circle imageRounded imageRoundedSmall`}
                                                            style={`margin-right: 2px; ${el.dogImage ? `background-image: url("${el.dogImage.s3SmallLink}");` : ''}`}
                                                        >
                                                            &nbsp;
                                                        </div>
                                                    ) : (
                                                        <div class={`mx-auto text-center border rounded-circle imageRounded imageRoundedSmall text-muted pt-1`}
                                                            style={`margin-right: 2px; ${el.images && el.images[0] ? `background-image: url("${el.images[0].s3SmallLink}");` : ''}`}
                                                        >
                                                            {/* {!hasImage && <i class='fas fa-dog text-muted' style='font-size: 10px;' />} */}
                                                            {!hasImage && <span style='font-size: 15px;'>{util.ucfirst(el.dogName, true)}</span>}
                                                        </div>
                                                    )}
                                                    {el.dogShortname || el.dogName}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div class='w-100 clearfix mt-2'>
                                        {line.grouped.map(el => (
                                            <span class='float-left mr-2 text-muted'>
                                                <a href={`/dogs/${el.dogId}`}>
                                                    {el.dogShortname || el.dogName}
                                                </a>
                                            </span>
                                        ))}
                                    </div> */}
                                </>
                            )}

                            <div class='w-100 clearfix'>
                                <small class='text-muted'>
                                    {line.date && <span onClick={this.toggleDate}>
                                        {showDate ? util.formatDate(line.date, { locale: 'no-NB' }) : util.formatDistance(line.date, new Date(), { locale: 'no-NB' })}
                                        {line.endDate && <span class='ml-3'><i class='fas fa-hourglass-end' /> {util.formatDate(line.endDate, { locale: 'no-NB' })}</span>}
                                        {line.historyType && <span class='ml-3'>
                                            <i class='fas fa-folder-open' /> {HISTORY_TYPE_MAP[line.historyType]} {line.vaccineType ? ` - ${VACCINE_TYPES[line.vaccineType].name}` : ''}
                                        </span>}
                                        {line.weight && <span class='ml-3'><i class='fas fa-balance-scale' /> {line.weight} kg</span>}
                                        {line.bodyScore && <span class='ml-3'><i class='fas fa-dog' /> {line.bodyScore}</span>}
                                        {line.user && line.user.email && <span class='ml-3'><i class='fas fa-user' /> {line.user.email}</span>}
                                    </span>}
                                </small>
                            </div>

                            <div class='row mt-2'>
                                <div class='col-12' style='line-height: 1.3em;'>
                                    <span class='font-weight-normal'>{line.comment}</span>
                                    <span class='float-right'>
                                        {line.cost ? `Kr. ${util.format(line.cost, 2)}` : ''}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class='col-12 mt-1'>
                        <div class='row'>
                            <ImageScroller stores={this.props.stores} images={line.images} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DogHistoryLine;
