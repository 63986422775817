import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import mu from '../lib/musher-util';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

function getDiffInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
}

function getDateOfISOWeek(y, w) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
}

function sortByX(a, b) {
    if (a.x < b.x) {
        return -1;
    }
    if (a.x > b.x) {
        return 1;
    }
    return 0;
}

function kmtToMph(value) {
    return 0.621371 * value;
}

function kmToMiles(value) {
    return 0.621371 * value;
}

function meterToFeet(value) {
    return 3.28084 * value;
}

class UserStore extends StoreModel {
    constructor() {
        super('user', {
            namePlural: 'users',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/users/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/users/',
                    params: {},
                },
                save: {
                    url: '/api/users/',
                    params: {},
                },
            },
        });
    }

    @observable getInfoEpoch = 0;

    @observable isLoading = {};

    @observable user = {};

    @observable currentEmail = '';

    @observable simulatedEmail = '';

    @observable isAdmin = false;

    @observable isVeterinary = false;

    @observable users = [];

    @observable team = {};

    @observable teams = [];

    @observable dogs = [];

    @observable mushers = [];

    @observable workouts = [];

    @observable tracks = [];

    @observable types = [];

    @observable intensities = [];

    @observable dogPositions = [];

    @observable dogstatuses = [];

    @observable equipments = [];

    @observable harnesses = [];

    @observable teamAmbitions = [];

    @observable teamTypes = [];

    @observable countries = [];

    @observable searchUsersResult = [];

    @observable searchUserTeams = [];

    @observable vaccineStatuses = [];

    @observable vaccineLogStatuses = [];

    @observable allUsers = [];

    @observable subscribedRaces = [];

    @observable subscribedTeams = [];

    @observable workoutSummaryTeam = 0;

    @observable workoutSummary = [];

    @observable workoutSummaryType = [];

    @observable workoutSummaryWeek = [];

    @observable workoutSummaryWeekDay = [];

    @observable workoutSummaryDaysCurrentMonth = [];

    @observable workoutSummaryDaysCurrentMonthType = [];

    @observable workoutSummaryDaysCurrentMonthPrevSeason = [];

    @observable workoutSummaryDaysCurrentMonthPrevSeasonType = [];

    @observable workoutSummaryDays500Type = [];

    @observable workoutSummaryDay = [];

    @observable graphworkoutSummaryDaysCurrentMonth = [];

    @observable workoutSummaryDaysCurrentMonthPrevMonth = [];

    @observable workoutSummaryDaysCurrentMonthPrevMonthType = [];

    @observable graphworkoutSummaryDaysCurrentMonthPrevSeason = [];

    @observable graphWorkoutSummaryWeekDay = [];

    @observable workoutCurrentWeek = [];

    @observable workoutCurrentSeason = [];

    @observable workoutPrevSeason = [];

    @observable seasonDaysSoFar = [];

    @observable seasonWeeksSoFar = [];

    @observable prevSeasonWeeksSoFar = [];

    @observable workoutSeasonTotal = [];

    @observable workoutDistinctTypes = [];

    @observable workoutSeasonEquipment = [];

    @observable workoutsDay = [];

    @observable workoutSummaryMonth = [];

    @observable workoutSummaryAvg = [];

    @observable avgWorkoutStats = [];

    @observable graphWorkoutSummaryWeekDistanceCurrent = [];

    @observable graphWorkoutSummaryWeekDistanceCurrentTotal = 0;

    @observable graphWorkoutSummaryWeekDistancePrev = [];

    @observable graphWorkoutSummaryWeekElevationCurrent = [];

    @observable graphWorkoutSummaryWeekElevationPrev = [];

    @observable graphWorkoutSummaryWeekDurationCurrent = [];

    @observable graphWorkoutSummaryWeekDurationPrev = [];

    @observable graphWorkoutSummaryWeekLoadIndexCurrent = [];

    @observable graphWorkoutSummaryWeekLoadIndexLastPeriode = [];

    @observable graphWorkoutSummaryWeekLoadIndexPrev = [];

    @observable workoutSummaryWeekAvgLoadLastPeriode = 0;

    @observable graphWorkoutSummaryMonthDistanceCurrent = [];

    @observable graphWorkoutSummaryMonthDistancePrev = [];

    @observable graphWorkoutSummaryMonthDurationCurrent = [];

    @observable graphWorkoutSummaryMonthDurationPrev = [];

    @observable graphWorkoutSummaryMonthElevationCurrent = [];

    @observable graphWorkoutSummaryMonthElevationPrev = [];

    @observable graphWorkoutAvgWeekDistanceCurrent = [];

    @observable graphWorkoutAvgWeekElevationCurrent = [];

    @observable graphWorkoutAvgWeekSpeedCurrent = [];

    @observable graphWorkoutAvgWeekDurationCurrent = [];

    @observable graphWorkoutAvgWeekRealDurationCurrent = [];

    @observable graphWorkoutAvgWeekLoadIndexCurrent = [];

    @observable graphAvgWorkoutStatsDistance = [];

    @observable graphAvgWorkoutStatsElevation = [];

    @observable graphAvgWorkoutStatsSpeed = [];

    @observable graphAvgWorkoutStatsDuration = [];

    @observable graphAvgWorkoutStatsRealDuration = [];

    @observable graphAvgWorkoutStatsLoadIndex = [];

    @observable graphAvgWorkoutStatsCount = [];

    @observable historyFeed = [];

    @observable notifications = [];

    @observable emailIsAvailable = false;

    @observable twoFactorLogin = false;

    @observable emailMessage = '';

    @observable emailMessageIcon = '';

    @observable emailError = '';

    @observable emailErrorIcon = '';

    @observable subscriptionPaypal = {};

    @observable yrWeather = {};

    @observable yrWeatherFormatted = {};

    @observable yrWeatherKeys = [];

    @observable favorites = [];

    // Cordova variables
    bgGeo = null;

    @observable cordovaAuthorizationStatus = null;

    @observable powerSaveStatus = false;

    @observable providerState = false;

    @observable register = {
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable login = {
        showToken: false,
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable loginLink = {
        emailSent: false,
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable currentLocation = null;

    @observable loadMore = () => {};

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('UserStore.cleanupMemory');
        }
        this.localUpdateField('searchUsersResult', []);
        this.localUpdateField('searchUserTeams', []);
        this.localUpdateField('vaccineStatuses', []);
        this.localUpdateField('vaccineLogStatuses', []);
        this.localUpdateField('workoutSummaryWeekDay', []);
        this.localUpdateField('workoutSummaryDay', []);
        this.localUpdateField('workoutsDay', []);
        this.localUpdateField('workoutSummaryMonth', []);
        this.localUpdateField('workoutSummaryAvg', []);
        this.localUpdateField('avgWorkoutStats', []);
        this.localUpdateField('graphWorkoutSummaryWeekDistanceCurrent', []);
        this.localUpdateField('graphWorkoutSummaryWeekDistanceCurrentTotal', []);
        this.localUpdateField('graphWorkoutSummaryWeekDistancePrev', []);
        this.localUpdateField('graphWorkoutSummaryWeekElevationCurrent', []);
        this.localUpdateField('graphWorkoutSummaryWeekElevationPrev', []);
        this.localUpdateField('graphWorkoutSummaryWeekDurationCurrent', []);
        this.localUpdateField('graphWorkoutSummaryWeekDurationPrev', []);

        this.localUpdateField('graphWorkoutSummaryMonthDistanceCurrent', []);
        this.localUpdateField('graphWorkoutSummaryMonthDistancePrev', []);
        this.localUpdateField('graphWorkoutSummaryMonthElevationCurrent', []);
        this.localUpdateField('graphWorkoutSummaryMonthElevationPrev', []);
        this.localUpdateField('graphWorkoutSummaryMonthDurationCurrent', []);
        this.localUpdateField('graphWorkoutSummaryMonthDurationPrev', []);

        this.localUpdateField('graphWorkoutAvgWeekDistanceCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekElevationCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekSpeedCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekDurationCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekRealDurationCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekRealDurationCurrent', []);
        this.localUpdateField('graphWorkoutAvgWeekLoadIndexCurrent', []);
        this.localUpdateField('graphAvgWorkoutStatsDistance', []);
        this.localUpdateField('graphAvgWorkoutStatsElevation', []);
        this.localUpdateField('graphAvgWorkoutStatsSpeed', []);
        this.localUpdateField('graphAvgWorkoutStatsDuration', []);
        this.localUpdateField('graphAvgWorkoutStatsRealDuration', []);
        this.localUpdateField('graphAvgWorkoutStatsLoadIndex', []);
        this.localUpdateField('graphAvgWorkoutStatsCount', []);
        this.localUpdateField('historyFeed', []);
        this.localUpdateField('notifications', []);
    }

    @action
    setPowerSaveStatus(status = false) {
        this.powerSaveStatus = status;
    }

    @action
    setProviderState(state = false) {
        this.providerState = state;
    }

    @action
    setGetInfoEpoch(epoch = new Date().getTime()) {
        this.getInfoEpoch = epoch;
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    updateCurrentLocation(location) {
        this.currentLocation = location
    }

    @action
    setSubscriptionPaypal(subscriptionPaypal) {
        this.subscriptionPaypal = subscriptionPaypal
    }

    @action
    setLoading(name, state = false) {
        this.isLoading[name] = state;
        if (!this.isLoading[name]) {
            delete this.isLoading[name];
        }
    }

    @action
    findSubscribedTeam(teamid) {
        const idx = this.subscribedTeams?.findIndex(e => e.id === teamid);
        if (idx > -1) {
            return toJS(this.subscribedTeams[idx]);
        }
    }

    @action
    findSubscribedRace(raceid) {
        const idx = this.subscribedRaces?.findIndex(e => e.id === raceid);
        if (idx > -1) {
            return toJS(this.subscribedRaces[idx]);
        }
    }


    @action
    findTeam(team, asObject = false, teams = []) {
        const idx = this.teams?.findIndex(e => e.id === team);
        if (idx > -1) {
            if (asObject) {
                return toJS(this.teams[idx]);
            }
            return this.teams[idx].name;
        } else if (Array.isArray(teams)) {
            const foundTeams = this.teams?.filter(t => (
                teams?.includes(t.id)
            ));
            if (foundTeams && foundTeams.length > 0) {
                return toJS(foundTeams[0]);
            }
        }
    }



    @action
    getCurrentTeam() {
        const currentTeamId = this.user.activeTeam?.team || this.user.team;
        const currentTeam = this.findTeam(currentTeamId, true, this.user.teams) || {};
        return currentTeam;
    }

    @action
    findTeams(teams = []) {
        const results = this.teams.filter(e => teams?.indexOf(e.id) > -1).map(e => toJS(e));
        return results;
    }

    @action
    findPublicTeams(teams = []) {
        const results = this.teams.filter(e => e.public === 1).map(e => toJS(e));
        return results;
    }

    @action
    findTrack(track) {
        if (!track) {
            return undefined;
        }
        const idx = this.tracks?.findIndex(e => e.id === track);
        if (idx > -1) {
            return toJS(this.tracks[idx]);
        }
    }

    @action
    findTrackName(track) {
        if (!track) {
            return undefined;
        }
        const idx = this.tracks?.findIndex(e => e.id === track);
        if (idx > -1) {
            return this.tracks[idx].name;
        }
    }

    @action
    findHarness(harness, field = 'name') {
        const idx = this.harnesses?.findIndex(e => e.id === harness);
        if (idx > -1) {
            return this.harnesses[idx][field];
        }
    }

    @action
    findEquipment({ equipment, field = 'name', language }) {
        const idx = this.equipments?.findIndex(e => e.id === equipment);
        if (idx > -1) {
            if (field === 'name' && language && this.equipments[idx].lang[language]) {
                return this.equipments[idx].lang[language];
            }
            return this.equipments[idx][field];
        }
    }

    @action
    findDogstatus({ status, language }) {
        const idx = this.dogstatuses?.findIndex(e => e.id === status);
        if (idx > -1) {
            const dogstatus = this.dogstatuses[idx];
            if (dogstatus.id === 1) {
                return {};
            }
            if (language && dogstatus.lang[language]) {
                dogstatus.name = dogstatus.lang[language];
            }
            return dogstatus;
        }
    }

    @action
    findDogposition({ position, language }) {
        const idx = this.dogPositions?.findIndex(e => e.id === position);
        if (idx > -1) {
            const dogPosition = this.dogPositions[idx];
            if (language && dogPosition.lang[language]) {
                dogPosition.name = dogPosition.lang[language];
            }
            return dogPosition;
        }
    }

    @action
    findIntensity({ code, language }) {
        const idx = this.intensities?.findIndex(e => e.id === code);
        if (idx > -1) {
            const intensity = this.intensities[idx];
            if (language && intensity.lang[language]) {
                intensity.name = intensity.lang[language];
            }
            return intensity;
        }
        return {};
    }

    @action
    findTeamAmbition({ ambition, field = 'name', language }) {
        const idx = this.teamAmbitions?.findIndex(e => e.id === ambition);
        if (idx > -1) {
            if (field === 'name' && language && this.teamTypes[idx].lang[language]) {
                return this.teamAmbitions[idx].lang[language];
            }
            return this.teamAmbitions[idx][field];
        }
    }

    @action
    findTeamType({ type, field = 'name', language }) {
        const idx = this.teamTypes?.findIndex(e => e.id === type);
        if (idx > -1) {
            if (field === 'name' && language && this.teamTypes[idx].lang[language]) {
                return this.teamTypes[idx].lang[language];
            }
            return this.teamTypes[idx][field];
        }
    }

    @action
    findWorkoutType({ type, field = 'name', language }) {
        const idx = this.types?.findIndex(e => e.id === type);
        if (idx > -1) {
            if (field === 'name' && language && this.types[idx].lang[language]) {
                return this.types[idx].lang[language];
            }
            return this.types[idx][field];
        }
    }

    @action
    findWorkoutSummaryWeek(year, week, field = 'distanceKm') {
        const idx = this.workoutSummaryWeek?.findIndex(e => e.year === year && e.week === week);
        if (idx > -1) {
            if (field === 'all') {
                return this.workoutSummaryWeek[idx];
            }
            return util.format(this.workoutSummaryWeek[idx][field], 0);
        }
    }

    @computed
    get totalWorkoutKm() {
        const distanceKmReducer = (acc, cur) => acc + cur.distanceKm;
        return this.workoutSummaryWeek.reduce(distanceKmReducer, 0)
    }

    @computed
    get totalWorkoutKmDay() {
        const distanceKmReducer = (acc, cur) => acc + cur.distanceKm;
        return this.workoutSummaryWeekDay.reduce(distanceKmReducer, 0)
    }

    @action
    findWorkoutSummaryWeekDay(year, month, day, currentMonth) {
        // const idx = this.workoutSummaryWeekDay?.findIndex(e => e.year === year
        const idx = this.workoutSummaryWeekDay?.findIndex(e => e.year === year
            && e.month === month && e.day === day
            && (currentMonth ? e.month === currentMonth : true)
        );
        if (idx > -1) {
            // return toJS(this.workoutSummaryWeekDay[idx]);
            return toJS(this.workoutSummaryWeekDay[idx]);
        }
    }

    @action
    findHistory(year, month, day) {
        const histories = this.historyFeed.filter(e => e.year === year && e.month === month && e.day === day).map(e => toJS(e));
        return histories;
    }

    @action
    findEndHistory(year, month, day) {
        const histories = this.historyFeed.filter(e => e.endYear === year && e.endMonth === month && e.endDay === day).map(e => toJS(e));
        return histories;
    }

    @action
    findDogsFromHistory(historyType) {
        const dogIds = [];
        const histories = this.historyFeed.filter(e => e.historyType === historyType).map(e => toJS(e));
        for (let i = 0, l = histories.length; i < l; i += 1) {
            const hist = histories[i];
            dogIds.push(hist.dogId);
            if (hist.grouped) {
                const dIds = hist.grouped.map(e => e.dogId);
                dogIds.push(...dIds);
            }
        }
        return dogIds;
    }

    @action
    findWorkoutSummaryMonth(year, month, field = 'distanceKm', skipFormat = true) {
        if (this.workoutSummaryMonth) {
            const idx = this.workoutSummaryMonth?.findIndex(e => e.year === year &&  e.month === month);
            if (idx > -1) {
                if (field === 'all') {
                    return this.workoutSummaryMonth[idx];
                }
                if (skipFormat) {
                    return this.workoutSummaryMonth[idx][field];
                }
                return util.format(this.workoutSummaryMonth[idx][field], 0, '.', ' ', true);
            }
        }
    }

    @action
    findDogs(dogIds = []) {
        const dogs = this.dogs.filter(e => dogIds?.indexOf(e.id) > -1);
        return dogs;
    }

    @action
    findDog(dogId) {
        if (Array.isArray(this.dogs)) {
            const idx = this.dogs?.findIndex(e => e.id === dogId);
            const dog = this.dogs[idx];
            return dog;
        }
        return null;
    }

    @action
    findDogsByTeam(teamId) {
        if (Array.isArray(this.dogs)) {
            const dogs = this.dogs.filter((e) => {
                if (e.team === teamId) {
                    return true;
                }
                if (e.teams && e.teams?.indexOf(teamId) > -1) {
                    return true;
                }
                return false;
            });
            return dogs;
        }
        return null;
    }

    @action
    findMushers(musherIds = []) {
        const mushers = this.mushers.filter(e => musherIds?.indexOf(e.id) > -1);
        return mushers;
    }

    @action
    findMusher(musherId) {
        if (Array.isArray(this.mushers)) {
            const idx = this.mushers?.findIndex(e => e.id === musherId);
            const musher = this.mushers[idx];
            return musher;
        }
        return null;
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    updateUserField(key, value) {
        this.user[key] = value;
    }

    @action
    updateUserSubValue(key, value) {
        const keyParts = key.split('.');
        // console.log({ key, value, keyParts });
        if (!this.user[keyParts[0]]) {
            this.user[keyParts[0]] = {};
        }
        this.user[keyParts[0]][keyParts[1]] = value;
    }

    @action
    updateObjectField(object, key, value) {
        this[object][key] = value;
    }

    @action
    resetRegisterMessages() {
        this.register = {
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    @action
    resetLoginMessages() {
        this.login = {
            showToken: false,
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    @action
    resetLoginLinkMessages() {
        this.loginLink = {
            emailSent: this.loginLink.emailSent,
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    async registerUser({ email, password, fingerprint, jwtToken, skipPubSub = false, language = 'en' }) {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const registerResponse = await util.fetchApi('/api/users/register', { publish: true, method: 'POST' }, { email, password, fingerprint, jwtToken, language });
            switch (registerResponse.status) {
                case 201:
                    this.localUpdateField('user', registerResponse.data.user);
                    this.localUpdateField('emailIsAvailable', true);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'Du er nå registrert 😃');
                    } else {
                        this.localUpdateField('emailMessage', 'You are now registered 😃');
                    }
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(registerResponse.data.apiToken);
                    util.setUserEmail(registerResponse.data.user.email);
                    if (!skipPubSub) {
                        PubSub.publish(topics.JWT_TOKEN_CHANGED, registerResponse.data.apiToken);
                    }
                    break;
                case 401:
                    this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('register', 'error', `Registreringen feilet! ${registerResponse.status}: ${registerResponse.message}`);
                    } else {
                        this.updateObjectField('register', 'error', `Registration failed! ${registerResponse.status}: ${registerResponse.message}`);
                    }
                    break;
                default:
                    this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('register', 'error', `Registreringen feilet! ${registerResponse.status}: ${registerResponse.message}`);
                    } else {
                        this.updateObjectField('register', 'error', `Registration failed! ${registerResponse.status}: ${registerResponse.message}`);
                    }
                    break;
            }
        }
    }

    async verifyEmail({ token, language = 'en' }) {
        const verifyResponse = await util.fetchApi('/api/users/verify/email/', { publish: true, method: 'POST' }, { token, language });
        switch (verifyResponse.status) {
            case 200:
                this.localUpdateField('emailMessageIcon', 'fas fa-user-check');
                if (language === 'no') {
                    this.localUpdateField('emailMessage', 'E-postadressen er nå verifisert 😃');
                } else {
                    this.localUpdateField('emailMessage', 'Email address is verified 😃');
                }
                break;
            default:
                this.localUpdateField('emailErrorIcon', 'fas fa-bomb');
                if (language === 'no') {
                    this.localUpdateField('emailError', `Verifiseringen feilet! ${verifyResponse.status}: ${verifyResponse.message}`);
                } else {
                    this.localUpdateField('emailError', `Verification failed! ${verifyResponse.status}: ${verifyResponse.message}`);
                }
                break;
        }
    }

    async checkEmail(email, language = 'en') {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const emailCheckResponse = await util.fetchApi('/api/users/check', { publish: true }, { email });
            switch (emailCheckResponse.status) {
                case 200:
                    this.localUpdateField('emailIsAvailable', true);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'E-posten er tilgjengelig 😃');
                    } else {
                        this.localUpdateField('emailMessage', 'Email is available 😃');
                    }
                    break;
                case 403:
                    this.localUpdateField('emailIsAvailable', false);
                    this.localUpdateField('twoFactorLogin', emailCheckResponse.twoFactorLogin);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'E-posten er allerede registert. Forsøk "Glemt passord" på loginsiden.');
                    } else {
                        this.localUpdateField('emailMessage', 'Email is already registered. Please try "Forgotten password".');
                    }
                    break;
                default:
                    this.localUpdateField('emailIsAvailable', false);
                    this.localUpdateField('emailMessage', '');
                    break;
            }
        }
    }

    async checkLogin({ email, password, token, sendPubSubMessage = true, language = 'en' }) {
        const loginResponse = await util.fetchApi('/api/users/login', { method: 'POST', publish: true }, { email, password, token, language });
        switch (loginResponse.status) {
            case 200:
                this.localUpdateField('user', loginResponse.data.user);
                util.setJwtToken(loginResponse.data.apiToken);
                util.setUserEmail(loginResponse.data.user.email);
                if (sendPubSubMessage) {
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                }
                return true;
            case 202:
                if (loginResponse.needsTwoFactor) {
                    if (language === 'no') {
                        this.updateObjectField('login', 'message', '2FA token er påkrevd for å logge inn.');
                    } else {
                        this.updateObjectField('login', 'message', '2FA token is required to login.');
                    }
                    this.updateObjectField('login', 'showToken', true);
                }
                return false;
            case 403:
                if (loginResponse.message === 'Wrong password!') {
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Feil passord! Dersom du har glemt passordet så kan du trykke på "Glemt passord?" helt nederst.`);
                    } else {
                        this.updateObjectField('login', 'error', `Wrong password. If you have forgotten your password, please use "Forgotten password".`);
                    }
                } else {
                    this.updateObjectField('login', 'errorIcon', 'fas fa-search');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Finner ikke denne brukeren, men du er velkommen til å registrere deg 😃 \nTrykk på "Ny bruker" under for å komme igang.`);
                    } else {
                        this.updateObjectField('login', 'error', `Can't find the user. You are welcome to register as a new user 😃`);
                    }
                }
                return false;
            case 422:
                this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                if (language === 'no') {
                    this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                } else {
                    this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                }
                return false;
            default:
                this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                if (language === 'no') {
                    this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                } else {
                    this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                }
                return false;
        }
    }

    async getInfo(loadAllUsers = false, skipSummary = true, skipStaticDataLoad = false, language = 'en', darkmode) {
        let params = {};
        if (util.isObject(loadAllUsers)) {
            params = { ...loadAllUsers };
        }
        const { forceLoad } = params;

        const now = new Date().getTime();
        const reloadTimeMs = 1000 * 300;
        const { getInfoEpoch } = this;
        const epochDiff = now - getInfoEpoch;
        if (!forceLoad && epochDiff < reloadTimeMs) {
            // console.log('Skipping load', { epochDiff, now, getInfoEpoch, reloadTimeMs });
            return false;
        }

        let skipStaticData = skipStaticDataLoad;
        if (this.countries && this.countries.length > 0) {
            skipStaticData = true;
        }
        const infoResponse = await util.fetchApi('/api/users/info', { publish: true, method: 'GET' }, { loadAllUsers, skipSummary, skipStaticData, darkmode });
        // const infoResponse = await util.fetchApi('/api/test/html', { publish: true, method: 'GET' }, { loadAllUsers, skipSummary, skipStaticData, darkmode });
        switch (infoResponse.status) {
            case 200:
                this.setGetInfoEpoch(now);
                this.localUpdateField('user', infoResponse.data.user);
                this.localUpdateField('team', infoResponse.data.team);
                this.localUpdateField('teams', infoResponse.data.teams);
                this.localUpdateField('dogs', infoResponse.data.dogs);
                this.localUpdateField('mushers', infoResponse.data.members);
                this.localUpdateField('workouts', infoResponse.data.workouts);
                this.localUpdateField('tracks', infoResponse.data.tracks);
                this.localUpdateField('subscribedRaces', infoResponse.data.subscribedRaces);
                this.localUpdateField('subscribedTeams', infoResponse.data.subscribedTeams);
                if (!skipSummary) {
                    this.localUpdateField('workoutSummary', infoResponse.data.workoutSummary);
                }
                if (!skipStaticData) {
                    this.localUpdateField('types', infoResponse.data.types);
                    this.localUpdateField('intensities', infoResponse.data.intensities);
                    this.localUpdateField('dogPositions', infoResponse.data.dogPositions);
                    this.localUpdateField('dogstatuses', infoResponse.data.dogstatuses);
                    this.localUpdateField('equipments', infoResponse.data.equipments);
                    this.localUpdateField('harnesses', infoResponse.data.harnesses);
                    // this.localUpdateField('allUsers', infoResponse.data.allUsers);
                    this.localUpdateField('teamAmbitions', infoResponse.data.teamAmbitions);
                    this.localUpdateField('teamTypes', infoResponse.data.teamTypes);
                    this.localUpdateField('countries', infoResponse.data.countries);
                }

                this.localUpdateField('isAdmin', infoResponse.data.isAdmin);
                this.localUpdateField('isEditor', infoResponse.data.isEditor);
                this.localUpdateField('isTester', infoResponse.data.isTester);
                this.localUpdateField('isVeterinary', infoResponse.data.isVeterinary);
                this.localUpdateField('isPremium', infoResponse.data.isPremium);
                this.localUpdateField('currentEmail', infoResponse.data.currentEmail);
                this.localUpdateField('simulatedEmail', infoResponse.data.simulatedEmail);

                if (darkmode) {
                    const { settings = {} } = this.user;
                    if (settings.darkmode !== darkmode) {
                        const field = 'darkmode';
                        await this.updateSetting({ [`settings.${field}`]: settings[field] === 1 ? 0 : 1 }, { [field]: settings[field] === 1 ? 0 : 1 });
                    }
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'errorStatus', infoResponse.status);
                if (language === 'no') {
                    this.updateObjectField('register', 'error', `Henting av brukerinfo feilet! ${infoResponse.status}: ${infoResponse.message}`);
                } else {
                    this.updateObjectField('register', 'error', `Fetching user info failed! ${infoResponse.status}: ${infoResponse.message}`);
                }
                break;
        }
    }

    async cordovaProviderState() {
        this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const providerState = await this.bgGeo.getProviderState();
            this.setProviderState(providerState);
            switch(providerState.status) {
                case this.bgGeo.AUTHORIZATION_STATUS_NOT_DETERMINED:
                    // iOS only
                    this.localUpdateField('cordovaAuthorizationStatus', 'NOT DETERMINED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_RESTRICTED:
                    // iOS only
                    this.localUpdateField('cordovaAuthorizationStatus', 'RESTRICTED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_DENIED:
                    // Android & iOS
                    this.localUpdateField('cordovaAuthorizationStatus', 'DENIED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_ALWAYS:
                    // Android & iOS
                    this.localUpdateField('cordovaAuthorizationStatus', 'ALWAYS');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_WHEN_IN_USE:
                    // iOS only
                    this.localUpdateField('cordovaAuthorizationStatus', 'WHEN_IN_USE');
                    break;
            }
        }
    }

    async cordovaGetCurrentPosition(force) {
        this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            await this.cordovaProviderState();
            if (force || this.cordovaAuthorizationStatus === 'WHEN_IN_USE' || this.cordovaAuthorizationStatus === 'ALWAYS') {
                const location = await this.bgGeo.getCurrentPosition({
                    timeout: 3,              // 30 second timeout to fetch location
                    persist: false,          // Defaults to state.enabled
                    maximumAge: 3600 * 1000, // Accept the last-known-location if not older than 5000 ms.
                    desiredAccuracy: 100,    // Try to fetch a location with an accuracy of `10` meters.
                    samples: 1,              // How many location samples to attempt.
                });
                this.updateCurrentLocation(location);
                await util.fetchApi('/api/users/location', { publish: true, method: 'POST' }, { location });
                return location;
            }
        }
        return null;
    }

    async getWeatherUser({ lat, lon, altitude, force }) {
        if (lat && lon) {
            const result = await util.fetchApi('/api/yr/user', { publish: true, method: 'GET' }, { lat, lon, altitude, force });
            this.updateUserField('yrWeather', result.data);
            return result;
        }
        return null;
    }

    @action
    formatWeather(weather = this.user.yrWeather) {
        this.localUpdateField('yrWeather', weather);
        const formatted = {};
        if (weather) {
            for (let i = 0, l = weather.length; i < l; i += 1) {
                const { time, instant, next_6_hours } = weather[i];
                const nightRegexp = new RegExp('T00:00');
                const morningRegexp = new RegExp('T06:00');
                const dayRegexp = new RegExp('T12:00');
                const eveningRegexp = new RegExp('T18:00');
                const date = util.isoDate(time, false, false, true);
                if (!formatted[date]) {
                    formatted[date] = {
                        air_temperatures: [],
                        wind_speeds: [],
                        precipitation_amount: 0,
                    };
                }
                if (nightRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].night = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (morningRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].morning = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (dayRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].day = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (eveningRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].evening = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
            }
            const keys = Object.keys(formatted);
            this.localUpdateField('yrWeatherKeys', keys);

            for (let i = 0, l = keys.length; i < l; i += 1) {
                    const key = keys[i];
                    const temperatures = formatted[key].air_temperatures;
                    const wind_speeds = formatted[key].wind_speeds;
                    formatted[key].air_temperatures_min = Math.min(...temperatures);
                    formatted[key].air_temperatures_max = Math.max(...temperatures);
                    formatted[key].wind_speeds_min = Math.min(...wind_speeds);
                    formatted[key].wind_speeds_max = Math.max(...wind_speeds);
            }
            this.localUpdateField('yrWeatherFormatted', formatted);
            return weather;
        }
        return null;
    }

    async getFeed({ year, week, month, team, dog, daysBack, language = 'en', limit, offset }) {
        const infoResponse = await util.fetchApi('/api/users/feed', { publish: true, method: 'GET' }, { year, week, month, team, dog, daysBack, limit, offset });
        switch (infoResponse.status) {
            case 200:
                if (offset > 0) {
                    let historyFeed = toJS(this.historyFeed);
                    historyFeed.push(...infoResponse.data.historyFeed);
                    this.localUpdateField('historyFeed', historyFeed);
                } else {
                    this.localUpdateField('historyFeed', infoResponse.data.historyFeed);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                if (language === 'no') {
                    this.updateObjectField('register', 'error', `Henting av feed feilet! ${infoResponse.status}: ${infoResponse.message}`);
                } else {
                    this.updateObjectField('register', 'error', `Fetching the feed failed! ${infoResponse.status}: ${infoResponse.message}`);
                }
                break;
        }
    }

    @action
    getWorkoutsInWeek(year, week, month) {
        const daysInCurrentWeek = util.getDaysInWeek(week, year, month);
        const workoutsInWeek = [];
        daysInCurrentWeek.forEach(date => {
            const yyyy = date.getFullYear();
            const mm = date.getMonth() + 1;
            const dd = date.getDate();
            const day = this.workoutSummaryDay.find(d => d.year === yyyy && d.month === mm && d.day === dd);
            workoutsInWeek.push(day || {
                year: yyyy,
                month: mm,
                day: dd,
                distanceKm: 0,
                elevation: 0,
                duration: 0,
                speed_avg: 0,
                loadIndex: 0,
                rest: 0,
                rating: 0,
                temperature: 0,
                count: 0,
            });
        });
        return workoutsInWeek;
    }

    async getWorkoutSummary({ team, dog, track, daysBack, year, month, type, keyname }) {
        const currentWeek = util.getWeek(new Date());
        const currentMonth = parseInt(month || new Date().getMonth() + 1, 10);
        const currentYear = parseInt(year || util.getYear(), 10);
        const currentDay = new Date().getDate();
        const seasonStart = parseInt(currentMonth < 8 ? currentYear - 1 : currentYear, 10);
        const prevYear = seasonStart - 1;
        const today = util.isoDate(new Date(), false, false, true);
        const daysInCurrentWeek = util.getDaysInWeek(currentWeek, currentYear, currentMonth);

        // const summaryMonth = workoutPlanStore.findWorkoutPlanSummaryMonth(year, monthNum) || {};
        // const seasonDaysToDate = util.dayRange(`${seasonStart}-08-01`, `${today}`);
        const seasonDays = util.dayRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        const seasonWeeks = util.weekRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        const seasonMonths = util.monthRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        const prevSeasonWeeks = util.weekRange(`${prevYear}-08-01`, `${prevYear + 1}-07-31`);
        const prevSeasonMonths = util.monthRange(`${prevYear}-08-01`, `${prevYear + 1}-07-31`);

        const seasonDaysSoFar = util.dayRange(`${seasonStart}-08-01`, `${today}`);
        const seasonWeeksSoFar = util.weekRange(`${seasonStart}-08-01`, `${today}`);
        const seasonMonthsSoFar = util.monthRange(`${seasonStart}-08-01`, `${today}`);

        const prevSeasonDaysSoFar = util.dayRange(`${prevYear}-08-01`, `${prevYear + 1}-${util.padDate(currentMonth)}-${util.padDate(currentDay)}`);
        const prevSeasonWeeksSoFar = util.weekRange(`${prevYear}-08-01`, `${prevYear + 1}-${util.padDate(currentMonth)}-${util.padDate(currentDay)}`);
        const prevSeasonMonthsSoFar = util.monthRange(`${prevYear}-08-01`, `${prevYear + 1}-${util.padDate(currentMonth)}-${util.padDate(currentDay)}`);

        const date3MonthsAgo = new Date();
        const MONTHS_IN_TRAINING_PERIODE = 2;
        date3MonthsAgo.setMonth(date3MonthsAgo.getMonth() - MONTHS_IN_TRAINING_PERIODE);
        const lastPeriodeWeeks = util.weekRange(
            util.isoDate(date3MonthsAgo, false, false, true),
            `${today}`,
        );
        let graphWorkoutSummaryWeekDistanceCurrentTotal = 0;

        const { settings = {} } = this.user;
        const { imperial } = settings;
        // console.log({ settings, imperial });

        const avgDayDistance = [];
        const avgDayElevation = [];
        const avgDayDuration = [];
        const avgDaySpeedAvg = [];
        const avgDayLoadIndex = [];
        const avgDayAvgDogs = [];
        const avgDayAvgImages = [];
        const avgDaySledWeight = [];
        const avgDayRest = [];
        const avgDayRating = [];
        const avgDayTemperature = [];
        const todayEpoch = new Date().getTime();

        const infoResponse = await util.fetchApi('/api/workouts/summary', { publish: true, method: 'GET' }, {
            team,
            dog,
            track,
            daysBack,
            year,
            seasonStart: `${seasonStart}-08-01`,
            type,
        });
        switch (infoResponse.status) {
            case 200:
                this.localUpdateField('workoutSummaryTeam', team);
                this.localUpdateField('workoutSummaryWeek', infoResponse.data);
                this.localUpdateField('workoutSummaryMonth', infoResponse.included.workoutSummaryMonth || []);
                this.localUpdateField('workoutAvgWeek', infoResponse.included.workoutAvgWeek || []);
                this.localUpdateField('avgWorkoutStats', infoResponse.included.avgWorkoutStats || []);
                this.localUpdateField('workoutSummaryDay', infoResponse.included.workoutSummaryDay || []);
                this.localUpdateField('workoutSeasonTotal', infoResponse.included.workoutSeasonTotal || []);
                this.localUpdateField('workoutDistinctTypes', infoResponse.included.workoutDistinctTypes || []);
                this.localUpdateField('workoutSeasonEquipment', infoResponse.included.workoutSeasonEquipment || []);

                // eslint-disable-next-line no-case-declarations
                const workoutCurrentWeek = [];
                daysInCurrentWeek.forEach(date => {
                    const yyyy = date.getFullYear();
                    const mm = date.getMonth() + 1;
                    const dd = date.getDate();
                    const day = this.workoutSummaryDay.find(d => d.year === yyyy && d.month === mm && d.day === dd);
                    workoutCurrentWeek.push(day || {
                        year: yyyy,
                        month: mm,
                        day: dd,
                        distanceKm: 0,
                        elevation: 0,
                        duration: 0,
                        speed_avg: 0,
                        load_index: 0,
                        rest: 0,
                        rating: 0,
                        temperature: 0,
                        count: 0,
                    });
                });
                this.localUpdateField('workoutCurrentWeek', workoutCurrentWeek);

                // eslint-disable-next-line no-case-declarations
                const workoutCurrentSeason = [];
                seasonDaysSoFar.forEach(date => {
                    const yyyy = date.year;
                    const mm = date.month;
                    const dd = date.day;
                    const day = this.workoutSummaryDay.find(d => d.year === yyyy && d.month === mm && d.day === dd);
                    workoutCurrentSeason.push(day || {
                        year: yyyy,
                        month: mm,
                        day: dd,
                        distanceKm: 0,
                        elevation: 0,
                        duration: 0,
                        speed_avg: 0,
                        load_index: 0,
                        rest: 0,
                        rating: 0,
                        temperature: 0,
                        count: 0,
                    });
                });
                this.localUpdateField('workoutCurrentSeason', workoutCurrentSeason);
                this.localUpdateField('seasonDaysSoFar', seasonDaysSoFar);
                this.localUpdateField('seasonWeeksSoFar', seasonWeeksSoFar);

                // eslint-disable-next-line no-case-declarations
                const workoutPrevSeason = [];
                prevSeasonDaysSoFar.forEach(date => {
                    const yyyy = date.year;
                    const mm = date.month;
                    const dd = date.day;
                    const day = this.workoutSummaryDay.find(d => d.year === yyyy && d.month === mm && d.day === dd);
                    workoutPrevSeason.push(day || {
                        year: yyyy,
                        month: mm,
                        day: dd,
                        distanceKm: 0,
                        elevation: 0,
                        duration: 0,
                        speed_avg: 0,
                        load_index: 0,
                        rest: 0,
                        rating: 0,
                        temperature: 0,
                        count: 0,
                    });
                });

                this.localUpdateField('workoutPrevSeason', workoutPrevSeason);
                this.localUpdateField('prevSeasonDaysSoFar', prevSeasonDaysSoFar);
                this.localUpdateField('prevSeasonWeeksSoFar', prevSeasonWeeksSoFar);

                // eslint-disable-next-line no-case-declarations
                seasonDays.forEach((w, idx) => {
                    const dateEpoch = new Date(`${w.year}-${w.month}-${w.day}`).getTime();
                    if (dateEpoch > todayEpoch) {
                        avgDayDistance.push({ x: idx, y: 0 });
                        avgDayElevation.push({ x: idx, y: 0 });
                        avgDayDuration.push({ x: idx, y: 0 });
                        avgDaySpeedAvg.push({ x: idx, y: 0 });
                        avgDayLoadIndex.push({ x: idx, y: 0 });
                        avgDayAvgDogs.push({ x: idx, y: 0 });
                        avgDayAvgImages.push({ x: idx, y: 0 });
                        avgDaySledWeight.push({ x: idx, y: 0 });
                        avgDayRest.push({ x: idx, y: 0 });
                        avgDayRating.push({ x: idx, y: 0 });
                        avgDayTemperature.push({ x: idx, y: 0 });
                    } else {
                        const dataArr = infoResponse.included.workoutSummaryDay || [];
                        const data = dataArr.find(e => e.year === w.year && e.month === w.month && e.day === w.day) || {};
                        avgDayDistance.push({
                            x: idx,
                            y: imperial ? kmToMiles(data.distanceKm || mu.getLastValue(avgDayDistance, 'y', 0)) : (data.distanceKm || mu.getLastValue(avgDayDistance, 'y', 0)),
                        });
                        avgDayElevation.push({
                            x: idx,
                            y: imperial ? meterToFeet(data.elevation || mu.getLastValue(avgDayElevation, 'y', 0)) : (data.elevation || mu.getLastValue(avgDayElevation, 'y', 0)),
                        });
                        avgDayDuration.push({
                            x: idx,
                            y: data.duration || mu.getLastValue(avgDayDuration, 'y', 0),
                        });
                        avgDaySpeedAvg.push({
                            x: idx,
                            y: imperial ? kmtToMph(data.speedAvg || mu.getLastValue(avgDaySpeedAvg, 'y', 0)) : (data.speedAvg || mu.getLastValue(avgDaySpeedAvg, 'y', 0)),
                        });
                        avgDayLoadIndex.push({
                            x: idx,
                            y: data.loadIndex || mu.getLastValue(avgDayLoadIndex, 'y', 0),
                        });
                        avgDayAvgDogs.push({
                            x: idx,
                            y: data.avgDogs || mu.getLastValue(avgDayAvgDogs, 'y', 0),
                        });
                        avgDayAvgImages.push({
                            x: idx,
                            y: data.avgImages || mu.getLastValue(avgDayAvgImages, 'y', 0),
                        });
                        avgDaySledWeight.push({
                            x: idx,
                            y: data.sledWeight || mu.getLastValue(avgDaySledWeight, 'y', 0),
                        });
                        avgDayRest.push({
                            x: idx,
                            y: data.rest || mu.getLastValue(avgDayRest, 'y', 0),
                        });
                        avgDayRating.push({
                            x: idx,
                            y: data.rating || mu.getLastValue(avgDayRating, 'y', 0),
                        });
                        avgDayTemperature.push({
                            x: idx,
                            y: data.temperature || mu.getLastValue(avgDayTemperature, 'y', 0),
                        });
                    }
                });
                this.localUpdateField('avgDayDistance', avgDayDistance);
                this.localUpdateField('avgDayElevation', avgDayElevation);
                this.localUpdateField('avgDayDuration', avgDayDuration);
                this.localUpdateField('avgDaySpeedAvg', avgDaySpeedAvg);
                this.localUpdateField('avgDayLoadIndex', avgDayLoadIndex);
                this.localUpdateField('avgDayAvgDogs', avgDayAvgDogs);
                this.localUpdateField('avgDayAvgImages', avgDayAvgImages);
                this.localUpdateField('avgDaySledWeight', avgDaySledWeight);
                this.localUpdateField('avgDayRest', avgDayRest);
                this.localUpdateField('avgDayRating', avgDayRating);
                this.localUpdateField('avgDayTemperature', avgDayTemperature);

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekDistanceCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    if (data.distanceKm > 0) {
                        graphWorkoutSummaryWeekDistanceCurrentTotal += data.distanceKm;
                    }
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekElevationCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekDurationCurrent = seasonWeeksSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekLoadIndexLastPeriode = lastPeriodeWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.loadIndex || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekLoadIndexCurrent = seasonWeeksSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.loadIndex || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const workoutSummaryWeekAvgLoadLastPeriodeArray = lastPeriodeWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return data.loadIndex || 0;
                });
                // eslint-disable-next-line no-case-declarations
                const workoutSummaryWeekAvgLoadLastPeriode = workoutSummaryWeekAvgLoadLastPeriodeArray
                    .reduce((a, b) => a + b, 0) / workoutSummaryWeekAvgLoadLastPeriodeArray.length;
                // console.log({ workoutSummaryWeekAvgLoadLastPeriode });

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekDistancePrev = prevSeasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekElevationPrev = prevSeasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekDurationPrev = prevSeasonWeeksSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryWeekLoadIndexPrev = prevSeasonWeeksSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.loadIndex || 0,
                        week: w.week,
                        year: w.year,
                    };
                });

                this.localUpdateField('graphWorkoutSummaryWeekDistanceCurrent', graphWorkoutSummaryWeekDistanceCurrent);
                this.localUpdateField('graphWorkoutSummaryWeekDistanceCurrentTotal', graphWorkoutSummaryWeekDistanceCurrentTotal);
                this.localUpdateField('graphWorkoutSummaryWeekDistancePrev', graphWorkoutSummaryWeekDistancePrev);
                this.localUpdateField('graphWorkoutSummaryWeekElevationCurrent', graphWorkoutSummaryWeekElevationCurrent);
                this.localUpdateField('graphWorkoutSummaryWeekElevationPrev', graphWorkoutSummaryWeekElevationPrev);
                this.localUpdateField('graphWorkoutSummaryWeekDurationCurrent', graphWorkoutSummaryWeekDurationCurrent);
                this.localUpdateField('graphWorkoutSummaryWeekDurationPrev', graphWorkoutSummaryWeekDurationPrev);
                this.localUpdateField('graphWorkoutSummaryWeekLoadIndexCurrent', graphWorkoutSummaryWeekLoadIndexCurrent);
                this.localUpdateField('graphWorkoutSummaryWeekLoadIndexLastPeriode', graphWorkoutSummaryWeekLoadIndexLastPeriode);
                this.localUpdateField('graphWorkoutSummaryWeekLoadIndexPrev', graphWorkoutSummaryWeekLoadIndexPrev);

                this.localUpdateField('workoutSummaryWeekAvgLoadLastPeriode', workoutSummaryWeekAvgLoadLastPeriode);

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthDistanceCurrent = seasonMonths.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        month: w.month,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthElevationCurrent = seasonMonths.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        month: w.month,
                        year: w.year,
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthDurationCurrent = seasonMonthsSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        month: w.month,
                        year: w.year,
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthDistancePrev = prevSeasonMonths.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        month: w.month,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthElevationPrev = prevSeasonMonths.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        month: w.month,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutSummaryMonthDurationPrev = prevSeasonMonthsSoFar.map((w, idx) => {
                    const dataArr = infoResponse.data || [];
                    const data = dataArr.find(e => e.month === w.month && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        month: w.month,
                        year: w.year,
                    };
                });

                this.localUpdateField('graphWorkoutSummaryMonthDistanceCurrent', graphWorkoutSummaryMonthDistanceCurrent);
                this.localUpdateField('graphWorkoutSummaryMonthDistancePrev', graphWorkoutSummaryMonthDistancePrev);
                this.localUpdateField('graphWorkoutSummaryMonthElevationCurrent', graphWorkoutSummaryMonthElevationCurrent);
                this.localUpdateField('graphWorkoutSummaryMonthElevationPrev', graphWorkoutSummaryMonthElevationPrev);
                this.localUpdateField('graphWorkoutSummaryMonthDurationCurrent', graphWorkoutSummaryMonthDurationCurrent);
                this.localUpdateField('graphWorkoutSummaryMonthDurationPrev', graphWorkoutSummaryMonthDurationPrev);


                // {
                //     distanceKm: 11.370000000000001,
                //     elevation: 143.75,
                //     speedAvg: 13.2,
                //     count: 8,
                //     week: 33,
                //     year: 2020,
                //     teamType: 5,
                //     epoch: 1597010400000
                // },
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsDistance = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsElevation = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsSpeed = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmtToMph(data.speedAvg || 0) : (data.speedAvg || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsDuration = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsRealDuration = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.realDuration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsLoadIndex = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.loadIndex || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphAvgWorkoutStatsCount = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.avgWorkoutStats || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.count || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                this.localUpdateField('graphAvgWorkoutStatsDistance', graphAvgWorkoutStatsDistance);
                this.localUpdateField('graphAvgWorkoutStatsElevation', graphAvgWorkoutStatsElevation);
                this.localUpdateField('graphAvgWorkoutStatsSpeed', graphAvgWorkoutStatsSpeed);
                this.localUpdateField('graphAvgWorkoutStatsDuration', graphAvgWorkoutStatsDuration);
                this.localUpdateField('graphAvgWorkoutStatsRealDuration', graphAvgWorkoutStatsRealDuration);
                this.localUpdateField('graphAvgWorkoutStatsLoadIndex', graphAvgWorkoutStatsLoadIndex);
                this.localUpdateField('graphAvgWorkoutStatsCount', graphAvgWorkoutStatsCount);

                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekDistanceCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekElevationCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? meterToFeet(data.elevation || 0) : (data.elevation || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekSpeedCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: imperial ? kmtToMph(data.speedAvg || 0) : (data.speedAvg || 0),
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekDurationCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.duration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekRealDurationCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.realDuration || 0,
                        week: w.week,
                        year: w.year,
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const graphWorkoutAvgWeekLoadIndexCurrent = seasonWeeks.map((w, idx) => {
                    const dataArr = infoResponse.included.workoutAvgWeek || [];
                    const data = dataArr.find(e => e.week === w.week && e.year === w.year) || {};
                    return {
                        x: idx,
                        y: data.loadIndex || 0,
                        week: w.week,
                        year: w.year,
                    };
                });

                this.localUpdateField('graphWorkoutAvgWeekDistanceCurrent', graphWorkoutAvgWeekDistanceCurrent);
                this.localUpdateField('graphWorkoutAvgWeekElevationCurrent', graphWorkoutAvgWeekElevationCurrent);
                this.localUpdateField('graphWorkoutAvgWeekSpeedCurrent', graphWorkoutAvgWeekSpeedCurrent);
                this.localUpdateField('graphWorkoutAvgWeekDurationCurrent', graphWorkoutAvgWeekDurationCurrent);
                this.localUpdateField('graphWorkoutAvgWeekRealDurationCurrent', graphWorkoutAvgWeekRealDurationCurrent);
                this.localUpdateField('graphWorkoutAvgWeekLoadIndexCurrent', graphWorkoutAvgWeekLoadIndexCurrent);

                // this.localUpdateField('graphWorkoutSummaryMonthDistanceCurrent',
                //     this.workoutSummaryMonth.filter(e => e.year === seasonStart)
                //         .map(e => ({
                //             x: e.month,
                //             y: imperial ? kmToMiles(e.distanceKm) : e.distanceKm,
                //         })));
                // this.localUpdateField('graphWorkoutSummaryMonthDistancePrev',
                //     this.workoutSummaryMonth.filter(e => e.year === prevYear)
                //         .map(e => ({
                //             // x: Math.floor(getDateOfISOWeek(e.year, e.week).getTime() / 1000),
                //             x: e.month,
                //             y: imperial ? kmToMiles(e.distanceKm) : e.distanceKm,
                //         })));
                // this.localUpdateField('graphWorkoutSummaryMonthElevationCurrent',
                //     this.workoutSummaryMonth.filter(e => e.year === seasonStart)
                //         .map(e => ({
                //             x: e.month,
                //             y: imperial ? meterToFeet(e.elevation) : e.elevation,
                //         })));
                // this.localUpdateField('graphWorkoutSummaryMonthElevationPrev',
                //     this.workoutSummaryMonth.filter(e => e.year === prevYear)
                //         .map(e => ({
                //             // x: Math.floor(getDateOfISOWeek(e.year, e.week).getTime() / 1000),
                //             x: e.month,
                //             y: imperial ? meterToFeet(e.elevation) : e.elevation,
                //         })));
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'error', `Fetching the feed failed! ${infoResponse.status}: ${infoResponse.message}`);
                break;
        }
    }

    async getWorkoutSummaryDay({ year, week, month, day, team, dog, track, daysBack, keyname, type }) {
        const infoResponse = await util.fetchApi('/api/workouts/summary/day', { publish: true, method: 'GET' }, { year, week, month, team, dog, track, daysBack, type });
        switch (infoResponse.status) {
            case 200:
                if (Array.isArray(infoResponse.data)) {
                    const currentWeek = util.getWeek(new Date());
                    const currentMonth = parseInt(new Date().getMonth() + 1, 10);
                    const prevMonth = currentMonth - 1 < 1 ? 12 : currentMonth - 1;
                    const currentYear = parseInt(util.getYear(), 10);
                    const prevYear = currentYear - 1;
                    const workoutSummaryDaysCurrentMonth = infoResponse.data.filter(e => e.year === currentYear && e.month === currentMonth);
                    const workoutSummaryDaysCurrentMonthPrevMonth = infoResponse.data.filter(e => e.year === currentYear && e.month === prevMonth);
                    const workoutSummaryDaysCurrentMonthPrevSeason = infoResponse.data.filter(e => e.year === prevYear && e.month === currentMonth);
                    this.localUpdateField('workoutSummaryDaysCurrentMonth', workoutSummaryDaysCurrentMonth || []);
                    this.localUpdateField('workoutSummaryDaysCurrentMonthPrevMonth', workoutSummaryDaysCurrentMonthPrevMonth || []);
                    this.localUpdateField('workoutSummaryDaysCurrentMonthPrevSeason', workoutSummaryDaysCurrentMonthPrevSeason || []);

                    if (keyname) {
                        this.localUpdateField(keyname, infoResponse.data);
                    } else {
                        this.localUpdateField('workoutSummaryWeekDay', infoResponse.data);
                    }

                    if (year && month) {
                        const { settings = {} } = this.user;
                        const { imperial } = settings;
                        const daysInMonth = day || util.daysInMonth(month, year);
                        const days = util.range(1, daysInMonth);
                        let total = 0;
                        // eslint-disable-next-line no-case-declarations
                        const graphAvgWorkoutStatsDistance = days.map((day, idx) => {
                            const dataArr = infoResponse.data || [];
                            const data = dataArr.find(e => e.day === day && e.month === month && e.year === year) || {};
                            total += imperial ? kmToMiles(data.distanceKm || 0) : (data.distanceKm || 0);
                            return {
                                x: idx,
                                y: total,
                                day,
                                month,
                                year,
                            };
                        });
                        if (keyname) {
                            this.localUpdateField(`graph${keyname}`, graphAvgWorkoutStatsDistance || []);
                        } else {
                            this.localUpdateField('graphWorkoutSummaryWeekDay', graphAvgWorkoutStatsDistance || []);
                        }
                    }
                }
                if (infoResponse.included) {
                    this.localUpdateField('workoutsDay', infoResponse.included.workouts || []);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'error', `Fetching the feed failed! ${infoResponse.status}: ${infoResponse.message}`);
                break;
        }
    }

    async sendLoginLink(email, language = 'en') {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const emailCheckResponse = await util.fetchApi('/api/users/login/link', { publish: true }, { email });
            switch (emailCheckResponse.status) {
                case 200:
                    this.updateObjectField('loginLink', 'emailSent', true);
                    this.updateObjectField('loginLink', 'messageIcon', 'fas fa-shipping-fast');
                    if (language === 'no') {
                        this.updateObjectField('loginLink', 'message', `E-post er sendt.`);
                    } else {
                        this.updateObjectField('loginLink', 'message', `Email sent.`);
                    }
                    break;
                default:
                    this.updateObjectField('loginLink', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('loginLink', 'error', `E-postsending feilet! ${emailCheckResponse.status}: ${emailCheckResponse.message}`);
                    } else {
                        this.updateObjectField('loginLink', 'error', `Email send failed! ${emailCheckResponse.status}: ${emailCheckResponse.message}`);
                    }
                    break;
            }
        }
    }

    async validateLoginCode(email, code, language = 'en') {
        const isValidCode = util.validateCode(code, 100000, 999999);
        if (isValidCode) {
            const loginResponse = await util.fetchApi('/api/users/login/code', { publish: true, method: 'POST' }, { email, code });
            switch (loginResponse.status) {
                case 200:
                    this.localUpdateField('user', loginResponse.data.user);
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(loginResponse.data.apiToken);
                    util.setUserEmail(loginResponse.data.user.email);
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                    break;
                case 403:
                    if (loginResponse.message === 'Wrong code!') {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Feil code! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectField('login', 'error', `Wrong code! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login code has expired!') {
                        this.updateObjectField('loginLink', 'emailSent', false);
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Koden har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Code expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Something wrong with your code! Try to get a new code and try again.`);
                        }
                    }
                    break;
                case 422:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                    }
                    break;
                default:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    break;
            }
        }
    }

    async validateLoginToken(loginToken, language = 'en') {
        const isValidToken = util.isString(loginToken);
        if (isValidToken) {
            const loginResponse = await util.fetchApi('/api/users/login/token', { publish: true, method: 'POST' }, { loginToken });
            switch (loginResponse.status) {
                case 200:
                    this.localUpdateField('user', loginResponse.data.user);
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(loginResponse.data.apiToken);
                    util.setUserEmail(loginResponse.data.user.email);
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                    return loginResponse;
                case 403:
                    if (loginResponse.message === 'Wrong login token!') {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Feil token! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectField('login', 'error', `Wrong token! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login token has expired!') {
                        this.updateObjectField('loginLink', 'emailSent', false);
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Token har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Token expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Something wrong with your token! Try to get a new code and try again.`);
                        }
                    }
                    break;
                case 422:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                    }
                    break;
                default:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    break;
            }
        }
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.user.images)) {
            const idx = this.user.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.user.images.splice(idx, 1);
            }
        }
        const widx = this.users?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.users[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.users[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/users/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 200:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    removeFieldLocal({ field, email, value }) {
        if (util.isArray(this.user[field])) {
            const idx = this.user[field]?.findIndex(e => e === value);
            if (idx > -1) {
                this.user[field].splice(idx, 1);
            }
        }
        const widx = this.users?.findIndex(e => e.email === email);
        if (widx > -1) {
            const idx = this.users[widx][field]?.findIndex(e => e === value);
            if (idx > -1) {
                this.users[widx][field].splice(idx, 1);
            }
        }
    }

    /*
        removeHideTeamSuggestions = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { value } = e.target.closest('i').dataset;
            const { userStore } = this.props.stores;
            const { user } = userStore;
            await userStore.removeField({ email: user.email, field: 'hideTeamSuggestions', value: parseInt(value, 10) });
        }
    */
    async removeField({ field, email, value }) {
        const apiFieldName = `remove${util.ucfirst(field)}`;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [apiFieldName]: value });
        switch (response.status) {
            case 200:
                this.removeFieldLocal({ field, email, value });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateUserArrayLocal({ field, email, value }) {
        if (!util.isArray(this.user[field])) {
            this.user[field] = [];
        }
        this.user[field].push(value);

        const widx = this.users?.findIndex(e => e.email === email);
        if (widx > -1) {
            if (!util.isArray(this.users[widx][field])) {
                this.users[widx][field] = [];
            }
            this.users[widx][field].push(value);
        }
    }

    /*
        hideTeamSuggestions = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { value } = e.target.closest('i').dataset;
            const { userStore } = this.props.stores;
            const { user } = userStore;
            await userStore.updateUserArray({ email: user.email, field: 'hideTeamSuggestions', value: parseInt(value, 10) });
        }
    */
    async updateUserArray({ email, field, value }) {
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserArrayLocal({ field, email, value });
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    findApplicationById(appId) {
        if (util.isArray(this.user.applications)) {
            const idx = this.user.applications?.findIndex(e => e.appId === appId);
            if (idx > -1) {
                return this.user.applications[idx];
            }
        }
        return null;
    }

    @action
    removeApplicationLocal({ id, name }) {
        if (util.isArray(this.user.applications)) {
            const idx = this.user.applications?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.user.applications.splice(idx, 1);
            }
        }
        const widx = this.users?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.users[widx].applications?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.users[widx].applications.splice(idx, 1);
            }
        }
    }

    async removeApplication({ id, name: removeApplicationById }) {
        const response = await util.fetchApi(`/api/users/${id}`, { publish: true, method: 'PATCH' }, { removeApplicationById });
        switch (response.status) {
            case 200:
                this.removeImageLocal({ id, name: removeApplicationById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getSimulateUser(email) {
        const simulateResponse = await util.fetchApi(`/api/users/simulate/${email}`, { publish: true, method: 'GET' }, {});
        switch (simulateResponse.status) {
            case 200:
                this.localUpdateField('user', simulateResponse.data.user);
                util.setJwtToken(simulateResponse.data.apiToken);
                util.setUserEmail(simulateResponse.data.user.email);
                await this.getInfo();
                PubSub.publish(topics.JWT_TOKEN_CHANGED, simulateResponse.data.apiToken);
                route('/');
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setLanguage({ email, language }) {
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { language });
        switch (response.status) {
            case 200:
                this.updateObjectField('user', 'language', language);
                await this.getInfo();
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setFirebase({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    hasFirebasePlugin = () => {
        if (window.FirebasePlugin) {
            return true;
        }
        return false;
    }

    checkNotificationPermission = async (requested) => {
        this.firebasePlugin = window.FirebasePlugin;
        if (this.firebasePlugin) {
            this.firebasePlugin.hasPermission(async (hasPermission) => {
                if (hasPermission) {
                    console.log('Remote notifications permission granted');
                    await this.setFirebase({ field: 'firebase.hasPermission', value: true });
                    // Granted
                    this.getToken();
                } else if (!requested) {
                    // Request permission
                    console.log('Requesting remote notifications permission');
                    await this.setFirebase({ field: 'firebase.hasPermission', value: false });
                    this.firebasePlugin.grantPermission(this.checkNotificationPermission(true));
                } else {
                    // Denied
                    console.log('Notifications won\'t be shown as permission is denied');
                }
            });
        }
    };

    getToken = () => {
        this.firebasePlugin.getToken(async (token) => {
            console.log(`Got FCM token: ${token}`)
            await this.setFirebase({ field: 'firebase.fcmToken', value: token });
        }, (error) => {
            console.log('Failed to get FCM token', error);
        });
    }

    async setActiveTeam({ team, teamType }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { activeTeam: {
            team,
            teamType,
        } });
        switch (response.status) {
            case 200:
                this.updateUserSubValue('activeTeam.team', team);
                this.updateUserSubValue('activeTeam.teamType', teamType);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setLastUpdated({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setToggleFeature({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setNotification({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async setNotificationTopic({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async updatePlatform() {
        const { platformId, platformVersion } = window.cordova || {};
        // console.log({ platformId, platformVersion });
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { platformId, platformVersion });
        switch (response.status) {
            case 200:
                this.updateUserField('platformId', platformId);
                this.updateUserField('platformVersion', platformVersion);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async saveDeviceInfo(deviceInfo) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/${email}`, { publish: true, method: 'PATCH' }, { deviceInfo });
        switch (response.status) {
            case 200:
                this.updateUserField('deviceInfo', deviceInfo);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }


    async stravaGetActivityStream() {
        const response = await util.fetchApi(`/api/strava/v3-activities-streams`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                console.log(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async garminTest() {
        const response = await util.fetchApi(`/api/garmin/test/`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                console.log(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async inviteKennel({ email }) {
        const response = await util.fetchApi(`/api/users/invite`, { publish: true, method: 'GET' }, { email });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateSettingLocal(settings) {
        const userSettings = {
            ...this.user.settings,
            ...settings,
        };
        this.user.settings = userSettings;
    }

    async updateSetting(settings, settingsRaw) {
        const response = await util.fetchApi(`/api/users/${this.user.email}`, { publish: true, method: 'PATCH' }, { ...settings });
        switch (response.status) {
            case 200:
                this.updateSettingLocal(settingsRaw);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async adminUpdateUser(email, object = {}) {
        const response = await util.fetchApi(`/api/users/admin/${email}`, { publish: true, method: 'PATCH' }, { ...object });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async changePassword({ password, passwordConfirm }) {
        const response = await util.fetchApi(`/api/users/${this.user.email}`, { publish: true, method: 'PATCH' }, {
            password,
            passwordConfirm,
        });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async searchUsers({ search, showAll }) {
        const response = await util.fetchApi(`/api/users/search`, { publish: true, method: 'GET' }, { search, showAll });
        switch (response.status) {
            case 200:
                this.localUpdateField('searchUsersResult', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addVaccineLogStatuses(data) {
        if (data && data.length > 0) {
            for (let i = 0, l = data.length; i < l; i += 1) {
                const line = data[i];
                const date = line.vaccine.date || new Date();
                for (let j = 0, m = line.vaccine.agens.length; j < m; j += 1) {
                    const agen = line.vaccine.agens[j];
                    const { durationMonths, karensDays } = agen;
                    const durationDays = durationMonths * 30;
                    agen.durationStart = date;
                    agen.durationEnd = util.addDays(date, durationDays);
                    agen.karensStart = date;
                    agen.karensEnd = util.addDays(date, karensDays);
                    const vac = {
                        chipId: line.dog.chipId,
                        name: line.dog.name,
                        shortname: line.dog.shortname,
                        team: line.dog.team,
                        user: line.dog.user,
                        vaccine: line.vaccine.id,
                        vaccineAgens: agen,
                        vaccineName: line.vaccine.name,
                        veterinary: line.veterinary.id,
                        veterinaryEmail: line.veterinary.email,
                        veterinaryFirstname: line.veterinary.firstname,
                        veterinaryLastname: line.veterinary.lastname,
                        inKarens: agen.karensStart <= new Date() && agen.karensEnd >= new Date(),
                        karensDaysLeft: getDiffInDays(new Date(), agen.karensEnd),
                        durationDaysLeft: getDiffInDays(new Date(), agen.durationEnd),
                    };
                    this.vaccineLogStatuses.push(vac);
                }
            }
        }
    }

    async getDogs({ id }) {
        this.setLoading('getDogs', true);
        const response = await util.fetchApi(`/api/users/dogs/${id}`, { publish: true, method: 'GET' }, {});
        this.setLoading('getDogs', false);
        switch (response.status) {
            case 200:
                this.localUpdateField('searchUserTeams', response.data);
                this.localUpdateField('vaccineStatuses', response.included.vaccineStatuses);
                this.localUpdateField('vaccineLogStatuses', response.included.vaccineLogStatuses);
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getNotifications() {
        const response = await util.fetchApi(`/api/users/notifications`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('notifications', response.data);
                // mu.tapticNotification('success');
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async markNotificationsAsRead({ id, ids }) {
        // const idx = this.notifications?.findIndex(e => e.id === id);
        // const obj = this.notifications[idx];

        const response = await util.fetchApi(`/api/users/notifications`, { publish: true, method: 'PATCH' }, { id, ids });
        switch (response.status) {
            case 200:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getWeather({ lat, lon, altitude, force, workout }) {
        if (!lat || !lon) {
            return [];
        }
        const response = await util.fetchApi(`/api/yr/`, { publish: true, method: 'GET' }, { lat, lon, altitude, force, workout });
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadFavorites({ query, limit, offset, search, opts = {} }) {
        const response = await util.fetchApi(`/api/users/favorites/`, { publish: true, method: 'GET' }, { ...query, search, limit, offset, ...opts });
        switch (response.status) {
            case 200:
                if (offset > 0) {
                    let favorites = toJS(this.favorites);
                    favorites.push(...response.data);
                    this.localUpdateField('favorites', favorites);
                } else {
                    this.localUpdateField('favorites', response.data);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new UserStore();
export default store;
