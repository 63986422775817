import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import mu from '../../lib/musher-util';

const STOP_STATUSES = [2, 9];
const WARN_STATUSES = [4, 8];


function getFatigueLevel(wo, dog) {
    if (dog) {
        if (STOP_STATUSES.indexOf(dog.status) > -1) {
            return 'danger';
        } else if (WARN_STATUSES.indexOf(dog.status) > -1) {
            return 'warning';
        }
    }
    if (wo) {
        if (wo.distanceKm > 50) {
            return 'danger';
        } else if (wo.distanceKm > 30) {
            return 'warning';
        }
    }
    return 'success';
}

@observer
class Dog extends Component {
    render() {
        const { 
            dog = {},
            dogs = [],
            showTeam,
            showInfo,
            showName = true,
            showPosition = true,
            showAge = false,
            className,
            size,
            onDogClick = () => {},
        } = this.props;
		const { userStore } = this.props.stores;
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;

        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = Math.ceil(mu.daysUntilNextBirthday(dog.birth));

        let containerWidth = '92px';
        let imageClasses = '';
        let fontSize = '35px';
        if (size === 'small') {
            containerWidth = '50px';
            imageClasses = 'imageRoundedSmall';
            fontSize = '20px';
        } else if (size === 'medium') {
            containerWidth = '60px';
            imageClasses = 'imageRoundedMedium';
            fontSize = '25px';
        }

        return (
            <div class={`mb-2 position-relative ${className} clearfix`} style={`width: ${containerWidth}`}
                onTouchstart={(e) => { e.stopPropagation(); }}
                onTouchend={(e) => { e.stopPropagation(); }}
                onTouchmove={(e) => { e.stopPropagation(); }}
            >
                {/* {JSON.stringify(dog, null, 4)} */}
                {showPosition && dogPosition && <small class='position-absolute' style='left:0px; top: 50px;'>
                    <span class={`badge badge-${dogPosition.class || 'primary'} mr-3`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>
                </small>}
                <div
                    class={`text-center border rounded-circle imageRounded ${imageClasses} mx-auto text-muted pt-2`}
                    style={`${dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''} border-width: 2px !important;`}
                >
                    {/* {!dog.image && <i class='fas fa-dog mt-3' style='font-size: 40px;' />} */}
                    {!dog.image && <span class='font-weight-lighter' style={`font-size: ${fontSize};`}>{util.ucfirst(dog.name, true)}</span>}
                </div>
                <div class='text-center text-overflow' style='line-height: 1.0em;'>
                    <small>
                        {/* <span class='float-right'>
                            {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                            {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                        </span> */}
                        <a href={`/dogs/public/${dog.uuidv4}`} onClick={(e) => onDogClick(e, dog.uuidv4, dogs)} class='stretched-link'>
                            {showName && <>
                                <span class='mb-1 font-weight-light'>
                                    <nobr>
                                        {dog.shortname || dog.name}
                                        {hasBirthday && <>
                                            <i class='fas fa-birthday-cake ml-3' />
                                        </>}
                                    </nobr>
                                </span>
                            </>}
                        </a>
                    </small>
                </div>
                <div class='text-center text-overflow' style='line-height: 1.0em;'>
                    <small>
                        {dog.birth && showAge && <>
                            <span class='float-right'>
                                {/* {daysUntilBirthday < 7 && <>
                                    <span class='text-muted mr-2'>
                                        <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                    </span>
                                </>}                                 */}
                                {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                            </span>
                        </>}

                        {showTeam && <><span class='font-weight-light'>{userStore.findTeam(dog.team)}</span><br /></>}

                        {showInfo && <small class='text-muted'>
                            {dog.weight && <><i class='fas fa-balance-scale ml-2' /> {util.format(dog.weight, 1)} kg</>}
                            {dog.bodyScore && <><i class='fas fa-dog ml-2' /> {util.format(dog.bodyScore, 1)}</>}
                        </small>}

                    </small>
                </div>
            </div>
        );
    }
}

export default Dog;
