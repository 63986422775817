import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';

import Geohash from 'latlon-geohash';

import PubSub, { topics } from '../lib/pubsub';

import mu from '../lib/musher-util';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

const DEBUG = false;

function consoleLog(...args) {
    if (DEBUG) {
        console.log(...args);
    }
}

class WorkoutStore extends StoreModel {
    constructor() {
        super('workout', {
            namePlural: 'workouts',
            sort: '-date',
            limit: 500,
            api: {
                search: {
                    url: '/api/workouts/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/workouts/',
                    params: {},
                },
                save: {
                    url: '/api/workouts/',
                    params: {},
                },
            },
        });
    }

    @observable workout = {};

    @observable lastWorkout = {};

    @observable geocluster = [];

    @observable waypoints2 = [];

    @observable waypoints3 = [];

    @observable cordovaActiveTrackingParams = { workout: null, team: null };

    @observable newWorkout = {
        name: '',
        type: 1,
        dogs: [],
        teams: [],
    };

    @observable currentWeather = {};

    @observable lastWeatherUpdate = 0;

    @observable workouts = [];

    @observable isTracking = false;

    @observable cordovaInitSet = false;

    @observable workoutLocations = [];

    @observable currentTeam = util.get('currentTeam');

    @observable activeTrackingWorkoutId = util.get('activeTrackingWorkoutId');

    @observable activeTrackingTeamId = util.get('activeTrackingTeamId');

    @observable competitionResults = [];

    @observable competitionAllResults = [];

    @observable competitionTeams = [];

    @observable heatmap = [];

    @observable publicUsers = [];

    @observable publicTeams = [];

    // Cordova variables
    bgGeo = null;
    cordova = null;

    @observable cordovaAuthorizationStatus = null;

    @observable isRunning = false;

    @observable powerSaveStatus = false;

    @observable pluginState = { enabled: null, trackingMode: null, params: {} };

    @observable providerState = { enabled: null, status: null, gps: null, network: null };

    @observable log = null;

    @observable startTime = 0;

    @observable lastTime = 0;

    @observable totalTime = 0;

    @observable currentSpeed = 0;

    @observable isMoving = 0;

    @observable totalDistance = 0;

    @observable backgroundGeoLocationReady = false;

    @observable geoLocationState = null;

    @observable activity = {};

    @observable battery = {};

    @observable coords = {};

    @observable currentLocation = {};

    @observable altitude = 0;

    @observable accuracy = 0;

    @observable heading = 0;

    @observable currentTrack = [];

    @observable gpsAccuracy = true;

    @observable laps = [];
    // /Cordova variables

    @observable logs = [];

    @observable loadMore = () => {};

    @observable updateTrackingOnResume = () => {
        console.log('updateTrackingOnResume');
        if (!this.isTracking) {
            this.cordovaProviderState();
            this.cordovaGetCurrentPosition();
        }
    };

    @observable updateTrackingOnPause = () => {
        console.log('updateTrackingOnPause');
    };

    @observable updateOnResume = () => {
        console.log('updateOnResume');
    };

    @observable updateOnPause = () => {
        console.log('updateOnPause');
    };

    @action
    beforeLoad({ id, addData, queryFilter }) {
        if (id == this.workout.id) {
            return;
        }
        const workoutPlaceholder = {
            date: '{placeholder}',
            name: '{placeholder}',
            description: '{placeholder}',
        };
        if (id) {
            this.workout = workoutPlaceholder;
        } else {
            this.workouts = [
                workoutPlaceholder,
                workoutPlaceholder,
                workoutPlaceholder,
            ];
        }
    }

    @action
    async afterLoad(response, opt) {
        if (opt.skipScreenshot) {
            return true;
        }
        if (!response.data?.mapImage && response.data.id) {
            const { id } = response.data;
            await this.screenshot(id);
            await this.aggregations(id);
        }
    }

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('WorkoutStore.cleanupMemory');
        }
        this.localUpdateField('workout', {});
        this.localUpdateField('workouts', []);
        this.localUpdateField('heatmap', []);
        this.localUpdateField('laps', []);
        this.localUpdateField('competitionAllResults', []);
        this.localUpdateField('competitionTeams', []);
    }

    @action
    cleanupMemoryDetail() {
        if (isDevelopment) {
            console.log('WorkoutStore.cleanupMemoryDetail');
        }
        this.localUpdateField('workout', {});
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    updatePluginState(state) {
        this.pluginState = state;
    }

    @action
    setActiveTrackingWorkoutId(workout) {
        this.activeTrackingWorkoutId = workout;
        util.set('activeTrackingWorkoutId', workout);
    }

    @action
    setActiveTrackingTeamId(team) {
        this.activeTrackingTeamId = team;
        util.set('activeTrackingTeamId', team);
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    setCurrentTeam(team) {
        this.currentTeam = team;
        util.set('currentTeam', team);
    }

    @action
    setCurrentSpeed(currentSpeed) {
        this.currentSpeed = currentSpeed;
    }

    @action
    setIsMoving(isMoving) {
        this.isMoving = isMoving;
    }

    @action
    setTotalDistance(totalDistance) {
        this.totalDistance = totalDistance;
    }

    @action
    addCoords(coords) {
        this.currentTrack.push(coords);
    }

    @action
    setBackgroundGeoLocationReady(state = false) {
        this.backgroundGeoLocationReady = state;
    }

    @action
    setGeoLocationState(state) {
        this.geoLocationState = state;
    }

    @action
    setCurrentLocation(location) {
        this.currentLocation = location;
    }

    @action
    updateCurrentLocation(location) {
        this.currentLocation = location
    }

    @action
    setActivity(activity) {
        this.activity = activity;
    }

    @action
    setAltitude(altitude) {
        this.altitude = altitude;
    }
    @action
    setAccuracy(accuracy) {
        this.accuracy = accuracy;
    }
    @action
    setHeading(heading) {
        this.heading = heading;
    }

    @action
    setBattery(battery) {
        this.battery = battery;
    }

    @action
    setCoords(coords) {
        this.coords = coords;
    }

    @action
    setTrackingEnabled(isEnabled) {
        this.trackingEnabled = isEnabled;
    }

    @action
    findPublicUser(user) {
        if (!this.publicUsers) {
            return null;
        }
        const idx = this.publicUsers?.findIndex(e => e.id === user);
        if (idx > -1) {
            return this.publicUsers[idx];
        }
    }

    @action
    findPublicTeamByMembers(user) {
        if (!user || !this.publicTeams) {
            return [];
        }
        const teams = this.publicTeams.filter(e => e.members?.indexOf(user) > -1);
        return teams;
    }

    @action
    getWorkoutNavigation(workoutId) {
        const idx = this.workouts?.findIndex(e => e.id === workoutId);
        return {
            idx,
            previous: toJS(this.workouts[idx - 1]),
            next: toJS(this.workouts[idx + 1]),
            total: this.workouts.length,
        };
    }

    @action
    plotGeocluster() {
        if (this.geocluster && this.geocluster.length > 0) {
            this.waypoints2 = [];
            this.waypoints3 = [];
            for (let i = 0, l = this.geocluster.length; i < l; i += 1) {
                const line = this.geocluster[i];
                const wp = {
                    lat: line.centroid[0],
                    lng: line.centroid[1],
                };
                this.waypoints2.push(wp);
                line.elements.forEach((e) => {
                    const p = {
                        lat: e[0],
                        lng: e[1],
                    };
                    this.waypoints3.push(p);
                });
            }
            console.log(this.waypoints2);
        }
    }

    // @action
    // afterLoad(response) {
    //     consoleLog(response);
    // }

    @action
    appendLocation(position) {
        // GeolocationCoordinates.latitude Read only Secure context
        //     Returns a double representing the position's latitude in decimal degrees.
        // GeolocationCoordinates.longitude Read only Secure context
        //     Returns a double representing the position's longitude in decimal degrees.
        // GeolocationCoordinates.altitude Read only Secure context
        //     Returns a double representing the position's altitude in meters, relative to sea level. This value can be null if the implementation cannot provide the data.
        // GeolocationCoordinates.accuracy Read only Secure context
        //     Returns a double representing the accuracy of the latitude and longitude properties, expressed in meters.
        // GeolocationCoordinates.altitudeAccuracy Read only Secure context
        //     Returns a double representing the accuracy of the altitude expressed in meters. This value can be null.
        // GeolocationCoordinates.heading Read only Secure context
        //     Returns a double representing the direction in which the device is traveling. This value, specified in degrees, indicates how far off from heading true north the device is. 0 degrees represents true north, and the direction is determined clockwise (which means that east is 90 degrees and west is 270 degrees). If speed is 0, heading is NaN. If the device is unable to provide heading information, this value is null.
        // GeolocationCoordinates.speed Read only Secure context
        //     Returns a double representing the velocity of the device in meters per second. This value can be null.
        //
        // latitude : double
        // longitude : double
        // altitude : double, meters above sea level
        // accuracy : accuracy or radius of accuracy in meters
        // altitudeAccuracy : accuracy or radius of accuracy in meters
        // heading : how many degrees from true North the device is moving
        // speed : velocity in meters/second the device is moving
        const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
        };
        this.workoutLocations.push({ ...coords, timestamp: position.timestamp });
        if (this.workoutLocations.length > 100) {
            this.saveTrack();
        }
    }

    @action
    startTracking = () => {
        this.isTracking = true;
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 500,
        };
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => this.appendLocation(position));
            this.watchLocationId = navigator.geolocation.watchPosition(
                position => this.appendLocation(position),
                err => console.error(err),
                options,
            );
        } else {
            console.error('Geolocation API not supported.');
        }
    }

    @action
    stopTracking = () => {
        this.isTracking = false;
        navigator.geolocation.clearWatch(this.watchLocationId);
        this.saveTrack();
    }

    @action
    saveTrack() {
        const tracks = [...this.workoutLocations];
        this.workoutLocations = [];
        this.saveField(this.workout.id, 'tracks', tracks);
    }

    @action
    setCordovaActiveTrackingParams(params) {
        this.cordovaActiveTrackingParams = params;
    }

    // Cordova tracking
    async cordovaGetState() {
        consoleLog('cordovaGetState');
        this.apiServer = util.getApiServer();
        this.jwtToken = util.getJwtToken();
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const state = await this.bgGeo.getState();
            this.updatePluginState(state);

            consoleLog('[cordovaGetState] ', state.enabled, state.trackingMode, state.params);
            if (state.trackingMode || state.params) {
                if (state.trackingMode === 1) {
                    this.bgGeo.changePace(true, () => {
                        consoleLog('- changePace: plugin is now tracking');
                    });
                }
                const { odometer, params } = state;
                if (state.enabled) {
                    this.cordovaSetToRunning();
                }
                if (params && params.workout) {
                    this.setCordovaActiveTrackingParams(params);
                    const { workout, team } = params;
                    if (workout && team) {
                        this.setActiveTrackingWorkoutId(workout);
                        this.setActiveTrackingTeamId(team);
                    }
                }
                this.setTotalDistance(odometer);
                this.setGeoLocationState(state);
                if (params.workout) {
                    await this.load(params.workout);
                    if (this.workout.date) {
                        consoleLog('[cordovaGetState workout.date] ', this.workout.date);
                        const now = Math.floor(new Date().getTime() / 1000);
                        let startEpoch = Math.floor(new Date(this.workout.date).getTime() / 1000);
                        if (startEpoch > now) {
                            startEpoch = now - 3600;
                        }
                        consoleLog('[cordovaGetState workout.date startEpoch] ', startEpoch);
                        consoleLog('[cordovaGetState workout.date now] ', now);
                        this.cordovaSetTimes({
                            startTime: startEpoch,
                            lastTime: now,
                            totalTime: now - startEpoch,
                        });
                        this.cordovaTickTracking();
                    }
                }
                if (this.cordovaActiveTrackingParams && this.cordovaActiveTrackingParams.workout) {
                    await this.bgGeo.setConfig(
                        this.corovaConfig({ params: { ...this.cordovaActiveTrackingParams } })
                    );
                }
            }
            return {
                enabled: state.enabled,
                trackingMode: state.trackingMode,
            };
        }
        return {};
    }

    @action
    cordovaSetToRunning(value = true) {
        this.isRunning = value;
    }

    @action
    setPowerSaveStatus(status = false) {
        this.powerSaveStatus = status;
    }

    @action
    setProviderState(state = false) {
        this.providerState = state;
    }

    @action
    setLog(log = null) {
        this.log = log;
    }

    @action
    cordovaSetTimes = ({ lastTime, totalTime, startTime, laps, totalDistance, currentSpeed }) => {
        if (util.isDefined(lastTime)) {
            this.lastTime = lastTime;
        }
        if (util.isDefined(totalTime)) {
            this.totalTime = totalTime;
        }
        if (util.isDefined(startTime)) {
            this.startTime = startTime;
        }
        if (util.isDefined(laps)) {
            this.laps = laps;
        }
        if (util.isDefined(totalDistance)) {
            this.totalDistance = totalDistance;
        }
        if (util.isDefined(currentSpeed)) {
            this.currentSpeed = currentSpeed;
        }
    }

    cordovaSetOdometer = (distance) => {
        // this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            this.bgGeo.setOdometer(distance).then((location) => {
                // This is the location where odometer was set at.
                consoleLog('[setOdometer] success: ', location);
            });
        }
    }

    @action
    cordovaTickTracking = () => {
        if (!this.isRunning) {
            this.cordovaStopTicker();
            return false;
        }
        const now = parseInt(new Date().getTime() / 1000, 10);
        this.totalTime += now - this.lastTime;
        this.lastTime = now;
        this.cordovaTimer = setTimeout(() => this.cordovaTickTracking(), 1000);
    }

    @action
    cordovaStopTicker = () => {
        clearTimeout(this.cordovaTimer);
    }

    @action
    cordovaInitTracking = () => {
        consoleLog('cordovaInitTracking');
        // https://github.com/transistorsoft/cordova-background-geolocation-lt
        // 1.  Listen to events
        // const { enabled } = this.cordovaGetState();
        // if (enabled) {
        //     // return true;
        // }
        if (this.cordovaInitSet) {
            return true;
        }
        // this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            this.bgGeo.onLocation(location => {
                // Fired with each recorded Location
                // {
                //     "timestamp":     [Date],     // <-- Javascript Date instance
                //     "event":         [String],    // <-- motionchange|geofence|heartbeat
                //     "is_moving":     [Boolean],  // <-- The motion-state when location was recorded.
                //     "uuid":          [String],   // <-- Universally unique identifier
                //     "coords": {
                //         "latitude":  [Double],
                //         "longitude": [Double],
                //         "accuracy":  [Double],
                //         "speed":     [Double],
                //         "heading":   [Double],
                //         "altitude":  [Double]
                //     },
                //     "activity": {
                //         "type": [still|on_foot|walking|running|in_vehicle|on_bicycle],
                //         "confidence": [0-100%]
                //     },
                //     "battery": {
                //         "level": [Double],
                //         "is_charging": [Boolean]
                //     },
                //     "odometer": [Double/meters]
                // }
                consoleLog('[location] -', location);
                if (location.is_moving) {
                    this.setCurrentSpeed(location.coords.speed);
                } else {
                    this.setCurrentSpeed(0);
                }
                this.addCoords(location.coords);
                this.updateCurrentLocation(location);
                this.setTotalDistance(location.odometer);
                this.setActivity(location.activity);
                this.setBattery(location.battery);
                this.setCoords(location.coords);

                this.setAltitude(location.coords.altitude);
                this.setAccuracy(location.coords.accuracy);
                this.setHeading(location.coords.heading);
                this.shouldGetWeather(location);
            });

            this.bgGeo.onMotionChange((event) => {
                // Fired when the plugin changes state between moving / stationary
                consoleLog('[motionchange] -', event.isMoving, event.location);
                this.setIsMoving(event.isMoving);
                if (!event.isMoving) {
                    this.setCurrentSpeed(0);
                }
            });

            this.bgGeo.onHttp((response) => {
                consoleLog('[http] - ', response.success, response.status, response.responseText);
            });

            this.bgGeo.onProviderChange((event) => {
                consoleLog('[providerchange] -', event.status, event.enabled, event.gps, event.network);
                switch(event.status) {
                    case this.bgGeo.AUTHORIZATION_STATUS_NOT_DETERMINED:
                        // iOS only
                        consoleLog('- Location authorization not determined');
                        this.localUpdateField('cordovaAuthorizationStatus', 'NOT DETERMINED');
                        break;
                    case this.bgGeo.AUTHORIZATION_STATUS_RESTRICTED:
                        // iOS only
                        consoleLog('- Location authorization restricted');
                        this.localUpdateField('cordovaAuthorizationStatus', 'RESTRICTED');
                        break;
                    case this.bgGeo.AUTHORIZATION_STATUS_DENIED:
                        // Android & iOS
                        consoleLog('- Location authorization denied');
                        this.localUpdateField('cordovaAuthorizationStatus', 'DENIED');
                        break;
                    case this.bgGeo.AUTHORIZATION_STATUS_ALWAYS:
                        // Android & iOS
                        consoleLog('- Location always granted');
                        this.localUpdateField('cordovaAuthorizationStatus', 'ALWAYS');
                        break;
                    case this.bgGeo.AUTHORIZATION_STATUS_WHEN_IN_USE:
                        // iOS only
                        consoleLog('- Location WhenInUse granted');
                        this.localUpdateField('cordovaAuthorizationStatus', 'WHEN_IN_USE');
                        break;
                }
                const authorizationStatus = event.authorizationStatus;
                if (authorizationStatus == this.bgGeo.ACCURACY_AUTHORIZATION_REDUCED) {
                    this.localUpdateField('gpsAccuracy', false);
                }
            });

            this.bgGeo.onHeartbeat((event) => {
                if (!this.backgroundGeoLocationReady) { return false; }
                consoleLog('[onHeartbeat] ', event);
                // You could request a new location if you wish.
                this.bgGeo.getCurrentPosition({
                    samples: 1,
                    persist: true,
                }).then((location) => {
                    consoleLog('[getCurrentPosition] ', location);
                });
            });

            this.bgGeo.onEnabledChange((isEnabled) => {
                console.log('[onEnabledChanged] isEnabled? ', isEnabled);
                this.setTrackingEnabled(isEnabled);
            });

            this.bgGeo.onConnectivityChange((event) => {
                console.log('[onConnectivityChange] ', event);
            });

            this.bgGeo.onPowerSaveChange((isPowerSaveMode) => {
                console.log('[onPowerSaveChange: ', isPowerSaveMode);
                this.setPowerSaveStatus(isPowerSaveMode);
            });

            this.cordovaInitSet = true;
        }
    }

    corovaConfig = ({ params = {} }) => {
        this.apiServer = util.getApiServer();
        this.jwtToken = util.getJwtToken();

        const config = {
            reset: false,
            debug: false,
            logLevel: isDevelopment ? this.bgGeo.LOG_LEVEL_VERBOSE : this.bgGeo.LOG_LEVEL_ERROR,

            // locationAuthorizationRequest: 'Always',
            locationAuthorizationRequest: 'WhenInUse',
            activityType: this.bgGeo.ACTIVITY_TYPE_FITNESS,

            desiredAccuracy: this.bgGeo.DESIRED_ACCURACY_HIGH,
            desiredOdometerAccuracy: 10,
            distanceFilter: 10,
            stationaryRadius: 10,   // [iOS only] The minimum distance the device must move beyond the stationary location for aggressive background-tracking to engage.
            disableElasticity: true,

            allowIdenticalLocations: true, // [Android only] Allow recording locations which are duplicates of the previous.

            disableStopDetection: true, // Disables the accelerometer-based Stop-detection System.
            pausesLocationUpdatesAutomatically: false, // [iOS only] Configure iOS location API to never automatically turn off.

            locationAuthorizationAlert: {
                titleWhenNotEnabled: 'Location-services not enabled!',
                titleWhenOff: 'Location-services are OFF!',
                instructions: 'You must enable "WhenInUse" in location-services, to allow this app to track your location.',
                cancelButton: 'Cancel',
                settingsButton: 'Settings',
            },

            showsBackgroundLocationIndicator: true,
            url: `${this.apiServer}/api/workouts/cordova-track`,
            headers: {
                Authorization: `Bearer ${this.jwtToken}`,
            },
            params,
            autoSync: true,
            autoSyncThreshold: 50,
            batchSync: true,
            // maxBatchSize: 5,

            backgroundPermissionRationale: { // (Android 11+) Configure the dialog presented to the user when Always location permission is requested.
                title: `Allow {applicationName} to access to this device's location in the background?`,
                message: `In order to track your workouts in the background, please enable {backgroundPermissionOptionLabel} location permission`,
                positiveAction: `Change to {backgroundPermissionOptionLabel}`,
                negativeAction: `Cancel`,
            },

            startOnBoot: true,        // Controls whether to resume location-tracking after device is rebooted.
            stopOnTerminate: false,   // Don't stop tracking when app is terminated.
            stopOnStationary: false,  // Automatically BackgroundGeolocation.stop when the stopTimeout elapses.
            stopDetectionDelay: 600,  // [iOS only] Allows the iOS stop-detection system to be delayed from activating.
            // stopTimeout: 10,       // Change state to stationary after 1 min with device "still"
            foregroundService: true,  // Prevent Android from terminating service due to memory pressure from other apps.
            enableHeadless: true,     // [Android only] Enables "Headless" operation allowing you to respond to events after you app has been terminated
            preventSuspend: true,     // [iOS only] Prevent iOS from suspending your application in the background after location-services have been switched off.
            heartbeatInterval: 60,    // <-- heartbeat event every 60s

            locationsOrderDirection: 'ASC',
            maxDaysToPersist: 7,     // Maximum number of days to store a geolocation in plugin's SQLite database.

            logMaxDays: 3,           // Maximum number of days to store logs in plugin's SQLite database.
        };
        return config;
    }

    loadWorkoutInProgress = async (workoutid) => {
        await this.load(workoutid, {}, { limit: 1 });
        if (this.workout && this.workout.id) {
            const startEpoch = Math.floor(new Date(this.workout.date).getTime() / 1000);
            const now = Math.floor(new Date().getTime() / 1000);
            this.cordovaSetTimes({
                startTime: startEpoch,
                lastTime: now,
                totalTime: now - startEpoch,
            });
            let distanceKm = this.workout.distanceKm;
            if (distanceKm <= 0 && this.workout.gpxInfo && this.workout.gpxInfo.calculatedTotalDistance) {
                distanceKm = this.workout.gpxInfo.calculatedTotalDistance;
            }
            this.setTotalDistance(distanceKm * 1000);
            this.cordovaSetOdometer(distanceKm * 1000);
            this.cordovaTickTracking();
        }
    }
    cordovaStartPlugin = async () => {
        consoleLog('cordovaStartPlugin');
        // consoleLog(`${this.apiServer}/api/workouts/cordova-track`);
        this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            this.cordovaInitTracking();
            // Execute #ready method:
            return new Promise((resolve, reject) => {
                this.bgGeo.ready(this.corovaConfig({}), async (state) => {
                    this.updatePluginState(state);
                    await this.cordovaGetState();
                    await this.cordovaProviderState();
                    await this.cordovaIsPowerSaveMode();

                    // Module is ready to be used.
                    consoleLog('BackgroundGeolocation is configured and ready to use', state);

                    const { odometer = 0, params, enabled, trackingMode } = state;
                    if (params && params.workout) {
                        this.setCordovaActiveTrackingParams(params);
                        await this.loadWorkoutInProgress(params.workout);
                    }
                    if (enabled) {
                        this.cordovaSetToRunning();
                    }
                    this.setTotalDistance(odometer);
                    this.setBackgroundGeoLocationReady(true);
                    // if (state.enabled) {
                    //     return true;
                    // }
                    resolve();
                });
            });
        }
    }
    cordovaStartTracking = async ({ workout, team, user }) => {
        this.apiServer = util.getApiServer();
        this.jwtToken = util.getJwtToken();

        let hrStart = this.hrStart();

        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            let state = await this.bgGeo.getState();
            hrStart = this.addLog(hrStart, 'await this.bgGeo.getState', 'cordovaStartTracking');

            this.updatePluginState(state);

            consoleLog('[cordovaStartTracking] ', state.enabled, state.trackingMode, state);
            const configState = await this.bgGeo.setConfig(
                this.corovaConfig({ params: { workout, team } })
            );
            hrStart = this.addLog(hrStart, 'await this.bgGeo.setConfig', 'cordovaStartTracking');
            consoleLog('[setConfig] success: ', { params: { workout, team }, configState });

            const startState = await this.bgGeo.start();
            hrStart = this.addLog(hrStart, 'await this.bgGeo.start', 'cordovaStartTracking');

            state = await this.bgGeo.getState();
            hrStart = this.addLog(hrStart, 'await this.bgGeo.getState', 'cordovaStartTracking');

            this.updatePluginState(state);
            consoleLog('- BackgroundGeolocation tracking started', startState);
            await this.bgGeo.changePace(true);
            this.addLog(hrStart, 'await this.bgGeo.changePace', 'cordovaStartTracking');

            consoleLog('- changePace: plugin is now tracking');

            const now = parseInt(new Date().getTime() / 1000, 10);
            this.cordovaSetTimes({
                startTime: now,
                lastTime: now,
                totalTime: 0,
            });
            this.cordovaSetToRunning();
            this.cordovaTickTracking();
            this.setActiveTrackingWorkoutId(workout);
            this.setActiveTrackingTeamId(team);
            this.setCordovaActiveTrackingParams({ workout, team });

            this.postLog({ type: 'cordova', deviceInfo: user.deviceInfo, currentLocation: user.currentLocation });
        }
    }
    cordovaResumeTracking = async () => {
        // consoleLog(`${this.apiServer}/api/workouts/cordova-track`);
        this.cordovaSetToRunning();
        const now = parseInt(new Date().getTime() / 1000, 10);
        this.cordovaSetTimes({
            lastTime: now,
        });
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const state = await this.bgGeo.getState();
            this.updatePluginState(state);

            consoleLog('[state] ', state.enabled, state.trackingMode);
            if (!state.enabled) {
                await this.bgGeo.start();
                consoleLog('- BackgroundGeolocation tracking resumed');
            }
            this.bgGeo.changePace(true);
            consoleLog('- changePace: plugin is now tracking');
        }
        this.cordovaTickTracking();
    }
    cordovaPauseTracking = async () => {
        this.cordovaSetToRunning(false);
        this.setCurrentSpeed(0);
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            await this.bgGeo.stop();
            const state = await this.bgGeo.getState();
            this.updatePluginState(state);
        }
    }
    cordovaResetTracking = async () => {
        consoleLog('cordovaResetTracking');
        this.apiServer = util.getApiServer();
        this.jwtToken = util.getJwtToken();
        let hrStart = this.hrStart();

        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const state = await this.bgGeo.getState();
            hrStart = this.addLog(hrStart, 'await this.bgGeo.getState', 'cordovaResetTracking');

            this.updatePluginState(state);
            if (state.enabled) {
                await this.bgGeo.stop();
                hrStart = this.addLog(hrStart, 'await this.bgGeo.stop', 'cordovaResetTracking');
                consoleLog('[stop] success');
            }
            if (state.odometer > 0) {
                // await this.bgGeo.resetOdometer();
                this.bgGeo.resetOdometer().then(location => {
                    // This is the location where odometer was set at.
                    this.addLog(hrStart, 'this.bgGeo.resetOdometer().then', 'cordovaResetTracking');
                    this.postLog({ type: 'cordova' });
                    consoleLog('[resetOdometer] success', location);
                  });
                await this.bgGeo.destroyLocations();
                hrStart = this.addLog(hrStart, 'await this.bgGeo.destroyLocations', 'cordovaResetTracking');
                consoleLog('[destroyLocations] success');
            }
            await this.bgGeo.setConfig(
                this.corovaConfig({ params: { workout: null, team: null } })
            );
            this.addLog(hrStart, 'await this.bgGeo.setConfig', 'cordovaResetTracking');

            this.cordovaStopTicker();
            this.setActiveTrackingWorkoutId(null);
            this.setActiveTrackingTeamId(null);
            this.cordovaSetToRunning(false);
            this.setCurrentSpeed(0);
            this.setCordovaActiveTrackingParams({ workout: null, team: null });
            this.cordovaSetTimes({
                startTime: 0,
                lastTime: 0,
                totalTime: 0,
                laps: [],
                totalDistance: 0,
                currentSpeed: 0,
            });
            this.postLog({ type: 'cordova' });
        }
    }
    // @action
    // cordovaLapTracking() {
    // }
    cordovaSaveTracking = async () => {
        // TODO: Save stuff
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            try {
                const records = await this.bgGeo.sync();
                // const { workout } = this.cordovaActiveTrackingParams;
                // await this.load(workout, {}, { skipScreenshot: 1 });
                consoleLog('[sync] success: ');
            } catch(err) {
                console.log('[sync] error: ', err);
            }
        }
    }

    cordovaGetCurrentPosition = async (force) => {
        // this.bgGeo = window.BackgroundGeolocation;
        if (!this.backgroundGeoLocationReady) { return false; }

        if (this.bgGeo) {
            const location = await this.bgGeo.getCurrentPosition({
                timeout: 1,              // 1 second timeout to fetch location
                maximumAge: 3600 * 1000, // Accept the last-known-location if not older than 5000 ms.
                desiredAccuracy: 100,    // Try to fetch a location with an accuracy of `100` meters.
                samples: 1,              // How many location samples to attempt.
            });
            this.updateCurrentLocation(location);
            this.setCoords(location.coords);
            await util.fetchApi('/api/users/location', { publish: true, method: 'POST' }, { location });
            return location;
        }
        return null;
    }

    cordovaIsPowerSaveMode = async () => {
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const powerSaveStatus = await this.bgGeo.isPowerSaveMode();
            this.setPowerSaveStatus(powerSaveStatus);
        }
    }

    cordovaDeviceInfo = async () => {
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const deviceInfo = await this.bgGeo.getDeviceInfo();
            return deviceInfo;
        }
    }

    cordovaProviderState = async () => {
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const providerState = await this.bgGeo.getProviderState();
            this.setProviderState(providerState);
            consoleLog('[providerchange] -', providerState.status, providerState.enabled, providerState.gps, providerState.network);
            switch(providerState.status) {
                case this.bgGeo.AUTHORIZATION_STATUS_NOT_DETERMINED:
                    // iOS only
                    consoleLog('- Location authorization not determined');
                    this.localUpdateField('cordovaAuthorizationStatus', 'NOT DETERMINED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_RESTRICTED:
                    // iOS only
                    consoleLog('- Location authorization restricted');
                    this.localUpdateField('cordovaAuthorizationStatus', 'RESTRICTED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_DENIED:
                    // Android & iOS
                    consoleLog('- Location authorization denied');
                    this.localUpdateField('cordovaAuthorizationStatus', 'DENIED');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_ALWAYS:
                    // Android & iOS
                    consoleLog('- Location always granted');
                    this.localUpdateField('cordovaAuthorizationStatus', 'ALWAYS');
                    break;
                case this.bgGeo.AUTHORIZATION_STATUS_WHEN_IN_USE:
                    // iOS only
                    consoleLog('- Location WhenInUse granted');
                    this.localUpdateField('cordovaAuthorizationStatus', 'WHEN_IN_USE');
                    break;
            }
        }
    }

    cordovaGetLog = async () => {
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            const log = await this.bgGeo.logger.getLog();
            this.setLog(log);
        }
    }

    cordovaSendLog = async () => {
        if (this.bgGeo) {
            await this.bgGeo.logger.emailLog('10@litt.no');
        }
    }

    cordovaUploadLog = async () => {
        if (this.bgGeo) {
            console.log('cordovaUploadLog');
            this.apiServer = util.getApiServer();
            try {
                await this.bgGeo.logger.uploadLog(`${this.apiServer}/api/logs/`);
            } catch(err) {
                console.log(err);
            }
        }
    }

    cordovaDestroyLog = async () => {
        //this.bgGeo = window.BackgroundGeolocation;
        if (this.bgGeo) {
            await this.bgGeo.logger.destroyLog();
        }
    }
    // /Cordova tracking

    cordovaSettingsOpen = async (setting = 'settings') => {
        this.cordova = window.cordova;
        if (this.cordova?.plugins?.settings) {
            this.cordova.plugins.settings.open(setting, () => {
                    console.log('opened settings', setting);
                },
                () => {
                    console.log('failed to open settings', setting);
                }
            );
        } else {
            console.log('cordovaSettingsOpen is not active!');
        }
    }

    @action
    getLatests(cnt = 3) {
        const workouts = this.workouts.slice(0, cnt).map(e => toJS(e));
        return workouts;
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.workout.images)) {
            const idx = this.workout.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.workout.images.splice(idx, 1);
            }
        }
        const widx = this.workouts?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.workouts[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.workouts[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/workouts/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async duplicateWorkout({ id }) {
        const response = await util.fetchApi(`/api/workouts/duplicate/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async workoutResults() {
        const response = await util.fetchApi(`/api/workouts/competition-results`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('competitionResults', response.data);
                this.localUpdateField('competitionAllResults', response.included.allResults);
                this.localUpdateField('competitionTeams', response.included.teams);
                return response;

            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async makeTrackFromWorkout({ id }) {
        const response = await util.fetchApi(`/api/workouts/create-track/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    removeTeamLocal({ id, team }) {
        if (!id) {
            if (util.isArray(this.newWorkout.teams)) {
                const idx = this.newWorkout.teams?.findIndex(e => e === team);
                if (idx > -1) {
                    this.newWorkout.teams.splice(idx, 1);
                }
            }
            return true;
        }
        if (util.isArray(this.workout.teams)) {
            const idx = this.workout.teams?.findIndex(e => e === team);
            if (idx > -1) {
                this.workout.teams.splice(idx, 1);
            }
        }
        const widx = this.workouts?.findIndex(e => e.id === id);
        if (widx > -1 && util.isArray(this.workouts[widx].teams)) {
            const idx = this.workouts[widx].teams?.findIndex(e => e === team);
            if (idx > -1) {
                this.workouts[widx].teams.splice(idx, 1);
            }
        }
    }

    @action
    addTeamLocal({ id, team }) {
        if (!id) {
            if (util.isArray(this.newWorkout.teams)) {
                const idx = this.newWorkout.teams?.findIndex(e => e === team);
                if (idx === -1) {
                    this.newWorkout.teams.push(team);
                }
            }
            return true;
        }
        if (util.isArray(this.workout.teams)) {
            const idx = this.workout.teams?.findIndex(e => e === team);
            if (idx === -1) {
                this.workout.teams.push(team);
            }
        }
        const widx = this.workouts?.findIndex(e => e.id === id);
        if (widx > -1) {
            if (!util.isArray(this.workouts[widx].teams)) {
                this.workouts[widx].teams = [];
            }
            const idx = this.workouts[widx].teams?.findIndex(e => e === team);
            if (idx === -1) {
                this.workouts[widx].teams.push(team);
            }
        }
    }

    async removeTeam({ id, team }) {
        if (!id) {
            return this.removeTeamLocal({ id, team });
        }
        const response = await util.fetchApi(`/api/workouts/${id}`, { publish: true, method: 'PATCH' }, { $pull: { teams: team } });
        switch (response.status) {
            case 202:
                this.removeTeamLocal({ id, team });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async addTeam({ id, team }) {
        if (!id) {
            return this.addTeamLocal({ id, team });
        }
        const response = await util.fetchApi(`/api/workouts/${id}`, { publish: true, method: 'PATCH' }, { teams: team });
        switch (response.status) {
            case 202:
                this.addTeamLocal({ id, team });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToWorkout({ id, data }) {
        if (util.isUndefined(this.workout.likes)) {
            this.workouts.likes = [];
        }
        if (util.isArray(this.workout.likes)) {
            this.workout.likes.push(data);
        }
        const idx = this.workouts?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.workouts[idx].likes)) {
                this.workouts[idx].likes = [];
            }
            this.workouts[idx].likes.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        if (util.isDefined(this.workout.comments)) {
            const commentIdx = this.workout.comments?.findIndex(e => e.id === commentId);
            const comment = this.workout.comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        const idx = this.workouts?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.workouts[idx].comments)) {
                const commentIdx = this.workouts[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.workouts[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        if (util.isDefined(this.workout.comments)) {
            const commentIdx = this.workout.comments?.findIndex(e => e.id === commentId);
            const comment = this.workout.comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        const idx = this.workouts?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.workouts[idx].comments)) {
                const commentIdx = this.workouts[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.workouts[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    addCommentToWorkout({ id, data }) {
        if (util.isArray(this.workout.comments)) {
            this.workout.comments.push(data);
        }
        const idx = this.workouts?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.workouts[idx].comments)) {
                this.workouts[idx].comments = [];
            }
            this.workouts[idx].comments.push(data);
        }
    }

    @action
    addCommentToWorkoutComment({ id, commentId, data }) {
        if (util.isDefined(this.workout.comments)) {
            const commentIdx = this.workout.comments?.findIndex(e => e.id === commentId);
            const comment = this.workout.comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        const idx = this.workouts?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.workouts[idx].comments)) {
                const commentIdx = this.workouts[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.workouts[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    async likeWorkout({ id }) {
        const response = await util.fetchApi(`/api/workouts/like/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToWorkout({ id, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeWorkoutComment({ id, commentId }) {
        const response = await util.fetchApi(`/api/workouts/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToComment({ id, commentId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeWorkoutCommentReply({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/workouts/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToCommentReply({ id, commentId, replyId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentWorkout({ id, comment }) {
        const response = await util.fetchApi(`/api/workouts/comment/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToWorkout({ id, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyWorkout({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/workouts/comment/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToWorkoutComment({ id, commentId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reimportStravaWorkout(id, workout) {
        const response = await util.fetchApi(`/api/strava/re-import-gpx`, { publish: true, method: 'GET' }, { id, workout });
        switch (response.status) {
            case 200:
                consoleLog(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reimportGarminWorkout(id, workout) {
        const response = await util.fetchApi(`/api/garmin/re-import-gpx`, { publish: true, method: 'GET' }, { id, workout });
        switch (response.status) {
            case 200:
                consoleLog(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reLoad(id) {
        const response = await util.fetchApi(`/api/workouts/${id}`, { publish: true, method: 'GET' }, { reload: true });
        switch (response.status) {
            case 200:
                consoleLog(response);
                this.screenshot(id);
                this.aggregations(id);
                if (response.data && response.data.id === id) {
                    this.localUpdateField('workout', response.data);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadPublic(id) {
        const response = await util.fetchApi(`/api/workouts/public/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                consoleLog(response);
                if (response.data && response.data.id === id) {
                    this.localUpdateField('workout', response.data);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getHeatmap(team) {
        const response = await util.fetchApi(`/api/workouts/heatmap`, { publish: true, method: 'GET' }, { team });
        switch (response.status) {
            case 200:
                this.localUpdateField('heatmap', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async sendGpxFile(workoutId) {
        const response = await util.fetchApi(`/api/workouts/send-gpx-file/${workoutId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async postScrollview(data = []) {
        if (!data || data?.length === 0) {
            return false;
        }
        const response = await util.fetchApi(`/api/scrollview/`, { publish: false, method: 'POST' }, { data });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async screenshot(id) {
        const screenshotResponse = await util.fetchApi(`/api/workouts/${id}/screenshot`, { publish: true, method: 'GET' }, {});
        switch (screenshotResponse.status) {
            case 200:
                return screenshotResponse;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async aggregations(id) {
        const aggregationsResponse = await util.fetchApi(`/api/workouts/${id}/aggregations`, { publish: true, method: 'GET' }, {});
        switch (aggregationsResponse.status) {
            case 200:
                return aggregationsResponse;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async adminEdit({ workout, field, value }) {
        const response = await util.fetchApi(`/api/races/workout/${workout}`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
    async adminCreate({ musher, team, distanceKm, elevation, duration, rest, type, date }) {
        const response = await util.fetchApi(`/api/races/workout/`, { publish: true, method: 'POST' }, {
            musher, team, distanceKm, elevation, duration, rest, type, date,
        });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    hrStart = () => {
        return new Date().getTime();
    }

    @action
    addLog = (hrStart, message, title) => {
        this.logs.push({
            timeused: new Date().getTime() - hrStart,
            title,
            message,
        });
        return this.hrStart();
    }

    @action
    getLogs = () => {
        const logs = [...this.logs];
        this.logs = [];
        return logs;
    }

    printLogs = () => {
        this.logs.forEach((e) => {
            console.log(`${e.message}: ${e.timeused} ms`);
        });
    }

    async postLog({ type = 'workoutStore', deviceInfo, currentLocation }) {
        const lines = this.getLogs();
        const response = await util.fetchApi(`/api/logs/`, { publish: true, method: 'POST' }, {
            lines,
            deviceInfo,
            currentLocation,
            type,
        });
        switch (response.status) {
            case 201:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    setCurrentWeather(weather) {
        if (weather) {
            this.currentWeather = weather;
        }
    }

    @computed
    get currentTemp() {
        return this.currentWeather[0]?.instant?.details?.air_temperature;
    }

    @computed
    get currentWeatherNow() {
        // return this.currentWeather[0]?.instant?.details;
        return this.currentWeather[0];
    }

    @computed
    get currentWeatherMarkers() {
        if (!this.workout.yrWeatherOnTrail) {
            return [];
        }
        const markers = [];
        const geoHashes = {};
        try {
            this.workout.yrWeatherOnTrail.forEach(e => {
                if (e.lat && e.lon) {
                    const geohash = Geohash.encode(e.lat, e.lon, 6);
                    if (!geoHashes[geohash]) {
                        markers.push({
                            lat: e.lat,
                            lng: e.lon,
                            temp: e.instant.details.air_temperature,
                            windSpeed: e.instant.details.wind_speed,
                            windDirection: e.instant.details.wind_from_direction,
                            time: e.time,
                        });
                        geoHashes[geohash] = true;
                    }
                }
            });
        } catch (err) {
            console.log(err);
        }
        return markers;
    }

    @computed
    get currentTrackGeoJson() {
        const geoJson = {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                properties: {
                    name: 'Morning run',
                    time: null,
                    coordTimes: [],
                },
                geometry: {
                    type: 'LineString',
                    coordinates:
                        this.currentTrack.map(coord => [coord.longitude, coord.latitude, coord.altitude]),
                }
            }]
        };
        return geoJson;
        // return JSON.stringify(geoJson);
    }

    @action
    setLastWorkout(workout) {
        this.lastWorkout = workout;
    }

    @action
    setNewWorkout(workout) {
        this.newWorkout = {};

        const timeOfDay = util.timeOfDay(new Date(), true);
        this.newWorkout.name = `${timeOfDay} run`;

        this.newWorkout.dogs = workout.dogs || [];
        this.newWorkout.dogsLeft = workout.dogsLeft || [];
        this.newWorkout.dogsRight = workout.dogsRight || [];
        this.newWorkout.mushers = workout.mushers;
        this.newWorkout.type = workout.type || 2;
        this.newWorkout.equipment = workout.equipment || 8;
        this.newWorkout.sledWeight = workout.sledWeight;
    }

    async loadLastWorkout(team, setNewWorkout = false) {
        const cutOffDate = new Date();
        const daysBack = 30;
        cutOffDate.setDate(cutOffDate.getDate() - daysBack);
        const response = await util.fetchApi(`/api/workouts/`, { publish: true, method: 'GET' }, {
            team,
            skipUpdate: 1,
            limit: 1,
            sort: '-createdDate',
            skipScreenshot: 1,
            date: { $gt: cutOffDate },
        });
        switch (response.status) {
            case 200:
                const workouts = response.data;
                this.setLastWorkout(workouts[0]);
                if (setNewWorkout) {
                    this.setNewWorkout(workouts[0]);
                }
                return this.lastWorkout;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    setLastWeatherUpdate(utime) {
        this.lastWeatherUpdate = utime;
    }

    shouldGetWeather = async location => {
        const now = Math.floor(new Date().getTime() / 1000);
        const sec30Min = 30 * 60;
        const workoutId = this.workout.id;

        if (now > this.lastWeatherUpdate + sec30Min) {
            this.setLastWeatherUpdate(now);
            await this.getWeather({
                lat: location.coords.latitude,
                lon: location.coords.longitude,
                altitude: location.coords.altitude,
                workout: workoutId,
                force: 1,
            });
        }
    }

    async getWeather({ lat, lon, altitude, force, workout }) {
        if (!lat || !lon) {
            return [];
        }
        const response = await util.fetchApi(`/api/yr/`, { publish: true, method: 'GET' }, { lat, lon, altitude, force, workout });
        switch (response.status) {
            case 200:
                this.setCurrentWeather(response.data);
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new WorkoutStore();
export default store;
