import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import mu from '../lib/musher-util';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

class DogStore extends StoreModel {
    constructor() {
        super('dog', {
            namePlural: 'dogs',
            sort: 'shortname,name',
            limit: 100,
            api: {
                search: {
                    url: '/api/dogs/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/dogs/',
                    params: {},
                },
                save: {
                    url: '/api/dogs/',
                    params: {},
                },
            },
        });
    }

    @observable dog = {};

    @observable dogs = [];

    @observable publicDog = {};

    @observable publicTeam = {};

    @observable allDogs = [];

    @observable externalDogs = [];

    @observable workoutSummary = [];

    @observable workoutSummaryCurrentSeason = {};

    @observable workoutSummaryPreviousSeason = {};

    @observable workoutSummaryCurrent = [];

    @observable graphFitness = [];

    @observable graphFatigue = [];

    @observable graphForm = [];

    @observable vaccineStatuses = [];

    @observable vaccineLogStatuses = [];

    @observable historyStatuses = [];

    @observable publicViews = {};

    @observable publicViewsGraphData = [];

    @observable publicViewsGraphLegend = [];

    @observable currentTeam = util.get('currentTeam');

    @observable daysBack = util.get('dogWorkoutDaysBack') || 7;

    @action
    beforeLoad({ id, addData, queryFilter }) {
        if (id == this.dog.id) {
            return;
        }
        const dogPlaceholder = {
            name: '{placeholder}',
            description: '{placeholder}',
        };
        if (id) {
            this.dog = dogPlaceholder;
        } else {
            this.dogs = [
                dogPlaceholder,
                dogPlaceholder,
                dogPlaceholder,
            ];
        }
    }

    @action
    updateObjectField(object, key, value) {
        this[object][key] = value;
    }

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('DogStore.cleanupMemory');
        }
        this.localUpdateField('allDogs', []);
        this.localUpdateField('externalDogs', []);
        this.localUpdateField('workoutSummary', []);
        this.localUpdateField('workoutSummaryCurrentSeason', {});
        this.localUpdateField('workoutSummaryPreviousSeason', {});
        this.localUpdateField('workoutSummaryCurrent', []);
        this.localUpdateField('workoutSummaryCurrent', []);
        this.localUpdateField('graphFitness', []);
        this.localUpdateField('graphFatigue', []);
        this.localUpdateField('graphForm', []);
        this.localUpdateField('vaccineStatuses', []);
        this.localUpdateField('vaccineLogStatuses', []);
        this.localUpdateField('historyStatuses', []);
        this.localUpdateField('publicViewsGraphData', []);
        this.localUpdateField('publicViewsGraphLegend', []);
    }

    @action
    cleanupMemoryDetail() {
        if (isDevelopment) {
            console.log('DogStore.cleanupMemoryDetail');
        }
        this.localUpdateField('dog', []);
        this.localUpdateField('team', []);
        this.localUpdateField('user', []);
        this.localUpdateField('workoutSummary', {});
        this.localUpdateField('vaccineStatuses', {});
        this.localUpdateField('historyStatuses', []);
        this.localUpdateField('vaccineLogStatuses', []);
        this.localUpdateField('allDogs', []);
    }

    @action
    setCurrentTeam(team) {
        this.currentTeam = team;
        util.set('currentTeam', team);
    }

    @action
    setDaysBack(daysBack) {
        this.daysBack = daysBack;
        util.set('dogWorkoutDaysBack', daysBack);
    }

    @action
    findWorkoutSummary(dog, year) {
        const idx = this.workoutSummary?.findIndex(e => e.dog === dog && e.year === year);
        if (idx > -1) {
            return toJS(this.workoutSummary[idx]);
        }
    }

    @action
    findWorkoutSummaryCurrenSeason(dogId, year) {
        this.workoutSummaryCurrentSeason = this.findWorkoutSummary(dogId, year);
    }

    @action
    findWorkoutSummaryPreviousSeason(dogId, year) {
        this.workoutSummaryPreviousSeason = this.findWorkoutSummary(dogId, year);
    }

    @action
    findDogposition(position) {
        const idx = this.dogPositions?.findIndex(e => e.id === position);
        if (idx > -1) {
            const dogPosition = toJS(this.dogPositions[idx]);
            return dogPosition;
        }
    }

    @action
    findCurrentWorkoutSummary(dog) {
        const idx = this.workoutSummaryCurrent?.findIndex(e => e.dog === dog);
        if (idx > -1) {
            return toJS(this.workoutSummaryCurrent[idx]);
        }
    }

    @action
    findDog(dog) {
        const idx = this.allDogs?.findIndex(e => e.id === dog);
        if (idx > -1) {
            return this.allDogs[idx];
        }
    }

    @action
    findDogByChipId(chipId) {
        if (!chipId) {
            return null;
        }
        let idx = this.dogs?.findIndex(e => e.chipId === chipId);
        if (idx === -1) {
            idx = this.allDogs?.findIndex(e => e.chipId === chipId);
        }
        if (idx === -1) {
            idx = this.externalDogs?.findIndex(e => e.chipId === chipId);
        }
        if (idx > -1) {
            return toJS(this.dogs[idx]);
        }
    }

    @action
    findVaccineStatuses(dogId) {
        const vaccines = this.vaccineStatuses.filter(e => e.dogId === dogId).map(e => toJS(e));
        return vaccines;
    }

    @action
    findVaccineLogStatuses(dogChipId) {
        if (!dogChipId) {
            return [];
        }
        const vaccines = this.vaccineLogStatuses.filter(e => e.chipId === dogChipId).map(e => toJS(e));
        return vaccines;
    }

    @action
    findHistoryStatuses(dogId) {
        const statuses = this.historyStatuses.filter(e => e.dogId === dogId).map(e => toJS(e));
        return statuses;
    }

    @action
    findDogsByGender(gender) {
        const dogs = this.allDogs.filter(e => e.gender === gender).map(e => toJS(e));
        return dogs;
    }

    @action
    findChildren(parentChipId) {
        if (!parentChipId) {
            return [];
        }
        const dogs = [
            this.allDogs.filter(e => e.motherChipId === parentChipId || e.fatherChipId === parentChipId),
            this.externalDogs.filter(e => e.motherChipId === parentChipId || e.fatherChipId === parentChipId),
        ].flat();
        return dogs;
    }

    @action
    findSiblings(currentId, motherChipId, birth) {
        if (!currentId || !motherChipId || !birth) {
            return [];
        }
        const dogs = [
            this.allDogs.filter(e => e.id !== currentId && e.motherChipId === motherChipId && e.birth === birth),
            this.externalDogs.filter(e => e.id !== currentId && e.motherChipId === motherChipId && e.birth === birth),
        ].flat();
        return dogs;
    }

    @action
    findDogsByStatus(status) {
        const dogs = this.dogs.filter((e) => {
            if (status) {
                if (util.isArray(status)) {
                    return status?.indexOf(e.status) > -1;
                }
                return e.status === status;
            }
            return true;
        }).map(e => toJS(e));
        return dogs;
    }

    @action
    findDogsWithStatus() {
        const dogs = this.dogs.filter((e) => {
            return e.status === 1 ? false : !!e.status;
        }).map(e => toJS(e));
        return dogs;
    }

    @action
    findDogsWithNeeds() {
        const dogs = this.dogs.filter((e) => {
            return !!e.specialNeeds;
        }).map(e => toJS(e));
        return dogs;
    }

    @action
    setField(key, value) {
        this[key] = value;
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    plotFormGraph() {
        if (this.dog && this.dog.fitness) {
            this.graphFitness = this.dog.fitness.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
        if (this.dog && this.dog.fatigue) {
            this.graphFatigue = this.dog.fatigue.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
        if (this.dog && this.dog.form) {
            this.graphForm = this.dog.form.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
    }

    async loadPublicDetail(query) {
        this.localUpdateField('publicDog', {});
        this.localUpdateField('publicTeam', {});
        const response = await util.fetchApi(`/api/dogs/public/`, { publish: true, method: 'GET' }, { ...query });
        switch (response.status) {
            case 200:
                this.localUpdateField('publicDog', response.data);
                this.localUpdateField('publicTeam', response.included.team);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getDogsWithTraining({ daysBack }) {
        const response = await util.fetchApi('/api/dogs/', { publish: true, method: 'GET' }, { daysBack });
        switch (response.status) {
            case 200:
                if (response && response.included && response.included.workoutSummary) {
                    this.setField('workoutSummaryCurrent', response.included.workoutSummary);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'error', `Henting av feed feilet! ${response.status}: ${response.message}`);
                break;
        }
    }

    async loadAllDogs() {
        const response = await util.fetchApi('/api/dogs/', { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('allDogs', response.data);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'error', `Henting av feed feilet! ${response.status}: ${response.message}`);
                break;
        }
    }

    async loadExternalDogs(inputChipIds = []) {
        const chipIds = inputChipIds.filter((e) => {
            if (!e) {
                return false;
            }
            if (!this.findDogByChipId(e)) {
                return true;
            }
        });
        if (chipIds.length === 0) {
            return false;
        }
        const response = await util.fetchApi('/api/dogs/external/bychipid', { publish: true, method: 'GET' }, { chipIds });
        switch (response.status) {
            case 200:
                if (response.data) {
                    this.localUpdateField('externalDogs', response.data);
                }
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'error', `Henting av feed feilet! ${response.status}: ${response.message}`);
                break;
        }
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.dog.images)) {
            const idx = this.dog.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.dog.images.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.dogs[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.dogs[widx].images.splice(idx, 1);
            }
        }
    }

    @action
    removeFileLocal({ id, name }) {
        if (util.isArray(this.dog.files)) {
            const idx = this.dog.files?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.dog.files.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.dogs[widx].files?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.dogs[widx].files.splice(idx, 1);
            }
        }
    }

    @action
    removeLinkLocal({ id, name, linkId }) {
        if (util.isArray(this.dog.links)) {
            let idx;
            if (linkId) {
                idx = this.dog.links?.findIndex(e => e.id === linkId);
            } else {
                idx = this.dog.links?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.dog.links.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            let idx;
            if (linkId) {
                idx = this.dogs[widx].links?.findIndex(e => e.id === linkId);
            } else {
                idx = this.dogs[widx].links?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.dogs[widx].links.splice(idx, 1);
            }
        }
    }

    @action
    removeAchievementLocal({ id, name, achievementId }) {
        if (util.isArray(this.dog.achievements)) {
            let idx;
            if (achievementId) {
                idx = this.dog.achievements?.findIndex(e => e.id === achievementId);
            } else {
                idx = this.dog.achievements?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.dog.achievements.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            let idx;
            if (achievementId) {
                idx = this.dogs[widx].achievements?.findIndex(e => e.id === achievementId);
            } else {
                idx = this.dogs[widx].achievements?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.dogs[widx].achievements.splice(idx, 1);
            }
        }
    }

    @action
    removeTeamLocal({ id, team }) {
        if (util.isArray(this.dog.teams)) {
            const idx = this.dog.teams?.findIndex(e => e === team);
            if (idx > -1) {
                this.dog.teams.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1 && util.isArray(this.dogs[widx].teams)) {
            const idx = this.dogs[widx].teams?.findIndex(e => e === team);
            if (idx > -1) {
                this.dogs[widx].teams.splice(idx, 1);
            }
        }
    }

    @action
    addTeamLocal({ id, team }) {
        if (util.isArray(this.dog.teams)) {
            const idx = this.dog.teams?.findIndex(e => e === team);
            if (idx === -1) {
                this.dog.teams.push(team);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            if (!util.isArray(this.dogs[widx].teams)) {
                this.dogs[widx].teams = [];
            }
            const idx = this.dogs[widx].teams?.findIndex(e => e === team);
            if (idx === -1) {
                this.dogs[widx].teams.push(team);
            }
        }
        const aidx = this.allDogs?.findIndex(e => e.id === id);
        if (aidx > -1) {
            if (!util.isArray(this.allDogs[aidx].teams)) {
                this.allDogs[aidx].teams = [];
            }
            const idx = this.allDogs[aidx].teams?.findIndex(e => e === team);
            if (idx === -1) {
                this.allDogs[aidx].teams.push(team);
            }
        }
    }

    @action
    removeStatusLocal({ id, status }) {
        if (util.isArray(this.dog.statuses)) {
            const idx = this.dog.statuses?.findIndex(e => e === status);
            if (idx > -1) {
                this.dog.statuses.splice(idx, 1);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1 && util.isArray(this.dogs[widx].statuses)) {
            const idx = this.dogs[widx].statuses?.findIndex(e => e === status);
            if (idx > -1) {
                this.dogs[widx].statuses.splice(idx, 1);
            }
        }
    }

    @action
    addStatusLocal({ id, status }) {
        if (util.isArray(this.dog.statuses)) {
            const idx = this.dog.statuses?.findIndex(e => e === status);
            if (idx === -1) {
                this.dog.statuses.push(status);
            }
        }
        const widx = this.dogs?.findIndex(e => e.id === id);
        if (widx > -1) {
            if (!util.isArray(this.dogs[widx].statuses)) {
                this.dogs[widx].statuses = [];
            }
            const idx = this.dogs[widx].statuses?.findIndex(e => e === status);
            if (idx === -1) {
                this.dogs[widx].statuses.push(status);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeFile({ id, name: removeFileByName }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { removeFileByName });
        switch (response.status) {
            case 202:
                this.removeFileLocal({ id, name: removeFileByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeLink({ id, name: removeLinkByName, linkId: removeLinkById }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { removeLinkByName, removeLinkById });
        switch (response.status) {
            case 202:
                this.removeLinkLocal({ id, name: removeLinkByName, linkId: removeLinkById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeAchievement({ id, name: removeAchievementByName, achievementId: removeAchievementById }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { removeAchievementByName, removeAchievementById });
        switch (response.status) {
            case 202:
                this.removeAchievementLocal({ id, name: removeAchievementByName, achievementId: removeAchievementById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeTeam({ id, team }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { $pull: { teams: team } });
        switch (response.status) {
            case 202:
                this.removeTeamLocal({ id, team });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async addTeam({ id, team }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { teams: team });
        switch (response.status) {
            case 202:
                this.addTeamLocal({ id, team });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeStatus({ id, status }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { $pull: { statuses: status } });
        switch (response.status) {
            case 202:
                this.removeStatusLocal({ id, status });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async addStatus({ id, status }) {
        const response = await util.fetchApi(`/api/dogs/${id}`, { publish: true, method: 'PATCH' }, { statuses: status });
        switch (response.status) {
            case 202:
                this.addStatusLocal({ id, status });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getPublicViews(uuid) {
        const response = await util.fetchApi(`/api/dogs/summary/${uuid}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('publicViews', response.data);
                const { legends, data } = mu.getGraphData(response.data);
                this.localUpdateField('publicViewsGraphData', data);
                this.localUpdateField('publicViewsGraphLegend', legends);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async sendChiplistByEmail(chipIds) {
        const response = await util.fetchApi(`/api/dogs/chiplist/send`, { publish: true, method: 'POST' }, { chipIds });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new DogStore();
export default store;
