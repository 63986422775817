import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class ProfileLastTrophy extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetProfileLastTrophyList', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Profile'
        });
    }

    render() {
        const { imageElementScale } = this.state;

        const { submenu, height, isOverflow, isExpanded, filterText, sessionid, handleIntersection, key } = this.state;
        const { userStore, appState, trophyStore } = this.props.stores;
        const { darkmode } = appState;
		const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
		const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;

        const { trophies = [] } = trophyStore;

        const team = userStore.getCurrentTeam();
        // const lastTrophys = team.trophys?.length > 0 ? team.trophys.sort(mu.fieldSorter(['-createdDate', 'name'])).splice(0, 3) : []

        let colWidth = 12;
        if (trophies.length >= 4) {
            colWidth = 3;
        } else if (trophies.length === 3) {
            colWidth = 4;
        } else if (trophies.length === 2) {
            colWidth = 6;
        }

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} headerRight={`${team.trophys.length}/${trophies.length}`} onHeaderClick={this.onClick} onClick={this.onClick}>
            <div class='w-100 position-relative px-3 pb-3 pt-2'>
                <div
                    class='d-flex flex-row flex-nowrap no-scrollbar w-100'
                    style={`
                        overflow-x: ${imageElementScale > 1 && trophies.length === 1 ? 'visible !important' : 'auto'};
                        overflow-y: ${imageElementScale > 1 ? 'visible !important' : 'auto'};
                        scroll-snap-type: x mandatory;
                    `}
                    onScroll={this.scrollImages}
                    ref={c => this.imageScrollerRef = c}
                >
                    {trophies.map(trophy => {
                        const hasTrophy = team.trophys?.findIndex(e => e.name === trophy.key) > -1;
                        const img = mu.getTrophyImage(trophy.key);
                        if (!img) {
                            return '';
                        }
                        const textHeader = trophy.descriptionHeader && trophy.descriptionHeader[language] ? trophy.descriptionHeader[language] : '';
                        const text = trophy.description && trophy.description[language] ? trophy.description[language] : '';
                        //     <strong>{textHeader}</strong>
                        //     {/* <small class='font-weight-lighter mr-2'>
                        //         {util.isoDate(file.createdDate, false, false, true)}
                        //     </small> */}
                        //     <small>
                        //         <details>
                        //             <summary><Text id='dogs.trophy-details'>Details</Text></summary>
                        //             <Markdown markdown={mu.replaceImages(text, dog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                        //         </details>
                        //     </small>
                        return (<>
                            <div class={`col-${colWidth} clearfix p-0 mx-2`} key={`trophy-id-${trophy.uuidv4}`}>
                                <div
                                    class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center position-relative p-2`}
                                    style={`
                                        scroll-snap-align: start;
                                        flex-wrap: wrap;
                                        ${!hasTrophy ? 'filter: grayscale(100%) opacity(0.20);' : ''};
                                    `}
                                    // overflow: ${imageElementScale > 1 ? 'visible !important' : 'hidden'};
                                    onTouchstart={(e) => { e.stopPropagation(); }}
                                    onTouchend={(e) => { e.stopPropagation(); }}
                                    onTouchmove={(e) => { e.stopPropagation(); }}
                                >
                                    <img src={img} class='img-fluid' />
                                    {/* {JSON.stringify(trophy)} */}
                                </div>
                            </div>
                        </>);
                    })}
                </div>
            </div>
        </Widget>);
    }
}

export default ProfileLastTrophy;
