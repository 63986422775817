import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class ChooseTeam extends Component {
	constructor(props) {
        super(props);
        this.state = {
            submenu: 'main',
            limit: 15,
            offset: 0,
        };
    }

    async loadPublicView({ id, props = this.props }) {
		const { teamStore, userStore } = this.props.stores;
        const team = userStore.findTeam(id, true);
        await teamStore.getPublicViews(team.uuidv4);
	}

    async loadPublicTeam({ limit, offset, id, props = this.props }) {
		const { teamStore, userStore } = props.stores;
        const team = userStore.findTeam(id, true);
        await teamStore.loadPublicDetail({ id: team.uuidv4, imagesLimit: limit, imagesOffset: offset });
    }

    loadWorkouts = async ({ limit, offset, team }) => {
		const { workoutStore } = this.props.stores;
		const append = offset > 0;
		await workoutStore.load({
			query: { team },
			limit,
			offset,
			addData: ['publicTeams', 'publicUsers'],
		}, append);
	}

	loadDogs = async (props = this.props, currentTeam) => {
		const { dogStore } = this.props.stores;
		const { daysBack } = dogStore;
		const query = {
			team: currentTeam,
		};
		await Promise.all([
			this.loadWorkoutPlans(props, currentTeam),
			dogStore.load({ query, addData: ['team', 'user', 'workoutSummary', 'vaccineStatuses', 'historyStatuses', 'vaccineLogStatuses', 'allDogs'] }),
			dogStore.getDogsWithTraining({ daysBack }),
		])
	}

	loadWorkoutPlans = async (props = this.props, currentTeam) => {
		const currentMonth = new Date().getMonth() + 1;
		const currentYear = util.getYear();
		const seasonStart = parseInt((currentMonth < 8 ? currentYear - 1 : currentYear), 10);
		const { workoutPlanStore } = props.stores;

		workoutPlanStore.updateItem('workoutPlans', []);
		workoutPlanStore.updateItem('workoutPlan', {});
		workoutPlanStore.resetGraphPlanWeekDistanceCurrent();

		if (currentTeam) {
			await workoutPlanStore.load({ query: {
				team: currentTeam,
				seasonYear: seasonStart,
				seasonMonth: 6,
			} });
			const { workoutPlans } = workoutPlanStore;
			const workoutPlanCurrent = workoutPlans[0] || {};
			if (workoutPlanCurrent.id) {
				await workoutPlanStore.load(workoutPlanCurrent.id);
				workoutPlanStore.prepareWeekGraphs({ year: currentYear });
			}
		}
	}

    async loadFeed(props = this.props, currentTeam) {
		const { userStore } = props.stores;

        await userStore.getFeed({ team: currentTeam });
    }

    chooseTeam = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { team, teamtype } = e.target.closest('.teamRow').dataset;
        const { userStore } = this.props.stores;
        userStore.setActiveTeam({
            team: parseInt(team, 10),
            teamType: parseInt(teamtype, 10),
        });
        const { limit, offset } = this.state;
        const id = parseInt(team, 10);
        this.loadPublicTeam({ limit, offset, id });
        this.loadPublicView({ limit, offset, id });
        this.loadWorkouts({ limit, offset, team: id });
        this.loadDogs(this.props, team);
        this.loadFeed(this.props, team);
        this.toggleDrawer();
    }

    newTeam = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('newTeam', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer2,
        });
	}

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    toggleDrawer2 = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const { submenu } = this.state;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;
		const allTeams = userStore.findTeams(teams);
        const team = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

        const image = mu.getImage({ user: user, team, priority: 'user' });
        const activeTeam = user.activeTeam ? user.activeTeam.team : user.team;

        return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'><Text id='settings.choose-team'>Choose team</Text></h3>
                    </div>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                        {allTeams && allTeams.map((currentTeam, idx) => {
                            const dogs = userStore.findDogsByTeam(currentTeam.id);
                            const image = mu.getImage({ team: currentTeam, priority: 'team' });

                            return (<>
                                <div
                                    class={`row ${idx > 0 ? 'border-top' : ''} teamRow`}
                                    onClick={this.chooseTeam}
                                    data-team={currentTeam.id}
                                    data-teamtype={currentTeam.type}
                                >
                                    <div
                                        class={`col-12 py-3 d-flex align-items-center position-relative ${activeTeam === currentTeam.id ? 'font-weight-bold bg-light' : ''}`}
                                        style={`${activeTeam === currentTeam.id ? 'font-size: 1.5em;' : ''}`}
                                    >
                                        <div class='d-flex position-relative mr-3'>
                                            <div class='text-center editUser'>
                                                <div
                                                    class={`position-relative text-center border rounded-circle imageRounded imageRoundedSmall`}
                                                    style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
                                                    {!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
                                                </div>
                                            </div>
                                        </div>
                                        <span class={`flex-grow-1`} style='line-height: 1.0em;'>
                                            {currentTeam.name}
                                        </span>
                                        <span>
                                            {dogs && dogs.length} <i class='fa-solid fa-dog text-muted' />
                                        </span>
                                        {/* <span>
                                            {currentTeam.workouts && currentTeam.workouts.length} <i class='fa-solid fa-dog text-muted' />
                                        </span> */}
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                        <button class='btn btn-primary btn-block' onClick={this.newTeam}>
                            <i class='fa-solid fa-plus' /> <Text id='settings.create-team'>Create new team</Text>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChooseTeam;
