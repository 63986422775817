import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import Speed from '../../../components/data/speed';
import Elevation from '../../../components/data/elevation';
import Weight from '../../../components/data/weight';
import Distance from '../../../components/data/distance';
import Duration from '../../../components/data/duration';
import Temperature from '../../../components/data/temperature';

import mu from '../../../lib/musher-util';

@observer
class WorkoutKeyFigures extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetWorkoutKeyFiguresList', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Profile'
        });
    }

    render() {
        const { workoutStore, workoutPlanStore, teamStore, userStore } = this.props.stores;
        const { workouts, cordovaActiveTrackingParams, isRunning, totalDistance, currentSpeed, totalTime } = workoutStore;

        const currentDay = new Date().getDate();
		const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const {
			month = currentMonth,
			year = currentYear,
            currentSeason: seasonData,
            headerRight = 'See details',
            header = 'Key figures',
		} = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const { user } = userStore;
        const currentTeamObject = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const currentTeam = currentTeamObject.id;
        const teamCurrentSeason = (currentTeam ? teamStore.findWorkoutSummary(currentTeam, seasonStart) : teamStore.findWorkoutSummaryTotal(seasonStart)) || {};

        const currentSeason = seasonData || teamCurrentSeason;

        return (<Widget stores={this.props.stores} classNamesPadding={'p-0'} header={header} headerRight={headerRight} onHeaderClick={this.onClick} onClick={this.onClick} {...this.props}>
            <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                <div class={`widgetHeaderSmall`}>
                    <div class='text-secondary'>
                        <Text id='workouts.distance'>Distance</Text>
                    </div>
                    <div class='widgetContentTextSmall text-center'>
                        <Distance stores={this.props.stores} value={currentSeason.distanceKm || 0} smallUnit={true} />
                    </div>
                </div>
                <div class={`widgetHeaderSmall`}>
                    <div class='text-secondary'>
                        <Text id='workouts.duration'>Duration</Text>
                    </div>
                    <div class='widgetContentTextSmall text-center'>
                        <Duration stores={this.props.stores} value={currentSeason.duration || 0} smallUnit={true} />
                    </div>
                </div>
                <div class={`widgetHeaderSmall`}>
                    <div class='text-secondary'>
                        <Text id='workouts.avg-pace'>Avg pace</Text>
                    </div>
                    <div class='widgetContentTextSmall text-center'>
                        <Speed stores={this.props.stores} value={currentSeason.speedAvg || 0} smallUnit={true} />
                    </div>
                </div>
                <div class={`widgetHeaderSmall`}>
                    <div class='text-secondary'>
                        <Text id='workouts.elevation'>Elevation</Text>
                    </div>
                    <div class='widgetContentTextSmall text-center'>
                        <Elevation stores={this.props.stores} value={currentSeason.elevation || 0} smallUnit={true} />
                    </div>
                </div>
            </div>
        </Widget>);
    }
}

export default WorkoutKeyFigures;
