import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';
import linkstate from 'linkstate';

@observer
class Achievements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
    }

    handleAdd = () => {
        const { data } = this.state;
        const {
            updateField,
            object,
            field,
        } = this.props;
        updateField({ id: object.id, field, value: { ...data } });
        this.setState({
            data: {
                name: '',
                description: '',
                url: '',
            }
        });
    };


    removeAchievement = async (e) => {
        const { dogStore } = this.props.stores;
        const { object } = this.props;
        const { name, id } = e.target.closest('button').dataset;
        dogStore.removeAchievement({
            id: object.id,
            name,
            achievementId: id,
        });
    }

    render() {
        const { data } = this.state;
        const { showFiles = true, object = {}, showMissingInfo = true, detailsOpen } = this.props;
        return (
            <div class='row'>
                <div class='col-12 mt-3'>
                    {showFiles && <>
                        <div class='container-fluid'>
                            <div class='row'>

                                {showMissingInfo && (!object.achievements || object.achievements?.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-trophy' /></div>
                                        <h3><Text id='dogs.no-achievements-title'>No achievements for this dog!</Text></h3>
                                        <Text id='dogs.no-achievements-text'>You can add achievements to this dog. Ie. Memories, trips, tests, prices, races, etc.</Text>
                                    </div>
                                </>}

                                {object.achievements && object.achievements.map(file => (
                                    <div class='col-12 p-1'>
                                        <span class='font-weight-lighter mr-2'>{util.isoDate(file.date, false, false, true)}</span>
                                        <span class='mr-2'>{file.name}</span>
                                        <span class='mr-2'>{file.url}</span>
                                        <button class='btn btn-sm btn-link float-right p-0'
                                            onClick={this.removeAchievement}
                                            data-name={file.name}
                                            data-id={file.id}
                                        ><i class='fas fa-trash-alt' /> Del</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                </div>
                <div class='col-12 mt-3'>
                    <details open={detailsOpen}>
                        <summary><Text id='dogs.achievement-add'>Add new achievement</Text></summary>

                        <div class='form-group'>
                            <label for='name'><Text id='dogs.achievement-name'>Name</Text></label>
                            <input type='text' class='form-control' id='name' aria-describedby='nameHelp' onInput={linkstate(this, 'data.name')} value={data.name} />
                            <small id='nameHelp' class='form-text text-muted'><Text id='dogs.achievement-name-help'>Name of achievement</Text></small>
                        </div>
                        <div class='form-group'>
                            <label for='date'><Text id='dogs.achievement-date'>Date</Text></label>
                            <input type='date' class='form-control' id='date' aria-describedby='dateHelp' onInput={linkstate(this, 'data.date')} value={data.date} />
                            <small id='dateHelp' class='form-text text-muted'><Text id='dogs.achievement-date-help'>Date of achievement</Text></small>
                        </div>
                        <div class='form-group'>
                            <label for='description'><Text id='dogs.achievement-description'>Description</Text></label>
                            <input type='text' class='form-control' id='description' aria-describedby='descriptionHelp' onInput={linkstate(this, 'data.description')} value={data.description} />
                            <small id='descriptionHelp' class='form-text text-muted'><Text id='dogs.achievement-description-help'>Description of achievement</Text></small>
                        </div>
                        <div class='form-group'>
                            <label for='url'><Text id='dogs.achievement-url'>Url</Text></label>
                            <input type='text' class='form-control' id='url' aria-describedby='urlHelp' onInput={linkstate(this, 'data.url')} value={data.url} />
                            <small id='urlHelp' class='form-text text-muted'><Text id='dogs.achievement-url-help'>URL of achievement</Text></small>
                        </div>
                        <button class='btn btn-success' onClick={this.handleAdd}>
                            <Text id='dogs.achievement-add'>Add achievement</Text>
                        </button>
                    </details>
                </div>
            </div>
        );
    }
}

export default Achievements;
