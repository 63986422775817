import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

function getValue(value, settings) {
    if (value <= 0) {
        return '--';
    }
    if (settings.imperial) {
        return util.format(value, 1);
    }
    return util.format(value, 1);
}

@observer
class Intensity extends Component {
    render() {
		const { value = 0, smallUnit } = this.props;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;

        if (settings.imperial) {
            return (<>
                <nobr>
                    {getValue(value, settings)} {smallUnit ? <><small><small><small class='ml-0'>%</small></small></small></> : <>%</>}
                </nobr>
            </>);
        }

		return (<>
            <nobr>
                {getValue(value, settings)} {smallUnit ? <><small><small><small class='ml-0'>%</small></small></small></> : <>%</>}
            </nobr>
        </>);
    }
}

export default Intensity;
