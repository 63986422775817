import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

@observer
class ImageSlider extends Component {
    constructor(props) {
        super(props);
        const { images = [] } = props;
        this.state = {
            imageContainer: 1,
            currentImage1: images[0] || {},
            currentImage2: {},
            currentIdx: 0,
            totalImages: images.length || 0,
            imageDots: [...Array(images.length || 0).fill(1).map((x, y) => x + y)],
        };
    }

    nextImage() {
        // console.log('next');
        const { images } = this.props;
        const { totalImages, currentIdx, imageContainer } = this.state;
        const nextImageContainer = imageContainer === 1 ? 2 : 1;
        let nextIdx = currentIdx + 1;
        if (nextIdx > totalImages -1) {
            nextIdx = 0;
        }
        this.setState({
            [`currentImage${nextImageContainer}`]: images[nextIdx],
            currentIdx: nextIdx,
            imageContainer: nextImageContainer,
        });
    }

    prevImage() {
        // console.log('prev');
        const { images } = this.props;
        const { totalImages, currentIdx, imageContainer } = this.state;
        const nextImageContainer = imageContainer === 1 ? 2 : 1;
        let nextIdx = currentIdx - 1;
        if (nextIdx < 0) {
            nextIdx = totalImages - 1;
        }
        this.setState({
            [`currentImage${nextImageContainer}`]: images[nextIdx],
            currentIdx: nextIdx,
            imageContainer: imageContainer === 1 ? 2 : 1,
        });
    }

    touchStart = (e) => {
        e.stopPropagation();
        this.setState({
            startX: e.touches[0].pageX,
        })
    }

    touchEnd = (e) => {
        e.stopPropagation();
        this.setState({
            startX: undefined,
            sliding: false,
        })
    }

    touchMove = (e) => {
        e.stopPropagation();
        // e.preventDefault();
        const { totalImages } = this.state;
        if (totalImages > 1) {
            const { startX, sliding } = this.state;
            const scrollDistance = 50;
            const x = e.touches[0].pageX;
            if (!sliding) {
                if (x > startX + scrollDistance) {
                    this.setState({ sliding: true })
                    this.prevImage();
                } else if (x < startX - scrollDistance) {
                    this.setState({ sliding: true })
                    this.nextImage();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.images !== this.props.images) {
            const { images: nextImages = [] } = nextProps;
            this.setState({
                imageContainer: 1,
                currentImage1: nextImages.length > 0 ? nextImages[0] : {},
                currentImage2: {},
                currentIdx: 0,
                totalImages: nextImages.length || 0,
                imageDots: [...Array(nextImages.length || 0).fill(1).map((x, y) => x + y)],
            });
        }
    }

    render() {
        const { currentImage1, currentImage2, imageContainer, totalImages, imageDots, currentIdx } = this.state;
        const img = this[`image${imageContainer}`];
        const curImage = imageContainer === 1 ? currentImage1 : currentImage2;
        const htmlImageRatio = img && img.height ? img.width / img.height : 4 / 3;
        let imgHeight;
        let imgWidth;
        if (curImage.dimensions) {
            const { width, height, orientation } = curImage.dimensions;
            if (orientation > 4 && orientation < 9) {
                imgHeight = width;
                imgWidth = height;
            } else {
                imgHeight = height;
                imgWidth = width;
            }
        }
        const imageRatio = curImage.dimensions ? imgWidth / imgHeight : htmlImageRatio;
        if (totalImages <= 0) {
            return '';
        }

        return (
            <div class='w-100'>
                <div class='border rounded position-relative w-100'
                    style={`height: calc(85vw / ${imageRatio}); overflow: hidden; transition: all .3s ease-in-out;`}
                    onTouchstart={this.touchStart}
                    onTouchend={this.touchEnd}
                    onTouchmove={this.touchMove}
                >
                    {totalImages > 1 && <span class='position-absolute' style='top:0px; right: 5px; z-index: 2000;'><small>{currentIdx + 1}/{totalImages}</small></span>}
                    {currentImage1 && <div class='position-absolute' style={`${imageContainer === 1 ? 'opacity: 1;' :  'opacity: 0;'} transition: all .3s ease-in-out; top: 0; left: 0;`}>
                        <img loading='lazy' src={currentImage1.s3LargeLink}
                            class='img-fluid align-middle'
                            ref={c => this.image1 = c}
                        />
                    </div>}
                    {currentImage2 && <div class='position-absolute' style={`${imageContainer === 2 ? 'opacity: 1;' :  'opacity: 0;'} transition: all .3s ease-in-out; top: 0; left: 0;`}>
                        <img loading='lazy' src={currentImage2.s3LargeLink}
                            class='img-fluid align-middle'
                            ref={c => this.image2 = c}
                        />
                    </div>}
                </div>
                {imageDots.length > 1 && <div class='w-100 text-center'>{imageDots.map(i => <span style={`color: ${i === currentIdx + 1 ? '#ffffff' : '#808080'};`}>&bull;</span>)}</div>}
            </div>
        );
    }
}

export default ImageSlider;
