import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

function kgToPounds(value) {
    return 2.20462 * value;
}

@observer
class Weight extends Component {
    render() {
		const { value = 0, smallUnit } = this.props;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;

        if (settings.imperial) {
            return (<>
                <nobr>
                    {util.format(kgToPounds(value), 0)} lb
                </nobr>
            </>);
        }

		return (<>
            <nobr>
                {util.format(value, 0)} kg
            </nobr>
        </>);
    }
}

export default Weight;
