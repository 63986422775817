import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import mu from '../../lib/musher-util';

const RED_DAYS = [0, 6];

@observer
class WorkoutCalendar extends Component {

    async loadWorkoutSummary(props = this.props) {
        this.setState({ isLoading: true });
        if (props.skipLoadSummary) {
            return false;
        }

		const { userStore, workoutStore } = this.props.stores;
        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentMonth = new Date().getMonth() + 1;
        const { month = currentMonth, year = util.getYear(), team = currentTeam, dog = {} } = props;
        if (dog && dog.id) {
            await userStore.getWorkoutSummary({ dog: dog.id, year });
            await userStore.getWorkoutSummaryDay({ month, year, dog: dog.id });
        } else {
            await userStore.getWorkoutSummary({ team, dog: dog.id, year });
            await userStore.getWorkoutSummaryDay({ month, year, team, dog: dog.id });
        }
		// await workoutStore.load({ query: { team: currentTeam, month, year } });
        this.setState({ isLoading: false });
    }

    chooseDay = (e) => {
        const { year: yyyy, month: mm, day: dd } = this.state;
        const { chooseDayFunction = () => {} } = this.props;

        const { date, day, month, year, workoutid } = e.target.closest('.dataElement').dataset;
		// console.log({ day, month, year, workoutid });

        if (year === yyyy && month === mm && day === dd) {
            this.setState({
                year: null,
                month: null,
                day: null,
            });
        } else {
            this.setState({
                year,
                month,
                day,
            });
        }
        chooseDayFunction({ e, date, day, month, year, workoutid });
    }

    componentDidMount() {
        this.loadWorkoutSummary();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.month !== this.props.month
            || nextProps.year !== this.props.year
            || nextProps.dog !== this.props.dog
            || nextProps.team !== this.props.team) {
            this.loadWorkoutSummary(nextProps);
        }
    }

    render() {
        const { appState, userStore, workoutPlanStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en' } = user;
        const DAYS = mu.getDays(language);
        const MONTHS = mu.getMonths(language);

        const { day: dd, month: mm, year: yyyy } = this.state;

		const { darkmode } = appState;

        const currentDay = new Date().getDate();
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const {
            month = currentMonth,
            year = currentYear,
            dog,
            basehref,
            hideSummary,
            hideTable,
            onNavClick = () => {},
            classNames = 'col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-4',
        } = this.props;

		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);
        const { month: nextMonth, year: nextYear } = mu.getNextMonth(monthNum, yearNum);
        const { month: prevMonth, year: prevYear } = mu.getPrevMonth(monthNum, yearNum);

        const daysOfMonth = util.getDaysInMonth(month, year);
        const weeksInMonth = util.getWeeksInMonth(month, year);
        const firstWeek = weeksInMonth[0].week;
        const daysOfWeek = util.getDaysInWeek(firstWeek, year, month);
        let totalDistanceKmMonth = 0;
        let totalCountMonth = 0;
        let totalElevationMonth = 0;
        let totalDurationMonth = 0;
        let totalSpeedavgMonth = [];

        let totalPlanDistanceKmMonth = 0;

        return (
            <>
                <div class={`${classNames} text-center`}>
                    <a
                        onClick={e => onNavClick(e, nextMonth, nextYear)}
                        href={`${basehref || '/workouts/month/'}${nextMonth}/${nextYear}/skipScroll`}
                        class='float-right btn btn-sm btn-outline-secondary ml-2'
                    >
                        <i class='fas fa-arrow-right' />
                    </a>
                    <a
                        onClick={e => onNavClick(e, prevMonth, prevYear)}
                        href={`${basehref || '/workouts/month/'}${prevMonth}/${prevYear}/skipScroll`}
                        class='float-left btn btn-sm btn-outline-secondary mr-2'
                    >
                        <i class='fas fa-arrow-left' />
                    </a>
                    <h3>
                        <Text id='workout.calendar.month'>Måned</Text> - {MONTHS[month - 1]} {year}
                    </h3>
                    <h5 class='text-muted font-weight-light'>
                        <Text id='workout.plan.season'>Sesongen</Text> {seasonStart} - {seasonStart + 1}
                    </h5>
                    {dog && <div>{dog.name}</div>}
                    {/* <small>{util.isoDate(daysOfMonth[0], false, false, true)} - {util.isoDate(daysOfMonth[daysOfMonth.length - 1], false, false, true)}</small> */}
                </div>

                <div class={`${classNames}`}>

                    {!hideTable && <>
                        <table class='table table-striped table-sm'>
                            <thead>
                                <tr>
                                    <th class='text-muted'><small><Text id='workout.calendar.week'>Uke</Text></small></th>
                                    {daysOfWeek.map(day => <th><small>{DAYS[day.getDay()]}</small></th>)}
                                    <th class='text-muted'><small><Text id='workout.calendar.sum'>Sum</Text></small></th>
                                    {/* <th class='text-muted'>Totalt</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {weeksInMonth && weeksInMonth.map(weekObj => {
                                    const daysOfWeek = util.getDaysInWeek(weekObj.week, year, month);
                                    const summaryPlanWeek = workoutPlanStore.findWorkoutPlanSummaryWeek(parseInt(year, 10), weekObj.week) || {};
                                    let totalWeek = 0;
                                    return (
                                        <tr key={`calendar-${year}-${month}-${weekObj.week}`}>
                                            <td class='text-right text-muted pt-3'>
                                                <small>
                                                    <a href={`/workouts/week/${weekObj.week}/${year}`} class='text-muted'>{weekObj.week}</a>
                                                </small>
                                            </td>
                                            {daysOfWeek.map(day => {
                                                const summary = userStore.findWorkoutSummaryWeekDay(day.getFullYear(), day.getMonth() + 1, day.getDate(), monthNum) || {};
                                                const summaryPlanDay = workoutPlanStore.findWorkoutPlanSummaryDay(day.getFullYear(), day.getMonth() + 1, day.getDate(), monthNum) || {};
                                                if (summary && summary.distanceKm) {
                                                    totalWeek += summary.distanceKm;
                                                    totalDistanceKmMonth += summary.distanceKm;
                                                    totalCountMonth += summary.count;
                                                    totalElevationMonth += summary.elevation;
                                                    totalDurationMonth += summary.duration;
                                                    totalSpeedavgMonth.push(summary.speedAvg);
                                                }
                                                if (summaryPlanDay && summaryPlanDay.distance) {
                                                    totalPlanDistanceKmMonth += summaryPlanDay.distance;
                                                }
                                                const isChosen = (dd == day.getDate()) && (mm == day.getMonth() + 1) && (yyyy === day.getFullYear());
                                                const isToday =  (currentDay == day.getDate()) && (currentMonth == day.getMonth() + 1) && (currentYear == day.getFullYear());
                                                const isDayOff = RED_DAYS.indexOf(day.getDay()) > -1;
                                                // console.log(summary);
                                                // count: 1, day: 5, distanceKm: 10, duration: 3600, elevation: 200, month: 9, speedAvg: 12, year: 2020
                                                return (
                                                    <td
                                                        key={`calendar-${year}-${month}-${weekObj.week}-${day.getDate()}`}
                                                        class={`dataElement text-center font-weight-light position-relative pt-3 pb-3 ${isChosen ? 'calendarIsChosen' : ''} ${isToday ? 'calendarIsToday' : ''} ${isDayOff ? 'calendarIsWeekend' : ''}`}
                                                        style={`font-size: 0.8em;`}
                                                        onClick={this.chooseDay}
                                                        data-day={day.getDate()}
                                                        data-month={day.getMonth() + 1}
                                                        data-year={year}
                                                        data-date={`${year}-${util.padDate(day.getMonth() + 1)}-${util.padDate(day.getDate())}`}
                                                        data-workoutid={summary.id}
                                                    >
                                                        <div class='position-absolute text-muted' style='top: 0px; right: 2px; font-size: 0.7em;'>
                                                            {day.getDate()}
                                                        </div>
                                                        {summary && summary.distanceKm ?
                                                            <div class='rounded-pill w-100 px-1'
                                                                style={`background-color: ${mu.getTrainingColor(summary, darkmode)};`}
                                                            >
                                                                <Distance stores={this.props.stores} value={summary.distanceKm} />
                                                                {summary.count > 1 && (
                                                                    <span class='position-absolute text-danger'
                                                                        style='bottom: 0; right: 0px; font-size: 0.6em;'>
                                                                        {summary.count}
                                                                    </span>
                                                                )}

                                                            </div> : <>
                                                                {summary.duration > 0 && <>
                                                                    <div class='rounded-pill w-100 px-1'
                                                                        style={`background-color: ${mu.getTrainingColor(summary, darkmode)};`}
                                                                    >
                                                                        <Duration stores={this.props.stores} value={summary.duration} />
                                                                    </div>
                                                                </>}
                                                            </>
                                                        }
                                                        {summaryPlanDay.distance > 0 && (
                                                            <span class='position-absolute text-primary'
                                                                style='bottom: 0; left: 0px; font-size: 0.9em;'>
                                                                <Distance stores={this.props.stores} value={summaryPlanDay.distance} />
                                                            </span>
                                                        )}
                                                    </td>
                                                );
                                            })}
                                            <td class='text-right pt-3 pb-3 position-relative' style='font-size: 0.8em;'>
                                                <Distance stores={this.props.stores} value={totalWeek} />
                                                {summaryPlanWeek.distance > 0 && (
                                                    <span class='position-absolute text-primary'
                                                        style='bottom: 0; left: 0px; font-size: 0.9em;'>
                                                        <Distance stores={this.props.stores} value={summaryPlanWeek.distance} />
                                                    </span>
                                                )}
                                            </td>
                                            {/* <td class='text-right text-muted pt-3'>{totalDistanceKmMonth}</td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan='8' class='text-right'>
                                        <Text id='workout.calendar.totalKm'>Totalt km</Text>
                                    </td>
                                    <td class='text-right text-muted pb-3 position-relative'>
                                        <Distance stores={this.props.stores} value={totalDistanceKmMonth} />
                                        {totalPlanDistanceKmMonth > 0 && (
                                            <span class='position-absolute text-primary'
                                                style='bottom: 0; left: 0px; font-size: 0.8em;'>
                                                <Distance stores={this.props.stores} value={totalPlanDistanceKmMonth} />
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </>}

                    {!hideSummary && <>
                        <h5 class='mt-4'>
                            <Text id='workout.calendar.summary'>Oppsummering</Text> - {MONTHS[month - 1]} {year}
                        </h5>
                        {dog && <div>{dog.name}</div>}

                        <table class='table table-striped table-sm'>
                            <tbody>
                                <tr>
                                    <td class='text-muted'><Text id='workout.calendar.totalWorkouts'>Antall økter</Text></td>
                                    <td class='text-right'>{totalCountMonth}</td>
                                </tr>
                                <tr>
                                    <td class='text-muted'><Text id='workout.calendar.distance'>Distanse</Text></td>
                                    <td class='text-right'>
                                        <Distance stores={this.props.stores} value={totalDistanceKmMonth} />
                                    </td>
                                </tr>
                                <tr>
                                    <td class='text-muted'><Text id='workout.calendar.ascend'>Høydemeter</Text></td>
                                    <td class='text-right'>
                                        <Elevation stores={this.props.stores} value={totalElevationMonth} />
                                    </td>
                                </tr>
                                <tr>
                                    <td class='text-muted'><Text id='workout.calendar.duration'>Varighet</Text></td>
                                    <td class='text-right'>
                                        {util.secToHms(totalDurationMonth)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class='text-muted'><Text id='workout.calendar.avg-speed'>Gjennomsnittsfart</Text></td>
                                    <td class='text-right'>
                                        <Speed stores={this.props.stores} value={mu.avgArr(totalSpeedavgMonth)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>}
                </div>
            </>
        );
    }
}

export default WorkoutCalendar;
