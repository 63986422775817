import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';

import MiniGraph from '../../components/workout/miniGraph';

import WorkoutTotals from '../../components/workout/totals';
import FormGraph from '../../components/team/formGraph';

import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

@observer
class WorkoutFitness extends Component {
    render() {
        const {
            showHeader = true,
        } = this.props;

        const { teamStore } = this.props.stores;
        const { currentForm, currentFitness = 0, currentFatigue, formTrend, fitnessTrend = 0, fatigueTrend } = teamStore;

		let fitnessMessage = `Team is in good shape and resting well.`;
		let fitnessBackground = 'bg-success';
		if (currentFitness > 50) {
			// Default message
            if (currentFatigue > currentFitness) {
                fitnessMessage = `Team has a high fatigue. Consider some rest days!`;
                fitnessBackground = 'bg-warning';
            }
		} else if (currentFitness < 50 && currentFitness >= 25) {
			fitnessMessage = `Team is in ok shape.`;
			fitnessBackground = 'bg-warning';
		} else if (currentFitness < 25 && currentFitness >= 10) {
			fitnessMessage = `Team is not in good shape. Be careful when training.`;
			fitnessBackground = 'bg-warning';
		} else if (currentFitness < 10 && currentFitness > 0) {
			fitnessMessage = `Team is out of shape. Take it easy!`;
			fitnessBackground = 'bg-danger';
		} else {
			fitnessMessage = `Team has no workout data yet. Track workouts to show fitness data.`;
			fitnessBackground = 'bg-secondary';
		}

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.stats'>Fitness</Text>
                        </h3>
                    </div>}


                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                        <div
                            class={`display-3 text-white text-center ${fitnessBackground} px-3 pt-2`}
                            style='border-radius: 20px 20px 0px 0px; height: 110px;'
                        >
                            {Math.floor(currentFitness)}
                        </div>
                        <div
                            class={`text-white text-center ${fitnessBackground} px-3 pb-2`}
                            style='line-height: 0.9em; border-radius: 0px 0px 20px 20px;'
                        >
                            <small>
                                {Math.floor(fitnessTrend)}%
                            </small><br />
                            <small>
                                {fitnessMessage}
                            </small>
                        </div>
                        {/* currentForm: {currentForm}, <br />
                        current{currentFitness}, <br />
                        currentFatigue: {currentFatigue}, <br />
                        formTrend: {formTrend}, <br />
                        fitnessTrend: {fitnessTrend}, <br />
                        fatigueTrend: {fatigueTrend}<br /> */}
                    </div>

                    <Widget stores={this.props.stores} onClick={this.openDrawer} page='workoutFitnessProgress'>
                        <h5>
                            <Text id='workouts.fitness-fatigue'>Fitness progress</Text>
                        </h5>
                        <div class='row'>
                            <div class='col-12'>
                                <FormGraph
                                    stores={this.props.stores}
                                    showHeader={false}
                                    showFatigue={true}
                                    showForm={true}
                                    height={300}
                                    width={1000}
                                    showYTicksLeft={1}
                                    showXTicks={1}
                                    showLegendLeft={1}
                                />
                            </div>
                        </div>
                    </Widget>

                </div>
            </div>
        </>);
    }
}

export default WorkoutFitness;
