import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import Speed from '../../../components/data/speed';
import Elevation from '../../../components/data/elevation';
import Weight from '../../../components/data/weight';
import Distance from '../../../components/data/distance';
import Duration from '../../../components/data/duration';
import Temperature from '../../../components/data/temperature';
import Calories from '../../../components/data/calories';
import Intensity from '../../../components/data/intensity';
import Fitness from '../../../components/data/fitness';

import mu from '../../../lib/musher-util';

@observer
class WorkoutIntensity extends Component {
	onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetWorkoutIntensityDetail', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Analysis'
        });
    }

    render() {
        const { workoutStore, workoutPlanStore, teamStore, userStore } = this.props.stores;
        const { currentForm, currentFitness = 0, currentFatigue, formTrend, fitnessTrend = 0, fatigueTrend } = teamStore;

        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			graphWorkoutSummaryWeekLoadIndexLastPeriode,
			graphworkoutSummaryDaysCurrentMonth,
			graphworkoutSummaryDaysCurrentMonthPrevMonth,
			graphworkoutSummaryDaysCurrentMonthPrevSeason,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
			workoutDistinctTypes,
			workoutSeasonEquipment,
		} = userStore;

        const currentDay = new Date().getDate();
		const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
        const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm = 0,
			elevation: weekElevation = 0,
			duration: weekDuration = 0,
			speedAvg: weekSpeedAvg = 0,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

		const weekLoadCompared = Math.floor(weekLoadIndex / (workoutSummaryWeekAvgLoadLastPeriode || 1) * 100);

        return (<Widget stores={this.props.stores} {...this.props} onClick={this.onClick}>
{/* weekLoadIndex: {weekLoadIndex}<br />
workoutSummaryWeekAvgLoadLastPeriode: {workoutSummaryWeekAvgLoadLastPeriode}<br /> */}
            <div class={`widgetHeader`}>
                <i class='fa-solid fa-droplet widgetHeaderIcon mr-2' />
                <Text id='workouts.intensity'>Intensity</Text><br />
                <div class='widgetContentText text-center'>
                    <Intensity stores={this.props.stores} value={weekLoadCompared} smallUnit={true} />
                </div>
            </div>
        </Widget>);
    }
}

export default WorkoutIntensity;
