import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import { toJS } from 'mobx';
import Markdown from 'preact-markdown';

import mu from '../../lib/musher-util';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

function getStateColor(state) {
	switch (state) {
		case 'running':
			return 'primary';
		case 'rest':
			return 'warning';
		case 'restTrail':
			return 'danger';
	}
}

function getStateIcon(state) {
	switch (state) {
		case 'running':
			return 'fas fa-running';
		case 'rest':
			return 'fas fa-male';
		case 'restTrail':
			return 'fas fa-bed';
	}
}

@observer
class States extends Component {
    hideDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
			stateDetail: {
				state: '',
                idx: 0,
			},
		});
    }

	showStateDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
		const data = e.target.closest('div').dataset;
		this.setState({
			stateDetail: {
				...data,
			},
		});
	}

    nextStateDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { states, duration } = this.props;
        const { stateDetail = {} } = this.state;
        const currentIdx = parseInt(stateDetail.idx, 10);
        const nextIdx = currentIdx + 1;
        const nextState = states[nextIdx];
        if (nextState) {
            this.setState({
                stateDetail: {
                    idx: nextIdx,
                    state: nextState.state,
                    duration: nextState.duration,
                    distance: nextState.distance,
                    avgspeed: nextState.avgSpeed,
                    statespeeds: nextState.stateSpeeds,
                    point: nextState.point,
                    start: nextState.start,
                    end: nextState.end,
                    percent: util.normalizeBetween(nextState.duration, 0, duration, 0, 100),
                },
            });
        }
    }

   prevStateDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { states, duration } = this.props;
        const { stateDetail = {} } = this.state;
        const currentIdx = parseInt(stateDetail.idx, 10);
        const prevIdx = currentIdx - 1;
        const prevState = states[prevIdx];
        if (prevState) {
            this.setState({
                stateDetail: {
                    idx: prevIdx,
                    state: prevState.state,
                    duration: prevState.duration,
                    distance: prevState.distance,
                    avgspeed: prevState.avgSpeed,
                    statespeeds: prevState.stateSpeeds,
                    point: prevState.point,
                    start: prevState.start,
                    end: prevState.end,
                    percent: util.normalizeBetween(prevState.duration, 0, duration, 0, 100),
                },
            });
        }
    }

    render() {
        const { states, duration, height = '20px', showText = true } = this.props;
        const { stateDetail = {} } = this.state;
        const locale = 'en-GB';

        return (<>
            <div class='row'>
                {states && <div class='col-12 px-0'>
                    <div class='progress' style={`height: ${(stateDetail && stateDetail.idx) ? '20px' : height};`}>
                        {states.map((state, idx) => {
                            const value = Math.ceil(util.normalizeBetween(state.duration, 0, duration, 0, 100));
                            let currentWidth = value;
                            if (stateDetail && stateDetail.idx == idx) {
                                currentWidth = value < 10 ? 10 : value;
                            }
                            return (<>
                                <div
                                    class={`progress-bar bg-${getStateColor(state.state)}`}
                                    role='progressbar'
                                    style={`width: ${currentWidth}%`}
                                    aria-valuenow={value}
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                    data-idx={idx}
                                    data-state={state.state}
                                    data-duration={state.duration}
                                    data-distance={state.distance}
                                    data-avgspeed={state.avgSpeed}
                                    data-statespeeds={state.stateSpeeds}
                                    data-point={state.point}
                                    data-start={state.start}
                                    data-end={state.end}
                                    data-percent={util.normalizeBetween(state.duration, 0, duration, 0, 100)}
                                    onClick={this.showStateDetail}
                                >
                                    {showText ? <>
                                        <nobr>
                                            {value > 10 && <>
                                                {state.state === 'rest' ? <>
                                                    {util.secToHms(state.duration, true)}
                                                </> : <>
                                                    {util.format(state.distance / 1000, 0)}km
                                                </>}
                                            </>}
                                            {(stateDetail && stateDetail.idx == idx) ? <i class={`fas fa-hand-point-down text-${state.state === 'rest' ? 'dark' : 'warning'} mx-2`} /> : ''}
                                        </nobr>
                                    </> : <>
                                        {(stateDetail && stateDetail.idx == idx) ? <i class={`fas fa-hand-point-down text-${state.state === 'rest' ? 'dark' : 'warning'} mx-2`} /> : ''}
                                    </>}
                                </div>
                            </>);
                        })}
                    </div>
                    {stateDetail && stateDetail.state && <>
                        <div class='card position-relative'>
                            <span class='position-absolute' style='top: 0px; right: 5px;'>
                                <small class='text-muted'>
                                    <i class={getStateIcon(stateDetail.state)} /> {util.format(stateDetail.percent, 1)}%
                                </small>
                            </span>
                            <div class='card-body'>
                                {stateDetail.state === 'rest' ? <>
                                    <div class='card-text row'>
                                        <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.duration'>Vargihet</Text>
                                            </small>
                                            <h6>
                                                {util.secToHms(stateDetail.duration, true)}
                                            </h6>
                                        </div>
                                        {stateDetail.point && <div class='col-8 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fa-map-marked ml-2' /> <Text id='workouts.position'>Position</Text>
                                            </small>
                                            <h6>
                                                {stateDetail.point}
                                            </h6>
                                        </div>}
                                    </div>
                                    <div class='card-text row'>
                                        {stateDetail.start && <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.starttime'>Start</Text>
                                            </small>
                                            <h6>
                                                {mu.formatDate(util.parseInputDate(stateDetail.start), { locale, hour12: false, day: 'numeric', month: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                            </h6>
                                        </div>}
                                        {stateDetail.end && <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.endtime'>End</Text>
                                            </small>
                                            <h6>
                                                {mu.formatDate(util.parseInputDate(stateDetail.end), { locale, hour12: false, day: 'numeric', month: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                            </h6>
                                        </div>}
                                    </div>
                                </> : <>
                                    <div class='card-text row'>
                                        <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-road ml-2' /> <Text id='workouts.distance'>Distanse</Text>
                                            </small>
                                            <h6>
                                                <Distance stores={this.props.stores} value={stateDetail.distance / 1000} />
                                            </h6>
                                        </div>

                                        <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.duration'>Vargihet</Text>
                                            </small>
                                            <h6>
                                                {util.secToHms(stateDetail.duration, true)}
                                            </h6>
                                        </div>

                                        <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-tachometer-alt ml-2' /> <Text id='workouts.avgspeed'>Gj.fart</Text>
                                            </small>
                                            <h6>
                                                <Speed stores={this.props.stores} value={stateDetail.distance / stateDetail.duration * 3.6} />
                                            </h6>
                                        </div>
                                    </div>
                                    <div class='card-text row'>
                                        {stateDetail.start && <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.starttime'>Start</Text>
                                            </small>
                                            <h6>
                                                {mu.formatDate(util.parseInputDate(stateDetail.start), { locale, hour12: false, day: 'numeric', month: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                            </h6>
                                        </div>}
                                        {stateDetail.end && <div class='col-4 text-nowrap text-overflow'>
                                            <small class='text-muted'>
                                                <i class='fas fa-clock ml-2' /> <Text id='workouts.endtime'>End</Text>
                                            </small>
                                            <h6>
                                                {mu.formatDate(util.parseInputDate(stateDetail.end), { locale, hour12: false, day: 'numeric', month: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                            </h6>
                                        </div>}
                                    </div>
                                </>}
                            </div>
                            <div class='float-right'>

                            <button class='btn btn-link text-primary mx-2 py-0 float-right' onClick={this.hideDetail} style='line-height: 1.0em; font-size: 2.0em;'>
                                <i class='fas fa-times' />
                            </button>

                            {stateDetail.idx < states.length - 1 && <button class='btn btn-link text-primary mx-2 py-0 float-right' onClick={this.nextStateDetail} style='line-height: 1.0em; font-size: 2.0em;'>
                                <i class='fas fa-angle-right' />
                            </button>}
                            {stateDetail.idx > 0 && <button class='btn btn-link text-primary mx-2 py-0 float-right' onClick={this.prevStateDetail} style='line-height: 1.0em; font-size: 2.0em;'>
                                <i class='fas fa-angle-left' />
                            </button>}

                            </div>
                        </div>
                    </>}
                </div>}
            </div>
        </>);
    }
}

export default States;
