import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import LineGraph from '../../components/lineGraph';

@observer
class FormGraph extends Component {
    render() {
        const {
            showHeader = true,
            showFitness = true,
            showFatigue = true,
            showForm = true,
            height = 300,
            width = 1500,
            showXTicks = 1,
            showYTicksLeft = 1,
            showLegendLeft = 1,
        } = this.props;
        const { teamStore } = this.props.stores;
        const {
            graphFitness,
            graphFatigue,
            graphForm,
		} = teamStore;

        if (!graphFitness || graphFitness?.length === 0) {
            return '';
        }

        const xTicksAll = graphForm.map(e => e.date);
        const partsLength = Math.floor(xTicksAll.length / 4) || 1;
        const xTicks = [];
        for (let i = 0, l = xTicksAll.length; i < l; i += partsLength) {
            xTicks.push(xTicksAll[i]);
        }
        xTicks[xTicks.length - 1] = xTicksAll[xTicksAll.length - 1];

        const dataLeft = [];
        const legendLeft = [];
        if (showFitness) {
            dataLeft.push(graphFitness);
            legendLeft.push(<Text id='teams.legend-fitness'>Fitness</Text>);
        }
        if (showFatigue) {
            dataLeft.push(graphFatigue);
            legendLeft.push(<Text id='teams.legend-fatigue'>Fatigue</Text>);
        }

        const dataRight = [];
        const legendRight = [];
        if (showForm) {
            dataRight.push(graphForm);
            legendRight.push(<Text id='teams.legend-form'>Form</Text>);
        }

        return (
            <>
                {showHeader && <h5><Text id='teams.fitness'>Form og belastning</Text></h5>}
                <div class='w-100'>
                    <LineGraph
                        stores={this.props.stores}
                        width={width}
                        height={height}
                        showXTicks={showXTicks}
                        xTicks={xTicks}
                        paddingTop={30}
                        lineFromXzero={false}
                        lineFromXmax={false}

                        dataLeft={dataLeft}
                        dataFillLeft={[1]}
                        legendLeft={showLegendLeft ? legendLeft : []}

                        dataRight={dataRight}
                        legendRight={legendRight}

                        // yMaxLeft={30}
                        // yMinLeft={0}
                        showYTicksLeft={showYTicksLeft}
                        avgWindowLeft={[0, 0]}
                        smoothLeft={[0, 0]}

                        // dataRight={[graphWorkoutAvgWeekElevationCurrent, graphAvgWorkoutStatsElevation]}
                        // legendRight={[`${seasonStart} ${ascendAvg}`, `${seasonStart} ${ascendAvgAll}`]}
                        showYTicksRight={showForm ? 1 : 0}
                        // yTicksRightPostfix={'m'}
                        // unitRight={['m', 'm']}
                        // avgWindowRight={[0, 0]}
                        // smoothRight={[1, 1]}

                        // marker={week}
                        scrollToRight={true}
                    />
                </div>
            </>
        );
    }
}

export default FormGraph;
