import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkState from 'linkstate';

import mu from '../../lib/musher-util';

@observer
class StoryPoll extends Component {
     constructor(props) {
        super(props);
        this.state = {};
        this.inputRef = {};
    }

    pollStory = async (e) => {
        const { storyId } = this.props;
		const { storyStore } = this.props.stores;
        const { line } = e.target.closest('button').dataset;
		const response = await storyStore.pollStory({ id: storyId, line });
	}

    toggleResult = () => {
        const { viewResult } = this.state;
        this.setState({ viewResult: !viewResult });
    }

    render() {
        const { viewResult } = this.state;
        const { story, storyId, params, opts, content, selectedImageIdx } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;

        const lines = content.split('\n').map(line => line.trim().replace(/^\-\s*/g, ''));
        const hasPolledStory = story.polls && story.polls?.findIndex(e => e.user === currentUser.id) > -1;
        const totalPolls = story.polls ? story.polls.length : 0;
        let maxPercent = 0;
        lines.forEach((line) => {
            const percent = (story.polls ? story.polls.filter(e => e.line === mu.md5(line)).length / totalPolls : 0) * 100;
            if (percent > maxPercent) {
                maxPercent = percent;
            }
        });
        const voteDays = opts.days ? parseInt(opts.days, 10) : 7;

        const finishDate = util.addDays(story.date, voteDays);
        const isExpired = finishDate < new Date();
        const hasImageRegexp = new RegExp(/{{img\./i);

        return (<>
            <div class='w-100 my-4 storyPoll'>
                {isExpired || hasPolledStory || viewResult ? <>
                    {lines && lines.map((line, idx) => {
                        const hasPolledLine = story.polls && story.polls?.findIndex(e => e.user === currentUser.id && e.line === mu.md5(line)) > -1;
                        const totalLinePolls = story.polls ? story.polls.filter(e => e.line === mu.md5(line)).length : 0;
                        const percent = totalLinePolls / totalPolls * 100;
                        return (<>
                            <div class={`progress position-relative my-2 `} style='height: 1.5em; font-size: 1.3em;'>
                                <span class='position-absolute' style='right: 10px;'> {util.format(percent || 0, 1)}%</span>
                                <span class='position-absolute' style='left: 10px;'>
                                    <Markdown markdown={mu.replaceImages(`${line} ${hasPolledLine ? `<i class='fas fa-check-circle' />` : ''}`, story.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                </span>
                                <div
                                    class={`progress-bar ${maxPercent === percent ? 'bg-primary' : 'bg-secondary'}`}
                                    role="progressbar"
                                    style={`width: ${percent}%;`}
                                    aria-valuenow={percent}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                />
                            </div>
                        </>);
                    })}
                    <div class='w-100 mt-3 text-muted d-flex justify-content-start'>
                        <div>
                            <small>
                                {util.format(totalPolls, 0)} {totalPolls === 1 ? <Text id='story.poll'>vote</Text> : <Text id='story.polls'>votes</Text>}
                            </small>
                        </div>
                        {isExpired ? <>
                            <div class='ml-2'>
                                <small>
                                    - <Text id='story.finished'>Finished</Text>
                                </small>
                            </div>
                        </> : <>
                            <div class='ml-2'>
                                <small>
                                    - {util.formatDistance(finishDate, new Date(), {}, { extended: true })} <Text id='story.left'>left</Text>
                                </small>
                            </div>
                            {!hasPolledStory && <div class='ml-2' onClick={this.toggleResult}>
                                <small>
                                    - <Text id='story.view-vote'>View vote</Text>
                                </small>
                            </div>}
                        </>}
                    </div>
                </> : <>
                    {lines && lines.map((line, idx) => <>
                        <button
                            class={`btn btn-block ${hasImageRegexp.test(line) && idx === selectedImageIdx ? 'btn-outline-success' : 'btn-outline-primary'} rounded-pill`}
                            onClick={this.pollStory}
                            data-line={mu.md5(line)}
                        >
                            {hasImageRegexp.test(line) && idx === selectedImageIdx && <span class='float-right'><i class='fas fa-eye' /></span>}
                            <Markdown markdown={mu.replaceImages(line, story.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                        </button>
                    </>)}

                    <div class='w-100 mt-3 text-muted d-flex justify-content-start'>
                        <div>
                            <small>
                                {util.format(totalPolls, 0)} {totalPolls === 1 ? <Text id='story.poll'>vote</Text> : <Text id='story.polls'>votes</Text>}
                            </small>
                        </div>
                        {isExpired ? <>
                            <div class='ml-2'>
                                <small>
                                    - <Text id='story.finished'>Finished</Text>
                                </small>
                            </div>
                        </> : <>
                            <div class='ml-2'>
                                <small>
                                    - {util.formatDistance(finishDate, new Date(), {}, { extended: true })} <Text id='story.left'>left</Text>
                                </small>
                            </div>
                        </>}
                        <div class='ml-2' onClick={this.toggleResult}>
                            <small>
                                - <Text id='story.view-results'>View results</Text>
                            </small>
                        </div>
                    </div>
                </>}
            </div>
        </>);
    }
}

export default StoryPoll;
