import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import PubSub, { topics } from '../../lib/pubsub';
// import DatePicker from '../../components/form/datePicker';
// import TimePicker from '../../components/form/timePicker';
import Images from '../../components/form/images';
import Dogs from '../../components/dog/dogs';
import Map from '../../components/map/mapbox';
import Files from '../../components/form/files';
import Field from '../../components/util/field';

import ScrollIntoView from '../../components/util/scrollIntoView';

const initialState = {
	mapHeight: '400px',
    newWorkout: {},
};

@observer
class InputNew extends Component {
    onInput = (e) => {
        const { field, datatype } = this.props;
        const value = datatype === 'int' ? parseInt(e.target.value, 10) : e.target.value;
        const { workoutStore } = this.props.stores;
        workoutStore.updateObjectKeyValue('newWorkout', field, value);
        workoutStore.updateObjectKeyValue('newWorkout', `updated-${field}`, new Date().getTime());
    }

    updateDuration = (name, value) => {
        const { field } = this.props;
        const { workoutStore } = this.props.stores;
        workoutStore.updateObjectKeyValue('newWorkout', name, value);
        const { newWorkout } = workoutStore;

        if (util.isUndefined(newWorkout[`${field}-hours`])) {
            // const hh = Math.floor(newWorkout[field] / 3600);
            const hh = 0;
            workoutStore.updateObjectKeyValue('newWorkout', `${field}-hours`, hh);
        }
        if (util.isUndefined(newWorkout[`${field}-minutes`])) {
            // const mi = Math.floor(newWorkout[field] % 3600 / 60);
            const mi = 0;
            workoutStore.updateObjectKeyValue('newWorkout', `${field}-minutes`, mi);
        }
        // console.log({ newWorkout, field, name, value });
        // console.log({ newWorkout, field, name, value });
        const hours = newWorkout[`${field}-hours`] || 0;
        const minutes = newWorkout[`${field}-minutes`] || 0;
        const combinedValue = (hours * 3600) + (minutes * 60);
        workoutStore.updateObjectKeyValue('newWorkout', field, combinedValue);
        workoutStore.updateObjectKeyValue('newWorkout', `updated-${field}`, new Date().getTime());
    }

    onInputDuration = (e) => {
        const value = parseInt(e.target.value, 10);
        const name = e.target.name;
        this.updateDuration(name, value);
    }

    decDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { workoutStore } = this.props.stores;
        const { newWorkout } = workoutStore;
        const currentValue = newWorkout[name] || 0
        const newValue = currentValue - 1;
        const value = newValue < 0 ? 0 : newValue;
        // console.log({ name, currentValue, newValue, value });
        this.updateDuration(name, value);
    }

    incDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { workoutStore } = this.props.stores;
        const { newWorkout } = workoutStore;
        const currentValue = newWorkout[name] || 0
        const newValue = currentValue + 1;
        const value = newValue < 0 ? 0 : newValue;
        this.updateDuration(name, value);
    }

    // selectDate = (value) => {
    //     const { field } = this.props;
    //     const { workoutStore } = this.props.stores;
    //     workoutStore.updateObjectKeyValue('newWorkout', field, util.isoDate(value));
    // }

    setDateToNow = () => {
        const { field } = this.props;
        const value = util.isoDate(undefined, false, false, true);
        const { workoutStore } = this.props.stores;
        workoutStore.updateObjectKeyValue('newWorkout', field, value);
    }

    setTimeToNow = () => {
        const { field } = this.props;
        const value = util.isoTime();
        const { workoutStore } = this.props.stores;
        workoutStore.updateObjectKeyValue('newWorkout', field, value);
    }

    setRightValue = () => {
        const { field, rightValueReal } = this.props;
        const { workoutStore } = this.props.stores;
        workoutStore.updateObjectKeyValue('newWorkout', field, rightValueReal);
    }

    render() {
        const { type, datalist = [], min, max, step, elementtype, field, title, icon, help, placeholder = '', rightValue, updateField } = this.props;
        const { workoutStore } = this.props.stores;
        const { newWorkout } = workoutStore;
        let maxValue = max;
        if (type === 'date') {
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        let displayValue;
        if (field === 'duration' || field === 'rest') {
            displayValue = util.secToHms(newWorkout[field], true);
        }
        if (field === 'weather' && util.checkNested(newWorkout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(newWorkout.yr[0].instant.details);
            const yrDate = util.isoDate(newWorkout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(newWorkout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${mu.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
            }
        }
        if (field === 'temperature' && util.checkNested(newWorkout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(newWorkout.yr[0].instant.details);
            const yrDate = util.isoDate(newWorkout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(newWorkout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C`;
            }
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group mb-1 pb-1 `}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        onInput={this.onInput}
                        value={newWorkout[field]}
                    />
                    <datalist id={`list-${field}`} >
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            );
        }
        if (type === 'duration') {
            const hh = Math.floor(newWorkout[field] / 3600);
            const mi = Math.floor(newWorkout[field] % 3600 / 60);
            // const ss = Math.floor(newWorkout[field] % 3600 % 60);

            return (
                <div class={`form-group mb-1 pb-1 `}>
                    <span class='float-right'>
                        <strong>{displayValue}</strong>
                    </span>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='form-row'>
                        <div class='input-group col-6 offset-0 col-xl-3 offset-xl-3'>
                            <div class='input-group-prepend '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-hours`}
                                    onClick={this.decDuration}
                                ><i class='fas fa-minus' /></button>
                            </div>
                            <input class={`form-control text-center`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-hours`}
                                value={hh}
                                type='number'
                                min='0'
                                max='24'
                            />
                            <div class='input-group-append '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-hours`}
                                    onClick={this.incDuration}
                                ><i class='fas fa-plus' /></button>
                            </div>
                        </div>
                        <div class='input-group col-6 col-xl-3'>
                            <div class='input-group-prepend '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-minutes`}
                                    onClick={this.decDuration}
                                ><i class='fas fa-minus' /></button>
                            </div>
                            <input class={`form-control text-center`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-minutes`}
                                value={mi}
                                type='number'
                                min='0'
                                max='59'
                            />
                            <div class='input-group-append '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-minutes`}
                                    onClick={this.incDuration}
                                ><i class='fas fa-plus' /></button>
                            </div>
                        </div>
                    </div>
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            );
        }
        if (type === 'toggle') {
            return (
                <div class={`form-group mb-1 pb-1 `}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='custom-control custom-switch'>
                        <input type='checkbox'
                            class='custom-control-input'
                            id={`${field}Input`}
                            value={newWorkout[field] === 1 ? 0 : 1}
                            data-field={field}
                            onInput={this.onInput}
                            checked={newWorkout[field] == 1 ? 'checked' : ''}
                        />
                        <label class='custom-control-label' for={`${field}Input`} />
                    </div>
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            );
        }
        if (type === 'toggleInline') {
            return (
                <div class='row mt-4'>
					<div class='col-8 text-muted text-right'>
                        {icon && <i class={`${icon} text-muted`} />} {title}
					</div>
					<div class='col-4'>
						<div class='custom-control custom-switch'>
                            <input type='checkbox'
                                class='custom-control-input'
                                id={`${field}Input`}
                                value={newWorkout[field] === 1 ? 0 : 1}
                                data-field={field}
                                onInput={this.onInput}
                                checked={newWorkout[field] == 1 ? 'checked' : ''}
                            />
                            <label class='custom-control-label' for={`${field}Input`} />
						</div>
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
					</div>
				</div>
            );
        }

        if (type === 'select') {
            return (
                <div class='form-group mb-1 pb-1 '>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <select
                        list={`list-${field}`}
                        class={`form-control`}
                        id={`${field}Input`}
                        aria-describedby={`${field}Help`}
                        value={newWorkout[field]}
                        data-field={field}
                        onInput={this.onInput}
                        autoComplete='off'
                    >
                        <option value=''>-- <Text id='workout.choose'>Choose</Text> {title} --</option>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </select>
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            );
        }

        if (type === 'radiolist') {
            return (
                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                    <div class='form-group mb-1 pb-1 d-flex flex-column'>
                        <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                            {datalist.map((el, idx) => {
                                return (<Fragment key={`list-${idx}-${el[0]}`}>
                                    <div class='d-flex justify-content-between border-bottom mt-1'>
                                        {el[2] && <div class='' style='width: 40px;'>
                                            <label for={`${field}Input${el[0]}`}><i class={el[2]} /></label>
                                        </div>}
                                        <div class='flex-grow-1'>
                                            <label class='w-100' for={`${field}Input${el[0]}`}>{el[1]}</label>
                                        </div>
                                        <div>
                                            <input
                                                type='radio'
                                                value={el[0]}
                                                id={`${field}Input${el[0]}`}
                                                name={field}
                                                onInput={this.onInput}
                                                checked={newWorkout[field] == el[0] ? 'checked' : ''}
                                            />
                                        </div>
                                    </div>
                                </Fragment>);
                            })}
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                    </div>
                </div>
            );
        }

        if (type === 'textarea') {
            return (<>
                <div class='form-group mb-1 pb-1 '>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <textarea
                        class={`form-control ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''}`}
                        id={`${field}Input`}
                        aria-describedby={`${field}Help`}
                        min={min}
                        max={maxValue}
                        step={step}
                        rows={5}
                        onInput={this.onInput}
                        value={newWorkout[field]}
                        placeholder={placeholder}
                        onKeyUp={mu.resizeTextarea}
                        onFocus={mu.resizeTextarea}
                    />
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
                </div>
            </>);
        }

        return (
            <div class='form-group mb-1 pb-1 '>
                {type === 'date' && <span class='float-right text-muted font-weight-lighter' onClick={this.setDateToNow}>I dag <i class='far fa-check-circle' /></span>}
                {type === 'time' && <span class='float-right text-muted font-weight-lighter' onClick={this.setTimeToNow}>Nå <i class='far fa-check-circle' /></span>}
                {rightValue && <span class='float-right text-muted font-weight-lighter' onClick={this.setRightValue}>{rightValue}</span>}
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                {displayValue && <span class='float-right'>
                    <strong>{displayValue}</strong>
                </span>}
                <input
                    type={type}
                    class={`form-control ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''}`}
                    id={`${field}Input`}
                    aria-describedby={`${field}Help`}
                    min={min}
                    max={maxValue}
                    step={step}
                    onInput={this.onInput}
                    value={newWorkout[field]}
                    placeholder={placeholder}
                    />
                <small id={`${field}Help`} class='form-text text-muted'>{help}</small>
            </div>
        );
    }
}

@observer
class Input extends Component {
    updateDuration = (name, value) => {
        const { field, updateField, workout } = this.props;

        const hhmmss = util.secToHms(workout[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            if (isNaN(value)) {
                hh = 0;
            } else {
                hh = value;
            }
        }
        if (name.match(/-minutes/)) {
            if (isNaN(value)) {
                mi = 0;
            } else {
                mi = value;
            }
        }
        if (name.match(/-seconds/)) {
            if (isNaN(value)) {
                ss = 0;
            } else {
                ss = value;
            }
        }
        const combinedValue = (hh * 3600) + (mi * 60) + ss;
        updateField({ id: workout.id, field, value: combinedValue });

    }

    onInputDuration = (e) => {
        const value = parseInt(e.target.value, 10);
        const name = e.target.name;
        this.updateDuration(name, value);
    }

    decDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { field, workout } = this.props;
        const hhmmss = util.secToHms(workout[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            const value = hh - 1 < 0 ? 0 : hh - 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-minutes/)) {
            const value = mi - 1 < 0 ? 0 : mi - 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-seconds/)) {
            const value = ss - 1 < 0 ? 0 : ss - 1;
            this.updateDuration(name, value);
        }
    }

    incDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { field, workout } = this.props;
        const hhmmss = util.secToHms(workout[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            const value = hh + 1 < 0 ? 0 : hh + 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-minutes/)) {
            const value = mi + 1 < 0 ? 0 : mi + 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-seconds/)) {
            const value = ss + 1 < 0 ? 0 : ss + 1;
            this.updateDuration(name, value);
        }

    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { workoutStore } = this.props.stores;
        const { workout } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        workoutStore.removeImage({
            id: workout.id,
            name: imageName,
        });
    }

    removeMainImage = () => {
        const { workout, field, updateField } = this.props;
        updateField({ id: workout.id, field, value: {} });
    }

    setDateToNow = () => {
        const { field, updateField, workout } = this.props;
        const value = util.isoDate(undefined, false, false, true);
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        updateField({ id: workout.id, field, value });
    }

    setTimeToNow = () => {
        const { field, updateField, workout } = this.props;
        const value = util.isoTime();
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        updateField({ id: workout.id, field, value });
    }

    setValue = (e) => {
        const value = e.target.parentElement.dataset.value || e.target.dataset.value;
        if (value) {
            const { field, updateField, workout } = this.props;
            // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
            updateField({ id: workout.id, field, value });
        }
    }

    setRightValue = () => {
        const { field, updateField, workout, rightValueReal } = this.props;
        updateField({ id: workout.id, field, value: rightValueReal });
    }

    render() {
        const { saved, workout, type, datalist = [], min, max, step, elementtype, field, title, icon, help, placeholder = '', onInput, updateField, rightValue } = this.props;
        let value = toJS(workout[field]);
        let maxValue = max;
        if (type === 'date') {
            if (value) {
                value = util.isoDate(workout[field], false, false, true);
            }
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        // if (type === 'time') {
        //     if (value) {
        //         value = util.isoTime(workout[field]);
        //     }
        // }
        let displayValue;
        let insertValue;
        if (field === 'duration' || field === 'rest') {
            displayValue = util.secToHms(value, true);
        }
        if (field === 'weather' && util.checkNested(workout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(workout.yr[0].instant.details);
            const yrDate = util.isoDate(workout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(workout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${mu.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
                insertValue = displayValue;
            }
        }
        if (field === 'temperature' && util.checkNested(workout, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(workout.yr[0].instant.details);
            const yrDate = util.isoDate(workout.yr[0].date, false, false, true);
            const workoutDate = util.isoDate(workout.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === workoutDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C`;
                insertValue = util.asNumber(yr.air_temperature);
            }
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Files saved={saved} object={workout} field={field} updateField={updateField} />
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'image') {
            return (
                <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='container-fluid'>
                        <div class='row'>
                            {/* {workout.image && workout.image.s3SmallLink && <div class='col-3 p-1'>
                                <img src={workout.image.s3SmallLink} class='img-fluid border rounded-lg' onError={this.handleImageErrored} />
                                <button class='btn btn-sm btn-link'
                                    onClick={this.removeMainImage}
                                    data-name={workout.image.name}
                                ><i class='fas fa-trash' /> Remove</button>
                                <span class='badge badge-success'>Main</span>
                            </div>} */}
                            {workout.images && workout.images.map(img => (
                                <div class='col-3 p-1'>
                                    <img
                                        src={img.s3SmallLink}
                                        alt={`May be an image of: ${mu.imageKeywords(img).join(', ')}`}
                                        class='img-fluid'
                                        onError={this.handleImageErrored}
                                    />
                                    <button class='btn btn-sm btn-link'
                                        onClick={this.removeImage}
                                        data-name={img.name}
                                    ><i class='fas fa-trash' /> Remove</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Images saved={saved} object={workout} field={field} updateField={updateField} autoOpen={false} stores={this.props.stores} key={`image-${field}`} />
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'>Endringene er lagret</div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={workout.id}
                        data-field={field}
                        onInput={onInput}
                    />
                    <datalist id={`list-${field}`} >
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'duration') {
            const hh = Math.floor(workout[field] / 3600);
            const mi = Math.floor(workout[field] % 3600 / 60);
            const ss = Math.floor(workout[field] % 3600 % 60);

            return (
                <div class={`form-group mb-1 pb-1 `}>
                    <span class='float-right'>
                        <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                    </span>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='form-row'>
                        <div class='input-group col-6 offset-0 col-xl-3 offset-xl-3'>
                            <div class='input-group-prepend '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-hours`}
                                    onClick={this.decDuration}
                                ><i class='fas fa-minus' /></button>
                            </div>
                            <input class={`form-control text-center`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-hours`}
                                value={hh}
                                type='number'
                                min='0'
                                max='24'
                            />
                            <div class='input-group-append '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-hours`}
                                    onClick={this.incDuration}
                                ><i class='fas fa-plus' /></button>
                            </div>
                        </div>
                        <div class='input-group col-6 col-xl-3'>
                            <div class='input-group-prepend '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-minutes`}
                                    onClick={this.decDuration}
                                ><i class='fas fa-minus' /></button>
                            </div>
                            <input class={`form-control text-center`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-minutes`}
                                value={mi}
                                type='number'
                                min='0'
                                max='59'
                            />
                            <div class='input-group-append '>
                                <button
                                    class='btn btn-sm btn-outline-secondary'
                                    type='button'
                                    data-name={`${field}-minutes`}
                                    onClick={this.incDuration}
                                ><i class='fas fa-plus' /></button>
                            </div>
                        </div>
                        {/* <div class='form-group col-3'>
                            <input class={`form-control`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-seconds`}
                                value={ss}
                                type='number'
                                min='0'
                                max='59'
                            />
                        </div> */}
                    </div>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'toggle') {
            return (
                <div class={`form-group ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <div class='custom-control custom-switch'>
                        <input type='checkbox'
                            class='custom-control-input'
                            id={`${field}Input`}
                            value={value === 1 ? 0 : 1}
                            data-id={workout.id}
                            data-field={field}
                            onInput={onInput}
                            checked={value == 1 ? 'checked' : ''}
                        />
                        <label class='custom-control-label' for={`${field}Input`} />
                    </div>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }

        if (type === 'select') {
            return (
                <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <select
                        list={`list-${field}`}
                        class={`form-control ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}
                        id={`${field}Input`}
                        aria-describedby={`${field}Help`}
                        value={value}
                        data-id={workout.id}
                        data-field={field}
                        onInput={onInput}
                        autoComplete='off'
                    >
                        <option value=''>-- <Text id='workouts.choose'>Choose</Text> {title} --</option>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </select>
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }

        if (type === 'textarea') {
            return (<>
                <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <textarea
                        class={`form-control ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''} ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}
                        id={`${field}Input`}
                        aria-describedby={`${field}Help`}
                        min={min}
                        max={maxValue}
                        step={step}
                        rows={5}
                        value={value}
                        data-id={workout.id}
                        data-field={field}
                        data-type={type}
                        data-elementtype={elementtype}
                        onInput={onInput}
                        onKeyUp={mu.resizeTextarea}
                        onFocus={mu.resizeTextarea}
                        placeholder={placeholder}
                    />
                    {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            </>);
        }

        return (
            <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                {type === 'date' && <span class='float-right text-muted font-weight-lighter' onClick={this.setDateToNow}>I dag <i class='far fa-check-circle' /></span>}
                {type === 'time' && <span class='float-right text-muted font-weight-lighter' onClick={this.setTimeToNow}>Nå <i class='far fa-check-circle' /></span>}
                {rightValue && <span class='float-right text-muted font-weight-lighter' onClick={this.setRightValue}>{rightValue}</span>}

                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                {displayValue && <span class='float-right'>
                    <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                </span>}
                <input type={type}
                    class={`form-control ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''} ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}
                    id={`${field}Input`}
                    aria-describedby={`${field}Help`}
                    min={min}
                    max={maxValue}
                    step={step}
                    value={value}
                    data-id={workout.id}
                    data-field={field}
                    data-type={type}
                    data-elementtype={elementtype}
                    onInput={onInput}
                    placeholder={placeholder}
                />
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}


@observer
class Mushers extends Component {
    toggleMusher(musherId) {
        const { workout, type, elementtype, field, updateField, isNew } = this.props;
        const value = toJS(workout[field]) || [];
        const idx = value?.indexOf(musherId);
        if (idx > -1) {
            value.splice(idx, 1);
        } else {
            value.push(musherId);
        }
        updateField({ id: workout.id, field, value, type, elementtype, isNew} );
    }

    render() {
        const { userStore } = this.props.stores;
        const { mushers } = userStore;
        const { saved, workout, field, title, icon, help } = this.props;
        const value = toJS(workout[field]) || [];
        // console.log('Dogs.render: type, elementType, value:', type, elementtype, value);
        return (
            <div class={`form-group mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-column pb-2 px-1'
                            >
                                {mushers && mushers.map((musher, idx) => (
                                    <div class='col mb-2 px-1'
                                        ref={value?.indexOf(musher.id) > -1 ? c => this.container = c : null}
                                    >
                                        <button
                                            class={`btn btn-block
                                                rounded-lg
                                                btn${value?.indexOf(musher.id) > -1 ? '' : '-outline'}-success
                                                text-nowrap overflow-hidden`}
                                            style='text-overflow: ellipsis;'
                                            onClick={() => this.toggleMusher(musher.id)}
                                        >
                                            <i class='fas fa-user mr-2' />
                                            {musher.firstname ? <>
                                                {musher.firstname} {musher.lastname}
                                            </> : <>
                                                {musher.email}
                                            </>}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Teams extends Component {
    toggleTeam(teamId) {
        const { dogStore, workoutStore } = this.props.stores;
        const { workout, type, field, updateField, isNew, selectTeam } = this.props;
        let value = toJS(workout[field]);

        if (value === teamId) {
            value = null;
        } else {
            value = teamId;
        }
        if (value === null) {
            updateField({ id: workout.id, field, value, type, isNew });
        } else if (workout.teams && workout.teams?.indexOf(value) > -1) {
            workoutStore.removeTeam({ id: workout.id, team: value });
        } else if (workout.team) {
            workoutStore.addTeam({ id: workout.id, team: value });
        } else {
            dogStore.setCurrentTeam(value);
			workoutStore.setCurrentTeam(value);
            updateField({ id: workout.id, field, value, type, isNew });
            if (util.isDefined(selectTeam)) {
                selectTeam(teamId);
            }
        }
    }

    render() {
        const { userStore } = this.props.stores;
        const { teams } = userStore;
        const { saved, workout, field, title, icon, help } = this.props;
        const value = toJS(workout[field]) || [];
        const { highlight } = this.props;
        // console.log('Dogs.render: type, elementType, value:', type, elementtype, value);
        return (
            <div class={`form-group mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-row flex-nowrap pb-2 px-1'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {teams && teams.map((team, idx) => {
                                    const isPrimary = value === team.id;
                                    const isSecondary = workout && util.isArray(workout.teams) && workout.teams?.indexOf(team.id) > -1;
                                    return (
                                        <div class='col mb-0 px-0'
                                            ref={(isPrimary || isSecondary) ? c => this.container = c : null}
                                        >
                                            <button
                                                class={`btn btn-block
            										rounded-none ${idx === 0 ? 'rounded-pill-left' : (idx + 1 === teams.length ? 'rounded-pill-right': '')}
                                                    btn${(isPrimary || isSecondary) ? '' : '-outline'}-${isSecondary ? 'info' : 'success'}
                                                    text-nowrap overflow-hidden`}
                                                style='text-overflow: ellipsis;'
                                                onClick={() => this.toggleTeam(team.id)}
                                            >
                                                {workout.hintTeam === team.id && <span class='float-right text-primary'><i class='fas fa-circle' /></span>}
                                                <i class='fas fa-users mr-2' />
                                                {team.name}

                                                {highlight && highlight?.indexOf(team.id) > -1 && <>
                                                    {value === team.id ? <>
                                                        <i class='fas fa-check float-right' />
                                                    </> : <>
                                                        <div class='spinner-grow text-warning float-right' role='status'>
                                                            <span class='sr-only'>Loading...</span>
                                                        </div>
                                                        <i class='far fa-hand-point-left float-right' style='font-size: 2.0em;' />
                                                    </>}
                                                </>}

                                                <small>
                                                    {isPrimary && <span class='badge badge-pill badge-primary'>Primary</span>}
                                                    {isSecondary && <span class='badge badge-pill badge-secondary'>Secondary</span>}
                                                </small>
                                                {isPrimary || isSecondary && <ScrollIntoView horizontal={'center'} />}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Tracks extends Component {
    toggleTrack(trackId, track) {
        const { showAll } = this.state;
        const { workout = {}, type, field, updateField, isNew } = this.props;
        const { userStore } = this.props.stores;
        const { types, user } = userStore;
        const { language } = user;

        const DAY_OF_WEEK = mu.getDays(language);

        let value = toJS(workout[field]);
        if (value === trackId) {
            value = null;
        } else {
            value = trackId;
        }
        const skipTimer = true;
        updateField({ id: workout.id, field, value, type, isNew, skipTimer });
        if (!workout.distanceKm) {
            updateField({ id: workout.id, field: 'distanceKm', value: track.distanceKm, type: 'number', isNew, skipTimer });
        }
        if (!workout.elevation) {
            updateField({ id: workout.id, field: 'elevation', value: track.elevation, type: 'number', isNew, skipTimer });
        }
        if (!workout.name) {
            const date = workout.date ? new Date(workout.date) : new Date();
            const dayOfWeek = DAY_OF_WEEK[date.getDay()];
            const type = types.find(e => e.id === workout.type);
            updateField({
                id: workout.id,
                field: 'name',
                value: `${track.name}: ${dayOfWeek}${type ? ` ${type.name}` : 'stur'}`,
                type: 'string',
                isNew,
                skipTimer,
            });
        }
        this.setState({
            filterText: '',
        });
        if (showAll) {
            this.toggleFilter();
        }
    }

    toggleFilter = () => {
        const { showAll } = this.state;
        this.setState({
            showAll: showAll ? 0 : 1,
        });
    }

    toggleSearchField = () => {
        const { showSearchField } = this.state;
        this.setState({
            showSearchField: showSearchField ? null : 1,
        });
    }

    render() {
        const { showAll, filterText, showSearchField } = this.state;
        const { userStore } = this.props.stores;
        const { tracks } = userStore;
        const { saved, workout, field, title, icon, help, filter = () => true } = this.props;
        const value = toJS(workout[field]);
        let trackFilter = showAll ? () => true : filter;
        if (filterText) {
            trackFilter = (e) => e.name.match(new RegExp(filterText, 'i')) || workout.track === e.id;
        }
        const filteredTracks = tracks.filter(trackFilter);

        return (
            <div class={`form-group mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <span class='float-right' onClick={this.toggleSearchField}><i class='fas fa-search' /></span>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    {showSearchField && <div class='row'>
                        <div class='col-12'>
                            <input class='form-control form-control-sm' type='text' placeholder='Søk etter runder' value={filterText} onInput={linkState(this, 'filterText')} />
                        </div>
                    </div>}
                    <div class='row'>
                        {filteredTracks && filteredTracks.map(track => (
                            <div class='col-6 col-sm-6 col-md-6 col-lg-6 mb-2'>
                                <div class={`border position-relative rounded p-2 pt-3 pb-1 ${value === track.id ? 'bg-success' : ''}`}
                                    onClick={() => this.toggleTrack(track.id, track)}
                                    style='line-height: 0.9em; cursor: pointer; pointer-events: inherit;'
                                >
                                    <small class='float-right position-absolute' style='top: 0; right: 5px;'>
                                        <small>
                                            <i class='fas fa-road' /> {util.format(track.distanceKm, 1)} km
                                            <i class='fas fa-mountain ml-2' /> {util.format(track.elevation, 0)} m
                                        </small>
                                    </small>
                                    {/* <div style='overflow: hidden; white-space: nowrap;'> */}
                                    <div style='overflow: hidden;'>
                                        {workout.hintTrack === track.id && <span class='float-right text-primary'><i class='fas fa-circle' /></span>}
                                        <small>
                                            <i class='fas fa-map-marked mr-2' />
                                            {track.name}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class='row'>
                        <div class='col-12 text-right'>
                            {showAll ?
                                <button class='btn btn-sm btn-link' onClick={this.toggleFilter}><Text id='workouts.show-last-used-tracks'>Vis kun de sist brukte rundene</Text></button> :
                                <button class='btn btn-sm btn-link' onClick={this.toggleFilter}><Text id='workouts.show-all-tracks'>Vis alle rundene</Text></button>
                            }
                        </div>
                    </div>
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Types extends Component {
    toggleType(typeId) {
        const { workout, type, field, updateField, isNew } = this.props;
        let value = toJS(workout[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: workout.id, field, value, type, isNew });
    }

    updateTextField = (e) => {
        const { type, updateField, isNew } = this.props;
        const { id, field } = e.target.dataset;
        const value = e.target.value;
        updateField({ id: parseInt(id, 10), field, value, type, isNew });
    }

    render() {
        const { userStore, raceStore } = this.props.stores;
        const { types, user } = userStore;
        const { language } = user;
        const { saved, workout, field, title, icon, help, isNew } = this.props;
        const value = toJS(workout[field]);

        const raceInProgress = raceStore.getSignupByUserEmail(user.email);
        const hasRaces = raceInProgress.length > 0;

        return (
            <div class={`form-group mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-row flex-nowrap pb-2 px-1'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {types && types.map((type, idx) => (
                                    <div class='col mb-0 px-0'
                                        ref={value === type.id ? c => this.container = c : null}
                                    >
                                        <button
                                            class={`btn btn-block
        										rounded-none ${idx === 0 ? 'rounded-pill-left' : (idx + 1 === types.length ? 'rounded-pill-right': '')}
                                                btn${value === type.id ? '' : '-outline'}-success
                                                text-nowrap overflow-hidden`}
                                            style='text-overflow: ellipsis;'
                                            onClick={() => this.toggleType(type.id)}
                                        >
                                            {type.icon && <i class={type.icon} />} {language && type.lang && type.lang[language] ? type.lang[language] : type.name}

                                            {hasRaces && type.id === 2 && <>
                                                {value === type.id ? <>
                                                    <i class='fas fa-check float-right' />
                                                </> : <>
                                                    <div class='spinner-grow text-warning float-right' role='status'>
                                                        <span class='sr-only'>Loading...</span>
                                                    </div>
                                                    <i class='far fa-hand-point-left float-right' style='font-size: 2.0em;' />
                                                </>}
                                            </>}
                                        </button>
                                        {value === type.id && <ScrollIntoView horizontal={'center'} />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {value === 4 && (
                        <div class='row'>
                            {isNew ?
                                <div class='col-12'>
                                    <InputNew stores={this.props.stores} field='typeTitle'
                                        title={<Text id='workouts.other'>Annet</Text>}
                                        help={<Text id='workouts.other-help'>Hva slags type tur/aktivitet var dette?</Text>} />
                                </div>
                                : <div class='col-12'>
                                    <Input saved={saved} workout={workout} field='typeTitle'
                                        title={<Text id='workouts.other'>Annet</Text>}
                                        help={<Text id='workouts.other-help'>Hva slags type tur/aktivitet var dette?</Text>}
                                        onInput={this.updateTextField} />
                                </div>}
                        </div>
                    )}
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Intensities extends Component {
    toggleType(typeId) {
        const { workout, type, field, updateField, isNew } = this.props;
        let value = toJS(workout[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: workout.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { intensities } = userStore;
        const { saved, workout, field, title, icon, help } = this.props;
        const value = toJS(workout[field]);
        return (
            <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        {intensities && intensities.map(intensity => (
                            <div class='col-12 mb-2'>
                                <button
                                    class={`btn btn-block btn${value === intensity.id ? '' : '-outline'}-${intensity.class || 'success'} text-nowrap overflow-hidden`}
                                    style='text-overflow: ellipsis;'
                                    onClick={() => this.toggleType(intensity.id)}
                                >
                                    {workout.hintIntensity === intensity.id && <span class='float-right text-primary'><i class='fas fa-circle' /></span>}
                                    <span style='font-size: 1.5em;'>{intensity.icon || ''} {intensity.name}</span>
                                    {intensity.help && <>
                                        <br />
                                        <small>{intensity.help}</small>
                                    </>}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}


@observer
class Equipments extends Component {
    toggleEquipment(equipmentId) {
        const { workout, type, field, updateField, isNew } = this.props;
        let value = toJS(workout[field]);
        if (value === equipmentId) {
            value = null;
        } else {
            value = equipmentId;
        }
        updateField({ id: workout.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { equipments, user } = userStore;
        const { language } = user;
        const { saved, workout, field, title, icon, help } = this.props;
        const value = toJS(workout[field]);
        return (
            <div class={`form-group  mb-1 pb-1  ${saved[`${field}.${workout.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-column'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {equipments && equipments.map(equipment => (
                                    <div class='col px-1 mb-1'>
                                        <button
                                            class={`btn btn-block btn${value === equipment.id ? '' : '-outline'}-success text-nowrap overflow-hidden`}
                                            style='text-overflow: ellipsis;'
                                            onClick={() => this.toggleEquipment(equipment.id)}
                                        >
                                            {equipment.icon && <i class={equipment.icon} />} {language && equipment.lang && equipment.lang[language] ? equipment.lang[language] : equipment.name}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${workout.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class RaceInProgress extends Component {
    // loadAll = async () => {
    //     const { raceStore } = this.props.stores;
    //     raceStore.loadRaceInProgress();
    // }

	// componentDidMount() {
    //     this.loadAll();
    // }

    render() {
        const { raceStore, userStore } = this.props.stores;
        const { user } = userStore;
        const raceInProgress = raceStore.getSignupByUserEmail(user.email);

        if (raceInProgress && raceInProgress.length > 0) {
            return (
                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                    <div class='alert alert-warning'>
                        <h5><Text id='races.race-in-progress'>Løp pågår</Text></h5>
                        <ul>
                            {raceInProgress.map(activeRace => <li>
                                {activeRace.race.title}
                            </li>)}
                        </ul>
                        <i class='fas fa-exclamation-triangle' /> <Text id='races.choose-correct-team-and-type'>Velg riktig team og type for løpet.</Text>
                    </div>
                </div>
            );
        }
        return '';
    }
}

@observer
class WorkoutsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            submenu: props.submenu || 'info',
        };
        this.updateTimer = {};
    }

    preload = () => {
        if (this.props.workout === 'new') {
            this.setState({ createNew: true });
        }
    }

    loadAll = async () => {
        const { field } = this.props;
        this.setState({ isLoading: true });
        await Promise.all([
            this.loadDogs(),
            this.loadWorkout(),
        ]);
        if (field === 'followTrack') {
            await this.loadTracks();
        }
		this.setState({ isLoading: false });

        const { raceStore } = this.props.stores;
        raceStore.loadRaceInProgress();
    }

    loadTracks = async () => {
        const { trackStore } = this.props.stores;
        await trackStore.load({ addData: ['publicTracks'] });
    }

    loadWorkout = async (team) => {
		const { workoutStore, userStore, dogStore } = this.props.stores;
        const { user } = userStore;
        const { language } = user;
        const DAY_OF_WEEK = mu.getDays(language);

        let { workouts = [], newWorkout } = workoutStore;
        if (this.props.workout === 'new') {
            this.setState({ createNew: true });

            const currentTeamObject = userStore.getCurrentTeam();
            const currentTeam = currentTeamObject?.id;
            const fallbackTeam = currentTeam || user.team;

            workouts = await workoutStore.load({ query: { team: (team || currentTeam) }, skipUpdate: 1, limit: 5, sort: '-createdDate' });
            let lastWorkout = workouts[0];

            if (lastWorkout) {
                // console.log({ lastWorkout, fallbackTeam, currentTeam });
                if (!newWorkout.team) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'team', fallbackTeam);
                }
                workoutStore.updateObjectKeyValue('newWorkout', 'dogs', lastWorkout.dogs || []);
                workoutStore.updateObjectKeyValue('newWorkout', 'dogsLeft', lastWorkout.dogsLeft || []);
                workoutStore.updateObjectKeyValue('newWorkout', 'dogsRight', lastWorkout.dogsRight || []);
                // workoutStore.updateObjectKeyValue('newWorkout', 'dogsLeft', []);
                // workoutStore.updateObjectKeyValue('newWorkout', 'dogsRight', []);
                workoutStore.updateObjectKeyValue('newWorkout', 'mushers', lastWorkout.mushers);
                // workoutStore.updateObjectKeyValue('newWorkout', 'temperature', lastWorkout.temperature || 5);
                // workoutStore.updateObjectKeyValue('newWorkout', 'name', lastWorkout.name);
                workoutStore.updateObjectKeyValue('newWorkout', 'type', lastWorkout.type);
                workoutStore.updateObjectKeyValue('newWorkout', 'equipment', lastWorkout.equipment);
                if (!newWorkout.duration) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'duration', lastWorkout.duration);
                }
                if (!newWorkout['duration-hours']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'duration-hours',
                        lastWorkout.duration ? Math.floor(lastWorkout.duration / 3600) : 0);
                }
                if (!newWorkout['duration-minutes']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'duration-minutes',
                        lastWorkout.duration ? Math.floor(lastWorkout.duration % 3600 / 60) : 0);
                }
                if (!newWorkout.rest) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'rest', lastWorkout.rest || 0);
                }
                if (!newWorkout['rest-hours']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'rest-hours',
                        lastWorkout.rest ? Math.floor(lastWorkout.rest / 3600) : 0);
                }
                if (!newWorkout['rest-minutes']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'rest-minutes',
                        lastWorkout.rest ? Math.floor(lastWorkout.rest % 3600 / 60) : 0);
                }
                workoutStore.updateObjectKeyValue('newWorkout', 'rating', lastWorkout.rating || 3);
                workoutStore.updateObjectKeyValue('newWorkout', 'sledWeight', lastWorkout.sledWeight);

                if (!newWorkout['updated-date']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'date', util.isoDate(this.props.date || lastWorkout.date, false,  false, true));
                }
                if (!newWorkout['updated-time']) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'time', util.isoTime(this.props.date ? `${this.props.date} 08:00:00` : lastWorkout.date));
                }

                workoutStore.updateObjectKeyValue('newWorkout', 'hintTeam', lastWorkout.team);
                workoutStore.updateObjectKeyValue('newWorkout', 'hintType', lastWorkout.type);
                workoutStore.updateObjectKeyValue('newWorkout', 'hintTrack', lastWorkout.track);
                workoutStore.updateObjectKeyValue('newWorkout', 'hintIntensity', lastWorkout.intensity);

                const lastUsedTracks = workouts.map(w => w.track);
                this.setState({ lastUsedTracks });
            } else {
                const date = new Date();
                const dayOfWeek = DAY_OF_WEEK[date.getDay()];
                const dogs = await dogStore.load({ query: { team: (team || currentTeam) }, skipUpdate: 1 });
                if (!newWorkout.team) {
                    workoutStore.updateObjectKeyValue('newWorkout', 'team', fallbackTeam);
                }
                workoutStore.updateObjectKeyValue('newWorkout', 'dogs', dogs.map(d => d.id) || []);
                workoutStore.updateObjectKeyValue('newWorkout', 'dogsLeft', []);
                workoutStore.updateObjectKeyValue('newWorkout', 'dogsRight', []);
                workoutStore.updateObjectKeyValue('newWorkout', 'mushers', [user.id]);
                workoutStore.updateObjectKeyValue('newWorkout', 'name', util.ucfirst(`${dayOfWeek} - Tur med hundene`));
                // workoutStore.updateObjectKeyValue('newWorkout', 'temperature', 5);
                workoutStore.updateObjectKeyValue('newWorkout', 'type', 1);
                workoutStore.updateObjectKeyValue('newWorkout', 'equipment', 2);
                workoutStore.updateObjectKeyValue('newWorkout', 'duration', 3600);
                workoutStore.updateObjectKeyValue('newWorkout', 'rest', 0);
                workoutStore.updateObjectKeyValue('newWorkout', 'rating', 3);
                workoutStore.updateObjectKeyValue('newWorkout', 'date', util.isoDate(undefined, false,  false, true));
                workoutStore.updateObjectKeyValue('newWorkout', 'time', util.isoTime());
            }
            workoutStore.updateObjectKeyValue('newWorkout', 'temperature', -1);
        } else {
            workoutStore.load(this.props.workout);
        }
    }

    loadDogs = async () => {
		const { dogStore } = this.props.stores;
		// const { currentTeam } = dogStore;
		// await dogStore.load({ query: { team: currentTeam }, addData: ['workoutSummary'] });
		await dogStore.load({ query: { deceased: 0 } });
	}

	resetDog() {
        const { workoutStore } = this.props.stores;
        workoutStore.updateKeyValue('newWorkout', {
            name: '',
        });
	}

    async loadWorkoutSummary({ props = this.props, type }) {
		const { userStore, workoutStore } = props.stores;

		const currentDay = new Date().getDate();
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear } = props;

		const prevMonthDate = new Date(currentYear, currentMonth - 2, 1);
		const prevMonth = prevMonthDate.getMonth() + 1;
        const prevYear = prevMonthDate.getFullYear();

		// const { currentTeam } = workoutStore;
		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const workoutType = type === 'all' ? null : type;

        await userStore.getWorkoutSummary({
			team: currentTeam.id,
			year,
			month,
			type: workoutType,
		});
		await userStore.getWorkoutSummaryDay({
			year: currentYear,
			month: currentMonth,
			day: currentDay,
			team: currentTeam.id,
			keyname: 'workoutSummaryDaysCurrentMonth',
            type: workoutType,
		});
		await userStore.getWorkoutSummaryDay({
			year: prevYear,
			month: prevMonth,
			team: currentTeam.id,
			keyname: 'workoutSummaryDaysCurrentMonthPrevMonth',
            type: workoutType,
		});
		await userStore.getWorkoutSummaryDay({
			year: currentYear - 1,
			month: currentMonth,
			team: currentTeam.id,
			keyname: 'workoutSummaryDaysCurrentMonthPrevSeason',
            type: workoutType,
		});
		await userStore.getWorkoutSummaryDay({
			daysBack: 500,
			team: currentTeam.id,
            type: workoutType,
		});
    }

    loadWorkouts = async ({ limit, offset }) => {
		const { workoutStore, userStore } = this.props.stores;
        const { user } = userStore;
        const team = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const append = offset > 0;
		await workoutStore.load({
			query: { team: team.id },
			limit,
			offset,
			addData: ['publicTeams', 'publicUsers'],
		}, append);
	}

	insertWorkout = async () => {
		const { workoutStore, userStore } = this.props.stores;
        const { callback } = this.props;
		const { newWorkout } = workoutStore;
		const response = await workoutStore.insert({ ...newWorkout });
        if (response.status === 422) {
            PubSub.publish(topics.STATUS_MESSAGE, {
				message: 'Mangler tittel på treningsøkten',
				type: 'warning',
				icon: 'fas fa-exclamation-triangle',
			});
        } else if (response.status >= 500) {
            PubSub.publish(topics.STATUS_MESSAGE, {
				message: response.message || response.error,
				type: 'danger',
				icon: 'fas fa-exclamation-triangle',
			});
        } else {
            this.resetDog();
            userStore.getInfo();
            this.loadWorkoutSummary({ props: this.props });
            this.loadWorkouts({ limit: 5, offset: 0 });
            if (util.isFunction(callback)) {
                callback();
            } else {
                route(`/workouts/${response.data.id}`);
            }
        }
	}

    updateField = (e) => {
        const { id, field, type, elementtype, isNew } = e.target.dataset;
        const { value, type: fieldType, min, max } = e.target;
        // console.log({ id, field, fieldType, type, elementtype, value, min, max });
        if (fieldType === 'date') {
            if (min && max) {
                if (value >= min && value <= max) {
                    this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
                }
            } else {
                this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
            }
        } else {
            this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
        }
    }

    updateFieldValue = async ({ id, field, value, datatype, elementtype, type, isNew = false, skipTimer = false }) => {
        const { workoutStore } = this.props.stores;
        let newValue = value;
        let localObjectValue = value;
        const { workout } = workoutStore;
        if (field === 'images') {
            if (Array.isArray(workout.images) && Array.isArray(value)) {
                localObjectValue = [...workout.images, ...value];
            } else {
                localObjectValue = [...workout.images];
            }
        }
        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }
        if (isNew) {
            workoutStore.updateObjectKeyValue('newWorkout', field, value);
        } else {
            workoutStore.updateField(id, field, localObjectValue);
            if (skipTimer) {
                await this.doUpdateField(id, field, newValue, localObjectValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue, localObjectValue);
                }, 500);
            }
        }

        // GPX update stuff
        if (field === 'gpx' && workout && util.checkNested(workout, 'gpx', 'gpxInfo')) {
            const { totalDistance, maxSpeed, avgSpeed, ascent, decent, duration, rest, startLatlng, endLatlng } = workout.gpx.gpxInfo;
            const updateObject = {};
            if (duration) {
                workoutStore.updateField(id, 'duration', duration);
                updateObject.duration = duration;
            }
            if (rest) {
                workoutStore.updateField(id, 'rest', rest);
                updateObject.rest = rest;
            }
            if (totalDistance) {
                workoutStore.updateField(id, 'distanceKm', util.roundTo(totalDistance, 10));
                updateObject.distanceKm = util.roundTo(totalDistance, 10);
            }
            if (maxSpeed) {
                workoutStore.updateField(id, 'speedmax', util.roundTo(maxSpeed, 10));
                updateObject.speedmax = util.roundTo(maxSpeed, 10);
            }
            if (avgSpeed) {
                workoutStore.updateField(id, 'speedAvg', util.roundTo(avgSpeed, 10));
                updateObject.speedAvg = util.roundTo(avgSpeed, 10);
            }
            if (ascent) {
                workoutStore.updateField(id, 'elevation', ascent);
                updateObject.elevation = ascent;
            }
            if (decent) {
                workoutStore.updateField(id, 'decent', decent);
                updateObject.decent = decent;
            }
            if (startLatlng) {
                workoutStore.updateField(id, 'startLatlng', startLatlng);
                updateObject.startLatlng = startLatlng;
            }
            if (endLatlng) {
                workoutStore.updateField(id, 'endLatlng', endLatlng);
                updateObject.endLatlng = endLatlng;
            }
            await workoutStore.save(updateObject, workout.id);
        }
    }

    async doUpdateField(id, field, value, localValue) {
        const { workoutStore, teamStore } = this.props.stores;
        if (id && field) {
            await workoutStore.saveField(id, field, value);
            teamStore.updateObjectKeyValue('publicWorkout', field, localValue);
        }
    }

    dogFilter = (e) => {
        const { workoutStore } = this.props.stores;
        const { workout } = workoutStore;
        if (workout && workout.dogs) {
            return (e.team === workout.team || workout.dogs?.indexOf(e.id) > -1);
        }
        return false;
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    getYrWeather = async (position) => {
        const { createNew } = this.state;
        const { workoutStore, userStore } = this.props.stores;
		const { workout = {}, newWorkout } = workoutStore;

        const firstPoint = mu.getFirstPoint(workout);
        const midPoint = mu.getMidPoint(workout, 0.5);
        // const endPoint = mu.getMidPoint(workout, 1);

        const weather = await userStore.getWeather({ lat: firstPoint[0], lon: firstPoint[1], altitude: firstPoint[2], force: true });
        // const weatherMid = await userStore.getWeather({ lat: midPoint[0], lon: midPoint[1], altitude: midPoint[2], force: true });

        if (createNew) {
            this.updateFieldValue({ id: newWorkout.id, field: 'yr', value: weather, isNew: true, skipTimer: false });
            // this.updateFieldValue({ id: newWorkout.id, field: 'yrMid', value: weatherMid, isNew: true, skipTimer: false });
            if (util.checkNested(newWorkout, 'yr', '0', 'instant', 'details') && !newWorkout.weather) {
                let yrValue = '';
                const yr = toJS(newWorkout.yr[0].instant.details);
                // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
                yrValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${mu.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
                // console.log({ yrValue });
                this.updateFieldValue({ id: newWorkout.id, field: 'weather', value: yrValue, isNew: true, skipTimer: false });
                if (!newWorkout.temperature) {
                    this.updateFieldValue({ id: newWorkout.id, field: 'temperature', value: yr.air_temperature, isNew: true, skipTimer: false });
                }
            }

        } else {
            this.updateFieldValue({ id: workout.id, field: 'yr', value: weather, isNew: false, skipTimer: false });
            // this.updateFieldValue({ id: workout.id, field: 'yrMid', value: weatherMid, isNew: false, skipTimer: false });
            if (util.checkNested(workout, 'yr', '0', 'instant', 'details')) {
                let yrValue = '';
                const yr = toJS(workout.yr[0].instant.details);
                const yrDate = util.isoDate(workout.yr[0].date, false, false, true);
                const workoutDate = util.isoDate(workout.date, false, false, true);
                // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
                if (yrDate === workoutDate && (!workout.weather || workout.weather === '')) {
                    yrValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${mu.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
                    // console.log({ yrValue, yrDate, workoutDate });
                    this.updateFieldValue({ id: workout.id, field: 'weather', value: yrValue, isNew: false, skipTimer: false });
                    if (!workout.temperature) {
                        this.updateFieldValue({ id: workout.id, field: 'temperature', value: yr.air_temperature, isNew: true, skipTimer: false });
                    }
                }
            }
        }
    }

    onMapClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
		const { id } = e.target.closest('button').dataset;
        // const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        appState.openDrawer3('workoutMap', { id, height: drawerHeightMedium });
	}

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

    toggleAdminDebug = () => {
        const { showAdminDebug } = this.state;
        this.setState({ showAdminDebug: !showAdminDebug });
    }

    componentWillMount() {
        this.preload();
    }

	componentDidMount() {
        this.loadAll();
    }

	render() {
        const { createNew, lastUsedTracks, submenu, showAdminDebug = true, isLoading, mapHeight } = this.state;
		const { workoutStore, userStore, appState, raceStore, trackStore } = this.props.stores;
        const { marginTop, marginBottom, showBackButton = true } = this.props;
        const { isAdmin, user, types, equipments } = userStore;
        const { language = 'en' } = user;
		const { insertStatus, saved, workout = {}, newWorkout = {}, workoutLocations, isTracking } = workoutStore;
		const { waypoints2 = [], waypoints3 = [] } = workoutStore;

        const { mapColorMode, mapRangeMin, mapRangeMax, isCordova, hasShareApi, viewmode } = appState;
        const { currentTeam: currentTeamId } = workoutStore;
        const currentTeam = userStore.findTeam(workout.team || currentTeamId, true);

        const raceInProgress = raceStore.getSignupByUserEmail(user.email);
        const raceTeams = raceInProgress.map(e => parseInt(e.musher.team, 10));

        const { name, gpxInfo = {}, gpx = { gpxInfo: {} } } = workout;

        const { field, title, help, icon, type, datatype, limitImageList, isNew } = this.props;

        let isStationary = false;
        if (createNew) {
            isStationary = [6, 8].indexOf(parseInt(newWorkout.type, 10)) > -1;
        } else {
            isStationary = [6, 8].indexOf(workout.type) > -1;
        }
        if (field && isNew) {
            let datalist = [];
            if (field === 'type') {
                datalist = types.map(type => {
                    const name = language && type.lang && type.lang[language] ? type.lang[language] : type.name;
                    return [type.id, name, type.icon];
                });
            } else if (field === 'equipment') {
                datalist = equipments.map(eq => {
                    const name = language && eq.lang && eq.lang[language] ? eq.lang[language] : eq.name;
                    return [eq.id, name, eq.icon];
                });
            } else if (field === 'followTrack') {
                const { tracks, publicTracks } = trackStore;
                datalist = [...tracks, ...publicTracks].map(tr => {
                    return [tr.uuidv4, tr.name];
                });
            }
            if (field === 'dogs') {
                return (<>
                    <Localizer>
                        <Dogs stores={this.props.stores} isNew={1} saved={saved}
                            object={newWorkout} type='array' icon='fas fa-dog' elementtype='number' field='dogs'
                            title={<Text id='workouts.dogs'>Hunder</Text>}
                            help={<Text id='workouts.dogs-help'>Hvilke hunder var med på turen.</Text>}
                            updateField={this.updateFieldValue} filter={(e) => (e.team === newWorkout.team || (newWorkout.dogs && newWorkout.dogs?.indexOf(e.id) > -1))}
                            showPositions={isStationary ? false : true}
                            currentTeam={currentTeam}
                            key={`dogs-${util.getNestedValue(currentTeam, 'id')}`}
                        />
                    </Localizer>
                </>);
            }
            return (<>
                <Localizer>
                    <InputNew
                        stores={this.props.stores}
                        field={field}
                        icon={icon}
                        datalist={datalist}
                        datatype={datatype}
                        type={type}
                        title={title}
                        // help={<Text id='workouts.type-help'>Type of workout</Text>}
                    />
                </Localizer>
            </>);
        }

        if (field) {
            return <>
                <Input
                    saved={saved}
                    workout={workout}
                    field={field}
                    icon={icon}
                    type={type}
                    stores={this.props.stores}
                    title={title}
                    help={help}
                    onInput={this.updateField}
                    updateField={this.updateFieldValue}
                    showImages={true}
                    limitImageList={limitImageList}
                />
            </>;
        }

        if (createNew) {
            return (
                <div class='container-fluid' style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                    {showBackButton && <button
                        type='button'
                        class='btn btn-link float-left pl-0 fixed-top text-primary'
                        style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                        onClick={this.back}
                    >
                        <i class='fas fa-arrow-circle-left' />
                    </button>}

                    <div class='row'>
                        <RaceInProgress stores={this.props.stores} />

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3' style='padding-left: 35px;'>
                            <div>
                                <h5><Text id='workouts.add-new'>Legg til ny treningstur</Text></h5>
                            </div>
                        </div>

                        {1 === 2 && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <div class='row'>
                                    <div class='w-100 overflow-hidden'>
                                        <div
                                            class='d-flex flex-row flex-nowrap pb-2 px-1'
                                            style='overflow: auto; scroll-snap-type: x mandatory;'
                                        >
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'info' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='info'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-info-circle' /> <Text id='workouts.info'>Info</Text></button>
                                                {submenu === 'info' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'team' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='team'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-users' /> <Text id='workouts.team'>Team</Text></button>
                                                {submenu === 'team' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'user' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='user'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-user' /> <Text id='workouts.user'>Musher</Text></button>
                                                {submenu === 'user' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'images' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='images'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-images' /> <Text id='workouts.images'>Bilder</Text></button>
                                                {submenu === 'images' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'gps' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='gps'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-map-marker-alt' /> <Text id='workouts.gps'>GPS</Text></button>
                                                {submenu === 'gps' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'equipment' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='equipment'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-sleigh' /> <Text id='workouts.equipment'>Equipment</Text></button>
                                                {submenu === 'equipment' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='advanced'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-cogs' /> <Text id='workouts.advanced'>advanced</Text></button>
                                                {submenu === 'advanced' && <ScrollIntoView horizontal={'center'}  />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}

                        {submenu === 'info' && <>
                            {/* {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Types stores={this.props.stores} isNew={1} saved={saved} workout={newWorkout} type='number' field='type' icon='fas fa-running'
                                        title={<Text id='workouts.type'>Type tur</Text>}
                                        help={<Text id='workouts.type-help'>Type treningstur. 'trening', 'konkurranse', 'tur'.</Text>}
                                        updateField={this.updateFieldValue} />
                                </div>
                            </>} */}
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} field='type' icon='fas fa-running'
                                        datalist={types.map(type => {
                                            const name = language && type.lang && type.lang[language] ? type.lang[language] : type.name;
                                            return [type.id, name];
                                        })}
                                        type='select'
                                        title={<Text id='workouts.type'>Workout type</Text>}
                                        // help={<Text id='workouts.type-help'>Type of workout</Text>}
                                    />
                                </Localizer>
                            </div>

                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} field='name' icon='fas fa-heading'
                                        title={<Text id='workouts.title'>Tittel</Text>}
                                        placeholder={<Text id='workouts.title-help'>Tittel på denne turen. 'Fin høsttur i øsende regn...'</Text>}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} field='comment' type='textarea'
                                        title={<Text id='workouts.comment'>Kommentar</Text>}
                                        placeholder={<Text id='workouts.comment-help'>Kommentar til turen. Hvordan gikk det?</Text>}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} field='privateComment' type='textarea'
                                        title={<Text id='workouts.privateComment'>Private comment</Text>}
                                        placeholder={<Text id='workouts.privateComment-help'>This comment is only shown to you and your team.</Text>}
                                    />
                                </Localizer>
                            </div>
                            {/* {currentTeam && currentTeam.public > 0 && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <InputNew stores={this.props.stores} field='private' icon='fas fa-eye' type='toggle'
                                        title={<Text id='workouts.private'>Skjul treningsturen</Text>}
                                        help={<Text id='workouts.private-help'>Skal denne turen være skjult for de som følger teamet ditt?</Text>}
                                    />
                                </div>
                            </>} */}
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <div class='row px-0'>
                                    <div class='col-6'>
                                        <Localizer>
                                            <InputNew stores={this.props.stores} field='date' icon='fas fa-calendar' type='date' min='2010-01-01' max='today'
                                                title={<Text id='workouts.date'>Dato</Text>}
                                                // help={<Text id='workouts.date-help'>Dato for turen. Format på dato er: 'YYYY-MM-DD'.</Text>}
                                            />
                                        </Localizer>
                                    </div>
                                    <div class='col-6'>
                                        <Localizer>
                                            <InputNew stores={this.props.stores} field='time' icon='fas fa-clock' type='time'
                                                title={<Text id='workouts.time'>Klokka</Text>}
                                                // help={<Text id='workouts.time-help'>Klokkeslett for turen. Format: 'HH:MI'.</Text>}
                                            />
                                        </Localizer>
                                    </div>
                                </div>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} field='duration' type='duration' icon='fas fa-stopwatch'
                                        title={<Text id='workouts.duration'>Varighet på treningen</Text>}
                                        // help={<Text id='workouts.duration-help'>Hvor lenge var du ute? (HH:MI)</Text>}
                                    />
                                </Localizer>
                            </div>
                            {!isStationary && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Localizer>
                                        <InputNew stores={this.props.stores} field='rest' type='duration' icon='fas fa-stopwatch'
                                            title={<Text id='workouts.rest'>Hviletid på treningen</Text>}
                                            // help={<Text id='workouts.rest-help'>Hvor lenge stod du stille? (HH:MI)</Text>}
                                        />
                                    </Localizer>
                                </div>
                            </>}
                            {/* <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                    <InputNew stores={this.props.stores} field='speedAvg'
                                        rightValue={`${util.format(mu.speedAvg({
                                            duration: newWorkout.duration,
                                            rest: newWorkout.rest,
                                            distanceKm: newWorkout.distanceKm,
                                        }), 1, '.', ' ')} km/t`}
                                        rightValueReal={mu.speedAvg({
                                            duration: newWorkout.duration,
                                            rest: newWorkout.rest,
                                            distanceKm: newWorkout.distanceKm,
                                        })}
                                        updateField={this.updateFieldValue}
                                        icon='fas fa-tachometer-alt'
                                        type='number' min='0' max='100' step='0.1'
                                        title={<Text id='workouts.avg-speed'
                                    >Gjennomsnittsfart</Text>}
                                        help={<Text id='workouts.avg-speed-help'>Gjennomsnittsfart når du var i bevegelse. Dersom du ikke vet dette så legger du bare inn distanse og varighet så blir dette regnet ut.</Text>} />
                                </>}
                            </div> */}
                            {!isStationary && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <div class='row px-0'>
                                        <div class='col-6'>
                                            <Localizer>
                                                <InputNew stores={this.props.stores} field='distanceKm' icon='fas fa-road'
                                                    title={<Text id='workouts.distance'>Distanse i km</Text>}
                                                    type='number' min='0' max='300' step='1'
                                                    // placeholder={<Text id='workouts.distance-help'>Hvor lang var turen i km.</Text>}
                                                />
                                            </Localizer>
                                        </div>
                                        <div class='col-6'>
                                            <Localizer>
                                                <InputNew stores={this.props.stores} field='elevation' icon='fas fa-mountain'
                                                    title={<Text id='workouts.ascend'>Høydemeter</Text>}
                                                    type='number' min='0' max='3000' step='1'
                                                    // placeholder={<Text id='workouts.ascend-help'>Høydemeter du klatret på denne turen.</Text>}
                                                />
                                            </Localizer>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}

                        {submenu === 'team' && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Teams stores={this.props.stores} isNew={1} saved={saved}
                                        workout={newWorkout} type='number' field='team' icon='fas fa-users'
                                        title={<Text id='workouts.team'>Team</Text>}
                                        help={<Text id='workouts.team-help'>Teamet som er på tur.</Text>}
                                        updateField={this.updateFieldValue} selectTeam={this.loadWorkout}
                                        highlight={raceTeams}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Dogs stores={this.props.stores} isNew={1} saved={saved}
                                        object={newWorkout} type='array' icon='fas fa-dog' elementtype='number' field='dogs'
                                        title={<Text id='workouts.dogs'>Hunder</Text>}
                                        help={<Text id='workouts.dogs-help'>Hvilke hunder var med på turen.</Text>}
                                        updateField={this.updateFieldValue} filter={(e) => (e.team === newWorkout.team || (newWorkout.dogs && newWorkout.dogs?.indexOf(e.id) > -1))}
                                        showPositions={isStationary ? false : true}
                                        currentTeam={currentTeam}
                                        key={`dogs-${util.getNestedValue(currentTeam, 'id')}`}
                                    />
                                </Localizer>
                            </div>
                        </>}

                        {submenu === 'user' && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Mushers stores={this.props.stores} isNew={1} saved={saved}
                                        workout={newWorkout} type='array' elementtype='number' field='mushers' icon='fas fa-user'
                                        title={<Text id='workouts.mushers'>Kjørere</Text>}
                                        help={<Text id='workouts.mushers-help'>Hvem av kjørerne var med på turen.</Text>}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                        </>}

                        {submenu === 'images' && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <div class='text-center text-muted'>
                                    <div class='display-1'><i class='fas fa-images' /></div>
                                    <Text id='workouts.save-to-add-images'>Please save workout and then go into edit mode to add images.</Text>
                                </div>
                            </div>
                        </>}

                        {submenu === 'gps' && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <div class='text-center text-muted mb-3'>
                                    <div class='display-1'><i class='fas fa-map-marker-alt' /></div>
                                    <Text id='workouts.save-to-add-gpx'>Please save workout and then you will be able to upload a GPX-file if you like.</Text>
                                </div>

                                <Localizer>
                                    <Tracks stores={this.props.stores} isNew={1} saved={saved}
                                        workout={newWorkout} type='number' field='track' icon='fas fa-map-marked'
                                        title={<Text id='workouts.track'>Runde</Text>}
                                        help={<Text id='workouts.track-help'>Hvilken treningsrunde er dette.</Text>}
                                        updateField={this.updateFieldValue} filter={(e) => (e.id === newWorkout.track || (lastUsedTracks && lastUsedTracks?.indexOf(e.id) > -1))}
                                    />
                                </Localizer>
                            </div>
                        </>}

                        {submenu === 'equipment' && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Equipments stores={this.props.stores} isNew={1} saved={saved}
                                        workout={newWorkout} type='number' field='equipment' icon='fas fa-sleigh'
                                        title={<Text id='workouts.equipment'>Utstyr</Text>}
                                        help={<Text id='workouts.equipment-help'>Utstyr som ble brukt. 'trollvogn', 'atv', 'slede'.</Text>}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <InputNew stores={this.props.stores} isNew={1} saved={saved}
                                        workout={newWorkout} type='number' field='sledWeight' icon='fas fa-sleigh'
                                        title={<Text id='workouts.sledWeight'>Sledevekt</Text>}
                                        help={<Text id='workouts.sledWeight-help'>Vekt på sleden denne turen.</Text>}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                        </>}

                        {submenu === 'advanced' && <>
                            {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Localizer>
                                        <InputNew stores={this.props.stores} field='weather' icon='fas fa-cloud-sun-rain'
                                            title={<Text id='workouts.weather'>Været på turen</Text>}
                                            help={<Text id='workouts.weather-help'>Hvordan var været.</Text>}
                                        />
                                    </Localizer>
                                </div>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Localizer>
                                        <InputNew stores={this.props.stores} field='temperature' icon='fas fa-temperature-low'
                                            title={<Text id='workouts.temperature'>Temperatur</Text>}
                                            type='number' min='-40' max='20' step='0.5'
                                            help={<Text id='workouts.temperature-help'>Hvilken temperatur var det.</Text>}
                                        />
                                    </Localizer>
                                </div>
                            </>}
                            {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Localizer>
                                        <InputNew stores={this.props.stores} field='rating' icon='fas fa-dice'
                                            title={<Text id='workouts.rating'>Rating av turen</Text>}
                                            type='number' min='0' max='6' step='1'
                                            help={<Text id='workouts.rating-help'>Din rating av turen. 1-6</Text>}
                                        />
                                    </Localizer>
                                </div>
                                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                    <Localizer>
                                        <Intensities stores={this.props.stores} isNew={1} saved={saved} workout={newWorkout} type='number' field='intensity' icon='fas fa-dumbbell'
                                            title={<Text id='workouts.intensity'>Intensitet</Text>}
                                            help={<Text id='workouts.intensity-help'>Hvor stor belastning var denne turen?</Text>}
                                            updateField={this.updateFieldValue}
                                        />
                                    </Localizer>
                                </div>
                            </>}
                        </>}

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <div class={`form-group mb-1 pb-1`}>
                                <button type='button' class='btn btn-block btn-primary rounded-pill-both'
                                    onClick={this.insertWorkout}
                                >
                                    <Text id='workouts.add-save-new'>Lagre treningstur</Text>
                                </button>
                            </div>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-3'>
                            <Localizer>
                                <InputNew
                                    stores={this.props.stores}
                                    field='skipNotification'
                                    title={<Text id='workouts.skip-notification'>Skip team notification</Text>}
                                    type='toggleInline'
                                />
                            </Localizer>
                        </div>

                    </div>

                    {insertStatus && <div class='alert alert-success mt-4 col-12' role='alert'>
						<Text id='workouts.workout-saved'>Treningen er lagret.</Text>
					</div>}

                </div>
            );
        }

		return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                {showBackButton && <button
                    type='button'
                    class='btn btn-link float-left pl-0 fixed-top text-primary'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                    onClick={this.back}
                >
                    <i class='fas fa-arrow-circle-left' />
                </button>}
				<div class='row'>
                    <RaceInProgress stores={this.props.stores} />

					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3' style='padding-left: 35px;'>
                        <div>
                            <h5><Text id='workouts.edit-workout'>Endre trening</Text>: {name}
                                {workout.isLocked === 1 && <i class='fas fa-lock text-danger ml-3' />}
                            </h5>
                        </div>
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                        <div class='row'>
                            <div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-2 px-1'
                                    style='overflow: auto; scroll-snap-type: x mandatory;'
                                >
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'info' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='info'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-info-circle' /> <Text id='workouts.info'>Info</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'team' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='team'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-users' /> <Text id='workouts.team'>Team</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'user' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='user'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-user' /> <Text id='workouts.user'>Musher</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'images' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='images'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-images' /> <Text id='workouts.images'>Bilder</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'gps' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='gps'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-map-marker-alt' /> <Text id='workouts.gps'>GPS</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'equipment' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='equipment'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-sleigh' /> <Text id='workouts.equipment'>Equipment</Text></button>
                                    </div>
                                    <div class='col px-0 pt-2'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='advanced'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-cogs' /> <Text id='workouts.advanced'>advanced</Text></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {submenu === 'info' && <>
                        {/* {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Types stores={this.props.stores} saved={saved} workout={workout} type='number' field='type' icon='fas fa-running'
                                    title={<Text id='workouts.type'>Type tur</Text>}
                                    help={<Text id='workouts.type-help'>Type treningstur. 'trening', 'konkurranse', 'tur'.</Text>}
                                    updateField={this.updateFieldValue}
                                />
                            </div>
                        </>} */}
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input
                                    stores={this.props.stores}
                                    saved={saved}
                                    workout={workout}
                                    field='type'
                                    type='select'
                                    icon='fas fa-running'
                                    datalist={types.map(type => {
                                        const name = language && type.lang && type.lang[language] ? type.lang[language] : type.name;
                                        return [type.id, name];
                                    })}
                                    title={<Text id='workouts.type'>Workout type</Text>}
                                    // help={<Text id='workouts.type-help'>Type of workout</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='name' icon='fas fa-heading'
                                    title={<Text id='workouts.title'>Tittel</Text>}
                                    placeholder={<Text id='workouts.title-help'>Tittel på denne turen. 'Fin høsttur i øsende regn...'</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='comment' type='textarea'
                                    title={<Text id='workouts.comment'>Kommentar</Text>}
                                    // help={<Text id='workouts.comment-help'>Kommentar til turen. Hvordan gikk det?</Text>}
                                    placeholder={<Text id='workouts.comment-help'>Kommentar til turen. Hvordan gikk det?</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='privateComment' type='textarea'
                                    title={<Text id='workouts.privateComment'>Private comment</Text>}
                                    placeholder={<Text id='workouts.privateComment-help'>This comment is only shown to you and your team.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <div class='row px-0'>
                                <div class='col-6'>
                                    <Localizer>
                                        <Input stores={this.props.stores} saved={saved} workout={workout} field='date' icon='fas fa-calendar' type='date' min='2010-01-01' max='today'
                                            title={<Text id='workouts.date'>Dato</Text>}
                                            // help={<Text id='workouts.date-help'>Dato for turen. Format på dato er: 'YYYY-MM-DD'.</Text>}
                                            onInput={this.updateField}
                                            updateField={this.updateFieldValue}
                                        />
                                    </Localizer>
                                    {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                        {isAdmin && gpxInfo.minEpoch > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpxInfo.minEpoch: {util.isoDate(gpxInfo.minEpoch, true, true)} <br />
                                        </small>}
                                        {isAdmin && gpx.gpxInfo && gpx.gpxInfo.startTime > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpx.gpxInfo.startTime {util.isoDate(gpx.gpxInfo.startTime, true, true)} <br />
                                        </small>}
                                    </div>}
                                </div>
                                <div class='col-6'>
                                    <Localizer>
                                        <Input stores={this.props.stores} saved={saved} workout={workout} field='time' icon='fas fa-clock' type='time'
                                            title={<Text id='workouts.time'>Klokka</Text>}
                                            // help={<Text id='workouts.time-help'>Klokkeslett for turen. Format: 'HH:MI'.</Text>}
                                            onInput={this.updateField}
                                            updateField={this.updateFieldValue}
                                        />
                                    </Localizer>
                                </div>
                            </div>
                        </div>

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='duration' type='duration' icon='fas fa-stopwatch'
                                    title={<Text id='workouts.duration'>Varighet på treningen</Text>}
                                    // help={<Text id='workouts.duration-help'>Hvor lenge var du ute? (HH:MI)</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                            {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                {isAdmin && gpxInfo.minEpoch > 0 && gpxInfo.maxEpoch > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                    gpxInfo.maxEpoch-minEpoch: {util.secToHms(gpxInfo.maxEpoch - gpxInfo.minEpoch)} <br />
                                </small>}
                                {isAdmin && gpxInfo.duration > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                    gpxInfo.duration: {util.secToHms(gpxInfo.duration)} <br />
                                </small>}
                                {isAdmin && gpx.gpxInfo && gpx.gpxInfo.endTime > 0 && gpx.gpxInfo.startTime > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                    gpx.gpxInfo.endTime-startTime: {util.secToHms((gpx.gpxInfo.endTime - gpx.gpxInfo.startTime) / 1000)} <br />
                                </small>}
                                {isAdmin && gpx.gpxInfo && gpx.gpxInfo.duration > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                    gpx.gpxInfo.duration: {util.secToHms(gpx.gpxInfo.duration)} <br />
                                </small>}
                            </div>}
                        </div>
                        {!isStationary && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Input saved={saved} workout={workout} field='rest' type='duration' icon='fas fa-stopwatch'
                                        title={<Text id='workouts.rest'>Hviletid på treningen</Text>}
                                        // help={<Text id='workouts.rest-help'>Hvor lenge stod du stille? (HH:MI)</Text>}
                                        onInput={this.updateField}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                                {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                    {isAdmin && gpxInfo.minEpoch > 0 && gpxInfo.maxEpoch > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                        gpxInfo.maxEpoch-minEpoch: {util.secToHms(gpxInfo.rest)} <br />
                                    </small>}
                                    {isAdmin && gpxInfo.rest > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                        gpxInfo.rest: {util.secToHms(gpxInfo.rest)} <br />
                                    </small>}
                                    {isAdmin && gpx.gpxInfo && gpx.gpxInfo.rest > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                        gpx.gpxInfo.rest: {util.secToHms(gpx.gpxInfo.rest)} <br />
                                    </small>}
                                </div>}
                            </div>
                        </>}
                        {!isStationary && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                    <Localizer>
                                        <Input saved={saved} workout={workout} field='speedAvg'
                                            rightValue={`${util.format(mu.speedAvg({
                                                duration: workout.duration,
                                                rest: workout.rest,
                                                distanceKm: workout.distanceKm,
                                            }), 1, '.', ' ')} km/t`}
                                            rightValueReal={mu.speedAvg({
                                                duration: workout.duration,
                                                rest: workout.rest,
                                                distanceKm: workout.distanceKm,
                                            })}
                                            updateField={this.updateFieldValue}
                                            icon='fas fa-tachometer-alt'
                                            title={<Text id='workouts.avg-speed'>Gjennomsnittsfart</Text>}
                                            type='number' min='0' max='100' step='0.1'
                                            help={<Text id='workouts.avg-speed-help'>Gjennomsnittsfart når du var i bevegelse. Dersom du ikke vet dette så legger du bare inn distanse og varighet så blir dette regnet ut.</Text>}
                                            onInput={this.updateField}
                                        />
                                    </Localizer>
                                    {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                        {isAdmin && gpxInfo.avgMovingSpeeds > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpxInfo.avgMovingSpeeds {util.format(gpxInfo.avgMovingSpeeds * 3.6, 2)} km/t <br />
                                        </small>}
                                        {isAdmin && gpxInfo.avgGpsSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpxInfo.avgGpsSpeed {util.format(gpxInfo.avgGpsSpeed * 3.6, 2)} km/t <br />
                                        </small>}
                                        {isAdmin && gpxInfo.avgGpsMovingSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpxInfo.avgGpsMovingSpeed {util.format(gpxInfo.avgGpsMovingSpeed * 3.6, 2)} km/t <br />
                                        </small>}
                                        {isAdmin && gpx.gpxInfo && gpx.gpxInfo.avgSpeed > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                            gpx.gpxInfo.avgSpeed {util.format(gpx.gpxInfo.avgSpeed * 3.6, 2)} km/t <br />
                                        </small>}
                                    </div>}
                                </>}
                            </div>
                        </>}

                        {!isStationary && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <div class='row px-0'>
                                    <div class='col-6'>
                                        <Localizer>
                                            <Input saved={saved} workout={workout} field='distanceKm' icon='fas fa-road'
                                                title={<Text id='workouts.distance'>Distanse i km</Text>}
                                                type='number' min='0' max='300' step='1'
                                                help={<Text id='workouts.distance-help'>Hvor lang var turen i km.</Text>}
                                                onInput={this.updateField}
                                                updateField={this.updateFieldValue}
                                            />
                                        </Localizer>
                                        {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                            {isAdmin && gpxInfo.calculatedTotalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpxInfo.calculatedTotalDistance: {util.format(gpxInfo.calculatedTotalDistance, 2)} km <br />
                                            </small>}
                                            {isAdmin && gpx.gpxInfo && gpx.gpxInfo.totalDistance > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpx.gpxInfo.totalDistance: {util.format(gpx.gpxInfo.totalDistance, 2)} km <br />
                                            </small>}
                                        </div>}
                                    </div>
                                    <div class='col-6'>
                                        <Localizer>
                                            <Input saved={saved} workout={workout} field='elevation' icon='fas fa-mountain'
                                                title={<Text id='workouts.ascend'>Høydemeter</Text>}
                                                type='number' min='0' max='3000' step='1'
                                                help={<Text id='workouts.ascend-help'>Høydemeter du klatret på denne turen.</Text>}
                                                onInput={this.updateField}
                                                updateField={this.updateFieldValue}
                                            />
                                        </Localizer>
                                        {showAdminDebug && <div onClick={this.toggleAdminDebug}>
                                            {isAdmin && gpxInfo.calculatedAscend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpxInfo.calculatedAscend: {util.format(gpxInfo.calculatedAscend, 0)} m <br />
                                            </small>}
                                            {isAdmin && gpxInfo.calculatedDescend > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpxInfo.calculatedDescend: {util.format(gpxInfo.calculatedDescend, 0)} m <br />
                                            </small>}
                                            {isAdmin && gpx.gpxInfo && gpx.gpxInfo.ascent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpx.gpxInfo.ascent: {util.format(gpx.gpxInfo.ascent, 0)} m <br />
                                            </small>}
                                            {isAdmin && gpx.gpxInfo && gpx.gpxInfo.descent > 0 && <small class='text-danger' style='line-height: 0.8em;'>
                                                gpx.gpxInfo.descent: {util.format(gpx.gpxInfo.descent, 0)} m <br />
                                            </small>}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>}

                    {submenu === 'team' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Teams stores={this.props.stores} saved={saved} workout={workout} type='number' field='team' icon='fas fa-users'
                                    title={<Text id='workouts.team'>Team</Text>}
                                    help={<Text id='workouts.team-help'>Teamet som er på tur.</Text>}
                                    updateField={this.updateFieldValue}
                                    highlight={raceTeams}
                                    />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Dogs stores={this.props.stores} saved={saved} object={workout} type='array' elementtype='number' field='dogs' icon='fas fa-dog'
                                    title={<Text id='workouts.dogs'>Hunder</Text>}
                                    help={<Text id='workouts.dogs-help'>Hvilke hunder var med på turen.</Text>}
                                    updateField={this.updateFieldValue}  filter={this.dogFilter}
                                    showPositions={isStationary ? false : ['beta', 'advanced', 'normal'].indexOf(viewmode) > -1}
                                    currentTeam={currentTeam}
                                />
                            </Localizer>
                        </div>
                    </>}

                    {submenu === 'user' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Mushers stores={this.props.stores} saved={saved} workout={workout} type='array'
                                    elementtype='number' field='mushers' icon='fas fa-user'
                                    title={<Text id='workouts.mushers'>Kjørere</Text>}
                                    help={<Text id='workouts.mushers-help'>Hvem av kjørerne var med på turen.</Text>}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                    </>}

                    {submenu === 'images' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='image' type='image'
                                    stores={this.props.stores}
                                    title={<Text id='workouts.photos'>Bilder</Text>}
                                    help={<Text id='workouts.photos-help'>Legg til bilde fra treningen.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                    </>}

                    {submenu === 'gps' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            {gpx && <>
                                <button type='button' class='btn btn-sm btn-link float-right' onClick={this.onMapClick} data-id={workout.id}>
									Toggle map size
								</button>

									<div class='' style={`height: ${mapHeight};`}>
                                        <Map
                                            stores={this.props.stores}
                                            mapId={`${workout.id}-${mapHeight}`}
                                            // geojson={workout.gpx.geoJSON}
                                            geojsonExternal={workout.uuidv4}
                                            startMarker={workout.startMarker}
                                            endMarker={workout.endMarker}
                                            center={workout.gpxInfo?.centerCoords}
                                            waypoints2={waypoints2}
                                            waypoints3={waypoints3}
                                            skipGeojson={false}
                                            gpxInfo={workout.gpxInfo}
                                            height={mapHeight}
                                            mapColorMode={mapColorMode}
                                            mapRangeMin={mapRangeMin}
                                            mapRangeMax={mapRangeMax}
                                            key={`workout-map-${workout.id}`}
                                            fullscreenMap={true}
                                            show3dMap={false}
                                        />
                                    </div>
                            </>}
                            <Localizer>
                                <Input saved={saved} workout={workout} field='gpx' type='file'
                                    title={<Text id='workouts.gpx'>Gpx</Text>}
                                    help={<Text id='workouts.gpx-help'>Gpx til denne treningen.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Tracks stores={this.props.stores} saved={saved} workout={workout} type='number' field='track' icon='fas fa-map-marked'
                                    title={<Text id='workouts.track'>Runde</Text>}
                                    help={<Text id='workouts.track-help'>Hvilken treningsrunde er dette.</Text>}
                                    updateField={this.updateFieldValue}
                                    filter={(e) => (e.id === workout.track)}
                                />
                            </Localizer>
                        </div>
                    </>}

                    {submenu === 'equipment' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Equipments stores={this.props.stores} saved={saved} workout={workout} type='number' field='equipment' icon='fas fa-sleigh'
                                    title={<Text id='workouts.equipment'>Utstyr</Text>}
                                    help={<Text id='workouts.equipment-help'>Utstyr som ble brukt. 'trollvogn', 'atv', 'slede'.</Text>}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            <Localizer>
                                <Input saved={saved} workout={workout} field='sledWeight' type='number' step='1' icon='fas fa-sleigh'
                                    title={<Text id='workouts.sledWeight'>Sledevekt</Text>}
                                    help={<Text id='workouts.sledWeight-help'>Vekt på sleden denne turen.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>
                    </>}

                    {submenu === 'advanced' && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                            {currentTeam && currentTeam.public > 0 && <>
                                <Localizer>
                                    <Input stores={this.props.stores} saved={saved} workout={workout} field='private' icon='fas fa-eye' type='toggle'
                                        title={<Text id='workouts.private'>Skjul treningsturen</Text>}
                                        help={<Text id='workouts.private-help'>Skal denne turen være skjult for de som følger teamet ditt?</Text>}
                                        onInput={this.updateField}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </>}
                        </div>

                        {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                {!util.checkNested(workout, 'yr', '0', 'instant', 'details') && <button class='btn btn-sm float-right btn-secondary' onClick={this.getYrWeather}>
                                    <Text id='workouts.fetch-weather'>Hent fra yr</Text>
                                </button>}
                                <Localizer>
                                    <Input saved={saved} workout={workout} field='weather' icon='fas fa-cloud-sun-rain'
                                        title={<Text id='workouts.weather'>Været på turen</Text>}
                                        help={<Text id='workouts.weather-help'>Hvordan var været.</Text>}
                                        onInput={this.updateField}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Input saved={saved} workout={workout} field='temperature' icon='fas fa-temperature-low'
                                        title={<Text id='workouts.temperature'>Temperatur</Text>}
                                        type='number' min='-40' max='20' step='0.5'
                                        help={<Text id='workouts.temperature-help'>Hvilken temperatur var det.</Text>}
                                        onInput={this.updateField}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                        </>}
                        {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Input saved={saved} workout={workout} field='rating' type='number' min='0' max='6' step='1' icon='fas fa-dice'
                                        title={<Text id='workouts.rating'>Rating av turen</Text>}
                                        help={<Text id='workouts.rating-help'>Din rating av turen. 1-6</Text>}
                                        onInput={this.updateField}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-2'>
                                <Localizer>
                                    <Intensities stores={this.props.stores} saved={saved}
                                        workout={workout} type='number' field='intensity' icon='fas fa-dumbbell'
                                        title={<Text id='workouts.intensity'>Intensitet</Text>}
                                        help={<Text id='workouts.intensity-help'>Hvor stor belastning var denne turen?</Text>}
                                        updateField={this.updateFieldValue}
                                    />
                                </Localizer>
                            </div>
                        </>}
                    </>}

                </div>
			</div>
		);
	}
}

export default WorkoutsEdit;
