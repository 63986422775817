import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkState from 'linkstate';

import StoryReplyComment from '../../components/story/commentReply';

import style from '../style.css';
import mu from '../../lib/musher-util';

const initialState = {
    showAddComment: {},
    showAllComments: false,
    showAllReplies: {},
    viewImageIdx: {},
    likeComment: {},
    likeReply: {},
    showOriginal: {},
};

const MAX_COMMENTS = 2;

@observer
class StoryComments extends Component {
     constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            showAllComments: props.showAllComments,
        };
        this.inputRef = {};
    }

    addLikeComment = async (e) => {
        const { callback = () => {} } = this.props;
        const commentId = e.target.closest('.commentContainer').dataset.id;
        const { story } = this.props;
		const { storyStore } = this.props.stores;

        const { likeComment } = this.state;
        likeComment[commentId] = true;
        this.setState({ likeComment });
        setTimeout(() => {
            const { likeComment } = this.state;
            delete likeComment[commentId];
            this.setState({ likeComment });
        }, 1200);

		const response = await storyStore.likeStoryComment({ id: story.id, commentId });
		if (response && response.status === 200) {
            callback({ type: 'likeComment', story, commentId, response });
		}
        return true;
    }

    likeReply = async (e) => {
        const { callback = () => {} } = this.props;
        const replyId = e.target.closest('.replyContainer').dataset.id;
        const commentId = e.target.closest('.replyContainer').dataset.comment;
        const { story } = this.props;
		const { storyStore } = this.props.stores;

        const { likeReply } = this.state;
        likeReply[replyId] = true;
        this.setState({ likeReply });
        setTimeout(() => {
            const { likeReply } = this.state;
            delete likeReply[replyId];
            this.setState({ likeReply });
        }, 1200);

		const response = await storyStore.likeStoryCommentReply({ id: story.id, replyId, commentId });
        if (response && response.status === 200) {
            callback({ type: 'likeReply', story, commentId, replyId, response });
		}
    }

    replyComment = async (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;

        const { showAddComment } = this.state;
        showAddComment[commentId] = !showAddComment[commentId];
		this.setState({
			showAddComment,
		}, () => {
            if (this.inputRef[commentId]) {
                this.inputRef[commentId].focus();
            }
		});
    }

    toggleComments = () => {
		const { showAllComments } = this.state;
		this.setState({
			showAllComments: !showAllComments,
		});
	}

    toggleReplies = (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;
		const { showAllReplies } = this.state;
        showAllReplies[commentId] = !showAllReplies[commentId];
		this.setState({
			showAllReplies,
		});
	}

    toggleMenu = (e) => {
        const { id } = e.target.closest('i').dataset;
		const { showMenu = {} } = this.state;
        showMenu[id] = !showMenu[id];
		this.setState({
            showDeleteComment: {},
            showEdit: {},
			showMenu,
		});
	}

    toggleEdit = (e) => {
        const { id, comment: commentEditText } = e.target.closest('button').dataset;
		const { showEdit = {} } = this.state;
        showEdit[id] = !showEdit[id];
		this.setState({
            showDeleteComment: {},
			showEdit,
            showMenu: {},
            commentEditText,
		});
	}

    toggleDeleteComment = (e) => {
        const { id } = e.target.closest('button').dataset;
		const { showDeleteComment = {} } = this.state;
        showDeleteComment[id] = !showDeleteComment[id];
		this.setState({
			showDeleteComment,
            showEdit: {},
            showMenu: {},
		});
	}

    deleteComment = async (e) => {
        const { id: commentId } = e.target.closest('button').dataset;
        const { story } = this.props;
        const { storyStore } = this.props.stores;
        await storyStore.deleteCommentStory({ id: story.id, commentId });
        this.setState({
            commentEditText: '',
			showDeleteComment: {},
            showEdit: {},
            showMenu: {},
		});
    }

    deleteCommentReply = async (e) => {
        const { id: replyId, commentid: commentId } = e.target.closest('button').dataset;
        const { story } = this.props;
        const { storyStore } = this.props.stores;
        await storyStore.deleteCommentReplyStory({ id: story.id, commentId, replyId });
        this.setState({
            commentEditText: '',
			showDeleteComment: {},
            showEdit: {},
            showMenu: {},
		});

    }

    saveComment = async (e) => {
        const { id: commentId } = e.target.closest('button').dataset;
        const { commentEditText: comment } = this.state;
        const { story } = this.props;
        const { storyStore } = this.props.stores;
        await storyStore.editCommentStory({ id: story.id, commentId, comment });
        this.setState({
            commentEditText: '',
			showDeleteComment: {},
            showEdit: {},
            showMenu: {},
		});
    }

    saveCommentReply = async (e) => {
        const { id: replyId, commentid: commentId } = e.target.closest('button').dataset;
        const { commentEditText: comment } = this.state;
        const { story } = this.props;
        const { storyStore } = this.props.stores;
        await storyStore.editCommentReplyStory({ id: story.id, commentId, replyId, comment });
        this.setState({
            commentEditText: '',
			showDeleteComment: {},
            showEdit: {},
            showMenu: {},
		});
    }

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { replyid, commentid } = e.target.closest('button').dataset;
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        const { showOriginal = {}, translatedText = {} } = this.state;
        const key = `${commentid}${replyid ? `-${replyid}`: ''}`;
        showOriginal[key] = 0;
        if (replyid) {
            translatedText[key] = await storyStore.translateCommentReply({ commentid, replyid, story, user });
        } else {
            translatedText[key] = await storyStore.translateComment({ commentid, story, user });
        }
        this.setState({ showOriginal, translatedText });
    }

    showOriginal = (e) => {
        const { replyid, commentid } = e.target.closest('button').dataset;
        e.preventDefault();
        e.stopPropagation();
        const { showOriginal = {} } = this.state;
        showOriginal[`${commentid}${replyid ? `-${replyid}`: ''}`] = 1;
        this.setState({ showOriginal });
    }

    displayTeam = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
	}

    openInbox = (e, fromuser) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
    }

    render() {
        const { showAddComment, showAllComments, showAllReplies, viewImageIdx, showMenu = {}, showEdit = {},
            showDeleteComment = {}, commentEditText, likeComment, showOriginal = {}, translatedText = {} } = this.state;
		const { story, callback = () => {}, initCall = () => {}, compact = false } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user: currentUser, isAdmin } = userStore;
        const { language = 'en' } = currentUser;

        let filteredComments = [];
        let totalComments = 0;
        let diplayedComments = 0;
        let max_comments;
        if (story.comments) {
            totalComments = story.comments.length;
            if (showAllComments) {
                filteredComments = story.comments;
            } else {
                // filteredComments = story.comments.slice(0, MAX_COMMENTS);
                max_comments = compact ? 0 : MAX_COMMENTS;
                filteredComments = story.comments.slice(Math.max(totalComments - max_comments, 0));
            }
            diplayedComments = filteredComments.length;
        }

        return (
            <div>
                {story && util.isArray(filteredComments) && filteredComments.reverse().map((comment) => {
                    const { user = {} } = comment;
                    const teams = storyStore.findPublicTeamByMembers(user.id);
                    const displayNameShort = mu.displayNameShort(user);
                    const displayName = mu.displayName(user);
                    const userImage = user.image || (user.images ? user.images[0] : undefined);
                    const hasLikedComment = comment.likes && comment.likes?.findIndex(e => e.user === currentUser.id) > -1;

                    let filteredReplises = [];
                    let totalReplies = 0;
                    let diplayedReplies = 0;
                    if (comment.comments) {
                        totalReplies = comment.comments.length;
                        if (showAllReplies[comment.id]) {
                            filteredReplises = comment.comments;
                        } else {
                            filteredReplises = comment.comments.slice(0, max_comments);
                        }
                        diplayedReplies = filteredReplises.length;
                    }

                    const images = [];
                    if (comment.images) {
                        const imgs = toJS(comment.images);
                        images.push(...imgs);
                    }
                    const imageIdx = viewImageIdx[comment.id] ? viewImageIdx[comment.id] : 0;
                    const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

                    const { currentLocationGeocode = {} } = comment;

                    const translation = storyStore.getCommentTranslations({
                        story,
                        commentid: comment.id,
                        targetLang: language,
                    }) || translatedText[comment.id];
                    let body = translation || comment.comment;
                    if (showOriginal[comment.id]) {
                        body = comment.comment;
                    }

                    return (
                        <div class='row'>
                            <div class='col-11 offset-1 d-flex mb-2 position-relative'>
                                <div
                                    class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                                    style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                >
                                    {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                </div>
                                <div class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'>
                                    <div class='bg-light p-2 rounded rounded-lg position-relative'>

                                        {currentUser.id === user.id && <>
                                            <span class='float-right font-weight-lighter text-muted'>
                                                <i class='fas fa-ellipsis-h' onClick={this.toggleMenu} data-id={comment.id} />
                                            </span>
                                        </>}
                                        {showMenu[comment.id] && <>
                                            <div class='position-absolute bg-white p-2 border rounded rounded-lg shadow-sm' style='top: 30px; right: 10px; width: 60%; z-index: 999999;'>
                                                <button class='btn btn-link btn-block text-left' onClick={this.toggleEdit} data-id={comment.id} data-comment={comment.comment}><i class='fas fa-edit' /> Edit</button>
                                                <hr />
                                                <button class='btn btn-link btn-block text-left' onClick={this.toggleDeleteComment} data-id={comment.id}><i class='fas fa-trash-alt' /> Delete</button>
                                            </div>
                                        </>}

                                        <div>
                                            <span style='font-size: 0.95em;'>
                                                <h5 class='font-weight-bold mb-2' style='font-size: 1.0em;'>
                                                    {user.uuidv4 && user.uuidv4 !== currentUser.uuidv4 ? <>
                                                        <a href={`/inbox/${user.uuidv4}`} onClick={(e) => this.openInbox(e, user.uuidv4)}>{displayName}</a>
                                                    </> : <>
                                                        {displayName}
                                                    </>}
                                                    {/* {teams && teams.length > 0 && <>
                                                        <span class='font-weight-lighter text-muted ml-1'>
                                                            {teams.filter(e => e.public === 1).slice(0, 1).map(t => <>
                                                                - <a href={`/teams/public/${t.uuidv4}`} onClick={(e) => this.displayTeam(e, t.uuidv4)}>{t.name}</a>
                                                            </>)}
                                                        </span>
                                                    </>} */}
                                                </h5>


                                                {showEdit[comment.id] ? <>
                                                    <div class='d-flex bd-highlight'>
                                                        <div class='p-0 w-100 bd-highlight'>
                                                            <label class='sr-only' for='inlineFormInput'><Text id='comments.comment'>Kommentar</Text></label>
                                                            <div class='border rounded rounded-lg position-relative'>
                                                                <Localizer>
                                                                    <textarea
                                                                        class='form-control-plaintext px-2'
                                                                        type='text'
                                                                        placeholder={<Text id='comments.write-comment'>Skriv en kommentar...</Text>}
                                                                        onInput={linkState(this, 'commentEditText')}
                                                                        onKeyUp={mu.resizeTextarea}
                                                                        onFocus={mu.resizeTextarea}
                                                                        style='height: 2.3em;'
                                                                        value={commentEditText}
                                                                    />
                                                                </Localizer>
                                                           </div>
                                                        </div>
                                                        <div class='p-0 pl-2 flex-shrink-1 bd-highlight'>
                                                            <button type='button' class='btn btn-primary mb-2'
                                                                onClick={this.saveComment}
                                                                data-id={comment.id}
                                                            ><i class='fas fa-paper-plane' /></button>
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    <Markdown markdown={mu.replaceImages(body, comment.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                                </>}
                                                {showDeleteComment[comment.id] && <div class='clearfix mt-3 mb-3'>
                                                    <button class='btn btn-danger float-left' onClick={this.deleteComment} data-id={comment.id}><i class='fas fa-trash-alt' /> Delete</button>
                                                    <button class='btn btn-secondary float-right' onClick={this.toggleDeleteComment} data-id={comment.id}><i class='fas fa-times-circle' /> Cancel</button>
                                                </div>}

                                            </span>
                                        </div>
                                        <div>
                                            <div class='container-fluid px-3 mt-1'>
                                                {currentImage && <div class='clearfix row position-relative'>
                                                    <div
                                                        class={`commentContainer col-12 px-0 overflow-hidden`}
                                                        style={`max-height: 60vh;`}
                                                        onDblclick={this.addLikeComment}
                                                        data-id={comment.id}
                                                    >
                                                        <img loading='lazy' class={`img-fluid`} style='min-width: 100px;' src={currentImage.s3LargeLink} />
                                                    </div>
                                                    {likeComment[comment.id] && <span class='likeHeart position-absolute text-white display-1' style='top: calc(50% - 50px); left: calc(50% - 50px); width: 100px; height: 100px;'>
                                                        <i class='fas fa-heart likeStory' />
                                                    </span>}
                                                </div>}
                                                {images && images.length > 1 && <div class='clearfix d-flex flex-wrap row'>
                                                    {util.isArray(images) && images.map((img, idx) => {
                                                        return (<>
                                                            <div
                                                                class={`col px-0 overflow-hidden`}
                                                                style={`max-height: 100px; min-width: 100px;`}
                                                            >
                                                                <img loading='lazy' class={`img-fluid border`} style='min-width: 100px;' src={img.s3SmallLink} onClick={this.viewImage} data-id={comment.id} data-idx={idx} />
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <small>
                                        <button class={`commentContainer btn btn-sm btn-link py-0 ${hasLikedComment ? 'text-primary' : ''}`}
                                            onClick={this.addLikeComment}
                                            data-id={comment.id}
                                            disabled={hasLikedComment}
                                        >
                                            {hasLikedComment ? <Text id='comments.has-liked'>Liked</Text> : <Text id='comments.like'>Lik</Text>}
                                        </button>
                                        -
                                        <button class='btn btn-sm btn-link py-0'
                                            onClick={this.replyComment}
                                            data-id={comment.id}
                                        >
                                            <Text id='comments.comment-add'>Kommentar</Text>
                                        </button>
                                        {comment.language !== language && <>
                                            -
                                            {!showOriginal[comment.id] && translation ? <>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link'
                                                    data-commentid={comment.id}
                                                    onClick={this.showOriginal}
                                                >
                                                    <i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text>
                                                </button>
                                            </> : <>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link'
                                                    onClick={this.translate}
                                                    data-commentid={comment.id}
                                                >
                                                    <i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text>
                                                </button>
                                            </>}
                                        </>}
                                        -
                                        <button class='btn btn-sm btn-link py-0 disabled'>
                                            {util.formatDistance(comment.createdDate, new Date(), { locale: 'no-NB' })}
                                            {comment.updatedDate && <>
                                                <span class='font-weight-light ml-2'>(Edited {util.formatDistance(comment.updatedDate, new Date(), { locale: 'no-NB' })})</span>
                                            </>}
                                        </button>
                                    </small>
                                </div>
                                {comment.likes && comment.likes.length > 0 && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                    <i class='fas fa-thumbs-up text-primary' /> {comment.likes.length}
                                </small>}
                            </div>
                            <div class='col-10 offset-2 mb-2'>
                                {comment && util.isArray(filteredReplises) && filteredReplises.map((reply) => {
                                    const { user = {} } = reply;
                                    const teams = storyStore.findPublicTeamByMembers(user.id);
                                    const displayNameShort = mu.displayNameShort(user);
                                    const displayName = mu.displayName(user);
                                    const userImage = user.image || (user.images ? user.images[0] : undefined);
                                    const hasLikedReply = reply.likes && reply.likes?.findIndex(e => e.user === currentUser.id) > -1;
                                    const { currentLocationGeocode = {} } = reply;

                                    const replyTranslation = storyStore.getCommentReplyTranslations({
                                        story,
                                        commentid: comment.id,
                                        replyid: reply.id,
                                        targetLang: language,
                                    }) || translatedText[`${comment.id}-${reply.id}`];
                                    let replyBody = replyTranslation || reply.comment;
                                    if (showOriginal[`${comment.id}-${reply.id}`]) {
                                        replyBody = reply.comment;
                                    }

                                    return (
                                        <div class='row'>
                                            <div class='col-12 d-flex mb-2 position-relative'>
                                                <div
                                                    class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                                                    style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                                >
                                                    {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                                </div>
                                                <div class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'>
                                                    <div class='bg-light p-2 rounded-lg position-relative'>
                                                    {currentUser.id === user.id && <>
                                                            <span class='float-right font-weight-lighter text-muted'>
                                                                <i class='fas fa-ellipsis-h' onClick={this.toggleMenu} data-id={reply.id} />
                                                            </span>
                                                        </>}
                                                        {showMenu[reply.id] && <>
                                                            <div class='position-absolute bg-white p-2 border rounded rounded-lg shadow-sm' style='top: 30px; right: 10px; width: 60%; z-index: 999999;'>
                                                                <button class='btn btn-link btn-block text-left' onClick={this.toggleEdit} data-id={reply.id} data-comment={reply.comment}><i class='fas fa-edit' /> Edit</button>
                                                                <hr />
                                                                <button class='btn btn-link btn-block text-left' onClick={this.toggleDeleteComment} data-id={reply.id}><i class='fas fa-trash-alt' /> Delete</button>
                                                            </div>
                                                        </>}

                                                        <span style='font-size: 0.95em;'>
                                                            <h5 class='font-weight-bold mb-2' style='font-size: 1.0em;'>
                                                                {user.uuidv4 && user.uuidv4 !== currentUser.uuidv4 ? <>
                                                                    <a href={`/inbox/${user.uuidv4}`} onClick={(e) => this.openInbox(e, user.uuidv4)}>{displayName}</a>
                                                                </> : <>
                                                                    {displayName}
                                                                </>}
                                                                {/* {teams && teams.length > 0 && <>
                                                                    <span class='font-weight-lighter text-muted ml-2'>
                                                                        {teams.filter(e => e.public === 1).slice(0, 1).map(t => <>
                                                                            - <a href={`/teams/public/${t.uuidv4}`} class='mr-2' onClick={(e) => this.displayTeam(e, t.uuidv4)}> {t.name}</a>
                                                                        </>)}
                                                                    </span>
                                                                </>} */}
                                                            </h5>


                                                            {showEdit[reply.id] ? <>
                                                                <div class='d-flex bd-highlight'>
                                                                    <div class='p-0 w-100 bd-highlight'>
                                                                        <label class='sr-only' for='inlineFormInput'><Text id='comments.comment'>Kommentar</Text></label>
                                                                        <div class='border rounded rounded-lg position-relative'>
                                                                            <Localizer>
                                                                                <textarea
                                                                                    class='form-control-plaintext px-2'
                                                                                    type='text'
                                                                                    placeholder={<Text id='comments.write-comment'>Skriv en kommentar...</Text>}
                                                                                    onInput={linkState(this, 'commentEditText')}
                                                                                    onKeyUp={mu.resizeTextarea}
                                                                                    onFocus={mu.resizeTextarea}
                                                                                    style='height: 2.3em;'
                                                                                    value={commentEditText}
                                                                                />
                                                                            </Localizer>
                                                                    </div>
                                                                    </div>
                                                                    <div class='p-0 pl-2 flex-shrink-1 bd-highlight'>
                                                                        <button type='button' class='btn btn-primary mb-2'
                                                                            onClick={this.saveCommentReply}
                                                                            data-commentid={comment.id}
                                                                            data-id={reply.id}
                                                                        ><i class='fas fa-paper-plane' /></button>
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                <Markdown markdown={mu.replaceImages(replyBody, reply.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                                            </>}
                                                            {showDeleteComment[reply.id] && <div class='clearfix mt-3 mb-3'>
                                                                <button class='btn btn-danger float-left' onClick={this.deleteCommentReply} data-id={reply.id} data-commentid={comment.id}><i class='fas fa-trash-alt' /> Delete</button>
                                                                <button class='btn btn-secondary float-right' onClick={this.toggleDeleteComment} data-id={reply.id}><i class='fas fa-times-circle' /> Cancel</button>
                                                            </div>}

                                                        </span>
                                                    </div>
                                                    <small>
                                                        <button class={`replyContainer btn btn-sm btn-link py-0 ${hasLikedReply ? 'text-primary' : ''}`}
                                                            onClick={this.likeReply}
                                                            data-comment={comment.id}
                                                            data-id={reply.id}
                                                            disabled={hasLikedReply}
                                                        >
                                                            {hasLikedReply ? <Text id='comments.has-liked'>Liked</Text> : <Text id='comments.like'>Lik</Text>}
                                                        </button>
                                                        -
                                                        <button class='btn btn-sm btn-link py-0'
                                                            onClick={this.replyComment}
                                                            data-id={comment.id}
                                                        >
                                                            <Text id='comments.comment-add'>Kommentar</Text>
                                                        </button>
                                                        {reply.language !== language && <>
                                                            -
                                                            {!showOriginal[`${comment.id}-${reply.id}`] && replyTranslation ? <>
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-sm btn-link'
                                                                    data-commentid={comment.id}
                                                                    data-replyid={reply.id}
                                                                    onClick={this.showOriginal}
                                                                >
                                                                    <i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text>
                                                                </button>
                                                            </> : <>
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-sm btn-link'
                                                                    onClick={this.translate}
                                                                    data-commentid={comment.id}
                                                                    data-replyid={reply.id}
                                                                >
                                                                    <i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text>
                                                                </button>
                                                            </>}
                                                        </>}
                                                        -
                                                        <button class='btn btn-sm btn-link py-0 disabled'>
                                                            {util.formatDistance(reply.createdDate, new Date(), { locale: 'no-NB' })}
                                                            {reply.updatedDate && <>
                                                                <span class='font-weight-light ml-2'>(Edited {util.formatDistance(reply.updatedDate, new Date(), { locale: 'no-NB' })})</span>
                                                            </>}
                                                        </button>
                                                    </small>
                                                </div>
                                                {reply.likes && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                                    <i class='fas fa-thumbs-up text-primary' /> {reply.likes.length}
                                                </small>}
                                            </div>
                                        </div>
                                    );
                                })}
                                {totalReplies - diplayedReplies > 0 && <div>
                                    <button class='btn btn-sm btn-link py-0'
                                        onClick={this.toggleReplies}
                                        data-id={comment.id}
                                    >
                                        <Text id='comments.show-more' fields={{ more: totalReplies - diplayedReplies }}>
                                            Vis {totalReplies - diplayedReplies} flere kommentarer...
                                        </Text>
                                    </button>
                                </div>}

                            </div>
                            {showAddComment[comment.id] && <div class='col-11 offset-1 d-flex mb-2 position-relative'>
                                <StoryReplyComment stores={this.props.stores} story={story}
                                    initCall={initCall}
                                    callback={callback}
                                    commentId={comment.id}
                                    inputRef={c => this.inputRef[comment.id] = c}
                                    inputRefObject={this.inputRef}
                                    key={`story-commentreply-${comment.id}`}
                                />
                            </div>}
                        </div>
                    );
                })}
                {totalComments - diplayedComments > 0 && <div>
                    <button class='btn btn-sm btn-link py-0'
                        onClick={this.toggleComments}
                    >
                        <Text id='comments.show-more' fields={{ more: totalComments - diplayedComments }}>
                            Vis {totalComments - diplayedComments} flere kommentarer...
                        </Text>
                    </button>
                </div>}
                {showAllComments && <div>
                    <button class='btn btn-sm btn-link py-0'
                        onClick={this.toggleComments}
                    >
                        <Text id='comments.hide-all-comments'>Skjul alle kommentarene.</Text>
                    </button>
                </div>}
            </div>
        );
    }
}

export default StoryComments;
