import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

function displayDistance(distance) {
	if (distance <= 0) {
		return '--,-';
	}
	if (distance < 5) {
		return util.format(distance, 2);
	}
	if (distance < 10) {
		return util.format(distance, 1);
	}
	return util.format(distance, 1);
}

function kmToMiles(value) {
    return 0.621371 * value;
}

@observer
class Distance extends Component {
    render() {
		const { value = 0, smallUnit, showUnit = true } = this.props;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;

        if (settings.imperial) {
            return (<>
                <nobr>
					{displayDistance(kmToMiles(value))} {showUnit && <>
						{smallUnit ? <><small><small><small class='ml-0'>mi</small></small></small></> : <>mi</>}
					</>}
				</nobr>
            </>);
        }

		return (<>
			<nobr>
	            {displayDistance(value)} {showUnit && <>
					{smallUnit ? <><small><small><small class='ml-0'>km</small></small></small></> : <>km</>}
				</>}
			</nobr>
	    </>);
    }
}

export default Distance;
