import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import { route } from 'preact-router';
import util from 'preact-util';

import mu from '../lib/musher-util';

import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

function merge(a, b, prop){
    const reduced =  a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

class StoryStore extends StoreModel {
    constructor() {
        super('story', {
            namePlural: 'stories',
            sort: 'date',
            limit: 100,
            api: {
                search: {
                    url: '/api/stories/',
                    params: {
                        extendedView: 1,
                        limit: 10,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/stories/',
                    params: {},
                },
                save: {
                    url: '/api/stories/',
                    params: {},
                },
            },
        });
    }

    @observable story = {};

    @observable stories = [];

    @observable publicStory = {};

    @observable publicFeed = [];

    @observable publicTeams = [];

    @observable publicUsers = [];

    @observable publicDogs = [];

    @observable publicTags = [];

    @observable topRankedStories = [];

    // @observable publicWorkouts = [];

    @observable latestEpoch = 0;

    @observable hasNewStories = false;

    @observable loadMore = () => {};

    @action
    beforeLoad({ id }) {
        if (id == this.story.id) {
            return;
        }
        const workoutPlaceholder = {
            team: '{placeholder}',
            date: '{placeholder}',
            body: '{placeholder}',
        };
        if (id) {
            this.workout = workoutPlaceholder;
        } else {
            this.workouts = [
                workoutPlaceholder,
                workoutPlaceholder,
                workoutPlaceholder,
            ];
        }
    }

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('StoryStore.cleanupMemory');
        }
        this.localUpdateField('stories', []);
        this.localUpdateField('publicFeed', []);
        this.localUpdateField('publicTeams', []);
        this.localUpdateField('publicUsers', []);
        this.localUpdateField('publicDogs', []);
        this.localUpdateField('publicTags', []);
        this.localUpdateField('topRankedStories', []);
        // this.localUpdateField('publicWorkouts', []);
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    publicFeedUpdate(id, field, value) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.publicFeed[idx][field] = value;
        }
    }


    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.story.images)) {
            const idx = this.story.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.story.images.splice(idx, 1);
            }
        }
        const widx = this.stories?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.stories[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.stories[widx].images.splice(idx, 1);
            }
        }
        const pubIdx = this.publicFeed?.findIndex(e => e.id === id);
        if (pubIdx > -1) {
            const idx = this.publicFeed[pubIdx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.publicFeed[pubIdx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/stories/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    findPublicTeam(team) {
        const idx = this.publicTeams?.findIndex(e => e.id === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByUuidv4(team) {
        const idx = this.publicTeams?.findIndex(e => e.uuidv4 === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByMembers(user) {
        if (!user) {
            return [];
        }
        const teams = this.publicTeams.filter(e => e.members?.indexOf(user) > -1);
        return teams;
    }

    @action
    findPublicUser(user) {
        if (!this.publicUsers) {
            return {};
        }
        const idx = this.publicUsers?.findIndex(e => e.id === user);
        if (idx > -1) {
            return this.publicUsers[idx];
        }
    }

    @action
    sortPublicFeed(sortBy) {
        this.publicFeed = this.publicFeed.sort(sortBy);
    }

    @action
    beforeFeedLoad() {
        const placeholder = {
            type: 1,
            team: '{placeholder}',
            date: '{placeholder}',
            title: '{placeholder}',
            body: '{placeholder}',
            comment: '{placeholder}',
        };
        this.publicFeed = [
            placeholder,
            placeholder,
            placeholder,
        ];
    }

    async loadPublicFeed({ story = '', hashtag, hashtags, skiptags = '', teamid, skipWorkouts, limit, offset, sort, clearStore = true }) {
        if (!offset && clearStore) {
            this.localUpdateField('publicFeed', []);
        }
        if (offset === 0) {
            this.beforeFeedLoad();
        }
        const response = await util.fetchApi(`/api/stories/public/${story}`, { publish: true, method: 'GET' }, { tag: hashtag, tags: hashtags, skiptags, skipWorkouts, teamid, limit, offset, sort });
        switch (response.status) {
            case 200:
                if (offset > 0) {
                    let publicFeed = toJS(this.publicFeed);
                    publicFeed.push(...response.data);
                    // if (publicFeed.length > 30) {
                    //     publicFeed = publicFeed.slice(-15)
                    // }
                    this.localUpdateField('publicFeed', publicFeed);
                    const publicTeams = merge(toJS(this.publicTeams), response.included.teams, 'id');
                    this.localUpdateField('publicTeams', publicTeams);
                    const publicUsers = merge(toJS(this.publicUsers), response.included.users, 'id');
                    this.localUpdateField('publicUsers', publicUsers);
                    const publicDogs = merge(toJS(this.publicDogs), response.included.dogs, 'id');
                    this.localUpdateField('publicDogs', publicDogs);
                } else {
                    this.localUpdateField('publicFeed', response.data);
                    this.localUpdateField('publicTeams', response.included.teams);
                    this.localUpdateField('publicUsers', response.included.users);
                    this.localUpdateField('publicDogs', response.included.dogs);
                    this.localUpdateField('publicTags', response.included.tags);
                    this.localUpdateField('topRankedStories', response.included.topRankedStories);
                    // this.localUpdateField('publicWorkouts', response.included.workouts);
                    this.localUpdateField('latestEpoch', response.included.latestEpoch);
                    this.localUpdateField('hasNewStories', false);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadPublicStory({ id }) {
        this.localUpdateField('publicStory', []);
        const response = await util.fetchApi(`/api/stories/public/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('publicStory', response.data[0]);
                this.localUpdateField('publicTeams', response.included.teams);
                this.localUpdateField('publicUsers', response.included.users);
                this.localUpdateField('publicDogs', response.included.dogs);
                this.localUpdateField('publicTags', response.included.tags);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async checkForNewStories({ story = '', hashtag, hashtags, skiptags = '', skipWorkouts, teamid, sort = '-date' }) {
        const response = await util.fetchApi(`/api/stories/public/${story}`, { publish: false, method: 'GET' }, { tag: hashtag, tags: hashtags, skiptags, skipWorkouts, teamid, limit: 1, sort, onlyStories: 1 });
        switch (response.status) {
            case 200:
                if (response.included.latestEpoch > this.latestEpoch) {
                    this.localUpdateField('hasNewStories', true);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToStory({ id, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicFeed[idx].likes)) {
                this.publicFeed[idx].likes = [];
            }
            this.publicFeed[idx].likes.push(data);
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isUndefined(this.publicStory.likes)) {
                this.publicStory.likes = [];
            }
            this.publicStory.likes.push(data);
        }
    }

    @action
    addPollToStory({ id, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicFeed[idx].polls)) {
                this.publicFeed[idx].polls = [];
            }
            this.publicFeed[idx].polls.push(data);
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isUndefined(this.publicStory.polls)) {
                this.publicStory.polls = [];
            }
            this.publicStory.polls.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    editComment({ id, commentId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                comment.comment = data;
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                comment.comment = data;
            }
        }
    }

    @action
    deleteComment({ id, commentId }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                this.publicFeed[idx].comments.splice([commentIdx], 1);
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                this.publicStory.comments.splice([commentIdx], 1);
            }
        }
    }

    @action
    editCommentReply({ id, commentId, replyId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
    }

    @action
    deleteCommentReply({ id, commentId, replyId }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
    }

    @action
    addCommentToStory({ id, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicFeed[idx].comments)) {
                this.publicFeed[idx].comments = [];
            }
            this.publicFeed[idx].comments.push(data);
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isUndefined(this.publicStory.comments)) {
                this.publicStory.comments = [];
            }
            this.publicStory.comments.push(data);
        }
    }

    @action
    addCommentToStoryComment({ id, commentId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
        if (this.publicStory && this.publicStory.id === id) {
            if (util.isDefined(this.publicStory.comments)) {
                const commentIdx = this.publicStory.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicStory.comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    @action
    removeStory({ id }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.publicFeed.splice(idx, 1)
        }
    }

    async adminHideStory({ id }) {
        const response = await util.fetchApi(`/api/stories/${id}/admin-hide`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                this.removeStory({ id });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reportStory({ id }) {
        const response = await util.fetchApi(`/api/stories/${id}/report`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeStory({ id }) {
        const response = await util.fetchApi(`/api/stories/like/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToStory({ id, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async pollStory({ id, line }) {
        const response = await util.fetchApi(`/api/stories/poll/${id}`, { publish: true, method: 'GET' }, { line });
        switch (response.status) {
            case 200:
                this.addPollToStory({ id, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeStoryComment({ id, commentId }) {
        const response = await util.fetchApi(`/api/stories/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToComment({ id, commentId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeStoryCommentReply({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/stories/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToCommentReply({ id, commentId, replyId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentStory({ id, comment }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToStory({ id, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async editCommentStory({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}/${commentId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.editComment({ id, commentId, data: comment });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async deleteCommentStory({ id, commentId }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}/${commentId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.deleteComment({ id, commentId });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async editCommentReplyStory({ id, commentId, replyId, comment }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}/${commentId}/${replyId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.editCommentReply({ id, commentId, replyId, data: comment });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async deleteCommentReplyStory({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}/${commentId}/${replyId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.deleteCommentReply({ id, commentId, replyId });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyStory({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/stories/comment/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToStoryComment({ id, commentId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async postScrollview(data = []) {
        if (!data || data?.length === 0) {
            return false;
        }
        const response = await util.fetchApi(`/api/scrollview/`, { publish: false, method: 'POST' }, { data });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    localUpdateObjectField(obj, key, value) {
        this[obj][key] = value;
    }

    @action
    updateStoryTranslation({ id, field, value, targetLang }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.publicFeed[idx][`${field}${targetLang}`] = value;
        }
    }

    // getTranslations({ story, targetLang, field }) {
    //     if (story.translations) {
    //         const idx = story.translations?.findIndex((e) => {
    //             return e.language === targetLang && e.field === field;
    //         });
    //         if (idx > -1) {
    //             return story.translations[idx].text;
    //         }
    //     }
    //     return null;
    // }

    async translate({ story, user, field = 'body' }) {
        const { id, language = 'no', body: text } = story;
        const { language: userLanguage = 'en' } = user;

        const sourceLang = language === 'undefined' ? 'no' : language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = mu.getTranslation({ object: story, targetLang, field });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/stories/${id}/translate/${sourceLang}/${targetLang}/${field}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateStoryTranslation({
                            id: story.id,
                            field,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    /* Comment translations */
    @action
    updateCommentTranslation({ id, commentid, value, targetLang, field = 'comment' }) {
        const storyIdx = this.publicFeed?.findIndex(e => e.id === id);
        if (storyIdx > -1) {
            const story = this.publicFeed[storyIdx];
            if (util.isDefined(story.comments)) {
                const commentIdx = story.comments?.findIndex(e => e.id === commentid);
                const comment = story.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, storyIdx, commentIdx, comment });

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        return e.language === targetLang && e.field === field;
                    });
                    comment.translations[translationIdx] = translationObj;
                } else {
                    comment.translations = [translationObj];
                }
            }
        }
    }

    @action
    updateCommentReplyTranslation({ id, commentid, replyid, value, targetLang, field = 'comment' }) {
        const storyIdx = this.publicFeed?.findIndex(e => e.id === id);
        if (storyIdx > -1) {
            const story = this.publicFeed[storyIdx];
            if (util.isDefined(story.comments)) {
                const commentIdx = story.comments?.findIndex(e => e.id === commentid);
                const comment = story.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, storyIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
    }

    getCommentTranslations({ story, commentid, targetLang, field = 'comment' }) {
        const storyIdx = this.publicFeed?.findIndex(e => e.id === story.id);
        if (storyIdx > -1) {
            const story = this.publicFeed[storyIdx];
            if (util.isDefined(story.comments)) {
                const commentIdx = story.comments?.findIndex(e => e.id === commentid);
                const comment = story.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        return null;
    }

    getCommentReplyTranslations({ story, commentid, replyid, targetLang, field = 'comment' }) {
        const storyIdx = this.publicFeed?.findIndex(e => e.id === story.id);
        if (storyIdx > -1) {
            const story = this.publicFeed[storyIdx];
            if (util.isDefined(story.comments)) {
                const commentIdx = story.comments?.findIndex(e => e.id === commentid);
                const comment = story.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        return null;
    }

    async translateComment({ story, user, commentid }) {
        const { id, language = 'no' } = story;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(story.comments)) {
            return null;
        }
        const commentIdx = story.comments?.findIndex(e => e.id === commentid);
        const comment = story.comments[commentIdx];
        const text = comment.comment;

        const sourceLang = comment.language === 'undefined' ? 'no' : comment.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;
        const translatedText = this.getCommentTranslations({ story, commentid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/stories/${id}/${commentid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentTranslation({
                            id: story.id,
                            commentid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    async translateCommentReply({ story, user, commentid, replyid }) {
        const { id, language = 'no' } = story;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(story.comments)) {
            return null;
        }
        const commentIdx = story.comments?.findIndex(e => e.id === commentid);
        const comment = story.comments[commentIdx];
        const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
        const reply = comment.comments[replyIdx];
        const text = reply.comment;

        const sourceLang = reply.language === 'undefined' ? 'no' : reply.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = this.getCommentReplyTranslations({ story, commentid, replyid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/stories/${id}/${commentid}/${replyid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentReplyTranslation({
                            id: story.id,
                            commentid,
                            replyid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    @action
    workoutsAddLikeToWorkout({ id, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicFeed[idx].likes)) {
                this.publicFeed[idx].likes = [];
            }
            this.publicFeed[idx].likes.push(data);
        }
    }

    @action
    workoutsAddLikeToComment({ id, commentId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    workoutsAddLikeToCommentReply({ id, commentId, replyId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    workoutsAddCommentToWorkout({ id, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicFeed[idx].comments)) {
                this.publicFeed[idx].comments = [];
            }
            this.publicFeed[idx].comments.push(data);
        }
    }

    @action
    workoutsAddCommentToWorkoutComment({ id, commentId, data }) {
        const idx = this.publicFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicFeed[idx].comments)) {
                const commentIdx = this.publicFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }


}

const store = new StoryStore();
export default store;
