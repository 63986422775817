import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { toJS } from 'mobx';
import Markdown from 'preact-markdown';

import TeamConnectBoxes from '../../components/team/connect-boxes';
import TeamConnectConfirm from '../../components/team/connect-confirm';

import Ad from '../../components/ad/';

import FastList from '../../components/team/fastList';

import Map from '../../components/map/mapbox';

import Overlay from '../../components/overlay/';
import TeamsPublicDetail from '../../routes/teams/publicDetail';
import DogPublicDetail from '../../routes/dogs/publicDetail';

import ImageScroller from '../../components/imageScroller';
import DogScroller from '../../components/dog/scroller';
import WorkoutComments from '../../components/workout/comments';
import WorkoutAddComment from '../../components/workout/commentAdd';
import WorkoutInfoGraph from '../../components/workout/infoGraph';

import PublicTeam from '../../components/team/public';

import LikesAndComments from '../../components/feedback/likesAndComments';
import Views from '../../components/feedback/views';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

import ScrollIntoView from '../../components/util/scrollIntoView';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

const initialState = {
    sessionid: new Date().getTime(),
};

@observer
class WorkoutPublicMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.mapContainer = null;
    }

    async loadPublicWorkout({ props = this.props }) {
		const { teamStore } = props.stores;
        const { workout: workoutid } = props;
        if (workoutid) {
            await teamStore.loadPublicFeed({ workoutid });
        }
	}

    toggleDate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

    componentDidMount() {
        this.loadPublicWorkout(this.props);
	}

	componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps.viewmenu, this.props.viewmenu);
        if (nextProps.workoutid !== this.props.workoutid) {
            this.loadPublicWorkout(nextProps);
        }
    }

    render() {
        const {
            mapHeight = '600px',
            showDate,
        } = this.state;

        const {
            holdingStore = 'team',
            displayAllComments = 'true',
            marginTop,
            marginBottom,
        } = this.props;
        const { teamStore, storyStore, userStore, appState } = this.props.stores;
        const { publicWorkout = {} } = teamStore;
        const { user, isAdmin } = userStore;
        const { language = 'en' } = user;
        const { darkmode, hasShareApi } = appState;
        const { mapColorMode, mapRangeMin, mapRangeMax, isCordova, viewmode, drawerHeightMedium } = appState;
        let publicDogs;

        let team = {};
        let publicUser = {};
        if (holdingStore === 'team') {
            team = teamStore.findPublicTeam(publicWorkout.team) || {};
            publicUser = teamStore.findPublicUser(publicWorkout.user) || {};
            const { publicDogs: teamPublicDogs } = teamStore;
            publicDogs = teamPublicDogs;
        } else if (holdingStore === 'story') {
            team = storyStore.findPublicTeam(publicWorkout.team) || {};
            publicUser = storyStore.findPublicUser(publicWorkout.user) || {};
            const { publicDogs: storyPublicDogs } = storyStore;
            publicDogs = storyPublicDogs;
        }

        const isTeamAdmin = (team && team.members) ? team.members.some(m => m === user.id) : false;

        const { likes, comments, reports = [] } = publicWorkout;
        const hasLikedWorkout = publicWorkout.likes && publicWorkout.likes?.findIndex(e => e.user === user.id) > -1;
        const totalFollowers = team && team.followers ? team.followers.length : 0;

        const isYourTeam = team.members && team.members?.indexOf(user.id) > -1;
        const isFollowing = team && team.followers && team.followers?.indexOf(user.id) > -1;
        const hasRequestedFollow = team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;
        const workoutEquipment = userStore.findEquipment({ equipment: publicWorkout.equipment, language });
        const workoutEquipmentIcon = userStore.findEquipment({ equipment: publicWorkout.equipment, field: 'icon' });

        const image = mu.getImage({ user: publicUser, team, object: publicWorkout, priority: 'user' });
		const { gpxInfo = {} } = publicWorkout;

        let shortname;
        let name;
        let username;
        let userLink;
        if (publicUser) {
            shortname = mu.displayNameShort(publicUser);
            name = mu.displayName(publicUser);
            username = mu.displayName(publicUser);
            if (publicUser.settings && publicUser.uuidv4 !== user.uuidv4) {
                userLink = publicUser.settings.hideProfile ? null : `/inbox/${publicUser.uuidv4}`;
            }
        }

        const { currentLocationGeocode = {}, yrWeather = [] } = publicWorkout;
        const views = mu.getViews(publicWorkout.statistics);
        const adminData = mu.getAdminData(publicWorkout.statistics);

        if (!team || !team.name) {
            return '';
        }

        const {
            publicWorkouts,
            publicWorkoutDetails,
            publicWorkoutSummary,
            publicTrophys,
            publicMemories,
        } = team;

        return (<>
			<div class={`container-fluid p-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} h-100`} style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <div class='row mt-0 h-100 position-relative'>
                    <div
                        class='col-12 my-0 h-100'
                        ref={c => this.mapContainer = c}
                    >
                            {util.checkNested(publicWorkout, 'gpx', 'geoJSON') ? <>
                                <div
                                    class='clearfix h-100'
                                    style={`height: ${drawerHeightMedium} !important;`}
                                >
                                    <Map
                                        stores={this.props.stores}
                                        mapId={`${publicWorkout.id}-${mapHeight}`}
                                        geojson={publicWorkout.gpx.geoJSON}
                                        skipGeojson={false}
                                        gpxInfo={publicWorkout.gpxInfo}
                                        height={mapHeight}
                                        mapColorMode={mapColorMode}
                                        mapRangeMin={mapRangeMin}
                                        mapRangeMax={mapRangeMax}
                                        // waypoints2={waypoints2}
                                        // waypoints3={waypoints3}
                                        key={`workout-map-${publicWorkout.id}`}
                                        fullscreenMap={true}
                                        showRange={true}
                                    />
                                </div>
                            </> : <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fa-solid fa-map-location-dot' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='workout.no-gpx-title'>No gps data for this workout.</Text>
                                    </h3>
                                </div>
                            </>}
                    </div>
                    <div
                        class={`position-absolute ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} border rounded rounded-lg p-2`}
                        style='bottom: 35px; left: 50%; z-index: 99999; width: 95vw; transform: translate(-50%,0);'
                    >
                        <div class='d-flex position-relative'>
                            <div class='text-center'>
                                <div
                                    class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                                    style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
                                    {!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
                                </div>
                            </div>
                            <div class='flex-grow-1 pl-3 text-overflow' style='line-height: 1.2em;'>
                                {/* <span class='badge badge-pill badge-secondary float-right'>{teamType}</span> */}
                                <h5 class='mb-1 position-relative' style='line-height: 1.0em;'>
                                    {team.name}
                                </h5>

                                <div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
                                    {publicWorkout.date && <small class='text-muted' onClick={this.toggleDate}>
                                        {showDate ? util.formatDate(publicWorkout.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' }) : util.formatDistance(publicWorkout.date, new Date(), { locale: 'no-NB' })}
                                    </small>}
                                    {username && <>
                                        <small class='ml-2 text-muted'>
                                            <i class='fas fa-user' /> {username}
                                        </small>
                                    </>}
                                    <small class='text-muted'>
                                        {publicWorkout.equipment > 0 && <><i class={`${workoutEquipmentIcon} ml-2`} /> {workoutEquipment}</>}
                                        {/* {publicWorkout.distanceKm > 0 && <><i class='fas fa-road ml-2' /> {displayDistance(publicWorkout.distanceKm)}km</>}
                                        {publicWorkout.elevation > 0 && <><i class='fas fa-mountain ml-2' /> {util.format(publicWorkout.elevation, 0)}m</>}
                                        {publicWorkout.speedAvg > 0 && <><i class='fas fa-tachometer-alt ml-2' /> {util.format(publicWorkout.speedAvg, 1)}km/t</>} */}
                                        {publicWorkout.dogs > 0 && <><i class='fas fa-dog ml-2' /> {publicWorkout.dogs.length}</>}
                                        {publicWorkout.track > 0 && <><i class={`fas fa-map-marked text-${publicWorkout.track > 0 ? 'success' : 'danger'} ml-2`} /></>}
                                        {/* {publicWorkout.dogsLeft > 0 && <><i class={`fas fa-crosshairs text-${publicWorkout.dogsLeft && publicWorkout.dogsRight ? 'success' : 'danger'} ml-2`} /></>} */}
                                        {publicWorkout.sledWeight > 0 && <>
                                            <i class='fas fa-sleigh ml-2' /> <Weight stores={this.props.stores} value={publicWorkout.sledWeight} />
                                        </>}
                                        {publicWorkout.temperature !== 0 && <>
                                            <i class='fas fa-temperature-low ml-2' /> <Temperature stores={this.props.stores} value={publicWorkout.temperature} />
                                        </>}
                                        {publicWorkout.dogs && publicWorkout.dogs.length > 0 && <>
                                            <i class='fas fa-dog ml-2' /> {util.format(publicWorkout.dogs.length, 0)}
                                        </>}
                                        {/* {publicWorkout.kcalBurnedTotal && <><i class='fas fa-balance-scale' /> {util.format(publicWorkout.kcalBurnedTotal)} kcal</>}
                                        {publicWorkout.kcalMeatGramsTotal && <><i class='fas fa-drumstick-bite ml-2' /> {util.format(publicWorkout.kcalMeatGramsTotal / 1000, 1)} kg</>}
                                        {publicWorkout.kcalSalmonGramsTotal && <><i class='fas fa-fish ml-2' /> {util.format(publicWorkout.kcalSalmonGramsTotal / 1000, 1)} kg</>}
                                        {publicWorkout.kcalFatGramsTotal && <><i class='fas fa-piggy-bank ml-2' /> {util.format(publicWorkout.kcalFatGramsTotal / 1000, 1)} kg</>} */}
                                        {currentLocationGeocode && currentLocationGeocode.formattedCounty && <>
                                            <i class='fas fa-map-marker-alt ml-2' /> {currentLocationGeocode.formattedCounty}
                                        </>}
                                        {publicWorkout.moonIllumination && publicWorkout.moonIllumination.phaseIcon && <span class='ml-2'>{publicWorkout.moonIllumination.phaseIcon}</span>}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <strong><Markdown markdown={mu.replaceImages(publicWorkout.name, publicWorkout.images, language)} markedOpts={mu.getMarkdownOptions()} /></strong>


                    </div>
                </div>
            </div>
        </>);
    }
}

export default WorkoutPublicMap;
