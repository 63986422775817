import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';

configure({ enforceActions: 'always' });

class VaccineLogStore extends StoreModel {
    constructor() {
        super('vaccineLog', {
            namePlural: 'vaccineLogs',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/veterinary/vaccine-logs/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/veterinary/vaccine-logs/',
                    params: {},
                },
                save: {
                    url: '/api/veterinary/vaccine-logs/',
                    params: {},
                },
            },
        });
    }

    @observable vaccineLog = {};

    @observable vaccineLogs = [];
}

const store = new VaccineLogStore();
export default store;
