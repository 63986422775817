import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';
import WidgetPrefix from '../../../components/widget/prefix';
import WidgetSegmentControl from '../../../components/widget/segment-control';

import MiniBarGraph from '../../../components/workout/miniBarGraph';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetWorkoutTotalRestDetail extends Component {
    constructor(props) {
        super(props);
        const currentWeek = util.getWeek(new Date());
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        this.state = {
            periode: 'week',
            periodeWeek: currentWeek,
            periodeMonth: currentMonth,
            periodeYear: currentYear,
        };
    }

    loadAll = async () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		await Promise.all([
			this.loadWorkoutSummary({ yyyy: currentYear, mm: currentMonth }),
			this.loadWorkoutPlans({ year: currentYear }),
		]);
	}

    loadWorkoutSummary = async ({ yyyy, mm, type }) => {
		const { userStore } = this.props.stores;

		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const year = yyyy || currentYear;
        const month = mm || currentMonth;

		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const workoutType = type === 'all' ? null : type;

        await userStore.getWorkoutSummary({
			team: currentTeam.id,
            daysBack: 365,
			year,
			month,
			type: workoutType,
		});
    }

    loadWorkoutPlans = async ({ year }) => {
		this.setState({ isLoading: true });

		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const seasonStart = parseInt(year || (currentMonth < 8 ? currentYear - 1 : currentYear), 10);
		const { workoutPlanStore, workoutStore, userStore } = this.props.stores;
		// const { currentTeam } = workoutStore;

		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		workoutPlanStore.updateItem('workoutPlans', []);
		workoutPlanStore.updateItem('workoutPlan', {});

		await workoutPlanStore.load({
			query: {
				team: currentTeam.id,
				seasonYear: seasonStart,
				seasonMonth: 6,
			},
			addData: ['tips'],
		});

		const { workoutPlans } = workoutPlanStore;
		const workoutPlanCurrent = workoutPlans[0] || {};
		if (workoutPlanCurrent.id) {
			await workoutPlanStore.load(workoutPlanCurrent.id);
			workoutPlanStore.prepareWeekGraphs({ year });
			workoutPlanStore.prepareDaysGraphs({
				year: currentYear,
				month: currentMonth,
			});
		} else {
			workoutPlanStore.updateKeyValue('workoutPlan', {});
			workoutPlanStore.updateKeyValue('workoutPlans', []);
			workoutPlanStore.resetGraphPlanWeekDistanceCurrent();
		}
		this.setState({ isLoading: false });
	}

    handlePeriodeChange = periode => {
        this.setState({ periode });
    }

    handleSegmentChange = value => {
        const { periode } = this.state;
        if (periode === 'week') {
            this.setState({ periodeWeek: value });
        } else if (periode === 'month') {
            this.setState({ periodeMonth: value });
        } else if (periode === 'year') {
            this.setState({ periodeYear: value });
            this.loadWorkoutSummary({ yyyy: value });
        }
    }

    getCurrentRest = () => {
        const { userStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const {
            graphWorkoutSummaryDayRestCurrent, graphWorkoutSummaryDayRestPrev,
            graphWorkoutSummaryWeekRestCurrent,
		} = userStore;
        if (periode === 'week') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayRestCurrent });
            const data = graphWorkoutSummaryDayRestCurrent.filter(e => e.week === periodeWeek).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'month') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayRestCurrent });
            const data = graphWorkoutSummaryDayRestCurrent.filter(e => e.month === periodeMonth).map((e, idx) => ({ ...e, x: idx }));
            // console.log('month', {data});
            return data;
        }
        if (periode === 'year') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayRestCurrent });
            const data = graphWorkoutSummaryWeekRestCurrent.filter(e => e.seasonStart === periodeYear).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        return graphWorkoutSummaryWeekRestCurrent;
    }

    componentDidMount() {
		// const { appState } = this.props.stores;
		this.loadAll();
		// this.setLoadMore();
	}

    render() {
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const { drawerLevel } = this.props;
        const { appState, userStore } = this.props.stores;
        const { darkmode } = appState;
        const { user } = userStore;
        const { language = 'en', settings = {} } = user;

        const graphWorkoutRestCurrent = this.getCurrentRest();

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={<Text id='workout.total-rest-detail'>Rest</Text>} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    <WidgetSegmentControl stores={this.props.stores} callbackPrimary={this.handlePeriodeChange} callbackSecondary={this.handleSegmentChange} />
                    <WidgetPrefix stores={this.props.stores}>
                        <span>{periodeYear}</span>
                        {periode === 'week' && <span class='ml-2'>w{periodeWeek}</span>}
                        {periode === 'month' && <span class='ml-2'>{mu.getMonth(periodeMonth, language)}</span>}
                    </WidgetPrefix>

                    <Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} header={`Rest`} onClick={this.onClick}>
                        <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                            <MiniBarGraph
                                stores={this.props.stores}
                                // dataLeft={[graphWorkoutDistanceCurrent, graphWorkoutDistancePrev]}
                                dataLeft={[graphWorkoutRestCurrent]}
                                yTicksLeftVal={val => util.secToHms(val, true)}
                                showDebug={false}
                                periode={periode}
                                // xVal={o => o.x}
                            />
                        </div>
                    </Widget>
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetWorkoutTotalRestDetail;
