import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import WorkoutReplyComment from '../../components/workout/commentReply';

import style from '../style.css';
import mu from '../../lib/musher-util';

const initialState = {
    showAddComment: {},
    showAllReplies: {},
    viewImageIdx: {},
};

const MAX_COMMENTS = 1;

@observer
class WorkoutComments extends Component {
     constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            showAllComments: props.displayAllComments,
        };
        this.inputRef = {};
    }

    likeComment = async (e) => {
        const { callback = () => {} } = this.props;
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;

        const { workout } = this.props;
		const { workoutStore } = this.props.stores;
		const response = await workoutStore.likeWorkoutComment({ id: workout.id, commentId });

		if (response && response.status === 200) {
            callback({ type: 'likeComment', workout, commentId, response });
		}
        return true;
    }

    likeReply = async (e) => {
        const { callback = () => {} } = this.props;

        const replyId = e.target.parentElement.dataset.id || e.target.dataset.id;
        const commentId = e.target.parentElement.dataset.comment || e.target.dataset.comment;

        const { workout } = this.props;
		const { workoutStore } = this.props.stores;
		const response = await workoutStore.likeWorkoutCommentReply({ id: workout.id, replyId, commentId });

        if (response && response.status === 200) {
            callback({ type: 'likeReply', workout, commentId, replyId, response });
		}
    }

    replyComment = async (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;

        const { showAddComment } = this.state;
        showAddComment[commentId] = !showAddComment[commentId];
		this.setState({
			showAddComment,
		}, () => {
            if (this.inputRef[commentId]) {
                this.inputRef[commentId].focus();
            }
		});
    }

    toggleComments = () => {
		const { showAllComments } = this.state;
		this.setState({
			showAllComments: !showAllComments,
		});
	}

    toggleReplies = (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;
		const { showAllReplies } = this.state;
        showAllReplies[commentId] = !showAllReplies[commentId];
		this.setState({
			showAllReplies,
		});
	}

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { replyid, commentid } = e.target.closest('button').dataset;
        const { workout } = this.props;
        const { userStore, teamStore } = this.props.stores;
        const { user } = userStore;
        const { showOriginal = {}, translatedText = {} } = this.state;
        const key = `${commentid}${replyid ? `-${replyid}`: ''}`;
        showOriginal[key] = 0;
        if (replyid) {
            translatedText[key] = await teamStore.translateCommentReply({ commentid, replyid, object: workout, user });
        } else {
            translatedText[key] = await teamStore.translateComment({ commentid, object: workout, user });
        }
        this.setState({ showOriginal, translatedText });
    }

    showOriginal = (e) => {
        const { replyid, commentid } = e.target.closest('button').dataset;
        e.preventDefault();
        e.stopPropagation();
        const { showOriginal = {} } = this.state;
        showOriginal[`${commentid}${replyid ? `-${replyid}`: ''}`] = 1;
        this.setState({ showOriginal });
    }

    displayTeam = (e, teamId) => {
        const { onTeamClick } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onTeamClick)) {
            onTeamClick(e, teamId);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
        }
	}

    openInbox = (e, fromuser) => {
        const { onUserClick } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onUserClick)) {
            onUserClick(e, fromuser);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
        }
    }

    render() {
        const { showAddComment, showAllComments, showAllReplies, viewImageIdx, showOriginal = {}, translatedText = {} } = this.state;
		const { workout, callback = () => {}, col = 12, offset = 0, compact = false } = this.props;
        const { userStore, teamStore } = this.props.stores;
        const { user: currentUser, isAdmin } = userStore;
        const { language = 'en' } = currentUser;

        let filteredComments = [];
        let totalComments = 0;
        let diplayedComments = 0;
        let max_comments;
        if (workout.comments) {
            totalComments = workout.comments.length;
            if (showAllComments) {
                filteredComments = workout.comments;
            } else {
                // filteredComments = workout.comments.slice(0, MAX_COMMENTS);
                max_comments = compact ? 0 : MAX_COMMENTS;
                filteredComments = workout.comments.slice(Math.max(totalComments - max_comments, 0));
            }
            diplayedComments = filteredComments.length;
        }

        return (
            <div>
                {/* {showAllComments && <div>
                    <button class='btn btn-sm btn-link py-0 mb-2'
                        onClick={this.toggleComments}
                    >
                        <Text id='comments.hide-all-comments'>Skjul alle kommentarene.</Text>
                    </button>
                </div>} */}
                {workout && filteredComments && filteredComments.reverse().map((comment) => {
                    const { user = {} } = comment;
                    const teams = teamStore.findPublicTeamByMembers(user.id);
                    const displayNameShort = mu.displayNameShort(user);
                    const displayName = mu.displayName(user);
                    const userImage = user.image || (user.images ? user.images[0] : undefined);
                    const hasLikedComment = comment.likes && comment.likes?.findIndex(e => e.user === currentUser.id) > -1;

                    let filteredReplises = [];
                    let totalReplies = 0;
                    let diplayedReplies = 0;
                    if (comment.comments) {
                        totalReplies = comment.comments.length;
                        if (showAllReplies[comment.id]) {
                            filteredReplises = comment.comments;
                        } else {
                            filteredReplises = comment.comments.slice(0, max_comments);
                        }
                        diplayedReplies = filteredReplises.length;
                    }

                    const images = [];
                    if (comment.images) {
                        const imgs = toJS(comment.images);
                        images.push(...imgs);
                    }
                    const imageIdx = viewImageIdx[comment.id] ? viewImageIdx[comment.id] : 0;
                    const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

                    const { currentLocationGeocode = {} } = comment;

                   const translation = teamStore.getCommentTranslations({
                        object: workout,
                        commentid: comment.id,
                        targetLang: language,
                    }) || translatedText[comment.id];
                    let body = translation || comment.comment;
                    if (showOriginal[comment.id]) {
                        body = comment.comment;
                    }

                    return (
                        <div class='row'>
                            <div class='col-12 d-flex mb-2 position-relative'>
                                <div
                                    class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                                    style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                >
                                    {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                </div>
                                <div class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'>
                                    <div class='bg-light p-2 rounded rounded-lg'>
                                        <div>
                                            <span style='font-size: 0.95em;'>
                                                <h5 class='font-weight-bold mb-0' style='font-size: 1.0em;'>
                                                    {user.uuidv4 && user.uuidv4 !== currentUser.uuidv4 ? <>
                                                        <a href={`/inbox/${user.uuidv4}`} onClick={(e) => this.openInbox(e, user.uuidv4)}>{displayName}</a>
                                                    </> : <>
                                                        {displayName}
                                                    </>}
                                                </h5>

                                                {/* {teams && teams.length > 0 && <div class='mb-2'>
                                                    <small class='font-weight-lighter text-muted'>
                                                        {teams.filter(e => e.public === 1).slice(0, 2).map(t => <>
                                                            <a href={`/teams/public/${t.uuidv4}`} onClick={(e) => this.displayTeam(e, t.uuidv4)} class='mr-2'><i class='fas fa-users text-muted' /> {t.name}</a>
                                                        </>)}
                                                    </small>
                                                </div>} */}

                                                <Markdown markdown={mu.replaceImages(body, comment.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                            </span>
                                        </div>
                                        <div class='container-fluid px-3 mt-1'>
                                            {currentImage && <div class='clearfix row'>
                                                <div
                                                    class={`col-12 px-0 overflow-hidden`}
                                                    style={`max-height: 30vh;`}
                                                >
                                                    <img loading='lazy' class={`img-fluid`} style='min-width: 100px;' src={currentImage.s3LargeLink} />
                                                </div>
                                            </div>}
                                            {images && images.length > 1 && <div class='clearfix d-flex flex-wrap row'>
                                                {images.map((img, idx) => {
                                                    return (<>
                                                        <div
                                                            class={`col px-0 overflow-hidden`}
                                                            style={`max-height: 100px; min-width: 100px;`}
                                                        >
                                                            <img loading='lazy' class={`img-fluid border`} style='min-width: 100px;' src={img.s3SmallLink} onClick={this.viewImage} data-id={comment.id} data-idx={idx} />
                                                        </div>
                                                    </>);
                                                })}
                                            </div>}
                                        </div>
                                    </div>
                                    <small>
                                        <button class={`btn btn-sm btn-link py-0 ${hasLikedComment ? 'text-primary' : ''}`}
                                            onClick={this.likeComment}
                                            data-id={comment.id}
                                        >
                                            <Text id='comments.like'>Lik</Text>
                                        </button>
                                        -
                                        <button class='btn btn-sm btn-link py-0'
                                            onClick={this.replyComment}
                                            data-id={comment.id}
                                        >
                                            <Text id='comments.comment-add'>Kommentar</Text>
                                        </button>
                                        {comment.language !== language && <>
                                            -
                                            {!showOriginal[comment.id] && translation ? <>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link'
                                                    data-commentid={comment.id}
                                                    onClick={this.showOriginal}
                                                >
                                                    <i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text>
                                                </button>
                                            </> : <>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link'
                                                    onClick={this.translate}
                                                    data-commentid={comment.id}
                                                >
                                                    <i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text>
                                                </button>
                                            </>}
                                        </>}
                                        -
                                        <button class='btn btn-sm btn-link py-0 disabled'>
                                            {util.formatDistance(comment.createdDate, new Date(), { locale: 'no-NB' })}
                                            {comment.updatedDate && <>
                                                <span class='font-weight-light ml-2'>(Edited {util.formatDistance(comment.updatedDate, new Date(), { locale: 'no-NB' })})</span>
                                            </>}
                                        </button>
                                        {isAdmin && currentLocationGeocode && currentLocationGeocode.formatted_address && <>
                                            <span class='ml-2 text-muted'>
                                                <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formatted_address}
                                            </span>
                                        </>}
                                    </small>
                                </div>
                                {comment.likes && comment.likes.length > 0 && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                    <i class='fas fa-thumbs-up text-primary' /> {comment.likes.length}
                                </small>}
                            </div>
                            <div class='col-11 offset-1 mb-2'>

                                {comment && filteredReplises && filteredReplises.map((reply) => {
                                    const { user = {} } = reply;
                                    const teams = teamStore.findPublicTeamByMembers(user.id);
                                    const displayNameShort = user.firstname ? `${util.ucfirst(user.firstname, true)}${util.ucfirst(user.lastname, true)}` : `${util.ucfirst(user.email || '', true)}`;
                                    const displayName = user.firstname ? `${user.firstname} ${user.lastname}` : `${user.email}`;
                                    const userImage = user.image || (user.images ? user.images[0] : undefined);
                                    const hasLikedReply = reply.likes && reply.likes?.findIndex(e => e.user === currentUser.id) > -1;

                                    const replyTranslation = teamStore.getCommentReplyTranslations({
                                        object: workout,
                                        commentid: comment.id,
                                        replyid: reply.id,
                                        targetLang: language,
                                    }) || translatedText[`${comment.id}-${reply.id}`];
                                    let replyBody = replyTranslation || reply.comment;
                                    if (showOriginal[`${comment.id}-${reply.id}`]) {
                                        replyBody = reply.comment;
                                    }

                                    return (
                                        <div class='row'>
                                            <div class='col-12 d-flex mb-2 position-relative'>
                                                <div
                                                    class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                                    style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                                >
                                                    {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                                </div>
                                                <div class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'>
                                                    <div class='bg-light p-2 rounded-lg'>
                                                        <span style='font-size: 0.95em;'>
                                                            <h5 class='font-weight-bold mb-0' style='font-size: 1.0em;'>
                                                                {user.uuidv4 && user.uuidv4 !== currentUser.uuidv4 ? <>
                                                                    <a href={`/inbox/${user.uuidv4}`} onClick={(e) => this.openInbox(e, user.uuidv4)}>{displayName}</a>
                                                                </> : <>
                                                                    {displayName}
                                                                </>}
                                                            </h5>

                                                            {/* {teams && teams.length > 0 && <div class='mb-2'>
                                                                <small class='font-weight-lighter text-muted'>
                                                                    {teams.filter(e => e.public === 1).slice(0, 2).map(t => <>
                                                                        <a href={`/teams/public/${t.uuidv4}`}  onClick={(e) => this.displayTeam(e, t.uuidv4)} class='mr-2'>
                                                                            <i class='fas fa-users text-muted' /> {t.name}
                                                                        </a>
                                                                    </>)}
                                                                </small>
                                                            </div>} */}

                                                            <Markdown markdown={mu.replaceImages(replyBody, reply.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                                        </span>
                                                    </div>
                                                    <small>
                                                        <button class={`btn btn-sm btn-link py-0 ${hasLikedReply ? 'text-primary' : ''}`}
                                                            onClick={this.likeReply}
                                                            data-comment={comment.id}
                                                            data-id={reply.id}
                                                        >
                                                            <Text id='comments.like'>Lik</Text>
                                                        </button>
                                                        -
                                                        <button class='btn btn-sm btn-link py-0'
                                                            onClick={this.replyComment}
                                                            data-id={comment.id}
                                                        >
                                                            <Text id='comments.comment-add'>Kommentar</Text>
                                                        </button>
                                                        {reply.language !== language && <>
                                                            -
                                                            {!showOriginal[`${comment.id}-${reply.id}`] && replyTranslation ? <>
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-sm btn-link'
                                                                    data-commentid={comment.id}
                                                                    data-replyid={reply.id}
                                                                    onClick={this.showOriginal}
                                                                >
                                                                    <i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text>
                                                                </button>
                                                            </> : <>
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-sm btn-link'
                                                                    onClick={this.translate}
                                                                    data-commentid={comment.id}
                                                                    data-replyid={reply.id}
                                                                >
                                                                    <i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text>
                                                                </button>
                                                            </>}
                                                        </>}
                                                        -
                                                        <button class='btn btn-sm btn-link py-0 disabled'>
                                                            {util.formatDistance(reply.createdDate, new Date(), { locale: 'no-NB' })}
                                                        </button>
                                                    </small>
                                                </div>
                                                {reply.likes && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                                    <i class='fas fa-thumbs-up text-primary' /> {reply.likes.length}
                                                </small>}
                                            </div>
                                        </div>
                                    );
                                })}
                                {totalReplies - diplayedReplies > 0 && <div>
                                    <button class='btn btn-sm btn-link py-0'
                                        onClick={this.toggleReplies}
                                        data-id={comment.id}
                                    >
                                        <Text id='comments.show-more' fields={{ more: totalReplies - diplayedReplies }}>
                                            Vis {totalReplies - diplayedReplies} flere kommentarer...
                                        </Text>
                                    </button>
                                </div>}

                            </div>
                            {showAddComment[comment.id] && <div class='col-11 offset-1 d-flex mb-2 position-relative'>
                                <WorkoutReplyComment stores={this.props.stores} workout={workout}
                                    callback={callback}
                                    commentId={comment.id}
                                    inputRef={c => this.inputRef[comment.id] = c}
                                    inputRefObject={this.inputRef}
                                />
                            </div>}
                        </div>
                    );
                })}
                {totalComments - diplayedComments > 0 && <div>
                    <button class='btn btn-sm btn-link py-0'
                        onClick={this.toggleComments}
                    >
                        <Text id='comments.show-more' fields={{ more: totalComments - diplayedComments }}>
                            Vis {totalComments - diplayedComments} flere kommentarer...
                        </Text>
                    </button>
                </div>}
                {showAllComments && <div>
                    <button class='btn btn-sm btn-link py-0'
                        onClick={this.toggleComments}
                    >
                        <Text id='comments.hide-all-comments'>Skjul alle kommentarene.</Text>
                    </button>
                </div>}
            </div>
        );
    }
}

export default WorkoutComments;
