import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import style from '../style.css';
import mu from '../../lib/musher-util';

@observer
class WorkoutStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async loadAll(props = this.props) {
        // await Promise.all([
        //     this.loadTeams(props),
        //     this.loadWorkoutPlans(props),
        //     this.loadWorkoutSummary(props),
        // ]);
    }

    async loadTeams(props = this.props) {
		const { teamStore } = props.stores;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const { month = currentMonth, year = currentYear, currentTeam } = props;
		await teamStore.load({ query: { team: currentTeam, skipMusherWorkoutSummary: true, year, month }, addData: ['workoutSummary'] });
	}

    loadWorkoutPlans = async (props = this.props) => {
		const { workoutPlanStore } = props.stores;

		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { year = currentYear, month = currentMonth, currentTeam } = props;
		const seasonStart = parseInt(year || (month < 8 ? year - 1 : year), 10);

		if (currentTeam) {
			await workoutPlanStore.load({ query: {
				team: currentTeam,
				seasonYear: seasonStart,
				seasonMonth: 6,
			} });
			const { workoutPlans } = workoutPlanStore;
			const workoutPlanCurrent = workoutPlans[0] || {};
			if (workoutPlanCurrent.id) {
				await workoutPlanStore.load(workoutPlanCurrent.id);
			}
		}
	}

    async loadWorkoutSummary(props = this.props) {
        const { year, currentTeam, dog } = props;
		const { userStore } = props.stores;
        await userStore.getWorkoutSummary({ team: currentTeam, year, dog });
    }

	componentDidMount() {
		this.loadAll();
	}

    componentWillReceiveProps(nextProps) {
        if (nextProps.year !== this.props.year || nextProps.currentTeam !== this.props.currentTeam) {
            this.loadAll(nextProps);
        }
    }

    render() {
		const {
            currentSeason,
            prevSeason,
            currentTeam,
            showHeader = true,
            showAddButton = true,
        } = this.props;

        const { userStore, workoutPlanStore, teamStore } = this.props.stores;
        const { workoutPlan } = workoutPlanStore;
        const { user, workoutSummaryTeam } = userStore;
        const { language = 'en' } = user;
		const MONTHS = mu.getMonths(language);

		const team = teamStore.findTeam(currentTeam) || {};

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const { year = currentYear, month = currentMonth } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);
        const months = util.monthRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        let lastPlanMonth;

        return (
            <div class='container-fluid mb-2'>
                {showHeader && <div class='row mt-2'>
                    <div class='col-12'>
                        <h5>
                            <span style='display: none;'>{workoutPlan.id}</span>
                            <Text id='workout.totals.title'>Treningsplanstatus</Text>
                        </h5>
                    </div>
                </div>}
                <div class='row mt-2 pb-2'>
                    {months && months.map(month => {
                        if (month.year > year || (month.year === currentYear && month.month > currentMonth)) {
                            return '';
                        }
                        const planMonth = workoutPlanStore.sumWorkoutPlanMonth(month.year, month.month);
                        if (!planMonth) {
                            return '';
                        }
                        lastPlanMonth = planMonth;
                        const summaryMonth = userStore.findWorkoutSummaryMonth(month.year, month.month);
                        const percCompleted = summaryMonth / planMonth * 100;
                        const height = percCompleted > 100 ? 100 : util.format(percCompleted, 0);
                        return (
                            <div class='col-2 text-center'>
                                {/* <div class='w-100 mt-1'>
                                    <small class='text-muted'>
                                        {planMonth}km
                                    </small>
                                    <small class='text-info'>
                                        {summaryMonth}km
                                    </small>
                                </div> */}
                                <div class='px-auto w-100 clearfix'>
                                    <div class={`progress ${style['progress-bar-vertical']}`}
                                        style={' margin-left: calc(50% - 20px);'}
                                    >
                                        <div class='progress-bar progress-bar-striped bg-success' role='progressbar'
                                            style={`height: ${height}%; width: 40px;`}
                                            aria-valuenow={height} aria-valuemin='0' aria-valuemax='100'
                                        >
                                            {Math.floor(percCompleted)}%
                                        </div>
                                    </div>
                                </div>
                                <div class='w-100 mt-1'>
                                    {MONTHS[month.month - 1]} {month.year}
                                </div>
                            </div>
                        );
                    })}
                    {!lastPlanMonth && <div class='col-12 text-center text-muted mb-5'>
                        <div class='display-1'>
                            <i class='far fa-sad-tear' />
                        </div>
                        <h3 class='font-weight-lighter'>
                            <Text id='workout.plan.no-workout-plan-found' fields={{ name: team.name }}>Ingen treningsplaner funnet for team {team.name}.</Text>
                        </h3>
                        {showAddButton && <a class='btn btn-success btn-lg mt-3' href='/workouts/plan'><i class='fas fa-calendar-alt' /> <Text id='workout.plan.new'>New workoutplan</Text></a>}
                    </div>}
                </div>

            </div>
        );
    }
}

export default WorkoutStatus;
