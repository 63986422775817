import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class ProfileHeadV2 extends Component {
    onDogClick = (e, dogId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawer2('publicDog', { dogId, height: drawerHeightLarge });
	}

    onWorkoutClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('workoutDetail', {
            id: parseInt(workoutId, 10),
            onLinkClick: this.toggleDrawer,
            onDogClick: this.onDogClick,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: false,
        });
	}

    onUserClick = (e, userid) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onFavoritesClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('profileFavorites', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            drawerLevel: 1,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            backButtonText: 'Profile'
        });
    }

    onPostsClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('publicStoryList', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            drawerLevel: 1,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            backButtonText: 'Profile'
        });
    }

    onFollowersClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowers', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 1,
            backButtonText: 'Profile'
        });
    }

    onFollowingClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowing', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 1,
            backButtonText: 'Profile'
        });
    }

    editTeamField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editTeam').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editTeam', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    editUserField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editUser').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editUser', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    goToUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { url } = e.target.closest('i').dataset;
        route(url);
    }

    render() {
        const { userStore, teamStore, appState, workoutStore, dogStore } = this.props.stores;
        const { darkmode } = appState;
        const team = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
        const { language = 'en' } = user;

        const { id } = this.props;
        const dog = userStore.findDog(parseInt(id, 10));
        const dogImage = mu.getImage({ object: dog });
        const userImage = mu.getImage({ user, fallback: './assets/paw.png' });
        const headerImage = mu.getImage({ team: {}, field: 'imageHeader', size: 's3XXLargeLink', fallback: './assets/BG_mountain_small_profile.png' });

        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = dog.birth ? Math.ceil(mu.daysUntilNextBirthday(dog.birth)) : 365;
        const age = util.age(dog.birth, dog.deceased, { year: 'years', month: 'months', week: 'weeks', day: 'days' });

        const dogVaccineLogStatuses = dogStore.findVaccineLogStatuses(dog.chipId);

        const dogMother = dogStore.findDog(dog.mother);
        const dogFather = dogStore.findDog(dog.father);

        const children = dogStore.findChildren(dog.chipId);
        const siblings = dogStore.findSiblings(dog.id, dog.motherChipId, dog.birth);

        const { vaccineStatuses, historyStatuses, workoutSummary, workoutSummaryCurrentSeason = {}, workoutSummaryPreviousSeason = {}, saved } = dogStore;
        const dogStatus = userStore.findDogstatus({ status: dog.status, language });
        const dogStatuses = dog.statuses ? dog.statuses.map(e => userStore.findDogstatus({ status: e, language })) : [];
        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        // const teamName = userStore.findTeam(dog.team);
        // const teamNames = dog.teams ? dog.teams.map(e => userStore.findTeam(e)) : [];

		// const hasHistory = historyFeed && historyFeed.length > 0;
		const hasHistory = dog.history && dog.history.length > 0;

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'}>
            <div class='d-flex justify-content-center rounded-lg'>
                <div
                    class='w-100 position-relative editTeam d-flex justify-content-center'
                    style={`
                        height: 300px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-color: #a0a0a0; ${dogImage ? `background-image: url("${dogImage}");` : ''}
                        width: 100% !important;
                        border-radius: 10px !important;
                    `}
                    // onClick={this.editTeamField}
                    data-field='imageHeader'
                    data-type='image'
                    data-title='Top image'
                    data-id={team.id}
                />
            </div>
            <div class='mt-2 pb-2 d-flex justify-content-center rounded-lg'>
                <div
                    class='col-12'
                >
                    <div class='d-flex justify-content-between pl-0'>
                        <div class='profileSmallName'>
                            {dog.shortname || dog.name} {dog.shortname && dog.shortname !== '' && <> - <span class='font-weight-lighter'>{dog.name}</span></>}
                            {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                            {hasBirthday && <>
                                <i class='fas fa-birthday-cake ml-3' />
                            </>}
                        </div>
                        <div>
                            <i
                                class='fa-solid fa-pen-to-square text-primary'
                                onClick={this.goToUrl}
                                data-url={`/teams/edit/${team.id}`}
                            />
                        </div>
                    </div>

                    <div class='d-flex justify-content-between'>
                        <div class='text-left'>
                            Age: {dog.birth && <span class='mr-2'>{age}</span>}
                            {daysUntilBirthday < 7 && <>
                                <span class='text-muted mr-2'>
                                    <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                </span>
                            </>}<br />
                            Activities: {dog.totalWorkouts || 0}<br />

                            {dog.chipId && dog.chipId.length > 5 && <><i class='fas fa-microchip' /> {dog.chipId}<br /></>}
                            {dog.weight > 0 && <><i class='fas fa-balance-scale' /> {util.format(dog.weight, 1)} kg<br /></>}
                            {dog.bodyScore > 0 && <><i class='fas fa-dog' /> {util.format(dog.bodyScore, 1)}<br /></>}
                        </div>
                        <div class='text-left ml-1'>
                            <div>
                                {historyStatuses && historyStatuses.filter(e => e.dogId === dog.id).map(v => (
                                    <span class={`badge badge-warning mr-2`} title={util.isoDate(v.endDate, false, false, true)}>{v.title}</span>
                                ))}
                            </div>

                            <div>
                                {dog.specialNeeds && <span class={`badge badge-warning mr-2`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                {dogStatus && dogStatus.class && <span class={`badge badge-${dogStatus.class} mr-2`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                                {dogStatuses && dogStatuses.map(status => <>
                                    {status && status.class && <span class={`badge badge-${status.class} mr-2`}>{status.icon && <i class={status.icon} />} {status.name}</span>}
                                </>)}
                                {dogPosition && dogPosition.class && <span class={`badge badge-${dogPosition.class || 'primary'} mr-2`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>}
                            </div>

                        </div>
                    </div>

                    <div class='d-flex justify-content-between'>
                        {vaccineStatuses && vaccineStatuses.filter(e => e.dogId === dog.id).map(v => (
                            <span class={`badge badge-success mr-2`} title={util.isoDate(v.endDate, false, false, true)}>
                                {v.vaccineType}
                                <span class='font-weight-lighter ml-3'>
                                    <i class='fas fa-calendar-check' /> {util.isoDate(v.endDate, false, false, true)}
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </Widget>);
    }
}

export default ProfileHeadV2;
