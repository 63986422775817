import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import mu from '../../lib/musher-util';

const STOP_STATUSES = [2, 9];
const WARN_STATUSES = [4, 8];


function getFatigueLevel(wo, dog) {
    if (dog) {
        if (STOP_STATUSES.indexOf(dog.status) > -1) {
            return 'danger';
        } else if (WARN_STATUSES.indexOf(dog.status) > -1) {
            return 'warning';
        }
    }
    if (wo) {
        if (wo.distanceKm > 50) {
            return 'danger';
        } else if (wo.distanceKm > 30) {
            return 'warning';
        }
    }
    return 'success';
}

@observer
class Dog extends Component {
    render() {
        const {
            dog = {},
            dogs = [],
            showTeam,
            showInfo,
            showName = true,
            showPosition = true,
            showAge = false,
            className,
            size,
            onDogClick = () => {},
        } = this.props;
		const { userStore, appState } = this.props.stores;
        const { darkmode } = appState;
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;

        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = Math.ceil(mu.daysUntilNextBirthday(dog.birth));

        let containerWidth = '92px';
        let imageClasses = '';
        let fontSize = '35px';
        if (size === 'small') {
            containerWidth = '50px';
            imageClasses = 'imageRoundedSmall';
            fontSize = '20px';
        } else if (size === 'medium') {
            containerWidth = '60px';
            imageClasses = 'imageRoundedMedium';
            fontSize = '25px';
        }

        const dogImage = dog.image?.s3SmallLink;

        return (
            <div class={`mb-2 position-relative ${className} clearfix`} style={`width: ${containerWidth}`}
                onTouchstart={(e) => { e.stopPropagation(); }}
                onTouchend={(e) => { e.stopPropagation(); }}
                onTouchmove={(e) => { e.stopPropagation(); }}
            >

                <div class={`d-flex flex-column rounded-lg position-relative ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    style='
                        border-radius: 10px !important;
                    '
                >
                    <div
                        class='w-100 d-flex justify-content-center'
                        style={`
                            height: 130px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-color: #a0a0a0; ${dogImage ? `background-image: url("${dogImage}");` : ''}
                            width: 100% !important;
                            border-top-left-radius: 10px !important;
                            border-top-right-radius: 10px !important;
                        `}
                    />
                    <div
                        class='w-100 bg-light text-left px-2 py-2'
                        style='
                            border-bottom-left-radius: 10px !important;
                            border-bottom-right-radius: 10px !important;
                        '
                    >
                        <a href={`/dogs/public/${dog.uuidv4}`} onClick={(e) => onDogClick(e, dog.uuidv4, dogs)} class='stretched-link'>
                            {showName && <>
                                <span class='mb-1 font-weight-normal text-dark'>
                                    <nobr>
                                        {dog.gender === 'female' ? <i class='fas fa-venus text-primary mr-1' /> : <i class='fas fa-mars text-primary mr-1' />}

                                        {dog.shortname || dog.name}
                                        {hasBirthday && <>
                                            <i class='fas fa-birthday-cake ml-3' />
                                        </>}
                                    </nobr>
                                </span>
                            </>}
                        </a>
                    </div>
                </div>

            </div>
        );
    }
}

export default Dog;
