import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class TeamPresentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 120,
            actualHeight: 120,
            maxHeight: 120,
            isOverflow: false,
            sessionid: new Date().getTime(),
            key: new Date().getTime(),
        };
        this.descriptionRef = null;
    }

    editUserField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editUser').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editUser', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    checkHeight = (element) => {
        const { maxHeight } = this.state;
        const clientHeight = element?.clientHeight || maxHeight;
        const scrollHeight= element?.scrollHeight || maxHeight;
        if (scrollHeight > clientHeight) {
            this.setState({
                isOverflow: true,
                actualHeight: scrollHeight,
            });
        }
    }

    toggleDescription = (e) => {
        const { isExpanded, maxHeight, actualHeight } = this.state;
        this.setState({
            isExpanded: !isExpanded,
            height: !isExpanded ? actualHeight : maxHeight,
        });
    }

    componentDidMount() {
        this.checkHeight(this.descriptionRef);
    }

    render() {
        const { submenu, height, isOverflow, isExpanded, filterText, sessionid, handleIntersection, key } = this.state;
        const { userStore, appState } = this.props.stores;
        const { darkmode } = appState;
		const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
		const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;

        const { id } = this.props;
        const team = userStore.findTeam(parseInt(id, 10), true);

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} >

            <div
                class='w-100 position-relative p-3'
                style={`
                    height: ${height}px;
                    max-height: ${height}px;
                    overflow-y: hidden;
                    transition: height 0.5 ease-in-out;
                `}
                onClick={this.editUserField}
                data-field='description'
                data-type='textarea'
                data-title='Description'
                data-id={team.id}
                ref={c => this.descriptionRef = c}
            >
                {team.description ? <>
                    <Markdown markdown={mu.replaceImages(team.description, team.images, language)} markedOpts={mu.getMarkdownOptions()} />
                </> : <>
                    <div class='text-center text-muted'>
                        <div class='display-4'><i class='fa-regular fa-align-center' /></div>
                        <h5><Text id='teams.no-description'>No description for your team!</Text></h5>
                    </div>
                </>}
            </div>
            {isOverflow && <>
                {isExpanded ? <>
                    <button
                        class='btn btn-block btn-link'
                        type='button'
                        onClick={this.toggleDescription}
                    >
                        Read less <i class='fas fa-angle-up' />
                    </button>
                </> : <>
                    <button
                        class='btn btn-block btn-link'
                        type='button'
                        onClick={this.toggleDescription}
                    >
                        Read more <i class='fas fa-angle-down' />
                    </button>
                </>}
            </>}
        </Widget>);
    }
}

export default TeamPresentation;
