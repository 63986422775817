import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';
import Calories from '../../components/data/calories';
import Intensity from '../../components/data/intensity';
import Fitness from '../../components/data/fitness';

import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import LineGraph from '../../components/lineGraph';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; // mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);

@withText((props) => ({
    week: <Text id='workout.graph.week' />,
    distance: <Text id='workout.graph.distance' />,
    duration: <Text id='workout.graph.duration'>Duration</Text>,
    rest: <Text id='workout.graph.rest'>Rest</Text>,
    ascend: <Text id='workout.graph.ascend' />,
    plan: <Text id='workout.graph.plan' />,
    speedAvg: <Text id='workout.graph.speedavg'>Speed</Text>,
    loadIndex: <Text id='workout.graph.loadindex'>Loadindex</Text>,

    avgDogs: <Text id='workout.graph.avgdogs'>Avg dogs</Text>,
    avgImages: <Text id='workout.graph.avgimages'>Avg images</Text>,
    sledWeight: <Text id='workout.graph.sledweight'>Sled weight</Text>,
    rating: <Text id='workout.graph.rating'>Rating</Text>,
    temperature: <Text id='workout.graph.temperature'>Temperature</Text>,
}))
@observer
class WorkoutRestDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { userStore, workoutPlanStore, workoutStore, appState } = this.props.stores;
        const {
            user,
			workoutSummary,
			graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
			graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
            avgDayDistance, avgDayElevation,
            avgDayDuration, avgDaySpeedAvg, avgDayLoadIndex,
            avgDayAvgDogs, avgDayAvgImages, avgDaySledWeight,
            avgDayRest, avgDayRating, avgDayTemperature,
		} = userStore;
        const { settings = {} } = user;
        const { graphPlanWeekDistanceCurrent } = workoutPlanStore;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const {
            isLoading,
            week,
            distance,
            duration,
            rest,
            ascend,
            plan,
            speedAvg,
            loadIndex,
            avgDogs,
            avgImages,
            sledWeight,
            rating,
            temperature,
            year = currentYear,
            month = currentMonth,
            showExtra = false,
            showHeader = true,
            showElevation = true,
            scrollToRight = true,
        } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        // const summaryMonth = workoutPlanStore.findWorkoutPlanSummaryMonth(year, monthNum) || {};
        const seasonWeeks = util.weekRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        const seasonDays = util.dayRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);

        const xTicksAll = seasonWeeks.map(wo => wo.week);
        const parts = Math.floor(xTicksAll.length / 5);
        const xTicks = [];
        for (let i = 0, l = xTicksAll.length; i < l; i += parts) {
            xTicks.push(xTicksAll[i]);
        }

        const xTicksAllDays = seasonDays.map(wo => `${wo.day}.${wo.month}`);
        const partsDays = Math.floor(xTicksAllDays.length / 5);
        const xTicksDays = [];
        for (let i = 0, l = xTicksAllDays.length; i < l; i += partsDays) {
            xTicksDays.push(xTicksAllDays[i]);
        }

        const { workout } = workoutStore;
        const { darkmode, isCordova } = appState;

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
				<Localizer>
					<WidgetHead
						stores={this.props.stores}
						title={<Text id='workout.workout-elevation-detail'>Workout Rest</Text>}
						// right={<i class='fa-solid fa-pen-to-square' />}
						// onRightClick={this.onEditClick}
						// right2={<i class='fa-solid fa-upload' />}
						// onRight2Click={this.exportWorkout}
					/>
				</Localizer>
				{/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} p-3`} paddingBottom={0}>
                    <div class='display-4 text-center'>
                        <Duration stores={this.props.stores} value={workout.rest || 0} smallUnit={true} />
                    </div>
                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-duration'>Average duration/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            paddingLeft={50}
                            yTicksLeftWidth={'50px'}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataRight={[avgDayDuration]}
                            legendRight={[`${seasonStart} ${duration}`]}
                            yTicksRightVal={val => `${util.secToHms(val)}`}

                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksRight={1}
                            // yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                            // unitLeft={settings.imperial ? ['mi', 'mi', 'mi'] : ['km', 'km', 'km']}
                            avgWindowRight={[0, 0, 0]}
                            smoothRight={[0, 0, 0]}

                            dataLeft={[avgDayRest]}
                            legendLeft={[`${seasonStart} ${rest}`]}
                            showYTicksLeft={1}
                            yTicksLeftVal={val => `${util.secToHms(val)}`}
                            // yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                            // unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowLeft={[0, 0]}
                            smoothLeft={[0, 0]}

                            // marker={week}
                        />
                    </div>

                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutRestDetail;
