import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

import PublicTeam from '../../components/team/public';

const initialState = {
	confirmed: {},
	deleted: {},
};

@observer
class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    async loadTeams() {
        const { teamStore } = this.props.stores;
        await teamStore.load({ query: {}, addData: ['followRequests'] });
    }

    confirmRequest = (e) => {
        const { team, user, follow } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        teamStore.confirmFollowRequest({
            team: parseInt(team, 10),
            user: parseInt(user, 10),
        });
        const { confirmed } = this.state;
		confirmed[`${team}-${user}`] = true;
        this.setState({ confirmed });

        if (follow) {
            teamStore.followRequestUser(user);
        }
    }

    deleteRequest = (e) => {
        const { team, user } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        teamStore.deleteFollowRequest({
            team: parseInt(team, 10),
            user: parseInt(user, 10),
        });
        const { deleted } = this.state;
		deleted[`${team}-${user}`] = true;
        this.setState({ deleted });
    }

	componentDidMount() {
		this.loadTeams();
	}

	render() {
        const { deleted = {}, confirmed = {} } = this.state;
		const { teamStore, appState } = this.props.stores;
		const { teams, hasFollowRequests, followRequests = [] } = teamStore;
        const { darkmode } = appState;
		if (!hasFollowRequests) {
            return <></>;
		}

		return (
            <div class='row pb-1'>
                <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative rounded rounded-lg py-3 bg-${darkmode ? 'black' : 'white'} shadow-sm`}>
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-12'>
                                <h5><Text id='teams.connect.teams-follow-requests'>Forespørsel om å følge</Text>:</h5>
                            </div>
                        </div>
						<div class='row'>
                            <div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
                                {util.isArray(teams) && teams.map((team) => <>
                                    {team && util.isArray(team.followRequests) && team.followRequests.map((reqUser) => {
                                        const image = reqUser.image && reqUser.image.s3SmallLink ? reqUser.image : null;
                                        const isDeleted = deleted[`${team.id}-${reqUser.id}`];
                                        const isConfirmed = confirmed[`${team.id}-${reqUser.id}`];
                                        const publicTeam = followRequests.find(e => e.user.id === reqUser.id);
                                        return (
                                            <div class='mr-2 py-2 border rounded-lg' style='width: 150px;'>
                                                <div class={`text-center border rounded-circle imageRounded imageRoundedSmall mx-auto`} style={image ? `background-image: url("${image.s3SmallLink}");` : ''}>
                                                    {!image && <div class='text-muted font-weight-lighter' style='font-size: 20px; padding-top: 5px;'>{mu.displayNameShort(reqUser)}</div>}
                                                </div>
                                                <small>
                                                    <div class='text-overflow text-center'>
                                                        <strong>{mu.displayName(reqUser)}</strong><br />
                                                        {/* {publicTeam && publicTeam.public > 0 ? <>
                                                            <a href={`/teams/public/${publicTeam.uuidv4}`}>{publicTeam.name}</a>
                                                        </> : <>
                                                            {publicTeam ? <>{publicTeam.name}</> : ''}<br />
                                                            <span class='text-muted'>(<Text id='teams.not-public'>Not public</Text>)</span>
                                                        </>} */}
                                                    </div>
                                                    <div class='text-overflow text-center'>
                                                        <Text id='teams.connect.wants-to-follow'>wants to follow your team:</Text>
                                                    </div>
                                                    <div class='text-overflow text-center'>
                                                        <strong>{mu.displayName(team)}</strong>
                                                    </div>
                                                </small>
                                                {(isConfirmed || isDeleted) ? <>
                                                    <div class='text-center'>
                                                        {isConfirmed ? <div class='text-success'>
                                                            <Text id='teams.connect.confirmed'>Confirmed</Text> <i class='fas fa-check' />
                                                        </div> : <div class='text-danger'>
                                                            <Text id='teams.connect.deleted'>Deleted</Text> <i class='fas fa-trash-alt' />
                                                        </div>}
                                                    </div>
                                                </> : <>
                                                    <div>
                                                        <button class='btn btn-sm btn-block btn-success text-overflow mt-1' onClick={this.confirmRequest} data-team={team.id} data-user={reqUser.id}>
                                                            <Text id='teams.connect.confirm'>Confirm</Text>
                                                        </button>
                                                        {publicTeam && publicTeam.public > 0 && <button class='btn btn-sm btn-block btn-outline-success text-overflow mt-1' onClick={this.confirmRequest} data-team={team.id} data-user={reqUser.id} data-follow={true}>
                                                            <Text id='teams.connect.confirm-and-follow'>Confirm & follow back</Text>
                                                        </button>}
                                                        <div class='mt-3'>
                                                            <small>
                                                                <button class='btn btn-sm btn-block btn-link text-overflow' style='font-size: 1.0em;' onClick={this.deleteRequest} data-team={team.id} data-user={reqUser.id}>
                                                                    <Text id='teams.connect.delete'>Del</Text>
                                                                </button>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                        );
                                    })}
                                </>)}
                            </div>
                        </div>
                        <details class='mt-3'>
                            <summary><small><Text id='teams.connect.how-summary'>How does this work?</Text></small></summary>
                            <p>
                                <Text id='teams.connect.how-text'>What can followers see when I grant them access to follow? The answer is very little. They can see main details of the workouts you share and name of the dogs. That's all. Try to follow a team and check out the workout tab.</Text>
                            </p>
                        </details>
                    </div>
                </div>
            </div>
		);
	}
}

export default Teams;
