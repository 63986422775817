import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

@observer
class Team extends Component {
    requestFollowTeam = (e) => {
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const teamId = parseInt(e.target.parentElement.dataset.team || e.target.dataset.team, 10);
        teamStore.followRequest(teamId, user);
        this.setState({ requestSent: true });
    }

    unFollowTeam = (e) => {
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const teamId = parseInt(e.target.parentElement.dataset.team || e.target.dataset.team, 10);
        teamStore.unollowTeam(teamId);
        this.setState({ unfollowed: true });
    }

    blockTeam = (e) => {
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const teamId = parseInt(e.target.parentElement.dataset.team || e.target.dataset.team, 10);
        teamStore.blockTeam(teamId);
        this.setState({ unfollowed: true });
    }

    getButton = (text = '') => {
        const { requestSent } = this.state;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { team, skipHome, simple, classNames, submenu } = this.props;
        const isYourTeam = team.members && team.members?.indexOf(user.id) > -1;
        const isFollowing = team.followers && team.followers?.indexOf(user.id) > -1;
        const isBlocked = team.blockedFollowers && team.blockedFollowers?.indexOf(user.id) > -1;
        const hasRequestedFollow = requestSent || team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;

        if (isYourTeam) {
            if (skipHome) {
                return '';
            }
            return (
                <i class='fas fa-home' />
            );
        }
        if (submenu === 'followers') {
            // TODO: Add block/remove follower button.
            return <>
                <button
                    type='button'
                    class={classNames || `btn btn-link btn-sm float-right text-muted`}
                    onClick={this.blockTeam}
                    data-team={team.id}
                >
                    <i class='fa-solid fa-hand mr-2' />
                    <Text id='teams.block'>Block</Text>
                </button>
            </>;
        }
        if (isFollowing) {
            return (<>
                <button
                    type='button'
                    class={classNames || `btn btn-block btn-outline-secondary float-right`}
                    onClick={this.unFollowTeam}
                    disabled='true'
                    data-team={team.id}
                >
                    <i class='fas fa-link mr-2' />
                    <Text id='teams.connect.is-following'>Følger</Text> {!simple && <>{text}</>}
                </button>
                <button
                    type='button'
                    class={classNames || `btn btn-sm btn-block btn-link float-right`}
                    onClick={this.unFollowTeam}
                    data-team={team.id}
                >
                    <i class='fas fa-unlink mr-2' />
                    <Text id='teams.connect.unfollow'>Unfollow</Text>
                </button>
            </>);
        }
        if (isBlocked) {
            return '';
            // return (
            //     <button
            //         class={`btn btn-block btn-outline-secondary float-right`}
            //         disabled='true'
            //     >
            //         <i class='fas fa-hand-paper mr-2' />
            //         <Text id='teams.connect.is-blocked'>Blokkert</Text> {text}
            //     </button>
            // );
        }
        if (hasRequestedFollow) {
            return (
                <button
                    type='button'
                    class={classNames || `btn btn-block btn-outline-secondary float-right`}
                    disabled='true'
                >
                    <i class='fas fa-hourglass-half mr-2' />
                    <Text id='teams.connect.has-requested'>Venter på godkjenning</Text> {!simple && <>{text}</>}
                </button>
            );
        }
        if (team.public) {
            return (
                <button
                    type='button'
                    class={classNames || `btn btn-block btn-outline-success float-right`}
                    onClick={this.requestFollowTeam}
                    data-team={team.id}
                >
                    <i class='fas fa-plus mr-2' />
                    <Text id='teams.connect.follow'>Følg</Text> {!simple && <>{text}</>}
                </button>
            );
        }
    }

    onTeamClick = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        if (drawerLevel === 2) {
            appState.openDrawer2('publicTeam', { teamId, height: drawerHeightMedium, drawerLevel: 3 });
        } else {
            appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium, drawerLevel: 3 });
        }
    }

    render() {
        const { unfollowed } = this.state;
        const { team = {}, buttonOnly } = this.props;
        const { userStore } = this.props.stores;
        const { isAdmin, isVeterinary, user } = userStore;
        const { language = 'en' } = user;
        const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        const teamType = userStore.findTeamType({ type: team.type, language });
        const image = team.image && team.image.s3SmallLink ? team.image : null;

        if (buttonOnly) {
            return (<>
                {this.getButton(`${team.name}`)}
            </>);
        }

        return (
            <div class='w-100 mb-3'>
                <div class='row'>
                    <div class='col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 d-flex mb-0 position-relative border-top pt-3'
                        style={`${unfollowed ? 'text-decoration: line-through; opacity: 0.3;' : ''}`}
                    >
                        {/* {JSON.stringify(team, null, 4)} */}
                        <div
                            class={`text-center border rounded-circle imageRounded`}
                            style={image ? `background-image: url("${image.s3SmallLink}");  background-size: cover;` : ''}
                        >
                            {!image && <i class='fas fa-users text-muted mt-3' style='font-size: 40px;' />}
                        </div>
                        <div class='flex-grow-1 pl-3' style='line-height: 1.2em;'>
                            <h5 class='mb-1' style='line-height: 1.0em;'>
                                {team.public ? <>
                                    <a href={`/teams/public/${team.uuidv4}`} onClick={(e) => this.onTeamClick(e, team.uuidv4)}>
                                        {team.name}
                                    </a>
                                </> : <>
                                    {team.name}
                                </>}
                            </h5>
                            <div>
                                {team.place} {team.country}
                            </div>
                            <div>
                                {teamType && <span class='badge badge-pill badge-secondary'>{teamType}</span>}
                                {teamAmbition && <span class='badge badge-pill badge-secondary ml-2'>{teamAmbition}</span>}
                            </div>
                            <small class='text-muted'>
                                {team && team.dogs && <span class=''><i class='fas fa-dog' /> {team.dogs.length}</span>}
                                {team && team.followers && <span class='ml-2'><i class='fas fa-user' /> {team.followers.length}</span>}
                            </small>
                        </div>
                        <div>
                            {this.getButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
