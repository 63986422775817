import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class ScrollIntoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.container = null;
    }

    componentDidMount() {
		this.container.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

    render() {
        return (
            <div class='col-12 mb-0' ref={c => this.container = c}>&nbsp;</div>
        );
    }
}

export default ScrollIntoView;
