import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(true);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();

import PublicTeam from '../../components/team/public';

const initialState = {
	isLoading: false,
	limit: 25,
	offset: 0,
	submenu: 'followers',
};

@observer
class TeamFollowers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            submenu: props.submenu,
        };
    }

	async loadTeams({ limit, offset, search }) {
		const { submenu } = this.state;
		this.setState({ isLoading: true });
		const { teamStore, userStore } = this.props.stores;
		const opts = {
            skipImages: 1,
        };
        const currentTeam = userStore.getCurrentTeam();

		if (submenu === 'following') {
			opts.following = 1;
		}
        if (submenu === 'followers') {
			opts.followers = 1;
		}
		await teamStore.loadPublic({ limit, offset, search, team: currentTeam.id, opts });
		this.setState({ isLoading: false });
	}

    setLoadMore = () => {
        const { teamStore } = this.props.stores;
        teamStore.setLoadMore(() => {
            let { limit, offset, search } = this.state;
            offset += limit;
            this.setState({ offset });
            this.loadTeams({ limit, offset, search });
        });
    }

	unsetLoadMore = () => {
        const { teamStore } = this.props.stores;
        teamStore.setLoadMore(() => {});
    }

	searchForTeams = () => {
		const { search, limit } = this.state;
		this.setState({
			offset: 0,
		});
		this.loadTeams({ search, limit });
	}

    chooseSubmenu = (e) => {
		const { limit } = this.state;
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({
			submenu,
			offset: 0,
		}, () => {
			this.loadTeams({
				limit,
				offset: 0,
			});
		});
	}

	componentDidMount() {
        const { limit, offset } = this.state;
		this.loadTeams({ limit, offset });
        this.setLoadMore();
	}

    componentWillUnmount() {
        this.unsetLoadMore();
    }

	render() {
        const { scrolledDown, marginBottom, marginTop, drawerLevel } = this.props;
		const { isLoading, submenu } = this.state;
		const { teamStore, userStore, appState } = this.props.stores;
		const { publicTeamsToFollow = [], followers = [] } = teamStore;
		const { user } = userStore;
		const { darkmode } = appState;

		return (
			<div class='container-fluid' style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
				<div class='row'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-3'>
						<div class='row'>
							<div class='w-100 overflow-hidden'>
								<div
									class='d-flex flex-row flex-nowrap pb-2 px-1'
									style='overflow: auto; scroll-snap-type: x mandatory;'
								>
									<div class='col px-0 pt-2'>
										<button
											type='button'
											class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'followers' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='followers'
											onClick={this.chooseSubmenu}
										><i class='fas fa-users' /> <Text id='teams.followers'>Followers</Text></button>
									</div>
									<div class='col px-0 pt-2'>
										<button
											type='button'
											class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'following' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
											style='text-overflow: ellipsis; overflow: hidden;'
											data-menu='following'
											onClick={this.chooseSubmenu}
										><i class='fas fa-link' /> <Text id='teams.following'>Following</Text></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class='row'>
					<div class='col-12'>
						<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
	                        <div class='d-flex flex-row flex-nowrap position-relative' style='overflow: auto;'>
								<div class='flex-grow-1'>
									<label class='sr-only' for='teamSearch'><Text id='search.title'>Search</Text></label>
									<Localizer>
										<input
											type='text'
											class='form-control form-control-lg mb-2'
											id='teamSearch'
											onInput={linkState(this, 'search')}
										/>
									</Localizer>
								</div>
								<div class='align-self-end ml-1'>
									<button
										type='button'
										class='btn btn-lg btn-primary mb-2'
										onClick={this.searchForTeams}
									><Text id='dog.veterinary.search'>Search</Text></button>
								</div>
							</div>
						</div>
						{publicTeamsToFollow && publicTeamsToFollow.map(team => (
							<PublicTeam stores={this.props.stores} team={team} key={`team-id-${team.id}`} drawerLevel={drawerLevel} submenu={submenu} />
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default TeamFollowers;
