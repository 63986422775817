import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkState from 'linkstate';

import Dogs from '../../components/dog/dogs';

import mu from '../../lib/musher-util';

@observer
class AddTags extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    addDogs = ({ field, value, type, elementtype, skipTimer, singleValue }) => {
        // console.log('addDogs', { field, value, type, elementtype, skipTimer, singleValue });
        // {
        //     "field": "dogs",
        //     "value": [
        //       24
        //     ],
        //     "type": "array",
        //     "elementtype": "number",
        //     "skipTimer": true,
        //     "singleValue": 24
        //   }
        const { appState } = this.props.stores;
        const { drawerLevel, object } = this.props;
        const { callback = () => {} } = this.props;
        callback(value);
        // appState.toggleDrawer(false, drawerLevel);
    }

    search = e => {
        const { search = () => {} } = this.props;
        search(e);
    }

    closeDrawer = () => {
        const { appState } = this.props.stores;
        const { drawerLevel } = this.props;
        appState.toggleDrawer(false, drawerLevel);
    }

    render() {
        const { appState, userStore } = this.props.stores;
        const { object } = this.props;
        const { darkmode } = appState;

        const currentTeamObject = userStore.getCurrentTeam();
		const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        return (
            <>
                <div
                    class={`position-absolute text-center w-100 appHeader ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    style={`
                        top: 24px;
                        left: 0px;
                    `}
                >
                    <Text id='doglog.dogs'>Dogs</Text>
                </div>
                <div class={`w-100 container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style='margin-top: 75px;'>
                    <div class='row mb-5'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <div
                                class=' rounded rounded-lg position-relative bg-light d-flex flex-row justify-content-center align-items-center pl-3'
                                style=' border-radius: 10px !important;'
                            >
                                <i class='fa-regular fa-magnifying-glass text-muted' />
                                <Localizer>
                                    <input
                                        class='form-control-plaintext px-2'
                                        type='text'
                                        placeholder={<Text id='doglog.search-dogs'>Search for dogs</Text>}
                                        onInput={this.search}
                                        value={object.search}
                                    />
                                </Localizer>
                            </div>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-5'>
                            <Localizer>
                                <Dogs
                                    stores={this.props.stores}
                                    object={object}
                                    type='array'
                                    elementtype='number'
                                    field='dogs'
                                    icon='fas fa-dog'
                                    title={<Text id='doglog.dogs'>Dogs</Text>}
                                    help={<Text id='doglog.dogs-help'>Select dogs</Text>}
                                    updateField={this.addDogs}
                                    search={object.search}
                                    // filter={e => dog.id === e.id || object.dogs?.indexOf(e.id) > -1}
                                    // filter={e => object.dogs?.indexOf(e.id) > -1}
                                    // filter={e => e.name.match(new RegExp(search, 'i'))}
                                    // filterText={search}
                                    currentTeam={currentTeam}
                                />
                            </Localizer>
                        </div>
                        {/* <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-5'>
                            <div class='row border-bottom pb-2'>
                                <div
                                    class='col-12 mt-2'
                                    onClick={this.addTag}
                                    data-value='Fnugg'
                                >
                                    <span class='header3'>Fnugg</span><br />
                                    <small class='text-muted'>Dog</small>
                                </div>
                            </div>
                            <div class='row border-bottom pb-2'>
                                <div
                                    class='col-12 mt-2'
                                    onClick={this.addTag}
                                    data-value='Blitz'
                                >
                                    <span class='header3'>Blitz</span><br />
                                    <small class='text-muted'>Dog</small>
                                </div>
                            </div>
                        </div> */}
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <button type='button' class='btn btn-primary btn-block' onClick={this.closeDrawer}>
                                <Text id='doglog.apply'>Apply</Text>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddTags;
