import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import LineGraph from '../../components/lineGraph';

@observer
class MiniViewGraph extends Component {
    render() {
        const {
            legendLeft = [],
            legendRight = [],
            dataLeft = [],
            dataRight = [],
            showDebug,
            xTicksVal = val => util.isoDate(val, false, false, true),
            xTicksCnt = 5,
            yTicksLeftVal = val => val,
            yTicksRightVal = val => val,
        } = this.props;

        const firstData = dataLeft && dataLeft[0] ? dataLeft[0] : [];
        const xTicks = [];
        if (firstData.length) {
            const partSize = Math.ceil(firstData.length / (xTicksCnt - 1));
            const parts = Math.floor(firstData.length / partSize);
            if (showDebug) {
                console.log({ firstData, parts, partSize });
            }
            for (let i = 0, l = firstData.length; i < l; i += partSize) {
                xTicks.push(firstData[i].epoch);
            }
            xTicks.push(firstData[firstData.length - 1].epoch);
        }
        return (
            <>
                <div class='w-100 mt-3 mb-4'>
                    {/* <xmp>graphWorkoutSummaryWeekDistanceCurrent: {JSON.stringify(graphWorkoutSummaryWeekDistanceCurrent, null, 4)}</xmp>
                    <xmp>graphWorkoutSummaryWeekDistancePrev: {JSON.stringify(graphWorkoutSummaryWeekDistancePrev, null, 4)}</xmp> */}
                    <LineGraph
                        stores={this.props.stores}
                        width={1000}
                        height={300}
                        showXTicks={1}
                        xTicksVal={xTicksVal}
                        xTicks={xTicks}
                        paddingTop={30}
                        lineFromXzero={false}
                        lineFromXmax={false}
                        // xTicksLink={'/workouts/week/'}

                        dataLeft={dataLeft}
                        legendLeft={legendLeft}
                        // yMaxLeft={30}
                        // yMinLeft={0}
                        showYTicksLeft={1}
                        // yTicksLeftPostfix={'km'}
                        // unitLeft={['km', 'km', 'km']}
                        yTicksLeftVal={yTicksLeftVal}
                        avgWindowLeft={[0, 0, 0, 0, 0, 0, 0]}
                        smoothLeft={[0, 0, 0, 0, 0, 0, 0]}


                        dataRight={dataRight}
                        legendRight={legendRight}
                        // dataRight={[graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev]}
                        // legendRight={[`${seasonStart} ${ascend}`, `${seasonStart - 1} ${ascend}`]}
                        showYTicksRight={dataRight.length > 0 ? 1 : 0}
                        // yTicksRightPostfix={'m'}
                        // unitRight={['m', 'm']}
                        yTicksRightVal={yTicksRightVal}
                        avgWindowRight={[0, 0, 0, 0, 0, 0, 0]}
                        smoothRight={[0, 0, 0, 0, 0, 0, 0]}

                        scrollToRight={true}
                        // marker={week}
                    />
                </div>

            </>
        );
    }
}

export default MiniViewGraph;
