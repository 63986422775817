import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route, Router } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import PubSub, { topics } from '../../lib/pubsub';
import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import ImageScroller from '../../components/imageScroller';
import MiniViewGraph from '../../components/dog/miniViewGraph';

import ScrollIntoView from '../../components/util/scrollIntoView';

const initialState = {
    newTeam: {},
    submenu: 'presentation',
};

@observer
class DogsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.updateTimer = {};
        this.time = new Date().getTime();
        this.apiServer = util.getApiServer();
    }

    async loadPublicView(props) {
		this.setState({ isLoading: true });
        const { dogid } = props;
		const { dogStore } = this.props.stores;
        await dogStore.getPublicViews(dogid);
		this.setState({ isLoading: false });
	}

    async loadAll(props = this.props) {
        await this.loadPublicDog(props);
        await this.loadPublicView(props);
    }

    async loadPublicDog(props = this.props) {
		const { dogStore } = props.stores;
        const { dogid, dogs = [] } = props;
        await dogStore.loadPublicDetail({ id: dogid });

		const { publicDog } = dogStore;
        const currentDogIdx = dogs?.findIndex(e => e.id === publicDog.id);
        let prevDogIdx = null;
        let nextDogIdx = null;
        let prevDogName = null;
        let nextDogName = null;
        let hasPrevDog = false;
        let hasNextDog = false;
        if (currentDogIdx > 0) {
            prevDogIdx = currentDogIdx - 1;
            prevDogName = dogs[prevDogIdx].shortname || dogs[prevDogIdx].name;
            hasPrevDog = true;
        }
        if (currentDogIdx < dogs.length - 1) {
            nextDogIdx = currentDogIdx + 1;
            nextDogName = dogs[nextDogIdx].shortname || dogs[nextDogIdx].name;
            hasNextDog = true;
        }
        this.setState({
            prevDogIdx,
            prevDogName,
            nextDogIdx,
            nextDogName,
            hasPrevDog,
            hasNextDog,
            currentDogIdx,
        });
        // console.log({ prevDogIdx, nextDogIdx, hasPrevDog, hasNextDog, currentDogIdx });
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    nextDog = () => {
        const { nextDogIdx } = this.state;
        const { dogs = [], stores } = this.props;

        const nextDog = dogs[nextDogIdx];
        if (nextDog && nextDog.uuidv4) {
            this.loadPublicDog({
                stores,
                dogs,
                dogid: nextDog.uuidv4,
            });
        }
    }

    prevDog = () => {
        const { prevDogIdx } = this.state;
        const { dogs = [], stores } = this.props;

        const prevDog = dogs[prevDogIdx];
        if (prevDog && prevDog.uuidv4) {
            this.loadPublicDog({
                stores,
                dogs,
                dogid: prevDog.uuidv4,
            });
        }
    }

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

    toggleDrawer = (e) => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    editDogField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer3('editDog', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    favoriteDog = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value, isfavorite } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (isfavorite) {
            await userStore.removeField({ email: user.email, field: 'favoriteDog', value: parseInt(value, 10) });
        } else {
            await userStore.updateUserArray({ email: user.email, field: 'favoriteDog', value: parseInt(value, 10) });
        }
    }

    onTeamClick = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer3('publicTeam', { teamId, height: drawerHeightMedium });
	}

	componentDidMount() {
        const { back = this.back } = this.props;
        const { appState } = this.props.stores;
        appState.setSwipeRight(() => {
            appState.setSwipeRight();
            back();
        });
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.dogid !== this.props.dogid) {
            this.loadAll(nextProps);
        }
    }

	render() {
        const { hasPrevDog, hasNextDog, prevDogName, nextDogName, submenu } = this.state;
		const { teamStore, dogStore, userStore, appState } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { darkmode } = appState;
        const { language = 'en' } = user;
		const { publicTeam: teamPublicTeam } = teamStore;
		const { publicDog, publicTeam: dogPublicTeam = {}, publicViewsGraphData, publicViewsGraphLegend } = dogStore;
        const isTeamAdmin = (dogPublicTeam && dogPublicTeam.members) ? dogPublicTeam.members.some(m => m.id === user.id) : false;

        // const publicTeam = dogPublicTeam || teamPublicTeam;
        const publicTeam = dogPublicTeam || {};
        const { marginTop, marginBottom, back = this.back, dogs, showBackButton = true } = this.props;
        const defaultImage = {
            s3XXLargeLink: './assets/homerun-race-logo.png',
        };

        const {
            users = [],
            imageHeader,
        } = publicTeam;

        const {
            publicWorkouts,
            publicWorkoutDetails,
            publicWorkoutSummary,
            publicTrophys,
            publicMemories,
            publicDogs,
        } = teamPublicTeam;

        const { image } = publicDog;

        const headerImage = imageHeader && imageHeader.s3XXLargeLink ? imageHeader : defaultImage;
        const teamImage = image && image.s3LargeLink ? image : null;

        const dogPosition = userStore.findDogposition({ position: publicDog.position, language });
        const hasBirthday = mu.hasBirthday(publicDog.birth);
        const daysUntilBirthday = Math.ceil(mu.daysUntilNextBirthday(publicDog.birth));

		return (<>
			<div
                class={`container-fluid pb-5 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}
            >
                {showBackButton && <button
                    type='button'
                    class='btn btn-link pl-0 fixed-top text-primary'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 2.0em; width: 43px;`}
                    onClick={back}>
                    <i class='fas fa-arrow-circle-left' />
                </button>}
                <div class='row mb-0'>
                    <div class='w-100 position-relative'
                        style={`height: 25vh; background-size: cover; background-repeat: no-repeat; background-position: 50% 50%; background-color: #a0a0a0; ${headerImage ? `background-image: url("${headerImage.s3XXLargeLink}");` : ''}`}
                    >
                        {isTeamAdmin && <>
                            <div class='position-absolute' style={`top: 5px; left: 10px;`}>
                                <a class='btn btn-sm btn-primary'
                                    href={`/dogs/edit/${publicDog.id}`}
                                    onClick={this.toggleDrawer}
                                >
                                    <i class='fas fa-edit' /> <Text id='teams.edit'>Endre</Text>
                                </a>
                                <a class='btn btn-sm btn-primary ml-2'
                                    href={`/dogs/${publicDog.id}`}
                                    onClick={this.toggleDrawer}
                                >
                                    <i class='fas fa-dog' /> <Text id='dogs.detail'>Detail</Text>
                                </a>
                            </div>
                        </>}
                    </div>
					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                        <div class='row'>
                            <div class='col-3 col-sm-4 col-md-3 col-lg-4 position-relative'>
                                <div
                                    class={`text-center border border-white rounded-circle position-absolute imageRoundedXLarge imageRoundedResponsive imageRoundedResponsivePosition`}
                                    style={`left: 20px; ${teamImage ? `background-image: url("${teamImage.s3LargeLink}"); background-size: cover; background-repeat: no-repeat; background-position: 50% 50%;` : ''}`}
                                >
                                    {!teamImage && <i class='fas fa-users text-muted mt-3' style='font-size: 40px;' />}
                                </div>
                                &nbsp;
                                {isTeamAdmin && <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-secondary position-absolute'
                                        style={`bottom: 0px; left: 20px; opacity: 0.8; border-radius: 50%;`}
                                        onClick={this.editDogField}
                                        data-field='image'
                                        data-type='image'
                                        data-title='Profile image of the dog'
                                        data-id={publicDog.id}
                                        data-height='60vh'
                                    >
                                        <i class='fa-solid fa-camera ' />
                                    </button>
                                </>}
                            </div>
                            <div class='col-9 col-sm-8 col-md-9 col-lg-8' style='line-height: 1.2em;'>
                                <div class='d-flex justify-content-between'>
                                    <div>
                                        <small class='font-weight-light'>
                                            {publicTeam.public ? <>
                                                <a
                                                    href={`/teams/public/${publicTeam.uuidv4}`}
                                                    onClick={e => this.onTeamClick(e, publicTeam.uuidv4)}
                                                >{publicTeam.name}</a>
                                            </> : <>
                                                {publicTeam.name}
                                            </>}
                                        </small>
                                    </div>
                                </div>
                                <div class='d-flex justify-content-between'>
                                    <div>
                                        <h5 class='my-0'>
                                            {publicDog.shortname || publicDog.name}
                                            {hasBirthday && <>
                                                <i class='fas fa-birthday-cake ml-3' />
                                            </>}
                                        </h5>
                                        {publicDog.shortname && <div>
                                            {publicDog.name}
                                        </div>}
                                    </div>
                                    <div>
                                        {publicDog.birth && <>
                                            <h5 class='float-right'>
                                                {daysUntilBirthday < 7 && <>
                                                    <span class='text-muted mr-2'>
                                                        <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                                    </span>
                                                </>}
                                                {publicDog.birth && <span class='mr-2'>{util.age(publicDog.birth, publicDog.deceased)}</span>}
                                                {publicDog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                                            </h5>
                                        </>}
                                    </div>
                                    <div class='ml-2'>
                                        <i
                                            class={`fa-${user.favoriteDog?.indexOf(publicDog.id) > -1 ? 'solid' : 'regular'} fa-star`}
                                            style='font-size: 1.1em;'
                                            onClick={this.favoriteDog}
                                            data-value={publicDog.id}
                                            data-isfavorite={user.favoriteDog?.indexOf(publicDog.id) > -1}
                                        />
                                    </div>
                                </div>

                                {dogPosition && dogPosition.class && <>
                                    <span class={`badge badge-${dogPosition.class || 'primary'} mt-2`}>
                                        {dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}
                                    </span>
                                </>}
                                {publicDog.weight > 0 && <small class='text-muted'>
                                    {publicDog.weight && <><i class='fas fa-balance-scale ml-2' /> {util.format(publicDog.weight, 1)} kg</>}
                                </small>}
                            </div>
                        </div>
                    </div>
                </div>


                <div class='row mt-2'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
						<div class='row'>
                            <div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-0 px-1'
                                    style='overflow: auto; scroll-snap-type: x mandatory; line-height: 1.0em; height: 56px;'
                                >
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'presentation' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='presentation'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-chalkboard-teacher' /> <Text id='teams.presentation'>Presentation</Text></button>
                                        {/* {submenu === 'presentation' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='pictures'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-images' /> <Text id='teams.pictures'>Pictures</Text></button>
                                        {/* {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    {publicTrophys && <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'trophy' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='trophy'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-award' /> <Text id='teams.trophy'>Trophy case</Text></button>
                                        {/* {submenu === 'trophy' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                    {publicMemories && <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'achievements' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='achievements'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-clock' /> <Text id='teams.achievements'>Memories</Text></button>
                                        {/* {submenu === 'achievements' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                </div>
                            </div>
						</div>
					</div>
                </div>

                {submenu === 'presentation' && <>
                    {!publicDog.description && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fa-solid fa-align-center' /></div>
                            <h3><Text id='dogs.no-description'>No description for this dog!</Text></h3>
                        </div>
                    </>}
                    <div class='row mt-1'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <Markdown markdown={mu.replaceImages(publicDog.description, publicDog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                        </div>
                    </div>

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editDogField}
                            data-field='description'
                            data-type='textarea'
                            data-title='Description'
                            data-id={publicDog.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-pen text-white' />
                        </button>
                    </>}
                </>}

                {submenu === 'pictures' && <>
                    {(!publicDog.images || publicDog.images?.length === 0) && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                            <div class='text-center text-muted'>
                                <div class='display-1'><i class='fa-solid fa-camera' /></div>
                                <h3><Text id='dogs.no-images-title'>No images for this dog!</Text></h3>
                            </div>
                        </div>
                    </>}
                    {publicDog.images && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative px-0`}>
                            <ImageScroller stores={this.props.stores} images={publicDog.images.reverse()} />
                        </div>
                    </div>}

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editDogField}
                            data-field='images'
                            data-type='image'
                            data-title='Images of the dog'
                            data-id={publicDog.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-camera text-white' />
                        </button>
                    </>}
                </>}

                {submenu === 'trophy' && <>
                    {(!publicDog.trophys || publicDog.trophys?.length === 0) && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fas fa-award' /></div>
                            <h3><Text id='dogs.no-trophys-title'>No trophys for this dog!</Text></h3>
                        </div>
                    </>}
                    {publicDog.trophys && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                            <div class='row'>
                                {publicDog.trophys && publicDog.trophys.reverse().map(file => {
                                    const img = mu.getTrophyImage(file.name);
                                    if (!img) {
                                        return '';
                                    }
                                    const textHeader = file.descriptionHeader && file.descriptionHeader[language] ? file.descriptionHeader[language] : '';
                                    const text = file.description && file.description[language] ? file.description[language] : '';
                                    return (
                                        <div class='col-4 mt-3'>
                                            <div class='mb-2'>
                                                <img src={img} class='img-fluid' />
                                            </div>
                                            <strong>{textHeader}</strong>
                                            {/* <small class='font-weight-lighter mr-2'>
                                                {util.isoDate(file.createdDate, false, false, true)}
                                            </small> */}
                                            <small>
                                                <details>
                                                    <summary><Text id='dogs.trophy-details'>Details</Text></summary>
                                                    <Markdown markdown={mu.replaceImages(text, publicDog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                </details>
                                            </small>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                </>}

                {submenu === 'achievements' && <>
                    {(!publicDog.achievements || publicDog.achievements?.length === 0) && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fas fa-clock' /></div>
                            <h3><Text id='dogs.no-achievements-title'>No memories for this dog!</Text></h3>
                        </div>
                    </>}
                    {publicDog.achievements && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                            <div class='row'>
                                {publicDog.achievements && publicDog.achievements.map(file => {
                                    return (
                                        <div class='col-12'>
                                            {file.date && <span class='font-weight-lighter mr-2'>{util.isoDate(file.date, false, false, true)}</span>}
                                            <span class='mr-2 font-weight-bold'>{file.name}</span>
                                            <span class='mr-2 font-weight-light'>{file.description}</span>
                                            {file.url && <span class='mr-2'><a href={mu.asUrl(file.url)} target='_blank' rel='noreferrer' native><i class='fas fa-link' /></a></span>}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                </>}

                {(isAdmin || isTeamAdmin) && <>
                    <div class='row bg-light py-5 mt-3'>
                        <h5 class='font-weight-lighter text-muted border-bottom mt-5'><Text id='dogs.visits'>Dog page visits</Text></h5>
                        <MiniViewGraph stores={this.props.stores}
                            dataLeft={publicViewsGraphData}
                            legendLeft={['dog']}
                            key={`minigraph-dog-${publicDog.uuidv4}`}
                        />
                    </div>
                </>}

                {hasNextDog && <span class='fixed-bottom text-primary' style='bottom: 60px; left: auto; right: 12px; width: 43px; font-size: 3.0em; z-index: 10;'>
                    <i class='fas fa-arrow-circle-right' onClick={this.nextDog} />
                </span>}
                {hasPrevDog && <span class='fixed-bottom text-primary' style='bottom: 60px; left: 5px; width: 43px; font-size: 3.0em; z-index: 10;'>
                    <i class='fas fa-arrow-circle-left' onClick={this.prevDog} />
                </span>}

                {publicDog.uuidv4 && <img src={`${this.apiServer}/api/dogs/${publicDog.uuidv4}/view?user=${user.id}&time=${this.time}`} style='width: 1; height: 1; opacity: 0.1;' />}
			</div>
		</>);
	}
}

export default DogsDetail;
