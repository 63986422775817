import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import mu from '../../lib/musher-util';

const initialState = {
    likeMessage: {},
};

@observer
class InboxUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    addLike = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const messageId = e.target.closest('.inboxBubble').dataset.id;
		const { messageStore } = this.props.stores;

        const { likeMessage } = this.state;
        likeMessage[messageId] = true;
        this.setState({ likeMessage });
        setTimeout(() => {
            const { likeMessage } = this.state;
            delete likeMessage[messageId];
            this.setState({ likeMessage });
        }, 1200);

		await messageStore.likeMessage({ messageId });
        return true;
    }

	render() {
        const { likeMessage } = this.state;
        const { message = {}, messageUser } = this.props;
        const { userStore } = this.props.stores;
		const { user } = userStore;
        const { language = 'en' } = user;
        const isMyMessage = user.uuidv4 === message.fromUser;

        const shortname = mu.displayNameShort(messageUser);
        const userImage = messageUser.image || (messageUser.images ? messageUser.images[0] : undefined);
        const images = message.images || [];

		return (
            <div class='position-relative'>
                <div class={`d-flex ${isMyMessage ? 'justify-content-end' : 'justify-content-start'}`}>
                    <div class='d-flex align-items-start flex-column overflow-auto' style='max-width: 80%;'>

                            {images && images.length > 0 && <>
                                <div class={`d-flex flex-column`} style={`${isMyMessage ? '' : 'margin-left: 29px;'}`}>
                                    {util.isArray(images) && images.map((img, idx) => {
                                        return (<>
                                            <div
                                                class={`px-0 overflow-hidden rounded-lg`}
                                                style={`max-height: 50vh;`}
                                            >
                                                <img loading='lazy' class={`img-fluid border rounded-lg`} src={img.s3MediumLink} onClick={this.viewImage} data-id={message.uuidv4} data-idx={idx} />
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </>}

                        <div class={`d-flex flex-row flex-nowrap position-relative ${isMyMessage ? 'align-self-end' : ''}`}>
                            {!isMyMessage && <div
                                class={`text-center text-muted border rounded-circle imageRounded imageRoundedThumb pt-0 mr-1`}
                                style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important; line-height: 15px;`}
                            >
                                {!userImage && <span class='font-weight-lighter' style='font-size: 15px;'>{shortname}</span>}
                            </div>}

                            <div class='d-flex flex-column'>
                                <div
                                    class={`inboxBubble py-2 px-3 rounded-lg position-relative ${isMyMessage ? 'bg-primary text-white' : 'bg-secondary text-white'} overflow-hidden`}
                                    style='overflow: auto;'
                                    data-id={message.uuidv4}
                                    onDblclick={isMyMessage ? null : this.addLike}
                                >
                                    <Markdown markdown={mu.replaceImages(message.body, message.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                    {likeMessage[message.uuidv4] && <span class='likeHeart position-absolute text-white display-1' style='top: calc(50% - 50px); left: calc(50% - 50px); width: 100px; height: 100px;'>
                                        <i class='fas fa-heart likeStory' />
                                    </span>}
                                </div>

                                <div class={`flex-fill position-relative ${isMyMessage ? 'align-self-end mr-2' : 'align-self-end mr-2'}`}>
                                    {message.likes && message.likes.length > 0 && <>
                                        <div
                                            class={`position-absolute text-center`}
                                            style={`line-height: 10px; bottom: 18px; right: ${isMyMessage ? '12px' : '0px'};`}
                                        >
                                            <i class='fas fa-thumbs-up text-warning' />
                                        </div>
                                    </>}
                                    {(isMyMessage && message.readDate) && <>
                                        <div
                                            class={`position-absolute text-center text-muted border rounded-circle imageRounded imageRoundedIcon pt-0 bg-secondary`}
                                            style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 1px !important; line-height: 11px; bottom: 18px; right: -8px;`}
                                        >
                                            {!userImage && <span class='font-weight-lighter text-white' style='font-size: 10px;'>{shortname}</span>}
                                        </div>
                                        {/* <small class='text-muted'><Text id='inbox.read'>Read</Text>: {util.formatDistance(message.readDate, new Date(), { locale: 'no-NB' })}</small> */}
                                    </>}
                                    <small class='text-muted ml-2'>
                                        {util.formatDistance(message.sentDate, new Date(), { locale: 'no-NB' })}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

export default InboxUser;
