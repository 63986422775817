import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';

import MiniGraph from '../../components/workout/miniGraph';

import WorkoutTotals from '../../components/workout/totals';
import WorkoutTypeHeader from '../../components/workout/typeHeader';

import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const WEEKDAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

@observer
class Week extends Component {
    onWorkoutClick = e => {
        console.log('onWorkoutClick', e);
		e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
		const { id } = e.target.closest('.workoutBubble').dataset;

        appState.openDrawer2('workoutDetail', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            id: parseInt(id, 10),
        });
    }

    newWorkout = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightMedium } = appState;
        const { date } = e.target.closest('.workoutBubble').dataset;
        appState.openDrawer2('newWorkout', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            team: user.activeTeam?.team || user.team,
            date,
        });
	}

    render() {
        const {
            sumWeek,
            week,
            firstDayInWeek,
            lastDayInWeek,
            workoutsInWeek,
            workoutDistanceCurrentSeasonMiddle,
            workoutDurationCurrentSeasonMiddle,

        } = this.props;
        return (<>
            <Widget stores={this.props.stores}>
                <div class=''>
                    <small class='float-right'>
                        <Distance stores={this.props.stores} value={sumWeek} smallUnit={false} showUnit={true} />
                    </small>
                    <small class='float-left'>
                        w{week.week} {week.year}:
                        <span class='text-muted ml-2'>
                            {mu.formatDate(firstDayInWeek, { day: 'numeric', month: 'numeric' })} - {mu.formatDate(lastDayInWeek, { day: 'numeric', month: 'numeric' })}
                        </span>
                    </small>
                    <br />
                    <div class='d-flex justify-content-between '>
                        {/* <xmp>{JSON.stringify(workoutCurrentWeek, null, 2)}</xmp> */}
                        {workoutsInWeek && workoutsInWeek.length > 0 && workoutsInWeek.map((day, idx) => {
                            const size = day.distanceKm / workoutDistanceCurrentSeasonMiddle;
                            let pixelSize = Math.floor(40 * size);
                            pixelSize = pixelSize < 25 ? 25 : pixelSize;
                            pixelSize = pixelSize > 55 ? 55 : pixelSize;

                            const dampingFactor = 0.8;
                            let sizeDuration = day.duration / workoutDurationCurrentSeasonMiddle;
                            if (sizeDuration > 1) {
                                sizeDuration = sizeDuration * dampingFactor;
                            } else {
                                sizeDuration = sizeDuration / dampingFactor;
                            }
                            let pixelSizeDuration = Math.floor(20 * sizeDuration);
                            pixelSizeDuration = pixelSizeDuration < 10 ? 10 : pixelSizeDuration;
                            pixelSizeDuration = pixelSizeDuration > 55 ? 55 : pixelSizeDuration;
                            return (<>
                                <div class='' style='width: 14.2%'>
                                    <div class='d-flex justify-content-center align-items-center' style='height: 60px;'>
                                        {day.distanceKm > 0 ? <>
                                            <div
                                                class='text-white bg-primary d-flex align-items-center justify-content-center workoutBubble'
                                                style={`width: ${pixelSize}px; height: ${pixelSize}px; border-radius: 50%;`}
                                                data-id={day.id}
                                                onClick={this.onWorkoutClick}
                                            >
                                                <small>
                                                    <Distance stores={this.props.stores} value={day.distanceKm} smallUnit={false} showUnit={false} />
                                                </small>
                                            </div>
                                        </> : <>
                                            {day.duration > 0 ? <>
                                                <div
                                                    class='text-white bg-success d-flex align-items-center justify-content-center workoutBubble'
                                                    style={`width: ${pixelSizeDuration}px; height: ${pixelSizeDuration}px; border-radius: 50%;`}
                                                    data-id={day.id}
                                                    onClick={this.onWorkoutClick}
                                                >
                                                    {/* <small>
                                                        <Duration stores={this.props.stores} value={day.duration} smallUnit={false} showUnit={false} />
                                                    </small> */}
                                                    &nbsp;
                                                </div>
                                            </> : <>
                                                <div
                                                    class='workoutBubble'
                                                    onClick={this.newWorkout}
                                                    data-date={`${day.year}-${util.padDate(day.month)}-${util.padDate(day.day)}`}
                                                >·</div>
                                            </>}
                                        </>}

                                    </div>
                                    <div class='text-muted text-center'>
                                        <small>
                                            {WEEKDAYS[idx]}
                                        </small>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
            </Widget>
        </>);
    }
}

@observer
class WorkoutTrainingLog extends Component {


    toggleDrawer = (e) => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const {
            showHeader = true,
        } = this.props;

        const { userStore, workoutPlanStore } = this.props.stores;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;

        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm,
			elevation: weekElevation,
			duration: weekDuration,
			speedAvg: weekSpeedAvg,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
            workoutCurrentSeason,
            workoutPrevSeason,
            seasonDaysSoFar,
            seasonWeeksSoFar,
            prevSeasonWeeksSoFar,
            workoutSummaryDaysCurrentMonth,
            workoutSummaryDaysCurrentMonthPrevSeason,
            graphworkoutSummaryDaysCurrentMonth,
            graphworkoutSummaryDaysCurrentMonthPrevSeason,
		} = userStore;

		const workoutCurrentSeasonMax = workoutCurrentSeason.reduce((max, workout) => Math.max(max, workout.distanceKm), 0);
		const workoutCurrentSeasonMedian = mu.median(workoutCurrentSeason.map(w => w.distanceKm));
		const workoutDistanceCurrentSeasonMiddle = mu.avgOfValues(workoutCurrentSeason.map(w => w.distanceKm));
		const workoutDurationCurrentSeasonMiddle = mu.avgOfValues(workoutCurrentSeason.map(w => w.duration)) || 1;

        const workoutPrevSeasonMax = workoutPrevSeason.reduce((max, workout) => Math.max(max, workout.distanceKm), 0);
		const workoutPrevSeasonMedian = mu.median(workoutPrevSeason.map(w => w.distanceKm));
		const workoutDistancePrevSeasonMiddle = mu.avgOfValues(workoutPrevSeason.map(w => w.distanceKm));
		const workoutDurationPrevSeasonMiddle = mu.avgOfValues(workoutPrevSeason.map(w => w.duration)) || 1;

        const workoutSummaryMonth = userStore.findWorkoutSummaryMonth(currentYear, currentMonth, 'all') || {};

        const planMonthDistanceKm = workoutPlanStore.sumWorkoutPlanMonth(currentYear, currentMonth, 'distance');
        const planMonthCount = workoutPlanStore.sumWorkoutPlanMonth(currentYear, currentMonth, 'count');

        const { graphPlanDaysDistanceCurrent } = workoutPlanStore;

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.training-log'>Training log</Text>
                        </h3>
                    </div>}

                    <WorkoutTypeHeader stores={this.props.stores} />

                    {seasonWeeksSoFar && seasonWeeksSoFar.length > 0 && seasonWeeksSoFar.reverse().map(week => {
                        const daysInWeek = util.getDaysInWeek(week.week, week.year, week.month);
                        const firstDayInWeek = daysInWeek[0];
                        const lastDayInWeek = daysInWeek[daysInWeek.length - 1];
                        const workoutsInWeek = userStore.getWorkoutsInWeek(week.year, week.week, week.month);
                        const sumWeek = workoutsInWeek.reduce((sum, workout) => sum + workout.distanceKm, 0);
                        return (<>
                            <Week
                                stores={this.props.stores}
                                sumWeek={sumWeek}
                                week={week}
                                firstDayInWeek={firstDayInWeek}
                                lastDayInWeek={lastDayInWeek}
                                workoutsInWeek={workoutsInWeek}
                                workoutDistanceCurrentSeasonMiddle={workoutDistanceCurrentSeasonMiddle}
                                workoutDurationCurrentSeasonMiddle={workoutDurationCurrentSeasonMiddle}
                            />
                        </>);
                    })}
                    {prevSeasonWeeksSoFar && prevSeasonWeeksSoFar.length > 0 && prevSeasonWeeksSoFar.reverse().map(week => {
                        const daysInWeek = util.getDaysInWeek(week.week, week.year, week.month);
                        const firstDayInWeek = daysInWeek[0];
                        const lastDayInWeek = daysInWeek[daysInWeek.length - 1];
                        const workoutsInWeek = userStore.getWorkoutsInWeek(week.year, week.week, week.month);
                        const sumWeek = workoutsInWeek.reduce((sum, workout) => sum + workout.distanceKm, 0);
                        return (<>
                            <Week
                                stores={this.props.stores}
                                sumWeek={sumWeek}
                                week={week}
                                firstDayInWeek={firstDayInWeek}
                                lastDayInWeek={lastDayInWeek}
                                workoutsInWeek={workoutsInWeek}
                                workoutDistanceCurrentSeasonMiddle={workoutDistancePrevSeasonMiddle}
                                workoutDurationCurrentSeasonMiddle={workoutDurationPrevSeasonMiddle}
                            />
                        </>);
                    })}
                    {/* <div class='text-right text-muted mt-2'>
                        <small>
                            <Text id='workout.more-log'>See more of your training patterns</Text>
                        </small>
                    </div> */}

                </div>
            </div>
        </>);
    }
}

export default WorkoutTrainingLog;
