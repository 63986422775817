import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';

import DragNDropFileupload from './dragNdropFileupload';

@observer
class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    handleAddImage = (file) => {
        const { addToImages = true } = this.props;

        // <Images saved={saved} object={dog} field={field} updateField={updateField} stores={this.props.stores} />
        // updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const {
            updateField,
            object,
            field,
        } = this.props;

        if (field) {
            updateField({ id: object.id, field, value: file, skipTimer: true });
        }

        // let images = toJS(object.images);
        // if (!util.isArray(images)) {
        //     images = [];
        // }
        if (addToImages) {
            const images = [];
            images.push(file);
            updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        }
        // const allImages = [];
        // allImages.push(file);
        // updateField({ id: object.id, field: 'allImages', value: allImages, skipTimer: true });
    };

    render() {
        const { uploadStatus = () => {}, before = () => {}, after = () => {}, autoOpen = true } = this.props;
        return (
            <div class='row'>
                <div class='col-12'>
                    <DragNDropFileupload
                        handleAddImage={this.handleAddImage}
                        uploadStatus={uploadStatus}
                        apiUrl={'/api/fileupload'}
                        before={before}
                        after={after}
                        autoOpen={autoOpen}
                        stores={this.props.stores}
                        accept='image/*'
                    >
                        <div style={{ height: 30, width: '100%' }}>
                            {/* {files.map((file, idx) => (
                                <div key={idx}>
                                    {file.name}
                                    Total: {util.asString(file, 'load', 'total')}
                                    <img style='width: 48px;'
                                        alt='Preview'
                                        key={file.preview}
                                        src={file.src}
                                    />
                                </div>
                            ))} */}
                        </div>
                    </DragNDropFileupload>
                </div>
            </div>
        );
    }
}

export default Images;
