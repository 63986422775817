import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import linkState from 'linkstate';

import Images from '../form/images';

import mu from '../../lib/musher-util';

const initialState = {
    showAddImage: false,
    showTeamChange: false,
    showChangeVisibility: false,
    showChangeType: false,
    sendButtonEnabled: true,
};

@observer
class StoryAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            story: {
                type: 1,
                title: '',
                body: '',
                images: [],
                public: 1,
                urlText: '',
                url: '',
                currency: '',
                price: 0,
                code: '',
                country: '',
                region: '',
                startDate: '',
                endDate: '',
            },
            ...initialState,
        };
        this.inputRef = {};
    }

    beforeImagesUploaded = () => {
        this.setState({ sendButtonEnabled: false });
    }

    afterImagesUploaded = () => {
        this.setState({ sendButtonEnabled: true });
    }

    addStory = async () => {
        const { story = {}, showAddImage, sendButtonEnabled } = this.state;
        const { callback = () => {}, hashTags = [], postfix, topic = `team-${story.team}` } = this.props;
		const { storyStore } = this.props.stores;

        if (!sendButtonEnabled) {
            return false;
        }

        if (!story.body) {
            return false;
        }
        this.setState({ sendButtonEnabled: false });

        let body = story.body;
        if (postfix) {
            body += `\n\n${postfix}`;
        }
        if (hashTags && hashTags.length > 0) {
            body += `\n\n${hashTags.map(tag => `#${tag}`).join(' ')}`;
        }

		const response = await storyStore.insert({
            type: story.type,
            title: story.title,
            body,
            pinned: story.pinned,
            images: story.images,
            public: story.public ? 1 : 0,
            team: story.team,
            urlText: story.urlText,
            url: story.url,
            currency: story.currency,
            price: story.price,
            code: story.code,
            country: story.country,
            region: story.region,
            startDate: story.startDate,
            endDate: story.endDate,
            topic,
        });
		if (response && response.status === 201) {
            await storyStore.loadPublicFeed({ hashtags: hashTags });
            this.setState({
                story: {
                    type: 1,
                    title: '',
                    body: '',
                    pinned: null,
                    images: [],
                    public: 1,
                    urlText: '',
                    url: '',
                    currency: '',
                    price: null,
                    code: '',
                    country: '',
                    region: '',
                    startDate: null,
                    endDate: null,
                },
            }, () => {
                mu.resizeTextarea(this.inputRef['newStoryBody']);
                if (showAddImage) {
                    this.addImage();
                }
            });
            callback(response.data);
		}
        this.setState({ sendButtonEnabled: true });
	}

    addImages = ({ value }) => {
        // updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        const { story } = this.state;
        story.images.push(...value);
        this.setState({ story });
    }

    removeImage = async (e) => {
        const { story } = this.state;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        if (util.isArray(story.images)) {
            const idx = story.images?.findIndex(e => e.name === imageName);
            if (idx > -1) {
                story.images.splice(idx, 1);
            }
        }
        this.setState({ story });
    }

    addImage = () => {
        const { showAddImage } = this.state;
        this.setState({
            showAddImage: !showAddImage,
        });
    }

    changeVisibility = () => {
        const { showChangeVisibility } = this.state;
        this.setState({
            showChangeVisibility: !showChangeVisibility,
        });
    }

    changePinned = () => {
        const { showChangeVisibility } = this.state;
        this.setState({
            showChangeVisibility: !showChangeVisibility,
        });
    }

    changeType = () => {
        const { showChangeType } = this.state;
        this.setState({
            showChangeType: !showChangeType,
        });
    }

    toggleTeamChange = () => {
        const { showTeamChange } = this.state;
        this.setState({
            showTeamChange: !showTeamChange,
        });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    setPublicTeam = () => {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const publicTeams = userStore.findPublicTeams();
        const { story } = this.state;
        if (user && user.activeTeam && user.activeTeam.team) {
            story.team = user.activeTeam.team;
            this.setState({ story });
        } else if (util.isArray(publicTeams) && publicTeams.length > 0) {
            story.team = publicTeams[0].id;
            this.setState({ story });
        }
    }

    setFocus = (e) => {
        this.setState({ hasFocus: true });
        mu.resizeTextarea(e.target);
    }

    removeFocus = (e) => {
        this.setState({ hasFocus: false });
    }

    checkProps = (props = this.props) => {
        const { setFocus } = props;
        if (setFocus) {
            mu.resizeTextarea(this.inputRef['newStoryBody']);
            this.inputRef['newStoryBody'].focus();
        }
    }

    componentDidMount() {
        this.setPublicTeam();
        this.checkProps();
	}

    render() {
        const { col = 12, offset = 0, hashTags = [] } = this.props;
        const { story = {}, showAddImage, showTeamChange, showChangeVisibility, showChangeType, sendButtonEnabled, hasFocus } = this.state;
        const { forceShowAddImage } = this.props
        const { userStore, appState } = this.props.stores;
        const { user = {}, isAdmin } = userStore;
        // const team = userStore.findTeam(story.team || user.team, true) || {};
        const displayNameShort = user.firstname ? `${util.ucfirst(user.firstname, true)}${util.ucfirst(user.lastname, true)}` : `${util.ucfirst(user.email || '', true)}`;
        // const displayName = user.firstname ? `${user.firstname} ${user.lastname}` : `${user.email}`;

        let userImage = mu.getImage({ user: user, priority: 'user' });
        if (story.team) {
            const teamId = parseInt(story.team, 10);
            const team = userStore.findTeam(teamId, true);
            userImage = mu.getImage({ user: user, team, priority: 'user' });
        }
        const { darkmode } = appState;

        return (
            <div class='w-100 px-0'>
                <div class='row'>

                    <div class={`col-${col} offset-${offset} col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 rounded rounded-lg pt-3 pb-1 bg-${darkmode ? 'black' : 'white'} shadow-sm mb-1 mt-5`}>
                        <div class='d-flex mb-0 position-relative w-100'>
                            <div class='position-relative'>
                                <div
                                    class={`text-center border rounded-circle imageRounded imageRoundedSmall pointerCursor`}
                                    style={`${userImage ? `background-image: url('${userImage}'); background-size: cover;` : ''} border-width: 3px !important;`}
                                    onClick={this.toggleTeamChange}
                                >
                                    {!userImage && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                </div>
                                <div class='position-absolute' style='top: 16px; right: 0px;'>
                                    <small class='text-white' style='font-size: 1.5em;'>
                                        <i class='fas fa-caret-down pointerCursor' onClick={this.toggleTeamChange} />
                                    </small>
                                </div>
                            </div>
                            <div class='col pl-3 pr-0' style='line-height: 1.2em;'>
                                <div class='d-flex bd-highlight'>
                                    <div class='p-0 w-100 bd-highlight'>
                                        <label class='sr-only' for='inlineFormInput'><Text id='story.body'>Story</Text></label>
                                        <div class='border rounded rounded-lg position-relative'>
                                            <Localizer>
                                                <textarea
                                                    class='form-control-plaintext px-2'
                                                    type='text'
                                                    placeholder={<Text id='story.write-body'>Hva tenker du på?..</Text>}
                                                    onInput={linkState(this, 'story.body')}
                                                    onKeyUp={mu.resizeTextarea}
                                                    onFocus={this.setFocus}
                                                    style='height: 3.3em;'
                                                    value={story.body}
                                                    ref={c => this.inputRef['newStoryBody'] = c}
                                                />
                                            </Localizer>
                                            {hasFocus && <div class='position-absolute' style='bottom: 8px; right: 10px; font-size: 1.2em;'>
                                                {/* <i class='fas fa-image pointerCursor'
                                                    data-id={story.id}
                                                    onClick={this.addImage}
                                                /> */}
                                                <i class={`fas fa-${story.public === 1 ? 'globe-europe' : 'users'} pointerCursor ml-2`}
                                                    data-id={story.id}
                                                    onClick={this.changeVisibility}
                                                />
                                                {isAdmin && <i class={`fas fa-cog pointerCursor ml-2`}
                                                    data-id={story.id}
                                                    onClick={this.changeType}
                                                />}
                                            </div>}
                                        </div>
                                        {hashTags && hashTags.length > 0 && <>
                                            {hashTags.map(tag => <>
                                                <span class='badge badge-secondary mr-2 my-1'>
                                                    <a href={`/stories/tag/${tag}`} class='text-white'>#{tag}</a>
                                                </span>
                                            </>)}
                                        </>}
                                    </div>
                                    <div class='p-0 pl-2 flex-shrink-1 bd-highlight pt-auto'>
                                        <button type='button' class={`btn btn-${sendButtonEnabled ? 'primary' : 'secondary'} ml-1`} disabled={!sendButtonEnabled}
                                            onClick={this.addStory}
                                        ><i class='fas fa-paper-plane' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class='row'>
                                {showTeamChange && <>
                                    <div class='col-12 text-center mt-2'>
                                        <div class='form-group'>
                                            <small>
                                                <select
                                                    class='custom-select custom-select-sm'
                                                    id='team'
                                                    onInput={linkState(this, 'story.team')}
                                                    onChange={this.toggleTeamChange}
                                                >
                                                    <option value=''>-- <Text id='story.team-choose'>Velg team</Text> --</option>
                                                    {util.isArray(user.teams) && user.teams.map((teamId) => {
                                                        const team = userStore.findTeam(teamId, true);
                                                        if (team) {
                                                            return (<option value={team.id} selected={story.team == team.id ? 'selected' : ''}>{team.name}</option>);
                                                        }
                                                    })}

                                                    {!user.teams && <>
                                                        <option value=''>No teams</option>
                                                    </>}
                                                </select>
                                            </small>
                                        </div>
                                    </div>
                                </>}
                                {showChangeVisibility && <>
                                    {isAdmin && <>
                                        <div class='col-12 text-center mt-2'>
                                            <div class='form-group'>
                                                <small>
                                                    <label for='pinned'>Pinned to top:</label><input id='pinned' type='checkbox' onInput={linkState(this, 'story.pinned')} value={1} checked={story.pinned ? 1 : 0} />
                                                </small>
                                            </div>
                                        </div>
                                    </>}

                                    <div class='col-12 text-center mt-2'>
                                        <div class='form-group'>
                                            <small>
                                                <select
                                                    class='custom-select custom-select-sm'
                                                    id='team'
                                                    onInput={linkState(this, 'story.public')}
                                                    value={story.public}
                                                    onChange={this.changeVisibility}
                                                >
                                                    <option value={0} selected={story.public == 0 ? 'selected' : ''}>👥 <Text id='story.visibility-followers'>Kun synlig for de som følger deg.</Text></option>
                                                    <option value={1} selected={story.public == 1 ? 'selected' : ''}>🌍 <Text id='story.visibility-public'>Synlig for alle som bruker appen.</Text></option>
                                                </select>
                                            </small>
                                        </div>
                                    </div>
                                </>}
                                {showChangeType && <>
                                    <div class='col-12 text-center mt-2'>
                                        <div class='form-group'>
                                            <small>
                                                <select
                                                    class='custom-select custom-select-sm'
                                                    id='team'
                                                    onInput={linkState(this, 'story.type')}
                                                    value={story.type}
                                                    onChange={this.changeType}
                                                >
                                                <option value=''>-- <Text id='story.type-choose'>Velg type</Text> --</option>
                                                    <option value={1} selected={story.type == 1 ? 'selected' : ''}>✍️ <Text id='story.type-regular'>Vanlig</Text></option>
                                                    <option value={2} selected={story.type == 2 ? 'selected' : ''}>🚀 <Text id='story.type-ad'>Annonse</Text></option>
                                                    <option value={3} selected={story.type == 3 ? 'selected' : ''}>📄 <Text id='story.type-article'>Artikkel</Text></option>
                                                </select>
                                            </small>
                                        </div>
                                    </div>
                                </>}
                                {(forceShowAddImage || showAddImage) && <>
                                    <div class='col-12 text-center mt-2'>
                                        <div class='container-fluid'>
                                            <div class='row'>
                                                {util.isArray(story.images) && story.images.map(img => (
                                                    <div class='col-3 p-1'>
                                                        <img
                                                            src={img.s3SmallLink}
                                                            alt={`May be an image of: ${mu.imageKeywords(img).join(', ')}`}
                                                            class='img-fluid'
                                                            onError={this.handleImageErrored}
                                                        />
                                                        <button class='btn btn-sm btn-link'
                                                            onClick={this.removeImage}
                                                            data-name={img.name}
                                                        ><i class='fas fa-trash' /> Del</button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <Images object={story} updateField={this.addImages} before={this.beforeImagesUploaded} after={this.afterImagesUploaded} autoOpen={forceShowAddImage ? false : true} stores={this.props.stores} />
                                    </div>
                                </>}

                                {story.type > 1 && <>
                                    <div class='col-12 mt-3'>
                                        <label><Text id='story.url-text'>URL text</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='urlText'
                                                    onInput={linkState(this, 'story.urlText')}
                                                    value={story.urlText}
                                                />
                                            </small>
                                        </div>
                                    </div>

                                    <div class='col-12 mt-3'>
                                        <label><Text id='story.url'>URL</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='url'
                                                    onInput={linkState(this, 'story.url')}
                                                    value={story.url}
                                                />
                                            </small>
                                        </div>
                                    </div>
                                </>}

                                {story.type == 2 && <>
                                    <div class='col-12 mt-3'>
                                        <label><Text id='story.code'>Code</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='code'
                                                    onInput={linkState(this, 'story.code')}
                                                    value={story.code}
                                                />
                                            </small>
                                        </div>
                                    </div>

                                    <div class='col-12'>
                                        <label><Text id='story.region'>Region</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='region'
                                                    onInput={linkState(this, 'story.region')}
                                                    value={story.region}
                                                />
                                            </small>
                                        </div>
                                    </div>

                                    <div class='col-12'>
                                        <label><Text id='story.country'>Country</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='country'
                                                    onInput={linkState(this, 'story.country')}
                                                    value={story.country}
                                                />
                                            </small>
                                        </div>
                                    </div>

                                    <div class='col-4'>
                                        <label><Text id='story.currency'>Currency</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='currency'
                                                    onInput={linkState(this, 'story.currency')}
                                                    value={story.currency}
                                                />
                                            </small>
                                        </div>
                                    </div>

                                    <div class='col-8'>
                                        <label><Text id='story.price'>Price</Text></label>
                                        <div class='form-group'>
                                            <small>
                                                <input
                                                    class='form-control'
                                                    type='text'
                                                    name='price'
                                                    onInput={linkState(this, 'story.price')}
                                                    value={story.price}
                                                />
                                            </small>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StoryAdd;
