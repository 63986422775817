import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';
import linkstate from 'linkstate';

@observer
class Achievements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
    }

    handleAdd = () => {
        const { data } = this.state;
        const {
            updateField,
            object,
            field,
        } = this.props;
        updateField({ id: object.id, field, value: { ...data } });
        this.setState({
            data: {
                name: '',
                url: '',
            }
        });
    };

    removeLink = async (e) => {
        const { dogStore } = this.props.stores;
        const { object } = this.props;
        const { name, id } = e.target.closest('button').dataset;
        dogStore.removeLink({
            id: object.id,
            name,
            linkId: id,
        });
    }

    render() {
        const { showFiles = true, object = {} } = this.props;

        return (
            <div class='row'>
                <div class='col-12 mt-3'>
                    {showFiles && <>
                        <div class='container-fluid'>
                            <div class='row'>

                                {(!object.links || object.links?.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-link' /></div>
                                        <h3><Text id='dogs.no-links-title'>No links for this dog!</Text></h3>
                                        <Text id='dogs.no-links-text'>You can add links to this dog. Ie. Links to Facebook page, Instagram page, blogg, etc.</Text>
                                    </div>
                                </>}

                                {object.links && object.links.map(file => (
                                    <div class='col-12 p-1'>
                                        <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                        <span class='mr-2'>{file.name}</span>
                                        <span class='mr-2'>{file.url}</span>
                                        <button class='btn btn-sm btn-link float-right p-0'
                                            onClick={this.removeLink}
                                            data-name={file.name}
                                            data-id={file.id}
                                        ><i class='fas fa-trash-alt' /> Del</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                </div>
                <div class='col-12 mt-3'>
                    <details>
                        <summary><Text id='dogs.link-add'>Add new link</Text></summary>
                        <div class='form-group'>
                            <label for='name'><Text id='dogs.link-name'>Name</Text></label>
                            <input type='text' class='form-control' id='name' aria-describedby='nameHelp' onInput={linkstate(this, 'data.name')} />
                            <small id='nameHelp' class='form-text text-muted'><Text id='dogs.link-name-help'>Name of link</Text></small>
                        </div>
                        <div class='form-group'>
                            <label for='url'><Text id='dogs.link-url'>Url</Text></label>
                            <input type='text' class='form-control' id='url' aria-describedby='urlHelp' onInput={linkstate(this, 'data.url')} />
                            <small id='urlHelp' class='form-text text-muted'><Text id='dogs.link-url-help'>URL of link</Text></small>
                        </div>
                        <button class='btn btn-success' onClick={this.handleAdd}>
                            <Text id='dogs.link-add'>Add link</Text>
                        </button>
                    </details>
                </div>
            </div>
        );
    }
}

export default Achievements;
