import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import PublicImages from '../../components/team/publicImages';

import mu from '../../lib/musher-util';

@observer
class Favorites extends Component {
	constructor(props) {
        super(props);
        this.state = {
            limit: 15,
            offset: 0,
        };
    }

    async loadAll(props = this.props) {
        const { limit, offset } = this.state;
        this.loadFavorites({ limit, offset, props });
    }

    async loadFavorites({ limit, offset, props = this.props }) {
		const { userStore } = props.stores;
        await userStore.loadFavorites({ limit, offset });
    }

    setLoadMore = () => {
        const { userStore } = this.props.stores;
        userStore.setLoadMore(() => {
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset });
            this.loadFavorites({ limit, offset });
        });
    }

    unsetLoadMore = () => {
        const { userStore } = this.props.stores;
        userStore.setLoadMore(() => {});
    }

    componentDidMount() {
        this.setLoadMore();
        this.loadAll();
    }

    componentWillUnmount() {
        this.unsetLoadMore();
    }

    render() {
		const { userStore } = this.props.stores;
		const { favorites = [] } = userStore;

        return (<>
            <div class='container-fluid'>
                <div class='row mt-0'>
                    <div class='col-12 mt-0'>
                        <PublicImages stores={this.props.stores} publicImages={favorites}>
                            <div class='text-center text-muted'>
                                <div class='display-4'><i class='fa-solid fa-star' /></div>
                                <h5><Text id='teams.no-bookmarks'>No favorites found for your user!</Text></h5>
                            </div>
                        </PublicImages>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default Favorites;

