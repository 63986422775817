import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import { route } from 'preact-router';
import util from 'preact-util';

import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class StravaActivitiesStore extends StoreModel {
    constructor() {
        super('stravaActivity', {
            namePlural: 'stravaActivities',
            sort: '-start_date',
            limit: 10,
            api: {
                search: {
                    url: '/api/strava/activities/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: '-start_date',
                    },
                },
                load: {
                    url: '/api/strava/activities/',
                    params: {},
                },
                save: {
                    url: '/api/strava/activities/',
                    params: {},
                },
            },
        });
    }

    @observable stravaActivity = {};

    @observable stravaActivities = [];

    @action
    cleanupMemory() {
        this.localUpdateField('stravaActivity', {});
        this.localUpdateField('stravaActivities', []);
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    async ignoreWorkout(id) {
        const response = await util.fetchApi(`/api/strava/ignore`, { publish: false, method: 'GET' }, { id });
        switch (response.status) {
            case 200:
                console.log(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async importWorkout(id, team) {
        const response = await util.fetchApi(`/api/strava/import`, { publish: false, method: 'GET' }, { id, team });
        switch (response.status) {
            case 200:
                console.log(response);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new StravaActivitiesStore();
export default store;
