import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

@observer
class Overlay extends Component {
    render() {
        const { children } = this.props;
        return (
            <div
                class='w-100 h-100 fixed-top bg-light skipRefresh'
                style={`
                    margin-bottom: ${MARGIN_BOTTOM};
                    margin-top: ${MARGIN_TOP};
                    height: calc(100vh - ${MARGIN_TOP}) !important;
                    top: 0;
                    left: 0;
                    overflow-x: auto;
                    overflow-y: auto;
                    transition: .3s;
                    overscroll-behavior: contain;
                    z-index: 10000;
                `}
            >
                {children}
            </div>
        );
    }
}

export default Overlay;
