import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import style from './style.css';

import mu from '../../lib/musher-util';

const TEXT_BOX_PADDING_X = 10;
const TEXT_PADDING_X = 20;

function getTicks(count, min, max, reverse = false, prefix = '', postfix = '') {
    let loop = Array.from(Array(count).keys());
    if (reverse) {
        loop = loop.reverse();
    }
    const range = Math.ceil(max) - Math.floor(min);
    if (prefix === '' && postfix === '') {
        return loop.map(d => parseInt((range / (count - 1) * d), 10) + Math.floor(min));
    }
    return loop.map(d => `${prefix}${parseInt((range / (count - 1) * d), 10) + Math.floor(min)}${postfix}`);
}

function transformXTick(tick, fn) {
    if (util.isFunction(fn)) {
        return fn(tick);
    }
    if (typeof tick === 'string') {
        if (tick.match(/\d+h/)) {
            const msDiff = tick.replace(/h/g, '') * 3600 * 1000;
            const now = util.epoch();
            const then = now - msDiff;
            return util.isoDate(then);
        }
    }
    return tick;
}

function transformYTick(tick, fn) {
    if (util.isFunction(fn)) {
        return fn(tick);
    }
    return tick;
}

function normalizeRange(val, min, max, newMin, newMax) {
    return newMin + (val - min) * (newMax - newMin) / (max - min);
}

function round5(x, roundDown = false) {
    let sign = 1;
    if (x < 0) {
        sign = -1;
    }
    if (roundDown) {
        if (sign === 1) {
            return Math.floor(Math.abs(x) / 5) * 5 * sign;
        }
        return Math.ceil(Math.abs(x) / 5) * 5 * sign;
    }
    if (sign === 1) {
        return Math.ceil(Math.abs(x) / 5) * 5 * sign;
    }
    return Math.floor(Math.abs(x) / 5) * 5 * sign;
}

function findMinX({ data, min }) {
    const values = data.map((arr) => {
        if (util.isArray(arr)) {
            if (util.isNumber(min)) {
                return Math.min(min, ...arr.map(d => d.x));
            }
            return Math.min(...arr.map(d => d.x));
        }
    });
    return Math.min(...values);
}

function findMaxX({ data, max }) {
    const values = data.map((arr) => {
        if (util.isArray(arr)) {
            if (util.isNumber(max)) {
                return Math.max(max, ...arr.map(d => d.x));
            }
            return Math.max(...arr.map(d => d.x));
        }
    });
    return Math.max(...values);
}

function findMinY({ data, min }) {
    const values = data.map((arr) => {
        if (util.isArray(arr)) {
            if (util.isNumber(min)) {
                return Math.min(min, ...arr.map(d => d.y));
            }
            return Math.min(...arr.map(d => d.y));
        }
    });
    return Math.min(...values);
}

function findMaxY({ data, max }) {
    const values = data.map((arr) => {
        // console.log(arr);
        if (util.isArray(arr)) {
            if (util.isNumber(max)) {
                return Math.max(max, ...arr.map(d => d.y));
            }
            return Math.max(...arr.map(d => d.y));
        }
    });
    return Math.max(...values);
}

function smoothValues(arr, windowSize = 2, getter = (value) => value, setter) {
    const get = getter
    const result = []
    for (let i = 0; i < arr.length; i += 1) {
        const leftOffeset = i - windowSize
        const from = leftOffeset >= 0 ? leftOffeset : 0
        const to = i + windowSize + 1
        let count = 0
        let sum = 0
        for (let j = from; j < to && j < arr.length; j += 1) {
            sum += get(arr[j])
            count += 1
        }
        result[i] = setter ? setter(arr[i], sum / count) : sum / count
    }
    return result
}


// Properties of a line
// I:  - pointA (array) [x,y]: coordinates
//     - pointB (array) [x,y]: coordinates
// O:  - (object) { length: l, angle: a }: properties of the line
function lineProperties(pointA, pointB) {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX),
    };
}

// Position of a control point
// I:  - current (array) [x, y]: current point coordinates
//     - previous (array) [x, y]: previous point coordinates
//     - next (array) [x, y]: next point coordinates
//     - reverse (boolean, optional): sets the direction
// O:  - (array) [x,y]: a tuple of coordinates
function controlPoint(current, previous, next, reverse) {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist.
    // Replace with 'current'
    const p = previous || current;
    const n = next || current;

    // The smoothing ratio
    const smoothing = 0.02;

    // Properties of the opposed-line
    const o = lineProperties(p, n);

    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0);
    const length = o.length * smoothing;

    // The control point position is relative to the current point
    const x = current[0] + Math.cos(angle) * length;
    const y = current[1] + Math.sin(angle) * length;
    return [x, y]
}

// Create the bezier curve command
// I:  - point (array) [x,y]: current point coordinates
//     - i (integer): index of 'point' in the array 'a'
//     - a (array): complete array of points coordinates
// O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
function bezierCommand(point, i, a) {
    // start control point
    const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)  // end control point
    const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
    return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
}

// Svg path line command
// I:  - point (array) [x, y]: coordinates
// O:  - (string) 'L x,y': svg line command
function lineCommand(point) {
    if (point[1] === null) {
        return `M ${point[0]} ${point[1]}`;
    }
    return `L ${point[0]} ${point[1]}`;
}

// Render the svg <path> element
// I:  - points (array): points coordinates
//     - command (function)
//       I:  - point (array) [x,y]: current point coordinates
//           - i (integer): index of 'point' in the array 'a'
//           - a (array): complete array of points coordinates
//       O:  - (string) a svg path command
// O:  - (string): a Svg <path> element
function svgPath(points, command, opts = {}) {
    // build the d attributes by looping over the points
    const maxX = Math.max(...points.map(d => d[0]));
    const minX = Math.min(...points.map(d => d[0]));
    const firstPoint = points[0] || [[0, opts.height]];
    const lastPoint = points[points.length - 1] || [[maxX, opts.height]];

    let dPrefix;
    if (opts.lineFromXzero) {
        dPrefix = `M ${0},${opts.height} L ${firstPoint[0] - 20},${opts.height - 1}`;
    } else {
        dPrefix = `M ${firstPoint[0]},${firstPoint[1]} L ${firstPoint[0]},${firstPoint[1]}`;
    }
    let dPostfix;
    if (opts.lineFromXmax) {
        dPostfix = `L ${lastPoint[0] + 10},${opts.height} L ${opts.width},${opts.height - 1}`;
    } else {
        dPostfix = `L ${lastPoint[0]},${lastPoint[1]} L ${lastPoint[0]},${lastPoint[1]}`;
    }

    const d = points.reduce((acc, point, i, a) => i === 0
        // if first point
        ? `L ${point[0]},${point[1]}`
        // else
        : `${acc} ${command(point, i, a)}`
    , '');

    return `${dPrefix} ${d} ${dPostfix}`;
}

function makePaths(opts) {
    return opts.data.map((d, idx) => makePath({
        ...opts,
        data: d,
        yRangeMin: util.isArray(opts.yRangeMin) ? opts.yRangeMin[idx] : undefined,
        yRangeMax: util.isArray(opts.yRangeMax) ? opts.yRangeMax[idx] : undefined,
        avgWindow: util.isArray(opts.avgWindowLeft) ? opts.avgWindowLeft[idx] : (util.isArray(opts.avgWindowRight) ? opts.avgWindowRight[idx] : undefined),
        smooth: util.isArray(opts.smoothLeft) ? opts.smoothLeft[idx] : (util.isArray(opts.smoothRight) ? opts.smoothRight[idx] : undefined),
    }));
}

function makePath({ data, yMin, yMax, xMin, xMax, width, height, yRangeMin, yRangeMax,
    offsetY = 0, maxMinFixed = false, smooth = false, avgWindow = 0, lineFromXzero, lineFromXmax,
    yMaxMinRound,
}) {
    const dataMap = {};
    if (data && data.length) {
        const minX = xMin || Math.min(...data.map(d => d.x), xMin);
        const maxX = xMax || Math.max(...data.map(d => d.x), xMax);

        let minY;
        let maxY;
        if (maxMinFixed) {
            minY = util.isNumber(yMin) ? yMin : findMinY({ data });
        } else {
            minY = util.isNumber(yMin) ? findMinY({ data, min: yMin }) : findMinY({ data });
            if (yMaxMinRound) {
                minY = round5(minY, true);
            }
        }
        if (maxMinFixed) {
            maxY = util.isNumber(yMax) ? yMax : findMaxY({ data });
        } else {
            maxY = util.isNumber(yMax) ? findMaxY({ data, max: yMax }) : findMaxY({ data });
            if (yMaxMinRound) {
                maxY = round5(maxY);
            }
        }

        const realHeight = height;
        const fnX = (val, idx) => {
            if (val === -1) {
                return -1;
            }

            const dataVal = normalizeRange(val, minX, maxX, minX, maxX);
            const x = Math.round(normalizeRange(dataVal, minX, maxX, 0, width));
            // const x = Math.round(val / maxX * width);
            if (util.isDefined(idx)) {
                if (!dataMap[idx]) {
                    dataMap[idx] = {};
                }
                dataMap[idx].x = x;
                dataMap[idx].xVal = val;
            }
            return x;
        }

        const fnY = (val, idx) => {
            if (val === -1) {
                return height;
            }
            let dataVal = val;
            if (util.isNumber(yRangeMin) && util.isNumber(yRangeMax)) {
                dataVal = normalizeRange(dataVal, minY, maxY, yRangeMin, yRangeMax);
                return realHeight - normalizeRange(dataVal, yRangeMin, yRangeMax, 0, height - offsetY);
            }
            if (dataVal === null) {
                return null;
            }
            dataVal = normalizeRange(dataVal, minY, maxY, minY, maxY);
            const y = Math.round(realHeight - normalizeRange(dataVal, minY, maxY, 0, height - offsetY));
            if (util.isDefined(idx)) {
                if (!dataMap[idx]) { dataMap[idx] = {}; }
                dataMap[idx].y = y;
                dataMap[idx].yVal = dataVal;
            }
            return y;
        };

        const dataArray = data.map((p, idx) => [fnX(p.x, idx + 1), fnY(p.y, idx + 1)]).filter(e => e[1] !== null);
        const finalDataArray = avgWindow > 0 ? smoothValues(dataArray, avgWindow, (e) => e[1], (e, val) => [e[0], val]) : dataArray;
        for (let i = 0, l = finalDataArray.length; i < l; i += 1) {
            if (util.isDefined(dataMap[i])) {
                dataMap[i + 1].ySmooth = finalDataArray[i][1];
            }
        }

        const d = svgPath(finalDataArray, smooth ? bezierCommand : lineCommand, {
            height,
            width,
            lineFromXzero,
            lineFromXmax,
        });
        const dOriginal = svgPath(dataArray, lineCommand, { height });
        return { d, maxX, maxY, minY, dOriginal, dataMap };
    }
    return {};
}

const initialState = {
    mouseData: {},
};

@observer
class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.svgRef = null;
        this.svgContainerRef = null;
    }

    mouseMove = (e) => {
        if (this.props.stores) {
            const { x, y } = this.getCoordsWithPadding(e.layerX, e.layerY);
            const { appState } = this.props.stores;
            appState.setMousePosition([x, y]);
        }
    };

    getCoordsWithPadding(xVal, yVal) {
        if (!this.svgRef) {
            return {};
        }

        const {
            width = 600,
            height = 200,
            paddingLeft,
            // paddingRight,
            paddingTop,
            paddingBottom,
            showYTicksLeft,
            // showYTicksRight,
            showXTicks,
        } = this.props;

        const PADDING_LEFT = paddingLeft || (showYTicksLeft ? 35 : 0);
        const PADDING_RIGHT = 0; //paddingRight || (showYTicksRight ? 35 : 0);
        const PADDING_TOP = paddingTop;
        const PADDING_BOTTOM = paddingBottom || (showXTicks ? 20 : 0);
        // console.log({ PADDING_LEFT, PADDING_RIGHT, PADDING_TOP, PADDING_BOTTOM });

        const svgWidth = this.svgRef.clientWidth;
        const svgHeight = this.svgRef.clientHeight;

        const x = parseInt(normalizeRange(xVal, PADDING_LEFT, svgWidth, PADDING_RIGHT, width), 10);
        const y = parseInt(normalizeRange(yVal, PADDING_TOP, svgHeight, PADDING_BOTTOM, height), 10);

        return { x, y };
    }

    getMouseCoordX(maxX, xPos) {
        const { width = 600 } = this.props;
        return parseInt(normalizeRange(xPos, 0, width, 0, maxX), 10) + 1;
    }

    getCoordX(minX, maxX, x) {
        const { width = 600 } = this.props;
        return normalizeRange(x, minX, maxX, 0, width);
    }

    getTextBoxX(mousePos, align, subtractPadding = 0) {
        const RIGHT_TEXT_OFFSET = 150;
        const LEFT_SIDE_OF_MOUSE_LINE = 180;
        const { width = 600 } = this.props;

        const pos = (mousePos / width * 100) > 75 ? mousePos - LEFT_SIDE_OF_MOUSE_LINE : mousePos;
        if (align === 'right') {
            return pos + TEXT_PADDING_X - subtractPadding + RIGHT_TEXT_OFFSET;
        }
        return pos + TEXT_PADDING_X - subtractPadding;
    }

    getMouseData(minX, maxX, dataMap, xPos, key, opt = { autoLimit: false, padding: 0 }) {
        const { width = 600 } = this.props;
        const x = parseInt(normalizeRange(xPos, 0, width, minX, maxX), 10) + 1;
        if (!util.isObject(dataMap)) {
            return null;
        }
        // Find closest value to current mouse position.
        const dataMapArr = Object.values(dataMap);
        const dataMapCurrent = dataMapArr.reduce(({ xVal, ...resto }, { xVal: a, ...rest }) =>
            Math.abs(x - a) < Math.abs(x - xVal) ? { xVal: a, ...rest } : { xVal, ...resto }
        );


        if (util.isObject(dataMapCurrent)) {
            if (!key) {
                return dataMapCurrent;
            }
            // if (opt.autoLimit && key === 'y') {
            //     const val = dataMapCurrent.yVal;
            //     if (val > height / 2) {
            //         return val - 70 - opt.padding;
            //     }
            //     return val + 20 - opt.padding;
            // }
            // if (opt.autoLimit && key === 'x') {
            //     const val = dataMapCurrent.x;
            //     if (val > width / 2) {
            //         // console.log(`autoLimit=${autoLimit}, key=${key}, val=${val}, width=${width}`)
            //         return val - 160 - opt.padding;
            //     }
            //     return val + 20 - opt.padding;
            // }
            const val = dataMapCurrent[key];
            return val;
        }
    }

    componentDidMount() {
        const { showValueLeftLine, showValueRightLine, scrollToRight = false } = this.props;
        if(showValueLeftLine || showValueRightLine) {
            this.svgRef.addEventListener('mousemove', this.mouseMove);
        }
        if (this.svgContainerRef && scrollToRight) {
            const maxScroll = this.svgContainerRef.scrollLeftMax || (this.svgContainerRef.scrollWidth - this.svgContainerRef.clientWidth);
            this.svgContainerRef.scrollLeft = maxScroll;
        }
        // onMouseMove={this.mouseMove}
    }

    componentWillUnmount() {
        const { showValueLeftLine, showValueRightLine } = this.props;
        if(showValueLeftLine || showValueRightLine) {
            this.svgRef.removeEventListener('mousemove', this.mouseMove);
        }
    }

    render() {
        const {
            width = 600,         // Width of svg.
            height = 200,        // Height of svg.
            offsetY = 0,         // Data plot offset y axis.
            paddingLeft = 0,     // Make room for yTicksLeft.
            paddingRight = 0,    // Make room for yTicksRight.
            paddingTop = 0,      // Make room for legends.
            paddingBottom = 0,   // Make room for xTicks.
            tickCount = 6,       // Number of ticks to show

            showXTicks,          // Show X ticks.
            showYTicksLeft,      // Show Y ticks left.
            showYTicksRight,     // Show Y ticks right.

            xTicks,              // xTicks array to use instead of values. JSON.stringified and backslash escaped.
            xTicksVal,           // Function used to show x ticks
            xTicksLink,          // Link to xticks valuies.

            yTicksLeft,          // yTicks array to use instead of values. JSON.stringified and backslash escaped.
            yTicksLeftVal,       // Function used to show y ticks
            yTicksLeftPrefix,
            yTicksLeftPostfix,
            yTicksLeftWidth,

            yTicksRight,         // yTicks array to use instead of values. JSON.stringified and backslash escaped.
            yTicksRightVal,      // Function used to show y ticks
            yTicksRightPrefix,
            yTicksRightPostfix,
            yTicksRightWidth,

            xMin,
            xMax,

            yMaxLeft,            // Y max value to use for yTicks
            yMinLeft,            // Y min value to use for yTicks
            yMaxMinLeftRound = true, // Y min max value to use for yTicks

            yMaxRight,           // Y max value to use for yTicks
            yMinRight,           // Y min value to use for yTicks
            yMaxMinRightRound = true, // Y min max value to use for yTicks

            rangeMinLeft = [],
            rangeMaxLeft = [],

            rangeMinRight = [],
            rangeMaxRight = [],

            dataLeft = [],
            dataRight = [],

            legendLeft = [],
            legendRight = [],

            unitLeft = [],
            unitRight = [],

            dataFillLeft = [],
            dataFillRight = [],

            avgWindowLeft = [],
            avgWindowRight = [],

            smoothLeft = [],
            smoothRight = [],

            hoverValLeft = [],   // Function used to show value for hover.
            hoverValRight = [],  // Function used to show value for hover.

            showValueLeftLine = true,
            showValueRightLine = true,

            lineFromXzero,
            lineFromXmax,

            legendFontSize = '14px',
            legendLineHeight = 14,

            hoverFontSize = '12px',
            ticksFontSize = '12px',

            marker,
            markers = [],
        } = this.props;
        const { appState = {} } = this.props.stores || {};
        const { mousePos = [], darkmode } = appState;

        const PADDING_LEFT = paddingLeft || (showYTicksLeft ? 35 : 0);
        const PADDING_RIGHT = paddingRight || (showYTicksRight ? 20 : 0);
        const PADDING_TOP = paddingTop;
        const PADDING_BOTTOM = paddingBottom || (showXTicks ? 20 : 0);
        // console.log({ PADDING_LEFT, PADDING_RIGHT, PADDING_TOP, PADDING_BOTTOM });
        const LEGEND_LINE_HEIGHT = legendLineHeight;
        const LEGEND_FONT_SIZE = legendFontSize;

        const HOVER_FONT_SIZE = hoverFontSize;
        const TICKS_FONT_SIZE = ticksFontSize;

        const common = {
            width,
            height,
            offsetY,
        };

        // console.table(xTicks);
        // console.table(yTicks);
        const MIN_Y_LEFT = round5(findMinY({ data: dataLeft, min: yMinLeft }), true);
        const MAX_Y_LEFT = round5(findMaxY({ data: dataLeft, max: yMaxLeft }), false);
        const MIN_Y_RIGHT = round5(findMinY({ data: dataRight, min: yMinRight }), true);
        const MAX_Y_RIGHT = round5(findMaxY({ data: dataRight, max: yMaxRight }), false);

        const MIN_X = findMinX({ data: dataLeft.concat(dataRight), min: xMin });
        const MAX_X = findMaxX({ data: dataLeft.concat(dataRight), max: xMax });
        // console.log({ MIN_Y_LEFT, MAX_Y_LEFT, MIN_Y_RIGHT, MAX_Y_RIGHT, MIN_X, MAX_X });

        const pathLeft = makePaths({
            ...common,
            data: dataLeft,
            xMin: MIN_X,
            xMax: MAX_X,
            yMin: MIN_Y_LEFT,
            yMax: MAX_Y_LEFT,
            yRangeMin: rangeMinLeft,
            yRangeMax: rangeMaxLeft,
            maxMinFixed: true,
            smoothLeft,
            avgWindowLeft,
            offsetY: PADDING_TOP,
            lineFromXzero,
            lineFromXmax,
            yMaxMinRound: yMaxMinLeftRound,
        });

        const pathRight = makePaths({
            ...common,
            data: dataRight,
            xMin: MIN_X,
            xMax: MAX_X,
            yMin: MIN_Y_RIGHT,
            yMax: MAX_Y_RIGHT,
            yRangeMin: rangeMinRight,
            yRangeMax: rangeMaxRight,
            maxMinFixed: true,
            smoothRight,
            avgWindowRight,
            offsetY: PADDING_TOP,
            lineFromXzero,
            lineFromXmax,
            yMaxMinRound: yMaxMinRightRound,
        });
        // console.log(pathLeft);

        const TOTAL_GRAPHS_LEFT = pathLeft.length;
        const TOTAL_GRAPHS_RIGHT = pathRight.length;
        const TOTAL_GRAPHS = TOTAL_GRAPHS_LEFT + TOTAL_GRAPHS_RIGHT;
        const X_TICKS = xTicks || getTicks(tickCount, MIN_X, MAX_X);
        const Y_TICKS_LEFT = yTicksLeft || getTicks(tickCount, MIN_Y_LEFT, MAX_Y_LEFT, true, yTicksLeftPrefix, yTicksLeftPostfix);
        const Y_TICKS_RIGHT = yTicksRight || getTicks(tickCount, MIN_Y_RIGHT, MAX_Y_RIGHT, true, yTicksRightPrefix, yTicksRightPostfix);
        const markerX = this.getCoordX(MIN_X, MAX_X, marker);
        return (
            <div class={style.lineChart} style={`height: ${height}px; overflow: hidden;`}>
                <div
                    class='overflow-auto position-relative w-100 h-100'
                    style='overflow-y: hidden !important;'
                    ref={c => this.svgContainerRef = c}
                    onTouchstart={mu.captureEvents}
					onTouchend={mu.captureEvents}
					onTouchmove={mu.captureEvents}
					onScroll={mu.captureEvents}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' class={style.svg} viewBox={`0 0 ${width} ${height}`}
                        preserveAspectRatio='none'
                        style={`padding-left: ${PADDING_LEFT}px; padding-right: ${PADDING_RIGHT}px; padding-bottom: ${PADDING_BOTTOM}px; width: ${width}px; min-width: 100%; max-height: 100%;`}
                        ref={c => this.svgRef = c}
                    >
                        {pathRight && pathRight.reverse().map((path, idx) => {
                            let mouseData;
                            if (mousePos && mousePos.length > 0) {
                                mouseData = this.getMouseData(MIN_X, MAX_X, path.dataMap, mousePos[0]);
                            }
                            return (
                                <>
                                    <path d={path.d}
                                        class={`${style[TOTAL_GRAPHS_RIGHT > 20 ? 'pathOriginal' : `pathRight${TOTAL_GRAPHS_RIGHT - idx - 1}`]}
                                            ${dataFillRight[TOTAL_GRAPHS_RIGHT - idx - 1] ? style[`pathRightFill${TOTAL_GRAPHS_RIGHT - idx - 1}`] : ''}`}
                                        fill-rule='evenodd'
                                    />
                                    {showValueRightLine && mousePos && mousePos.length > 0 && path.dataMap
                                        && mouseData && mouseData.yVal
                                        && <line
                                            x1={this.getCoordX(MIN_X, MAX_X, MAX_X)}
                                            x2={mouseData.x}
                                            y1={mouseData.ySmooth}
                                            y2={mouseData.ySmooth}
                                            class={`${style.mouseLine} ${style[`pathRight${TOTAL_GRAPHS_RIGHT - idx - 1}`]}`} />}
                                </>
                            );
                        })}
                        {pathLeft && pathLeft.reverse().map((path, idx) => {
                            let mouseData;
                            if (mousePos && mousePos.length > 0) {
                                mouseData = this.getMouseData(MIN_X, MAX_X, path.dataMap, mousePos[0]);
                            }
                            const currentStyleIdx = TOTAL_GRAPHS_LEFT - idx - 1;
                            let className = style[`pathLeft${currentStyleIdx}`];
                            let classFillLeft = style[dataFillLeft[currentStyleIdx] ? style[`pathLeftFill${currentStyleIdx}`] : ''];
                            if (currentStyleIdx > 9) {
                                className = style.pathOriginal;
                            }
                            return (
                                <>
                                    <path d={path.d}
                                        class={`${className} ${classFillLeft}`}
                                        fill-rule='evenodd'
                                    />
                                    {showValueLeftLine && mousePos && mousePos.length > 0 && path.dataMap
                                        && mouseData && mouseData.yVal
                                        && <line
                                            x1={this.getCoordX(MIN_X, MAX_X, MIN_X)}
                                            x2={mouseData.x}
                                            y1={mouseData.ySmooth}
                                            y2={mouseData.ySmooth}
                                            class={`${style.mouseLine} ${style[`pathLeft${TOTAL_GRAPHS_LEFT - idx - 1}`]}`} />}
                                </>
                            );
                        })}

                        {/* {dCold && dCold.map(o => <line x1={o[0].x} y1={o[0].y} x2={o[1].x} y2={o[1].y} class={style.pathCold} />)};
                        {dWarm && dWarm.map(o => <line x1={o[0].x} y1={o[0].y} x2={o[1].x} y2={o[1].y} class={style.pathWarm} />)}; */}

                        {mousePos && mousePos.length > 0 && <line x1={mousePos[0]} y1={height} x2={mousePos[0]} y2='0' class={style.mouseLine} />}
                        {markerX && <line x1={markerX} y1={height} x2={markerX} y2='0' class={style.marker} />}

                        {markers && markers.map(m => {
                            const mX = this.getCoordX(MIN_X, MAX_X, m);
                            return (
                                <line x1={mX} y1={height} x2={mX} y2='0' class={style.markers} />
                            );
                        })}

                        {(showValueLeftLine || showValueRightLine) && pathLeft && mousePos && mousePos.length > 0 && <rect
                            x={this.getTextBoxX(mousePos[0], '', TEXT_BOX_PADDING_X)}
                            y={height / 2}
                            width='165' height={TOTAL_GRAPHS * 15 + 25} rx='5' ry='5' class={style.mouseHoverBox}
                        />}
                        {(showValueLeftLine || showValueRightLine) && mousePos && mousePos.length > 0
                            && <text
                                x={this.getTextBoxX(mousePos[0])}
                                y={height / 2}
                                font-size={HOVER_FONT_SIZE}
                                class={darkmode ? style.mouseHoverDarkmode : style.mouseHover}
                                style='border: #ff0000 solid 2px;'
                            >
                                {showValueLeftLine && pathLeft && pathLeft.reverse().map((path, idx) => {
                                    const hoverVal = util.isArray(hoverValLeft) && util.isFunction(hoverValLeft[idx]) ? hoverValLeft[idx] : (val) => val;
                                    const mouseData = this.getMouseData(MIN_X, MAX_X, path.dataMap, mousePos[0]);
                                    return (
                                        <>
                                            {mouseData && idx === 0 && <tspan
                                                x={this.getTextBoxX(mousePos[0])}
                                                dy='1.2em'
                                            >
                                                {transformXTick(mouseData.xVal, xTicksVal)}
                                            </tspan>}
                                            {path.d && path.dataMap && util.isNumber(mouseData.yVal) &&
                                            <tspan
                                                // x={this.getMouseData(MIN_X, MAX_X, path.dataMap, mousePos[0], 'x', { autoLimit: true, padding: 0, maxX: MAX_X })}
                                                // x={this.getCoordX(MIN_X, MAX_X, mouseData.xVal)}
                                                x={this.getTextBoxX(mousePos[0])}
                                                dy='1.2em'
                                                text-anchor='start'
                                            >
                                                <tspan class={style[`pathLeft${idx}`]}>--</tspan> {legendLeft[idx]}: {hoverVal(mouseData.yVal)} {unitLeft[idx]}
                                            </tspan>}
                                        </>
                                    );
                                })}
                                {showValueRightLine && pathRight && pathRight.reverse().map((path, idx) => {
                                    const hoverVal = util.isArray(hoverValRight) && util.isFunction(hoverValRight[idx]) ? hoverValRight[idx] : (val) => val;
                                    let mouseData;
                                    if (mousePos && mousePos.length > 0) {
                                        mouseData = this.getMouseData(MIN_X, MAX_X, path.dataMap, mousePos[0]);
                                    }
                                    return (
                                        <>
                                            {path.d && path.dataMap && util.isNumber(mouseData.yVal) &&
                                            <tspan
                                                x={this.getTextBoxX(mousePos[0], 'right')}
                                                dy='1.2em'
                                                text-anchor='end'
                                            >
                                                {legendRight[idx]}: {hoverVal(mouseData.yVal)} {unitRight[idx]} <tspan class={style[`pathRight${idx}`]}>--</tspan>
                                            </tspan>}
                                        </>
                                    );
                                })}
                            </text>}
                    </svg>

                    {showXTicks && (
                        <div class={`${style['x-axis']}`}
                            style={{
                                left: `${PADDING_LEFT}px`,
                                bottom: `0px`,
                                width: `calc(${width}px - ${PADDING_LEFT}px)`,
                                'font-size': TICKS_FONT_SIZE,
                            }}
                        >
                            {xTicksLink ? (
                                <>
                                    {X_TICKS.map(v => <div data-value={transformXTick(v, xTicksVal)} class='position-relative'><a href={`${xTicksLink}${v}`} class='stretched-link'>&nbsp;</a></div>)}
                                </>
                            ): (
                                <>
                                    {X_TICKS.map(v => <div data-value={transformXTick(v, xTicksVal)} />)}
                                </>
                            )}
                        </div>
                    )}
                </div>

                <div
                    class='position-absolute'
                    style={`top: 0; left: ${PADDING_LEFT + 20}px;`}
                >
                    {legendLeft && legendLeft.map((legend, idx) => {
                        const currentStyleIdx = idx;
                        let className = style[`legendLeft${currentStyleIdx}`];
                        if (currentStyleIdx > 9) {
                            className = style.legendOriginal;
                        }
                        return (
                            <div class='d-flex align-items-center'>
                                <span
                                    class={`${className}`}
                                />
                                <small
                                    class={style.pathText}
                                    x='35' y={15 + LEGEND_LINE_HEIGHT * idx}
                                    font-size={LEGEND_FONT_SIZE}
                                >{legend}</small>
                            </div>
                        );
                    })}
                </div>
                <div
                    class='position-absolute'
                    style={`top: 0; right: ${PADDING_RIGHT + 70}px;`}
                >
                    {legendRight && legendRight.map((legend, idx) => (
                        <div class='d-flex align-items-center'>
                            <span
                                class={`${style[`legendRight${idx}`]}`}
                                x1={width - 150} y1={10 + LEGEND_LINE_HEIGHT * idx}
                                x2={width - 130} y2={10 + LEGEND_LINE_HEIGHT * idx}
                            />
                            <small
                                class={style.pathText}
                                x={width - 125} y={15 + LEGEND_LINE_HEIGHT * idx}
                                font-size={LEGEND_FONT_SIZE}
                            >{legend}</small>
                        </div>
                    ))}
                </div>

                {showYTicksLeft && (
                    <div
                        class={style['y-axis-left']}
                        style={{
                            top: `${PADDING_TOP}px`,
                            bottom: `${PADDING_BOTTOM}px`,
                            'font-size': TICKS_FONT_SIZE,
                            width: `${yTicksLeftWidth}`,
                        }}
                    >
                        {Y_TICKS_LEFT.map(v => <div data-value={transformYTick(v, yTicksLeftVal)} />)}
                    </div>
                )}
                {showYTicksRight && (
                    <div
                        class={style['y-axis-right']}
                        style={{
                            top: `${PADDING_TOP}px`,
                            bottom: `${PADDING_BOTTOM}px`,
                            'font-size': TICKS_FONT_SIZE,
                            width: `${yTicksRightWidth}`,
                        }}
                    >
                        {Y_TICKS_RIGHT.map(v => <div data-value={transformYTick(v, yTicksRightVal)} />)}
                    </div>
                )}
            </div>
        );
    }
}

export default LineChart;
