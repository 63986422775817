import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const initialState = {};

const MAX_USERS_TO_DISPLAY = 1;

@observer
class DisplayLikesAndComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    toggleLikes = () => {
        const { showLikesList } = this.state;
        this.setState({
            showLikesList: !showLikesList,
        });
    }

    displayTeam = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
	}

    openInbox = (e, fromuser) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
    }

    render() {
        const { showLikesList } = this.state;
        const { storyStore, teamStore, workoutStore, raceStore } = this.props.stores;
        const { likes, comments, type = 'story', onTeamClick = () => {}, onUserClick = () => {} } = this.props;
		return (<>
            <div class='d-flex justify-content-between mb-0 text-overflow'>
                {likes && likes.length > 0 ? <>
                    <div class='d-flex flex-row flex-nowrap position-relative mr-3' style='overflow: auto;'>
                        <nobr>
                            <span
                                class='text-muted mr-2'
                                onClick={this.toggleLikes}
                            >
                                <small>
                                    <i class='fas fa-thumbs-up' /> {likes.length}
                                </small>
                            </span>
                            {/* {likes.slice(0, MAX_USERS_TO_DISPLAY).map((like) => {
                                let user;
                                let teams;
                                if (type === 'story') {
                                    user = storyStore.findPublicUser(like.user);
                                    teams = storyStore.findPublicTeamByMembers(like.user);
                                } else if (type === 'team') {
                                    user = teamStore.findPublicUser(like.user);
                                    teams = teamStore.findPublicTeamByMembers(like.user);
                                } else if (type === 'workout') {
                                    user = workoutStore.findPublicUser(like.user);
                                    teams = workoutStore.findPublicTeamByMembers(like.user);
                                } else if (type === 'race') {
                                    user = raceStore.findPublicUser(like.user);
                                    teams = raceStore.findPublicTeamByMembers(like.user);
                                }
                                let publicTeam;
                                if (util.isArray(teams)) {
                                    publicTeam = teams.find(e => e.public === 1);
                                }
                                const image = mu.getImage({ user, team: publicTeam, priority: 'user' });
                                const shortname = mu.displayNameShort(user);
                                const name = mu.displayName(user);
                                return (<>
                                    <span class='font-weight-normal'>
                                        <small>
                                            <nobr>
                                                {publicTeam ? <>
                                                    <a href={`/teams/public/${publicTeam.uuidv4}`} title={publicTeam.name} onClick={(e) => this.displayTeam(e, publicTeam.uuidv4)}>
                                                        {name}
                                                    </a>
                                                </> : <>
                                                    {name}
                                                </>}
                                            </nobr>
                                        </small>
                                    </span>
                                </>);
                                // return (<>
                                //     {publicTeam ? <>
                                //         <a href={`/teams/public/${publicTeam.uuidv4}`} title={publicTeam.name} onClick={(e) => onTeamClick(e, publicTeam.uuidv4)}>
                                //             <div
                                //                 class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                //                 style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''}`}
                                //             >
                                //                 {!image && <div class='' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                                //             </div>
                                //         </a>
                                //     </> : <>
                                //         <div
                                //             class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                //             style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''} opacity: 0.5;`}
                                //         >
                                //             {!image && <div class='text-muted' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                                //         </div>
                                //     </>}
                                // </>);
                            })}
                            {likes.length > MAX_USERS_TO_DISPLAY && <>
                                <span class='text-muted font-weight-normal ml-2' onClick={this.toggleLikes}>
                                    <small>
                                        <Text id='likes-comments.and' fields={{ total: likes.length - MAX_USERS_TO_DISPLAY }}>and {likes.length - MAX_USERS_TO_DISPLAY} others.</Text>
                                    </small>
                                </span>
                            </>} */}
                        </nobr>
                    </div>
                </> : <>
                    <small class='text-muted'>
                        <Text id='likes-comments.be-the-first'>Be the first to like this.</Text>
                    </small>
                </>}

                {comments && comments.length > 0 && <span class='float-right text-muted'>
                    <nobr>
                        <small>
                            <i class='fas fa-comment' /> {comments.length}
                        </small>
                    </nobr>
                </span>}
            </div>
            {showLikesList && <div class='mt-3 mb-3'>
                {likes && likes.map(like => {
                    let user;
                    let teams;
                    if (type === 'story') {
                        user = storyStore.findPublicUser(like.user);
                        teams = storyStore.findPublicTeamByMembers(like.user);
                    } else if (type === 'team') {
                        user = teamStore.findPublicUser(like.user);
                        teams = teamStore.findPublicTeamByMembers(like.user);
                    } else if (type === 'workout') {
                        user = workoutStore.findPublicUser(like.user);
                        teams = workoutStore.findPublicTeamByMembers(like.user);
                    } else if (type === 'race') {
                        user = raceStore.findPublicUser(like.user);
                        teams = raceStore.findPublicTeamByMembers(like.user);
                    }
                    let publicTeam;
                    if (util.isArray(teams)) {
                        publicTeam = teams.find(e => e.public === 1);
                    }
                    const image = mu.getImage({ user, team: publicTeam, priority: 'user' });
                    const shortname = mu.displayNameShort(user);
                    const name = mu.displayName(user, true);
                    return (<div class='d-flex flex-row position-relative font-weight-normal mb-1'>
                        {publicTeam ? <>
                            <a
                                href={`/teams/public/${publicTeam.uuidv4}`}
                                title={publicTeam.name}
                                onClick={(e) => this.displayTeam(e, publicTeam.uuidv4)}
                                class='stretched-link'
                            >
                                <div
                                    class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                    style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''}`}
                                >
                                    {!image && <div class='' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                                </div>
                            </a>
                            <div class='ml-2 '>
                                <small>
                                    {name} <span class='font-weight-lighter'>- {publicTeam.name}</span>
                                </small>
                            </div>
                        </> : <>
                            <div
                                class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''} opacity: 0.5;`}
                            >
                                {!image && <div class='text-muted' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                            </div>
                            <div class='ml-2'>
                                <small>
                                    {name}
                                </small>
                            </div>
                        </>}
                    </div>);
                })}
            </div>}
		</>);
	}
}

export default DisplayLikesAndComments;
