import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

import style from './style.css';

const initialState = {
    menu: {
		profile: false,
		main: false,
	},
	searchText: '',
};

@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDropdown = (e) => {
		const { menu } = this.state;
		let menuName = e.target.dataset.name;
		if (!menuName && e.target.parentNode) {
			menuName = e.target.parentNode.dataset.name;
		}
		if (menuName) {
			menu[menuName] = !menu[menuName];
		}
		this.setState({ menu });
	}

	toggleDarkmode = (e) => {
        const { appState } = this.props.stores;
		appState.toggleDarkmode();
		this.toggleDropdown(e);
	}

	toggleViewmode = (e) => {
        const { appState } = this.props.stores;
		let mode = e.target.dataset.mode;
		if (!mode && e.target.parentNode) {
			mode = e.target.parentNode.dataset.mode;
		}
		appState.toggleViewmode(mode);
		this.toggleDropdown(e);
	}

	reloadApp = () => {
		window.location.reload(true);
	}

	changeLanguage = (e) => {
		const lang = e.target.value;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		userStore.setLanguage({ email: user.email, language: lang });
	}

	search = () => {
		const { searchText } = this.state;
		route(`/search/${searchText}`);
	}

	toggleDashboard = () => {
		const { appState } = this.props.stores;
		appState.toggleDashboard();
	}

	scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

	toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

	chooseTeam = () => {
		const { appState } = this.props.stores;
        appState.openDrawer('chooseTeam', {});
	}

	addContent = () => {
		const { appState } = this.props.stores;
        appState.openDrawer('addContent', {});
	}

	userMenu = () => {
		const { appState, userStore, messageStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
		const { unread: unreadMessages = 0 } = messageStore;

        appState.openDrawer('userMenu', { height: drawerHeightMedium, counters: {
			inbox: unreadMessages,
			notifications: unread,
		}});
	}

	// loadFrontpage = () => {
	// 	const { appState, userStore, storyStore } = this.props.stores;
    //     await appState.getFingerprint();
    //     await userStore.getInfo();
	// 	await userStore.getNotifications();
	// 	await storyStore.loadPublicFeed({ limit: 15, offset: 0 });
	// }

    render() {
		const { menu, searchText } = this.state;
		const { scrolledDown } = this.props;
        const { appState, userStore, messageStore } = this.props.stores;
        const { darkmode, viewmode, path } = appState;
		const { allUsers, isAdmin, user, team, notifications = [], currentEmail, simulatedEmail } = userStore;

		const image = mu.getImage({ user, team });
		const displayNameShort = mu.displayNameShort(user);
		const displayName = mu.displayName(user);

		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
		const { language = 'en', toggleFeature = {} } = user;
		const { qpaws } = toggleFeature;
		// const qpaws = 0;
		const { unread: unreadMessages = 0 } = messageStore;
		const totalUnread = unread + unreadMessages;
		const linkTextClass = darkmode ? 'text-white' : 'text-dark';

		// Find servername and display it
		const serverName = window.location.hostname;
		let version = serverName.match(/(dev)/) ? 'beta' : null;
		// version = serverName.match(/(localhost|127.0.0.1)/) ? 'dev' : null;

		if (path === '/app-races/10/tracking') {
			return '';
		}

		const regexUserProfile = /^\/users\/profile/;
		const regexWorkouts = /^\/workouts/;
		const regexDogs = /^\/dogs/;
		const regexVaccines = /^\/vaccines/;
		const regexWorkoutTracking = /^\/workouts\/tracking/;

		if (regexWorkoutTracking.test(path)) {
			return <></>;
		}

		if (regexUserProfile.test(path) || regexWorkouts.test(path) || regexDogs.test(path) || regexVaccines.test(path)) {
			const { user, isAdmin } = userStore;
	        const team = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
			const displayName = team && team.name ? team.name : mu.displayName(user);

			return (
				<nav
					class={`navbar navbar-expand-xl navbar-themed bg-light fixed-top py-0 ${scrolledDown > 0 ? style.scrolledDown : style.scrolledUp}`}
					style={`height: 55px;`}
				>
					<div class='d-flex justify-content-between align-items-center w-100 mt-2'>
						<div onClick={this.chooseTeam}>
							<h4>{displayName} <i class='fa-solid fa-angle-down' style='font-size: 0.8em;' /></h4>
						</div>

						<div class='d-flex justify-content-between' style='font-size: 23px;'>
							<span class='mr-3'>
								<a href='/search' class={`${path.match(/\/search/) ? 'text-primary' : linkTextClass}`}>
									<i class='fas fa-search mr-1' />
								</a>
							</span>

							<div class='mr-3' onClick={this.addContent}>
								<i class='fa-regular fa-square-plus' />
							</div>

							<div class='position-relative' onClick={this.userMenu}>
								<i class='fa-solid fa-bars' />
								{totalUnread > 0 && <>
									<span
										class='badge badge-pill badge-danger position-absolute'
										style='font-size: 0.5em; right:-5px; top: 0px; padding: 2px 5px 3px;'
									>{totalUnread > 9 ? '9+' : totalUnread}</span>
								</>}
							</div>

						</div>
					</div>
					{isAdmin && simulatedEmail && <div class='position-absolute badge badge-danger' style='top: 0px; left: 50px;'>
						<a href='/admin/' class='text-white'>
							<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedEmail})
						</a>
					</div>}
				</nav>
			);
		}

		return (
			<nav
				class={`navbar navbar-expand-xl navbar-themed bg-light fixed-top py-0 ${scrolledDown > 0 ? style.scrolledDown : style.scrolledUp}`}
				style={`height: 55px;`}
			>
				<a
					class='navbar-brand'
					href='/'
					data-path={path}
					onClick={this.scrollToTop}
				>
					{darkmode ? <>
						<img
							src={`https://themusher.app/assets/logo-qpaws-horizontal-dark.png`}
							height='45'
							class='position-absolute'
							style='top: 8px; left: 10px;'
						/>
					</> : <>
						<img
							src={`https://themusher.app/assets/logo-qpaws-horizontal-light.png`}
							height='45'
							class='position-absolute'
							style='top: 8px; left: 10px;'
						/>
					</>}
				</a>
				{version && <div class='position-absolute' style='top: 38px; left: 131px; font-size: 12px; color: red; font-weight: 700;'>
					{version}
				</div>}

				<div class='d-flex pt-0 ml-auto mr-0' style='font-size: 23px;'>

					<span class='mt-2 ml-3'>
						<a href='/search' class={`${path.match(/\/search/) ? 'text-primary' : linkTextClass}`}>
							<i class='fas fa-search mr-1' />
						</a>
					</span>

					<span onClick={this.addContent} class={`mt-2 ml-3 ${linkTextClass}`}>
						<i class='fa-regular fa-square-plus' />
					</span>

					{/* <span class='mt-2 ml-3 position-relative' >
						<a href='/users/notifications' class={`${path.match(/\/users\/notifications/) ? 'text-primary' : linkTextClass}`}>
							<i class='fas fa-bell mr-1' />
							{unread > 0 && <>
								<span
									class='badge badge-pill badge-danger position-absolute'
									style='font-size: 0.5em; right: -5px; top: -4px; padding: 2px 5px 3px;'
								>{unread > 9 ? '9+' : unread}</span>
							</>}
						</a>
					</span> */}

					{/* <span class='mt-2 ml-3 position-relative'>
						<a href='/inbox' class={`${path.match(/\/inbox/) ? 'text-primary' : linkTextClass}`}>
							<i class='fas fa-paper-plane mr-1' />
							{unreadMessages > 0 && <>
								<span
									class='badge badge-pill badge-danger position-absolute'
									style='font-size: 0.5em; right: -5px; top: -4px; padding: 2px 5px 3px;'
								>{unreadMessages > 9 ? '9+' : unreadMessages}</span>
							</>}
						</a>
					</span> */}

					<div class='ml-3 mt-2 position-relative' onClick={this.userMenu}>
						<i class='fa-solid fa-bars' />
						{totalUnread > 0 && <>
							<span
								class='badge badge-pill badge-danger position-absolute'
								style='font-size: 0.5em; right:-5px; top: 0px; padding: 2px 5px 3px;'
							>{totalUnread > 9 ? '9+' : totalUnread}</span>
						</>}
					</div>
				</div>

				{isAdmin && simulatedEmail && <div class='position-absolute badge badge-danger' style='top: 0px; left: 50px;'>
					<a href='/admin/' class='text-white'>
						<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedEmail})
					</a>
				</div>}

			</nav>
		);
	}
}

export default Navigation;
