import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class PaypalStore extends StoreModel {
    constructor() {
        super('paypal', {
            namePlural: 'paypals',
            sort: 'createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/paypal/',
                    params: {
                        limit: 15,
                        sort: 'createdDate',
                    },
                },
                load: {
                    url: '/api/paypal/',
                    params: {},
                },
                save: {
                    url: '/api/paypal/',
                    params: {},
                },
            },
        });
    }

    @observable paypal = {};

    @observable paypals = [];

    async subscriptionCreate({ planId }) {
        const response = await util.fetchApi(`/api/paypal/subscription-create`, { publish: true, method: 'GET' }, { planId });
        console.log(response);
        switch (response.status) {
            case 200:
                return response;
        }
    }

    async subscriptionCancel({ planId }) {
        const response = await util.fetchApi(`/api/paypal/subscription-cancel/${planId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
        }
    }

    async subscriptionDetail({ id }) {
        const response = await util.fetchApi(`/api/paypal/subscription-detail/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
        }
    }

    // async subscriptionUpdate({ id, user }) {
    //     const response = await util.fetchApi(`/api/paypal/subscription-update`, { publish: true, method: 'GET' }, { user });
    //     switch (response.status) {
    //         case 202:
    //             return response;
    //     }
    // }

    // async subscriptionListTransactions({ id, user }) {
    //     const response = await util.fetchApi(`/api/paypal/subscription-list-transactions`, { publish: true, method: 'GET' }, { user });
    //     switch (response.status) {
    //         case 202:
    //             return response;
    //     }
    // }

}

const store = new PaypalStore();
export default store;
