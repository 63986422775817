import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

@observer
class WidgetPrefix extends Component {
    render() {
        const { appState } = this.props.stores;
        const { darkmode } = appState;
        const {
            children,
        } = this.props;
        return (<>
            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-0 px-3'>
                <span class='font-weight-bold'>
                    {children}
                </span>
            </div>
        </>);
    }
}

export default WidgetPrefix;
