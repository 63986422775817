import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class ProfileHeadV1 extends Component {
    onDogClick = (e, dogId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicDog', { dogId, height: drawerHeightMedium });
	}

    onWorkoutClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('workoutDetail', {
            id: parseInt(workoutId, 10),
            onLinkClick: this.toggleDrawer,
            onDogClick: this.onDogClick,
            height: drawerHeightMedium,
            marginTop: 0,
            showBackButton: false,
        });
	}

    onUserClick = (e, userid) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onFavoritesClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('profileFavorites', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            marginTop: 0,
            showBackButton: false,
            teamId: currentTeam.uuidv4,
        });
    }

    onPostsClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('publicStoryList', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            marginTop: 0,
            showBackButton: false,
            teamId: currentTeam.uuidv4,
        });
    }

    onFollowersClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowers', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            marginTop: 0,
            showBackButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 2,
        });
    }

    onFollowingClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowing', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            marginTop: 0,
            showBackButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 2,
        });
    }

    editTeamField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editTeam').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editTeam', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    editUserField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editUser').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editUser', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    goToUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { url } = e.target.closest('button').dataset;
        route(url);
    }

    render() {
        const { userStore, teamStore, appState, workoutStore } = this.props.stores;
        const { darkmode } = appState;
        const team = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const teamImage = mu.getImage({ team });
        const headerImage = mu.getImage({ team, field: 'imageHeader', size: 's3XXLargeLink', fallback: './assets/homerun-race-logo.png' });

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'}>
            <div class='mt-2 d-flex justify-content-center rounded-lg'>
                <div
                    class='w-100 position-relative editTeam'
                    style={`
                        height: 25vh;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-color: #a0a0a0; ${headerImage ? `background-image: url("${headerImage}");` : ''}
                        width: 100% !important;
                        border-radius: 10px !important;
                    `}
                    onClick={this.editTeamField}
                    data-field='imageHeader'
                    data-type='image'
                    data-title='Top image'
                    data-id={team.id}
                >
                    {/* <div class='position-absolute' style={`top: 5px; left: 10px; z-index: 1000;`}>
                        <button
                            class='btn btn-sm btn-primary'
                            type='button'
                            onClick={this.goToUrl}
                            data-url={`/teams/edit/${team.id}`}
                        >
                            <i class='fas fa-edit' /> <Text id='teams.edit'>Endre</Text>
                        </button>
                        <button
                            class='btn btn-sm btn-primary ml-2'
                            type='button'
                            onClick={this.goToUrl}
                            data-url={`/teams/${team.id}`}
                        >
                            <i class='fas fa-dog' /> <Text id='teams.detail'>Detail</Text>
                        </button>
                    </div> */}
                </div>
            </div>
            <div class='row mt-2'>
                <div
                    class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'
                    style='top: -60px; height: 100px;'
                >
                    <div class='d-flex position-relative align-items-end' style='padding-left: 30px;'>
                        <div class='text-center editTeam position-absolute'
                            style='top: 0px; left: 20px;'
                            onClick={this.editTeamField}
                            data-field='images'
                            data-type='image'
                            data-title='Team Image'
                            data-id={team.id}
                        >
                            <div
                                class={`text-center border rounded-circle imageRounded imageRoundedMediumPlus bg-light ${darkmode ? 'border-darkmode' : 'border-lightmode'}`}
                                style={`${teamImage ? `
                                    background-image: url("${teamImage}");
                                    background-size: cover;
                                ` : ''}`}>
                                {!teamImage && <i class='fas fa-dog text-muted mt-4 ml-2' style='font-size: 45px;' />}
                            </div>
                        </div>
                        <div
                            class='flex-grow-1 pl-4 text-overflow'
                            style='
                                line-height: 1.2em;
                                margin-top: 55px;
                                margin-left: 80px;
                            '
                        >
                            <div class='d-flex justify-content-between pl-2'>
                                <div class='profileSmallName'>
                                    {user.firstname} {user.lastname}
                                </div>
                            </div>
                            <div class='d-flex justify-content-between'>
                                <div class='text-center' onClick={this.onFavoritesClick}>
                                    <span class='profileSmallHeader'><Text id='users.favorites'>Favorites</Text></span><br />
                                    <span class='profileSmallNumbers'>{team.totalPosts || 0}</span>
                                </div>
                                <div class='text-center ml-1' onClick={this.onFollowersClick}>
                                    <span class='profileSmallHeader'><Text id='users.total-followers'>Followers</Text></span><br />
                                    <span class='profileSmallNumbers'>{team.followers ? team.followers.length : 0}</span>
                                </div>
                                <div class='text-center ml-1' onClick={this.onFollowingClick}>
                                    <span class='profileSmallHeader'><Text id='users.total-following'>Following</Text></span><br />
                                    <span class='profileSmallNumbers'>{user.totalFollowing || 0}</span>
                                </div>
                                <div class='text-center' onClick={this.onPostsClick}>
                                    <span class='profileSmallHeader'><Text id='users.total-posts'>Posts</Text></span><br />
                                    <span class='profileSmallNumbers'>{team.totalPosts || 0}</span>
                                </div>
                            </div>
                            <div class='d-flex justify-content-between mt-2'>
                                <button
                                    class='btn btn-block btn-sm btn-outline-primary'
                                    type='button'
                                    onClick={this.goToUrl}
                                    data-url={`/teams/edit/${team.id}`}
                                >
                                    <i class='fas fa-edit' /> <Text id='teams.edit-profile'>Edit profile</Text>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Widget>);
    }
}

export default ProfileHeadV1;
