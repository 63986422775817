import { h, Component } from 'preact';

class Refresh extends Component {
    render() {
        return (
            <div class='fixed-top text-center' style='padding-top: 90px;'>
                <div class='spinner-border text-primary' role='status'>
                    <span class='sr-only'>Loading...</span>
                </div>
            </div>
        );
    }
}

export default Refresh;

