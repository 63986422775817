import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';

import InboxMessages from '../../components/inbox/messages';
import ScrollIntoView from '../../components/inbox/scrollIntoView';

import Images from '../../components/form/images';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_BOTTOM = '100px';//mu.marginBottom();

const initialState = {};

@observer
class InboxDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {
                body: '',
                images: [],
            },
            sendButtonEnabled: true,
            limit: 10,
            offset: 0,
            ...initialState,
        };
        this.inputRef = null;
        this.containerRef = null;
    }

    loadMessages = async ({ limit, offset }) => {
        const { fromuser } = this.props;
		this.setState({ isLoading: true });
		const { messageStore, userStore } = this.props.stores;
        await userStore.getNotifications();
        await messageStore.getUnReadMessages();
		await messageStore.loadConversation({ to: fromuser, limit, offset });
		this.setState({ isLoading: false });
        this.markAsRead();
	}

    checkForNewMessages = async () => {
        const { limit, offset } = this.state;
        const { fromuser } = this.props;
		const { messageStore, userStore } = this.props.stores;
        const { messages } = messageStore;
        const message = messages[messages.length - 1];
        const cutoffDate = message.sentDate;
        // console.log('message', message, { cutoffDate });
		await messageStore.checkForNewMessages({ to: fromuser, limit, offset: 0, cutoffDate });
    }

    getAiResponse = () => {
        // console.log('getAiResponse');
        this.checkAiResponseTimer = setTimeout(() => this.doGetAiResponse(), 2000);
    }

    doGetAiResponse = async () => {
        // console.log('doGetAiResponse');
        this.setState({ waitingForAiResponse: true });
        await this.checkForNewMessages();
        const { messageStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { messages } = messageStore;
        const message = messages[messages.length - 1];
        if (message && message.fromUser === user.uuidv4) {
            this.getAiResponse();
        } else {
            this.setState({ waitingForAiResponse: false });
        }
    }


    setLoadMore = () => {
        const { messageStore } = this.props.stores;
        messageStore.setLoadMore(() => {
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset });
            this.loadMessages({ limit, offset });
        });
    }

    unsetLoadMore = () => {
        const { messageStore } = this.props.stores;
        messageStore.setLoadMore(() => {});
    }

    loadMore = () => {
        const { messageStore } = this.props.stores;
        messageStore.loadMore();
    }

    markAsRead = () => {
        const { fromuser } = this.props;
        const { messageStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { unread } = messageStore;
        if (unread > 0) {
            this.markAsReadTimer = setTimeout(() => messageStore.markAsRead({ to: fromuser, user }), 2000);
        }
    }

    sendMessage = async () => {
        const { fromuser } = this.props;
        const { message, sendButtonEnabled } = this.state;
        if (!sendButtonEnabled) {
            return false;
        }
        this.setState({ sendButtonEnabled: false });
        const { messageStore } = this.props.stores;
        const { conversationUser } = messageStore;
        if (conversationUser && conversationUser.isAiChatBot) {
            this.setState({ waitingForAiResponse: true });
        }
		await messageStore.sendMessage({ message, to: fromuser });
        this.containerRef.scrollTop = this.containerRef.scrollHeight;
        this.setState({
            showAddImage: false,
            message: {
                body: '',
                images: [],
            },
            sendButtonEnabled: true,
        }, () => {
            mu.resizeTextarea(this.inputRef);
        });
        if (conversationUser && conversationUser.isAiChatBot) {
            this.getAiResponse();
        }
    }

    addImage = () => {
        const { showAddImage } = this.state;
        this.setState({
            showAddImage: !showAddImage,
        });
    }

    addImages = ({ value }) => {
        // updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        const { message } = this.state;
        message.images.push(...value);
        this.setState({ message });
    }

    beforeImagesUploaded = () => {
        this.setState({ sendButtonEnabled: false });
    }

    afterImagesUploaded = () => {
        this.setState({ sendButtonEnabled: true });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    componentWillMount() {
        const { limit, offset } = this.state;

		this.loadMessages({ limit, offset });
        this.setLoadMore();
	}

    componentWillUnmount() {
        const { appState } = this.props.stores;
        appState.setSwipeRight();
        appState.setSwipeRightIndicate();
        this.unsetLoadMore();
    }

    componentDidMount() {
        const { back = this.back, backIndicate = this.backIndicate } = this.props;
        const { appState } = this.props.stores;
        appState.setSwipeRight(() => {
            appState.setSwipeRight();
            back();
        });
        appState.setSwipeRightIndicate((e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff }) => {
            backIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
        });
    }

    backIndicate = (e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff }) => {
        this.setState({ marginLeft: absXDiff });
        // console.log('backIndicate', { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff })
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    render() {
        const { message, showAddImage, marginLeft, sendButtonEnabled, offset, waitingForAiResponse } = this.state;
        const { fromuser, marginBottom, marginTop, headerMargin, showBack = true } = this.props;

        const { messageStore, appState } = this.props.stores;
        const { darkmode } = appState;
        const { conversationUser: user = {}, messages = [] } = messageStore;
        const filteredMessages = messages.filter(e => fromuser === e.user || fromuser === e.fromUser);

        const shortname = mu.displayNameShort(user);
        const name = mu.displayName(user, true);
        const userImage = user.image || (user.images ? user.images[0] : undefined) || user.teamImage;
        const imageHeader = user.teamImageHeader;
        const teamName = user.teamName;
        const teamUuidv4 = user.teamUuidv4;

        return (<>
            {marginLeft > 0 && <div class='position-absolute h-100 w-25 d-flex align-items-center'>
                <div class='text-muted'>
                    <div class='display-4 align-content-center'>
                        <i class='fas fa-angle-double-right' />
                    </div>
                    <Text id='inbox.go-back'>Go back</Text>
                </div>
            </div>}

			<div
                class={`container-fluid position-relative bg-${darkmode ? 'darkmode' : 'lightmode'}`}
                style={`margin-bottom:  ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP}; margin-left: ${marginLeft}px; transition: .2s;`}
                ref={c => this.containerRef = c}
            >
                <div
                    class='row fixed-top bg-light'
                    style={`
                        margin-top: ${headerMargin || marginTop || MARGIN_TOP};
                        border-radius: 20px 20px 0 0 !important;
                        border-top-width: 5px !important;
                    `}
                >
                    <div
                        class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mx-2'
                        // style={`${imageHeader ? `background-image: url("${imageHeader.s3SmallLink}"); background-size: cover;` : ''} background-position: 50% 50%;`}
                    >
                        {showBack && <button onClick={this.back} class='p-0 btn btn-link float-left mr-3' style='font-size: 2.8em; line-height: 1.0em;'><i class='fas fa-angle-left' /></button>}

                        <div class='d-flex flex-row flex-nowrap position-relative' style='overflow: auto;'>
                            <div
                                class={`text-center border rounded-circle imageRounded imageRoundedSmall pt-0`}
                                style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                            >
                                {!userImage && <span class='font-weight-lighter' style='font-size: 20px;'>{shortname}</span>}
                            </div>
                            <div class='flex-grow-1 pl-3 pt-0'>
                                <div class='text-overflow'>
                                    <strong>{name}</strong>
                                </div>
                                <div class='text-overflow'>
                                    {user.place ? `${user.place}, ` : ''} {user.country}
                                </div>
                                {teamName && <div class='text-overflow'>
                                    <a href={`/teams/public/${teamUuidv4}`}>{teamName}</a>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div class='row' style={`margin-top: 110px;`}>
                    {messages && messages.length >= 10 && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <button type='button' class='btn btn-block btn-outline-secondary rounded-pill' onClick={this.loadMore}>
                                Load older messages...
                            </button>
                        </div>
                    </>}

                    {filteredMessages && filteredMessages.map((message, idx) => <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <InboxMessages stores={this.props.stores} message={message} messageUser={user}  />
                        </div>
                        {(offset === 0 && filteredMessages.length - 1 === idx) && <ScrollIntoView />}
                    </>)}
                    {!filteredMessages || filteredMessages?.length === 0 && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <div class='mb-5 py-3 text-center'>
                                <div class='display-1 text-muted'>
                                    <i class='far fa-comments' />
                                </div>
                                <h3>
                                    <Text id='inbox.write-message'>Write the first message 😃</Text>
                                </h3>
                                <span class='text-muted'>
                                    <Text id='inbox.write-message-help'>Use the message field at the bottom of the page.</Text><br />
                                </span>
                            </div>
                        </div>
                    </>}

                    {waitingForAiResponse ? <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-1 pl-4'>
                            <div class='text-muted' style='font-size: 2.0em;'>
                                <i class="fa-regular fa-ellipsis fa-bounce"></i>
                            </div>
                        </div>
                    </> : <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <button type='button' class='btn btn-block btn-outline-secondary rounded-pill' onClick={this.checkForNewMessages}>
                                Check for new messages...
                            </button>
                        </div>
                    </>}


                </div>

                <div class='fixed-bottom w-100 px-1 py-1 bg-light' style={`margin-bottom: 0px; z-index: 100000; height: 90px;`}>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-0 pb-2'>

                        <div class='d-flex flex-row flex-nowrap position-relative' style='overflow: auto;'>
                            <div class='flex-grow-1 pb-2'>
                                <label class='sr-only' for='inboxSearch'><Text id='inbox.search'>Search</Text></label>

                                <div class='border rounded rounded-lg position-relative'>
                                    <Localizer>
                                        <textarea
                                            class='form-control-plaintext px-2'
                                            onInput={linkState(this, 'message.body')}
                                            onKeyUp={mu.resizeTextarea}
                                            onFocus={mu.resizeTextarea}
                                            style='height: 2.3em;'
                                            value={message.body}
                                            ref={c => this.inputRef = c}
                                        />
                                    </Localizer>
                                    <div class='position-absolute' style='bottom: 8px; right: 10px; font-size: 1.2em;'>
                                        <i class='fas fa-image pointerCursor'
                                            onClick={this.addImage}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div class='align-self-start ml-1'>
                                <button
                                    type='button'
                                    class='btn btn-primary mb-2'
                                    onClick={this.sendMessage}
                                    disabled={!sendButtonEnabled}
                                ><i class='fas fa-paper-plane' /></button>
                            </div>
                        </div>
                        {showAddImage && <>
                            <div class='col-12 text-center mt-2'>
                                <div class='container-fluid'>
                                    <div class='row'>
                                        {util.isArray(message.images) && message.images.map(img => (
                                            <div class='col-3 p-1'>
                                                <img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                                <button class='btn btn-sm btn-link'
                                                    onClick={this.removeImage}
                                                    data-name={img.name}
                                                ><i class='fas fa-trash' /> Del</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Images object={message} updateField={this.addImages} before={this.beforeImagesUploaded} after={this.afterImagesUploaded} autoOpen={true} stores={this.props.stores} />
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>);
    }
}

export default InboxDetail;
