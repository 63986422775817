import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route, Router } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import PubSub, { topics } from '../../lib/pubsub';
import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import ImageScroller from '../../components/imageScroller';
import StoryPublicFeed from '../../components/story/publicFeed';
import Story from '../../components/story/publicStory';

const initialState = {};

@observer
class StoryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    async loadStory(props = this.props) {
		this.setState({ isLoading: true });
        const { story } = props;
		const { storyStore } = this.props.stores;
        storyStore.loadPublicStory({ id: story });
		this.setState({ isLoading: false });
	}

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

	componentDidMount() {
        const { back = this.back } = this.props;
        const { appState } = this.props.stores;
        this.loadStory();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.story !== this.props.story) {
            this.loadStory(nextProps);
        }
    }

	render() {
		const { storyStore, appState } = this.props.stores;
        const { publicStory = {} } = storyStore;
        const { darkmode } = appState;
        const { marginTop, marginBottom } = this.props;


		return (<>
			<div class={`container-fluid pb-5 pt-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <Story
                    stores={this.props.stores}
                    story={publicStory}
                    key={`story-detail-${publicStory.id}`}
                    // onTeamClick={this.onTeamClick}
                    // onUserClick={this.onUserClick}
                />
            </div>
		</>);
	}
}

export default StoryDetail;
