import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import Gauge from '../../components/gauge';
import WorkoutLoadIndexGraph from '../../components/workout/loadIndexGraph';

import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';
import Widget from '../../components/widget/index';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; // mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);

const MAX_LOADINDEX = mu.getMaxLoadIndex();

@observer
class WorkoutWorkLoadDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore, workoutStore } = this.props.stores;
        const { types, equipments, language = 'en' } = userStore;
        const { darkmode, isCordova } = appState;
        const { workout } = workoutStore;
        const { gpxInfo } = workout;
        const { weightedTotalKcal = 0, loadIndex = 0 } = gpxInfo;

        const team = userStore.getCurrentTeam();
        const currentTeam = userStore.getCurrentTeam();

        const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        const teamType = userStore.findTeamType({ type: team.type, language });

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead
                        stores={this.props.stores}
                        title={<Text id='workout.workout-elevation-detail'>Workout Elevation</Text>}
                        // right={<i class='fa-solid fa-pen-to-square' />}
                        // onRightClick={this.onEditClick}
                        // right2={<i class='fa-solid fa-upload' />}
                        // onRight2Click={this.exportWorkout}
                    />
                </Localizer>
                {/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} p-3`} paddingBottom={0}>

                    {/* <Widget stores={this.props.stores} {...this.props} classNamesPadding={'pt-0 pb-3'} header={`Load Index`} onClick={this.onClick}> */}
                        {loadIndex > 0 && <div class='row mt-4'>
                            <div class='col text-center'>
                                <Gauge key={`gauge-${loadIndex}`}
                                    radius={100}
                                    showPercentText={true}
                                    color={mu.getLoadIndexColor(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
                                    percent={Math.floor(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
                                    completed={mu.getLoadIndexText(util.normalizeBetween(loadIndex, 0, MAX_LOADINDEX, 0, 100))}
                                    prefix=''
                                />
                                <div>
                                    {teamType && <>
                                        <a href={`/settings/team/${workout.team}`} onClick={this.openTeamSettings}>
                                            <span class='badge badge-pill badge-secondary'>{teamType}</span>
                                        </a>
                                    </>}
                                    {teamAmbition && <>
                                        <a href={`/settings/team/${workout.team}`} onClick={this.openTeamSettings}>
                                            <span class='badge badge-pill badge-secondary ml-2'>{teamAmbition}</span>
                                        </a>
                                    </>}
                                </div>

                                {workout.stravaId && !gpxInfo.reimported && <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-link text-muted'
                                        style='font-size: 0.8em;'
                                        onClick={this.reimportStravaWorkout}
                                    >
                                        <i class='fas fa-sync' /> <Text id='workouts.reimport-strava'>Reimport Strava data</Text>
                                    </button>
                                </>}
                                {workout.garminId && !gpxInfo.reimported && <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-link text-muted'
                                        style='font-size: 0.8em;'
                                        onClick={this.reimportGarminWorkout}
                                    >
                                        <i class='fas fa-sync' /> <Text id='workouts.reimport-garmin'>Reimport Garmin data</Text>
                                    </button>
                                </>}

                                {/* {isAdmin && showAdminInfo && <div>
                                    <small>
                                        <span class='badge badge-secondary mr-2'>loadIndex: {gpxInfo.loadIndex}</span>
                                        <span class='badge badge-secondary mr-2'>Max loadIndex: {MAX_LOADINDEX}</span>
                                        <span class='badge badge-secondary mr-2'>totalKcalRelated: {util.format(gpxInfo.totalKcalRelated, 1)}</span>
                                        <span class='badge badge-secondary mr-2'>typeFactor: {gpxInfo.typeFactor}</span>
                                        <span class='badge badge-secondary mr-2'>ambitionFactor: {gpxInfo.ambitionFactor}</span>
                                        <span class='badge badge-secondary mr-2'>normalSpeed: {gpxInfo.normalSpeed}</span>
                                        <span class='badge badge-secondary mr-2'>speedFactor: {util.format(gpxInfo.speedFactor, 2)}</span>
                                        <span class='badge badge-secondary mr-2'>normalDuration: {gpxInfo.normalDuration}</span>
                                        <span class='badge badge-secondary mr-2'>durationFactor: {util.format(gpxInfo.durationFactor, 2)}</span>
                                        <span class='badge badge-secondary mr-2'>intensityFactor: {util.format(gpxInfo.intensityFactor, 2)}</span>
                                        <div>
                                            <small class='text-muted'>
                                                loadIndex = Math.floor(totalKcalRelated * durationFactor * speedFactor * typeFactor * ambitionFactor);
                                            </small>
                                        </div>
                                    </small>
                                </div>} */}
                            </div>
                        </div>}
                    {/* </Widget> */}

                    {/* <Widget stores={this.props.stores} {...this.props} classNamesPadding={'pt-0 pb-3'} header={`Load Index`} onClick={this.onClick}> */}
                        {(util.checkNested(workout, 'gpxInfo', 'elevations') || util.checkNested(workout, 'gpx', 'gpxInfo', 'elevations')) ? <>
                            <WorkoutLoadIndexGraph stores={this.props.stores} key={`workout-loadindex-${workout.id}`} workout={workout} />
                        </> : <>
                            <div class='text-center text-muted'>
                                <div class='display-1'>
                                    <i class='fas fa-chart-area' />
                                </div>
                                <h3 class='font-weight-lighter'>
                                    <Text id='workout.no-elevation-data'>No elevation data. If you use a GPS to track your workouts you will get a nice graph here and more correct analysis of the energy usage.</Text>
                                </h3>
                            </div>
                        </>}
                    {/* </Widget> */}

                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutWorkLoadDetail;
