import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

@observer
class Musher extends Component {
    render() {
        const { musher } = this.props;
		// const { userStore, dogStore } = this.props.stores;
        // const workoutSummary = dogStore.findWorkoutSummary(dog.id, 2020) || {};
        // // {"0":{"year":2020,"dog":16,"count":1,"distanceKm":9.9,"elevation":202,"duration":0,"speedAvg":0}}
        // const dogVaccines = dogStore.findVaccineStatuses(dog.id);
        return (
            <div class='w-100 mb-3'>
                <div class='row'>
                    <div class='col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 d-flex mb-0 position-relative'>
                        {/* {JSON.stringify(dog, null, 4)} */}
                        <div class={`text-center border rounded-circle imageRounded text-muted pt-2`} style={musher.image ? `background-image: url("${musher.image.s3SmallLink}");` : ''}>
                            {/* {!dog.image && <i class='fas fa-dog mt-3' style='font-size: 40px;' />} */}
                            {!musher.image && <span class='font-weight-lighter' style='font-size: 35px;'>{util.ucfirst(musher.email, true)}</span>}
                        </div>
                        <div class='flex-grow-1 pl-3 pr-2'>
                            <span class='float-right'>
                                {/* {musher.birth && <span class='mr-2'>{util.age(musher.birth, musher.deceased)}</span>}
                                {musher.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />} */}
                            </span>
                            {/* <a class='stretched-link' href={`/dogs/${musher.id}`}> */}
                                <h5 class='mb-1' style='line-height: 1.0em;'>{musher.fname || musher.email}</h5>
                            {/* </a> */}
                            {/* <span class='font-weight-light'>{userStore.findTeam(dog.team)}</span><br /> */}

                            {/* <small class='text-muted'> */}
                                {/* {dog.chipId && <span class='ml-0'><i class='fas fa-microchip' /> {dog.chipId}</span>} */}
                                {/* {dog.history && <span class='ml-0'><i class='fas fa-file-signature' /> {dog.history.length}</span>}
                                {dog.images && <span class='ml-2'><i class='fas fa-image' /> {dog.images.length}</span>}

                                {workoutSummary && workoutSummary.distanceKm > 0 && <span class='ml-2'><i class='fas fa-road' /> {util.format(workoutSummary.distanceKm, 0)}km</span>}
                                {workoutSummary && workoutSummary.elevation > 0 && <span class='ml-2'><i class='fas fa-mountain' /> {util.format(workoutSummary.elevation, 0)}m</span>}
                                {workoutSummary && workoutSummary.duration > 0 && <span class='ml-2'><i class='fas fa-clock' /> {util.secToHms(workoutSummary.duration, true)}</span>}
                                {workoutSummary && workoutSummary.speedAvg > 0 && <span class='ml-2'><i class='fas fa-tachometer-alt' /> {util.format(workoutSummary.speedAvg, 1)}km/t</span>}
                                <div>
                                    {dogVaccines && dogVaccines.map(v => (
                                        <span class={`badge badge-success mr-2`} title={util.isoDate(v.endDate, false, false, true)}>{v.vaccineType}</span>
                                    ))}
                                </div> */}
                            {/* </small> */}
                            {/* <div class='clearfix pb-0'>
                                {dog.specialNeeds && <span class={`badge badge-warning ml-2 float-right`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                {dogStatus && <span class={`badge badge-${dogStatus.class} ml-2 float-right`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Musher;
