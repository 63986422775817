import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SCROLLDOWN_LIMIT = 500;

@observer
class Drawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: '0px',
        };
    }

    onCloseClick = (e) => {
        const { onCloseClick, drawer } = this.props;
        if (onCloseClick) {
            onCloseClick();
        } else {
            this.setState({ height: '0px' });
            setTimeout(() => {
                const { appState } = this.props.stores;
                if (drawer === 3) {
                    appState.toggleDrawer3(false);
                } else if (drawer === 2) {
                    appState.toggleDrawer2(false);
                } else {
                    appState.toggleDrawer(false);
                }
            }, 300);
        }
    }

    onScroll = (e) => {
        const { storyStore, teamStore, workoutStore } = this.props.stores;
        const { drawerComponent } = this.props;
        // Scroll to top function
        const element = e.target;
        const { scrolledDown } = this.state;
        if (element) {
            if (element.scrollTop > SCROLLDOWN_LIMIT && !scrolledDown) {
            // if (element.scrollTop > SCROLLDOWN_LIMIT) {
                this.setState({
                    scrolledDown: element.scrollTop,
                });
            } else if (element.scrollTop === 0) {
                this.setState({
                    scrolledDown: 0,
                });
            }
        }

        // Load more content function
        const { isAtBottom } = this.state;
        const marginToBottom = element.clientHeight;
        if (element.scrollTop + element.clientHeight + marginToBottom >= element.scrollHeight) {
            if (!isAtBottom) {
                this.setState({ isAtBottom: true });
                // console.log(`onScroll.isAtBottom.loadMore: ${drawerComponent}`);
                switch (drawerComponent) {
                   case 'publicTeam':
                       return teamStore.loadMore();
                   case 'publicStoryList':
                       return storyStore.loadMore();
                   case 'teamsFollowing':
                       return teamStore.loadMore();
                   case 'teamsFollowers':
                       return teamStore.loadMore();
                }
            }
        } else if (isAtBottom) {
            this.setState({ isAtBottom: false });
        }
    }

    animateHeight = (props) => {
        setTimeout(() => {
            this.doAnimateHeight(props);
        }, 1);
    }

    doAnimateHeight = (props = this.props) => {
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;

        const { height = drawerHeightSmall } = props;
        this.setState({ height });
    }

    componentDidMount() {
        this.animateHeight(this.props);
	}

    render() {
        const { height } = this.state;
        const { appState } = this.props.stores;
        const { darkmode } = appState;
        const {
            children,
            classNames = 'fixed-bottom',
            width = '100vw',
            // height = '75vh',
            drawer = 1,
            showOverlay = true,
        } = this.props;
        return (<>
            {showOverlay && <div
                class='w-100 h-100 fixed-bottom'
                style={`
                    height: calc(100vh) !important;
                    top: 0;
                    left: 0;
                    overflow-x: auto;
                    overflow-y: auto;
                    transition: .3s;
                    overscroll-behavior: contain;
                    z-index: ${1000000 + drawer * 10};
                    background-color: rgba(0,0,0,.6);
                `}
                onClick={this.onCloseClick}
                // onTouchmove={mu.captureEvents}
                onScroll={mu.captureEvents}
            />}

            <div
                class={`${classNames} ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} border rounded rounded-lg`}
                style={`
                    bottom: 0;
                    left: 0;
                    width: ${width};
                    height: ${height};
                    transition: .3s;
                    overflow: hidden;
                    z-index: ${1000000 + drawer * 10 + 1};
                    border-radius: 20px 20px 0 0 !important;
                    border-top-width: 1px !important;
                `}
                // onTouchmove={mu.captureEvents}
                onScroll={mu.captureEvents}
            >
                <button
                    type='button'
                    class='btn btn-primary float-right position-absolute'
                    style={`
                        top: 5px;
                        right: 5px;
                        z-index: ${1000000 + drawer * 10 + 3};
                        border-radius: 50%;
                    `}
                    onClick={this.onCloseClick}
                >
                    <i class='fas fa-times' />
                </button>
                <div
                    class={`h-100 overflow-auto ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    onScroll={this.onScroll}
                >
                    {children}
                </div>
            </div>
        </>);
    }
}

export default Drawer;
