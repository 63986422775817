import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

class TrackStore extends StoreModel {
    constructor() {
        super('track', {
            namePlural: 'tracks',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/tracks/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/tracks/',
                    params: {},
                },
                save: {
                    url: '/api/tracks/',
                    params: {},
                },
            },
        });
    }

    @observable track = {
        gpx: {},
    };

    @observable newTrack = {};

    @observable tracks = [];

    @observable publicTracks = [];

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('TrackStore.cleanupMemory');
        }
        this.localUpdateField('track', {});
        this.localUpdateField('tracks', []);
        this.localUpdateField('publicTracks', []);
    }

    @action
    cleanupMemoryDetail() {
        if (isDevelopment) {
            console.log('TrackStore.cleanupMemoryDetail');
        }
        this.localUpdateField('track', {});
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    // @action
    // afterLoad(response) {
    //     console.log(response);
    // }
    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.track.images)) {
            const idx = this.track.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.track.images.splice(idx, 1);
            }
        }
        const widx = this.tracks?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.tracks[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.tracks[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/tracks/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToTrack({ id, data }) {
        if (util.isArray(this.track.likes)) {
            this.track.likes.push(data);
        }
        const idx = this.tracks?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.tracks[idx].likes)) {
                this.tracks[idx].likes = [];
            }
            this.tracks[idx].likes.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        if (util.isDefined(this.track.comments)) {
            const commentIdx = this.track.comments?.findIndex(e => e.id === commentId);
            const comment = this.track.comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        const idx = this.tracks?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.tracks[idx].comments)) {
                const commentIdx = this.tracks[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.tracks[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        if (util.isDefined(this.track.comments)) {
            const commentIdx = this.track.comments?.findIndex(e => e.id === commentId);
            const comment = this.track.comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        const idx = this.tracks?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.tracks[idx].comments)) {
                const commentIdx = this.tracks[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.tracks[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    addCommentToTrack({ id, data }) {
        if (util.isArray(this.track.comments)) {
            this.track.comments.push(data);
        }
        const idx = this.tracks?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.tracks[idx].comments)) {
                this.tracks[idx].comments = [];
            }
            this.tracks[idx].comments.push(data);
        }
    }

    @action
    addCommentToTrackComment({ id, commentId, data }) {
        if (util.isDefined(this.track.comments)) {
            const commentIdx = this.track.comments?.findIndex(e => e.id === commentId);
            const comment = this.track.comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        const idx = this.tracks?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.tracks[idx].comments)) {
                const commentIdx = this.tracks[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.tracks[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    async likeTrack({ id }) {
        const response = await util.fetchApi(`/api/tracks/like/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToTrack({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeTrackComment({ id, commentId }) {
        const response = await util.fetchApi(`/api/tracks/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeTrackCommentReply({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/tracks/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToCommentReply({ id, commentId, replyId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentTrack({ id, comment }) {
        const response = await util.fetchApi(`/api/tracks/comment/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToTrack({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyTrack({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/tracks/comment/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToTrackComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadPublic($id = '', append, $opt = {}) {
        // if (!append) {
        //     this.updateOffset(0);
        // }
        let opt;
        let id;
        if (util.isObject($id)) {
            opt = $id;
            id = '';
        } else {
            id = $id;
            opt = $opt;
        }
        const url = `/api/tracks/public/`;
        const qf = opt.skipFilter ? {} : (opt.query || this.queryFilter);
        const queryFilter = id ? {} : { ...qf };

        let addData;
        if (Array.isArray(opt.addData) && opt.addData.length > 0) {
            addData = opt.addData.join(',');
        }
        const response = await util.fetchApi(`${url}${id}`, { publish: true }, util.cleanObject({
            addData,
            extendedView: this.extendedView,
            offset: opt.offset || this.offset,
            limit: opt.limit || this.limit,
            sort: opt.sort || this.sort,
            ...queryFilter,
        }));
        return this.handleApiLoadResponse(response, id, append, opt);
    }
}

const store = new TrackStore();
export default store;
