import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

@observer
class MenuStory extends Component {
	constructor(props) {
        super(props);
        this.state = {
            menuLinks: [
                {
                    hasToOwnStory: true,
                    name: <Text id='menu.story.edit'>Edit post</Text>,
                    func: this.editStory,
                    icon: 'fas fa-edit',
                },
                {
                    hasToOwnStory: true,
                    name: <Text id='menu.story.delete'>Delete post</Text>,
                    func: this.deleteStory,
                    icon: 'fas fa-trash-alt',
                },
                {
                    name: <Text id='menu.story.goto'>Go to post</Text>,
                    func: this.gotoStory,
                    icon: 'fas fa-link',
                },
                {
                    name: <Text id='menu.story.favorite'>Add to favorites</Text>,
                    func: this.favoriteStory,
                    icon: 'fa-regular fa-star',
                },
                {
                    name: <Text id='menu.story.share'>Share post</Text>,
                    func: this.shareStory,
                    icon: 'fa-solid fa-share',
                },
                {
                    name: <Text id='menu.story.report'>Report post</Text>,
                    func: this.reportStory,
                    icon: 'fas fa-flag',
                },
                {
                    hasToBeAdmin: true,
                    name: <Text id='menu.story.remove'>Remove from feed</Text>,
                    func: this.removeStory,
                    icon: 'fas fa-eraser',
                },
            ],
        };
    }

    editStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const { story } = this.props;
        appState.openDrawer2('editStory', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            story,
            setFocus: true,
        });
    }

    deleteStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { story } = this.props;
        appState.openDrawer2('deleteStory', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            story,
        });
    }

    reportStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { story } = this.props;
        appState.openDrawer2('reportStory', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            story,
        });
    }

    removeStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { story } = this.props;
        appState.openDrawer2('removeStory', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            story,
        });
    }


    gotoStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicStory', {
            id: story.id,
            height: drawerHeightMedium,
        });
    }

    favoriteStory = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        await userStore.updateUserArray({ email: user.email, field: 'favoriteStory', value: story.id });
        this.toggleDrawer();
    }

    shareStory = e => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { appState } = this.props.stores;
        const { hasShareApi } = appState;

        if (hasShareApi) {
            appState.shareUrl({
                url: `https://themusher.app/share/story/${story.uuidv4}`,
                title: story.title,
                text: story.body,
            });
        } else {
            window.open(`https://themusher.app/share/story/${story.uuidv4}`);
        }
        this.toggleDrawer();
    }

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    toggleDrawer2 = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore } = this.props.stores;
        const { darkmode } = appState;

        const { story = {} } = this.props;
        const { user: currentUser = {}, isAdmin } = userStore;
        const isSameUser = currentUser.id === story.user;

        return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3' style='font-size: 1.5em;'>
                        {menuLinks && menuLinks.map((link, idx) => {
                            if (link.hasToOwnStory && !isSameUser) return null;
                            if (link.hasToBeAdmin && !isAdmin) return null;

                            // eslint-disable-next-line react/jsx-key
                            return (<>
                                <div class={`row ${idx > 0 ? 'border-top' : ''} teamRow`}>
                                    <div class={`col-12 py-3 d-flex align-items-center position-relative`}>
                                        <div class='text-center' style='width: 55px;'>
                                            <i class={`${link.icon}`} />
                                        </div>
                                        <span class={`flex-grow-1`} style='line-height: 1.0em;'>
                                            {link.func ? <>
                                                <button
                                                    type='button'
                                                    onClick={link.func}
                                                    class={`${darkmode ? 'text-white' : 'text-dark'} btn btn-link btn-block text-left p-0`}
                                                    style='font-size: 1.0em;'
                                                >
                                                    {link.name}
                                                </button>
                                            </> : <>
                                                <a
                                                    href={link.href}
                                                    class={`${darkmode ? 'text-white' : 'text-dark'} stretched-link`}
                                                    onClick={this.toggleDrawer}
                                                >
                                                    {link.name}
                                                </a>
                                            </>}
                                        </span>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuStory;
