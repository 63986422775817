import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';


import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import WorkoutTemperatureGraph from '../../components/workout/temperatureGraph';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; // mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class WorkoutWeatherDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore, workoutStore } = this.props.stores;
        const { types, equipments, language = 'en' } = userStore;
        const { darkmode, isCordova } = appState;
        const { workout } = workoutStore;

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
				<Localizer>
					<WidgetHead
						stores={this.props.stores}
						title={<Text id='workout.workout-elevation-detail'>Workout Weather</Text>}
						// right={<i class='fa-solid fa-pen-to-square' />}
						// onRightClick={this.onEditClick}
						// right2={<i class='fa-solid fa-upload' />}
						// onRight2Click={this.exportWorkout}
					/>
				</Localizer>
				{/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} p-3`} paddingBottom={0}>
                    {/* <xmp>{JSON.stringify(workout.yr, null, 4)}</xmp> */}

                    {workout.weather}
                    {!workout.weather && <>
                        {util.format(workout.temperature, 1)}°C
                    </>}

                    {(util.checkNested(workout, 'gpxInfo', 'elevations') || util.checkNested(workout, 'gpx', 'gpxInfo', 'elevations')) ? <>
                            <WorkoutTemperatureGraph stores={this.props.stores} key={`workout-loadindex-${workout.id}`} workout={workout} />
                        </> : <>
                            <div class='text-center text-muted'>
                                <div class='display-1'>
                                    <i class='fas fa-chart-area' />
                                </div>
                                <h3 class='font-weight-lighter'>
                                    <Text id='workout.no-elevation-data'>No elevation data. If you use a GPS to track your workouts you will get a nice graph here and more correct analysis of the energy usage.</Text>
                                </h3>
                            </div>
                        </>}
                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutWeatherDetail;
