import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

import Field from '../../components/util/field';

import mu from '../../lib/musher-util';

@observer
class Dog extends Component {
    render() {
        const { dog, onDogClick = () => {} } = this.props;
		const { userStore, dogStore, appState } = this.props.stores;
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;
        const { viewmode, darkmode } = appState;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummary = dogStore.findWorkoutSummary(dog.id, seasonStart) || {};
        // {"0":{"year":2020,"dog":16,"count":1,"distanceKm":9.9,"elevation":202,"duration":0,"speedAvg":0}}
        const dogStatuses = dog.statuses ? dog.statuses.map(e => userStore.findDogstatus({ status: e, language })) : [];
        const dogVaccines = dogStore.findVaccineStatuses(dog.id);
        const dogVaccineLogStatuses = dogStore.findVaccineLogStatuses(dog.chipId);
        const dogHistoryStatuses = dogStore.findHistoryStatuses(dog.id);
        const dogStatus = userStore.findDogstatus({ status: dog.status, language });
        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        const teamNames = [];
        if (userStore.findTeam(dog.team)) {
            teamNames.push(userStore.findTeam(dog.team));
        }
        if (dog.teams) {
            dog.teams.forEach((e) => {
                if (userStore.findTeam(e)) {
                    teamNames.push(userStore.findTeam(e));
                }
            });
        }
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = dog.birth ? Math.ceil(mu.daysUntilNextBirthday(dog.birth)) : 365;

        const { url = `/dogs/${dog.id}` } = this.props;

        return (
            <div class='w-100 mb-3'>
                <div class='row'>
                    <div class='col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 d-flex mb-0 position-relative'>
                        <Field value={dog.name} darkmode={darkmode} width={600} height={100} type='dog'>
                            {/* {JSON.stringify(dog, null, 4)} */}
                            <div class={`text-center border rounded-circle imageRounded text-muted pt-2`} style={dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''}>
                                {/* {!dog.image && <i class='fas fa-dog mt-3' style='font-size: 40px;' />} */}
                                {!dog.image && <span class='font-weight-lighter' style='font-size: 35px;'>{util.ucfirst(dog.name, true)}</span>}
                            </div>
                            <div class='flex-grow-1 pl-3 pr-2'
                                style={`border-right: 4px solid ${userStore.findHarness(dog.harness, 'colorNonstop')} !important; line-height: 1.2em;`}
                            >
                                <span class='float-right'>
                                    {dogPosition && <span class={`badge badge-${dogPosition.class || 'primary'} mr-3`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>}
                                    {daysUntilBirthday < 7 && <>
                                        <span class='text-muted mr-2'>
                                            <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                        </span>
                                    </>}
                                    {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                    {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                                </span>
                                <a class='stretched-link' href={url} onClick={e => onDogClick(e, dog.uuidv4)}>
                                    <h5 class='mb-1' style='line-height: 1.0em;'>
                                        {dog.shortname || dog.name} {dog.shortname && dog.shortname !== '' && <> - <span class='font-weight-lighter'>{dog.name}</span></>}
                                        {hasBirthday && <>
                                            <i class='fas fa-birthday-cake ml-3' />
                                        </>}
                                    </h5>
                                </a>
                                <div>
                                    {teamNames && teamNames.length > 0 && <small class='font-weight-lighter'>
                                        {teamNames.map((name, idx) => <>{idx > 0 ? ', ' : ''}{name}</>)}
                                    </small>}
                                    <br />
                                </div>

                                <small class='text-muted'>
                                    {/* {dog.chipId && <span class='ml-0'><i class='fas fa-microchip' /> {dog.chipId}</span>} */}
                                    {dog.history > 0 && <span class='ml-0'><i class='fas fa-file-signature' /> {dog.history.length}</span>}
                                    {dog.images > 0 && <span class='ml-2'><i class='fas fa-image' /> {dog.images.length}</span>}

                                    {workoutSummary && workoutSummary.distanceKm > 0 && <span class='ml-2'><i class='fas fa-road' /> <Distance stores={this.props.stores} value={workoutSummary.distanceKm} /></span>}
                                    {workoutSummary && workoutSummary.elevation > 0 && <span class='ml-2'><i class='fas fa-mountain' /> <Elevation stores={this.props.stores} value={workoutSummary.elevation} /></span>}
                                    {workoutSummary && workoutSummary.duration > 0 && <span class='ml-2'><i class='fas fa-clock' /> {util.secToHms(workoutSummary.duration, true)}</span>}
                                    {workoutSummary && workoutSummary.speedAvg > 0 && <span class='ml-2'><i class='fas fa-tachometer-alt' /> <Speed stores={this.props.stores} value={workoutSummary.speedAvg} /></span>}

                                    {dog.weight > 0 && <><i class='fas fa-balance-scale ml-2' /> <Weight stores={this.props.stores} value={dog.weight} /></>}
                                    {dog.bodyScore > 0 && <><i class='fas fa-dog ml-2' /> {util.format(dog.bodyScore, 1)}</>}
                                </small>

                                {['beta', 'advanced', 'normal'].indexOf(viewmode) > -1 && <>
                                    <div>
                                        {dogVaccines && dogVaccines.map(v => (
                                            <span class={`badge badge-success mr-2`} title={util.isoDate(v.endDate, false, false, true)}>
                                                {v.vaccineType}
                                                <span class='font-weight-light ml-3'>
                                                    <i class='fas fa-calendar-check' /> {util.isoDate(v.endDate, false, false, true)}
                                                </span>
                                            </span>
                                        ))}
                                        {dogHistoryStatuses && dogHistoryStatuses.map(v => (
                                            <span class={`badge badge-warning mr-2`} title={util.isoDate(v.endDate, false, false, true)}>{v.title}</span>
                                        ))}
                                    </div>
                                </>}

                                {['beta', 'advanced'].indexOf(viewmode) > -1 && <>
                                    {dog.positions && dog.positions.lead && <div class=''>
                                        {dog.positions.lead.distanceKm > 0 && <span class='badge badge-secondary mr-2'>
                                            <span class='font-weight-lighter'><Text id='dogs.sort-lead'>Lead</Text>:</span> <Distance stores={this.props.stores} value={dog.positions.lead.distanceKm} />
                                        </span>}
                                        {dog.positions.point.distanceKm > 0 && <span class='badge badge-secondary mr-2'>
                                            <span class='font-weight-lighter'><Text id='dogs.sort-point'>Point</Text>:</span> <Distance stores={this.props.stores} value={dog.positions.point.distanceKm} />
                                        </span>}
                                        {dog.positions.team.distanceKm > 0 && <span class='badge badge-secondary mr-2'>
                                            <span class='font-weight-lighter'><Text id='dogs.sort-team'>Team</Text>:</span> <Distance stores={this.props.stores} value={dog.positions.team.distanceKm} />
                                        </span>}
                                        {dog.positions.wheel.distanceKm > 0 && <span class='badge badge-secondary mr-2'>
                                            <span class='font-weight-lighter'><Text id='dogs.sort-wheel'>Wheel</Text>:</span> <Distance stores={this.props.stores} value={dog.positions.wheel.distanceKm} />
                                        </span>}
                                    </div>}
                                </>}

                                <div class='clearfix pb-0'>
                                    {dog.specialNeeds && <span class={`badge badge-warning ml-2 mt-1 float-right`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                    {dogStatus && <span class={`badge badge-${dogStatus.class} ml-2 mt-1 float-right`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                                    {dogStatuses && dogStatuses.map(status => <>
                                        <span class={`badge badge-${status.class} ml-2 mt-1 float-right`}>
                                            {status.icon && <i class={status.icon} />} {status.lang && status.lang[language] ? status.lang[language] : status.name}
                                        </span>
                                    </>)}
                                </div>

                                {dogVaccineLogStatuses && dogVaccineLogStatuses.length > 0 && <>
                                    <div class='mt-2'>
                                        {dogVaccineLogStatuses && dogVaccineLogStatuses.filter(e => e.chipId === dog.chipId).map((vac) => {
                                            return (
                                                <span class={`badge badge-${vac.inKarens ? 'warning' : 'success'} mr-2 mb-1`}>
                                                    <i class='fas fa-user-md' /> {vac.vaccineAgens.name}
                                                    {/* <span class='font-weight-lighter ml-2'> */}
                                                        {/* <i class='fas fa-calendar-check' /> {util.isoDate(vac.vaccineAgens.durationEnd, false, false, true)} */}
                                                        {/* {vac.inKarens && <>
                                                            <br />
                                                            (karens: {util.format(vac.karensDaysLeft, 0)} days left)
                                                        </>} */}
                                                    {/* </span> */}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </>}

                            </div>
                        </Field>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dog;
