import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import DogSimpleV2 from '../../../components/dog/simple-v2';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileDogList extends Component {
    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    render() {
        const { appState, userStore } = this.props.stores;
        const { drawerLevel } = this.props;
        const { darkmode } = appState;

        const team = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const dogs = userStore.findDogsByTeam(team.id);

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead
                        stores={this.props.stores}
                        title={<Text id='dogs.all-dogs'>All dogs</Text>}
                        right={<Text id='dogs.add'>Add dog</Text>}
                        onRightClick={this.newDog}
                    />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br />

                    {dogs && dogs.map(dog => <>
                        <div
                            class={`col-6 clearfix p-0 mx-2 dogDetail`}
                            key={`dog-id-${dog.id}`}
                            data-id={dog.id}
                        >
                            <div
                                class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center position-relative px-0`}
                                style={`
                                    scroll-snap-align: start;
                                    flex-wrap: wrap;
                                `}
                                // overflow: ${imageElementScale > 1 ? 'visible !important' : 'hidden'};
                                onTouchstart={(e) => { e.stopPropagation(); }}
                                onTouchend={(e) => { e.stopPropagation(); }}
                                onTouchmove={(e) => { e.stopPropagation(); }}
                            >
                                <DogSimpleV2
                                    key={`dog-id-${dog.id}`}
                                    stores={this.props.stores}
                                    dog={dog}
                                    showAge={true}
                                    className={'w-100'}
                                    onDogClick={this.onDogClick}
                                />
                            </div>
                        </div>
                    </>)}

                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileDogList;
