import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom(false);

import Images from '../../components/form/images';
import Files from '../../components/form/files';
import Achievements from '../../components/form/achievements';
import Links from '../../components/form/links';

import ScrollIntoView from '../../components/util/scrollIntoView';

@observer
class Input extends Component {
    handleImageErrored = (e) => {
        const image = e.target;

        if (!image.dataset.retry) {
            image.dataset.retry = 0;
        }
        image.dataset.retry = parseInt(image.dataset.retry, 10) + 1;
        if (image.dataset.retry > 5) {
            return false;
        }

        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        dogStore.removeImage({
            id: dog.id,
            name: imageName,
        });
    }

    removeFile = async (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = this.props;
        const fileName = e.target.parentElement.dataset.name || e.target.dataset.name;
        dogStore.removeFile({
            id: dog.id,
            name: fileName,
        });
    }

    render() {
        const { saved, dog, type = 'text', datalist = [], min, max, step, field, title, icon, help, onInput, updateField, refName, showImages = false, showFiles = true } = this.props;
        let value = dog[field] || '';

        let maxValue = max;
        if (type === 'date') {
            if (value) {
                value = util.isoDate(dog[field], false, false, true);
            }
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    {showFiles && <>
                        <div class='container-fluid'>
                            <div class='row'>

                                {(!dog.files || dog.files?.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-file' /></div>
                                        <h3><Text id='dogs.no-files-title'>No files attached to this dog!</Text></h3>
                                        <Text id='dogs.no-files-text'>You can upload files to this dog inside edit. These files can be of any type. Ie: Certificates, test, etc.</Text>
                                    </div>
                                </>}

                                {dog.files && dog.files.map(file => (
                                    <div class='col-12 p-1'>
                                        <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                        <span class='mr-2'>{file.name}</span>
                                        <span class='font-weight-lighter mr-2'>{util.formatBytes(file.size, 1)}</span>
                                        <button class='btn btn-sm btn-link float-right p-0'
                                            onClick={this.removeFile}
                                            data-name={file.name}
                                        ><i class='fas fa-trash-alt' /> Del</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <Files saved={saved} object={dog} field={field} updateField={updateField} />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'textarea') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <textarea type='text' class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={dog.id}
                        data-field={field}
                        onInput={onInput}
                        rows={10}
                    />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (field === 'links') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Links saved={saved} stores={this.props.stores} object={dog} field={field} updateField={updateField} />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (field === 'achievements') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Achievements saved={saved} stores={this.props.stores} object={dog} field={field} updateField={updateField} />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'image') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>
                    {showImages && <>
                        <div class='container-fluid'>
                            <div class='row'>
                                {(!dog.images || dog.images?.length === 0) && <>
                                    <div class='text-center text-muted mb-3'>
                                        <div class='display-1'><i class='fas fa-images' /></div>
                                        <h3><Text id='dogs.no-images-title'>No images for this dog!</Text></h3>
                                        <Text id='dogs.no-images-text'>You can add more images to this dog. Puppy pictures, workout pictures, lazy summer pictures, etc. All is a nice way to create a better profile for this dog.</Text>
                                    </div>
                                </>}
                                {field === 'image' ? <>
                                    {dog.image && dog.image.s3SmallLink && <div class='col-3 p-1'>
                                        <img
                                            src={dog.image.s3SmallLink}
                                            alt={`May be an image of: ${mu.imageKeywords(dog.image).join(', ')}`}
                                            class='img-fluid border rounded-lg'
                                            onError={this.handleImageErrored}
                                        />
                                        <button class='btn btn-sm btn-link'
                                            onClick={this.removeImage}
                                            data-name={dog.image.name}
                                        ><i class='fas fa-trash' /> Remove</button>
                                        <span class='badge badge-success'>Main</span>
                                    </div>}
                                </> : <>
                                    {dog.images && dog.images.map(img => (
                                        <div class='col-3 p-1'>
                                            <img
                                                src={img.s3SmallLink}
                                                alt={`May be an image of: ${mu.imageKeywords(img).join(', ')}`}
                                                class='img-fluid'
                                                onError={this.handleImageErrored}
                                            />
                                            <button class='btn btn-sm btn-link'
                                                onClick={this.removeImage}
                                                data-name={img.name}
                                            ><i class='fas fa-trash' /> Remove</button>
                                        </div>
                                    ))}
                                </>}

                            </div>
                        </div>
                    </>}

                    {field === 'image' ? <>
                        <Images saved={saved} object={dog} field={field} updateField={updateField} autoOpen={false} addToImages={false} stores={this.props.stores} key={`profile-image-${field}`} />
                    </> : <>
                        <Images saved={saved} object={dog} field={field} updateField={updateField} autoOpen={false} stores={this.props.stores} key={`image-${field}`} />
                    </>}

                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={dog.id}
                        data-field={field}
                        onInput={onInput}
                        // onInput={e => console.log(e)}
                        // onChange={e => console.log(e)}
                    />
                    <datalist id={`list-${field}`}>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'select') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>

                    <select type={type} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={dog.id}
                        data-field={field}
                        onInput={onInput}
                    >
                        <option value=''>-- Velg kjønn --</option>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </select>
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        return (
            <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label> {refName}
                <input type={type} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                    min={min}
                    max={maxValue}
                    step={step}
                    value={value}
                    data-id={dog.id}
                    data-field={field}
                    onInput={onInput}
                />
                {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Teams extends Component {
    toggleType(typeId) {
        const { dogStore } = this.props.stores;
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        if (value === null) {
            updateField({ id: object.id, field, value, type, isNew });
        } else if (object.teams?.indexOf(value) > -1) {
            dogStore.removeTeam({ id: object.id, team: value });
        } else if (object.team) {
            dogStore.addTeam({ id: object.id, team: value });
        } else {
            updateField({ id: object.id, field, value, type, isNew });
        }
    }

    componentDidMount() {
		if (this.container) {
			this.container.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

    render() {
        const { userStore } = this.props.stores;
        const { teams } = userStore;
        const { saved, object, field, title, icon, help } = this.props;
        const value = toJS(object[field]);
        const { highlight } = this.props;

        return (
            <div class={`form-group mb-1 pb-1 border-bottom ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-row flex-nowrap pb-2 px-1'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {teams && teams.map((team, idx) => {
                                    const isPrimary = value === team.id;
                                    const isSecondary = object && util.isArray(object.teams) && object.teams?.indexOf(team.id) > -1;
                                    return (
                                        <div class='col mb-0 px-0'
                                            ref={(isPrimary || isSecondary) ? c => this.container = c : null}
                                        >
                                            <button
                                                class={`btn btn-block
            										rounded-none ${idx === 0 ? 'rounded-pill-left' : (idx + 1 === teams.length ? 'rounded-pill-right': '')}
                                                    btn${(isPrimary || isSecondary) ? '' : '-outline'}-${isSecondary ? 'info' : 'success'}
                                                    text-nowrap overflow-hidden`}
                                                style='text-overflow: ellipsis;'
                                                onClick={() => this.toggleType(team.id)}
                                            >
                                                {object.hintTeam === team.id && <span class='float-right text-primary'><i class='fas fa-circle' /></span>}
                                                <i class='fas fa-users mr-2' />
                                                {team.name}

                                                {highlight && highlight?.indexOf(team.id) > -1 && <>
                                                    {value === team.id ? <>
                                                        <i class='fas fa-check float-right' />
                                                    </> : <>
                                                        <div class='spinner-grow text-warning float-right' role='status'>
                                                            <span class='sr-only'>Loading...</span>
                                                        </div>
                                                        <i class='far fa-hand-point-left float-right' style='font-size: 2.0em;' />
                                                    </>}
                                                </>}

                                                <small>
                                                    {isPrimary && <span class='badge badge-pill badge-primary'>Primary</span>}
                                                    {isSecondary && <span class='badge badge-pill badge-secondary'>Secondary</span>}
                                                </small>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='workouts.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Harnesses extends Component {
    toggleType(typeId) {
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: object.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { harnesses, user } = userStore;
        const { language = 'en' } = user;
        const { saved, object, field, title, icon, help } = this.props;
        const value = toJS(object[field]);
        return (
            <div class={`form-group ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-row flex-nowrap pb-2 px-1'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {harnesses && harnesses.map((harness, idx) => (
                                    <div class='col px-0 mb-2'
                                        ref={value === harness.id ? c => this.container = c : null}
                                    >
                                        <div class={`border
                                            rounded-none ${idx === 0 ? 'rounded-pill-left' : (idx + 1 === harnesses.length ? 'rounded-pill-right': '')}
                                            p-2 ${value === harness.id ? 'bg-success' : ''}`}
                                            style={`border-bottom: 3px solid ${harness.colorNonstop} !important;`}
                                            onClick={() => this.toggleType(harness.id)}
                                        >
                                            {harness.lang && harness.lang[language] ? harness.lang[language] : harness.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Status extends Component {
    // toggleStatus(typeId) {
    //     const { object, type, field, updateField, isNew } = this.props;
    //     let value = toJS(object[field]);
    //     if (value === typeId) {
    //         value = 0;
    //     } else {
    //         value = typeId;
    //     }
    //     updateField({ id: object.id, field, value, type, isNew });
    // }

    toggleStatus(typeId) {
        const { dogStore } = this.props.stores;
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = 0;
        } else {
            value = typeId;
        }
        if (value === 0) {
            updateField({ id: object.id, field, value, type, isNew });
        } else if (object.statuses?.indexOf(value) > -1) {
            dogStore.removeStatus({ id: object.id, status: value });
        } else if (object.status) {
            dogStore.addStatus({ id: object.id, status: value });
        } else {
            updateField({ id: object.id, field, value, type, isNew });
        }
    }

    componentDidMount() {
		if (this.container) {
			this.container.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

    render() {
        const { userStore } = this.props.stores;
        const { dogstatuses, user } = userStore;
        const { language } = user;

        const { saved, object, field, title, icon, help } = this.props;
        const value = toJS(object[field]);
        return (
            <div class={`form-group  mb-4 pb-1 border-bottom ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        <div class='w-100 overflow-hidden'>
                            <div
                                class='d-flex flex-row flex-nowrap pb-2 px-1'
                                style='overflow: auto; scroll-snap-type: x mandatory;'
                            >
                                {dogstatuses && dogstatuses.map((status, idx) => {
                                    const isPrimary = value === status.id;
                                    const isSecondary = object && util.isArray(object.statuses) && object.statuses?.indexOf(status.id) > -1;
                                    return (
                                        <div class='col mb-2 px-0'
                                            ref={(isPrimary || isSecondary) ? c => this.container = c : null}
                                        >
                                            <button
                                                class={`btn btn-block
            										rounded-none ${idx === 0 ? 'rounded-pill-left' : (idx + 1 === dogstatuses.length ? 'rounded-pill-right': '')}
                                                    btn${(isPrimary || isSecondary) ? '' : '-outline'}-${isSecondary ? 'info' : 'success'}
                                                    text-nowrap overflow-hidden`}
                                                style='text-overflow: ellipsis;'
                                                onClick={() => this.toggleStatus(status.id)}
                                            >
                                                {language && status.lang && status.lang[language] ? status.lang[language] : status.name}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Position extends Component {
    toggleStatus(typeId) {
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: object.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { dogPositions } = userStore;
        const { saved, object, field, title, icon, help } = this.props;
        const value = toJS(object[field]);
        return (
            <div class={`form-group  mb-4 pb-1 border-bottom ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        {dogPositions && dogPositions.map(status => (
                            <div class='offset-2 col-8 mb-2'>
                                <div class={`border rounded rounded-pill px-3 py-1 bg-${value === status.id ? status.class || 'success' : ''}`}
                                    onClick={() => this.toggleStatus(status.id)}
                                    style='line-height: 1.1em; cursor: pointer; pointer-events: inherit;'
                                >
                                    <small>
                                        {status.name}
                                    </small>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class DogsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newDog: {
                team: props.team,
            },
            submenu: props.submenu || 'info',
        };
        this.updateTimer = {};
    }

    async loadDog() {
		const { dogStore } = this.props.stores;
        if (this.props.dog === 'new') {
            this.setState({ createNew: true });
        } else {
            await dogStore.load(this.props.dog, false, { addData: ['team', 'user', 'workoutSummary', 'vaccineStatuses', 'historyStatuses', 'vaccineLogStatuses', 'allDogs'] });
        }
    }

	resetDog() {
		this.setState({
            newDog: {},
            createNew: false,
        });
	}

	insertDog = async () => {
		const { newDog } = this.state;
        const { callback } = this.props;
		const { dogStore, userStore } = this.props.stores;
		await dogStore.insert({ ...newDog });
        await userStore.getInfo({ forceLoad: true });
        this.resetDog();
        if (util.isFunction(callback)) {
            callback();
        } else {
            route(`/dogs/`);
        }
	}

    updateField = (e) => {
        const { id, field, type, elementtype, isNew } = e.target.dataset;
        const { value, type: fieldType, min, max } = e.target;
    // console.log({ id, field, fieldType, type, elementtype, value, min, max });
        if (fieldType === 'date') {
            if (min && max) {
                if (value >= min && value <= max) {
                    this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
                }
            } else {
                this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
            }
        } else {
            this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
        }
    }

    updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const { dogStore } = this.props.stores;
        let newValue = value;

        let localObjectValue = value;
        const { dog } = dogStore;
        if (field === 'images') {
            if (Array.isArray(dog.images) && Array.isArray(value)) {
                localObjectValue = [...dog.images, ...value];
            } else {
                localObjectValue = [...dog.images];
            }
        }
        if (field === 'files') {
            if (Array.isArray(dog.files)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...dog.files, ...value];
                } else {
                    localObjectValue = [...dog.files, value];
                }
            }
        }
        if (field === 'achievements') {
            if (Array.isArray(dog.achievements)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...dog.achievements, ...value];
                } else {
                    localObjectValue = [...dog.achievements, value];
                }
            }
        }
        if (field === 'links') {
            if (Array.isArray(dog.links)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...dog.links, ...value];
                } else {
                    localObjectValue = [...dog.links, value];
                }
            }
        }

        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }

        if (isNew) {
            dogStore.updateObjectKeyValue('newDog', field, value);
        } else {
            dogStore.updateField(id, field, localObjectValue);
            if (skipTimer) {
                this.doUpdateField(id, field, newValue, localObjectValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue, localObjectValue);
                }, 500);
            }
        }
    }

    async doUpdateField(id, field, value, localValue) {
        const { dogStore } = this.props.stores;
        await dogStore.saveField(id, field, value);
        dogStore.updateObjectKeyValue('publicDog', field, localValue);
    }

    chooseMother = (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = dogStore;
        const dogId = parseInt(e.target.value, 10);
        const motherDog = dogStore.findDog(dogId);

        if (motherDog) {
            this.updateFieldValue({ id: dog.id, field: 'mother', value: motherDog.id, skipTimer: true });
            this.updateFieldValue({ id: dog.id, field: 'motherName', value: motherDog.name, skipTimer: true });
            this.updateFieldValue({ id: dog.id, field: 'motherChipId', value: motherDog.chipId, skipTimer: true });
        } else {
            this.updateFieldValue({ id: dog.id, field: 'mother', value: null, skipTimer: true });
        }
    }

    chooseFather = (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = dogStore;
        const dogId = parseInt(e.target.value, 10);
        const fatherDog = dogStore.findDog(dogId);

        if (fatherDog) {
            this.updateFieldValue({ id: dog.id, field: 'father', value: fatherDog.id, skipTimer: true });
            this.updateFieldValue({ id: dog.id, field: 'fatherName', value: fatherDog.name, skipTimer: true });
            this.updateFieldValue({ id: dog.id, field: 'fatherChipId', value: fatherDog.chipId, skipTimer: true });
        } else {
            this.updateFieldValue({ id: dog.id, field: 'father', value: null, skipTimer: true });
        }
    }

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });

        // const { dogStore } = this.props.stores;
        // const { dog } = dogStore;
        // route(`/dogs/edit/${dog.id}/${submenu}`);
	}

    back = () => {
        // const { dogStore } = this.props.stores;
        // const { dog } = dogStore;
        // route(`/dogs/${dog.id}`);
        const { history } = this.props.stores;
        history.goBack();
    }

	componentDidMount() {
        this.loadDog();
    }

	render() {
        const { createNew, newDog, submenu } = this.state;
		const { dogStore, userStore } = this.props.stores;
        const { marginTop, marginBottom, showBackButton = true } = this.props;
        const { isAdmin } = userStore;
		const { insertStatus, saved, dog } = dogStore;
        const dogFemales = dogStore.findDogsByGender('female');
        const dogMales = dogStore.findDogsByGender('male');
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = dog.birth ? Math.ceil(mu.daysUntilNextBirthday(dog.birth)) : 365;
        const { field, title, help, icon, type, limitImageList } = this.props;

        const { name } = dog;

        if (field) {
            if (util.isArray(field)) {
                return field.map((fieldObj, idx) => {
                    return (
                        <Input
                            saved={saved}
                            dog={dog}
                            field={fieldObj.field}
                            icon={fieldObj.icon}
                            type={fieldObj.type}
                            stores={this.props.stores}
                            title={fieldObj.title}
                            help={fieldObj.help}
                            onInput={this.updateField}
                            updateField={this.updateFieldValue}
                            skipImageList={false}
                            limitImageList={limitImageList}
                            showMissingInfo={false}
                            detailsOpen={true}
                        />
                    );
                });
            }
            return <>
                <Input
                    saved={saved}
                    dog={dog}
                    field={field}
                    icon={icon}
                    type={type}
                    stores={this.props.stores}
                    title={title}
                    help={help}
                    onInput={this.updateField}
                    updateField={this.updateFieldValue}
                    showImages={true}
                    limitImageList={limitImageList}
                />
            </>;
        }

        if (createNew) {
            return (
                <div class='container-fluid' style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                    {showBackButton && <button type='button' class='btn btn-link float-left pl-0 fixed-top text-primary'
                        style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                        onClick={this.back}
                    >
                        <i class='fas fa-arrow-circle-left' />
                    </button>}

                    <div class='row'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <div>
                                <h5 style='padding-left: 35px;'><Text id='dogs.add-new'>Legg til ny hund</Text></h5>
                                <div class='form-group'>
                                    <label for='nameInput'><Text id='dogs.name'>Navn</Text></label>
                                    <input type='text' class='form-control' id='nameInput' aria-describedby='nameHelp'
                                        onInput={linkState(this, 'newDog.name')}
                                        value={newDog.name}
                                    />
                                    <small id='nameHelp' class='form-text text-muted'><Text id='dogs.name-of-dog'>Fullt navn på hunden.</Text></small>
                                </div>
                                <button type='button' class='btn btn-primary'
                                    onClick={this.insertDog}
                                >
                                    <Text id='dogs.save-new'>Registrer ny hund</Text>
                                </button>

                                {insertStatus && <div class='alert alert-success mt-4 col-12' role='alert'>
                                    <Text id='dogs.dog-saved'>Hunden er lagret</Text> 🐕
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
		return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                {showBackButton && <button type='button' class='btn btn-link float-left pl-0 fixed-top'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                    onClick={this.back}
                >
                    <i class='fas fa-arrow-circle-left' />
                </button>}
				<div class='row'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='clearfix'>
                            <div class={`text-center border rounded-circle imageRounded float-right`} style={dog.image ? `background-image: url("${dog.image.s3SmallLink}");` : ''}>
                                {!dog.image && <i class='fas fa-dog text-muted mt-3' style='font-size: 40px;' />}
                            </div>
                            <span class='float-right'>
                                {daysUntilBirthday < 7 && <>
                                    <span class='text-muted mr-2'>
                                        <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                    </span>
                                </>}
                                {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                            </span>
                            <h5 style='padding-left: 35px;'>
                                <Text id='dogs.edit'>Endre</Text>: {name}
                                {hasBirthday && <>
                                    <i class='fas fa-birthday-cake ml-3' />
                                </>}
                            </h5>
                        </div>
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            <div class='col-12 my-3'>
                                <div class='row'>
                                    <div class='w-100 overflow-hidden'>
                                        <div
                                            class='d-flex flex-row flex-nowrap pb-2 px-1'
                                            style='overflow: auto; scroll-snap-type: x mandatory;'
                                        >
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'info' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='info'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-info-circle' /> <Text id='dogs.info'>Info</Text></button>
		                                        {/* {submenu === 'info' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'status' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='status'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-thermometer' /> <Text id='dogs.status'>Status</Text></button>
		                                        {/* {submenu === 'status' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='pictures'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-images' /> <Text id='dogs.pictures'>Pictures</Text></button>
		                                        {/* {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'team' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='team'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-users' /> <Text id='dogs.team'>Team</Text></button>
		                                        {/* {submenu === 'team' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'relations' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='relations'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-project-diagram' /> <Text id='dogs.relations'>Relations</Text></button>
		                                        {/* {submenu === 'relations' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'achievements' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='achievements'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-clock' /> <Text id='dogs.achievements'>Achievements</Text></button>
		                                        {/* {submenu === 'achievements' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'links' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='links'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-link' /> <Text id='dogs.links'>Links</Text></button>
		                                        {/* {submenu === 'links' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'files' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='files'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-file' /> <Text id='dogs.files'>Files</Text></button>
		                                        {/* {submenu === 'files' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='advanced'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-cogs' /> <Text id='dogs.advanced'>Advanced</Text></button>
		                                        {/* {submenu === 'advanced' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>

                        {submenu === 'info' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='name'
                                    title={<Text id='dogs.full-name'>Fullt navn</Text>}
                                    help={<Text id='dogs.full-name-help'>Fullt navn på hunden.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='shortname'
                                    title={<Text id='dogs.short-name'>Kallenavn</Text>}
                                    help={<Text id='dogs.short-name-help'>Kallenavnet på hunden. Hva roper du på innkalling?</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='gender' type='select'
                                    datalist={[
                                        ['male', <Text id='dogs.gender-male'>Male</Text>],
                                        ['female', <Text id='dogs.gender-female'>Female</Text>]
                                    ]}
                                    title={<Text id='dogs.gender'>Kjønn</Text>}
                                    help={<Text id='dogs.gender-help'>Kjønn på hunden.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='chipId'
                                    title={<Text id='dogs.chip-id'>Chip ID</Text>}
                                    help={<Text id='dogs.chip-id-help'>Chip ID på hunden. Står ofte i vetrinærhåndbok eller passet.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='birth' type='date' min='2000-01-01' max='today'
                                    title={<Text id='dogs.birth'>Fødselsdato</Text>}
                                    help={<Text id='dogs.birth-help'>Fødselsdato på hunden.</Text>}
                                    onInput={this.updateField}
                                />
                                <Harnesses stores={this.props.stores} saved={saved} object={dog} type='number' field='harness'
                                    title={<Text id='dogs.harness-size'>Selestørrelse</Text>}
                                    help={<Text id='dogs.harness-size-help'>Sele til hunden</Text>}
                                    updateField={this.updateFieldValue}
                                />
                                <Input saved={saved} dog={dog} field='description' icon='fas fa-paragraph' type='textarea'
                                    title={<Text id='dogs.description'>Beskrivelse</Text>}
                                    help={<Text id='dogs.description-help'>Beskrivelse av hunden.</Text>}
                                    onInput={this.updateField}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'status' && <>
                            <Localizer>
                                <Status stores={this.props.stores} saved={saved} object={dog} type='number' field='status'
                                    title={<Text id='dogs.status'>Status</Text>}
                                    help={<Text id='dogs.status-help'>Status til hunden</Text>}
                                    updateField={this.updateFieldValue} />
                                <Input saved={saved} dog={dog} field='specialNeeds'
                                    title={<Text id='dogs.special-needs'>Spesielle behov</Text>}
                                    help={<Text id='dogs.special-needs-help'>Har hunden spesielle behov? Trenger ekstra mat, utstyr eller annet? Eks: "Ekstra fett", "Husk ulveklobandasje", etc.</Text>}
                                    onInput={this.updateField} />
                            </Localizer>
                        </>}

                        {submenu === 'pictures' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='imageOther' type='image' icon='fas fa-camera'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.photo'>Bilde</Text>}
                                    help={<Text id='dogs.photo-help'>Bilder av hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                    showImages={true}
                                />
                                <Input saved={saved} dog={dog} field='image' type='image' icon='fas fa-portrait'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.profile-photo'>Profilbilde</Text>}
                                    help={<Text id='dogs.profile-photo-help'>Profilbilde av hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                    showImages={true}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'team' && <>
                            <Localizer>
                                <Teams stores={this.props.stores} saved={saved} object={dog} type='number' field='team'
                                    title={<Text id='dogs.team'>Team</Text>}
                                    help={<Text id='dogs.team-help'>Team som hunden er på.</Text>}
                                    updateField={this.updateFieldValue}
                                />
                                <Position stores={this.props.stores} saved={saved} object={dog} type='number' field='position'
                                    title={<Text id='dogs.position'>Plass i spannet</Text>}
                                    help={<Text id='dogs.position-help'>Foretrukket plass i spannet.</Text>}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'relations' && <>
                            <h5 class='mt-5'><Text id='dogs.mother'>Mor</Text></h5>
                            <select class='form-control' aria-describedby='fatherHelp'
                                value={dog.mother}
                                onInput={this.chooseMother}
                            >
                                <option value=''>-- <Text id='dogs.choose-mother'>Velg blant dine hunder</Text> --</option>
                                {dogFemales && dogFemales.map(d => <option value={d.id}>{d.name} - {util.age(d.birth, d.deceased)}</option>)}
                            </select>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='motherName' type='text'
                                    title={<Text id='dogs.mother-name'>Navn på mor</Text>}
                                    help={<Text id='dogs.mother-name-help'>Navn på moren til denne hunden.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='motherChipId' type='text'
                                    title={<Text id='dogs.mother-chipid'>Chip ID på mor</Text>}
                                    help={<Text id='dogs.mother-chipid-help'>Chip ID til moren til denne hunden. Dersom hunden finnes blant dine hunder, så vil den bli koblet sammen.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='motherComment'
                                    title={<Text id='dogs.mother-comment'>Kommentar til mor</Text>}
                                    help={<Text id='dogs.mother-comment-help'>Kommentar til moren til denne hunden.</Text>}
                                    onInput={this.updateField}
                                />
                            </Localizer>

                            <h5 class='mt-5'><Text id='dogs.father'>Far</Text></h5>
                            <select class='form-control' aria-describedby='fatherHelp'
                                value={dog.father}
                                onInput={this.chooseFather}
                            >
                                <option value=''>-- <Text id='dogs.choose-father'>Velg blant dine hunder</Text> --</option>
                                {dogMales && dogMales.map(d => <option value={d.id}>{d.name} - {util.age(d.birth, d.deceased)}</option>)}
                            </select>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='fatherName' type='text'
                                    title={<Text id='dogs.father-name'>Navn på far</Text>}
                                    help={<Text id='dogs.father-name-help'>Navn på faren til denne hunden.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='fatherChipId' type='text'
                                    title={<Text id='dogs.father-chipid'>Chip ID på far</Text>}
                                    help={<Text id='dogs.father-chipid-help'>Chip ID til faren til denne hunden. Dersom hunden finnes blant dine hunder, så vil den bli koblet sammen.</Text>}
                                    onInput={this.updateField}
                                />
                                <Input saved={saved} dog={dog} field='fatherComment'
                                    title={<Text id='dogs.father-comment'>Kommentar til far</Text>}
                                    help={<Text id='dogs.father-comment-help'>Kommentar til faren til denne hunden.</Text>}
                                    onInput={this.updateField}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'links' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='links' type='text' icon='fas fa-link'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.links'>Linker</Text>}
                                    help={<Text id='dogs.links-help'>Linker til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'achievements' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='achievements' type='text' icon='fas fa-clock'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.achievements'>Bragder</Text>}
                                    help={<Text id='dogs.achievements-help'>Bragder til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'files' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='files' type='file' icon='fas fa-file'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.files'>Files</Text>}
                                    help={<Text id='dogs.files-help'>Filer til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'advanced' && <>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='insuranceId'
                                    title={<Text id='dogs.insuranceId'>Insurance ID</Text>}
                                    help={<Text id='dogs.insuranceId-help'>Insurance ID for your dog. Check your insurance papers.</Text>}
                                    onInput={this.updateField}
                                />

                                <hr class='my-5' />

                                <div class='mt-5 pt-5'>
                                    <Input saved={saved} dog={dog} field='deceased' type='date' min='2000-01-01' max='today'
                                        title={<Text id='dogs.deceased'>Død</Text>}
                                        help={<Text id='dogs.deceased-help'>Dødsdato for hunden.</Text>}
                                        onInput={this.updateField}
                                    />
                                </div>
                            </Localizer>
                        </>}

					</div>

				</div>
			</div>
		);
	}
}

export default DogsEdit;
