import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import linkState from 'linkstate';

import style from '../style.css';
import mu from '../../lib/musher-util';

@observer
class CommentReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendButtonEnabled: true,
        };
        this.inputRef = {};
    }

    commentReplyAdd = async () => {
        const { sendButtonEnabled, comment = '' } = this.state;
        if (!sendButtonEnabled) {
            return false;
        }
        this.setState({ sendButtonEnabled: false });

        const {
            callback = () => {},
            commentId,
            inputRefObject = {},
            type,
            object,
            holdingArrayName,
            holdingStore,
        } = this.props;
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;
        let response;
        switch (type) {
            case 'story':
                response = await storyStore.commentReply({ id: object.id, commentId, comment });
                break;
            case 'workout':
                response = await workoutStore.commentReply({ id: object.id, commentId, comment });
                break;
            case 'news':
                response = await newsStore.commentReply({ id: object.id, commentId, comment });
                break;
            case 'race':
                response = await raceStore.commentReply({ id: object.id, commentId, comment });
                break;
            case 'track':
                response = await trackStore.commentReply({ id: object.id, commentId, comment });
                break;
        }

        switch(holdingStore) {
            case 'storyStore':
                storyStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'workoutStore':
                workoutStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'teamStore':
                teamStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'newsStore':
                newsStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'raceStore':
                raceStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'trackStore':
                trackStore.commentReplyAdd({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            default:
                break;
        }

        if (response && response.id) {
            this.setState({
                comment: '',
            }, () => {
                mu.resizeTextarea(inputRefObject[commentId]);
            });
            callback({ object, response, commentId });
		}
        this.setState({ sendButtonEnabled: true });
	}

    render() {
        const { inputRef, holdingArrayName, holdingStore } = this.props;
        const { comment, sendButtonEnabled } = this.state;
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const displayNameShort = user.firstname ? `${util.ucfirst(user.firstname, true)}${util.ucfirst(user.lastname, true)}` : `${util.ucfirst(user.email || '', true)}`;
        // const displayName = user.firstname ? `${user.firstname} ${user.lastname}` : `${user.email}`;
        const userImage = user.image || (user.images ? user.images[0] : undefined);

        return (
            <div class='w-100'>
                <div class='d-flex mb-0 position-relative w-100'>
                    <div
                        class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                        style={`${userImage ? `background-image: url('${userImage.s3SmallLink}'); background-size: cover;` : ''} border-width: 3px !important;`}
                    >
                        {!userImage && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                    </div>
                    <div class='col pl-3' style='line-height: 1.2em;'>

                        <div class='d-flex bd-highlight'>
                            <div class='p-0 w-100 bd-highlight'>
                                <label class='sr-only' for='inlineFormInput'><Text id='comments.comment'>Kommentar</Text></label>
                                <div class='border rounded rounded-lg position-relative'>
                                    <Localizer>
                                        <textarea
                                            class='form-control-plaintext px-2'
                                            type='text'
                                            placeholder={<Text id='comments.write-comment'>Skriv en kommentar...</Text>}
                                            onInput={linkState(this, 'comment')}
                                            onKeyUp={mu.resizeTextarea}
                                            style='height: 2.3em;'
                                            value={comment}
                                            ref={inputRef}
                                        />
                                    </Localizer>
                                </div>
                            </div>
                            <div class='p-0 pl-2 flex-shrink-1 bd-highlight'>
                                <button
                                    type='button'
                                    class={`btn btn-${sendButtonEnabled ? 'primary' : 'secondary'} mb-2`}
                                    disabled={!sendButtonEnabled}
                                    onClick={this.commentReplyAdd}
                                ><i class='fas fa-paper-plane' /></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default CommentReply;
