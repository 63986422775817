import { h, Component } from 'preact';
import { observer } from 'mobx-preact';

import PubSub, { topics } from '../../lib/pubsub';

const ERROR_DISPLAY_MS = 7000;

const initialState = {
    message: '',
    type: '',
    icon: '',
};

@observer
class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    subscriptions() {
        PubSub.subscribe(topics.STATUS_MESSAGE, ({ message, type, icon, status }) => {
            this.setState({
                message,
                type,
                icon,
                status,
            });
        });
    }

    remove = () => {
        this.setState({
            ...initialState
        });
    }

    componentDidMount() {
        this.subscriptions();
    }

    componentDidUpdate() {
        clearTimeout(this.removeTimer);
        this.removeTimer = setTimeout(() => this.remove(), ERROR_DISPLAY_MS);
    }

    componentWillUnmount() {
        clearTimeout(this.removeTimer);
    }

    render() {
        const { message, icon, type = 'success' } = this.state;

        if (!message) {
            return '';
        }
        return (
            <div class='container fixed-top' style={`margin-top: 82px;`}>
                <div class={`alert alert-${type} row`}>
                    <div class={`col-12`}>
                        <button onclick={this.remove} class='btn btn-sm btn-link float-right'>
                            <i className='fas fa-times' />
                        </button>
                        {icon && <h1 class='float-left mr-3'><i class={icon} /></h1>}
                        {message && message.split('\n').map(line => <div class='mb-2'>{line}</div>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Message;
