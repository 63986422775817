import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route, Router } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import mu from '../../lib/musher-util';

@observer
class PublicImages extends Component {
    showDogDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, height } = e.target.closest('.dogDetail').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicDog', {
            id,
            height: drawerHeightMedium,
        });
    }

    showTeamDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, height } = e.target.closest('.teamDetail').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicTeam', {
            id,
            height: drawerHeightMedium,
        });
    }

    showWorkoutDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, height } = e.target.closest('.workoutDetail').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicWorkout', {
            id,
            height: drawerHeightMedium,
        });
    }

    showStoryDetail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, height } = e.target.closest('.storyDetail').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicStory', {
            id,
            height: drawerHeightMedium,
        });
    }

    render() {
        const { publicImages, children } = this.props;
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en' } = user;

        return (<>
            {publicImages && publicImages.length > 0 ? <>
                <div class='row mt-1'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            {publicImages.map((obj, index) => {
                                let link;
                                if (obj.type === 'story') {
                                    link = `/stories/${obj.uuidv4}`;
                                } else if (obj.type === 'workout') {
                                    link = `/workouts/${obj.uuidv4}`;
                                }
                                let hasImage = false;
                                if (obj.images[0] && obj.images[0].s3SmallLink) {
                                    hasImage = true;
                                }
                                let hasMapImage = false;
                                if (obj.mapImage && obj.mapImage.s3SmallLink) {
                                    hasMapImage = true;
                                }
                                // eslint-disable-next-line react/jsx-key
                                return (<div class={`col-${hasImage ? 4 : 4} px-0 border`} style='height: 200px; overflow: hidden;'>
                                    <div class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center position-relative`}>
                                        {obj.type === 'story' && <>
                                            {hasImage ? <>
                                                <div
                                                    class='position-absolute w-100 h-100 storyDetail bg-img-cover'
                                                    style={`background-image: url('${obj.images[0].s3SmallLink}');`}
                                                    onClick={this.showStoryDetail} data-id={obj.uuidv4}
                                                />
                                            </> : <>
                                                <div
                                                    class='py-3 px-3 w-100 h-100 storyDetail overflow-hidden'
                                                    style='background: linear-gradient(#FF6321, #9198e5);'
                                                    onClick={this.showStoryDetail}
                                                    data-id={obj.uuidv4}
                                                >
                                                    <small>
                                                        <Markdown markdown={mu.replaceImages(obj.body, obj.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                    </small>
                                                </div>
                                            </>}
                                            <div class='position-absolute text-white' style='top: 0px; left: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-table-cells' />
                                            </div>
                                            {obj.totalImages > 1 && <div class='position-absolute text-white' style='top: 0px; right: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-images' />
                                            </div>}
                                        </>}

                                        {obj.type === 'workout' && <>
                                            {hasImage ? <>
                                                <div
                                                    class='position-absolute w-100 h-100 workoutDetail bg-img-cover'
                                                    style={`background-image: url('${obj.images[0].s3SmallLink}');`}
                                                    onClick={this.showWorkoutDetail} data-id={obj.uuidv4}
                                                />
                                            </> : <>
                                                {hasMapImage ? <>
                                                    <div
                                                        class='position-absolute w-100 h-100 workoutDetail bg-img-cover'
                                                        style={`background-image: url('${obj.mapImage.s3SmallLink}');`}
                                                        onClick={this.showWorkoutDetail} data-id={obj.uuidv4}
                                                    />
                                                </> : <>
                                                    <div
                                                        class='py-3 px-3 w-100 h-100 workoutDetail overflow-hidden'
                                                        style='background: linear-gradient(#FF6321, #9198e5);'
                                                        onClick={this.showWorkoutDetail}
                                                        data-id={obj.uuidv4}
                                                    >
                                                        <small>
                                                            <Markdown markdown={mu.replaceImages(obj.name, obj.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                        </small>
                                                    </div>
                                                </>}
                                            </>}
                                            <div class='position-absolute text-white' style='top: 0px; left: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-person-running' />
                                            </div>
                                            {obj.totalImages > 1 && <div class='position-absolute text-white' style='top: 0px; right: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-images' />
                                            </div>}
                                        </>}

                                        {obj.type === 'team' && <>
                                            {hasImage ? <>
                                                <div
                                                    class='position-absolute w-100 h-100 teamDetail bg-img-cover'
                                                    style={`background-image: url('${obj.images[0].s3SmallLink}');`}
                                                    onClick={this.showTeamDetail} data-id={obj.uuidv4}
                                                />
                                            </> : <>
                                                {hasMapImage ? <>
                                                    <div
                                                        class='position-absolute w-100 h-100 teamDetail bg-img-cover'
                                                        style={`background-image: url('${obj.mapImage.s3SmallLink}');`}
                                                        onClick={this.showTeamDetail} data-id={obj.uuidv4}
                                                    />
                                                </> : <>
                                                    <div
                                                        class='py-3 px-3 w-100 h-100 teamDetail overflow-hidden'
                                                        style='background: linear-gradient(#FF6321, #9198e5);'
                                                        onClick={this.showTeamDetail}
                                                        data-id={obj.uuidv4}
                                                    >
                                                        <small>
                                                            <Markdown markdown={mu.replaceImages(obj.name, obj.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                        </small>
                                                    </div>
                                                </>}
                                            </>}
                                            <div class='position-absolute text-white' style='top: 0px; left: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-users' />
                                            </div>
                                            {obj.images.length > 1 && <div class='position-absolute text-white' style='top: 0px; right: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-images' />
                                            </div>}
                                        </>}

                                        {obj.type === 'dog' && <>
                                            {hasImage ? <>
                                                <div
                                                    class='position-absolute w-100 h-100 dogDetail bg-img-cover'
                                                    style={`background-image: url('${obj.images[0].s3SmallLink}');`}
                                                    onClick={this.showDogDetail} data-id={obj.uuidv4}
                                                />
                                            </> : <>
                                                {hasMapImage ? <>
                                                    <div
                                                        class='position-absolute w-100 h-100 dogDetail bg-img-cover'
                                                        style={`background-image: url('${obj.mapImage.s3SmallLink}');`}
                                                        onClick={this.showDogDetail} data-id={obj.uuidv4}
                                                    />
                                                </> : <>
                                                    <div
                                                        class='py-3 px-3 w-100 h-100 dogDetail overflow-hidden'
                                                        style='background: linear-gradient(#FF6321, #9198e5);'
                                                        onClick={this.showDogDetail}
                                                        data-id={obj.uuidv4}
                                                    >
                                                        <small>
                                                            <Markdown markdown={mu.replaceImages(obj.name, obj.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                        </small>
                                                    </div>
                                                </>}
                                            </>}
                                            <div class='position-absolute text-white' style='top: 0px; left: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-dog' />
                                            </div>
                                            {obj.images.length > 1 && <div class='position-absolute text-white' style='top: 0px; right: 4px; opacity: 0.6;'>
                                                <i class='fa-solid fa-images' />
                                            </div>}
                                        </>}

                                    </div>
                                </div>);
                            })}
                        </div>
                    </div>
                </div>
            </> : <>
                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                    {children ? children : <>
                        <div class='text-center text-muted'>
                            <div class='display-4'><i class='fa-solid fa-camera' /></div>
                            <h5><Text id='teams.no-story-images'>No story or workout images for this team!</Text></h5>
                        </div>
                    </>}
                </div>
            </>}
        </>);
    }
}

export default PublicImages;
