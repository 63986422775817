import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../imageScroller';
import DogScroller from '../dog/scroller';
import WorkoutComments from '../workout/comments';
import WorkoutAddComment from '../workout/commentAdd';

import PublicTeam from '../team/public';

import LikesAndComments from '../feedback/likesAndComments';
import Views from '../feedback/views';

import Speed from '../data/speed';
import Elevation from '../data/elevation';
import Weight from '../data/weight';
import Distance from '../data/distance';
import Temperature from '../data/temperature';

import Field from '../../components/util/field';

import mu from '../../lib/musher-util';

function displayDistance(distanceKm) {
	if (distanceKm < 5) {
		return util.format(distanceKm, 2);
	}
	if (distanceKm < 10) {
		return util.format(distanceKm, 1);
	}
	return util.format(distanceKm, 1);
}

const initialState = {
	showAddComment: {},
    // viewImageIdx: {},
    likeWorkout: {},
    idxSuggestion: 0,
    idxAd: 4,
    limit: 15,
    offset: 0,
    hasFollowSugguestions: false,
    hasAd: false,
    sessionid: new Date().getTime(),
};

@observer
class TeamPublicFeedLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.inputRef = {};
        this.containerRef = null;
    }

    toggleDate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

	likeWorkout = async (e) => {
        const workoutId = parseInt(e.target.closest('.imageContainer').dataset.id, 10);
		const { workoutStore } = this.props.stores;

        const { likeWorkout } = this.state;
        likeWorkout[workoutId] = true;
        this.setState({ likeWorkout });
        setTimeout(() => {
            const { likeWorkout } = this.state;
            delete likeWorkout[workoutId];
            this.setState({ likeWorkout });
        }, 1200);

		const response = await workoutStore.likeWorkout({ id: workoutId });
        if (response && response.status === 200) {
            this.addLikeToFeed(workoutId, response.data);
		}
	}

    addCommentToFeed = ({ type, workout, response, commentId, replyId }) => {
        // console.log('addCommentToFeed', { workout, response, commentId });
        const { holdingStore = 'team' } = this.props;
        if (holdingStore === 'team') {
            const { teamStore } = this.props.stores;
            switch (type) {
                case 'likeComment':
                    teamStore.addLikeToComment({ id: workout.id, data: response.data, commentId });
                    break;
                case 'likeReply':
                    teamStore.addLikeToCommentReply({ id: workout.id, data: response.data, commentId, replyId });
                    break;

                default:
                    if (commentId) {
                        teamStore.addCommentToWorkoutComment({ id: workout.id, data: response.data, commentId });
                    } else {
                        teamStore.addCommentToWorkout({ id: workout.id, data: response.data });
                    }
                    break;
            }
        } else if (holdingStore === 'story') {
            const { storyStore } = this.props.stores;
            switch (type) {
                case 'likeComment':
                    storyStore.workoutsAddLikeToComment({ id: workout.id, data: response.data, commentId });
                    break;
                case 'likeReply':
                    storyStore.workoutsAddLikeToCommentReply({ id: workout.id, data: response.data, commentId, replyId });
                    break;

                default:
                    if (commentId) {
                        storyStore.workoutsAddCommentToWorkoutComment({ id: workout.id, data: response.data, commentId });
                    } else {
                        storyStore.workoutsAddCommentToWorkout({ id: workout.id, data: response.data });
                    }
                    break;
            }
        }
    }

    addLikeToFeed = (id, data) => {
        const { holdingStore = 'team' } = this.props;
        if (holdingStore === 'team') {
            const { teamStore } = this.props.stores;
            teamStore.addLikeToWorkout({ id, data });
        } else if (holdingStore === 'story') {
            const { storyStore } = this.props.stores;
            storyStore.workoutsAddLikeToWorkout({ id, data });
        }
    }

	toggleAddComment = (e) => {
		const workoutId = e.target.closest('button').dataset.id;
		const { showAddComment } = this.state;
        if (showAddComment[workoutId]) {
            delete showAddComment[workoutId];
        } else {
            showAddComment[workoutId] = true;
        }
		this.setState({
			showAddComment,
		}, () => {
			if (this.inputRef[workoutId]) {
				this.inputRef[workoutId].focus();
			}
		});
	}

    toggleMenu = (e) => {
        const { id } = e.target.closest('i').dataset;
		const { showMenu = {} } = this.state;
        showMenu[id] = !showMenu[id];
		this.setState({
			showMenu,
		});
	}

    toggleAdminDetails = () => {
        const { showAdminDetails } = this.state;
        this.setState({
            showAdminDetails: !showAdminDetails,
        });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    reportWorkout = async (e) => {
        const { id } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        await teamStore.reportWorkout({ id });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    adminRemoveWorkout = async (e) => {
        const { id } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        await teamStore.adminHideWorkout({ id });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    shareLink = (e) => {
        const { url, title, text } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        appState.shareUrl({ url, title, text });
    }

    showWorkoutDetail = (e) => {
        const { id, height } = e.target.closest('.workoutDetail').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicWorkout', {
            id,
            height: drawerHeightMedium,
        });
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { feed = {} } = this.props;
        const { userStore, teamStore } = this.props.stores;
        const { user } = userStore;
        const translatedTextName = await teamStore.translate({ object: feed, user, field: 'name' });
        let translatedTextComment;
        if (feed.comment) {
            translatedTextComment = await teamStore.translate({ object: feed, user, field: 'comment' });
        }
        this.setState({ showOriginal: 0, translatedTextName, translatedTextComment });
    }

    showOriginal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginal: 1 });
    }

    displayTeam = (e, teamId) => {
        const { onTeamClick } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onTeamClick)) {
            onTeamClick(e, teamId);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
        }
	}

    favoriteWorkout = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value, isfavorite } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (isfavorite) {
            await userStore.removeField({ email: user.email, field: 'favoriteWorkout', value: parseInt(value, 10) });
        } else {
            await userStore.updateUserArray({ email: user.email, field: 'favoriteWorkout', value: parseInt(value, 10) });
        }
    }

    openInbox = (e, fromuser) => {
        const { onUserClick } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onUserClick)) {
            onUserClick(e, fromuser);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
        }
    }

    onMenuClick = (e, workout) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('publicWorkoutMenu', { workout, height: drawerHeightSmall });
    }

    render() {
        const {
            showAddComment,
            showDate,
            likeWorkout,
            showOriginal,
            translatedTextName,
            translatedTextComment,
            showAdminDetails,
            showMenu = {},
        } = this.state;
        const {
            feed,
            idx,
            onTeamClick = () => {},
            onUserClick = () => {},
            onDogClick = () => {},
            holdingStore = 'team',
            displayAllComments,
        } = this.props;
        const { teamStore, storyStore, userStore, appState } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { language = 'en' } = user;
        const { darkmode, hasShareApi } = appState;
        let publicDogs;

        let team = {};
        let publicUser = {};
        if (holdingStore === 'team') {
            team = teamStore.findPublicTeam(feed.team) || {};
            publicUser = teamStore.findPublicUser(feed.user) || {};
            const { publicDogs: teamPublicDogs } = teamStore;
            publicDogs = teamPublicDogs;
        } else if (holdingStore === 'story') {
            team = storyStore.findPublicTeam(feed.team) || {};
            publicUser = storyStore.findPublicUser(feed.user) || {};
            const { publicDogs: storyPublicDogs } = storyStore;
            publicDogs = storyPublicDogs;
        }
        // const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        // const teamType = userStore.findTeamType({ type: team.type, language });

        const isTeamAdmin = (team && team.members) ? team.members.some(m => m === user.id) : false;

        const image = mu.getImage({ user: publicUser, team, object: feed, priority: 'user' });

        const { likes, comments, reports = [] } = feed;
        const hasLikedWorkout = feed.likes && feed.likes?.findIndex(e => e.user === user.id) > -1;
        // const totalFollowers = team && team.followers ? team.followers.length : 0;

        const isYourTeam = team.members && team.members?.indexOf(user.id) > -1;
        const isFollowing = team && team.followers && team.followers?.indexOf(user.id) > -1;
        const hasRequestedFollow = team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;
        const workoutEquipment = userStore.findEquipment({ equipment: feed.equipment, language });
        const workoutEquipmentIcon = userStore.findEquipment({ equipment: feed.equipment, field: 'icon' });
		const { gpxInfo = {} } = feed;


        let shortname;
        let name;
        let username;
        let userLink;
        if (publicUser) {
            shortname = mu.displayNameShort(publicUser);
            name = mu.displayName(publicUser);
            username = mu.displayName(publicUser);
            if (publicUser.settings && publicUser.uuidv4 !== user.uuidv4) {
                userLink = publicUser.settings.hideProfile ? null : `/inbox/${publicUser.uuidv4}`;
            }
        }

        const images = [];
        if (feed.mapImage) {
            images.push({
                ...feed.mapImage,
                type: 'map',
            });
        }
        if (feed.images) {
            const imgs = toJS(feed.images);
            images.push(...imgs);
        }

        const { currentLocationGeocode = {}, yrWeather = [] } = feed;
        const views = mu.getViews(feed.statistics);
        const adminData = mu.getAdminData(feed.statistics);

        if (!team || !team.name) {
            return '';
        }

        const translationName = mu.getTranslation({
            object: feed,
            targetLang: language,
            field: 'name',
        }) || translatedTextName;
        let feedName = translationName || feed.name;
        const translationComment = mu.getTranslation({
            object: feed,
            targetLang: language,
            field: 'comment',
        }) || translatedTextComment;
        let feedComment = translationComment || feed.comment;

        if (showOriginal) {
            feedName = feed.name;
            feedComment = feed.comment;
        }

        return (<>
            <Field value={feed.name} darkmode={darkmode} type='workout' width={800} height={150}>
                <span class='float-right font-weight-lighter text-muted'>
                    <i class='fas fa-ellipsis-h' onClick={e => this.onMenuClick(e, feed)} />
                </span>

                <div class='d-flex position-relative'>
                    <div class='text-center'>
                        <div
                            class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                            style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
                            onClick={e => this.displayTeam(e, team.uuidv4)}
                        >
                            {!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
                        </div>
                    </div>
                    <div class='flex-grow-1 pl-3' style='line-height: 1.2em;'>
                        {/* <span class='badge badge-pill badge-secondary float-right'>{teamType}</span> */}
                        <div
                            class='mb-1 position-relative'
                        >
                            {team.public ? <>
                                <a
                                    href={`/teams/public/${team.uuidv4}`}
                                    onClick={e => this.displayTeam(e, team.uuidv4)}
                                    class={`${darkmode ? 'text-light' : 'text-dark'}`}
                                >{team.name}</a>
                            </> : <>
                                {team.name}
                            </>}

                            {/* {story.subType === 1 && <>
                                <small class='text-muted ml-2' style='font-size:' onClick={e => onDogClick(e, story.dogUuidv4)}>
                                <Text id='story.dog-picture-added'>added picture of </Text> {story.dogName}
                                </small>
                            </>} */}

                            {username && <>
                                <span class='ml-2 text-muted'>
                                    <Text id='workout.is-out-with'>is out with </Text>
                                    {userLink ? <>
                                        <a
                                            href={userLink}
                                            onClick={e => this.openInbox(e, publicUser.uuidv4)}
                                            class={`${darkmode ? 'text-light' : 'text-dark'}`}
                                            >{username}</a>
                                    </> : <>
                                        {username}
                                    </>}
                                </span>
                            </>}
                        </div>

                        <div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
                            {feed.date && <small class='text-muted' onClick={this.toggleDate}>
                                {showDate ? util.formatDate(feed.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' }) : util.formatDistance(feed.date, new Date(), { locale: 'no-NB' })}
                            </small>}

                            <small class='text-muted'>
                                {feed.equipment > 0 && <>
                                    <span class='ml-2'>·</span>
                                    <i class={`${workoutEquipmentIcon} ml-2`} /> {workoutEquipment}
                                </>}
                                {/* {feed.distanceKm > 0 && <><i class='fas fa-road ml-2' /> {displayDistance(feed.distanceKm)}km</>}
                                {feed.elevation > 0 && <><i class='fas fa-mountain ml-2' /> {util.format(feed.elevation, 0)}m</>}
                                {feed.speedAvg > 0 && <><i class='fas fa-tachometer-alt ml-2' /> {util.format(feed.speedAvg, 1)}km/t</>} */}
                                {/* {feed.dogs > 0 && <>
                                    <span class='ml-2'>·</span>
                                    <i class='fas fa-dog ml-2' /> {feed.dogs.length}
                                </>} */}
                                {/* {feed.track > 0 && <>
                                    <span class='ml-2'>·</span>
                                    <i class={`fas fa-map-marked text-${feed.track > 0 ? 'success' : 'danger'} ml-2`} />
                                </>} */}
                                {/* {feed.dogsLeft > 0 && <><i class={`fas fa-crosshairs text-${feed.dogsLeft && feed.dogsRight ? 'success' : 'danger'} ml-2`} /></>} */}
                                {/* {feed.sledWeight > 0 && <>
                                    <span class='ml-2'>·</span>
                                    <i class='fas fa-sleigh ml-2' /> <Weight stores={this.props.stores} value={feed.sledWeight} />
                                </>} */}
                                {/* {feed.temperature !== 0 && <>
                                    <i class='fas fa-temperature-low ml-2' /> <Temperature stores={this.props.stores} value={feed.temperature} />
                                </>} */}
                                {feed.dogs && feed.dogs.length > 0 && <>
                                    <span class='ml-2'>·</span>
                                    <i class='fas fa-dog ml-2' /> {util.format(feed.dogs.length, 0)}
                                </>}
                                {/* {feed.kcalBurnedTotal && <><i class='fas fa-balance-scale' /> {util.format(feed.kcalBurnedTotal)} kcal</>}
                                {feed.kcalMeatGramsTotal && <><i class='fas fa-drumstick-bite ml-2' /> {util.format(feed.kcalMeatGramsTotal / 1000, 1)} kg</>}
                                {feed.kcalSalmonGramsTotal && <><i class='fas fa-fish ml-2' /> {util.format(feed.kcalSalmonGramsTotal / 1000, 1)} kg</>}
                                {feed.kcalFatGramsTotal && <><i class='fas fa-piggy-bank ml-2' /> {util.format(feed.kcalFatGramsTotal / 1000, 1)} kg</>} */}
                                {currentLocationGeocode && currentLocationGeocode.formattedCounty && <>
                                    <span class='ml-2'>·</span>
                                    <i class='fas fa-map-marker-alt ml-2' /> {currentLocationGeocode.formattedLocality || currentLocationGeocode.formattedCounty}
                                </>}
                                {/* {feed.moonIllumination && feed.moonIllumination.phaseIcon && <span class='ml-2'>{feed.moonIllumination.phaseIcon}</span>} */}
                            </small>
                        </div>

                        {showAdminDetails && isAdmin && <div
                            class='d-flex flex-row flex-nowrap bg-light border-top border-bottom py-2'
                            style='overflow: auto;'
                        >
                            <small class='text-muted mr-2'>Adm:</small>
                            {reports && reports.length > 0 && <>
                                <small class='text-danger mr-2'>
                                    <i class='fas fa-flag' /> {reports.length}
                                </small>
                            </>}
                            {currentLocationGeocode && currentLocationGeocode.formatted_address && <>
                                <small class='text-muted'>
                                    <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formatted_address}
                                </small>
                            </>}
                            {publicUser.deviceInfo && <>
                                <span class='badge badge-secondary ml-1 float-right font-weight-light'>
                                    {['platform', 'manufacturer', 'model'].map(e => <>
                                        <span class='ml-1 text-white'>
                                            {publicUser.deviceInfo[e]}
                                        </span>
                                    </>)}
                                </span>
                            </>}
                            {adminData && <>
                                {/* {JSON.stringify(adminData)}<br /> */}
                                <small class='ml-2 text-muted'>
                                    V: {adminData.count}
                                </small>
                                <small class='ml-2 text-muted'>
                                    R: {adminData.ranking} {util.isNumber(adminData.rankingTrend) && <><i class={mu.getClassForTrend(adminData.rankingTrend)} /></>}
                                </small>
                                <small class='ml-2 text-muted'>
                                    D: {util.format(adminData.durationAvg / 1000, 1)}s {util.isNumber(adminData.durationAvgTrend) && <><i class={mu.getClassForTrend(adminData.durationAvgTrend)} /></>}
                                </small>
                                {feed.statisticsCurrent && feed.statisticsCurrent.totalInteractions > 0 && <small class='ml-2 text-muted'>
                                    S: {util.format(feed.statisticsCurrent.totalInteractions, 0)}
                                </small>}
                                {feed.shadowTags && feed.shadowTags.map(tag => <>
                                    <span class='badge badge-secondary ml-2 float-right font-weight-light'>{tag}</span>
                                </>)}
                            </>}
                        </div>}
                    </div>
                </div>


                {/* <div class='row'>
                    <div class='row mb-3'>
                        {feed.gpxInfo && feed.gpxInfo.svgMap && <>
                            <div class='col-6 mt-auto text-center overflow-hidden'>
                                <svg viewBox='0 0 300 300' style='width: 150px; height: 150px;'>
                                    <path d={`${feed.gpxInfo.svgMap}`} style='fill: none; stroke: #0275d8; stroke-width: 5; opacity: 0.5;' />
                                </svg>
                                <small class='text-muted'>Track</small>
                            </div>
                        </>}
                        {feed.gpxInfo && feed.gpxInfo.svgElevation && <>
                            <div class='col-6 mt-auto text-center overflow-hidden'>
                                <svg viewBox='0 0 300 200' style='width: 150px; height: 100px;'>
                                    <path d={`${feed.gpxInfo.svgElevation}`} style='fill: none; stroke: #5cb85c; stroke-width: 5; opacity: 0.5;' />
                                </svg>
                                <small class='text-muted'>Elevation</small>
                            </div>
                        </>}
                    </div>
                </div> */}

                <div class='container-fluid px-0 mt-1'>
                    <div class='row workoutDetail' data-id={feed.uuidv4}>
                        <ImageScroller
                            stores={this.props.stores}
                            images={images} id={feed.id}
                            dblClick={this.likeWorkout}
                            likeRef={likeWorkout}
                            imageIdx={feed.mapImage ? 1 : 0}
                        />
                    </div>
                </div>

                {feed.dogs && feed.dogs.length > 0 && <div class='container-fluid px-0 mt-1'>
                    <div class='row pt-2'>
                        <DogScroller stores={this.props.stores} dogs={feed.dogs} allDogs={publicDogs} onDogClick={onDogClick} />
                    </div>
                </div>}

                <div class='d-flex justify-content-between bg-light rounded-lg px-2'>
                    {feed.distanceKm > 0 && <div class='mr-2 text-center'>
                        <div class='text-overflow text-muted'>
                            <small><i class='fas fa-road' /> <Text id='workout.calendar.distance'>Distanse</Text></small>
                        </div>
                        <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                            <Distance stores={this.props.stores} value={feed.distanceKm} />
                        </div>
                    </div>}
                    {feed.elevation > 0 && <div class='mr-2 text-center'>
                        <div class='text-overflow text-muted'>
                            <small><i class='fas fa-mountain' /> <Text id='workout.calendar.ascend'>Høydemeter</Text></small>
                        </div>
                        <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                            <Elevation stores={this.props.stores} value={feed.elevation} />
                        </div>
                    </div>}
                    {feed.duration > 0 && <div class='mr-2 text-center'>
                        <div class='text-overflow text-muted'>
                            <small><i class='fas fa-clock' /> <Text id='workout.calendar.duration'>Varighet</Text></small>
                        </div>
                        <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                            {util.secToHms(feed.duration, feed.duration > 3600)}
                        </div>
                    </div>}
                    {feed.speedAvg > 0 && <div class='text-center'>
                        <div class='text-overflow text-muted'>
                            <small><i class='fas fa-tachometer-alt' /> <Text id='workout.calendar.avg-speed'>Snittfart</Text></small>
                        </div>
                        <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                            {gpxInfo && gpxInfo.avgGpsMovingSpeed > 0 ? <>
                                <Speed stores={this.props.stores} value={gpxInfo.avgGpsMovingSpeed * 3.6} />
                            </> : <>
                                <Speed stores={this.props.stores} value={feed.speedAvg} />
                            </>}
                        </div>
                    </div>}
                </div>

                <div class='row mt-2'>
                    <div class='clearfix col-12 font-weight-bold workoutDetail' style='font-size: 1.2em;' onClick={this.showWorkoutDetail} data-id={feed.uuidv4}>
                        <Markdown markdown={mu.replaceImages(feedName, feed.images, language)} markedOpts={mu.getMarkdownOptions()} />
                    </div>
                    <div class='clearfix col-12 workoutDetail' onClick={this.showWorkoutDetail} data-id={feed.uuidv4}>
                        <Markdown markdown={mu.replaceImages(feedComment, feed.images, language)} markedOpts={mu.getMarkdownOptions()} />
                    </div>
                    {feed.language !== language && <>
                        {!showOriginal && translationName ? <>
                            <button type='button' class='btn btn-sm btn-link text-muted' onClick={this.showOriginal}><i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text></button>
                        </> : <>
                            <button type='button' class='btn btn-sm btn-link text-muted' onClick={this.translate}><i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text></button>
                        </>}
                    </>}
                </div>

                <div class='mt-2'>
                    <div class='d-flex justify-content-between mt-2'>
                        <div class='mr-2'>
                            <LikesAndComments
                                stores={this.props.stores}
                                likes={likes}
                                comments={comments}
                                type='team'
                                onTeamClick={onTeamClick}
                                onUserClick={onUserClick}
                            />
                        </div>
                        <div class='d-flex justify-content-between align-items-center'>
                            {views > 0 && <>
                                <Views
                                    stores={this.props.stores}
                                    views={views}
                                    object={feed}
                                    type='team'
                                    isTeamAdmin={isTeamAdmin}
                                    isAdmin={isAdmin}
                                    onTeamClick={onTeamClick}
                                    onUserClick={onUserClick}
                                />
                            </>}
                            <div class='ml-2'>
                                <i
                                    class={`fa-${user.favoriteWorkout?.indexOf(feed.id) > -1 ? 'solid' : 'regular'} fa-star`}
                                    style='font-size: 1.1em;'
                                    onClick={this.favoriteWorkout}
                                    data-value={feed.id}
                                    data-isfavorite={user.favoriteWorkout?.indexOf(feed.id) > -1}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {!isYourTeam && !isFollowing && !hasRequestedFollow && <div class='clearfix mb-2'>
                    <PublicTeam stores={this.props.stores} team={team} buttonOnly={true} />
                </div>}

                <div>
                    <div class='row'>
                        <div class='col text-center'>
                            <button type='button' class={`imageContainer btn btn-sm btn-outline-${hasLikedWorkout ? 'primary' : 'secondary'} btn-lg btn-block ${hasLikedWorkout ? 'text-primary' : ''} text-overflow`}
                                data-id={feed.id}
                                onClick={this.likeWorkout}
                                // disabled={hasLikedWorkout}
                            >
                                <i class={`${hasLikedWorkout ? 'text-primary fas' : 'far'} fa-thumbs-up`} /> <Text id='comments.like'>Lik</Text>
                            </button>
                        </div>
                        <div class='col text-center'>
                            <button type='button' class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow'
                                data-id={feed.id}
                                onClick={this.toggleAddComment}
                            >
                                <i class='fas fa-comment' /> <Text id='comments.comment-add'>Kommentar</Text>
                            </button>
                        </div>
                        {hasShareApi ? <>
                            <div class='col text-center'>
                                <button class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow'
                                    onClick={this.shareLink}
                                    data-url={`https://themusher.app/share/workout/${feed.uuidv4}`}
                                    data-title={`title: ${feed.title}`}
                                >
                                    <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                </button>
                            </div>
                        </> : <>
                            <div class='col text-center'>
                                <a class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow text-secondary'
                                    href={`https://themusher.app/share/workout/${feed.uuidv4}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    data-native
                                >
                                    <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                </a>
                            </div>
                        </>}
                    </div>
                </div>

                <div class='mt-2'>
                    {showAddComment[feed.id] && <WorkoutAddComment
                        stores={this.props.stores}
                        workout={feed}
                        inputRef={c => this.inputRef[feed.id] = c}
                        inputRefObject={this.inputRef}
                        col='12'
                        offset='0'
                        showAvatar={true}
                        extraClass='px-0'
                        callback={this.addCommentToFeed}
                    />}
                    <WorkoutComments
                        stores={this.props.stores}
                        workout={feed}
                        col='12'
                        offset='0'
                        compact={false}
                        callback={this.addCommentToFeed}
                        displayAllComments={displayAllComments}
                    />
                </div>
            </Field>
        </>);
    }
}

export default TeamPublicFeedLine;
