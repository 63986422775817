import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

@observer
class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { appState } = this.props.stores;
        const { darkmode } = appState;
        const {
            children,
            classNames = `${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`,
            classNamesPadding = 'px-4 py-3',
            classNamesMargin = '',
            classNamesMainPadding = 'px-4 py-3',
            classWidth = 'col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3',
            cssStyles = '',
            header,
            headerRight,
            headerSize = 'normal',
            icon = 'fa-light fa-paw',
            onClick = () => {},
            onHeaderClick = () => {},
            dataset = {},
            page,
            submenu,
        } = this.props;

        let headerStyle;
        switch(headerSize) {
            case 'small':
                headerStyle='font-size: 13px; font-weight: 400;';
                break;
            case 'normal':
                headerStyle='font-size: 15px; font-weight: 510;';
                break;
            case 'large':
                headerStyle='font-size: 18px; font-weight: 600;';
                break;
            default:
                headerStyle='font-size: 18px; font-weight: 600;';
        };

        return (<>
            <div class={`${classWidth} ${classNamesMainPadding}`}>
                <div
                    class={`rounded-lg widget-container overflow-hidden ${classNames} ${classNamesPadding} ${header ? '' : classNamesMargin}`}
                    style={`border-radius: 10px !important; ${cssStyles}`}
                    onClick={onClick}
                    data-page={page}
                    data-submenu={submenu}
                    {...dataset}
                >
                    {(header || headerRight) && <>
                        <div class='w-100 pt-0 px-0 pb-0 d-flex justify-content-between' onClick={onHeaderClick}>
                            {header ? <>
                                <span
                                    class='text-secondary font-weight-bold'
                                    style={`${headerStyle}`}
                                >
                                    <nobr>
                                        <i class={icon} /> {header}
                                    </nobr>
                                </span>
                            </> : <>
                                <span>&nbsp;</span>
                            </>}
                            {headerRight && <>
                                <span
                                    class='text-secondary'
                                    style={`${headerStyle}`}
                                >
                                    <nobr>
                                        {headerRight} <i class='fa-solid fa-angle-right' />
                                    </nobr>
                                </span>
                            </>}
                        </div>
                    </>}
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </>);
    }
}

export default Widget;
