import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

@observer
class WidgetHead extends Component {
    render() {
        const { appState } = this.props.stores;
        const { darkmode } = appState;
        const {
            children,
            // classNames = `${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`,
            title = 'Widget',
            right,
            onRightClick = () => {},
        } = this.props;
        return (<>
            <div
                class={`fixed-top w-100 pb-2 pt-2 d-flex justify-content-between appHeader border-bottom px-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                style='height: 40px;'
            >
                <div class='w-25'>&nbsp;</div>
                <div class='w-50 text-center font-weight-bold'>{title}</div>
                <div class='w-25 text-right pr-4 text-primary' onClick={onRightClick}>{right || <>&nbsp;</>}</div>

                {/* <div class='w-25 text-right pr-4 text-primary' onClick={this.newDog}><Text id='workout.training-log'>Add dog</Text></div> */}
            </div>
        </>);
    }
}

export default WidgetHead;
