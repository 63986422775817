import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

function kmtToMph(value) {
    return 0.621371 * value;
}

function getValue(value, settings) {
    if (value <= 0) {
        return '--,-';
    }
    if (settings.imperial) {
        return util.format(kmtToMph(value), 1)
    }
    return util.format(value, 1);
}

@observer
class Speed extends Component {
    render() {
		const { value = 0, smallUnit } = this.props;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;

        if (settings.imperial) {
            return (<>
                <nobr>
                    {getValue(value, settings)} {smallUnit ? <><small><small><small class='ml-0'><Text id='workouts.mph'>mph</Text></small></small></small></> : <><Text id='workouts.mph'>mph</Text></>}
                </nobr>
            </>);
        }

		return (<>
            <nobr>
                {getValue(value, settings)} {smallUnit ? <><small><small><small class='ml-0'><Text id='workouts.kmt'>km/t</Text></small></small></small></> : <><Text id='workouts.kmt'>km/t</Text></>}
            </nobr>
        </>);
    }
}

export default Speed;
