import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';

import MiniGraph from '../../components/workout/miniGraph';
import Gauge from '../../components/gauge';

import WorkoutTotals from '../../components/workout/totals';
import WorkoutCalendar from '../../components/workout/calendar';
import WorkoutTypeHeader from '../../components/workout/typeHeader';


import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

function kmToMiles(value) {
    return util.format(0.621371 * value, 1);
}

@observer
class WorkoutMonthlyActivities extends Component {
    render() {
        const {
            showHeader = true,
        } = this.props;

        const { userStore, workoutPlanStore } = this.props.stores;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;
        const { language = 'en', settings = {} } = user;
        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const prevMonthDate = new Date(currentYear, currentMonth - 2, 1);
		const prevMonth = prevMonthDate.getMonth() + 1;
        const prevYear = prevMonthDate.getFullYear();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm,
			elevation: weekElevation,
			duration: weekDuration,
			speedAvg: weekSpeedAvg,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
            workoutSummaryDaysCurrentMonth,
            workoutSummaryDaysCurrentMonthPrevSeason,
            graphworkoutSummaryDaysCurrentMonth,
            graphworkoutSummaryDaysCurrentMonthPrevMonth,
            graphworkoutSummaryDaysCurrentMonthPrevSeason,
            workoutSummaryWeekDay = [],
		} = userStore;

        const workoutSummaryMonth = userStore.findWorkoutSummaryMonth(currentYear, currentMonth, 'all') || {};

        // const planMonthDistanceKm = workoutPlanStore.sumWorkoutPlanMonth(currentYear, currentMonth, 'distance');
        // const planMonthCount = workoutPlanStore.sumWorkoutPlanMonth(currentYear, currentMonth, 'count');
		// const planMonth = workoutPlanStore.sumWorkoutPlanMonth(parseInt(year, 10), parseInt(month, 10)) || 0;
		// const summaryMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'distanceKm', true) || 0;

        // let percentComplete;
		// if (planMonth) {
		// 	percentComplete = Math.floor(summaryMonth / planMonth * 100);
		// }

        // const totalCountMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'count', true) || 0;
		// const totalDistanceKmMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'distanceKm', true) || 0;
		// const totalDurationMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'duration', true) || 0;
		// const totalSpeedavgMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'speedAvg', true) || 0;
		// const totalElevationMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'elevation', true) || 0;

        const { graphPlanDaysDistanceCurrent } = workoutPlanStore;

        const months = mu.getMonths(language);

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.stats'>Montly activities</Text>
                        </h3>
                    </div>}

                    <WorkoutTypeHeader stores={this.props.stores} />

                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    <Widget stores={this.props.stores}>
                        {/* planMonthDistanceKm: {planMonthDistanceKm}<br />
                        planMonthCount: {planMonthCount}<br />
                        workoutSummaryDaysCurrentMonth: {JSON.stringify(workoutSummaryDaysCurrentMonth, null, 4)}<br />
                        workoutSummaryDaysCurrentMonthPrevSeason: {JSON.stringify(workoutSummaryDaysCurrentMonthPrevSeason, null, 4)}<br />
                        graphworkoutSummaryDaysCurrentMonth: {JSON.stringify(graphworkoutSummaryDaysCurrentMonth, null, 4)}<br />
                        graphworkoutSummaryDaysCurrentMonthPrevSeason: {JSON.stringify(graphworkoutSummaryDaysCurrentMonthPrevSeason, null, 4)}<br /> */}
                        <h5>
                            {workoutSummaryMonth.count || 0} <Text id='workouts.activities-this-month'>activities this month</Text>
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={workoutSummaryMonth.distanceKm} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.duration'>Duration</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Duration stores={this.props.stores} value={workoutSummaryMonth.duration} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.elevation'>Elevation</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Elevation stores={this.props.stores} value={workoutSummaryMonth.elevation} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.activities'>Activities</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    {workoutSummaryMonth.count}
                                </h5>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <MiniGraph
                                    stores={this.props.stores}
                                    legendLeft={[`${months[currentMonth]} ${currentYear}`, `${months[prevMonth]} ${prevYear} `, 'workoutplan']}
                                    dataLeft={[graphworkoutSummaryDaysCurrentMonth, graphworkoutSummaryDaysCurrentMonthPrevMonth, graphPlanDaysDistanceCurrent]}
                                    yTicksLeftVal={val => val}
                                    yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                                    showYTicksLeft={true}
                                    xTicksType='day'
                                    showDebug={false}
                                    width={500}
                                    height={300}
                                />
                            </div>
                        </div>
                        {/* <div class='text-right text-muted mt-2'>
                            <small>
                                <Text id='workout.more-stats'>View more monthly stats</Text>
                            </small>
                        </div> */}
                    </Widget>


                    {/* <WorkoutCalendar stores={this.props.stores}
							year={year}
							month={month}
							chooseDayFunction={this.chooseDay}
							key={`calendar-header-${year}-${month}-${currentTeam}-}-${workoutSummaryWeekDay.length}`}
							hideTable={1}
							hideSummary={1}
							skipLoadSummary={1}
						/> */}

						{/* <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-2 mb-2'>
							<div class='row'>
								<div class='col-4 text-center'>
									{planMonth >= 0 && (
										<Gauge
											key={`gauge-${percentComplete}`}
											radius={55}
											percent={percentComplete}
											prefix='%'
											total={settings.imperial ? `${kmToMiles(planMonth)} mi` : `${util.format(planMonth, 0)} km`}
											completed={settings.imperial ? `${kmToMiles(summaryMonth)} mi` : `${util.format(summaryMonth, 0)} km`}
										/>
									)}<br />
									<small class='text-muted'><Text id='workout.index.plan'>Treningsplan</Text></small>
								</div>
								<div class='col-4 text-center'>
									<h5 class='font-weight-light'><span class='text-muted font-weight-lighter'><i class='fas fa-road' /></span></h5>
									<h3>
										<Distance stores={this.props.stores} value={totalDistanceKmMonth} smallUnit={true} />
									</h3>
									<small class='text-muted'>
										<Text id='workout.distance'>Distanse</Text>
									</small>
								</div>
								<div class='col-4 text-center'>
									<h5 class='font-weight-light'><span class='text-muted font-weight-lighter'><i class='fas fa-tachometer-alt ml-3' /></span></h5>
									<h3>
										<Speed stores={this.props.stores} value={totalSpeedavgMonth} smallUnit={true} />
									</h3>
									<small class='text-muted'>
										<Text id='workout.avg-speed'>Gj.snittsfart</Text>
									</small>
								</div>
								<div class='col-12 text-center'>
									<span class='text-muted font-weight-lighter'><i class='fas fa-running' /> </span> {totalCountMonth}
									<span class='text-muted font-weight-lighter'><i class='fas fa-clock ml-3' /></span> {util.secToHms(totalDurationMonth)}
								</div>
							</div>
						</div> */}


                </div>
            </div>
        </>);
    }
}

export default WorkoutMonthlyActivities;
