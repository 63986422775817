import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import DogSimpleV2 from '../../../components/dog/simple-v2';

import mu from '../../../lib/musher-util';

@observer
class ProfileDog extends Component {
    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('newDog', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const el = e.target.closest('.dogDetail');
        const id = el?.dataset?.id;
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        if (id) {
            appState.openDrawer('widgetProfileDogDetail', {
                height: drawerHeightLarge,
                callback: this.toggleDrawer,
                borderRadius: '',
                showCloseButton: false,
                showBackButton: true,
                backButtonText: 'Profile',
                id,
            });
        } else {
            appState.openDrawer('widgetProfileDogList', {
                height: drawerHeightLarge,
                callback: this.toggleDrawer,
                borderRadius: '',
                showCloseButton: false,
                showBackButton: true,
                backButtonText: 'Profile'
            });
        }
    }

    render() {
        const { imageElementScale } = this.state;
        const { userStore, teamStore, appState, workoutStore } = this.props.stores;
        const { darkmode } = appState;
        const team = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const teamImage = mu.getImage({ team });
        const userImage = mu.getImage({ user });
        const headerImage = mu.getImage({ team, field: 'imageHeader', size: 's3XXLargeLink', fallback: './assets/homerun-race-logo.png' });
        const dogs = userStore.findDogsByTeam(team.id);

        let dogColWidth = 12;
        if (dogs.length >= 4) {
            dogColWidth = 5;
        } else if (dogs.length === 3) {
            dogColWidth = 4;
        } else if (dogs.length === 2) {
            dogColWidth = 6;
        }

        if (dogs.length === 0) {
            return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} headerRight={'View all'} onHeaderClick={this.onClick} onClick={this.onClick}>
             <div class='w-100 position-relative px-3 pb-3 pt-2'>
                    <div
                        class={`col-4 rounded-lg text-center text-muted p-3 clearfix`}
                        style='
                            height: 100px;
                            border: 2px dashed #ccc;
                            border-radius: 20px !important;
                            min-height: 125px;
                        '
                        onClick={this.newDog}
                    >
                        <div class='display-4'><i class='fa-regular fa-circle-plus' /></div>
                        <Text id='dogs.add-dogs'>Add new dog</Text>
                    </div>

                    {/* <div class='mb-2'>
                        <Text id='dogs.no-dogs-registered'>You have not added any dogs yet. Add one or more dogs here</Text>
                    </div> */}
                    {/* <button type='button' class='btn btn-sm btn-block btn-outline-primary' onClick={this.newDog}>
                        <Text id='dogs.add-dogs'>Add dogs</Text>
                    </button> */}
                </div>
            </Widget>);
        }

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} headerRight={'View all'} onHeaderClick={this.onClick} onClick={this.onClick}>
             <div class='w-100 position-relative px-3 pb-3 pt-2'>
                <div
                    class={`d-flex flex-row flex-nowrap no-scrollbar`}
                    style={`
                        overflow-x: ${imageElementScale > 1 && dogs.length === 1 ? 'visible !important' : 'auto'};
                        overflow-y: ${imageElementScale > 1 ? 'visible !important' : 'auto'};
                        scroll-snap-type: x mandatory;
                    `}
                    onScroll={this.scrollImages}
                    ref={c => this.imageScrollerRef = c}
                >
                    {dogs && dogs.map(dog => <>
                        <div
                            class={`col-${dogColWidth} clearfix p-0 mx-2 dogDetail`}
                            key={`dog-id-${dog.id}`}
                            data-id={dog.id}
                        >
                            <div
                                class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center position-relative px-0`}
                                style={`
                                    scroll-snap-align: start;
                                    flex-wrap: wrap;
                                `}
                                // overflow: ${imageElementScale > 1 ? 'visible !important' : 'hidden'};
                                onTouchstart={(e) => { e.stopPropagation(); }}
                                onTouchend={(e) => { e.stopPropagation(); }}
                                onTouchmove={(e) => { e.stopPropagation(); }}
                            >
                                <DogSimpleV2
                                    key={`dog-id-${dog.id}`}
                                    stores={this.props.stores}
                                    dog={dog}
                                    showAge={true}
                                    className={'w-100'}
                                    onDogClick={this.onDogClick}
                                />
                            </div>
                        </div>
                    </>)}
                </div>
            </div>

        </Widget>);
    }
}

export default ProfileDog;
