import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';

import MiniGraph from '../../components/workout/miniGraph';

import WorkoutTotals from '../../components/workout/totals';
import WorkoutTypeHeader from '../../components/workout/typeHeader';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const WEEKDAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

function getIntensityColor(intensity) {
    let intensityBackground = `text-danger font-weight-bolder`;
    if (intensity >= 300) {
        // Default message
    } else if (intensity < 300 && intensity >= 150) {
        intensityBackground = `text-warning font-weight-bold`;
    } else if (intensity < 150 && intensity >= 50) {
        intensityBackground = `text-success font-weight-normal`;
    } else if (intensity < 50 && intensity > 0) {
        intensityBackground = `text-info font-weight-light`;
    } else {
        intensityBackground = `text-secondary font-weight-lighter`;
    }
    return intensityBackground;
}

@observer
class WorkoutIntensity extends Component {
    onWorkoutClick = e => {
		e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
		const { id } = e.target.closest('.workoutBubble').dataset;

        appState.openDrawer2('workoutDetail', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            id: parseInt(id, 10),
        });
    }

    toggleDrawer = (e) => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const {
            showHeader = true,
        } = this.props;

        const { userStore, workoutPlanStore } = this.props.stores;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;
        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm,
			elevation: weekElevation,
			duration: weekDuration,
			speedAvg: weekSpeedAvg,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
            graphWorkoutSummaryWeekLoadIndexLastPeriode,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
            seasonWeeksSoFar,
            workoutCurrentSeason,
		} = userStore;

        const workoutCurrentWeekMax = workoutCurrentWeek.reduce((max, workout) => Math.max(max, workout.distanceKm), 0);
		const weekLoadCompared = Math.floor(weekLoadIndex / (workoutSummaryWeekAvgLoadLastPeriode || 1) * 100);

        const workoutCurrentSeasonMiddle = mu.avgOfValues(workoutCurrentSeason.map(w => w.loadIndex));

        let intensityMessage = `Way above normal! Be careful and remember to rest well!`;
		let intensityBackground = `bg-danger`;
		if (weekLoadCompared >= 300) {
			// Default message
		} else if (weekLoadCompared < 300 && weekLoadCompared >= 150) {
			intensityMessage = `Above normal! Intense week. You fitness is beeing improved efficiently with polarized training.`;
			intensityBackground = `bg-warning`;
		} else if (weekLoadCompared < 150 && weekLoadCompared >= 50) {
			intensityMessage = `Normal! Normal workout week. Good job!`;
			intensityBackground = `bg-success`;
		} else if (weekLoadCompared < 50 && weekLoadCompared > 0) {
			intensityMessage = `Very easy week! Remember to go out and move around!`;
			intensityBackground = `bg-info`;
		} else {
			intensityMessage = `No intensity data registered this week.`;
			intensityBackground = `bg-secondary`;
		}

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.intensity'>Intensity</Text>
                        </h3>
                    </div>}


                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center mt-3'>
                        <div
                            class={`display-3 text-white text-center ${intensityBackground} px-3 pt-2 overflow-hidden`}
                            style='border-radius: 20px 20px 0px 0px; height: 110px;'
                        >
                            {weekLoadCompared}%
                        </div>
                        <div
                            class={`text-white ${intensityBackground} px-3 pb-2`}
                            style='line-height: 0.9em; border-radius: 0px 0px 20px 20px;'
                        >
                            <small>
                                {intensityMessage}
                            </small>
                        </div>

                        {/* workoutSummaryWeekAvgLoadLastPeriode: {workoutSummaryWeekAvgLoadLastPeriode}
                        weekLoadIndex: {weekLoadIndex}
                    weekLoadCompared: {weekLoadCompared}% */}
                    </div>

                    <WorkoutTypeHeader stores={this.props.stores} />

                    <Widget stores={this.props.stores} onClick={this.openDrawer} page='workoutIntensity'>
                        <h5>
                            <Text id='workouts.weekly-intensity'>Weekly intensity last 2 months</Text>
                        </h5>
                        <div class='row'>
                            <div class='col-12'>
                                <MiniGraph
                                    stores={this.props.stores}
                                    // legendLeft={[`${seasonStart}`]}
                                    dataLeft={[graphWorkoutSummaryWeekLoadIndexLastPeriode]}
                                    yTicksLeftVal={val => val}
                                    showDebug={false}
                                    width={500}
                                    height={150}
                                    xTicksCnt={3}
                                    showXTicks={1}
                                    showYTicksLeft={1}
                                />
                            </div>
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores}>
                        <h5>
                            <Text id='workouts.season-intensity'>Weekly intensity season</Text>
                        </h5>
                        <div class='row'>
                            <div class='col-12'>
                                <MiniGraph
                                    stores={this.props.stores}
                                    legendLeft={[`${seasonStart}`, `${seasonStart - 1}`]}
                                    dataLeft={[graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev]}
                                    yTicksLeftVal={val => val}
                                    showDebug={false}
                                    width={1000}
                                    height={300}
                                />
                            </div>
                        </div>
                    </Widget>

                    {seasonWeeksSoFar && seasonWeeksSoFar.length > 0 && seasonWeeksSoFar.reverse().map(week => {
                        const daysInWeek = util.getDaysInWeek(week.week, week.year, week.month);
                        const firstDayInWeek = daysInWeek[0];
                        const lastDayInWeek = daysInWeek[daysInWeek.length - 1];
                        const workoutsInWeek = userStore.getWorkoutsInWeek(week.year, week.week, week.month);
                        const avgWeek = mu.avg(workoutsInWeek.map(w => w.loadIndex));
                        return (<>
                            <Widget stores={this.props.stores}>
                                <div class=''>
                                    <small class={`float-right ${getIntensityColor(avgWeek)}`}>
                                        {Math.floor(avgWeek)}
                                    </small>
                                    <small class='float-left'>
                                        w{week.week} {week.year}:
                                        <span class='text-muted ml-2'>
                                            {mu.formatDate(firstDayInWeek, { day: 'numeric', month: 'numeric' })} - {mu.formatDate(lastDayInWeek, { day: 'numeric', month: 'numeric' })}
                                        </span>
                                    </small>
                                    <br />
                                    <div class='d-flex justify-content-between '>
                                        {/* <xmp>{JSON.stringify(workoutCurrentWeek, null, 2)}</xmp> */}
                                        {workoutsInWeek && workoutsInWeek.length > 0 && workoutsInWeek.map((day, idx) => {
                                            const size = day.loadIndex / workoutCurrentSeasonMiddle;
                                            let pixelSize = Math.floor(40 * size);
                                            pixelSize = pixelSize < 25 ? 25 : pixelSize;
                                            pixelSize = pixelSize > 55 ? 55 : pixelSize;
                                            return (<>
                                                <div class='' style='width: 14.2%'>
                                                    <div class='d-flex justify-content-center align-items-center' style='height: 60px;'>
                                                        {day.loadIndex > 0 ? <>
                                                            <div
                                                                class='text-white bg-primary d-flex align-items-center justify-content-center workoutBubble'
                                                                style={`width: ${pixelSize}px; height: ${pixelSize}px; border-radius: 50%;`}
                                                                data-id={day.id}
                                                                onClick={this.onWorkoutClick}
                                                            >
                                                                <small>
                                                                    {day.loadIndex}
                                                                </small>
                                                            </div>
                                                        </> : <>.</>}
                                                    </div>
                                                    <div class='text-muted text-center'>
                                                        <small>
                                                            {WEEKDAYS[idx]}
                                                        </small>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </Widget>

                        </>);
                    })}


                </div>
            </div>
        </>);
    }
}

export default WorkoutIntensity;
