import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { toJS } from 'mobx';
import Markdown from 'preact-markdown';

import TeamConnectBoxes from '../../components/team/connect-boxes';
import TeamConnectConfirm from '../../components/team/connect-confirm';

import Ad from '../../components/ad/';

import FastList from '../../components/team/fastList';

import Map from '../../components/map/mapbox';

import Overlay from '../../components/overlay/';
import TeamsPublicDetail from '../../routes/teams/publicDetail';
import DogPublicDetail from '../../routes/dogs/publicDetail';
import DogSimplePublic from '../../components/dog/simplePublic';

import ImageScroller from '../../components/imageScroller';
import DogScroller from '../../components/dog/scroller';
import WorkoutComments from '../../components/workout/comments';
import WorkoutAddComment from '../../components/workout/commentAdd';
import WorkoutInfoGraph from '../../components/workout/infoGraph';

import PublicTeam from '../../components/team/public';

import LikesAndComments from '../../components/feedback/likesAndComments';
import Views from '../../components/feedback/views';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

import ScrollIntoView from '../../components/util/scrollIntoView';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

const initialState = {
	showAddComment: {},
    // viewImageIdx: {},
    likeWorkout: {},
    hasFollowSugguestions: false,
    hasAd: false,
    sessionid: new Date().getTime(),
    submenu: 'summary',
};

const dataFields = ['id', 'type', 'user', 'team', 'title'];

@observer
class WorkoutPublicDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.inputRef = {};
        this.containerRef = null;
    }

    async loadPublicWorkout({ props = this.props }) {
		const { teamStore } = props.stores;
        const { workout: workoutid } = props;
		await teamStore.loadPublicFeed({ workoutid });
	}

    toggleDate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

	likeWorkout = async (e) => {
        const workoutId = parseInt(e.target.closest('.imageContainer').dataset.id, 10);
		const { workoutStore } = this.props.stores;

        const { likeWorkout } = this.state;
        likeWorkout[workoutId] = true;
        this.setState({ likeWorkout });
        setTimeout(() => {
            const { likeWorkout } = this.state;
            delete likeWorkout[workoutId];
            this.setState({ likeWorkout });
        }, 1200);

		const response = await workoutStore.likeWorkout({ id: workoutId });
        if (response && response.status === 200) {
            this.addLikeToFeed(workoutId, response.data);
		}
	}

    addCommentToFeed = ({ type, workout, response, commentId, replyId }) => {
        // console.log('addCommentToFeed', { workout, response, commentId });
        const { holdingStore = 'team' } = this.props;
        if (holdingStore === 'team') {
            const { teamStore } = this.props.stores;
            switch (type) {
                case 'likeComment':
                    teamStore.addLikeToComment({ id: workout.id, data: response.data, commentId });
                    break;
                case 'likeReply':
                    teamStore.addLikeToCommentReply({ id: workout.id, data: response.data, commentId, replyId });
                    break;

                default:
                    if (commentId) {
                        teamStore.addCommentToWorkoutComment({ id: workout.id, data: response.data, commentId });
                    } else {
                        teamStore.addCommentToWorkout({ id: workout.id, data: response.data });
                    }
                    break;
            }
        } else if (holdingStore === 'story') {
            const { storyStore } = this.props.stores;
            switch (type) {
                case 'likeComment':
                    storyStore.workoutsAddLikeToComment({ id: workout.id, data: response.data, commentId });
                    break;
                case 'likeReply':
                    storyStore.workoutsAddLikeToCommentReply({ id: workout.id, data: response.data, commentId, replyId });
                    break;

                default:
                    if (commentId) {
                        storyStore.workoutsAddCommentToWorkoutComment({ id: workout.id, data: response.data, commentId });
                    } else {
                        storyStore.workoutsAddCommentToWorkout({ id: workout.id, data: response.data });
                    }
                    break;
            }
        }
    }

    addLikeToFeed = (id, data) => {
        const { holdingStore = 'team' } = this.props;
        if (holdingStore === 'team') {
            const { teamStore } = this.props.stores;
            teamStore.addLikeToWorkout({ id, data });
        } else if (holdingStore === 'story') {
            const { storyStore } = this.props.stores;
            storyStore.workoutsAddLikeToWorkout({ id, data });
        }
    }

	toggleAddComment = (e) => {
		const workoutId = e.target.closest('button').dataset.id;
		const { showAddComment } = this.state;
        if (showAddComment[workoutId]) {
            delete showAddComment[workoutId];
        } else {
            showAddComment[workoutId] = true;
        }
		this.setState({
			showAddComment,
		}, () => {
			if (this.inputRef[workoutId]) {
				this.inputRef[workoutId].focus();
			}
		});
	}

    toggleMenu = (e) => {
        const { id } = e.target.closest('i').dataset;
		const { showMenu = {} } = this.state;
        showMenu[id] = !showMenu[id];
		this.setState({
			showMenu,
		});
	}

    toggleAdminDetails = () => {
        const { showAdminDetails } = this.state;
        this.setState({
            showAdminDetails: !showAdminDetails,
        });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    reportWorkout = async (e) => {
        const { id } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        await teamStore.reportWorkout({ id });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    adminRemoveWorkout = async (e) => {
        const { id } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        await teamStore.adminHideWorkout({ id });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    shareLink = (e) => {
        const { url, title, text } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        appState.shareUrl({ url, title, text });
    }

    showWorkoutDetail = (e) => {
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('#workoutDetail').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicWorkout', {
            field,
            type,
            title,
            help,
            id,
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightMedium,
        });
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { feed = {} } = this.props;
        const { userStore, teamStore } = this.props.stores;
        const { user } = userStore;
        const translatedTextName = await teamStore.translate({ object: feed, user, field: 'name' });
        let translatedTextComment;
        if (feed.comment) {
            translatedTextComment = await teamStore.translate({ object: feed, user, field: 'comment' });
        }
        this.setState({ showOriginal: 0, translatedTextName, translatedTextComment });
    }

    showOriginal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginal: 1 });
    }

    onTeamClick = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicTeam', { teamId, height: drawerHeightMedium });
	}

    onUserClick = (e, fromuser) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('inboxDetail', { fromuser, height: drawerHeightMedium });
    }

    onDogClick = (e, dogId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        // const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicDog', { dogId, height: drawerHeightMedium });
	}

    onMapClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        // const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicWorkoutMap', { id: workoutId, height: drawerHeightMedium });
	}

    editWorkoutField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('editWorkout', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

    favoriteWorkout = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value, isfavorite } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (isfavorite) {
            await userStore.removeField({ email: user.email, field: 'favoriteWorkout', value: parseInt(value, 10) });
        } else {
            await userStore.updateUserArray({ email: user.email, field: 'favoriteWorkout', value: parseInt(value, 10) });
        }
    }

    componentWillUnmount() {
        const { teamStore } = this.props.stores;
        // teamStore.cleanupMemory();
    }

	componentDidMount() {
        this.loadPublicWorkout(this.props);
	}

	componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps.viewmenu, this.props.viewmenu);
        if (nextProps.workoutid !== this.props.workoutid) {
            this.loadPublicWorkout(nextProps);
        }
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    render() {
        const {
            showAddComment,
            showDate,
            likeWorkout,
            showOriginal,
            translatedTextName,
            translatedTextComment,
            showAdminDetails,
            showMenu = {},
            submenu,
            mapHeight = 500,
        } = this.state;

        const {
            holdingStore = 'team',
            displayAllComments = 'true',
            marginTop,
            marginBottom,
        } = this.props;
        const { teamStore, storyStore, userStore, appState } = this.props.stores;
        const { publicWorkout = {} } = teamStore;
        const { user, isAdmin } = userStore;
        const { language = 'en' } = user;
        const { darkmode, hasShareApi } = appState;
        const { mapColorMode, mapRangeMin, mapRangeMax, isCordova, viewmode } = appState;
        let publicDogs;

        let team = {};
        let publicUser = {};
        if (holdingStore === 'team') {
            team = teamStore.findPublicTeam(publicWorkout.team) || {};
            publicUser = teamStore.findPublicUser(publicWorkout.user) || {};
            const { publicDogs: teamPublicDogs } = teamStore;
            publicDogs = teamPublicDogs;
        } else if (holdingStore === 'story') {
            team = storyStore.findPublicTeam(publicWorkout.team) || {};
            publicUser = storyStore.findPublicUser(publicWorkout.user) || {};
            const { publicDogs: storyPublicDogs } = storyStore;
            publicDogs = storyPublicDogs;
        }
        // console.log({ holdingStore, team, id = publicWorkout.team });
        const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        const teamType = userStore.findTeamType({ type: team.type, language });

        const isTeamAdmin = (team && team.members) ? team.members.some(m => m === user.id) : false;

        const image = mu.getImage({ user: publicUser, team, object: publicWorkout, priority: 'user' });

        const { likes, comments, reports = [] } = publicWorkout;
        const hasLikedWorkout = publicWorkout.likes && publicWorkout.likes?.findIndex(e => e.user === user.id) > -1;
        const totalFollowers = team && team.followers ? team.followers.length : 0;

        const isYourTeam = team.members && team.members?.indexOf(user.id) > -1;
        const isFollowing = team && team.followers && team.followers?.indexOf(user.id) > -1;
        const hasRequestedFollow = team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;
        const workoutEquipment = userStore.findEquipment({ equipment: publicWorkout.equipment, language });
        const workoutEquipmentIcon = userStore.findEquipment({ equipment: publicWorkout.equipment, field: 'icon' });
		const { gpxInfo = {} } = publicWorkout;

        let shortname;
        let name;
        let username;
        let userLink;
        if (publicUser) {
            shortname = mu.displayNameShort(publicUser);
            name = mu.displayName(publicUser);
            username = mu.displayName(publicUser);
            if (publicUser.settings && publicUser.uuidv4 !== user.uuidv4) {
                userLink = publicUser.settings.hideProfile ? null : `/inbox/${publicUser.uuidv4}`;
            }
        }

        const images = [];
        if (publicWorkout.images) {
            const imgs = toJS(publicWorkout.images);
            images.push(...imgs);
        }

        const { currentLocationGeocode = {}, yrWeather = [] } = publicWorkout;
        const views = mu.getViews(publicWorkout.statistics);
        const adminData = mu.getAdminData(publicWorkout.statistics);

        if (!team || !team.name) {
            return '';
        }

        const translationName = mu.getTranslation({
            object: publicWorkout,
            targetLang: language,
            field: 'name',
        }) || translatedTextName;
        let feedName = translationName || publicWorkout.name;
        const translationComment = mu.getTranslation({
            object: publicWorkout,
            targetLang: language,
            field: 'comment',
        }) || translatedTextComment;
        let feedComment = translationComment || publicWorkout.comment;

        if (showOriginal) {
            feedName = publicWorkout.name;
            feedComment = publicWorkout.comment;
        }

        const {
            publicWorkouts,
            publicWorkoutDetails,
            publicWorkoutSummary,
            publicTrophys,
            publicMemories,
        } = team;

        return (<>
			<div class={`container-fluid pb-5 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <div class='row mb-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 position-relative'>
                        {(isAdmin || (user.id === publicWorkout.user)) ? <>
                            <span class='float-right font-weight-lighter text-muted'>
                                <i class='fas fa-ellipsis-h' onClick={this.toggleMenu} data-id={publicWorkout.uuidv4} />
                            </span>
                        </> : <>
                            {!isYourTeam && !isFollowing && !hasRequestedFollow && team.public > 0 && <span class='float-right'>
                                <PublicTeam stores={this.props.stores} team={team} buttonOnly={true} simple={true} classNames={'btn btn-link text-primary'} />
                            </span>}
                        </>}
                        {showMenu[publicWorkout.uuidv4] && <>
                            <div class='position-absolute bg-light p-2 border rounded rounded-lg shadow-sm' style='top: 30px; right: 10px; width: 60%; z-index: 999999;'>
                                <button class='btn btn-link btn-block text-left' onClick={this.reportWorkout} data-id={publicWorkout.uuidv4}><i class='fas fa-flag' /> Report</button>
                                {isAdmin && <>
                                    <hr />
                                    <button class='btn btn-link btn-block text-left text-danger' onClick={this.adminRemoveWorkout} data-id={publicWorkout.id}>
                                        <i class='fas fa-eraser' /> Remove from feed
                                    </button>
                                </>}
                                <button class='btn btn-link btn-block text-left' onClick={this.toggleAdminDetails}>
                                    <i class='fas fa-user-cog' /> Toggle admin details
                                </button>
                            </div>
                        </>}

                        <div class='d-flex position-relative'>
                            <div class='text-center'>
                                <div
                                    class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                                    style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
                                    {!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
                                </div>
                            </div>
                            <div class='flex-grow-1 pl-3 text-overflow' style='line-height: 1.2em;'>
                                {/* <span class='badge badge-pill badge-secondary float-right'>{teamType}</span> */}
                                <h5 class='mb-1 position-relative' style='line-height: 1.0em;'>
                                    {team.public ? <>
                                        <a href={`/teams/public/${team.uuidv4}`} onClick={(e) => this.onTeamClick(e, team.uuidv4)}>{team.name}</a>
                                    </> : <>
                                        {team.name}
                                    </>}
                                </h5>

                                <div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
                                    {publicWorkout.date && <small class='text-muted' onClick={this.toggleDate}>
                                        {showDate ? util.formatDate(publicWorkout.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' }) : util.formatDistance(publicWorkout.date, new Date(), { locale: 'no-NB' })}
                                    </small>}
                                    {username && <>
                                        <small class='ml-2 text-muted'>
                                            {userLink ? <>
                                                <i class='fas fa-user' /> <a href={userLink} onClick={(e) => this.onUserClick(e, publicUser.uuidv4)}>{username}</a>
                                            </> : <>
                                                <i class='fas fa-user' /> {username}
                                            </>}
                                        </small>
                                    </>}
                                    <small class='text-muted'>
                                        {publicWorkout.equipment > 0 && <><i class={`${workoutEquipmentIcon} ml-2`} /> {workoutEquipment}</>}
                                        {/* {publicWorkout.distanceKm > 0 && <><i class='fas fa-road ml-2' /> {displayDistance(publicWorkout.distanceKm)}km</>}
                                        {publicWorkout.elevation > 0 && <><i class='fas fa-mountain ml-2' /> {util.format(publicWorkout.elevation, 0)}m</>}
                                        {publicWorkout.speedAvg > 0 && <><i class='fas fa-tachometer-alt ml-2' /> {util.format(publicWorkout.speedAvg, 1)}km/t</>} */}
                                        {publicWorkout.dogs > 0 && <><i class='fas fa-dog ml-2' /> {publicWorkout.dogs.length}</>}
                                        {publicWorkout.track > 0 && <><i class={`fas fa-map-marked text-${publicWorkout.track > 0 ? 'success' : 'danger'} ml-2`} /></>}
                                        {/* {publicWorkout.dogsLeft > 0 && <><i class={`fas fa-crosshairs text-${publicWorkout.dogsLeft && publicWorkout.dogsRight ? 'success' : 'danger'} ml-2`} /></>} */}
                                        {publicWorkout.sledWeight > 0 && <>
                                            <i class='fas fa-sleigh ml-2' /> <Weight stores={this.props.stores} value={publicWorkout.sledWeight} />
                                        </>}
                                        {publicWorkout.temperature !== 0 && <>
                                            <i class='fas fa-temperature-low ml-2' /> <Temperature stores={this.props.stores} value={publicWorkout.temperature} />
                                        </>}
                                        {publicWorkout.dogs && publicWorkout.dogs.length > 0 && <>
                                            <i class='fas fa-dog ml-2' /> {util.format(publicWorkout.dogs.length, 0)}
                                        </>}
                                        {/* {publicWorkout.kcalBurnedTotal && <><i class='fas fa-balance-scale' /> {util.format(publicWorkout.kcalBurnedTotal)} kcal</>}
                                        {publicWorkout.kcalMeatGramsTotal && <><i class='fas fa-drumstick-bite ml-2' /> {util.format(publicWorkout.kcalMeatGramsTotal / 1000, 1)} kg</>}
                                        {publicWorkout.kcalSalmonGramsTotal && <><i class='fas fa-fish ml-2' /> {util.format(publicWorkout.kcalSalmonGramsTotal / 1000, 1)} kg</>}
                                        {publicWorkout.kcalFatGramsTotal && <><i class='fas fa-piggy-bank ml-2' /> {util.format(publicWorkout.kcalFatGramsTotal / 1000, 1)} kg</>} */}
                                        {currentLocationGeocode && currentLocationGeocode.formattedCounty && <>
                                            <i class='fas fa-map-marker-alt ml-2' /> {currentLocationGeocode.formattedCounty}
                                        </>}
                                        {publicWorkout.moonIllumination && publicWorkout.moonIllumination.phaseIcon && <span class='ml-2'>{publicWorkout.moonIllumination.phaseIcon}</span>}
                                    </small>
                                </div>

                                {showAdminDetails && isAdmin && <div
                                    class='d-flex flex-row flex-nowrap bg-light border-top border-bottom py-2'
                                    style='overflow: auto;'
                                >
                                    <small class='text-muted mr-2'>Adm:</small>
                                    {reports && reports.length > 0 && <>
                                        <small class='text-danger mr-2'>
                                            <i class='fas fa-flag' /> {reports.length}
                                        </small>
                                    </>}
                                    {currentLocationGeocode && currentLocationGeocode.formatted_address && <>
                                        <small class='text-muted'>
                                            <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formatted_address}
                                        </small>
                                    </>}
                                    {publicUser.deviceInfo && <>
                                        <span class='badge badge-secondary ml-1 float-right font-weight-light'>
                                            {['platform', 'manufacturer', 'model'].map(e => <>
                                                <span class='ml-1 text-white'>
                                                    {publicUser.deviceInfo[e]}
                                                </span>
                                            </>)}
                                        </span>
                                    </>}
                                    {adminData && <>
                                        {/* {JSON.stringify(adminData)}<br /> */}
                                        <small class='ml-2 text-muted'>
                                            V: {adminData.count}
                                        </small>
                                        <small class='ml-2 text-muted'>
                                            R: {adminData.ranking} {util.isNumber(adminData.rankingTrend) && <><i class={mu.getClassForTrend(adminData.rankingTrend)} /></>}
                                        </small>
                                        <small class='ml-2 text-muted'>
                                            D: {util.format(adminData.durationAvg / 1000, 1)}s {util.isNumber(adminData.durationAvgTrend) && <><i class={mu.getClassForTrend(adminData.durationAvgTrend)} /></>}
                                        </small>
                                        {publicWorkout.statisticsCurrent && publicWorkout.statisticsCurrent.totalInteractions > 0 && <small class='ml-2 text-muted'>
                                            S: {util.format(publicWorkout.statisticsCurrent.totalInteractions, 0)}
                                        </small>}
                                        {publicWorkout.shadowTags && publicWorkout.shadowTags.map(tag => <>
                                            <span class='badge badge-secondary ml-2 float-right font-weight-light'>{tag}</span>
                                        </>)}
                                    </>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div class='row mt-2'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
                        <strong><Markdown markdown={mu.replaceImages(feedName, publicWorkout.images, language, false)} markedOpts={mu.getMarkdownOptions()} /></strong>
                        <div class='clearfix col-12'>
                            <Markdown markdown={mu.replaceImages(feedComment, publicWorkout.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                        </div>
                        {publicWorkout.language !== language && <>
                            {!showOriginal && translationName ? <>
                                <button type='button' class='btn btn-sm btn-link text-muted' onClick={this.showOriginal}><i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text></button>
                            </> : <>
                                <button type='button' class='btn btn-sm btn-link text-muted' onClick={this.translate}><i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text></button>
                            </>}
                        </>}
                    </div>
                </div>

                <div class='row mt-2'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
                        <div class='row'>
                            <div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-0 px-1'
                                    style='overflow: auto; scroll-snap-type: x mandatory; line-height: 1.0em; height: 56px;'
                                >
                                    <div class='col px-0 pt-0'>
                                        <button
                                            class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'summary' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='summary'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fa-solid fa-eye' /> <Text id='workouts.summary'>Summary</Text></button>
                                        {/* {submenu === 'summary' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='pictures'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-images' /> <Text id='workouts.pictures'>Pictures</Text></button>
                                        {/* {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    {publicDogs && <div class='col px-0 pt-0'>
                                        <button
                                            class={`btn btn-block rounded-none ${submenu === 'dogs' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='dogs'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-dog' /> <Text id='workouts.dogs-menu'>Dogs</Text></button>
                                        {/* {submenu === 'dogs' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                    <div class='col px-0 pt-0'>
                                        <button
                                            class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'details' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='details'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-clock' /> <Text id='workouts.details'>Details</Text></button>
                                        {/* {submenu === 'details' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {submenu === 'summary' && <>
                    <div class='row mt-0'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-0'>
                            {publicWorkout.mapImage ? <>
                                <div class='container-fluid px-0 mt-1'>
                                    <div class='row'>
                                        <ImageScroller stores={this.props.stores} images={[publicWorkout.mapImage]} id={publicWorkout.id} dblClick={this.likeWorkout} onClick={this.onMapClick} likeRef={likeWorkout} />
                                    </div>
                                </div>
                            </> : <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fa-solid fa-map-location-dot' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='workout.no-gpx-title'>No gps data for this workout.</Text>
                                    </h3>
                                </div>
                            </>}

                            <div class='container-fluid px-0 mt-1'>
                                <div class='row'>
                                    <div class='col-12 d-flex flex-wrap justify-content-between bg-light rounded-lg px-2'>
                                        {publicWorkout.distanceKm > 0 && <div class='mr-2 py-2 text-center'>
                                            <div class='text-overflow text-muted'>
                                                <small><i class='fas fa-road' /> <Text id='workout.calendar.distance'>Distanse</Text></small>
                                            </div>
                                            <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                                <Distance stores={this.props.stores} value={publicWorkout.distanceKm} />
                                            </div>
                                        </div>}
                                        {publicWorkout.elevation > 0 && <div class='mr-2 py-2 text-center'>
                                            <div class='text-overflow text-muted'>
                                                <small><i class='fas fa-mountain' /> <Text id='workout.calendar.ascend'>Høydemeter</Text></small>
                                            </div>
                                            <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                                <Elevation stores={this.props.stores} value={publicWorkout.elevation} />
                                            </div>
                                        </div>}
                                        {publicWorkout.duration > 0 && <div class='mr-2 py-2 text-center'>
                                            <div class='text-overflow text-muted'>
                                                <small><i class='fas fa-clock' /> <Text id='workouts.duration'>Varighet</Text></small>
                                            </div>
                                            <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                                {util.secToHms(publicWorkout.duration, true)}
                                            </div>
                                        </div>}
                                        {publicWorkout.rest > 0 && <div class='mr-2 py-2 text-center'>
                                            <div class='text-overflow text-muted'>
                                                <small><i class='fas fa-clock' /> <Text id='workouts.rest'>Rest</Text></small>
                                            </div>
                                            <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                                {util.secToHms(publicWorkout.rest, true)}
                                            </div>
                                        </div>}
                                        {publicWorkout.speedAvg > 0 && <div class='mr-2 py-2 text-center'>
                                            <div class='text-overflow text-muted'>
                                                <small><i class='fas fa-tachometer-alt' /> <Text id='workout.calendar.avg-speed'>Snittfart</Text></small>
                                            </div>
                                            <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                                {gpxInfo && gpxInfo.avgGpsMovingSpeed > 0 ? <>
                                                    <Speed stores={this.props.stores} value={gpxInfo.avgGpsMovingSpeed * 3.6} />
                                                </> : <>
                                                    <Speed stores={this.props.stores} value={publicWorkout.speedAvg} />
                                                </>}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isTeamAdmin && <>
                        <button
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editWorkoutField}
                            data-field='comment'
                            data-type='textarea'
                            data-title='Workout comment'
                            data-id={publicWorkout.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-pen text-white' />
                        </button>
                    </>}
                </>}

                {submenu === 'dogs' && <>
                    <div class='row mt-0'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-0'>
                            {publicWorkout.dogs && publicWorkout.dogs.length > 0 ? <>
                                <div class='container-fluid px-0 mt-1'>
                                    <div class='row pt-2'>
                                        {publicWorkout.dogs && publicWorkout.dogs.map((dogId) => {
                                            const dog = publicDogs.find(e => e.id === dogId);

                                            return (
                                                <DogSimplePublic stores={this.props.stores} dog={dog} showAge={true} className={'col'}
                                                    onDogClick={this.onDogClick}
                                                />
                                            );
                                        })}
                                        {/* <DogScroller stores={this.props.stores} dogs={publicWorkout.dogs} allDogs={publicDogs} onDogClick={this.onDogClick} size={'large'} /> */}

                                    </div>
                                </div>
                            </> : <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fa-solid fa-dog' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='workout.no-dogs-title'>No dogs for this workout.</Text>
                                    </h3>
                                </div>
                            </>}
                        </div>
                    </div>
                </>}

                {submenu === 'pictures' && <>
                    <div class='row mt-0'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-0'>
                            {images && images.length > 0 ? <>
                                <div class='container-fluid px-0 mt-1'>
                                    <div class='row'>
                                        <ImageScroller stores={this.props.stores} images={images} id={publicWorkout.id} dblClick={this.likeWorkout} likeRef={likeWorkout} />
                                    </div>
                                </div>
                            </> : <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fa-solid fa-camera' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='workout.no-images-title'>No images for this workout.</Text>
                                    </h3>
                                </div>
                            </>}

                        </div>
                    </div>
                    {isTeamAdmin && <>
                        <button
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editWorkoutField}
                            data-field='images'
                            data-type='image'
                            data-title='Workout images'
                            data-id={publicWorkout.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-camera text-white' />
                        </button>
                    </>}
                </>}

                {submenu === 'details' && <>
                    <div class='row mt-0'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-0'>
                            <div class='d-flex flex-wrap justify-content-between bg-light rounded-lg px-2'>
                                {publicWorkout.distanceKm > 0 && <div class='mr-2 py-2 text-center'>
                                    <div class='text-overflow text-muted'>
                                        <small><i class='fas fa-road' /> <Text id='workout.calendar.distance'>Distanse</Text></small>
                                    </div>
                                    <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                        <Distance stores={this.props.stores} value={publicWorkout.distanceKm} />
                                    </div>
                                </div>}
                                {publicWorkout.elevation > 0 && <div class='mr-2 py-2 text-center'>
                                    <div class='text-overflow text-muted'>
                                        <small><i class='fas fa-mountain' /> <Text id='workout.calendar.ascend'>Høydemeter</Text></small>
                                    </div>
                                    <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                        <Elevation stores={this.props.stores} value={publicWorkout.elevation} />
                                    </div>
                                </div>}
                                {publicWorkout.duration > 0 && <div class='mr-2 py-2 text-center'>
                                    <div class='text-overflow text-muted'>
                                        <small><i class='fas fa-clock' /> <Text id='workouts.duration'>Varighet</Text></small>
                                    </div>
                                    <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                        {util.secToHms(publicWorkout.duration, true)}
                                    </div>
                                </div>}
                                {publicWorkout.rest > 0 && <div class='mr-2 py-2 text-center'>
                                    <div class='text-overflow text-muted'>
                                        <small><i class='fas fa-clock' /> <Text id='workouts.rest'>Rest</Text></small>
                                    </div>
                                    <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                        {util.secToHms(publicWorkout.rest, true)}
                                    </div>
                                </div>}
                                {publicWorkout.speedAvg > 0 && <div class='mr-2 py-2 text-center'>
                                    <div class='text-overflow text-muted'>
                                        <small><i class='fas fa-tachometer-alt' /> <Text id='workout.calendar.avg-speed'>Snittfart</Text></small>
                                    </div>
                                    <div class='text-overflow font-weight-light' style='font-size: 1.2em;'>
                                        {gpxInfo && gpxInfo.avgGpsMovingSpeed > 0 ? <>
                                            <Speed stores={this.props.stores} value={gpxInfo.avgGpsMovingSpeed * 3.6} />
                                        </> : <>
                                            <Speed stores={this.props.stores} value={publicWorkout.speedAvg} />
                                        </>}
                                    </div>
                                </div>}
                            </div>

                            <div class='w-100 pt-5'>
                                {util.checkNested(gpxInfo, 'elevations') ? <>
                                    <WorkoutInfoGraph stores={this.props.stores} key={`workout-elevation-${publicWorkout.id}`} workout={publicWorkout} />
                                </> : <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'>
                                            <i class='fas fa-chart-area' />
                                        </div>
                                        <h3 class='font-weight-lighter'>
                                            <Text id='workout.no-elevation-data'>No elevation data. If you use a GPS to track your workouts you will get a nice graph here and more correct analysis of the energy usage.</Text>
                                        </h3>
                                    </div>
                                </>}
                            </div>

                            {/* <div class='row'>
                                <div class='row mb-3'>
                                    {publicWorkout.gpxInfo && publicWorkout.gpxInfo.svgMap && <>
                                        <div class='col-12 mt-auto text-center overflow-hidden'>
                                            <small class='text-muted'>Track</small><br />
                                            <svg viewBox='0 0 400 400' style='width: 500px; height: 500px;'>
                                                <path d={`${publicWorkout.gpxInfo.svgMap}`} style='fill: none; stroke: #0275d8; stroke-width: 5; opacity: 0.5;' />
                                            </svg>
                                        </div>
                                    </>}
                                    {publicWorkout.gpxInfo && publicWorkout.gpxInfo.svgElevation && <>
                                        <div class='col-12 mt-auto text-center overflow-hidden'>
                                            <small class='text-muted'>Elevation</small><br />
                                            <svg viewBox='0 0 300 100' style='width: 600px; height: 200px;'>
                                                <path d={`${publicWorkout.gpxInfo.svgElevation}`} style='fill: none; stroke: #5cb85c; stroke-width: 5; opacity: 0.5;' />
                                            </svg>
                                        </div>
                                    </>}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </>}

                <div class='row bg-light py-3 px-2 mt-3'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
                        <div class='d-flex justify-content-between mt-2'>
                            <div class='mr-2'>
                                <LikesAndComments
                                    stores={this.props.stores}
                                    likes={likes}
                                    comments={comments}
                                    type='team'
                                    onTeamClick={this.onTeamClick}
                                    onUserClick={this.onUserClick}
                                />
                            </div>
                            <div class='d-flex justify-content-between align-items-center'>
                                {views > 0 && <>
                                    <Views
                                        stores={this.props.stores}
                                        views={views}
                                        object={publicWorkout}
                                        type='team'
                                        isTeamAdmin={isTeamAdmin}
                                        isAdmin={isAdmin}
                                        onTeamClick={this.onTeamClick}
                                        onUserClick={this.onUserClick}
                                    />
                                </>}
                                <div class='ml-2'>
                                    <i
                                        class={`fa-${user.favoriteWorkout?.indexOf(publicWorkout.id) > -1 ? 'solid' : 'regular'} fa-star`}
                                        style='font-size: 1.1em;'
                                        onClick={this.favoriteWorkout}
                                        data-value={publicWorkout.id}
                                        data-isfavorite={user.favoriteWorkout?.indexOf(publicWorkout.id) > -1}
                                    />
                                </div>
                            </div>
                        </div>


                        {!isYourTeam && !isFollowing && !hasRequestedFollow && <div class='clearfix mb-2'>
                            <PublicTeam stores={this.props.stores} team={team} buttonOnly={true} />
                        </div>}

                        <div>
                            <div class='row'>
                                <div class='col text-center'>
                                    <button type='button' class={`imageContainer btn btn-sm btn-outline-${hasLikedWorkout ? 'primary' : 'secondary'} btn-lg btn-block ${hasLikedWorkout ? 'text-primary' : ''} text-overflow`}
                                        data-id={publicWorkout.id}
                                        onClick={this.likeWorkout}
                                        // disabled={hasLikedWorkout}
                                    >
                                        <i class={`${hasLikedWorkout ? 'text-primary fas' : 'far'} fa-thumbs-up`} /> <Text id='comments.like'>Lik</Text>
                                    </button>
                                </div>
                                <div class='col text-center'>
                                    <button type='button' class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow'
                                        data-id={publicWorkout.id}
                                        onClick={this.toggleAddComment}
                                    >
                                        <i class='fas fa-comment' /> <Text id='comments.comment-add'>Kommentar</Text>
                                    </button>
                                </div>
                                {hasShareApi ? <>
                                    <div class='col text-center'>
                                        <button class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow'
                                            onClick={this.shareLink}
                                            data-url={`https://themusher.app/share/workout/${publicWorkout.uuidv4}`}
                                            data-title={`title: ${publicWorkout.title}`}
                                        >
                                            <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                        </button>
                                    </div>
                                </> : <>
                                    <div class='col text-center'>
                                        <a class='btn btn-sm btn-outline-secondary btn-lg btn-block text-overflow'
                                            href={`https://themusher.app/share/workout/${publicWorkout.uuidv4}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            data-native
                                        >
                                            <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                        </a>
                                    </div>
                                </>}
                            </div>
                        </div>

                        <div class='mt-2'>
                            {showAddComment[publicWorkout.id] && <WorkoutAddComment
                                stores={this.props.stores}
                                workout={publicWorkout}
                                inputRef={c => this.inputRef[publicWorkout.id] = c}
                                inputRefObject={this.inputRef}
                                col='12'
                                offset='0'
                                showAvatar={true}
                                extraClass='px-0'
                                callback={this.addCommentToFeed}
                            />}
                            <WorkoutComments
                                stores={this.props.stores}
                                workout={publicWorkout}
                                col='12'
                                offset='0'
                                compact={false}
                                callback={this.addCommentToFeed}
                                displayAllComments={displayAllComments}
                                onTeamClick={this.onTeamClick}
                                onUserClick={this.onUserClick}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default WorkoutPublicDetail;
