import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileLastDogLogList extends Component {
    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    render() {
        const { appState, userStore, dogStore } = this.props.stores;
        const { drawerLevel } = this.props;
        const { darkmode } = appState;
        const { history = [] } = dogStore;

        const team = userStore.getCurrentTeam();

        const dogs = userStore.findDogsByTeam(team.id);

        if (dogs.length === 0) {
            return (<></>);
        }
        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={<Text id='profile.dog-log-list'>Dog log</Text>} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br /> */}

                    {history.slice(0, 100).map(line => {
                        const dogImage = line.dogImage?.s3SmallLink;
                        return (<>
                            <div
                                class={`col-12 clearfix rounded-lg mb-3 pl-0 d-flex bg-light`}
                                style='
                                    border-radius: 20px !important;
                                '
                                key={`history-id-${line.uuidv4}`}
                            >
                                <div
                                    class={`text-muted bg-secondary px-3 bg-dark d-flex justify-content-center align-items-center`}
                                    style='
                                        border-radius: 20px 0 0 20px !important;
                                        font-size: 20px;
                                        line-height: 20px;
                                        font-weight: 900;
                                    '
                                >
                                    <i class={mu.resolveJournalIcon(line.historyType)} />
                                </div>
                                <div class='d-flex justify-content-left flex-column p-2'>
                                    <small class='text-muted'>
                                        {/* {util.formatDate(line.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' })} */}
                                        {util.formatDistance(line.date, new Date(), { locale: 'no-NB' })}
                                    </small>
                                    <span>{line.dogShortname || line.dogName}: {line.title}</span>
                                </div>
                            </div>
                        </>);
                    })}

                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileLastDogLogList;
