import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

@observer
class MenuWorkout extends Component {
	constructor(props) {
        super(props);
        this.state = {
            menuLinks: [
                {
                    hasToOwnWorkout: true,
                    name: <Text id='menu.workout.edit'>Edit workout</Text>,
                    func: this.editWorkout,
                    icon: 'fas fa-edit',
                },
                {
                    hasToOwnWorkout: true,
                    name: <Text id='menu.workout.delete'>Delete workout</Text>,
                    func: this.deleteWorkout,
                    icon: 'fas fa-trash-alt',
                },
                {
                    name: <Text id='menu.workout.goto'>Go to workout</Text>,
                    func: this.gotoWorkout,
                    icon: 'fas fa-link',
                },
                {
                    name: <Text id='menu.workout.favorite'>Add to favorites</Text>,
                    func: this.favoriteWorkout,
                    icon: 'fa-regular fa-star',
                },
                {
                    name: <Text id='menu.workout.share'>Share workout</Text>,
                    func: this.shareWorkout,
                    icon: 'fa-solid fa-share',
                },
                {
                    name: <Text id='menu.workout.report'>Report workout</Text>,
                    func: this.reportWorkout,
                    icon: 'fas fa-flag',
                },
                {
                    hasToBeAdmin: true,
                    name: <Text id='menu.workout.remove'>Remove from feed</Text>,
                    func: this.removeWorkout,
                    icon: 'fas fa-eraser',
                },
            ],
        };
    }

    editWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const { workout } = this.props;
        appState.openDrawer2('editWorkout', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            id: workout.id,
            setFocus: true,
        });
    }

    deleteWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { workout } = this.props;
        appState.openDrawer2('deleteWorkout', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            workout,
        });
    }

    reportWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { workout } = this.props;
        appState.openDrawer2('reportWorkout', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            workout,
        });
    }

    removeWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        const { workout } = this.props;
        appState.openDrawer2('removeWorkout', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            workout,
        });
    }

    gotoWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { workout } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('publicWorkout', {
            id: workout.uuidv4,
            height: drawerHeightMedium,
        });
    }

    favoriteWorkout = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { workout } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        await userStore.updateUserArray({ email: user.email, field: 'favoriteWorkout', value: workout.id });
        this.toggleDrawer();
    }

    shareWorkout = e => {
        e.preventDefault();
        e.stopPropagation();
        const { workout } = this.props;
        const { appState } = this.props.stores;
        const { hasShareApi } = appState;

        if (hasShareApi) {
            appState.shareUrl({
                url: `https://themusher.app/share/workout/${workout.uuidv4}`,
                title: workout.title,
                text: workout.body,
            });
        } else {
            window.open(`https://themusher.app/share/workout/${workout.uuidv4}`);
        }
        this.toggleDrawer();
    }

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    toggleDrawer2 = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore } = this.props.stores;
        const { darkmode } = appState;

        const { workout = {} } = this.props;
        const { user: currentUser = {}, isAdmin } = userStore;
        const isSameUser = currentUser.id === workout.user;

        return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3' style='font-size: 1.5em;'>
                        {menuLinks && menuLinks.map((link, idx) => {
                            if (link.hasToOwnWorkout && !isSameUser) return null;
                            if (link.hasToBeAdmin && !isAdmin) return null;

                            // eslint-disable-next-line react/jsx-key
                            return (<>
                                <div class={`row ${idx > 0 ? 'border-top' : ''} teamRow`}>
                                    <div class={`col-12 py-3 d-flex align-items-center position-relative`}>
                                        <div class='text-center' style='width: 55px;'>
                                            <i class={`${link.icon}`} />
                                        </div>
                                        <span class={`flex-grow-1`} style='line-height: 1.0em;'>
                                            {link.func ? <>
                                                <button
                                                    type='button'
                                                    onClick={link.func}
                                                    class={`${darkmode ? 'text-white' : 'text-dark'} btn btn-link btn-block text-left p-0`}
                                                    style='font-size: 1.0em;'
                                                >
                                                    {link.name}
                                                </button>
                                            </> : <>
                                                <a
                                                    href={link.href}
                                                    class={`${darkmode ? 'text-white' : 'text-dark'} stretched-link`}
                                                    onClick={this.toggleDrawer}
                                                >
                                                    {link.name}
                                                </a>
                                            </>}
                                        </span>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuWorkout;
