import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';

import DragNDropFileupload from './dragNdropFileupload';

@observer
class Files extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    handleAddImage = (file) => {
        // <Images saved={saved} object={dog} field={field} updateField={updateField} stores={this.props.stores} />
        // updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const {
            updateField,
            object,
            field,
        } = this.props;
        updateField({ id: object.id, field, value: file });

        // let images = toJS(object.images);
        // if (!util.isArray(images)) {
        //     images = [];
        // }
        // images.push(file);
        // updateField({ id: object.id, field: 'images', value: images });
    };

    render() {
        const { uploadStatus = () => {}, before = () => {}, after = () => {}, autoOpen = false } = this.props;
        return (
            <div class='row'>
                <div class='col-12'>
                    <Text id='form.files.upload-file'>Last opp fil</Text>:<br />
                    <DragNDropFileupload
                        handleAddImage={this.handleAddImage}
                        apiUrl={'/api/fileupload'}
                        uploadStatus={uploadStatus}
                        before={before}
                        after={after}
                        autoOpen={autoOpen}
                        accept='*/*'
                    >
                        <div style={{ height: 30, width: '100%' }}>
                            {/* {files.map((file, idx) => (
                                <div key={idx}>
                                    {file.name}
                                    Total: {util.asString(file, 'load', 'total')}
                                    <img style='width: 48px;'
                                        alt='Preview'
                                        key={file.preview}
                                        src={file.src}
                                    />
                                </div>
                            ))} */}
                        </div>
                    </DragNDropFileupload>
                </div>
            </div>
        );
    }
}

export default Files;
