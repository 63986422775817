import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

import PublicTeam from '../../components/team/public';

const initialState = {
	requestSent: {},
};

@observer
class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	async loadTeams() {
		this.setState({ isLoading: true });
		const { teamStore } = this.props.stores;
		await teamStore.loadPublic({ query: { notfollowing: 1, array: 1, skipImages: 1 }, limit: 15 });
		this.setState({ isLoading: false });
	}

	requestFollowTeam = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { team } = e.target.closest('button').dataset;
        teamStore.followRequest(parseInt(team, 10), user);
		const { requestSent } = this.state;
		requestSent[team] = true;
        this.setState({ requestSent });
    }

    getButton = (team) => {
        const { requestSent } = this.state;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const isYourTeam = team.members && team.members?.findIndex(e => e.id === user.id) > -1;
        const isFollowing = team.followers && team.followers?.indexOf(user.id) > -1;
        const isBlocked = team.blockedFollowers && team.blockedFollowers?.indexOf(user.id) > -1;
        const hasRequestedFollow = requestSent[team.id] || team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;

        if (isYourTeam) {
            return (
                <i class='fas fa-home' />
            );
        }
        if (isFollowing) {
            return (
                <button
                    class={`btn btn-sm btn-block btn-outline-secondary float-right`}
                    onClick={this.unFollowTeam}
                    disabled='true'
                    data-team={team.id}
                >
                    <i class='fas fa-link mr-2' />
                    <Text id='teams.connect.is-following'>Følger</Text>
                </button>
            );
        }
        if (isBlocked) {
            return (
                <button
                    class={`btn btn-sm btn-block btn-outline-secondary float-right`}
                    disabled='true'
                >
                    <i class='fas fa-hand-paper mr-2' />
                    <Text id='teams.connect.is-blocked'>Blokkert</Text>
                </button>
            );
        }
        if (hasRequestedFollow) {
            return (
                <button
                    class={`btn btn-sm btn-block btn-outline-secondary float-right`}
                    disabled='true'
                >
                    <i class='fas fa-hourglass-half mr-2' />
                    <Text id='teams.connect.has-requested'>Venter på godkjenning</Text>
                </button>
            );
        }
        return (
            <button
                class={`btn btn-sm btn-block btn-outline-success float-right`}
                onClick={this.requestFollowTeam}
                data-team={team.id}
            >
                <i class='far fa-hand-point-right mr-2' />
                <Text id='teams.connect.follow'>Følg</Text>
            </button>
        );
    }

    onTeamClick = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
    }

    hideTeamSuggestions = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        await userStore.updateUserArray({ email: user.email, field: 'hideTeamSuggestions', value: parseInt(value, 10) });
    }

	componentDidMount() {
		this.loadTeams();
	}

	render() {
		const { teamStore, userStore, appState } = this.props.stores;
        const { user } = userStore;
		const { publicTeamsToFollow } = teamStore;
        const { darkmode } = appState;
		if (!publicTeamsToFollow || publicTeamsToFollow?.length === 0) {
			return '';
		}

		return (
			<div class='row pb-1 bg-light'>
                <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative rounded rounded-lg py-3 bg-${darkmode ? 'black' : 'white'} shadow-sm`}>
					<div class='container-fluid'>
						<div class='row'>
							<div class='col-12'>
								<h5><Text id='teams.connect.teams-to-follow'>Team du kan følge</Text>:</h5>
							</div>
						</div>
						<div class='row'>
							<div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
								{util.isArray(publicTeamsToFollow) && publicTeamsToFollow.map((team) => {
									const image = team.image && team.image.s3SmallLink ? team.image : null;
									return (
										<div class='mr-2 position-relative' style={`width: 110px; ${user.hideTeamSuggestions?.indexOf(team.id) > -1 && 'opacity: 0.5;'}`}>
                                            <div class='position-absolute' style='top: 0px; left: 3px;'>
                                                <i class='fa-solid fa-circle-xmark text-muted' onClick={this.hideTeamSuggestions} data-value={team.id} />
                                            </div>
                                            <a href={`/teams/public/${team.uuidv4}`} onClick={(e) => this.onTeamClick(e, team.uuidv4)}>
                                                <div
                                                    class={`text-center border rounded-lg`}
                                                    style={image ? `background-image: url("${image.s3SmallLink}"); background-size: cover; width: 110px; height: 100px;` : 'width: 110px; height: 100px;'}
                                                >
                                                    {!image && <i class='fas fa-users text-muted mt-3' style='font-size: 40px;' />}
                                                </div>
                                            </a>
											<small>
												<div class='text-overflow'>
                                                    <a href={`/teams/public/${team.uuidv4}`} onClick={(e) => this.onTeamClick(e, team.uuidv4)}>
    													<strong>{team.name}</strong>
                                                    </a>
												</div>
												<div class='text-overflow'>
													{team.place ? `${team.place}, ` : ''} {team.country} &nbsp;
												</div>
												<div class='text-overflow'>
													<small class='text-muted'>
														{team && team.dogs && <span class=''><i class='fas fa-dog' /> {team.dogs.length}</span>}
														{team && team.followers && <span class='ml-2'><i class='fas fa-user' /> {team.followers.length}</span>}
													</small>
												</div>
											</small>
											<div>
												{this.getButton(team)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Teams;
