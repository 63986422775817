import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkState from 'linkstate';

import mu from '../../lib/musher-util';

@observer
class AddAdvanced extends Component {
	constructor(props) {
        super(props);
        this.state = {
            story: {
            },
            today: util.isoDate(),
        };
    }

    applyAdvanced = e => {
        const { story } = this.state;
        const { appState } = this.props.stores;
        const { drawerLevel } = this.props;
        const { callback = () => {} } = this.props;
        callback({
            ...story,
        });
        appState.toggleDrawer(false, drawerLevel);
    }

    render() {
        const { story, today } = this.state;
        const { appState } = this.props.stores;
        const { darkmode } = appState;

        return (
            <>
                <div
                    class={`position-absolute text-center w-100 appHeader ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    style={`
                        top: 24px;
                        left: 0px;
                    `}
                >
                    <Text id='story.advanced'>Advanced</Text>
                </div>
                <div class={`w-100 container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style='margin-top: 75px;'>
                    <div class='row'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <label><Text id='story.date'>Publish date</Text></label>
                            <div class='form-group'>
                                <input
                                    type='datetime-local'
                                    class='form-control'
                                    id={'date'}
                                    aria-describedby={`dateHelp`}
                                    value={story.date || today}
                                    onInput={linkState(this, 'story.date')}
                                />
                            </div>
                        </div>


                         {/* <div class='d-flex mb-0 position-relative w-100'>
                                    <div class='position-relative'>
                                        <div
                                            class={`text-center border rounded-circle imageRounded imageRoundedSmall pointerCursor`}
                                            style={`${userImage ? `background-image: url('${userImage}'); background-size: cover;` : ''} border-width: 3px !important;`}
                                            onClick={this.toggleTeamChange}
                                        >
                                            {!userImage && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                                        </div>
                                        <div class='position-absolute' style='top: 16px; right: 0px;'>
                                            <small class='text-white' style='font-size: 1.5em;'>
                                                <i class='fas fa-caret-down pointerCursor' onClick={this.toggleTeamChange} />
                                            </small>
                                        </div>
                                    </div>
                                    <div class='col pl-3 pr-0' style='line-height: 1.2em;'>
                                        <div class='d-flex bd-highlight'>
                                            <div class='p-0 w-100 bd-highlight'>
                                                <label class='sr-only' for='inlineFormInput'><Text id='story.body'>Story</Text></label>

                                            <div class='p-0 pl-2 flex-shrink-1 bd-highlight pt-auto d-flex align-items-end'>
                                                <button type='button' class={`btn btn-${sendButtonEnabled ? 'primary' : 'secondary'} ml-1`} disabled={!sendButtonEnabled}
                                                    onClick={this.addStory}
                                                ><i class='fas fa-paper-plane' /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}




                                        {/* {showTeamChange && <>
                                            <div class='col-12 text-center mt-2'>
                                                <div class='form-group'>
                                                    <small>
                                                        <select
                                                            class='custom-select custom-select-sm'
                                                            id='team'
                                                            onInput={linkState(this, 'story.team')}
                                                            onChange={this.toggleTeamChange}
                                                        >
                                                            <option value=''>-- <Text id='story.team-choose'>Velg team</Text> --</option>
                                                            {util.isArray(user.teams) && user.teams.map((teamId) => {
                                                                const team = userStore.findTeam(teamId, true);
                                                                if (team) {
                                                                    return (<option value={team.id} selected={story.team == team.id ? 'selected' : ''}>{team.name}</option>);
                                                                }
                                                            })}

                                                            {!user.teams && <>
                                                                <option value=''>No teams</option>
                                                            </>}
                                                        </select>
                                                    </small>
                                                </div>
                                            </div>
                                        </>} */}
                                        {/* {showChangeVisibility && <>
                                            {isAdmin && <>
                                                <div class='col-12 text-center mt-2'>
                                                    <div class='form-group'>
                                                        <small>
                                                            <label for='pinned'>Pinned to top:</label><input id='pinned' type='checkbox' onInput={linkState(this, 'story.pinned')} value={1} checked={story.pinned ? 1 : 0} />
                                                        </small>
                                                    </div>
                                                </div>
                                            </>}

                                            <div class='col-12 text-center mt-2'>
                                                <div class='form-group'>
                                                    <small>
                                                        <select
                                                            class='custom-select custom-select-sm'
                                                            id='team'
                                                            onInput={linkState(this, 'story.public')}
                                                            value={story.public}
                                                            onChange={this.changeVisibility}
                                                        >
                                                            <option value={0} selected={story.public == 0 ? 'selected' : ''}>👥 <Text id='story.visibility-followers'>Kun synlig for de som følger deg.</Text></option>
                                                            <option value={1} selected={story.public == 1 ? 'selected' : ''}>🌍 <Text id='story.visibility-public'>Synlig for alle som bruker appen.</Text></option>
                                                        </select>
                                                    </small>
                                                </div>
                                            </div>
                                        </>} */}
                                        {/* {showChangeType && <>
                                            <div class='col-12 text-center mt-2'>
                                                <div class='form-group'>
                                                    <small>
                                                        <select
                                                            class='custom-select custom-select-sm'
                                                            id='team'
                                                            onInput={linkState(this, 'story.type')}
                                                            value={story.type}
                                                            onChange={this.changeType}
                                                        >
                                                        <option value=''>-- <Text id='story.type-choose'>Velg type</Text> --</option>
                                                            <option value={1} selected={story.type == 1 ? 'selected' : ''}>✍️ <Text id='story.type-regular'>Vanlig</Text></option>
                                                            <option value={2} selected={story.type == 2 ? 'selected' : ''}>🚀 <Text id='story.type-ad'>Annonse</Text></option>
                                                            <option value={3} selected={story.type == 3 ? 'selected' : ''}>📄 <Text id='story.type-article'>Artikkel</Text></option>
                                                        </select>
                                                    </small>
                                                </div>
                                            </div>
                                        </>} */}


                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-5'>
                            <button type='button' class='btn btn-primary btn-block' onClick={this.applyAdvanced}>
                                <Text id='story.advanced-apply'>Apply</Text>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddAdvanced;
