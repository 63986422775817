import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import { route } from 'preact-router';
import util from 'preact-util';

import mu from '../lib/musher-util';
import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

function merge(a, b, prop){
    const reduced =  a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

class TeamStore extends StoreModel {
    constructor() {
        super('team', {
            namePlural: 'teams',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/teams/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/teams/',
                    params: {},
                },
                save: {
                    url: '/api/teams/',
                    params: {},
                },
            },
        });
    }

    @observable newTeam = {};

    @observable team = {};

    @observable teams = [];

    @observable publicTeams = [];

    @observable publicUsers = [];

    @observable publicDogs = [];

    @observable publicTeam = {};

    @observable publicTeamFollowers = [];

    @observable publicImages = [];

    @observable publicTeamsToFollow = [];

    @observable publicTeamsFeed = [];

    @observable publicWorkout = {};

    @observable topRankedWorkouts = [];

    @observable dogs = [];

    @observable users = [];

    @observable followers = [];

    @observable blockedFollowers = [];

    @observable followRequests = [];

    @observable workoutSummary = [];

    @observable musherSummary = [];

    @observable graphFitness = [];

    @observable graphFatigue = [];

    @observable graphForm = [];

    @observable currentForm = 0;

    @observable currentFitness = 0;

    @observable currentFatigue = 0;

    @observable formTrend = 0;

    @observable fitnessTrend = 0;

    @observable fatigueTrend = 0;

    @observable publicViews = {};

    @observable publicViewsGraphData = [];

    @observable publicViewsGraphLegend = [];

    @observable hasFollowRequests = false;

    @observable loadMore = () => {};

    @action
    beforeLoad({ id }) {
        if (id == this.team.id) {
            return;
        }
        const workoutPlaceholder = {
            date: '{placeholder}',
            name: '{placeholder}',
            description: '{placeholder}',
        };
        if (id) {
            this.workout = workoutPlaceholder;
        } else {
            this.workouts = [
                workoutPlaceholder,
                workoutPlaceholder,
                workoutPlaceholder,
            ];
        }
    }

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('TeamStore.cleanupMemory');
        }
        this.localUpdateField('publicTeams', []);
        this.localUpdateField('publicUsers', []);
        this.localUpdateField('publicDogs', []);
        this.localUpdateField('publicTeamFollowers', []);
        this.localUpdateField('publicImages', []);
        this.localUpdateField('publicTeamsToFollow', []);
        this.localUpdateField('publicTeamsFeed', []);
        this.localUpdateField('topRankedWorkouts', []);
        this.localUpdateField('followers', []);
        this.localUpdateField('blockedFollowers', []);
        this.localUpdateField('followRequests', []);
        this.localUpdateField('workoutSummary', []);
        this.localUpdateField('musherSummary', []);
        this.localUpdateField('graphFitness', []);
        this.localUpdateField('graphFatigue', []);
        this.localUpdateField('graphForm', []);
        this.localUpdateField('publicViews', {});
        this.localUpdateField('publicViewsGraphData', []);
        this.localUpdateField('publicViewsGraphLegend', []);
        this.localUpdateField('topRankedStories', []);
    }

    @action
    cleanupMemoryPublic() {
        if (isDevelopment) {
            console.log('TeamStore.cleanupMemoryPublic');
        }
        this.localUpdateField('publicTeamsFeed', []);
        this.localUpdateField('publicTeams', []);
        this.localUpdateField('publicUsers', []);
        this.localUpdateField('publicDogs', []);
        this.localUpdateField('topRankedWorkouts', []);
    }

    @action
    addTeamLocal({ dog }) {
        const idx = this.dogs?.findIndex(e => e.id === dog.id);
        if (idx === -1) {
            this.dogs.push(dog);
        }
    }

    @action
    removeTeamLocal({ dog }) {
        if (util.isArray(this.dogs)) {
            const idx = this.dogs?.findIndex(e => e.id === dog.id);
            if (idx > -1) {
                this.dogs.splice(idx, 1);
            }
        }
    }

    @action
    afterLoad(response) {
        this.hasFollowRequests = false;
        for (let i = 0, l = this.teams.length; i < l; i += 1) {
            const team = this.teams[i];
            if (team.followRequests && team.followRequests.length > 0) {
                this.hasFollowRequests = true;
            }
        }
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    findTeam(team, asObject = false) {
        const idx = this.teams?.findIndex(e => e.id === team);
        if (idx > -1) {
            if (asObject) {
                return toJS(this.teams[idx]);
            }
            return toJS(this.teams[idx]).name;
        }
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.team.images)) {
            const idx = this.team.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.team.images.splice(idx, 1);
            }
        }
        const widx = this.teams?.findIndex(e => e.id === id);
        if (widx > -1 && this.teams[widx].images) {
            const idx = this.teams[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.teams[widx].images.splice(idx, 1);
            }
        }
    }

    @action
    removeSponsorImageLocal({ id, name }) {
        if (util.isArray(this.team.imagesSponsor)) {
            const idx = this.team.imagesSponsor?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.team.imagesSponsor.splice(idx, 1);
            }
        }
        const widx = this.teams?.findIndex(e => e.id === id);
        if (widx > -1 && this.teams[widx].imagesSponsor) {
            const idx = this.teams[widx].imagesSponsor?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.teams[widx].imagesSponsor.splice(idx, 1);
            }
        }
    }

    @action
    removeFileLocal({ id, name }) {
        if (util.isArray(this.team.files)) {
            const idx = this.team.files?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.team.files.splice(idx, 1);
            }
        }
        const widx = this.teams?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.teams[widx].files?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.teams[widx].files.splice(idx, 1);
            }
        }
    }

    @action
    removeLinkLocal({ id, name, linkId }) {
        if (util.isArray(this.team.links)) {
            let idx;
            if (linkId) {
                idx = this.team.links?.findIndex(e => e.id === linkId);
            } else {
                idx = this.team.links?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.team.links.splice(idx, 1);
            }
        }
        const widx = this.teams?.findIndex(e => e.id === id);
        if (widx > -1) {
            let idx;
            if (linkId) {
                idx = this.teams[widx].links?.findIndex(e => e.id === linkId);
            } else {
                idx = this.teams[widx].links?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.teams[widx].links.splice(idx, 1);
            }
        }
    }

    @action
    removeAchievementLocal({ id, name, achievementId }) {
        if (util.isArray(this.team.achievements)) {
            let idx;
            if (achievementId) {
                idx = this.team.achievements?.findIndex(e => e.id === achievementId);
            } else {
                idx = this.team.achievements?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.team.achievements.splice(idx, 1);
            }
        }
        const widx = this.teams?.findIndex(e => e.id === id);
        if (widx > -1) {
            let idx;
            if (achievementId) {
                idx = this.teams[widx].achievements?.findIndex(e => e.id === achievementId);
            } else {
                idx = this.teams[widx].achievements?.findIndex(e => e.name === name);
            }
            if (idx > -1) {
                this.teams[widx].achievements.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/teams/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
    async removeSponsorImage({ id, name: removeSponsorImageByName }) {
        const response = await util.fetchApi(`/api/teams/${id}`, { publish: true, method: 'PATCH' }, { removeSponsorImageByName });
        switch (response.status) {
            case 202:
                this.removeSponsorImageLocal({ id, name: removeSponsorImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeFile({ id, name: removeFileByName }) {
        const response = await util.fetchApi(`/api/teams/${id}`, { publish: true, method: 'PATCH' }, { removeFileByName });
        switch (response.status) {
            case 202:
                this.removeFileLocal({ id, name: removeFileByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeLink({ id, name: removeLinkByName, linkId: removeLinkById }) {
        const response = await util.fetchApi(`/api/teams/${id}`, { publish: true, method: 'PATCH' }, { removeLinkByName, removeLinkById });
        switch (response.status) {
            case 202:
                this.removeLinkLocal({ id, name: removeLinkByName, linkId: removeLinkById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async removeAchievement({ id, name: removeAchievementByName, achievementId: removeAchievementById }) {
        const response = await util.fetchApi(`/api/teams/${id}`, { publish: true, method: 'PATCH' }, { removeAchievementByName, removeAchievementById });
        switch (response.status) {
            case 202:
                this.removeAchievementLocal({ id, name: removeAchievementByName, achievementId: removeAchievementById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    findWorkoutSummary(team, year) {
        const idx = this.workoutSummary?.findIndex(e => e.team === team && e.year === year);
        if (idx > -1) {
            return toJS(this.workoutSummary[idx]);
        }
    }

    @action
    findMusherSummary(musher, team) {
        const idx = this.musherSummary?.findIndex(e => e.musher === musher && e.team === team);
        if (idx > -1) {
            return toJS(this.musherSummary[idx]);
        }
    }

    @action
    findPublicTeam(team) {
        const idx = this.publicTeams?.findIndex(e => e.id === team);
        if (idx > -1) {
            return toJS(this.publicTeams[idx]);
        }
    }

    @action
    findPublicTeamByUuidv4(team) {
        const idx = this.publicTeams?.findIndex(e => e.uuidv4 === team);
        if (idx > -1) {
            return toJS(this.publicTeams[idx]);
        }
    }

    @action
    findPublicUser(user) {
        if (!this.publicUsers) {
            return {};
        }
        const idx = this.publicUsers?.findIndex(e => e.id === user);
        if (idx > -1) {
            return this.publicUsers[idx];
        }
    }

    @action
    findPublicTeamByMembers(user) {
        if (!user) {
            return [];
        }
        const teams = this.publicTeams.filter(e => e.members?.indexOf(user) > -1);
        return teams;
    }

    @action
    findWorkoutSummaryTotal(year) {
        if (this.workoutSummary.length > 0) {
            const workouts = this.workoutSummary.filter(e => e.year === year);
            const result = {
                cnt: 0,
                totalSpeedAvg: 0,

                distanceKm: 0,
                elevation: 0,
                duration: 0,
                speedAvg: 0,
            };
            for (let i = 0, l = workouts.length; i < l; i += 1) {
                const wo = workouts[i];
                result.cnt += 1;
                result.totalSpeedAvg += wo.speedAvg;

                result.count += wo.count;
                result.distanceKm += wo.distanceKm;
                result.elevation += wo.elevation;
                result.duration += wo.duration;
            }
            result.speedAvg = result.totalSpeedAvg / result.cnt;
            return result;
        }
        return {};
    }

    @action
    plotFormGraph() {
        if (this.team?.fitness?.length > 0) {
            this.graphFitness = this.team.fitness.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
        if (this.team?.fatigue?.length > 0) {
            this.graphFatigue = this.team.fatigue.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
        if (this.team?.form?.length > 0) {
            this.graphForm = this.team.form.map((data, idx) => {
                return {
                    x: idx,
                    y: data.value || 0,
                    year: data.year,
                    month: data.month,
                    day: data.day,
                    date: data.date,
                };
            });
        }
    }

    @action
    calculateCurrentForm() {
        const {
            graphFitness,
            graphFatigue,
            graphForm,
		} = this;
        const lastFitness = graphFitness[graphFitness.length - 1];
        this.currentFitness = lastFitness?.y;
        const lastFatigue = graphFatigue[graphFatigue.length - 1];
        this.currentFatigue = lastFatigue?.y;
        const lastForm = graphForm[graphForm.length - 1];
        this.currentForm = lastForm?.y;

        const secLastFitness = graphFitness[graphFitness.length - 2];
        const secLastFatigue = graphFatigue[graphFatigue.length - 2];
        const secLastForm = graphForm[graphForm.length - 2];

        this.fitnessTrend = lastFitness?.y ? lastFitness?.y / secLastFitness?.y * 100 - 100 : 0;
        this.fatigueTrend = lastFitness?.y ? lastFatigue?.y / secLastFatigue?.y * 100 - 100 : 0;
        this.formTrend = lastForm?.y / secLastForm?.y * 100 - 100;
		// console.log({ graphFitness, graphFatigue, graphForm });
        // currentForm, currentFitness, currentFatigue, formTrend, fitnessTrend, fatigueTrend
    }

    async addMember(team, member) {
        const response = await util.fetchApi(`/api/teams/addmember/${team}`, { publish: true, method: 'PATCH' }, {
            ...member
        });
        switch (response.status) {
            case 202:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: 'Nytt medlem ble lagt til.',
                    type: 'success',
                    icon: 'fas fa-check',
                });
                route(`/teams/${team}`);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            case 404:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: `Fant ikke bruker med e-post: ${member.email}.
Brukere må registrere seg før de kan bli lagt til i et team.
For å gjøre det enkelt for deg, så har vi sendt en e-post med alle instruksjoner.`,
                    type: 'danger',
				    icon: 'fas fa-exclamation-triangle',
                });
                break;
            case 422:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: `Mangler e-postadresse.`,
                    type: 'danger',
				    icon: 'fas fa-exclamation-triangle',
                });
                break;
        }
    }

    async removeMember(team, member) {
        const response = await util.fetchApi(`/api/teams/removemember/${team}`, { publish: true, method: 'PATCH' }, {
            ...member
        });
        switch (response.status) {
            case 202:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: 'Nytt medlem ble slettet fra teamet.',
                    type: 'success',
                    icon: 'fas fa-check',
                });
                route(`/teams/${team}`);
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: `Sletting av bruker fra teamet feilet.`,
                    type: 'danger',
				    icon: 'fas fa-exclamation-triangle',
                });
                break;
        }
    }

    @action
    beforeFeedLoad() {
        const placeholder = {
            type: 'workout',
            team: '{placeholder}',
            date: '{placeholder}',
            comment: '{placeholder}',
        };
        this.publicTeamsFeed = [
            placeholder,
            placeholder,
            placeholder,
        ];
    }

    async loadPublicFeed({ limit, offset, sort, teamid, workoutid, opts = {} }) {
        this.localUpdateField('publicWorkout', {});
        if (offset === 0) {
            this.beforeFeedLoad();
        }
        const response = await util.fetchApi(`/api/teams/public/feed/`, { publish: true, method: 'GET' }, { limit, offset, sort, teamid, workoutid, ...opts });
        switch (response.status) {
            case 200:
                if (workoutid) {
                    this.localUpdateField('publicWorkout', response.data[0]);
                    const publicTeams = merge(toJS(this.publicTeams), response.included.teams, 'id');
                    this.localUpdateField('publicTeams', publicTeams);
                    const publicUsers = merge(toJS(this.publicUsers), response.included.users, 'id');
                    this.localUpdateField('publicUsers', publicUsers);
                    const publicDogs = merge(toJS(this.publicDogs), response.included.dogs, 'id');
                    this.localUpdateField('publicDogs', publicDogs);
                } else if (offset > 0) {
                    let publicTeamsFeed = toJS(this.publicTeamsFeed);
                    publicTeamsFeed.push(...response.data);
                    // if (publicTeamsFeed.length > 30) {
                    //     publicTeamsFeed = publicTeamsFeed.slice(-15)
                    // }
                    this.localUpdateField('publicTeamsFeed', publicTeamsFeed);

                    const publicTeams = merge(toJS(this.publicTeams), response.included.teams, 'id');
                    this.localUpdateField('publicTeams', publicTeams);
                    const publicUsers = merge(toJS(this.publicUsers), response.included.users, 'id');
                    this.localUpdateField('publicUsers', publicUsers);
                    const publicDogs = merge(toJS(this.publicDogs), response.included.dogs, 'id');
                    this.localUpdateField('publicDogs', publicDogs);
                } else {
                    this.localUpdateField('publicTeamsFeed', response.data);
                    this.localUpdateField('publicTeams', response.included.teams);
                    this.localUpdateField('publicUsers', response.included.users);
                    this.localUpdateField('publicDogs', response.included.dogs);
                    this.localUpdateField('topRankedWorkouts', response.included.topRankedWorkouts);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadPublic({ query, limit, offset, search, team, opts = {} }) {
        const response = await util.fetchApi(`/api/teams/public/`, { publish: true, method: 'GET' }, {
            ...query, search, limit, offset, team, array: 1, ...opts
        });
        switch (response.status) {
            case 200:
                if (offset > 0) {
                    let publicTeamsToFollow = toJS(this.publicTeamsToFollow);
                    publicTeamsToFollow.push(...response.data);
                    this.localUpdateField('publicTeamsToFollow', publicTeamsToFollow);
                } else {
                    this.localUpdateField('publicTeamsToFollow', response.data);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadPublicDetail(query) {
        this.localUpdateField('publicTeam', {});
        this.localUpdateField('publicTeamFollowers', []);
        this.localUpdateField('publicImages', []);
        const response = await util.fetchApi(`/api/teams/public/`, { publish: true, method: 'GET' }, { ...query });
        switch (response.status) {
            case 200:
                this.localUpdateField('publicTeam', response.data);
                if (response.included) {
                    this.localUpdateField('publicTeamFollowers', response.included.followers);
                    this.localUpdateField('publicImages', response.included.images);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addPublicImages(images) {
        this.publicImages.push(...images);
    }

    async loadMoreImages(query) {
        const response = await util.fetchApi(`/api/teams/public/`, { publish: true, method: 'GET' }, { ...query });
        switch (response.status) {
            case 200:
                if (response.included) {
                    // this.addPublicImages(response.included.images);
                    const publicImages = merge(toJS(this.publicImages), response.included.images, 'id');
                    // console.log(publicImages.length);
                    this.localUpdateField('publicImages', publicImages);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async followRequest(team) {
        const response = await util.fetchApi(`/api/teams/follow-request/${team}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async unollowTeam(team) {
        const response = await util.fetchApi(`/api/teams/unfollow/${team}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async blockTeam(team) {
        const response = await util.fetchApi(`/api/teams/block/${team}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async followRequestUser(user) {
        const response = await util.fetchApi(`/api/teams/follow-request/user/${user}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async confirmFollowRequest({ team, user }) {
        const response = await util.fetchApi(`/api/teams/confirm-follow-request/${team}`, { publish: true, method: 'GET' }, { user });
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async deleteFollowRequest({ team, user }) {
        const response = await util.fetchApi(`/api/teams/delete-follow-request/${team}`, { publish: true, method: 'GET' }, { user });
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToWorkout({ id, data }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicTeamsFeed[idx].likes)) {
                this.publicTeamsFeed[idx].likes = [];
            }
            this.publicTeamsFeed[idx].likes.push(data);
        }
        if (this.publicWorkout && this.publicWorkout.id === id) {
            if (util.isUndefined(this.publicWorkout.likes)) {
                this.publicWorkout.likes = [];
            }
            this.publicWorkout.likes.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicTeamsFeed[idx].comments)) {
                const commentIdx = this.publicTeamsFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicTeamsFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
        if (this.publicWorkout && this.publicWorkout.id === id) {
            if (util.isDefined(this.publicWorkout.comments)) {
                const commentIdx = this.publicWorkout.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicWorkout.comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicTeamsFeed[idx].comments)) {
                const commentIdx = this.publicTeamsFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicTeamsFeed[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
        if (this.publicWorkout && this.publicWorkout.id === id) {
            if (util.isDefined(this.publicWorkout.comments)) {
                const commentIdx = this.publicWorkout.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicWorkout.comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    addCommentToWorkout({ id, data }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.publicTeamsFeed[idx].comments)) {
                this.publicTeamsFeed[idx].comments = [];
            }
            this.publicTeamsFeed[idx].comments.push(data);
        }
        if (this.publicWorkout && this.publicWorkout.id === id) {
            this.publicWorkout.comments.push(data);
        }
    }

    @action
    addCommentToWorkoutComment({ id, commentId, data }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.publicTeamsFeed[idx].comments)) {
                const commentIdx = this.publicTeamsFeed[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.publicTeamsFeed[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
        if (this.publicWorkout && this.publicWorkout.id === id) {
            if (util.isDefined(this.publicWorkout.comments)) {
                const commentIdx = this.publicWorkout.comments?.findIndex(e => e.id === commentId);
                const comment = this.publicWorkout.comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    async postScrollview(data = []) {
        if (!data || data?.length === 0) {
            return false;
        }
        const response = await util.fetchApi(`/api/scrollview/`, { publish: false, method: 'POST' }, { data });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getPublicViews(uuid) {
        const response = await util.fetchApi(`/api/teams/summary/${uuid}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('publicViews', response.data);
                const { legends, data } = mu.getGraphData(response.data);
                this.localUpdateField('publicViewsGraphData', data);
                this.localUpdateField('publicViewsGraphLegend', legends);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reCalcFitness(teamId) {
        const response = await util.fetchApi(`/api/teams/${teamId}/recalculate`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('team', response.data);
                this.plotFormGraph();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async reportWorkout({ id }) {
        const response = await util.fetchApi(`/api/teams/${id}/report`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    removeWorkout({ id }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.publicTeamsFeed.splice(idx, 1)
        }
    }

    async adminHideWorkout({ id }) {
        const response = await util.fetchApi(`/api/teams/${id}/admin-hide`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                this.removeWorkout({ id });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateFeedTranslation({ id, field, value, targetLang }) {
        const idx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.publicTeamsFeed[idx][`${field}${targetLang}`] = value;
        }
    }

    async translate({ object, user, field = 'name' }) {
        const { id, language = 'no', [field]: text } = object;
        const { language: userLanguage = 'en' } = user;

        const sourceLang = language === 'undefined' ? 'no' : language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = mu.getTranslation({ object, targetLang, field });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/workouts/${id}/translate/${sourceLang}/${targetLang}/${field}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateFeedTranslation({
                            id: object.id,
                            field,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    /* Comment translations */
    @action
    updateCommentTranslation({ id, commentid, value, targetLang, field = 'comment' }) {
        const objIdx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (objIdx > -1) {
            const object = this.publicTeamsFeed[objIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objIdx, commentIdx, comment });

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        return e.language === targetLang && e.field === field;
                    });
                    comment.translations[translationIdx] = translationObj;
                } else {
                    comment.translations = [translationObj];
                }
            }
        }
    }

    @action
    updateCommentReplyTranslation({ id, commentid, replyid, value, targetLang, field = 'comment' }) {
        const objIdx = this.publicTeamsFeed?.findIndex(e => e.id === id);
        if (objIdx > -1) {
            const object = this.publicTeamsFeed[objIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
    }

    getCommentTranslations({ object, commentid, targetLang, field = 'comment' }) {
        const objIdx = this.publicTeamsFeed?.findIndex(e => e.id === object.id);
        if (objIdx > -1) {
            const object = this.publicTeamsFeed[objIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        return null;
    }

    getCommentReplyTranslations({ object, commentid, replyid, targetLang, field = 'comment' }) {
        const objIdx = this.publicTeamsFeed?.findIndex(e => e.id === object.id);
        if (objIdx > -1) {
            const object = this.publicTeamsFeed[objIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        return null;
    }

    async translateComment({ object, user, commentid }) {
        const { id, language = 'no' } = object;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const text = comment.comment;

        const sourceLang = comment.language === 'undefined' ? 'no' : comment.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;
        const translatedText = this.getCommentTranslations({ object, commentid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/workouts/${id}/${commentid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentTranslation({
                            id: object.id,
                            commentid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    async translateCommentReply({ object, user, commentid, replyid }) {
        const { id, language = 'no' } = object;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
        const reply = comment.comments[replyIdx];
        const text = reply.comment;

        const sourceLang = reply.language === 'undefined' ? 'no' : reply.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = this.getCommentReplyTranslations({ object, commentid, replyid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/workouts/${id}/${commentid}/${replyid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentReplyTranslation({
                            id: object.id,
                            commentid,
                            replyid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }
}

const store = new TeamStore();
export default store;
