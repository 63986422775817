import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import style from './style.css';

import mu from '../../lib/musher-util';

const TEXT_BOX_PADDING_X = 10;
const TEXT_PADDING_X = 20;

function getTicks(count, min, max, reverse = false, prefix = '', postfix = '') {
    let loop = Array.from(Array(count).keys());
    if (reverse) {
        loop = loop.reverse();
    }
    const range = Math.ceil(max) - Math.floor(min);
    if (prefix === '' && postfix === '') {
        return loop.map(d => parseInt((range / (count - 1) * d), 10) + Math.floor(min));
    }
    return loop.map(d => `${prefix}${parseInt((range / (count - 1) * d), 10) + Math.floor(min)}${postfix}`);
}

function transformYTick(tick, fn) {
    if (util.isFunction(fn)) {
        return fn(tick);
    }
    return tick;
}

function normalizeRange(x = 0, min, max, a, b, debug) {
    if (debug) {
        console.log('(b - a)', (b - a));
        console.log('(x - min) / (max - min)', (x - min) / (max - min));
        console.log('a', a);
    }
    const val = (b - a) * (
        (x - min) / (max - min)
    ) + a;
    return val;
    // return newMin + (val - min) * (newMax - newMin) / (max - min);
}

function round5(x, roundDown = false) {
    let sign = 1;
    if (x < 0) {
        sign = -1;
    }
    if (roundDown) {
        if (sign === 1) {
            return Math.floor(Math.abs(x) / 5) * 5 * sign;
        }
        return Math.ceil(Math.abs(x) / 5) * 5 * sign;
    }
    if (sign === 1) {
        return Math.ceil(Math.abs(x) / 5) * 5 * sign;
    }
    return Math.floor(Math.abs(x) / 5) * 5 * sign;
}

function findMinX({ data, min, xVal = o => o.x }) {
    const values = data.map(arr => {
        if (util.isArray(arr)) {
            if (util.isNumber(min)) {
                return Math.min(min, ...arr.map(d => xVal(d)));
            }
            return Math.min(...arr.map(d => xVal(d)));
        }
    });
    return Math.min(...values);
}

function findMaxX({ data, max, xVal = o => o.x }) {
    const values = data.map((arr) => {
        if (util.isArray(arr)) {
            if (util.isNumber(max)) {
                return Math.max(max, ...arr.map(d => xVal(d)));
            }
            return Math.max(...arr.map(d => xVal(d)));
        }
    });
    return Math.max(...values);
}

function findMinY({ data, min }) {
    const values = data.map((arr) => {
        if (util.isArray(arr)) {
            if (util.isNumber(min)) {
                return Math.min(min, ...arr.map(d => d.y));
            }
            return Math.min(...arr.map(d => d.y));
        }
    });
    return Math.min(...values);
}

function findMaxY({ data, max }) {
    const values = data.map((arr) => {
        // console.log(arr);
        if (util.isArray(arr)) {
            if (util.isNumber(max)) {
                return Math.max(max, ...arr.map(d => d.y));
            }
            return Math.max(...arr.map(d => d.y));
        }
    });
    return Math.max(...values);
}

const initialState = {
    mouseData: {},
};

@observer
class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.svgRef = null;
        this.svgContainerRef = null;
    }

    getCoordX(minX, maxX, x = 0) {
        const { width = 600, barPaddingLeft = 10, barPaddingRight = 15 } = this.props;

        const val = normalizeRange(x, minX, maxX, 0 + barPaddingLeft, width - barPaddingRight, false);
        // console.log(`getCoordX.normalizeRange: x: ${x}`, { minX, maxX }, `normalized to 0-${width}`, { val });
        return val;
    }

    getCoordY(minY, maxY, val = 0) {
        // console.log('getCoordY', minY, maxY, val);
        const { height = 200, offsetY = 0, showXTicks, barPaddingBottom = 20 } = this.props;
        // return normalizeRange(y, minY, maxY, 0, height);
        const calcHeight = showXTicks ? height - barPaddingBottom : height;

        if (val === -1) {
            return calcHeight;
        }
        let dataVal = val;
        if (dataVal === null) {
            return null;
        }
        dataVal = normalizeRange(dataVal, minY, maxY, minY, maxY) || 0.2;
        const y = Math.round(calcHeight - normalizeRange(dataVal, minY, maxY, 0, calcHeight - offsetY));
        // console.log({ dataVal, y });
        return y;
    }

    getFillColor = (idx) => {
        const colors = [
            '#325E75',
            '#990012',
            '#694C11',
            '#A800A7',
            '#0A007E',
            '#1B569D',
            '#51B73C',
            '#C47F22',
            '#139BFF',
        ];
        return colors[idx];
    }

    componentDidMount() {
        const { showValueLeftLine, showValueRightLine, scrollToRight = false } = this.props;
        // if(showValueLeftLine || showValueRightLine) {
        //     this.svgRef.addEventListener('mousemove', this.mouseMove);
        // }
        if (this.svgContainerRef && scrollToRight) {
            const maxScroll = this.svgContainerRef.scrollLeftMax || (this.svgContainerRef.scrollWidth - this.svgContainerRef.clientWidth);
            this.svgContainerRef.scrollLeft = maxScroll;
        }
        // onMouseMove={this.mouseMove}
    }

    componentWillUnmount() {
        const { showValueLeftLine, showValueRightLine } = this.props;
        if(showValueLeftLine || showValueRightLine) {
            this.svgRef.removeEventListener('mousemove', this.mouseMove);
        }
    }

    render() {
        const {
            width = 600,         // Width of svg.
            height = 200,        // Height of svg.
            offsetY = 0,         // Data plot offset y axis.
            paddingLeft = 0,     // Make room for yTicksLeft.
            paddingRight = 0,    // Make room for yTicksRight.
            paddingTop = 0,      // Make room for legends.
            paddingBottom = 0,   // Make room for xTicks.
            tickCount = 6,       // Number of ticks to show
            barPaddingBottom = 20, // Make room for xTicks.
            barWidth = 7,        // Width of bar.

            showXTicks,          // Show X ticks.
            showYTicksLeft,      // Show Y ticks left.
            showYTicksRight,     // Show Y ticks right.

            xTicks,              // xTicks array to use instead of values. JSON.stringified and backslash escaped.
            xTicksVal,           // Function used to show x ticks
            xTicksLink,          // Link to xticks valuies.

            yTicksLeft,          // yTicks array to use instead of values. JSON.stringified and backslash escaped.
            yTicksLeftVal,       // Function used to show y ticks
            yTicksLeftPrefix,
            yTicksLeftPostfix,
            yTicksLeftWidth,

            yTicksRight,         // yTicks array to use instead of values. JSON.stringified and backslash escaped.
            yTicksRightVal,      // Function used to show y ticks
            yTicksRightPrefix,
            yTicksRightPostfix,
            yTicksRightWidth,

            xMin,
            xMax,

            yMaxLeft = 1,            // Y max value to use for yTicks
            yMinLeft = 0,            // Y min value to use for yTicks
            yMaxMinLeftRound = true, // Y min max value to use for yTicks

            yMaxRight,           // Y max value to use for yTicks
            yMinRight,           // Y min value to use for yTicks
            yMaxMinRightRound = true, // Y min max value to use for yTicks

            rangeMinLeft = [],
            rangeMaxLeft = [],

            rangeMinRight = [],
            rangeMaxRight = [],

            xVal = o => o.x,    // Function used to show x values

            dataLeft = [],
            dataRight = [],

            legendLeft = [],
            legendRight = [],

            unitLeft = [],
            unitRight = [],

            dataFillLeft = [],
            dataFillRight = [],

            avgWindowLeft = [],
            avgWindowRight = [],

            smoothLeft = [],
            smoothRight = [],

            hoverValLeft = [],   // Function used to show value for hover.
            hoverValRight = [],  // Function used to show value for hover.

            showValueLeftLine = true,
            showValueRightLine = true,

            lineFromXzero,
            lineFromXmax,

            legendFontSize = '14px',
            legendLineHeight = 14,

            hoverFontSize = '12px',
            ticksFontSize = '12px',

            marker,
            markers = [],
            periode,
        } = this.props;
        const { appState = {} } = this.props.stores || {};
        // const { mousePos = [], darkmode } = appState;

        const PADDING_LEFT = paddingLeft || (showYTicksLeft ? 35 : 0);
        const PADDING_RIGHT = paddingRight || (showYTicksRight ? 20 : 0);
        const PADDING_TOP = paddingTop;
        const PADDING_BOTTOM = paddingBottom;// || (showXTicks ? 20 : 0);
        // console.log({ PADDING_LEFT, PADDING_RIGHT, PADDING_TOP, PADDING_BOTTOM });
        // const LEGEND_LINE_HEIGHT = legendLineHeight;
        // const LEGEND_FONT_SIZE = legendFontSize;

        // const HOVER_FONT_SIZE = hoverFontSize;
        const TICKS_FONT_SIZE = ticksFontSize;

        // const common = {
        //     width,
        //     height,
        //     offsetY,
        // };

        // console.table(xTicks);
        // console.table(yTicks);
        const MIN_Y_LEFT = round5(findMinY({ data: dataLeft, min: yMinLeft }), true);
        const MAX_Y_LEFT = round5(findMaxY({ data: dataLeft, max: yMaxLeft }), false);
        const MIN_Y_RIGHT = round5(findMinY({ data: dataRight, min: yMinRight }), true);
        const MAX_Y_RIGHT = round5(findMaxY({ data: dataRight, max: yMaxRight }), false);

        const MIN_X = findMinX({ data: dataLeft.concat(dataRight), min: xMin, xVal });
        const MAX_X = findMaxX({ data: dataLeft.concat(dataRight), max: xMax, xVal });
        // console.log({ MIN_X, MAX_X });
        // console.log({ MIN_Y_LEFT, MAX_Y_LEFT, MIN_Y_RIGHT, MAX_Y_RIGHT, MIN_X, MAX_X });

        // const X_TICKS = xTicks || getTicks(tickCount, MIN_X, MAX_X);
        const Y_TICKS_LEFT = yTicksLeft || getTicks(tickCount, MIN_Y_LEFT, MAX_Y_LEFT, true, yTicksLeftPrefix, yTicksLeftPostfix);
        const Y_TICKS_RIGHT = yTicksRight || getTicks(tickCount, MIN_Y_RIGHT, MAX_Y_RIGHT, true, yTicksRightPrefix, yTicksRightPostfix);
        // const markerX = this.getCoordX(MIN_X, MAX_X, marker);

        const calcHeight = showXTicks ? height - barPaddingBottom : height;

        return (
            <div class={style.lineChart} style={`height: ${height}px; overflow: hidden;`}>
                <div
                    class='overflow-auto position-relative w-100 h-100'
                    style='overflow-y: hidden !important;'
                    ref={c => this.svgContainerRef = c}
                    onTouchStart={mu.captureEvents}
					onTouchEnd={mu.captureEvents}
					onTouchMove={mu.captureEvents}
					onScroll={mu.captureEvents}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' class={style.svg} viewBox={`0 0 ${width} ${height}`}
                        preserveAspectRatio='none'
                        style={`
                            padding-left: ${PADDING_LEFT}px;
                            padding-right: ${PADDING_RIGHT}px;
                            padding-bottom: ${PADDING_BOTTOM}px;
                            width: ${width}px;
                            min-width: 100%;
                            max-height: 100%;
                        `}
                        ref={c => this.svgRef = c}
                    >
                        {dataLeft && dataLeft.length > 0 && dataLeft.reverse().map((dataSet, dataSetIdx) => <>
                            {dataSet && dataSet.length > 0 && dataSet.map((data, i) => {
                                const { x, y, xTick, day, week } = data;
                                const xCoord = this.getCoordX(MIN_X, MAX_X, x);
                                const yCoord = this.getCoordY(MIN_Y_LEFT, MAX_Y_LEFT, y);
                                let tick = xTick;
                                let width = barWidth;
                                let tickStep = 5;
                                switch(periode) {
                                    case 'week':
                                        tick = xTick;
                                        width = 15;
                                        tickStep = 1;
                                        break;
                                    case 'month':
                                        tick = day;
                                        width = 7;
                                        tickStep = 5;
                                        break;
                                    case 'year':
                                        tick = `w${week}`;
                                        width = 2;
                                        tickStep = 5;
                                        break;
                                }
                                const xOffset = dataSetIdx * width;

                                let showTick = dataSetIdx === 0
                                    && (
                                        (i === 0)
                                        || ((i + 1) % tickStep === 0)
                                    );
                                // console.log({ data, x, y, xCoord, yCoord, periode });
                                return (<>
                                    <rect
                                        x={xCoord + xOffset}
                                        y={yCoord}
                                        height={(calcHeight - yCoord) || 1}
                                        width={width}
                                        rx={y > 0 ? 0 : 0}
                                        ry={y > 0 ? 0 : 0}
                                        style={`
                                            stroke-width: 0px;
                                            stroke: ${this.getFillColor(dataSetIdx)};
                                            fill: ${this.getFillColor(dataSetIdx)};
                                        `}
                                    />
                                    {showTick && <text
                                        x={xCoord + (width / 2)}
                                        y={height - 5}
                                        style={`
                                            fill: #c0c0c0;
                                            font-size: 9px;
                                            stroke-linejoin: round;
                                            text-anchor: middle;
                                            stroke-width: 0px;
                                            paint-order: stroke fill;
                                        `}
                                    >{tick}</text>}
                                </>);
                            })}
                        </>)}
                    </svg>

                </div>

                {showYTicksLeft && (
                    <div
                        class={style['y-axis-left']}
                        style={{
                            top: `${PADDING_TOP}px`,
                            bottom: `${PADDING_BOTTOM}px`,
                            'font-size': TICKS_FONT_SIZE,
                            width: `${yTicksLeftWidth}`,
                        }}
                    >
                        {Y_TICKS_LEFT.map(v => <div data-value={transformYTick(v, yTicksLeftVal)} />)}
                    </div>
                )}
                {showYTicksRight && (
                    <div
                        class={style['y-axis-right']}
                        style={{
                            top: `${PADDING_TOP}px`,
                            bottom: `${PADDING_BOTTOM}px`,
                            'font-size': TICKS_FONT_SIZE,
                            width: `${yTicksRightWidth}`,
                        }}
                    >
                        {Y_TICKS_RIGHT.map(v => <div data-value={transformYTick(v, yTicksRightVal)} />)}
                    </div>
                )}
            </div>
        );
    }
}

export default LineChart;
