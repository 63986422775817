
import { h, Component, createRef, useEffect, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';

import mu from '../../lib/musher-util';

import ReactMapboxGl, {
    Layer,
    Feature,
    Source,
    GeoJSONLayer,
    Marker,
    ZoomControl,
    ScaleControl,
    RotationControl,
} from '!!react-mapbox-gl';

// import icons from '../../lib/icons';

import mapboxgl from 'mapbox-gl';
// import DrawControl from "react-mapbox-gl-draw";

const DEBUG = false;

import 'mapbox-gl/dist/mapbox-gl.css';

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1Ijoic29yZW5zbyIsImEiOiJjazhjbndnZDYwbmx4M2V0cXNkYmp2eW10In0.oYRSaI8XyElbhkQnBCrPbw',
    // attributionControl: false,
    // preserveDrawingBuffer: true,
});

function normalizeRange(val, min, max, newMin, newMax) {
    return newMin + (val - min) * (newMax - newMin) / (max - min);
}

function kmtToMph(value) {
    return 0.621371 * value;
}

function kmToMiles(value) {
    return 0.621371 * value;
}

function meterToFeet(value) {
    return 3.28084 * value;
}

function celciusToFahrenheit(value) {
    return ((value * 9/5) + 32);
}

function validateLatLng(lat, lng) {
    if (lat > 90 || lat < -90) {
        return false;
    }
    if (lng > 180 || lng < -180) {
        return false;
    }
    return true;
}

@observer
class MapboxExternalContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
        }
        this.apiServer = util.getApiServer();
    }

    render() {
        const {
            lineWidth = 4,
            startMarker,
            endMarker,
            distanceMarkers,
            markers,
            onMarkerClick = () => {},
        } = this.props;

        const geojsonStyles = {
            lineLayout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            linePaint: {
              'line-color': 'RGB(255, 103, 49)',
              'line-width': lineWidth,
              'line-opacity': 1
            },
            // circleLayout: {
            //     'color': 'RGB(255, 103, 49)',
            // },
            // fillLayout: {
            //     'color': 'RGB(255, 103, 49)',
            // },
            // symbolLayout: {
            //     "text-field": "{place}",
            //     "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            //     "text-offset": [0, 0.6],
            //     "text-anchor": "top"
            // },
        };

        return (<>
             {/* <Source id="maine" type="geojson" data={geojson} /> */}
             {startMarker && <Marker
                coordinates={startMarker}
                anchor='bottom'
                style={{
                    'border-radius': '50%',
                    'background-color': '#1B1B60',
                    width: '35px',
                    height: '35px',
                    color: '#fff',
                    'font-weight': 'bold',
                    'text-align': 'center',
                    'font-size': '20px',
                    'padding-top': '7px',
                }}
            >
                <i class='fa-solid fa-location-dot' />
            </Marker>}

            {endMarker && <Marker
                coordinates={endMarker}
                anchor='bottom'
                style={{
                    'border-radius': '50%',
                    'background-color': '#1B1B60',
                    width: '35px',
                    height: '35px',
                    color: '#fff',
                    'font-weight': 'bold',
                    'text-align': 'center',
                    'font-size': '20px',
                    'padding-top': '7px',
                }}
            >
                <i class='fa-solid fa-flag-checkered' />
            </Marker>}

            {distanceMarkers && distanceMarkers.map((marker, idx) => {
                return (
                    <Marker
                        key={idx}
                        coordinates={[marker.lng, marker.lat]}
                        anchor='center'
                        style={{
                            'border-radius': '50%',
                            'background-color': 'RGB(255, 103, 49)',
                            width: '20px',
                            height: '20px',
                            color: '#fff',
                            'font-weight': 'bold',
                            'text-align': 'center',
                        }}
                    >
                        {marker.title}
                    </Marker>
                );
            })}
            {markers && markers.map((marker, idx) => {
                return (
                    <Marker
                        key={idx}
                        coordinates={[marker.lng, marker.lat]}
                        anchor='bottom'
                        style={{
                            // 'border-radius': '50%',
                            // 'background-color': 'RGB(49, 103, 255)',
                            width: '20px',
                            height: '20px',
                            color: '#303030',
                            'font-size': '12px',
                            'font-weight': 'normal',
                            'text-align': 'center',
                        }}
                    >
                        {marker.temp}°C
                    </Marker>
                );
            })}
        </>);
    }
}

function MarkerSignal(props) {
    const { secSinceLastUpdate, classNames } = props;
    return (<>
        {secSinceLastUpdate > 300 && <>
            <small class={`${classNames}`}>
                {secSinceLastUpdate > 900 ? <>
                    <i class='fa-solid fa-clock text-danger' />
                </> : <>
                    {secSinceLastUpdate > 600 ? <>
                        <i class='fa-solid fa-clock text-warning' />
                    </> : <>
                        {secSinceLastUpdate > 300 ? <>
                            <i class='fa-solid fa-clock text-muted' />
                        </> : <></>}
                    </>}
                </>}
            </small>
        </>}
        {/* {secSinceLastUpdate > 300 && <>
            <small class={`${classNames}`}>
                {secSinceLastUpdate > 900 ? <>
                    <i class='fa-solid fa-signal-bars-slash' />
                </> : <>
                    {secSinceLastUpdate > 600 ? <>
                        <i class='fa-solid fa-signal-bars-weak' />
                    </> : <>
                        {secSinceLastUpdate > 300 ? <>
                            <i class='fa-solid fa-signal-bars-fair' />
                        </> : <>
                            {secSinceLastUpdate > 60 ? <>
                                <i class='fa-solid fa-signal-bars-good' />
                            </> : <>
                                <i class='fa-solid fa-signal-bars-strong' />
                            </>}
                        </>}
                    </>}
                </>}
            </small>
        </>} */}
    </>);
}

function MarkerMusher(props) {
    const {
        id,
        wp,
        onMarkerClick,
        mapIsDark,
        iconHeight,
        iconWidth,
        lineHeight,
        fontSize,
        paddingTop,
        paddingBottom,
        largeMarkers,
        dotColor,
        imageCirlceRadius,
        imageCircle,
        position = 'right',
        showSignal = true,
        showBearing = true,
        showDeviation = true,
        styles,
        isTheoretical,
        title = '',
    } = props;

    return (<>
        <div
            id={`waypoints-id-${id}`}
            class={`d-flex flex-nowrap overflow-hidden justify-content-${position === 'right' ? 'start' : 'end'} align-items-center`}
            style={`max-width: ${iconWidth}px; height: ${iconHeight}px; line-height: ${lineHeight}; font-size: ${fontSize}; ${styles}}`}
            onClick={e => onMarkerClick({ ...wp, e })}
        >
            {position === 'right' ? <>
                <div
                    class='rounded rounded-circle mr-2 mt-1 d-flex justify-content-center align-items-center'
                    style={`width: ${imageCirlceRadius}px; height: ${imageCirlceRadius}px; background-color: ${dotColor};`}
                >
                    {showBearing && wp.bearingTrack && (wp.currentAvgSpeed > 1.0 || wp.speed > 1.0) ? <i
                        class='fa-solid fa-arrow-up text-white'
                        style={`
                            transform: rotate(${wp.bearingTrack}deg);
                            font-size: 0.8em;
                        `}
                    /> : <>&nbsp;</>}
                </div>
                <div
                    class={`text-white font-weight-normal ${largeMarkers ? 'pr-3 pl-2' : 'pr-1 pl-1'} rounded-pill-left rounded-none`}
                    style={`background-color: ${dotColor ? dotColor : ''}; padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                >
                    {imageCircle}
                </div>
                <div
                    class={`${mapIsDark ? 'text-white' : 'text-dark'} pl-1 pr-2 rounded-pill-right rounded-none border ${mapIsDark ? 'bg-dark' : 'bg-white'} overflow-hidden`}
                    style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                >
                    <nobr style='letter-spacing: -0.9px;'>
                        <span>{wp.title}</span>
                        {showDeviation && wp.deviation > 1 && <><i class='fa-solid fa-triangle-exclamation ml-1 text-warning' /></>}
                        {showSignal && <MarkerSignal classNames={'ml-1 text-muted'} secSinceLastUpdate={wp.secSinceLastUpdate} key={`marker-signal-${wp.bib}-${wp.secSinceLastUpdate}`} />}
                        {title && <span class='ml-2 text-muted'>{title}</span>}
                        {/* {isTheoretical && <><i class='fa-regular fa-ghost ml-2' /></>} */}
                    </nobr>
                </div>
            </> : <>
                <div
                    class={`${mapIsDark ? 'text-white' : 'text-dark'} pl-2 pr-1 rounded-pill-left rounded-none border ${mapIsDark ? 'bg-dark' : 'bg-white'} overflow-hidden`}
                    style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                >
                    <nobr style='letter-spacing: -0.9px;'>
                        {title && <span class='mr-2 text-muted'>{title}</span>}
                        {/* {isTheoretical && <><i class='fa-regular fa-ghost mr-2' /></>} */}
                        {showSignal && <MarkerSignal classNames={'mr-1 text-muted'} secSinceLastUpdate={wp.secSinceLastUpdate} key={`marker-signal-${wp.bib}-${wp.secSinceLastUpdate}`} />}
                        {showDeviation && wp.deviation > 1 && <><i class='fa-solid fa-triangle-exclamation text-warning mr-1' /></>}
                        <span>{wp.title}</span>
                        {/* {wp.theoreticalDistanceKm}km */}
                        {/* {JSON.stringify(wp.theoreticalPoint, null, 4)} */}
                    </nobr>
                </div>
                <div
                    class={`text-white font-weight-normal ${largeMarkers ? 'pr-3 pl-2' : 'pr-1 pl-1'} rounded-pill-right rounded-none`}
                    style={`background-color: ${dotColor ? dotColor : ''}; padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                >
                    {imageCircle}
                </div>
                <div
                    class='rounded rounded-circle ml-2 mt-1 d-flex justify-content-center align-items-center'
                    style={`width: ${imageCirlceRadius}px; height: ${imageCirlceRadius}px; background-color: ${dotColor};`}
                >
                    {showBearing && wp.bearingTrack && (wp.currentAvgSpeed > 1.0 || wp.speed > 1.0) ? <i
                        class='fa-solid fa-arrow-up text-white'
                        style={`
                            transform: rotate(${wp.bearingTrack}deg);
                            font-size: 0.8em;
                        `}
                    /> : <>&nbsp;</>}
                </div>
            </>}
        </div>
    </>);
}

@observer
class MapboxContentMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }

    render() {
        const {
            geojson,
            startMarker,
            endMarker,
            distanceMarkers,
            markers,
            waypoints,
            waypoints2,
            waypoints3,
            waypoints4,
            lineWidth = 4,
            largeMarkers,
            mapChecked,
            iconWidth = 250,
            iconHeight = 20,
            borderColor = 0,
            paddingTop = '3px',
            paddingBottom = '3px',
            lineHeight = '1.0em',
            fontSize = '1.2em',
            imageCirlceRadius = 15,
            onMarkerClick = () => {},
            onMarker2Click = () => {},
            onMarker3Click = () => {},
            onMarker4Click = () => {},
            showTrailForBib = null,
            showRestLocationsForBib = null,
            showDistanceMarkers,
        } = this.props;

        const { userStore, raceStore } = this.props.stores;
        const { isAdmin } = userStore;
        const { isRaceAdmin, race } = raceStore;
        const now = Math.floor(new Date().getTime() / 1000);

        // console.log({ waypoints });

        const geojsonStyles = {
            lineLayout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            linePaint: {
              'line-color': 'RGB(255, 103, 49)',
              'line-width': lineWidth,
              'line-opacity': 1
            },
        };

        return (<>
            <GeoJSONLayer
                {...geojsonStyles}
                data={geojson}
            />

            {/* <Source id="maine" type="geojson" data={geojson} /> */}
            {startMarker && <Marker
                coordinates={startMarker}
                anchor='bottom'
                style={{
                    'border-radius': '50%',
                    'background-color': '#1B1B60',
                    width: '35px',
                    height: '35px',
                    color: '#fff',
                    'font-weight': 'bold',
                    'text-align': 'center',
                    'font-size': '20px',
                    'padding-top': '7px',
                }}
            >
                <i class='fa-solid fa-location-dot' />
            </Marker>}

            {endMarker && <Marker
                coordinates={endMarker}
                anchor='bottom'
                style={{
                    'border-radius': '50%',
                    'background-color': '#1B1B60',
                    width: '35px',
                    height: '35px',
                    color: '#fff',
                    'font-weight': 'bold',
                    'text-align': 'center',
                    'font-size': '20px',
                    'padding-top': '7px',
                }}
            >
                <i class='fa-solid fa-flag-checkered' />
            </Marker>}

            {showDistanceMarkers && distanceMarkers && distanceMarkers.map((marker, idx) => {
                return (
                    <Marker
                        key={idx}
                        coordinates={[marker.lng, marker.lat]}
                        anchor='center'
                        style={{
                            'border-radius': '50%',
                            'background-color': 'RGB(255, 103, 49)',
                            width: '20px',
                            height: '20px',
                            color: '#fff',
                            'font-weight': 'bold',
                            'text-align': 'center',
                        }}
                    >
                        {marker.title}
                    </Marker>
                );
            })}

            {markers && markers.map((marker, idx) => {
                return (
                    <Marker
                        key={idx}
                        coordinates={[marker.lng, marker.lat]}
                        anchor='bottom'
                        style={{
                            // 'border-radius': '50%',
                            // 'background-color': 'RGB(49, 103, 255)',
                            width: '20px',
                            height: '20px',
                            color: '#303030',
                            'font-size': '12px',
                            'font-weight': 'normal',
                            'text-align': 'center',
                        }}
                    >
                        {marker.temp}°C
                    </Marker>
                );
            })}

            {(isAdmin || isRaceAdmin) && <>
                {waypoints4 && waypoints4.length > 0 && waypoints4.reverse().map((wp, idx) => {
                    const id = `img-${util.randomPassword()}`;
                    const direction = idx % 2 === 0 ? 'top-left' : 'bottom-left';
                    return (<>
                        {showTrailForBib === wp.bib && wp.last10Points && wp.last10Points.length > 0 && wp.last10Points.map((p, idx) => {
                            const id = `img-${util.randomPassword()}`;
                            return (<>
                                <Marker
                                    key={`trail-waypoints-b-${idx}`}
                                    coordinates={[p[1], p[0]]}
                                    anchor={'top-left'}
                                    style={{
                                        // 'border-radius': '50%',
                                        // 'background-color': 'RGB(49, 103, 255)',
                                        width: '120px',
                                        height: '20px',
                                        color: '#303030',
                                        'font-size': '12px',
                                        'font-weight': 'normal',
                                        'text-align': 'center',
                                    }}
                                >
                                    <div
                                        id={`waypoints4-id-${id}`}
                                        class={`d-flex flex-nowrap overflow-hidden justify-content-start align-items-center`}
                                        style={`
                                            width: 120px;
                                            height: 20px;
                                            line-height: 0.9em;
                                            font-size: 0.9em;`}
                                    >
                                        <div
                                            class={`rounded-circle imageRounded imageRoundedSmallIcon bg-white`}
                                        >
                                            &nbsp;
                                        </div>
                                        <div
                                            class={`ml-2 text-dark pl-1 pr-2 rounded-pill-both rounded-none bg-white overflow-hidden`}
                                            style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                                        >
                                            <nobr>
                                                {wp.bib}: {util.isoDate(p[2])}
                                            </nobr>
                                        </div>
                                    </div>
                                </Marker>
                            </>);
                        })}
                        <Marker
                            key={`waypoints4-${idx}`}
                            coordinates={[wp.lng, wp.lat]}
                            anchor={direction ==='top-left' ? 'bottom-left' : 'top-left'}
                            style={{
                                // 'border-radius': '50%',
                                // 'background-color': 'RGB(49, 103, 255)',
                                width: `${iconWidth}px`,
                                height: `${iconHeight}px`,
                                color: '#303030',
                                'font-size': '12px',
                                'font-weight': 'normal',
                                'text-align': 'center',
                            }}
                        >
                            <div
                                id={`waypoints4-id-${id}`}
                                class={`d-flex flex-nowrap overflow-hidden justify-content-${direction === 'top-left' ? 'start' : 'start'} align-items-center`}
                                style={`width: ${iconWidth / 2}px; height: ${iconHeight}px; line-height: ${lineHeight}; font-size: ${fontSize};`}
                                onClick={e => onMarker4Click({ ...wp, e })}
                            >
                                <div
                                    class={`rounded-circle imageRounded imageRoundedSmallIcon bg-white`}
                                >
                                    {wp.bearingTrack ? <i
                                        class='fa-solid fa-arrow-up text-dark'
                                        style={`
                                            transform: rotate(${wp.bearingTrack}deg);
                                            font-size: 0.8em;
                                        `}
                                    /> : <>&nbsp;</>}
                                </div>
                                <div
                                    class={`ml-2 text-dark pl-2 pr-2 rounded-pill-both rounded-none bg-white overflow-hidden`}
                                    style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                                >
                                    <nobr>
                                        {wp.isScooter && <i class='fa-solid fa-person-snowmobiling mr-2' />}
                                        {wp.bib}
                                    </nobr>
                                </div>
                            </div>
                        </Marker>


                    </>);
                })}
            </>}

            {waypoints3 && waypoints3.length > 0 && waypoints3.reverse().map((wp, idx) => {
                const id = `img-${util.randomPassword()}`;
                const direction = idx % 2 === 0 ? 'top-left' : 'bottom-left';
                return (
                    <Marker
                        key={`waypoints3-${idx}`}
                        coordinates={[wp.lng, wp.lat]}
                        anchor={direction ==='top-left' ? 'bottom-left' : 'top-left'}
                        style={{
                            // 'border-radius': '50%',
                            // 'background-color': 'RGB(49, 103, 255)',
                            width: `${iconWidth}px`,
                            height: `${iconHeight}px`,
                            color: '#303030',
                            'font-size': '12px',
                            'font-weight': 'normal',
                            'text-align': 'center',
                        }}
                    >
                        <div
                            id={`waypoints3-id-${id}`}
                            class={`d-flex flex-nowrap overflow-hidden justify-content-${direction === 'top-left' ? 'start' : 'start'} align-items-center`}
                            style={`width: ${iconWidth / 2}px; height: ${iconHeight}px; line-height: ${lineHeight}; font-size: ${fontSize};`}
                            onClick={e => onMarker3Click({ ...wp, e })}
                        >
                            <div
                                class={`text-dark pl-2 pr-2 rounded-pill-both rounded-none bg-danger overflow-hidden`}
                                style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                            >
                                <nobr>
                                    {wp.title}
                                </nobr>
                            </div>
                        </div>

                        {/* <Popup position={[wp.lat, wp.lng]}> */}
                            {/* <h5><Markdown markdown={wp.title} markdownOpts={MARKDOWN_OPTIONS} /></h5>
                            <Markdown markdown={wp.body} markdownOpts={MARKDOWN_OPTIONS} /> */}
                        {/* </Popup> */}
                    </Marker>
                );
            })}

            {waypoints2 && waypoints2.length > 0 && waypoints2.reverse().map((wp, idx) => {
                const id = `img-${util.randomPassword()}`;
                const direction = idx % 2 === 0 ? 'top-left' : 'bottom-left';
                return (
                    <Marker
                        key={`waypoints2-${idx}`}
                        coordinates={[wp.lng, wp.lat]}
                        anchor={direction ==='top-left' ? 'bottom-left' : 'top-left'}
                        style={{
                            // 'border-radius': '50%',
                            // 'background-color': 'RGB(49, 103, 255)',
                            width: `${iconWidth}px`,
                            height: `${iconHeight}px`,
                            color: '#303030',
                            'font-size': '12px',
                            'font-weight': 'normal',
                            'text-align': 'center',
                        }}
                    >
                        <div
                            id={`waypoints2-id-${id}`}
                            class={`d-flex flex-nowrap overflow-hidden justify-content-${direction === 'top-left' ? 'start' : 'start'} align-items-center`}
                            style={`width: ${iconWidth / 2}px; height: ${iconHeight}px; line-height: ${lineHeight}; font-size: ${fontSize};`}
                            onClick={e => onMarker2Click({ ...wp, e })}
                        >
                            <div
                                class={`text-dark pl-2 pr-2 rounded-pill-both rounded-none bg-info overflow-hidden`}
                                style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom}; opacity: 0.8;`}
                            >
                                <nobr>
                                    {wp.title}
                                </nobr>
                            </div>
                        </div>

                        {/* <Popup position={[wp.lat, wp.lng]}> */}
                            {/* <h5><Markdown markdown={wp.title} markdownOpts={MARKDOWN_OPTIONS} /></h5>
                            <Markdown markdown={wp.body} markdownOpts={MARKDOWN_OPTIONS} /> */}
                        {/* </Popup> */}
                    </Marker>
                );
            })}

            {waypoints && waypoints.length > 0 && waypoints.reverse().map((wp, idx) => {
                // if (wp.hasCrossedFinishLine && (now - wp.finishTime > race.secondsToShowFinishMushers || wp.finishTime === 9999999999)) {
                if (wp.hasCrossedFinishLine && (now - wp.finishTime > race.secondsToShowFinishMushers)) {
                    return (<></>);
                }
                if (wp.hasScratched && (now - wp.scratchedTime > race.secondsToShowFinishMushers)) {
                    return (<></>);
                }
                const id = `img-${util.randomPassword()}`;
                const dotColor = mu.getRankingColor(wp.rank);
                const mapIsDark = /dark/i.test(mapChecked);
                const direction = idx % 2 === 0 ? 'right' : 'left';
                let imageCircle = wp.image ? <>
                    <div
                        class={`rounded-circle imageRounded imageRoundedSmallIcon`}
                        style={`background-image: url("${wp.image}"); background-size: cover; border: 1px ${dotColor} solid;`}
                    >
                        &nbsp;
                    </div>
                </> : <></>;
                imageCircle = wp.bib || wp.rank ? <>
                    <div
                        class={`px-0 text-center`}
                        style={`background-color: ${dotColor ? dotColor : ''}'`}
                    >
                        {wp.bib || wp.rank}
                    </div>
                </> : imageCircle;
                return (<Fragment key={`waypoints-${idx}`}>
                    <Marker
                        coordinates={[wp.lng, wp.lat]}
                        // coordinates={[wp.closestLng, wp.closestLat]}
                        anchor={direction === 'right' ? 'left' : 'right'}
                        style={{
                            // 'border-radius': '50%',
                            // 'background-color': 'RGB(49, 103, 255)',
                            'max-width': `${iconWidth}px`,
                            height: `${iconHeight}px`,
                            color: '#303030',
                            'font-size': '12px',
                            'font-weight': 'normal',
                            'text-align': 'center',
                        }}

                        // onClick={(e) => this.handleMarkerClick({ ...wp, e })}
                        // eventHandlers={{
                        //     click: (e) => this.handleMarkerClick({ ...wp, e }),
                        // }}
                        // zIndexOffset={300 + idx}
                    >
                        <MarkerMusher
                            id={id}
                            wp={wp}
                            onMarkerClick={onMarkerClick}
                            mapIsDark={mapIsDark}
                            iconHeight={iconHeight}
                            iconWidth={iconWidth}
                            lineHeight={lineHeight}
                            fontSize={fontSize}
                            paddingTop={paddingTop}
                            paddingBottom={paddingBottom}
                            largeMarkers={largeMarkers}
                            dotColor={dotColor}
                            imageCirlceRadius={imageCirlceRadius}
                            imageCircle={imageCircle}
                            position={direction}
                            // title={wp.finishTime}
                            // title={wp.hasCrossedFinishLine ? 'Finish' : ''}
                        />
                        {/* <Popup position={[wp.lat, wp.lng]}> */}
                            {/* <h5><Markdown markdown={wp.title} markdownOpts={MARKDOWN_OPTIONS} /></h5>
                            <Markdown markdown={wp.body} markdownOpts={MARKDOWN_OPTIONS} /> */}
                        {/* </Popup> */}
                    </Marker>

                    {(isAdmin || isRaceAdmin || race.showTheoreticalPosition) && wp.theoreticalPoint && wp.theoreticalDistanceFromLastTrackPointKm > 0.5 && <>
                        <Marker
                            key={`theoretical-waypoints-b-${idx}`}
                            coordinates={[wp.theoreticalPoint[1], wp.theoreticalPoint[0]]}
                            anchor={direction === 'right' ? 'left' : 'right'}
                            style={{
                                // 'border-radius': '50%',
                                // 'background-color': 'RGB(49, 103, 255)',
                                'max-width': `${iconWidth}px`,
                                height: `${iconHeight}px`,
                                color: '#303030',
                                'font-size': '12px',
                                'font-weight': 'normal',
                                'text-align': 'center',
                            }}
                        >
                            <MarkerMusher
                                id={id}
                                wp={wp}
                                onMarkerClick={onMarkerClick}
                                mapIsDark={mapIsDark}
                                iconHeight={iconHeight}
                                iconWidth={iconWidth}
                                lineHeight={lineHeight}
                                fontSize={fontSize}
                                paddingTop={paddingTop}
                                paddingBottom={paddingBottom}
                                largeMarkers={largeMarkers}
                                dotColor={dotColor}
                                imageCirlceRadius={imageCirlceRadius}
                                imageCircle={imageCircle}
                                position={direction}
                                isTheoretical={true}
                                title={`${util.format(wp.theoreticalDistanceFromLastTrackPointKm, 1)}km`}
                                // title={`${util.format(wp.theoreticalDistanceKm, 1)} km`}
                                styles={`opacity: 0.5;`}
                                showBearing={false}
                                showSignal={false}
                                showDeviation={false}
                            />
                        </Marker>
                    </>}

                    {showTrailForBib === wp.bib && wp.last10Points && wp.last10Points.length > 0 && wp.last10Points.map((p, idx) => {
                        const id = `img-${util.randomPassword()}`;
                        return (
                            <Marker
                                key={`trail-waypoints-b-${idx}`}
                                coordinates={[p[1], p[0]]}
                                anchor={'left'}
                                style={{
                                    // 'border-radius': '50%',
                                    // 'background-color': 'RGB(49, 103, 255)',
                                    width: '120px',
                                    height: '20px',
                                    color: '#303030',
                                    'font-size': '12px',
                                    'font-weight': 'normal',
                                    'text-align': 'center',
                                }}
                            >
                                <div
                                    id={`waypoints-id-${id}`}
                                    class={`d-flex flex-nowrap overflow-hidden justify-content-start align-items-center`}
                                    style={`
                                        width: 120px;
                                        height: 20px;
                                        line-height: 0.9em;
                                        font-size: 0.9em;`}
                                >
                                    <div
                                        class='rounded rounded-circle mr-2 mt-1'
                                        style={`width: ${imageCirlceRadius}px; height: ${imageCirlceRadius}px; background-color: ${dotColor};`}
                                    >
                                        &nbsp;
                                    </div>
                                    <div
                                        class={`${mapIsDark ? 'text-white' : 'text-dark'} pl-1 pr-2 rounded-pill-both rounded-none ${mapIsDark ? 'bg-dark' : 'bg-white'} overflow-hidden`}
                                        style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                                    >
                                        <nobr>
                                            {util.isoDate(p[2])}
                                        </nobr>
                                    </div>
                                </div>

                                {/* <Popup position={[wp.lat, wp.lng]}> */}
                                    {/* <h5><Markdown markdown={wp.title} markdownOpts={MARKDOWN_OPTIONS} /></h5>
                                    <Markdown markdown={wp.body} markdownOpts={MARKDOWN_OPTIONS} /> */}
                                {/* </Popup> */}
                            </Marker>
                        );
                    })}
                    {showRestLocationsForBib.indexOf(wp.bib) > -1 && wp.states && wp.states.length > 0 && wp.states.filter(state => state.state === 'rest').map((state, idx) => {
                        const p = state.point;
                        const id = `states-img-${util.randomPassword()}`;
                        return (
                            <Marker
                                key={`states-waypoints-b-${idx}`}
                                coordinates={[p[1], p[0]]}
                                anchor={'left'}
                                style={{
                                    // 'border-radius': '50%',
                                    // 'background-color': 'RGB(49, 103, 255)',
                                    width: '120px',
                                    height: '20px',
                                    color: '#303030',
                                    'font-size': '12px',
                                    'font-weight': 'normal',
                                    'text-align': 'center',
                                }}
                            >
                                <div
                                    id={`waypoints-id-${id}`}
                                    class={`d-flex flex-nowrap overflow-hidden justify-content-start align-items-center`}
                                    style={`
                                        width: 120px;
                                        height: 20px;
                                        line-height: 0.9em;
                                        font-size: 0.9em;`}
                                >
                                    <div
                                        class='rounded rounded-circle mr-2 mt-1 text-white d-flex justify-content-center align-items-center'
                                        style={`
                                            width: ${imageCirlceRadius + 5}px;
                                            height: ${imageCirlceRadius + 5}px;
                                            background-color: ${dotColor};
                                            font-size: 1.0em;
                                            font-weight: bold;
                                            letter-spacing: -1px;
                                        `}
                                    >
                                        {wp.bib}
                                    </div>
                                    <div
                                        class={`${mapIsDark ? 'text-white' : 'text-dark'} pl-1 pr-2 rounded-pill-both rounded-none ${mapIsDark ? 'bg-dark' : 'bg-white'} overflow-hidden`}
                                        style={`padding-top: ${paddingTop}; padding-bottom: ${paddingBottom};`}
                                    >
                                        <nobr>
                                            <i class='fa-solid fa-bed' /> {util.secToHms(state.end - state.start)}
                                        </nobr>
                                    </div>
                                </div>

                                {/* <Popup position={[wp.lat, wp.lng]}> */}
                                    {/* <h5><Markdown markdown={wp.title} markdownOpts={MARKDOWN_OPTIONS} /></h5>
                                    <Markdown markdown={wp.body} markdownOpts={MARKDOWN_OPTIONS} /> */}
                                {/* </Popup> */}
                            </Marker>
                        );
                    })}
                </Fragment>);
            })}

        </>);
    }
}

@observer
class MapboxMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
            lat: 61.0779066,
            lng: 9.800984,
            zoom: 9,
            zoomLevel: 9,
            startMarker: null,
            endMarker: null,
            distanceMarkers: null,
            centerForClass: {},
        };
        this.apiServer = util.getApiServer();
    }

    onStyleLoad = map => {
        if (DEBUG) console.log('onStyleLoad', map);
        this.map = map;
        const {
            geojson,
            padding = 40,
            show3dMap = true,
            geojsonExternal,
            geojsonExternalTrack,
            lineWidth = 4,
            startMarker,
            endMarker,
            distanceMarkers,
            markers,
            center = [9.800984, 61.0779066],
            pitch = 40,
            showBib,
            waypoints,
            showTop10,
        } =  this.props;

        const { appState } = this.props.stores;
        const { selectedClassIds = [] } = appState;
        let selectedClassId = selectedClassIds[0];
        if (DEBUG) console.log('onStyleLoad', { selectedClassId, selectedClassIds });
        map.setMaxZoom(18);

        const { lat, lng, zoom, centerForClass } = this.state;
        if (DEBUG) console.log('onStyleLoad', { lat, lng, zoom, centerForClass });

        let customCenter;
        let currentZoom = zoom;
        let currentCenter = center;
        if (centerForClass[selectedClassId] && validateLatLng(centerForClass[selectedClassId].lat, centerForClass[selectedClassId].lng)) {
            currentCenter = [centerForClass[selectedClassId].lng, centerForClass[selectedClassId].lat];
            customCenter = centerForClass[selectedClassId].customCenter;
            currentZoom = centerForClass[selectedClassId].zoom;
        } else if (validateLatLng(lat, lng)) {
            currentCenter = [lng, lat];
        }

        map.setZoom(currentZoom);
        if (currentCenter) {
            if (DEBUG) console.log({ currentCenter, lat, lng, center });
            map.setCenter(currentCenter);
        }
        if (pitch) {
            map.setPitch(pitch, { duration: 500 });
        }

        if (geojsonExternalTrack) {
            // if (!map) return;
            // console.log({geojsonExternalTrack})
            map.addSource('trackTrack', {
                type: 'geojson',
                data: `${this.apiServer}/api/tracks/${geojsonExternalTrack}/gpx`
            });

            map.addLayer({
                id: 'track-track-layer',
                source: 'trackTrack',
                type: 'line',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': 'RGB(49, 103, 255)',
                    'line-width': lineWidth + 4,
                    'line-opacity': 1
                },
                filter: ['==', '$type', 'LineString']
            });
        }

        if (geojsonExternal) {
            // if (!map) return;
            map.addSource('workoutTrack', {
                type: 'geojson',
                data: `${this.apiServer}/api/workouts/public/${geojsonExternal}/gpx`
            });

            map.addLayer({
                id: 'workout-track-layer',
                source: 'workoutTrack',
                type: 'line',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': 'RGB(255, 103, 49)',
                    'line-width': lineWidth,
                    'line-opacity': 1
                },
                filter: ['==', '$type', 'LineString']
            });

            // map.addLayer({
            //     'id': 'park-boundary',
            //     'type': 'fill',
            //     'source': 'national-park',
            //     'paint': {
            //         'fill-color': '#888888',
            //         'fill-opacity': 0.4
            //     },
            //     'filter': ['==', '$type', 'Polygon']
            // });

            // map.addLayer({
            //     id: 'workout-track-points',
            //     source: 'workoutTrack',
            //     type: 'circle',

            //     'paint': {
            //         'circle-radius': 6,
            //         'circle-color': '#B42222'
            //     },
            //     'filter': ['==', '$type', 'Point']
            // });

        }


        if (show3dMap) {
            map.addSource('mapbox-dem', {
                type: 'raster-dem',
                url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                tileSize: 512,
                maxzoom: 14
            });
            // add the DEM source as a terrain layer with exaggerated height
            map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            // add sky styling with `setFog` that will show when the map is highly pitched
            // map.setFog({
            //     'horizon-blend': 0.3,
            //     color: '#f8f0e3',
            //     'high-color': '#add8e6',
            //     'space-color': '#d8f2ff',
            //     'star-intensity': 0.0
            // });
        }

        // if (startMarker) {
        //     this.setState({
        //         startMarker,
        //         endMarker,
        //         distanceMarkers,
        //     });

        //     // Create a 'LngLatBounds' with both corners at the first coordinate.
        //     const bounds = new mapboxgl.LngLatBounds(
        //         startMarker,
        //         endMarker,
        //     );

        //     // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        //     if (distanceMarkers) {
        //         for (const coord of distanceMarkers) {
        //             bounds.extend(coord);
        //         }
        //     }
        //     map.fitBounds(bounds, {
        //         padding,
        //     });
        // }

       if (showBib && waypoints && waypoints.length > 0) {
            const bibs = [];
            if (showBib.match(/,/)) {
                const bibsArray = showBib.split(',');
                for (const bib of bibsArray) {
                    bibs.push(parseInt(bib, 10));
                }
            } else {
                bibs.push(parseInt(showBib, 10));
            }
            if (bibs.length === 0) return;

            const bounds = new mapboxgl.LngLatBounds();
            for (const bib of bibs) {
                const musher = waypoints.find(w => w.bib === bib);
                if (musher && musher.lat && musher.lng) {
                    if (validateLatLng(musher.lat, musher.lng)) {
                        bounds.extend([musher.lng, musher.lat]);
                    }
                }
            }
            map.fitBounds(bounds, { padding: 100 });
            // console.log('bib', { bib, musher, waypoints });
        } else if (!customCenter && waypoints && waypoints.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();
            for (const wp of waypoints) {
                if (validateLatLng(wp.lat, wp.lng)) {
                    bounds.extend([wp.lng, wp.lat]);
                }
            }
            map.fitBounds(bounds, { padding: 100 });
        } else if (geojson && geojson.features[0]?.geometry?.coordinates) {
            this.makeStartAndEndMarkers();
            this.makeDistanceMarkers();

            // Geographic coordinates of the LineString
            const coordinates = geojson.features[0].geometry.coordinates;
            let bounds;
            // Create a 'LngLatBounds' with both corners at the first coordinate.

            if (!customCenter) {
                if (validateLatLng(coordinates[0], coordinates[1])) {
                    bounds = new mapboxgl.LngLatBounds(
                        coordinates[1],
                        coordinates[0]
                    );

                    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
                    for (const coord of coordinates) {
                        bounds.extend(coord);
                    }
                    map.fitBounds(bounds, {
                        padding,
                    });
                }
            }
        } else if (markers) {
            const bounds = new mapboxgl.LngLatBounds();
            markers.forEach(m => {
                if (validateLatLng(m.lat, m.lng)) {
                    bounds.extend([m.lng, m.lat]);
                }
            });
            map.fitBounds(bounds, { padding });

            // const sw = new mapboxgl.LngLat(startMarker[0], startMarker[1]);
            // const ne = new mapboxgl.LngLat(startMarker[0], startMarker[1]);
            // const bounds = new mapboxgl.LngLatBounds(sw, ne);
            // for (const coord of coordinates) {
            //     bounds.extend(coord);
            // }
            // map.fitBounds(bounds, {
            //     padding,
            // });
        }
        // map.on('move', () => {
        //     if (DEBUG) console.log('map move', map.getCenter(), map.getZoom());
        //     // const { lng, lat } = map.getCenter();
        //     this.setState({
        //         lng: map.getCenter().lng.toFixed(4),
        //         lat: map.getCenter().lat.toFixed(4),
        //         zoom: map.getZoom().toFixed(2),
        //     });
        // });
        map.on('moveend', (event) => {
            if (DEBUG) console.log('map moveEnd', map.getCenter(), map.getZoom());

            const { centerForClass } = this.state;
            centerForClass[selectedClassId] = {
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2),
                customCenter: true,
            };
            this.setState({
                centerForClass,
                // lng: map.getCenter().lng.toFixed(4),
                // lat: map.getCenter().lat.toFixed(4),
                // zoom: map.getZoom().toFixed(2),
                // customCenter: true,
            });
        });
    }

    onZoomEnd = (map, event) => {
        const { zoomLevel } = this.state;

        if (event.fitboundUpdate) {
            if (DEBUG) console.log('onZoomEnd: Map bounds have been programmatically changed');
        } else {
            if (DEBUG) console.log('onZoomEnd: Map bounds have been changed by user interaction', event);
            const zoom = map.getZoom();
            const newZoomLevel = Math.floor(zoom);
            if (zoomLevel !== newZoomLevel) {
                this.setState({
                    zoomLevel: newZoomLevel,
                }, () => {
                    this.makeDistanceMarkers();
                });
            }
        }
    }

    onMoveEnd = (map, event) => {
        if (DEBUG) console.log('onMoveEnd', map.getCenter(), map.getZoom());
        // const { lng, lat } = map.getCenter();
        this.setState({
            lng: map.getCenter().lng.toFixed(4),
            lat: map.getCenter().lat.toFixed(4),
            zoom: map.getZoom().toFixed(2),
        });
    }

    makeStartAndEndMarkers = () => {
        if (DEBUG) console.log('makeStartAndEndMarkers');
        const {
            geojson,
        } =  this.props;

        let startMarker;
        let endMarker;
        const tempGeoJson = toJS(geojson);
		const track = util.asObject(tempGeoJson, 'features', 0, 'geometry', 'coordinates');
		if (track && track[0] && track[0][0]) {
            startMarker = track[0][0] && track[0][0][0] ?  [track[0][0][0], track[0][0][1]] : [track[0][0], track[0][1]];
			endMarker = track[track.length - 1][0] && track[track.length - 1][0][0] ? [track[track.length - 1][0][0], track[track.length - 1][0][1]] : [track[track.length - 1][0], track[track.length - 1][1]];
        }
        this.setState({
            startMarker,
            endMarker,
        });
    }

    makeDistanceMarkers = () => {
        const { zoomLevel } = this.state;
        const {
            geojson,
            gpxInfo,
            distanceMarkerSteps = 'normal',
            showDistanceMarkers,
        } =  this.props;
        if (!showDistanceMarkers) return;
        if (DEBUG) console.log('makeDistanceMarkers');

        const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;
        const distanceMarkers = [];

        const tempGeoJson = toJS(geojson);
		const track = util.asObject(tempGeoJson, 'features', 0, 'geometry', 'coordinates');
		if (track && track[0] && track[0][0]) {
			if (gpxInfo && gpxInfo.distances) {
				let totalDistance = 0;
				let currentDistance = 0;
				let steps = 10;
				switch (zoomLevel) {
					case 15:
						steps = distanceMarkerSteps === 'long' ? 3 : 1;
						break;
					case 14:
						steps = distanceMarkerSteps === 'long' ? 3 : 1;
						break;
					case 13:
						steps = distanceMarkerSteps === 'long' ? 5 : 2;
						break;
					case 12:
						steps = distanceMarkerSteps === 'long' ? 7 : 3;
						break;
					case 11:
						steps = distanceMarkerSteps === 'long' ? 10 : 5;
						break;
					case 10:
						steps = distanceMarkerSteps === 'long' ? 15 : 10;
						break;
					case 9:
						steps = distanceMarkerSteps === 'long' ? 20 : 15;
						break;
					case 8:
						steps = distanceMarkerSteps === 'long' ? 30 : 20;
						break;
					case 7:
						steps = distanceMarkerSteps === 'long' ? 40 : 30;
						break;
					case 6:
						steps = distanceMarkerSteps === 'long' ? 70 : 50;
						break;
					case 5:
						steps = distanceMarkerSteps === 'long' ? 150 : 100;
						break;
					default:
						steps = distanceMarkerSteps === 'long' ? 200 : 150;
						break;
				}
				// console.log({ mapZoom, steps });
				gpxInfo.distances.forEach((e, idx) => {
					totalDistance += settings.imperial ? kmToMiles(e) : e;
					currentDistance += settings.imperial ? kmToMiles(e) : e;
					if (currentDistance >= steps) {
						currentDistance = 0;
						if (track[idx] && Array.isArray(track[idx])) {
							distanceMarkers.push({
								lat: track[idx][1],
								lng: track[idx][0],
								// icon: wp.icon || 'marker',
								title: Math.floor(totalDistance),
								body: '',
								// color: wp.color,
							});
						}
					}
				});
                if (DEBUG) console.log('distanceMarkers', distanceMarkers);
                this.setState({
                    distanceMarkers,
                });
			}
		}
    }

    render() {
        const {
            lng,
            lat,
            // zoom,
            zoomLevel,
            distanceMarkers,
            startMarker,
            endMarker,
        } = this.state;

        const {
            geojson,
            zoom,
            center,
            defaultCenter = [lng, lat],
            pitch = 40,
            showZoomControl = true,
            showRotationControl = true,
            showScaleControl = true,
            animationOptions = { animate: false },
            movingMethod = 'jumpTo',
            preserveDrawingBuffer,
            lineWidth,
            attributionControl,
            style = 'mapbox://styles/mapbox/outdoors-v11?optimize=false',
            geojsonExternal,
        } =  this.props;


        return (
            <div class={`w-100 h-100 overflow-hidden`}
                // onTouchStart={mu.captureAllEvents}
                // onTouchend={mu.captureAllEvents}
                onTouchMove={mu.captureAllEvents}
                onScroll={mu.captureAllEvents}
            >
{/* <div class='fixed-top w-100' style={{ zIndex: 1000 }}>
    geojsonExternal: {JSON.stringify(geojsonExternal)}
</div> */}
                {geojsonExternal ? <>
                    <Map
                        style={style}
                        containerStyle={{
                            height: '100%',
                            width: '100%',
                        }}
                        defaultCenter={defaultCenter}
                        // zoom={[zoom]}
                        // center={[lat, lng]}
                        // onData={(data) => console.log('onData', data)}
                        // zoom={[zoom]}
                        // center={center}
                        // pitch={[pitch]}
                        onStyleLoad={ map => this.onStyleLoad(map) }
                        onZoomEnd={ (map, e) => this.onZoomEnd(map, e) }
                        onMoveEnd={ (map, e) => this.onMoveEnd(map, e) }
                        movingMethod={movingMethod}
                        animationOptions={animationOptions}
                        flyToOptions={animationOptions}
                    >
                        {showZoomControl && <ZoomControl position='top-right' />}
                        {showRotationControl && <RotationControl position='top-right' />}
                        {showScaleControl && <ScaleControl position='bottom-right' />}

                        <MapboxExternalContent
                            stores={this.props.stores}
                            geojsonExternal={geojsonExternal}
                            {...this.props}
                            lineWidth={lineWidth}
                        />

                        {/*
                            <DrawControl
                                position="top-left"
                                onDrawCreate={this.onDrawCreate}
                                onDrawUpdate={this.onDrawUpdate}
                            />
                        */}

                    </Map>
                </> : <>
                    {geojson && geojson.features[0]?.geometry?.coordinates ? <>
{/* <xmp style='z-index: 100000; max-height: 50vh;'>{JSON.stringify(this.props, null, 4)}</xmp> */}
                        <Map
                            style={style}

                            containerStyle={{
                                height: '100%',
                                width: '100%',
                            }}
                            defaultCenter={defaultCenter}
                            // zoom={[zoom]}
                            // center={[lat, lng]}
                            // onData={(data) => console.log('onData', data)}
                            // zoom={[zoom]}
                            // center={center}
                            // pitch={[pitch]}
                            onStyleLoad={ map => this.onStyleLoad(map) }
                            onZoomEnd={ (map, e) => this.onZoomEnd(map, e) }
                            movingMethod={movingMethod}
                            animationOptions={animationOptions}
                            flyToOptions={animationOptions}
                        >
                            {showZoomControl && <ZoomControl position='top-right' />}
                            {showRotationControl && <RotationControl position='top-right' />}
                            {showScaleControl && <ScaleControl position='bottom-right' />}

                            <MapboxContentMap
                                stores={this.props.stores}
                                geojson={geojson}
                                {...this.props}
                                startMarker={startMarker}
                                endMarker={endMarker}
                                distanceMarkers={distanceMarkers}
                                lineWidth={lineWidth}
                            />

                            {/*
                                <DrawControl
                                    position="top-left"
                                    onDrawCreate={this.onDrawCreate}
                                    onDrawUpdate={this.onDrawUpdate}
                                />
                            */}

                        </Map>
                    </> : <>
                        <div class='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div class='spinner-grow text-primary' style='width: 6rem; height: 6rem;' role='status'>
                                <span class='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </>}
                </>}
            </div>
        );
    }
}

export default MapboxMap;
