import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class NewsStore extends StoreModel {
    constructor() {
        super('news', {
            namePlural: 'newsList',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/news/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/news/',
                    params: {},
                },
                save: {
                    url: '/api/news/',
                    params: {},
                },
            },
        });
    }

    @observable newNews = {};

    @observable news = {};

    @observable newsList = [];

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.news.images)) {
            const idx = this.news.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.news.images.splice(idx, 1);
            }
        }
        const widx = this.newsList?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.newsList[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.newsList[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/news/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToNews({ id, data }) {
        if (util.isArray(this.news.likes)) {
            this.news.likes.push(data);
        }
        const idx = this.newsList?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.newsList[idx].likes)) {
                this.newsList[idx].likes = [];
            }
            this.newsList[idx].likes.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        if (util.isDefined(this.news.comments)) {
            const commentIdx = this.news.comments?.findIndex(e => e.id === commentId);
            const comment = this.news.comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        const idx = this.newsList?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.newsList[idx].comments)) {
                const commentIdx = this.newsList[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.newsList[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        if (util.isDefined(this.news.comments)) {
            const commentIdx = this.news.comments?.findIndex(e => e.id === commentId);
            const comment = this.news.comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        const idx = this.newsList?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.newsList[idx].comments)) {
                const commentIdx = this.newsList[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.newsList[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    addCommentToNews({ id, data }) {
        if (util.isArray(this.news.comments)) {
            this.news.comments.push(data);
        }
        const idx = this.newsList?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.newsList[idx].comments)) {
                this.newsList[idx].comments = [];
            }
            this.newsList[idx].comments.push(data);
        }
    }

    @action
    addCommentToNewsComment({ id, commentId, data }) {
        if (util.isDefined(this.news.comments)) {
            const commentIdx = this.news.comments?.findIndex(e => e.id === commentId);
            const comment = this.news.comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        const idx = this.newsList?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.newsList[idx].comments)) {
                const commentIdx = this.newsList[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.newsList[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    async likeNews({ id }) {
        const response = await util.fetchApi(`/api/news/like/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToNews({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeNewsComment({ id, commentId }) {
        const response = await util.fetchApi(`/api/news/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeNewsCommentReply({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/news/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToCommentReply({ id, commentId, replyId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentNews({ id, comment }) {
        const response = await util.fetchApi(`/api/news/comment/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToNews({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyNews({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/news/comment/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToNewsComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

}

const store = new NewsStore();
export default store;
