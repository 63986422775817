import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import LineGraph from '../../components/lineGraph';

@observer
class FormGraph extends Component {
    render() {
        const { dogStore } = this.props.stores;
        const {
            graphFitness = [],
            graphFatigue = [],
            graphForm = [],
		} = dogStore;

        const xTicksAll = graphForm.map(e => e.date) || [];
        const xTicks = [];
        if (util.isArray(xTicksAll)) {
            const partsLength = Math.floor(xTicksAll.length / 4);
            for (let i = 0, l = xTicksAll.length; i < l; i += partsLength) {
                xTicks.push(xTicksAll[i]);
            }
            xTicks[xTicks.length - 1] = xTicksAll[xTicksAll.length - 1];
        }

        return (
            <>
                <h5><Text id='teams.fitness'>Form og belastning</Text></h5>
                <div class='w-100 mt-3 mb-4'>
                    <Localizer>
                        <LineGraph
                            stores={this.props.stores}
                            width={1500}
                            height={300}
                            showXTicks={1}
                            xTicks={xTicks}
                            paddingTop={30}
                            lineFromXzero={false}
                            lineFromXmax={false}

                            dataLeft={[graphFitness, graphFatigue]}
                            dataFillLeft={[1]}
                            legendLeft={[<Text id='teams.legend-fitness'>Fitness</Text>, <Text id='teams.legend-fatigue'>Fatigue</Text>]}

                            dataRight={[graphForm]}
                            legendRight={[<Text id='teams.legend-form'>Form</Text>]}

                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            avgWindowLeft={[0, 0]}
                            smoothLeft={[0, 0]}

                            // dataRight={[graphWorkoutAvgWeekElevationCurrent, graphAvgWorkoutStatsElevation]}
                            // legendRight={[`${seasonStart} ${ascendAvg}`, `${seasonStart} ${ascendAvgAll}`]}
                            showYTicksRight={1}
                            // yTicksRightPostfix={'m'}
                            // unitRight={['m', 'm']}
                            // avgWindowRight={[0, 0]}
                            // smoothRight={[1, 1]}

                            // marker={week}
                            scrollToRight={true}
                        />
                    </Localizer>
                </div>
            </>
        );
    }
}

export default FormGraph;
