import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileTeamList extends Component {
    newTeam = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newTeam', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    render() {
        const { appState } = this.props.stores;
        const { drawerLevel } = this.props;
        const { darkmode } = appState;

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <WidgetHead
                    stores={this.props.stores}
                    title={<Text id='teams.all-teams'>All teams</Text>}
                    right={<Text id='team.add-team'>Add team</Text>}
                    onRightClick={this.newTeam}
                />
                <WidgetBody stores={this.props.stores}>
                    Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br />
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileTeamList;
