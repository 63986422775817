import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import mu from '../lib/musher-util';

configure({ enforceActions: 'always' });

const isDevelopment = process.env.NODE_ENV === 'development';

function consoleLog(...args) {
    if (isDevelopment) {
        console.log(...args);
    }
}

class MusherModel extends StoreModel {
    foo() {
        // console.log(`foo: ${this.constructor}`);
        console.log(`model.name: ${this.name}`);
        console.log(`model.namePlural: ${this.namePlural}`);
    }

    console(...args) {
        this.foo();
        console.log(...args);
    }

    @action
    reorderImages(startIndex, endIndex) {
        const [removed] = this.images.splice(startIndex, 1);
        this.images.splice(endIndex, 0, removed);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Likes
    @action
    likeAdd({ id, data, holdingArrayName }) {
        // consoleLog({ id, data, holdingArrayName });
        if (!data) {
            return false;
        }
        if (util.isUndefined(this[this.name].likes)) {
            this[this.name].likes = [];
        }
        if (util.isArray(this[this.name].likes)) {
            this[this.name].likes.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].likes)) {
                this[this.namePlural][idx].likes = [];
            }
            const hasLikedObject = this[this.namePlural][idx].likes.find(e => e.user === data.user);
            if (!hasLikedObject) {
                this[this.namePlural][idx].likes.push(data);
            }
        }
        // holdingArrayName
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].likes)) {
                this[holdingArrayName][idx].likes = [];
            }
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
            const hasLikedObject = this[holdingArrayName][idx].likes.find(e => e.user === data.user);
            if (!hasLikedObject) {
                this[holdingArrayName][idx].likes.push(data);
            }
        }
    }

    @action
    likeRemove({ id, data, holdingArrayName }) {
        // consoleLog({ id, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isUndefined(this[this.name].likes)) {
                this[this.name].likes = [];
            }
            this[this.name].likes = this[this.name].likes.filter(e => e.user !== data.user);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].likes)) {
                this[this.namePlural][idx].likes = [];
            }
            this[this.namePlural][idx].likes = this[this.namePlural][idx].likes.filter(e => e.user !== data.user);
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].likes)) {
                this[holdingArrayName][idx].likes = [];
            }
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
            this[holdingArrayName][idx].likes = this[holdingArrayName][idx].likes.filter(e => e.user !== data.user);
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
        }
    }

    async like({ id, holdingArrayName }) {
        const response = await util.fetchApi(`/api/likes/${this.namePlural}/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.likeAdd({ id, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async unlike({ id, holdingArrayName }) {
        const response = await util.fetchApi(`/api/likes/${this.namePlural}/${id}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.likeRemove({ id, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Comments
    @action
    commentAddLike({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentAddLike.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                const hasLikedObject = comment.likes.find(e => e.user === data.user);
                if (!hasLikedObject) {
                    comment.likes.push(data);
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                const hasLikedObject = comment.likes.find(e => e.user === data.user);
                if (!hasLikedObject) {
                    comment.likes.push(data);
                }
            }
        }
    }

    @action
    commentReplyAddLike({ id, commentId, replyId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyAddLike.${this.name}:`, { id, commentId, replyId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    const hasLikedObject = reply.likes.find(e => e.user === data.user);
                    if (!hasLikedObject) {
                        reply.likes.push(data);
                    }
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    const hasLikedObject = reply.likes.find(e => e.user === data.user);
                    if (!hasLikedObject) {
                        reply.likes.push(data);
                    }
                }
            }
        }
    }

    @action
    commentAdd({ id, data, holdingArrayName }) {
        consoleLog(`musherModel.commentAdd.${this.name}:`, { id, data, holdingArrayName });
        if (util.isArray(this[this.name].comments)) {
            this[this.name].comments.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].comments)) {
                this[this.namePlural][idx].comments = [];
            }
            this[this.namePlural][idx].comments.push(data);
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].comments)) {
                this[holdingArrayName][idx].comments = [];
            }
            this[holdingArrayName][idx].comments.push(data);
        }
    }

    @action
    commentRemove({ id, commentId, holdingArrayName }) {
        consoleLog(`musherModel.commentRemove.${this.name}:`, { id, commentId, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                this[this.name].comments.splice([commentIdx], 1);
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                this[this.namePlural][idx].comments.splice([commentIdx], 1);
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                this[holdingArrayName][idx].comments.splice([commentIdx], 1);
            }
        }
    }

    @action
    commentEdit({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentEdit.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                comment.comment = data;
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                comment.comment = data;
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                comment.comment = data;
            }
        }
    }

    @action
    commentReplyAdd({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyAdd.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    @action
    commentReplyRemove({ id, commentId, replyId, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyRemove.${this.name}:`, { id, commentId, replyId, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
    }

    @action
    commentReplyEditObject({ id, commentId, replyId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyEditObject.${this.name}:`, { id, commentId, replyId, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
    }

    async commentLike({ id, commentId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.commentAddLike({ id, commentId, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyLike({ id, commentId, replyId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.commentReplyAddLike({ id, commentId, replyId, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async comment({ id, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.commentAdd({ id, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentDelete({ id, commentId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.commentRemove({ id, commentId, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReply({ id, commentId, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.commentReplyAdd({ id, commentId, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyDelete({ id, commentId, replyId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentId}/${replyId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.commentReplyRemove({ id, commentId, data: response.data, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyEdit({ id, commentId, replyId, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentId}/${replyId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.commentReplyEditObject({ id, commentId, replyId, data: comment, holdingArrayName });
                // mu.tapticWeakBoom();
                return response.data;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Comment translate
    @action
    commentTranslateUpdate({ id, commentid, value, targetLang, field = 'comment', holdingArrayName }) {
        consoleLog(`musherModel.commentTranslateUpdate.${this.name}:`, { id, commentid, value, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex(e => {
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        comment.translations[translationIdx] = translationObj;
                    } else {
                        comment.translations.push(translationObj);
                    }
                } else {
                    comment.translations = [translationObj];
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };
                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex(e => {
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        comment.translations[translationIdx] = translationObj;
                    } else {
                        comment.translations.push(translationObj);
                    }
                    comment.translations = [translationObj];
                }
            }
        }
    }

    @action
    commentReplyTranslateUpdate({ id, commentid, replyid, value, targetLang, field = 'comment', holdingArrayName }) {
        consoleLog(`musherModel.commentReplyTranslateUpdate.${this.name}:`, { id, commentid, replyid, value, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
    }

    commentTranslateGet({ id, commentid, targetLang, field = 'comment', holdingArrayName, debug }) {
        if (debug) {
            consoleLog(`musherModel.commentTranslateGet.${this.name}:`, { id, commentid, targetLang, holdingArrayName });
        }
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (debug) {
                consoleLog(`musherModel.commentTranslateGet.${this.name}:`, { objectIdx, object });
            }

            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        return null;
    }

    commentReplyTranslateGet({ id, commentid, replyid, targetLang, field = 'comment', holdingArrayName }) {
        // consoleLog(`musherModel.commentReplyTranslateGet.${this.name}:`, { id, commentid, replyid, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        return null;
    }

    async commentTranslate({ object, user = {}, commentid, holdingArrayName }) {
        consoleLog(`musherModel.commentTranslate.${this.name}:`, { object, commentid, user, holdingArrayName });
        const { id } = object;
        const { language: targetLang = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const text = comment.comment;

        const sourceLang = comment.language === 'undefined' ? 'no' : comment.language;
        // const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;
        const translatedText = this.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName });
        if (translatedText) {
            return translatedText;
        }
        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        const updateObject ={
                            id: object.id,
                            commentid,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        };
                        this.commentTranslateUpdate(updateObject);
                        return updateObject;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    async commentReplyTranslate({ object, user = {}, commentid, replyid, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyTranslate.${this.name}:`, { object, user, commentid, replyid, holdingArrayName });
        const { id } = object;
        const { language: targetLang = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
        const reply = comment.comments[replyIdx];
        const text = reply.comment;

        const sourceLang = reply.language === 'undefined' ? 'no' : reply.language;
        // const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = this.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/comments/${this.namePlural}/${id}/${commentid}/${replyid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        const updateObject = {
                            id: object.id,
                            commentid,
                            replyid,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        };
                        this.commentReplyTranslateUpdate(updateObject);
                        return updateObject;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Translate
    @action
    updateTranslation({ id, field, value, targetLang, holdingArrayName = 'publicFeed' }) {
        // const idx = this.publicFeed?.findIndex(e => e.id === id);
        // if (idx > -1) {
        //     this.publicFeed[idx][`${field}${targetLang}`] = value;
        // }

        if (util.isObject(this[this.name])) {
            this[this.name][`${field}${targetLang}`] = value;
        }

        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            this[this.namePlural][idx][`${field}${targetLang}`] = value;
        }

        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            this[holdingArrayName][idx][`${field}${targetLang}`] = value;
        }
    }

    async translate({ object, user, field = 'body', holdingArrayName }) {
        consoleLog(`musherModel.translate.${this.name}:`, { object, user, field, holdingArrayName });
        const { id, language = 'en' } = object;
        const text = object[field];
        const { language: userLanguage = 'en' } = user;

        const sourceLang = language === 'undefined' ? 'no' : language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = mu.getTranslation({ object, targetLang, field });
console.log({ sourceLang, targetLang, translatedText });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(
                `/api/${this.namePlural}/${id}/translate/${sourceLang}/${targetLang}/${field}`,
                { publish: false, method: 'POST' },
                { text }
            );
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateTranslation({
                            id: object.id,
                            field,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // View logging
    async postScrollview(data = []) {
        if (!data || data?.length === 0) {
            return false;
        }
        const response = await util.fetchApi(`/api/scrollview/`, { publish: false, method: 'POST' }, { data });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Performance logging
    hrStart = () => {
        return new Date().getTime();
    }

    @action
    addLog = (hrStart, message, title) => {
        this.logs.push({
            timeused: new Date().getTime() - hrStart,
            title,
            message,
        });
        return this.hrStart();
    }

    @action
    getLogs = () => {
        const logs = [...this.logs];
        this.logs = [];
        return logs;
    }

    printLogs = () => {
        this.logs.forEach((e) => {
            console.log(`${e.message}: ${e.timeused} ms`);
        });
    }

    async postLog({ type = 'workoutStore', deviceInfo, currentLocation }) {
        const lines = this.getLogs();
        const response = await util.fetchApi(`/api/logs/`, { publish: true, method: 'POST' }, {
            lines,
            deviceInfo,
            currentLocation,
            type,
        });
        switch (response.status) {
            case 201:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

export default MusherModel;
