import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import WidgetRouter from '../../../components/widget/router';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileTeamDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: `team-detail-${new Date().getTime()}`,
        };
    }

    newTeam = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newTeam', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    render() {
        const { key } = this.state;
        const { appState, userStore } = this.props.stores;
        const { drawerLevel, id } = this.props;
        const { darkmode } = appState;

        const team = userStore.findTeam(parseInt(id, 10), true);

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <WidgetHead
                    stores={this.props.stores}
                    title={team.name}
                    right={<Text id='team.add-team'>Add team</Text>}
                    onRightClick={this.newTeam}
                />
                <WidgetBody stores={this.props.stores}>
                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br />
                    id: {id}<br /> */}

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            <WidgetRouter widgets={team.widgetsTeam} stores={this.props.stores} key={key} id={id}  />
                        </div>
                    </div>
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileTeamDetail;
