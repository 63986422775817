import { h, Component } from 'preact';
import { Router, route } from 'preact-router';
import { createHashHistory } from 'history';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';
import AsyncRoute from 'preact-async-route';
import queryString from 'query-string';

import util from 'preact-util';

import { polyfill } from "seamless-scroll-polyfill";
polyfill();

import mu from '../lib/musher-util';
import PubSub, { topics } from '../lib/pubsub';

import appState from '../stores/appstate';
import adminStore from '../stores/admin';
import waypointStore from '../stores/waypoint';
import userStore from '../stores/user';
import dogStore from '../stores/dog';
import teamStore from '../stores/team';
import workoutStore from '../stores/workout';
import workoutPlanStore from '../stores/workoutPlan';
import trackStore from '../stores/track';
import newsStore from '../stores/news';
import adStore from '../stores/ad';
import applicationStore from '../stores/application';
import stravaActivityStore from '../stores/stravaActivity';
import garminActivityDetailsStore from '../stores/garminActivityDetails';
import raceStore from '../stores/race';
import storyStore from '../stores/story';
import paypalStore from '../stores/paypal';
import vaccineLogStore from '../stores/vaccineLog';
import dogJournalStore from '../stores/dogJournal';
import messageStore from '../stores/message';
import widgetStore from '../stores/widget';
import trophyStore from '../stores/trophy';

import definitionNo from './languages/no';
import definitionDe from './languages/de';
import definitionEn from './languages/en';
import definitionEs from './languages/es';
import definitionFr from './languages/fr';
import definitionRu from './languages/ru';
import definitionSe from './languages/se';

const countryMap = {
    no: definitionNo,
    de: definitionDe,
    en: definitionEn,
    es: definitionEs,
    fr: definitionFr,
    ru: definitionRu,
    se: definitionSe,
    default: definitionEn,
};

import Progress from './progress';
import Message from './message';
import RefreshSpinner from './refresh';
import ReadyToRefresh from './refresh/ready';
import Header from './header';
import Footer from './footer';
import LoadingSpinner from '../routes/loading';

// import Login from './login';
// import Register from './register';
// import Forgotten from './forgotten';

// Code-splitting is automated for routes
// import Public from '../routes/public';
// import PublicMap from '../routes/public/map';
// import PublicRace from '../routes/public/race';
// import PublicRaceEdit from '../routes/public/race/edit';
// import PublicRaceSupport from '../routes/public/race/support';
// import PublicRaceNews from '../routes/public/race/news';
// import PublicRaceDetail from '../routes/public/race/detail';
// import PublicRaceMushers from '../routes/public/race/mushers';
// import PublicRaceResults from '../routes/public/race/results';
// import PublicRaceResultsMain from '../routes/public/race/results-main';
// import PublicRaceMedia from '../routes/public/race/media';
// import PublicRaceTracking from '../routes/public/race/tracking';

// import GrantAccess from '../routes/grant-access/';

// import Veterinary from '../routes/veterinary';
// import AppRaces from '../routes/app-races';
// import AppRacesList from '../routes/app-races/list';

// import Home from '../routes/home';
// import HomeFeed from '../routes/home/feed';
// import HomeTodo from '../routes/home/todo';
// import HomeAdmin from '../routes/home/admin';

// import Settings from '../routes/settings';
// import SettingsGeneral from '../routes/settings/general';
// import SettingsUser from '../routes/settings/user';
// import SettingsTeam from '../routes/settings/team';
// import SettingsTeamDetail from '../routes/settings/teamDetail';

// import Stories from '../routes/stories';
// import StoryDetail from '../routes/stories/detail';

// import Users from '../routes/users';

// import UsersSettings from '../routes/users/settings';
// import UsersSubscriptions from '../routes/users/subscriptions';
// import UsersIntegrations from '../routes/users/integrations';

// import UsersEdit from '../routes/users/edit';
// import UsersEditPassword from '../routes/users/editPassword';
// import UsersConnect from '../routes/users/connect';
// import UsersNotifications from '../routes/users/notifications';

// import Dogs from '../routes/dogs';
// import DogsPublicDetail from '../routes/dogs/publicDetail';
// import DogsList from '../routes/dogs/list';
// import DogsTodo from '../routes/dogs/todo';
// import DogsFeed from '../routes/dogs/feed';
// import DogsDetail from '../routes/dogs/detail';
// import DogsEdit from '../routes/dogs/edit';

// import Workouts from '../routes/workouts';
// import WorkoutsList from '../routes/workouts/list';
// import WorkoutsDetail from '../routes/workouts/detail';
// import WorkoutsPublicDetail from '../routes/workouts/publicDetail';
// import WorkoutsEdit from '../routes/workouts/edit';
// import WorkoutsMonth from '../routes/workouts/month';
// import WorkoutsWeek from '../routes/workouts/week';
// import WorkoutsPlan from '../routes/workouts/plan';
// import WorkoutsTracking from '../routes/workouts/tracking';

// import Tracks from '../routes/tracks';
// import TracksPublic from '../routes/tracks/public';
// import TracksDetail from '../routes/tracks/detail';
// import TracksEdit from '../routes/tracks/edit';

// import TeamsPublic from '../routes/teams/public';
// import TeamsPublicDetail from '../routes/teams/publicDetail';
// import Teams from '../routes/teams';
// import TeamsDetail from '../routes/teams/detail';
// import TeamsEdit from '../routes/teams/edit';
// import TeamsAddMember from '../routes/teams/addMember';
// import TeamsConnect from '../routes/teams/connect';

// import News from '../routes/news';
// import NewsDetail from '../routes/news/detail';
// import NewsEdit from '../routes/news/edit';

// import Inbox from '../routes/inbox';
// import InboxNew from '../routes/inbox/new';
// import InboxDetail from '../routes/inbox/detail';

// import TvTop10 from '../routes/tv/top10';
// import TvTop10Ticker from '../routes/tv/top10ticker';
// import TvOneTeam from '../routes/tv/oneTeam';
// import TvTracking from '../routes/tv/tracking';
// import TvFreeText from '../routes/tv/freetext';

// import EmbedTop10 from '../routes/embed/top10';
// import EmbedTracking from '../routes/embed/tracking';
// import EmbedChiplist from '../routes/embed/chiplist';
// import EmbedAgreement from '../routes/embed/agreement';

// import Search from '../routes/search';
// import Logout from '../routes/logout';

const SCROLLDOWN_LIMIT = 250;

const history = createHashHistory();

// history.listen((location, action) => {
//     console.log({ location, action });
//     // window.scrollTo(0, 0)
// })

// function log(msg){
//     console.log(msg);
// }

// function logError(msg, error){
//     if (typeof error === 'object'){
//         msg += `: ${JSON.stringify(error)}`;
//     } else if (typeof error === 'string'){
//         msg += `: ${error}`;
//     }
//     console.error(msg);
// }

class Catcher extends Component {
    constructor() {
        super();
        this.state = {
            errored: false,
            errorsReported: 0,
        };
    }

    componentDidCatch(error) {
        const { errorsReported } = this.state;
        if (errorsReported < 10) {
            const data = {
                timestamp: Math.floor(new Date().getTime() / 1000),
                error: {
                    name: error.name,
                    message: error.message,
                    stack: error.stack,
                },
                location: window.location,
            };
            this.setState({ errored: true, errorsReported: errorsReported + 1 });
            util.fetchApi('/api/errors/', { method: 'POST' }, data);
        }
    }

    reload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.assign('/');
        // window.location.reload();
    }

    render(props, state) {
        if (state.errored) {
            return (
                <div class='container-fluid h-100'>
                    <div class='row h-100'>
                        <div class='col-12 text-center my-auto'>
                            <div class='display-1 text-muted'>
                                <i class='fas fa-bomb' />
                            </div>
                            <h5>Something went badly wrong...</h5>
                            <p>Error is reported automatically.</p>
                            <a class='mt-3 btn btn-lg btn-primary' href='#' onClick={this.reload}>Go to the frontpage</a>
                        </div>
                    </div>
                </div>
            );
        }
        return props.children;
    }
}

@observer
class App extends Component {
	constructor(props) {
        super(props);
		this.stores = {
            appState,
            adminStore,
            waypointStore,
            userStore,
            dogStore,
            teamStore,
            workoutStore,
            workoutPlanStore,
            trackStore,
            newsStore,
            adStore,
            applicationStore,
            stravaActivityStore,
            garminActivityDetailsStore,
            raceStore,
            storyStore,
            paypalStore,
            vaccineLogStore,
            dogJournalStore,
            messageStore,
            widgetStore,
            trophyStore,
            history,
        };
        const startUrl = window.location.hash.replace(/^#/, '');
        this.state = {
            jwtToken: util.getJwtToken(),
            refreshTime: new Date().getTime(),
            currentUrl: startUrl,
            checkCordovaCounter: 0,
            bug: {},
        };
        this.subscriptions();
        const { darkmode } = appState;
        util.toggleDarkModeClasses(darkmode);
    }

    subscriptions() {
        PubSub.subscribe(topics.LOG_OUT, () => {
            this.setState({
                jwtToken: undefined,
            });
            util.removeUserEmail();
            util.removeJwtToken();
        });

        PubSub.subscribe(topics.JWT_TOKEN_CHANGED, async (token) => {
            this.setState({
                jwtToken: token,
            });
            route('/');
        });
    }

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
        const { scrolledDown } = this.state;
        const { prevScroll } = appState;
		this.setState({ currentUrl: e.url });
        appState.setPrevPath(e.previous);
        appState.setPrevScroll(scrolledDown);
        appState.setPath(e.url);
        this.cleanupMemory(e.url, e.previous);
        const { router } = e;
        const { props = {} } = router;
        const { history = {} } = props;
        const { action = {} } = history;
        if (this.appContainer) {
            //             if (prevScroll && action === 'POP') {
            //                 console.log({ e, scrolledDown, prevScroll }, this.appContainer);
            //                 this.scrollTimer = setTimeout(() => this.appContainer.scrollTo({ top: prevScroll }), 2000);
            //             }
            if (!e.url.match(/skipScroll/)) {
                // this.appContainer.scrollTo({ top: 0 });
                mu.scrollTo(this.appContainer);
            }
        }
	};

    cleanupMemory = (currentUrl, prevUrl) => {
        try {
            const urlRegexpWorkout = new RegExp(`^/workouts`);
            const urlRegexpTracks = new RegExp(`^/tracks`);
            const urlRegexpTeams = new RegExp(`^/teams`);
            const urlRegexpAppRaces = new RegExp(`^/app-races`);
            const urlRegexpStory = new RegExp(`^/$`);
            const urlRegexpPublicWorkouts = new RegExp(`^/home-workout`);
            const urlRegexpDogs = new RegExp(`^/dogs`);
            const urlRegexpTrackDetail = new RegExp(`^/tracks/[0-9]+`);
            const urlRegexpWorkoutDetail = new RegExp(`^/workouts/[0-9]+`);
            const urlRegexpWorkoutTracking = new RegExp(`^/workouts/tracking`);

            const urlRegexpAdmin = new RegExp(`/admin`);

            if (urlRegexpWorkout.test(prevUrl) && !urlRegexpWorkout.test(currentUrl)) {
                workoutStore.cleanupMemory();
                stravaActivityStore.cleanupMemory();
                garminActivityDetailsStore.cleanupMemory();
            } else if (urlRegexpTracks.test(prevUrl) && !urlRegexpTracks.test(currentUrl)) {
                trackStore.cleanupMemory();
            } else if (urlRegexpTeams.test(prevUrl) && !urlRegexpTeams.test(currentUrl)) {
                teamStore.cleanupMemory();
            } else if (urlRegexpAppRaces.test(prevUrl) && !urlRegexpAppRaces.test(currentUrl)) {
                raceStore.cleanupMemory();
            } else if (urlRegexpStory.test(prevUrl) && !urlRegexpStory.test(currentUrl)) {
                storyStore.cleanupMemory();
            } else if (urlRegexpPublicWorkouts.test(prevUrl) && !urlRegexpPublicWorkouts.test(currentUrl)) {
                teamStore.cleanupMemoryPublic();
            } else if (urlRegexpDogs.test(prevUrl) && !urlRegexpDogs.test(currentUrl)) {
                dogStore.cleanupMemory();
            } else if (urlRegexpTrackDetail.test(prevUrl) && !urlRegexpTrackDetail.test(currentUrl)) {
                trackStore.cleanupMemoryDetail();
            } else if (urlRegexpWorkoutDetail.test(prevUrl) && !urlRegexpWorkoutDetail.test(currentUrl)) {
                workoutStore.cleanupMemoryDetail();

            } else if (urlRegexpAdmin.test(prevUrl) && !urlRegexpAdmin.test(currentUrl)) {
                adminStore.cleanupMemory();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async loadAll() {
        await appState.getFingerprint();
        const { darkmode } = appState;
        await userStore.getInfo(false, true, false, 'en', darkmode ? 1 : 0);
        const { isAdmin, isRaceAdmin, isVeterinary, user } = userStore;

        // appState.websocket(user);

        await teamStore.load({ query: {}, addData: ['followRequests'] });

        // Determin version of app
        const hostname = window.location.host;
        const storedLocation = user.settings?.locationBeta ? 'beta' : 'prod';
        let appVersion;
        switch (hostname) {
            case 'themusher.app':
                appVersion = 'prod';
                break;
            case 'dev.themusher.app':
                appVersion = 'beta';
                break;
            case '127.0.0.1:8080':
            case 'localhost:8080':
                appVersion = 'dev';
                break;
            default:
                appVersion = 'prod';
        }
        // console.log({ storedLocation, appVersion });
        if (appVersion === 'prod' && storedLocation === 'beta') {
            const location = 'https://dev.themusher.app';
            window.location = location;
        }

		if (user.currentLocationInfo && user.currentLocationInfo.coords) {
			const { latitude: lat, longitude: lon, altitude } = user.currentLocationInfo.coords;
			userStore.getWeatherUser({ lat, lon, altitude });
		}

        raceStore.setAdmin(isAdmin);
        raceStore.setRaceAdmin(isAdmin || isRaceAdmin);
        raceStore.setVeterinary(isVeterinary);
    }

    touchStart = (e) => {
        this.setState({
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
        })
    }

    touchEnd = (e) => {
        this.setState({
            startY: undefined,
            startX: undefined,
            readyToRefresh: false,
        });

        const {
            swipeRight = () => {},
            swipeLeft = () => {},
            swipeUp = () => {},
            swipeDown = () => {},
            swipeRightIndicate = () => {},
            swipeLeftIndicate = () => {},
            swipeUpIndicate = () => {},
            swipeDownIndicate = () => {},
        } = appState;

        swipeRightIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeLeftIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeUpIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeDownIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
    }

    touchMove = (e) => {
        const { startY, startX, refreshing } = this.state;
        const {
            swipeRight = () => {},
            swipeLeft = () => {},
            swipeUp = () => {},
            swipeDown = () => {},
            swipeRightIndicate = () => {},
            swipeLeftIndicate = () => {},
            swipeUpIndicate = () => {},
            swipeDownIndicate = () => {},
        } = appState;
        const refreshRange = 130;
        const readyToRefreshRange = 30;
        const actionRange = 200;
        const indicateActionRange = 30;

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = startX - xUp;
        const yDiff = startY - yUp;
        const absXDiff = Math.abs(xDiff);
        const absYDiff = Math.abs(yDiff);

        if ( absXDiff > absYDiff ) { /*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */
                // console.log('left swipe', { startY, startX, xDiff, swipeLeft });
                if (absXDiff > actionRange) {
                    swipeLeft(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
                if (absXDiff > indicateActionRange) {
                    swipeLeftIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
            } else {
                /* right swipe */
                // console.log('right swipe', { e, startY, startX, xDiff, swipeRight });
                // eslint-disable-next-line no-lonely-if
                if (absXDiff > actionRange) {
                    swipeRight(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
                if (absXDiff > indicateActionRange) {
                    swipeRightIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
            }
        } else if ( yDiff > 0 ) {
            /* up swipe */
            // console.log('up swipe', { startY, startX, yDiff });
            if (absYDiff > actionRange) {
                swipeUp(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            if (absXDiff > indicateActionRange) {
                swipeUpIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
        } else {
            /* down swipe */
            // console.log('down swipe', { startY, startX, yDiff });
            if (absYDiff > actionRange) {
                swipeDown(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            if (absXDiff > indicateActionRange) {
                swipeDownIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            const skipRefresh = e.target.closest('.skipRefresh');
            if (!skipRefresh) {
                const { scrollTop } = this.appContainer;
                if (scrollTop === 0 && absYDiff > refreshRange && !refreshing) {
                    this.setState({
                        refreshing: true,
                        readyToRefresh: false,
                    });
                    this.pullToRefreshPage();
                } else if (scrollTop === 0 && absYDiff > readyToRefreshRange && !refreshing) {
                    this.setState({ readyToRefresh: true });
                }
            }
        }
    }

    scrollToTop = () => {
        if (this.appContainer) {
            mu.scrollTo(this.appContainer);
            this.refreshPage();
            // this.appContainer.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth',
            // });
        }
    }

    showReportBug = () => {
        const { showBugReport } = this.state;
        this.setState({
            showBugReport: !showBugReport,
        });
    }

    reportBug = async () => {
        const { bug = {} } = this.state;
        if (!bug.text) {
            this.setState({
                showBugReport: false,
            });
            return '';
        }
        const { currentUrl, path } = this.state;
        const { user } = userStore;
        const { language: localLanguage = 'en', isCordova } = appState;
        const { language = localLanguage } = user;
        const { cordovaActiveTrackingParams, cordovaAuthorizationStatus, powerSaveStatus,
			providerState, log, isRunning, totalDistance, geoLocationState, bgGeo, currentSpeed, totalTime, workouts } = workoutStore;

        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        await appState.sendEmailPlain({
            to: 'post@themusher.no',
            from: user.email,
            subject: `Bugreport: ${currentUrl}`,
            body: `Bugreport!

${bug.text.replace(/\n/g, '<br />\n')}

<hr />
DEBUG INFO:
<hr />

<xmp>
currentUrl: ${currentUrl}<br />
currentTeam: ${currentTeam}<br />
path: ${path}<br />
localLanguage: ${localLanguage}<br />
isCordova: ${isCordova}<br />
language: ${language}<br />
user.id: ${user.id}<br />
user.email: ${user.email}<br/>
user.firstname: ${user.firstname}<br/>
user.lastname: ${user.lastname}<br/>
user.cellphone: ${user.cellphone}<br/>
user.facebook: ${user.facebook}<br/>
user.instagram: ${user.instagram}<br/>
user.isVeterinary: ${user.isVeterinary}<br/>
user.twoFactorLogin: ${user.twoFactorLogin}<br/>
</xmp>
<hr />
<xmp>cordovaAuthorizationStatus: ${JSON.stringify(cordovaAuthorizationStatus, null, 4)}</xmp>
<xmp>cordovaActiveTrackingParams: ${JSON.stringify(cordovaActiveTrackingParams, null, 4)}</xmp>
<xmp>geoLocationState: ${JSON.stringify(geoLocationState, null, 4)}</xmp>
<xmp>powerSaveStatus: ${JSON.stringify(powerSaveStatus, null, 4)}</xmp>
<xmp>providerState: ${JSON.stringify(providerState, null, 4)}</xmp>
<xmp>isRunning: ${JSON.stringify(isRunning, null, 4)}</xmp>
<xmp>totalDistance: ${JSON.stringify(totalDistance, null, 4)}</xmp>
<xmp>currentSpeed: ${JSON.stringify(currentSpeed, null, 4)}</xmp>
<xmp>totalTime: ${JSON.stringify(totalTime, null, 4)}</xmp>

            `,
        });
        this.setState({
            showBugReport: false,
            bug: {
                text: '',
            },
        });
    }

    doneRefreshing() {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = setTimeout(() => this.setState({
            startY: undefined,
            refreshing: false,
            readyToRefresh: false,
        }), 1000);

    }

    setRefreshTime = () => {
        const refreshTime= new Date().getTime();
        this.setState({ refreshTime });
    }

    pullToRefreshPage = () => {
        const { currentUrl } = this.state;
        const urlRegexpInboxDetail = new RegExp(`^/inbox/[a-z0-9-]+`);

        if (urlRegexpInboxDetail.test(currentUrl)) {
            messageStore.loadMore();
        } else {
            this.refreshPage();
        }
        return this.doneRefreshing();
    }

    refreshPage = () => {
        const { currentUrl } = this.state;
        try {
            const urlRegexpStory = new RegExp(`^/$`);
            const urlRegexpWorkoutTracking = new RegExp(`^/workouts/tracking`);
            if (urlRegexpWorkoutTracking.test(currentUrl)) {
                // do nothing
            } else if (urlRegexpStory.test(currentUrl)) {
                this.setRefreshTime(currentUrl);
                return this.doneRefreshing();
            }
            // PubSub.publish(topics.REFRESH_PAGE, {
            //     message: 'Refresh page.',
            // });
        } catch (err) {
            console.log(err);
        }
    }

    resumeApp = () => {
        const { currentUrl } = this.state;
        try {
            // console.log({ currentUrl });
            const regex1 = /^\/$/;
            const regex8 = /^\/workouts\//;
            const regex15 = /^\/workouts\/tracking/;

            switch (true) {
                case regex1.test(currentUrl):
                    if (util.isFunction(storyStore.updateOnResume)) return storyStore.updateOnResume();
                    break;
                case regex15.test(currentUrl):
                    if (util.isFunction(workoutStore.updateTrackingOnResume)) return workoutStore.updateTrackingOnResume();
                    break;
                case regex8.test(currentUrl):
                    if (util.isFunction(workoutStore.updateOnResume)) return workoutStore.updateOnResume();
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    pauseApp = () => {
        const { currentUrl } = this.state;
        try {
            // console.log({ currentUrl });
            const regex1 = /^\/$/;
            const regex8 = /^\/workouts\//;
            const regex15 = /^\/workouts\/tracking/;

            switch (true) {
                case regex1.test(currentUrl):
                    if (util.isFunction(storyStore.updateOnPause)) return storyStore.updateOnPause();
                    break;
                case regex15.test(currentUrl):
                    if (util.isFunction(workoutStore.updateTrackingOnPause)) return workoutStore.updateTrackingOnPause();
                    break;
                case regex8.test(currentUrl):
                    if (util.isFunction(workoutStore.updateOnPause)) return workoutStore.updateOnPause();
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // cordova-plugin-statusbar
    setupStatusbar = () => {
        window.addEventListener('statusTap', () => {
            // scroll-up with document.body.scrollTop = 0; or do whatever you want
            this.scrollToTop();
        });
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // cordova-plugin-customurlscheme
    setupCustomUrlScheme = () => {
        window.handleOpenURL = (url) => {
            console.log(`customUrlScheme: received url: ${url}`);
            // 2021-12-13 11:26:14.530578+0100 QPaws[6022:1447877] customUrlScheme: received url: qpaws://test
            const targetUrl = url.replace(/qpaws:\/\//, '');
            route(`/${targetUrl}`);
        };
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // cordova-plugin-network-information
    setupConnection = () => {
        window.addEventListener('online', () => {
            console.log('is online');
            appState.setConnectionStatus('online');
        });
        window.addEventListener('offline', () => {
            console.log('is offline');
            appState.setConnectionStatus('offline');
        });
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // cordova-plugin-firebasex
    initIos = async () => {
        this.firebasePlugin.onApnsTokenReceived(async (token) => {
            console.log('APNS token received: ' + token);
            await userStore.setFirebase({ field: 'firebase.apnsToken', value: token });
        }, (error) => {
            console.log('Failed to receive APNS token', error);
        });

        this.firebasePlugin.registerInstallationIdChangeListener((installationId) => {
            console.log(`Installation ID changed - new ID: ${installationId}`);
        });
    }

    initAndroid = async () => {
        // Define custom  channel - all keys are except 'id' are optional.
        const customChannel = {
            // channel ID - must be unique per app package
            id: 'io.cordova.litt.no.themusher',

            // Channel name. Default: empty string
            name: 'QPaws',

            //The sound to play once a push comes. Default value: 'default'
            //Values allowed:
            //'default' - plays the default notification sound
            //'ringtone' - plays the currently set ringtone
            //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
            sound: 'blackberry',

            //Vibrate on new notification. Default value: true
            //Possible values:
            //Boolean - vibrate or not
            //Array - vibration pattern - e.g. [500, 200, 500] - milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
            vibration: [300, 200, 300],

            // Whether to blink the LED
            light: true,

            //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
            lightColor: '0xFF0000FF',

            //Importance - integer from 0 to 4. Default value: 3
            //0 - none - no sound, does not show in the shade
            //1 - min - no sound, only shows in the shade, below the fold
            //2 - low - no sound, shows in the shade, and potentially in the status bar
            //3 - default - shows everywhere, makes noise, but does not visually intrude
            //4 - high - shows everywhere, makes noise and peeks
            importance: 4,

            //Show badge over app icon when non handled pushes are present. Default value: true
            badge: true,

            //Show message on locked screen. Default value: 1
            //Possible values (default 1):
            //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
            //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
            //1 - public - Show the notification in its entirety on all lockscreens.
            visibility: 1
        };

        this.firebasePlugin.createChannel(customChannel,
            async () => {
                console.log(`Created custom channel: ${customChannel.id}`);
                await userStore.setFirebase({ field: 'firebase.customChannelId', value: customChannel.id });

                this.firebasePlugin.listChannels(
                    (channels) => {
                        if (typeof channels === 'undefined') return;
                        for(let i = 0; i < channels.length; i += 1) {
                            console.log(`Channel id=${channels[i].id}; name=${channels[i].name}`);
                        }
                    },
                    (error) => {
                        console.log('List channels error:', error);
                    }
                );
            },
            (error) => {
                console.log('Create channel error', error);
            }
        );
    };

    handleDataMessage = (message) => {
        try {
            console.log(`Data message received: ${JSON.stringify(message)}`);
        } catch(err) {
            console.log(err);
        }
    }

    handleNotificationMessage = async (message) => {
        try {
            await userStore.getNotifications();
            await messageStore.getUnReadMessages();

            let title;
            if (message.title) {
                title = message.title;
            } else if (message.notification && message.notification.title) {
                title = message.notification.title;
            } else if(message.aps && message.aps.alert && message.aps.alert.title) {
                title = message.aps.alert.title;
            }

            let body;
            if (message.body) {
                body = message.body;
            } else if(message.notification && message.notification.body) {
                body = message.notification.body;
            } else if(message.aps && message.aps.alert && message.aps.alert.body) {
                body = message.aps.alert.body;
            }

            let msg = 'Notification message received';
            if (message.tap) { // tap=foreground|background
                msg += ` (tapped in ${message.tap})`;
                if (message.url) {
                    route(message.url);
                }
            }
            if (title) {
                msg += `; title=${title}`;
            }
            if (body) {
                msg += `; body=${body}`;
            }
            msg += `: ${JSON.stringify(message)}`;
            console.log(msg);
        } catch(err) {
            console.log(err);
        }
    }

    checkAutoInit = () => {
        this.firebasePlugin.isAutoInitEnabled((enabled) => {
            console.log(`Auto init is "${enabled ? 'enabled' : 'disabled'}"`);
        }, (error) => {
            console.log('Failed to check auto init', error);
        });
    }

    enableAutoInit = () => {
        this.firebasePlugin.setAutoInitEnabled(true, () => {
            console.log('Enabled auto init');
            this.checkAutoInit();
        }, (error) => {
            console.log('Failed to enable auto init', error);
        });
    }

    disableAutoInit = () => {
        this.firebasePlugin.setAutoInitEnabled(false, () => {
            console.log('Disabled auto init');
            this.checkAutoInit();
        }, (error) => {
            console.log('Failed to disable auto init', error);
        });
    }

    getID = () => {
        this.firebasePlugin.getId(async (id) => {
            console.log(`Got FCM ID: ${id}`);
            await userStore.setFirebase({ field: 'firebase.fcmId', value: id });
        }, (error) => {
            console.log('Failed to get FCM ID', error);
        });
    };

    getToken = () => {
        this.firebasePlugin.getToken(async (token) => {
            console.log(`Got FCM token: ${token}`)
            await userStore.setFirebase({ field: 'firebase.fcmToken', value: token });
        }, (error) => {
            console.log('Failed to get FCM token', error);
        });
    }

    checkNotificationPermission = async (requested) => {
        this.firebasePlugin.hasPermission(async (hasPermission) => {
            if (hasPermission){
                console.log('Remote notifications permission granted');
                await userStore.setFirebase({ field: 'firebase.hasPermission', value: true });
                // Granted
                this.getToken();
            } else if (!requested){
                // Request permission
                console.log('Requesting remote notifications permission');
                await userStore.setFirebase({ field: 'firebase.hasPermission', value: false });
                this.firebasePlugin.grantPermission(this.checkNotificationPermission(true));
            } else {
                // Denied
                console.log('Notifications won\'t be shown as permission is denied');
            }
        });
    };

    setupPushNotifications = () => {
        this.firebasePlugin = window.FirebasePlugin;
        if (this.firebasePlugin) {
            //Register handlers
            this.firebasePlugin.onMessageReceived((message) => {
                try {
                    console.log('onMessageReceived');
                    // console.dir(message);
                    if (message.messageType === 'notification') {
                        this.handleNotificationMessage(message);
                    } else {
                        this.handleDataMessage(message);
                    }
                } catch (e) {
                    console.log(`Exception in onMessageReceived callback: ${e.message}`);
                }
            }, (error) => {
                console.log('Failed receiving FirebasePlugin message', error);
            });

            this.firebasePlugin.onTokenRefresh(async (token) => {
                try {
                    console.log(`Token refreshed: ${token}`)
                    await userStore.setFirebase({ field: 'firebase.fcmToken', value: token });
                } catch(err) {
                    console.log(err);
                }
            }, (error) => {
                console.log('Failed to refresh token', error);
            });

            this.firebasePlugin.registerAuthStateChangeListener((userSignedIn) => {
                console.log(`Auth state changed: User signed ${userSignedIn ? 'in' : 'out'}`);
            });

            this.firebasePlugin.hasPermission((hasPermission) => {
                try {
                    if (hasPermission){
                        console.log('Remote notifications permission granted');
                        // Granted
                        this.getToken();
                    } else {
                        console.log('Requesting remote notifications permission');
                        this.firebasePlugin.grantPermission(this.checkNotificationPermission(true));
                    }
                } catch(err) {
                    console.log(err);
                }
            });

            try {
                this.checkNotificationPermission(false); // Check permission then get token
                this.checkAutoInit();
            } catch(err) {
                console.log(err);
            }

            console.log('window.cordova.platformId', window.cordova.platformId);
            if (window.cordova.platformId === 'android') {
                this.initAndroid();
            } else if (window.cordova.platformId === 'ios') {
                this.initIos();
            }
        }
    }
    // / FirebasePlugin
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    ensureCordova = async () => {
        const { checkCordovaCounter, jwtToken, currentUrl } = this.state;
        const { cordovaIsReady } = appState;

        if (currentUrl.match(/\/race/) || currentUrl.match(/\/veterinary/) || currentUrl.match(/\/public/)) {
            return false;
        }

        // console.log('ensureCordova', JSON.stringify({ checkCordovaCounter, cordovaIsReady }));
        if (cordovaIsReady) {
            return true;
        }
        if (checkCordovaCounter > 10) {
            return false;
        }
        appState.checkCordova();
        const { isCordova } = appState;
        if (isCordova) {
            try {
                this.setupPushNotifications();
            } catch (e) {
                console.log(`Exception in setupPushNotifications: ${e.message}, ${e.stack}`);
            }
            try {
                this.setupStatusbar();
            } catch (e) {
                console.log(`Exception in setupStatusbar: ${e.message}, ${e.stack}`);
            }
            try {
                this.setupCustomUrlScheme();
            } catch (e) {
                console.log(`Exception in setupCustomUrlScheme: ${e.message}, ${e.stack}`);
            }
            try {
                this.setupConnection();
            } catch (e) {
                console.log(`Exception in setupConnection: ${e.message}, ${e.stack}`);
            }
        }
        if (isCordova && window.BackgroundGeolocation) {
            appState.cordovaSetToReady();
            try {
                this.setupCordova(); // BackgroundGeolocation
            } catch (e) {
                console.log(`Exception in setupCordova: ${e.message}, ${e.stack}`);
            }
        } else {
            clearTimeout(this.cordovaTimer);
            this.cordovaTimer = setTimeout(() => this.ensureCordova(), 2000);
            this.setState({ checkCordovaCounter: checkCordovaCounter + 1 })
        }
    }

    setupCordova = async () => { // BackgroundGeolocation
        await workoutStore.cordovaStartPlugin();
        // await workoutStore.cordovaGetState();
        // await workoutStore.cordovaProviderState();
        // await workoutStore.cordovaIsPowerSaveMode();
        // await workoutStore.cordovaGetLog();
        await userStore.updatePlatform();

        const deviceInfo = await workoutStore.cordovaDeviceInfo();
        await userStore.saveDeviceInfo(deviceInfo);

        // await userStore.cordovaGetCurrentPosition();
    }

    onScroll = (e) => {
        const { currentUrl } = this.state;
        // Scroll to top function
        const element = e.target;
        const { scrolledDown } = this.state;
        if (element) {
            if (element.scrollTop > SCROLLDOWN_LIMIT && !scrolledDown) {
            // if (element.scrollTop > SCROLLDOWN_LIMIT) {
                this.setState({
                    scrolledDown: element.scrollTop,
                });
            } else if (element.scrollTop === 0) {
                this.setState({
                    scrolledDown: 0,
                });
            }
        }

        // Load more content function
        const { isAtBottom } = this.state;
        const marginToBottom = element.clientHeight;
        if (element.scrollTop + element.clientHeight + marginToBottom >= element.scrollHeight) {
            if (!isAtBottom) {
                this.setState({ isAtBottom: true });

                // console.log({ currentUrl });
                const regex1 = /^\/$/;
                const regex2 = /^\/stories\/tag\/.+/;
                const regex3 = /^\/home-wall/;
                const regex4 = /^\/home-workout/;
                const regex5 = /^\/teams\/connect/;
                const regex6 = /^\/stories\/team\/.+/;
                const regex7 = /^\/teams\/workouts\/team.+/;
                const regex8 = /^\/workouts\//;
                const regex9 = /^\/users\/profile\/activities/;
                const regex13 = /^\/users\/profile\/favorites/;
                const regex10 = /^\/users\/profile/;
                const regex11 = /^\/search\/?$/;
                const regex12 = /^\/search\/[a-z]+/;
                const regex14 = /^\/dogs\/feed/;

                switch (true) {
                    case regex1.test(currentUrl):
                        return this.loadMoreStories();
                    case regex2.test(currentUrl):
                        return this.loadMoreStories();
                    case regex3.test(currentUrl):
                        return this.loadMoreStories();
                    case regex4.test(currentUrl):
                        return this.loadMorePublicWorkouts();
                    case regex5.test(currentUrl):
                        return this.loadMoreTeams();
                    case regex6.test(currentUrl):
                        return this.loadMoreStories();
                    case regex7.test(currentUrl):
                        return this.loadMorePublicWorkouts();
                    case regex8.test(currentUrl):
                        return this.loadMoreWorkouts();
                    case regex9.test(currentUrl):
                        return this.loadMoreWorkouts();
                    case regex13.test(currentUrl):
                        return this.loadMoreFavorites();
                    case regex10.test(currentUrl):
                        return this.loadMoreProfileImages();
                    case regex11.test(currentUrl):
                        return this.loadMoreProfileImages();
                    case regex12.test(currentUrl):
                        return this.loadMoreSearchResults();
                    case regex14.test(currentUrl):
                        return this.loadMoreHistoryResults();
                }
            }
        } else if (isAtBottom) {
            this.setState({ isAtBottom: false });
        }

        // console.log({ scrollTop: element.scrollTop, clientHeight: element.clientHeight, scrollHeight: element.scrollHeight });
    }

    loadMoreHistoryResults = async () => {
		await userStore.loadMore();
    }

    loadMoreSearchResults = async () => {
		await appState.loadMore();
    }

    loadMoreProfileImages = async () => {
		await teamStore.loadMore();
    }

    loadMoreStories = async () => {
		await storyStore.loadMore();
    }

    loadMoreTeams = async () => {
		await teamStore.loadMore();
    }

    loadMorePublicWorkouts = async () => {
		await teamStore.loadMore();
    }

    loadMoreWorkouts = async () => {
		await workoutStore.loadMore();
    }

    loadMoreFavorites = async () => {
		await userStore.loadMore();
    }

    checkShareApi = () => {
        appState.checkShareApi();
    }

    onPause = (e) => {
        // console.log('onPause', e);
        appState.setState('pause');
        this.pauseApp();
        // PubSub.publish(topics.APP_PAUSE, {
        //     message: 'App paused.',
        // });
    }

    onResume = (e) => {
        setTimeout(() => {
            try {
                // TODO: do your thing!
                // console.log('onResume', e);
                // PubSub.publish(topics.APP_RESUME, {
                //     message: 'App resumed.',
                // });
                appState.setState('active');
                this.resumeApp();
                this.refreshPage();
            } catch (err) {
                console.log(err);
            }
        }, 0);
    }

    // onMenubutton = (e) => {
    //     try {
    //         // Show dashboard
    //         console.log('onMenubutton', e);
    //         appState.toggleDashboard();
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // onBackbutton = (e) => {
    //     try {
    //         console.log('onBackbutton', e);
    //         history.goBack();
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // onSearchbutton = (e) => {
    //     try {
    //         console.log('onSearchbutton', e);
    //         appState.toggleDashboard();
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    componentWillMount() {
        // window.addEventListener("cordovacallbackerror", (event) => {
        //     // event.error contains the original error object
        //     console.log(event.error);
        // });
        const inputProps = util.collectPropsFromElement(window.document.body);
        const apiServer = inputProps.apiServer || `${window.location.protocol}//${window.location.host}`;
        const webocketUrl = inputProps.websocketUrl;
        appState.setApiServer(apiServer);
        appState.setWebsocketServer(webocketUrl);

        const qs = queryString.parse(window.location.search);
        console.log(qs)

        appState.setOpts({
            ...inputProps,
            showMenu: qs.showMenu === 'false' ? false : true,
        });

        this.ensureCordova();
        this.checkShareApi();
        this.loadAll();
    }

    componentDidMount() {
        // document.addEventListener('pause', this.onPause, false);
        document.addEventListener('resume', this.onResume, false);
        // document.addEventListener('menubutton', this.onMenubutton, false);
        // document.addEventListener('backbutton', this.onBackbutton, false);
        // document.addEventListener('searchbutton', this.onSearchbutton, false);
        appState.setAppContainer(this.appContainer);
        const { currentUrl } = this.state;
        appState.setPath(currentUrl);
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.props.path == nextProps.path) {
    //         console.log('same url');
    //     }
    //     return true;
    // }

	render() {
        const { refreshing, jwtToken, readyToRefresh, currentUrl, refreshTime, scrolledDown, showBugReport, bug = {} } = this.state;
        const scrolledDownPlaceholder = false;
        const { path } = this.props;
        const { user = {}, register = {} } = userStore;
        const { settings = {} } = user;
        const { language: localLanguage = 'en', isCordova, showDrawer, opts = {} } = appState;
        const { language = localLanguage, toggleFeature = {} } = user;
        const { qpaws } = toggleFeature;
        const languageDef = countryMap[language || 'default'];

        const { providerState = {}, powerSaveStatus, cordovaActiveTrackingParams = {} } = workoutStore;
        const { enabled: gpsPluginEnabled, status: gpsPluginStatus, gps: gpsPluginGps, network: gpsPluginNetwork } = providerState;
        const { workout: gpsPluginWorkout, team: gpsPluginTeam } = cordovaActiveTrackingParams;

        const currentTeamObject = userStore.getCurrentTeam();
		const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

		if (currentUrl.match(/^\/race/)) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100%; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .2s; overscroll-behavior: contain;`}
                            ref={c => this.appContainer = c}
                        >
                            <Router history={history}>
                                {/* <PublicRace stores={this.stores} {...this.props} path="/race" tag='race' /> */}
                                <AsyncRoute path="/race" stores={this.stores} {...this.props} tag='race' getComponent={() => import('../routes/public/race').then(module => module.default)} />
                                {/* <PublicRaceSupport stores={this.stores} {...this.props} path="/race/support" tag='racesupport' /> */}
                                <AsyncRoute path="/race/support" stores={this.stores} {...this.props} tag='racesupport' getComponent={() => import('../routes/public/race/support').then(module => module.default)} />
                                {/* <PublicRaceNews stores={this.stores} {...this.props} path="/race/news" tag='news' /> */}
                                <AsyncRoute path="/race/news" stores={this.stores} {...this.props} tag='news' getComponent={() => import('../routes/public/race/news').then(module => module.default)} />
                                {/* <PublicRaceNews stores={this.stores} {...this.props} path="/race/news/:newsid" tag='news' /> */}
                                <AsyncRoute path="/race/news/:newsid" stores={this.stores} {...this.props} tag='news' getComponent={() => import('../routes/public/race/news').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid" /> */}
                                <AsyncRoute path="/race/:raceid" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/news" section='news' /> */}
                                <AsyncRoute path="/race/:raceid/news" stores={this.stores} {...this.props} section='news' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/rules" section='rules' /> */}
                                <AsyncRoute path="/race/:raceid/rules" stores={this.stores} {...this.props} section='rules' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/veterinaryInfo" section='veterinaryInfo' /> */}
                                <AsyncRoute path="/race/:raceid/veterinaryInfo" stores={this.stores} {...this.props} section='veterinaryInfo' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/checkpoints" section='checkpoints' /> */}
                                <AsyncRoute path="/race/:raceid/checkpoints" stores={this.stores} {...this.props} section='checkpoints' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/trailDescription" section='trailDescription' /> */}
                                <AsyncRoute path="/race/:raceid/trailDescription" stores={this.stores} {...this.props} section='trailDescription' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/program" section='program' /> */}
                                <AsyncRoute path="/race/:raceid/program" stores={this.stores} {...this.props} section='program' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/prices" section='prices' /> */}
                                <AsyncRoute path="/race/:raceid/prices" stores={this.stores} {...this.props} section='prices' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/classes" section='classes' /> */}
                                <AsyncRoute path="/race/:raceid/classes" stores={this.stores} {...this.props} section='classes' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/signup" section='signup' /> */}
                                <AsyncRoute path="/race/:raceid/signup" stores={this.stores} {...this.props} section='signup' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/signup/newUser" section='signup' subsection='newUser' /> */}
                                <AsyncRoute path="/race/:raceid/newUser" stores={this.stores} {...this.props} section='newUser' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceDetail stores={this.stores} {...this.props} path="/race/:raceid/signup/forgotPassword" section='signup' subsection='forgotPassword' /> */}
                                <AsyncRoute path="/race/:raceid/forgotPassword" stores={this.stores} {...this.props} section='forgotPassword' getComponent={() => import('../routes/public/race/detail').then(module => module.default)} />
                                {/* <PublicRaceEdit stores={this.stores} {...this.props} path="/race/new/edit" createNew={true} /> */}
                                <AsyncRoute path="/race/new/edit" stores={this.stores} {...this.props} createNew={true}  getComponent={() => import('../routes/public/race/edit').then(module => module.default)} />
                                {/* <PublicRaceEdit stores={this.stores} {...this.props} path="/race/:raceid/edit" /> */}
                                <AsyncRoute path="/race/:raceid/edit" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/edit').then(module => module.default)} />
                                {/* <PublicRaceMushers stores={this.stores} {...this.props} path="/race/:raceid/mushers" /> */}
                                <AsyncRoute path="/race/:raceid/mushers" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/mushers').then(module => module.default)} />
                                {/* <PublicRaceResults stores={this.stores} {...this.props} path="/race/:raceid/results" /> */}
                                <AsyncRoute path="/race/:raceid/results" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/results').then(module => module.default)} />
                                {/* <PublicRaceResultsMain stores={this.stores} {...this.props} path="/race/:raceid/resultsMain" /> */}
                                <AsyncRoute path="/race/:raceid/resultsMain" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/results-main').then(module => module.default)} />
                                {/* <PublicRaceMedia stores={this.stores} {...this.props} path="/race/:raceid/media" /> */}
                                <AsyncRoute path="/race/:raceid/media" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/media').then(module => module.default)} />
                                {/* <PublicRaceTracking stores={this.stores} {...this.props} path="/race/:raceid/tracking" /> */}
                                <AsyncRoute path="/race/:raceid/tracking" stores={this.stores} {...this.props} getComponent={() => import('../routes/public/race/tracking').then(module => module.default)} />
                                {/* <Public stores={this.stores} {...this.props} path="/public" /> */}
                                <AsyncRoute path="/public" stores={this.stores} {...this.props} getComponent={() => import('../routes/public').then(module => module.default)} />
                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='race' />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
			);
        }

        if (currentUrl.match(/^\/grant-access/)) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100%; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .3s; overscroll-behavior: contain;`}
                            ref={c => this.appContainer = c}
                        >
                            <Progress stores={this.stores} />
                            <Message />
                            <Router history={history}>
                                <AsyncRoute stores={this.stores} {...this.props} path="/grant-access" getComponent={() => import('../routes/grant-access/').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/grant-access/newUser" subsection='newUser' getComponent={() => import('../routes/grant-access/').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/grant-access/forgotPassword" subsection='forgotPassword' getComponent={() => import('../routes/grant-access/').then(module => module.default)} />
                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='grant-access' />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
			);
        }

        if (currentUrl.match(/^\/public/)) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100%; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .3s; overscroll-behavior: contain;`}
                            ref={c => this.appContainer = c}
                            onScroll={this.onScroll}
                        >
                            <Router history={history}>
                                <AsyncRoute stores={this.stores} {...this.props} path="/public/map/:workout" getComponent={() => import('../routes/public/map').then(module => module.default)} />
                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='public' />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
            );
        }

        if (currentUrl.match(/^\/tv/)) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <style>
                            {`
                                body.bootstrap {
                                    background-color: transparent;
                                }
                            `}
                        </style>
                        <div id='app'
                            style={`
                                overflow-x: auto;
                                overflow-y: ${showDrawer ? 'hidden': 'auto'};
                                height: 100%;
                                ${readyToRefresh ? 'padding-top: 50px;' : ''}
                                ${refreshing ? 'padding-top: 70px;' : ''}
                                transition: .3s; overscroll-behavior: contain;
                                background-color: transparent;`}
                            ref={c => this.appContainer = c}
                            onScroll={this.onScroll}
                        >
                            <Router history={history}>
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top10/:raceid/:classid" limit={10} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top3/:raceid/:classid" limit={3} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top5/:raceid/:classid" limit={5} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top10/:raceid/:classid/:position" limit={10} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top3/:raceid/:classid/:position" limit={3} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top5/:raceid/:classid/:position" limit={5} getComponent={() => import('../routes/tv/top10').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top10progress/:raceid/:classid" getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top3progress/:raceid/:classid" limit={3} getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top5progress/:raceid/:classid" limit={5} getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top10progress/:raceid/:classid/:position" getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top3progress/:raceid/:classid/:position" limit={3} getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top5progress/:raceid/:classid/:position" limit={5} getComponent={() => import('../routes/tv/top10progress').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/top10ticker/:raceid/:classid" getComponent={() => import('../routes/tv/top10ticker').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/team/:raceid/:bib" getComponent={() => import('../routes/tv/oneTeam').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/team/:raceid" getComponent={() => import('../routes/tv/oneTeam').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/teamRemote/:raceid" getComponent={() => import('../routes/tv/oneTeamRemote').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/mixer/:raceid" getComponent={() => import('../routes/tv/mixer').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/tracking/:raceid/:classid" getComponent={() => import('../routes/tv/tracking').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/tracking/:raceid/:classid/:position" getComponent={() => import('../routes/tv/tracking').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/tracking/:raceid/:classid/:position/:bibs" getComponent={() => import('../routes/tv/tracking').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/freetext" getComponent={() => import('../routes/tv/freetext').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/freetext/:position/" getComponent={() => import('../routes/tv/freetext').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/tv/freetext/:position/:text" getComponent={() => import('../routes/tv/freetext').then(module => module.default)} />

                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='public' />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
            );
        }

        if (currentUrl.match(/^\/embed/)) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`
                                overflow-x: auto;
                                overflow-y: ${showDrawer ? 'hidden': 'auto'};
                                height: 100%;
                                ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''}
                                transition: .3s;
                                overscroll-behavior: contain;
                                background-color: #ffffff;
                            `}
                            ref={c => this.appContainer = c}
                            onScroll={this.onScroll}
                        >
                            <Router history={history}>
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/top10/:raceid/:classid" getComponent={() => import('../routes/embed/top10').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/tracking/:raceid/:classid/:showBib" getComponent={() => import('../routes/embed/tracking').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/tracking/:raceid/:classid" getComponent={() => import('../routes/embed/tracking').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/results/:raceid/:classid" getComponent={() => import('../routes/embed/results').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/chiplist/:raceid/:classid" getComponent={() => import('../routes/embed/chiplist').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/chiplist/:raceid/:classid/:bib" getComponent={() => import('../routes/embed/chiplist').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/embed/agreement/:raceid/:classid" getComponent={() => import('../routes/embed/agreement').then(module => module.default)} />
                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='public' />
                            </Router>
                            <Footer stores={this.stores} scrolledDown={scrolledDownPlaceholder} key={currentUrl} currentUrl={currentUrl} isCordova={isCordova} showMenu={false} />
                        </div>
                    </Catcher>
                </IntlProvider>
            );
        }

        if (!jwtToken) {
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`
                                overflow-x: auto;
                                overflow-y: ${showDrawer ? 'hidden': 'auto'};
                                height: 100%;
                                ${readyToRefresh ? 'padding-top: 50px;' : ''}
                                ${refreshing ? 'padding-top: 70px;' : ''}
                                transition: .3s;
                                overscroll-behavior: contain;
                                background-image: url('./assets/splash_empty.png');
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            `}
                            class='loginContainer'
                            ref={c => this.appContainer = c}
                        >
                            <Router history={history}>
                                <AsyncRoute stores={this.stores} {...this.props} path="/" getComponent={() => import('./welcome').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} default getComponent={() => import('./welcome').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} {...this.props} path="/login" getComponent={() => import('./login').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/:email" getComponent={() => import('./login').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} {...this.props} path="/link/login/:loginToken" getComponent={() => import('./login').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} path="/register/" getComponent={() => import('./register').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} path="/register/:email" getComponent={() => import('./register').then(module => module.default)} />

                                <AsyncRoute stores={this.stores} path="/forgotten/" getComponent={() => import('./forgotten').then(module => module.default)} />
                                <AsyncRoute stores={this.stores} path="/forgotten/:email" getComponent={() => import('./forgotten').then(module => module.default)} />
                                <LoadingSpinner default goto='/' gotoTitle='Home' routerName='login' />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
			);
        }

        if (!user.email) {
            if (register.error && register.errorStatus) {
                return (<>
                    <div id='app'
                        style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100%; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .3s; overscroll-behavior: contain;`}
                        ref={c => this.appContainer = c}
                    >
                        <div class='container-fluid h-100'>
                            <div class='row h-100'>
                                <div class='col-12 text-center my-auto'>
                                    <div class='display-1 text-muted mb-3'>
                                        <i class='fa-solid fa-bug' />
                                    </div>
                                    <h3>Error: {register.errorStatus}</h3>
                                    <p>We will be back soon...</p>
                                    <a class='mt-3 btn btn-link btn-sm' href='#' onClick={this.reload}>Try to reload app...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>);
            }
            return (
                <IntlProvider definition={languageDef}>
                    <Catcher>
                        <div id='app'
                            style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100%; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .3s; overscroll-behavior: contain;`}
                            ref={c => this.appContainer = c}
                        >
                            <Router history={history}>
                                <LoadingSpinner default />
                            </Router>
                        </div>
                    </Catcher>
                </IntlProvider>
			);
        }

        // if (settings.setupWizard) {
        //     return (
        //         <IntlProvider definition={languageDef}>
        //             <Catcher>
                            // <div id='app'
                            //     style={`
                            //         overflow-x: auto;
                            //         overflow-y: ${showDrawer ? 'hidden': 'auto'};
                            //         height: 100%;
                            //         ${readyToRefresh ? 'padding-top: 50px;' : ''}
                            //         ${refreshing ? 'padding-top: 70px;' : ''}
                            //         transition: .3s;
                            //         overscroll-behavior: contain;
                            //         background-image: url('./assets/splash_empty.png');
                            //         background-position: center;
                            //         background-repeat: no-repeat;
                            //         background-size: cover;
                            //     `}
                            //     class='loginContainer'
                            //     ref={c => this.appContainer = c}
                            // >
        //                     <Progress scrolledDown={scrolledDownPlaceholder} />
        //                     <Message  scrolledDown={scrolledDownPlaceholder} />
        //                     {readyToRefresh && <ReadyToRefresh scrolledDown={scrolledDownPlaceholder} />}
        //                     {refreshing && <RefreshSpinner scrolledDown={scrolledDownPlaceholder} />}

        //                     <Router onChange={this.handleRoute} history={history}>
        //                         <Home stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/" viewmenu='wall' />
        //                     </Router>
        //                 </div>
        //             </Catcher>
        //         </IntlProvider>
        //     );
        // }

		return (
            <IntlProvider definition={languageDef}>
                <Catcher>
                    {/* {qpaws && <>
                        <link rel="stylesheet" type="text/css" href='./css/qpaws.css' />
                    </>} */}
                    <div id='app'
                        class='bg-light'
                        style={`overflow-x: auto; overflow-y: ${showDrawer ? 'hidden': 'auto'}; height: 100vh; ${readyToRefresh ? 'padding-top: 50px;' : ''} ${refreshing ? 'padding-top: 70px;' : ''} transition: .3s; overscroll-behavior: contain;`}
                        onTouchstart={this.touchStart}
                        onTouchend={this.touchEnd}
                        onTouchmove={this.touchMove}
                        onScroll={this.onScroll}
                        ref={c => this.appContainer = c}
                    >
                        <Progress stores={this.stores} scrolledDown={scrolledDownPlaceholder} />
                        <Message  scrolledDown={scrolledDownPlaceholder} />
                        {readyToRefresh && <ReadyToRefresh scrolledDown={scrolledDownPlaceholder} />}
                        {refreshing && <RefreshSpinner scrolledDown={scrolledDownPlaceholder} />}

                        <Header stores={this.stores} scrolledDown={scrolledDownPlaceholder} refreshPage={this.refreshPage} />

                        <Router onChange={this.handleRoute} history={history}>
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/" viewmenu='wall' getComponent={() => import('../routes/home').then(module => module.default)} loading={() => <LoadingSpinner default />} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/home-wall" viewmenu='wall' getComponent={() => import('../routes/home').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/home-workout" viewmenu='workout' getComponent={() => import('../routes/home').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/home-totals" viewmenu='totals' getComponent={() => import('../routes/home').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/workouts/team/:teamid" viewmenu='workout' getComponent={() => import('../routes/home').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/feed" getComponent={() => import('../routes/home/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/feed/:month/:year" getComponent={() => import('../routes/home/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/feed/:month/:year/skipScroll" getComponent={() => import('../routes/home/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/todo" getComponent={() => import('../routes/home/todo').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/admin" getComponent={() => import('../routes/home/admin').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/settings" getComponent={() => import('../routes/settings').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/settings/general" getComponent={() => import('../routes/settings/general').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/settings/user" getComponent={() => import('../routes/settings/user').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/settings/team" getComponent={() => import('../routes/settings/team').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/settings/team/:teamid" getComponent={() => import('../routes/settings/teamDetail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/inbox" getComponent={() => import('../routes/inbox').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/inbox/new" getComponent={() => import('../routes/inbox/new').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/inbox/:fromuser" getComponent={() => import('../routes/inbox/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/stories" getComponent={() => import('../routes/stories').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/stories/teams/public/:teamid" getComponent={() => import('../routes/stories').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/stories/team/:teamid" getComponent={() => import('../routes/stories').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/stories/tag/:hashtag" getComponent={() => import('../routes/stories').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/stories/:story" getComponent={() => import('../routes/stories/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races" getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/list" getComponent={() => import('../routes/app-races/list').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid" getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/about" submenu='about' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/news" submenu='news' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/results" submenu='results' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/tracking" submenu='tracking' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/userImages" submenu='userImages' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/classes" submenu='classes' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/rules" submenu='rules' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/veterinary" submenu='veterinary' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/checkpoints" submenu='checkpoints' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/track" submenu='track' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/program" submenu='program' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/prices" submenu='prices' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/mushers" submenu='mushers' getComponent={() => import('../routes/app-races').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/app-races/:raceid/signup" submenu='signup' getComponent={() => import('../routes/app-races').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/" user="me" getComponent={() => import('../routes/users').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/settings" getComponent={() => import('../routes/users/settings').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/subscription" getComponent={() => import('../routes/users/subscriptions').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/integrations" getComponent={() => import('../routes/users/integrations').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/edit" getComponent={() => import('../routes/users/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/edit-password" getComponent={() => import('../routes/users/editPassword').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/connect" getComponent={() => import('../routes/users/connect').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/notifications" getComponent={() => import('../routes/users/notifications').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/profile" submenu='main' getComponent={() => import('../routes/users/profile').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/profile/favorites" submenu='favorites' getComponent={() => import('../routes/users/profile').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/profile/analysis" submenu='analysis' getComponent={() => import('../routes/users/profile').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/profile/activities" submenu='activities' getComponent={() => import('../routes/users/profile').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/profile/dogs" submenu='dogs' getComponent={() => import('../routes/users/profile').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/users/:user" getComponent={() => import('../routes/users').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/link/verify/:verifyEmailToken" getComponent={() => import('../routes/users').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/" getComponent={() => import('../routes/dogs').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/calendar/:month/:year" getComponent={() => import('../routes/dogs').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/calendar/:month/:year/skipScroll" getComponent={() => import('../routes/dogs').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/public/:dogid" getComponent={() => import('../routes/dogs/publicDetail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/list" getComponent={() => import('../routes/dogs/list').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/feed" getComponent={() => import('../routes/dogs/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/feed/:month/:year" getComponent={() => import('../routes/dogs/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/feed/:month/:year/skipScroll" getComponent={() => import('../routes/dogs/feed').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/todo" getComponent={() => import('../routes/dogs/todo').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/info" submenu='info' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/stats" submenu='stats' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/pictures" submenu='pictures' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/workouts" submenu='workouts' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/fitness" submenu='fitness' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/relations" submenu='relations' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/log" submenu='log' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/trophy" submenu='trophy' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/achievements" submenu='achievements' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/links" submenu='links' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/veterinary" submenu='veterinary' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/advanced" submenu='advanced' getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog" getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/info" submenu='info' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/status" submenu='status' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/pictures" submenu='pictures' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/team" submenu='team' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/relations" submenu='relations' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/achievements" submenu='achievements' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/links" submenu='links' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/edit/:dog/advanced" submenu='advanced' getComponent={() => import('../routes/dogs/edit').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/:month" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/:month/:year" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/:month/:year/skipScroll" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/history/:month" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/history/:month/:year" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/dogs/:dog/history/:month/:year/skipScroll" getComponent={() => import('../routes/dogs/detail').then(module => module.default)} />

                            {/* <WorkoutsTracking stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/tracking/:workoutid" key={`${gpsPluginEnabled}-${gpsPluginStatus}-${powerSaveStatus}-${gpsPluginWorkout}-${gpsPluginTeam}`} getComponent={() => import('../routes/home/admin').then(module => module.default)} />
                            <WorkoutsTracking stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/tracking/" key={`${gpsPluginEnabled}-${gpsPluginStatus}-${powerSaveStatus}-${gpsPluginWorkout}-${gpsPluginTeam}`} getComponent={() => import('../routes/home/admin').then(module => module.default)} /> */}
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/tracking/:workoutid" getComponent={() => import('../routes/workouts/tracking').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/tracking/" getComponent={() => import('../routes/workouts/tracking').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/trackingV2/" getComponent={() => import('../routes/workouts/trackingV2').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/" submenu='totals' team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/activities" submenu='activities' team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/analysis" submenu='analysis' team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:month/:year" team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:month/:year/skipScroll" team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/list" team={currentTeam} getComponent={() => import('../routes/workouts/list').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/month/:month" team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/month/:month/:year" team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/month/:month/:year/skipScroll" team={currentTeam} getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/week/:week" team={currentTeam} getComponent={() => import('../routes/workouts/week').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/week/:week/:year" team={currentTeam} getComponent={() => import('../routes/workouts/week').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/week/:week/:year/skipScroll" team={currentTeam} getComponent={() => import('../routes/workouts/week').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/plan" team={currentTeam} submenu='workoutplan' getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/plan/:year" team={currentTeam} submenu='workoutplan' getComponent={() => import('../routes/workouts').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/plan/:year/skipScroll" team={currentTeam} submenu='workoutplan' getComponent={() => import('../routes/workouts').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout" getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/reload" reload={true} getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/current" submenu='current' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/totals" submenu='totals' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/map" submenu='map' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/analysis" submenu='analysis' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/images" submenu='images' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/dogs" submenu='dogs' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/handlers" submenu='handlers' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/gpx" submenu='gpx' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/:workout/advanced" submenu='advanced' getComponent={() => import('../routes/workouts/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout" getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/info" submenu='info' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/team" submenu='team' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/user" submenu='user' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/images" submenu='images' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/gps" submenu='gps' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/equipment" submenu='equipment' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/edit/:workout/advanced" submenu='advanced' getComponent={() => import('../routes/workouts/edit').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/workouts/public/:workout" getComponent={() => import('../routes/workouts/publicDetail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/tracks/" getComponent={() => import('../routes/tracks').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/tracks/public" getComponent={() => import('../routes/tracks/public').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/tracks/:track" getComponent={() => import('../routes/tracks/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/tracks/:track/:isPublic" getComponent={() => import('../routes/tracks/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/tracks/edit/:track" getComponent={() => import('../routes/tracks/edit').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/public" getComponent={() => import('../routes/teams/public').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/public/:teamid" getComponent={() => import('../routes/teams/publicDetail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/" getComponent={() => import('../routes/teams').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/:team" getComponent={() => import('../routes/teams/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/:team/:month" getComponent={() => import('../routes/teams/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/:team/:month/:year" getComponent={() => import('../routes/teams/detail').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/:team/:month/:year/skipScroll" getComponent={() => import('../routes/teams/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/edit/:team" getComponent={() => import('../routes/teams/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/addmember/:team" getComponent={() => import('../routes/teams/addMember').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/teams/connect/" getComponent={() => import('../routes/teams/connect').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/howto" tag='howto' getComponent={() => import('../routes/news').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/news" tag='news' getComponent={() => import('../routes/news').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/news/tag/:tag" getComponent={() => import('../routes/news').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/news/edit/new" createNew={true} getComponent={() => import('../routes/news/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/news/edit/:id" getComponent={() => import('../routes/news/edit').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/news/:id" getComponent={() => import('../routes/news/detail').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/wizard/startup" getComponent={() => import('../routes/wizards/startup').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/wizard/person" getComponent={() => import('../routes/wizards/person').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/wizard/team" getComponent={() => import('../routes/wizards/team').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/wizard/follow" getComponent={() => import('../routes/wizards/follow').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/vaccines" getComponent={() => import('../routes/vaccines').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} {...this.props} path="/veterinary" getComponent={() => import('../routes/veterinary').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/search" getComponent={() => import('../routes/search').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/search/:search" getComponent={() => import('../routes/search').then(module => module.default)} />
                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} path="/logout/" getComponent={() => import('../routes/logout').then(module => module.default)} />

                            <AsyncRoute stores={this.stores} scrolledDown={scrolledDownPlaceholder} default viewmenu='wall' getComponent={() => import('../routes/home').then(module => module.default)} />
                        </Router>

                        <Footer stores={this.stores} scrolledDown={scrolledDownPlaceholder} key={currentUrl} currentUrl={currentUrl} isCordova={isCordova} showMenu={opts.showMenu} />

                        {/* {scrolledDown > SCROLLDOWN_LIMIT && <>
                            <span class='fixed-bottom text-primary' style='bottom: 60px; left: auto; right: 12px; width: 33px; font-size: 2.5em; z-index: 10;'>
                                <i class='fas fa-arrow-circle-up' onClick={this.scrollToTop} />
                            </span>
                            <span class='fixed-bottom' style='bottom: 60px; left: 5px; width: 33px; font-size: 1.5em; z-index: 10;'>
                                <i class='fas fa-bug text-muted' onClick={this.showReportBug} />
                            </span>
                        </>}

                        {showBugReport && <>
                            <div class='fixed-bottom bg-light' style='bottom: 60px;'>
                                <div class='col-12 pt-4'>
                                    <label class='' for='bugreport'><i class='fas fa-bug text-muted' /> <Text id='home.report-bug'>Rapportere feil</Text></label>
                                    <Localizer>
                                        <textarea
                                            id={'bugreport'}
                                            class='form-control-plaintext px-2 mb-3'
                                            type='text'
                                            placeholder={<Text id='home.report-bug-placeholder'>Hva er det du opplever som feil?</Text>}
                                            onInput={linkState(this, 'bug.text')}
                                            onKeyUp={mu.resizeTextarea}
                                            style='height: 2.3em;'
                                            value={bug.text}
                                        />
                                    </Localizer>
                                    <button class='btn btn-success float-left' onClick={this.reportBug}>
                                        <i class='fas fa-paper-plane' /> <Text id='home.send'>Send inn</Text>
                                    </button>
                                    <button class='btn btn-secondary float-right' onClick={this.showReportBug}>
                                        <i class='fas fa-times' /> <Text id='home.abort'>Avbryt</Text>
                                    </button>
                                </div>
                            </div>
                        </>} */}

                    </div>
                </Catcher>
            </IntlProvider>
		);
	}
}

export default App;
