import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import WidgetRouter from '../../../components/widget/router';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

const widgetsDefault = [
    {
        id: 1,
        widgetId: '571dba75-ff4b-4dad-8b26-6ff017b8f92f',
        name: 'Profile / Head v2',
        key: 'profile-head-v2',
        settings: {
            classWidth: 'col-12',
            disableDetails: true,
            disableEdit: true,
        },
    },
    {
        id: 1,
        widgetId: '0a07b6f2-2241-4934-af2e-7a3978d0781c',
        name: 'Profile / Dog',
        key: 'profile-dog',
        settings: {
            classWidth: 'col-12',
            header: 'Dogs',
            disableAdd: true,
        },
    },
    {
        id: 1,
        widgetId: 'f8672624-b469-42ef-bfe3-cec3b7a4a83e',
        name: 'Profile / Team',
        key: 'profile-team',
        settings: {
            classWidth: 'col-12',
            header: 'Teams',
            disableAdd: true,
        },
    },
    {
        id: 1,
        widgetId: '1d3aa9df-d33b-4186-aa8d-5fa9c8972d88',
        name: 'Profile / Other sections',
        key: 'profile-other-sections',
        settings: {
            classWidth: 'col-12',
            header: 'Other Sections',
        },
    },
];

@observer
class WidgetUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: `user-profile-${new Date().getTime()}`,
        };
    }

    loadAll = async () => {
        this.setState({ isLoading: true });
        const { userStore, teamStore } = this.props.stores;
        userStore.localUpdateField('publicUser', {});
        teamStore.localUpdateField('userPublicTeams', []);

        const response = await this.loadDogs();
        if (response && response.included)
        if (response.included?.publicUser) {
            userStore.localUpdateField('publicUser', response.included.publicUser);
        }
        if (response.included?.userPublicTeams) {
            teamStore.localUpdateField('userPublicTeams', response.included.userPublicTeams);
        }
		this.setState({ isLoading: false });
    }

    loadDogs = async () => {
        const { dogStore } = this.props.stores;
        const { user } = this.props;
        return await dogStore.loadPublicDogs(user.uuidv4);
    }

    loadTeams = async () => {
        const { teamStore } = this.props.stores;
        const { user } = this.props;
        await teamStore.loadPublicTeams(user.uuidv4);
    }

    loadWorkouts = async () => {
        const { workoutStore } = this.props.stores;
        const { user } = this.props;
        await workoutStore.loadPublicWorkouts(user.uuidv4);
    }

    newTeam = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerLevel } = this.props;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('newTeam', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        }, drawerLevel + 1);
	}

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { key } = this.state;
        const { appState, userStore, teamStore } = this.props.stores;
        const { publicUser } = userStore;
        const { userPublicTeams } = teamStore;
        const { drawerLevel, user = {}, team = {}, teams } = this.props;
        const { darkmode } = appState;

        const currentTeam = userStore.getActiveTeam(publicUser, userPublicTeams);
        // const currentUser =

        // let shortname;
        let name;
        if (publicUser && publicUser.id) {
            // shortname = mu.displayNameShort(publicUser);
            name = mu.displayName(publicUser);
        }

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <WidgetHead
                    stores={this.props.stores}
                    title={name}
                    // right={<Text id='team.add-team'>Add team</Text>}
                    // onRightClick={this.newTeam}
                />
                <WidgetBody stores={this.props.stores}>
                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br />
                    id: {id}<br /> */}
                    {/* {JSON.stringify(currentTeam, null, 2)} */}

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            <WidgetRouter
                                widgets={publicUser.widgetsPublicProfile || widgetsDefault}
                                stores={this.props.stores}
                                key={key}
                                user={publicUser}
                                team={currentTeam}
                                teams={teams}
                                drawerLevel={drawerLevel}
                            />
                        </div>
                    </div>
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetUserProfile;
