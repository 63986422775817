import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import linkState from 'linkstate';

import Images from '../form/images';

import style from '../style.css';
import mu from '../../lib/musher-util';

@observer
class WorkoutAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: {
                body: '',
                images: [],
            },
            showAddImage: false,
            sendButtonEnabled: true,
        };
        this.inputRef = {};
    }

    beforeImagesUploaded = () => {
        this.setState({ sendButtonEnabled: false });
    }

    afterImagesUploaded = () => {
        this.setState({ sendButtonEnabled: true });
    }

    commentWorkout = async () => {
        const { sendButtonEnabled } = this.state;
        if (!sendButtonEnabled) {
            return false;
        }
        const { callback = () => {}, inputRefObject = {} } = this.props;
        const { workout } = this.props;
		const { workoutStore } = this.props.stores;
        const { comment, showAddImage } = this.state;
        this.setState({ sendButtonEnabled: false });
		const response = await workoutStore.commentWorkout({ id: workout.id, comment });
		if (response && response.status === 200) {
            this.setState({
                comment: {
                    body: '',
                    images: [],
                },
            }, () => {
                mu.resizeTextarea(inputRefObject[workout.id]);
                if (showAddImage) {
                    this.addImage();
                }
            });
            callback({ workout, response });
		}
        this.setState({ sendButtonEnabled: true });
	}

    addImages = ({ value }) => {
        // updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        const { comment } = this.state;
        comment.images.push(...value);
        this.setState({ comment });
    }

    addImage = () => {
        const { showAddImage = false } = this.state;
        this.setState({
            showAddImage: !showAddImage,
        });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    render() {
        const { inputRef, col = 12, offset = 0, showAvatar = true, extraClass = '', workout } = this.props;
        const { comment, showAddImage, sendButtonEnabled } = this.state;
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const displayNameShort = user.firstname ? `${util.ucfirst(user.firstname, true)}${util.ucfirst(user.lastname, true)}` : `${util.ucfirst(user.email || '', true)}`;
        // const displayName = user.firstname ? `${user.firstname} ${user.lastname}` : `${user.email}`;
        const userImage = user.image || (user.images ? user.images[0] : undefined);

        return (
            <div class={`col-${col} offset-${offset} ${extraClass}`}>
                <div class='d-flex mb-0 position-relative w-100'>
                    {showAvatar && <div
                        class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                        style={`${userImage ? `background-image: url('${userImage.s3SmallLink}'); background-size: cover;` : ''} border-width: 3px !important;`}
                    >
                        {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>}
                    </div>}
                    <div class='col pl-3' style='line-height: 1.2em;'>

                        <div class='d-flex bd-highlight'>
                            <div class='p-0 w-100 bd-highlight'>
                                <label class='sr-only' for='inlineFormInput'><Text id='comments.comment'>Kommentar</Text></label>
                                <div class='border rounded rounded-lg position-relative'>
                                    <Localizer>
                                        <textarea
                                            class='form-control-plaintext px-2'
                                            type='text'
                                            placeholder={<Text id='comments.write-comment'>Skriv en kommentar...</Text>}
                                            onInput={linkState(this, 'comment.body')}
                                            onKeyUp={mu.resizeTextarea}
                                            onFocus={mu.resizeTextarea}
                                            style='height: 2.3em;'
                                            value={comment.body}
                                            ref={inputRef}
                                        />
                                    </Localizer>
                                    <div class='position-absolute' style='top: 8px; right: 10px; font-size: 1.2em;'>
                                        <i class='fas fa-image pointerCursor'
                                            data-id={workout.id}
                                            onClick={this.addImage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='p-0 pl-2 flex-shrink-1 bd-highlight'>
                                <button type='button' class={`btn btn-${sendButtonEnabled ? 'primary' : 'secondary'} mb-2`} disabled={!sendButtonEnabled}
                                    onClick={this.commentWorkout}
                                ><i class='fas fa-paper-plane' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {showAddImage && <>
                    <div class='col-12 text-center'>
                        <div class='container-fluid'>
                            <div class='row'>
                                {comment.images && comment.images.map(img => (
                                    <div class='col-3 p-1'>
                                        <img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                        <button class='btn btn-sm btn-link'
                                            onClick={this.removeImage}
                                            data-name={img.name}
                                        ><i class='fas fa-trash' />  <Text id='comments.remove-image'>Remove</Text></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Images object={comment} updateField={this.addImages} before={this.beforeImagesUploaded} after={this.afterImagesUploaded} autoOpen={true} stores={this.props.stores} />
                    </div>
                </>}
            </div>
        );
    }
}

export default WorkoutAdd;
