import { h, Component, createRef } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

@observer
class StoryRemove extends Component {
    constructor(props) {
        super(props);
        this.updateTimer = {};
        this.textareaRef = createRef();
        this.state = {
        };
    }

    removeStory = async (e) => {
        const { story, callback = () => true } = this.props;
        const { id } = story;
        const { storyStore } = this.props.stores;
        await storyStore.adminHideStory({ id });
        callback();
    }

    render() {
        const { callback = () => true } = this.props;
        const { userStore } = this.props.stores;
        const { isAdmin } = userStore;

        const { story = {} } = this.props;

        if (!isAdmin) {
            return '';
        }
        return (<>
            <div class='container-fluid px-0 mt-1'>
                <div class='row'>
                    <div class='col-12 text-center mt-2'>
                        <Text id='story.remove'>Remove story as admin?</Text>
                    </div>
                    <div class='col-12 text-center mt-0'>
                        <h5>{story.title}</h5>
                    </div>
                </div>

                <div class='row'>
                    <div class='col-6 mt-2'>
                        <button
                            type='button'
                            class='btn btn-block btn-lg btn-danger float-left'
                            onClick={this.removeStory}
                            data-id={story.id}
                        >
                            <i class='fas fa-trash-alt' /> Remove
                        </button>
                    </div>
                    <div class='col-6 mt-2'>
                        <button
                            type='button'
                            class='btn btn-block btn-lg btn-secondary float-right'
                            onClick={callback}
                            data-id={story.id}
                        >
                            <i class='fas fa-times-circle' /> Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
}

export default StoryRemove;
