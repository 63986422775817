import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class WorkoutFilter extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    updateFilterArray = (e) => {
        const { workoutStore } = this.props.stores;
        const { filter } = workoutStore;
        const { name, value, checked } = e.target;
        workoutStore.updateFilterArray(name, parseInt(value, 10), checked);
    }

    apply = () => {
        const { appState } = this.props.stores;
        const { applyFilter, drawerLevel } = this.props;
        applyFilter();
        appState.toggleDrawer(false, drawerLevel)
    }

    reset = () => {
        const { appState, workoutStore } = this.props.stores;
        const { drawerLevel } = this.props;
        workoutStore.resetFilter();
        appState.toggleDrawer(false, drawerLevel)
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore, workoutStore } = this.props.stores;
        const { types, equipments, language = 'en' } = userStore;
        const { darkmode, isCordova } = appState;
        const { filter } = workoutStore;

        return (
            <>
                <div
                    class='position-absolute text-center w-100 appHeader'
                    style={`
                        top: 24px;
                        left: 0px;
                    `}
                >
                    <Text id='workouts.filters'>Filters</Text>
                </div>
                <div class='w-100' style='margin-top: 45px;'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 pb-5 mb-5'>

{/* <xmp>{JSON.stringify(filter, null, 2)}</xmp> */}

                        <div class='row'>
                            <div class='col-12 mt-5'>
                                <span class='header2'>
                                    <Text id='workouts.activity-type'>Activity type</Text>
                                </span>
                            </div>
                            {types && types.map((type, index) => {
                                return (<Fragment key={`workout-type-${type}-${index}`}>
                                    <div class='col-12 mt-2 d-flex justify-content-between align-items-center'>
                                        <div class='flex-grow-1'>
                                            <label for={`activityType${type.name}`}>
                                                <i class={type.icon} style='width: 24px;' /> {type.lang[language]}
                                            </label>
                                        </div>
                                        <div class='form-check form-check-inline'>
                                            <input
                                                class='form-check-input'
                                                type='checkbox'
                                                id={`activityType${type.name}`}
                                                name={`type`}
                                                value={type.id}
                                                onInput={this.updateFilterArray}
                                                checked={filter.type?.indexOf(type.id) > -1}
                                            />
                                            {/* {filter.type?.indexOf(type.id) > -1 && <i class='fas fa-check' style='color: #00b300;' />} */}
                                        </div>
                                    </div>
                                </Fragment>);
                            })}
                        </div>

                        <div class='row'>
                            <div class='col-12 mt-5'>
                                <span class='header2'>
                                    <Text id='workouts.equipment'>Equipment</Text>
                                </span>
                            </div>
                            {equipments && equipments.map((equipment, index) => {
                                return (<Fragment key={`workout-equipment-${equipment}-${index}`}>
                                    <div class='col-12 mt-2 d-flex justify-content-between align-items-center'>
                                        <div class='flex-grow-1'>
                                            <label for={`activityEquipment${equipment.name}`}>
                                                <i class={equipment.icon} style='width: 24px;' /> {equipment.lang[language]}
                                            </label>
                                        </div>
                                        <div class='form-check form-check-inline'>
                                            <input
                                                class='form-check-input'
                                                type='checkbox'
                                                id={`activityEquipment${equipment.name}`}
                                                name={`equipment`}
                                                value={equipment.id}
                                                onInput={this.updateFilterArray}
                                                checked={filter.equipment?.indexOf(equipment.id) > -1}
                                            />
                                            {/* {filter.equipment?.indexOf(equipment.id) > -1 && <i class='fas fa-check' style='color: #00b300;' />} */}
                                        </div>
                                    </div>
                                </Fragment>);
                            })}
                        </div>

                        {/* <div class='row'>
                            <div class='col-12 mt-5'>
                                <span class='header2'>
                                    <Text id='workouts.date'>Date</Text>
                                </span>
                            </div>
                        </div> */}


                        <div class='fixed-bottom py-3 px-3 d-flex justify-content-between'>
                            <div class='w-50 px-2'>
                                <button type='button' class='btn btn-warning btn-block' onClick={this.reset}>
                                    <Text id='workouts.reset-filter'>Reset</Text>
                                </button>
                            </div>
                            <div class='w-50 px-2'>
                                <button type='button' class='btn btn-primary btn-block' onClick={this.apply}>
                                    <Text id='workouts.apply'>Apply</Text>
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    }
}

export default WorkoutFilter;
