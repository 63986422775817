import { h, Component, Fragment } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';
import util from 'preact-util';
import { route } from 'preact-router';

import Drawer from '../../components/drawer/';

import Favorites from '../../components/team/favorites';
import ImageFullscreen from '../../components/imageFullscreen';

import WorkoutDetail from '../../routes/workouts/detail';
import WorkoutEdit from '../../routes/workouts/edit';
import WorkoutPublicMenu from '../../components/workout/menu';
import WorkoutPublicDetail from '../../routes/workouts/publicDetail';
import WorkoutPublicMap from '../../routes/workouts/publicMap';
import WorkoutMap from '../../routes/workouts/map';
import WorkoutFilter from '../../components/workout/filter';
import WorkoutElevationDetail from '../../components/workout/elevationDetail';
import WorkoutLoadIndexDetail from '../../components/workout/loadIndexDetail';
import WorkoutWeatherDetail from '../../components/workout/weatherDetail';

import WorkoutAvgSpeedDetail from '../../components/workout/avgSpeedDetail';
import WorkoutCaloriesDetail from '../../components/workout/caloriesDetail';
import WorkoutDistanceDetail from '../../components/workout/distanceDetail';
import WorkoutDurationDetail from '../../components/workout/durationDetail';
import WorkoutEquipmentDetail from '../../components/workout/equipmentDetail';
import WorkoutRestDetail from '../../components/workout/restDetail';
import WorkoutWorkLoadDetail from '../../components/workout/workLoadDetail';

import Workout from '../../routes/workouts/';

import WorkoutStats from '../../components/workout/stats';
import WorkoutGoals from '../../components/workout/goals';
import WorkoutMonthlyActivities from '../../components/workout/monthlyActivities';
import WorkoutTrainingLog from '../../components/workout/trainingLog';
import WorkoutIntensity from '../../components/workout/intensity';
import WorkoutFitnessProgress from '../../components/workout/fitnessProgress';

import DogPublicDetail from '../../routes/dogs/publicDetail';
import DogEdit from '../../routes/dogs/edit';
import DogDetail from '../../routes/dogs/detail';
import DogAddLog from '../../components/dog/addlog';
import DogAddDogs from '../../components/dog/addDogs';

import DeleteWorkout from '../../components/workout/delete';
import ReportWorkout from '../../components/workout/report';
import RemoveWorkout from '../../components/workout/remove';

import StoryPublicFeed from '../../components/story/publicFeed';
import StoryPublicDetail from '../../routes/stories/publicDetail';
import StoryPublicMenu from '../../components/story/menu';
import StoryAdd from '../../components/story/add';
import StoryAddLocations from '../../components/story/addLocations';
import StoryAddTags from '../../components/story/addTags';
import StoryAdvanced from '../../components/story/advanced';
import EditStory from '../../components/story/edit';
import DeleteStory from '../../components/story/delete';
import ReportStory from '../../components/story/report';
import RemoveStory from '../../components/story/remove';

import Comments from '../../components/feedback/comments';

import TeamsPublicDetail from '../../routes/teams/publicDetail';
import EditTeam from '../../routes/teams/edit';
import ChooseTeam from '../../routes/users/chooseTeam';
import TeamsFollowers from '../../routes/teams/followers';

import InboxDetail from '../../routes/inbox/detail';
import EditUser from '../../routes/users/edit';
import UserMenu from '../../routes/users/userMenu';
import AddContent from '../../routes/users/addContent';

import WidgetProfileDogList from '../../components/widget/profile/dog-list';
import WidgetProfileDogDetail from '../../components/widget/profile/dog-detail';
import WidgetProfileTeamList from '../../components/widget/profile/team-list';
import WidgetProfileTeamDetail from '../../components/widget/profile/team-detail';
import WidgetProfileLastTrophyList from '../../components/widget/profile/last-trophy-list';
import WidgetProfileLastDogLogList from '../../components/widget/profile/last-dog-log-list';

import WidgetUserProfile from '../../components/widget/user/profile';

import WidgetWorkoutKeyFiguresList from '../../components/widget/workout/key-figures-list';
import WidgetWorkoutFitnessDetail from '../../components/widget/workout/fitness-detail';
import WidgetWorkoutIntensityDetail from '../../components/widget/workout/intensity-detail';
import WidgetWorkoutTotalKmDetail from '../../components/widget/workout/total-km-detail';
import WidgetWorkoutTotalElevationDetail from '../../components/widget/workout/total-elevation-detail';
import WidgetWorkoutTotalDurationDetail from '../../components/widget/workout/total-duration-detail';
import WidgetWorkoutTotalRestDetail from '../../components/widget/workout/total-rest-detail';
import WidgetWorkoutTotalCaloriesDetail from '../../components/widget/workout/total-calories-detail';
import WidgetWorkoutGoalsDetail from '../../components/widget/workout/goals-detail';
import WidgetWorkoutProgessDetail from '../../components/widget/workout/progress-detail';
import WidgetWorkoutAvgSpeedDetail from '../../components/widget/workout/avg-speed-detail';

import mu from '../../lib/musher-util';

const FONTSIZE_MENU = '12px;';
const FONTSIZE_ICON = '23px;';
const FONTSIZE_ICON_LARGE = '23px;';

// Dashboard
const FONTSIZE_LARGE_ICON = '40px;';


const initialState = {
    menu: {
		profile: false,
		main: false,
		showDashoard: false,
	},
	searchText: '',
};

@observer
class Canicross extends Component {
	render() {
		const { pathFixed } = this.props;
		const { appState, userStore, workoutStore } = this.props.stores;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const {
			path, isCordova, apiServer, apiServerChanged,
		} = appState;

        const image = mu.getImage({ user, team: activeTeam, priority: 'user' });

		return (<>
			<div class='w-100'>
				<div class='d-flex justify-content-between'>
					<div
						class={`text-center py-2 px-2 ${path.match(/(^\/$|\/home-wall)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href={`${path === '/' ? '/home-wall' : '/'}`} class={`text-${path.match(/(^\/$|\/home-wall)/) ? 'primary' : 'secondary'}`}>
							<i class='fas fa-home' style={`font-size: ${FONTSIZE_ICON_LARGE};`} />
						</a>
					</div>

					<div
						class={`text-center py-2 px-2 ${path.match(/(^\/search)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href={`/search`} class={`text-${path.match(/(^\/search)/) ? 'primary' : 'secondary'}`}>
							<i class='fa-solid fa-magnifying-glass' style={`font-size: ${FONTSIZE_ICON_LARGE};`} />
						</a>
					</div>

					{isCordova ? <>
						<div
							class={`col text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
							style='text-overflow: ellipsis;'
						>
							<a href='/workouts/tracking/' class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'danger'}`}>
								<i class='far fa-dot-circle' style={`font-size: ${FONTSIZE_ICON};`} /><br />
								<Text id='footer.workouts-start'>Start</Text>
							</a>
						</div>
						{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</> : <>
						{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</>}

					<div
						class={`text-center py-2 px-2 ${path.match(/(^\/teams\/connect)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href={`/teams/connect`} class={`text-${path.match(/(^\/teams\/connect)/) ? 'primary' : 'secondary'}`}>
							<i class='fa-solid fa-circle-nodes' style={`font-size: ${FONTSIZE_ICON_LARGE};`} />
						</a>
					</div>

					<div
						class={`text-center py-2 px-2 ${path.match(/(^\/users\/profile)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href='/users/profile' class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'danger'}`}>
							<div
								class={`position-relative text-center border rounded-circle imageRounded imageRoundedThumb`}
								style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
								{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
							</div>
						</a>

						{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</div>
				</div>
			</div>
		</>);
	}
}

@observer
class Mushing extends Component {
	render() {
		const { pathFixed } = this.props;
		const { appState, userStore, workoutStore } = this.props.stores;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const {
			path, isCordova, apiServer, apiServerChanged,
		} = appState;

        const image = mu.getImage({ user, team: activeTeam, priority: 'user' });

		return (<>
			<div class='container-fluid'>
				<div class='row pb-1'>
					<div
						class={`col text-center p-2 ${path.match(/(^\/$|\/home-wall)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis;'
					>
						<a href={`${path === '/' ? '/home-wall' : '/'}`} class={`text-${path.match(/(^\/$|\/home-wall)/) ? 'primary' : 'secondary'}`}>
							<i class='fas fa-home' style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.home'>Home</Text>
						</a>
					</div>
					<div
						class={`col text-center p-2 ${path.match(/\/workouts/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis;'
					>
						<a href='/workouts/' class={`text-${path.match(/\/workouts/) ? 'primary' : 'secondary'}`}>
							<i class='fas fa-running' style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.workouts'>Treningen</Text>
						</a>
					</div>

					{isCordova ? <>
						<div
							class={`col text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
							style='text-overflow: ellipsis;'
						>
							<a href='/workouts/tracking/' class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'secondary'}`}>
								<i class='far fa-dot-circle' style={`font-size: ${FONTSIZE_ICON};`} /><br />
								<Text id='footer.workouts-start'>Start</Text>
							</a>
						</div>
						{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</> : <>
						{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</>}

					<div
						class={`col text-center p-2 ${path.match(/\/dogs/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis;'
					>
						<a href='/dogs/' class={`text-${path.match(/\/dogs/) ? 'primary' : 'muted'}`}>
							<i class='fas fa-dog' style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.dogs'>Hundene</Text>
						</a>
					</div>

					<div
						class={`text-center py-2 px-2 ${path.match(/(^\/users\/profile)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden d-flex justify-content-center`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href='/users/profile' class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'secondary'}`}>
							<div
								class={`position-relative text-center border rounded-circle imageRounded imageRoundedThumb2 ml-1`}
								style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
							>
								{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
							</div>
							<Text id='footer.profile'>Profile</Text>
						</a>

						{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</div>

					{/* <div class={`col text-center p-2 text-nowrap overflow-hidden d-flex align-items-center justify-content-center`} style='text-overflow: ellipsis;'>
						<span class={`text-${showDashoard ? 'primary' : 'secondary'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-ellipsis-h' style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.menu'>Menu</Text>
						</span>

						{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
					</div> */}
				</div>
			</div>
		</>);
	}
}

@observer
class MenuV2 extends Component {
	userMenu = () => {
		const { appState, userStore, messageStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
		const { unread: unreadMessages = 0 } = messageStore;

        appState.openDrawer('userMenu', { height: drawerHeightMedium, counters: {
			inbox: unreadMessages,
			notifications: unread,
		}});
	}

	render() {
		const { pathFixed } = this.props;
		const { appState, userStore, workoutStore } = this.props.stores;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const {
			path, isCordova, apiServer, webocketUrl, apiServerChanged,
		} = appState;

        const image = mu.getImage({ user, team: activeTeam, priority: 'user' });

		return (<>
			<div class='d-flex justify-content-between'>
				<div
					class={`text-center p-2 ${path.match(/(^\/$|\/home-wall)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href={`${path === '/' ? '/home-wall' : '/'}`} class={`text-${path.match(/(^\/$|\/home-wall)/) ? 'primary' : 'secondary'}`}>
						<i class={`fa-${path.match(/(^\/$|\/home-wall)/) ? 'solid' : 'regular'} fa-house`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.home'>Home</Text>
					</a>
				</div>

				<div
					class={`col text-center p-2 ${path.match(/\/workouts/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
					style='text-overflow: ellipsis;'
				>
					<a href='/workouts/' class={`text-${path.match(/\/workouts/) ? 'primary' : 'secondary'}`}>
						<i class='fas fa-running' style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.workouts'>Treningen</Text>
					</a>
				</div>

				{isCordova ? <>
					{user.settings?.trackingBeta ? <>
						<div
							class={`col text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
							style='text-overflow: ellipsis;'
						>
							<a
								href={`/workouts/trackingV2/`}
								class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'danger'}`}
							>
								<i class='far fa-dot-circle' style={`font-size: ${FONTSIZE_ICON};`} /><br />
								<Text id='footer.workouts-start'>Start</Text>
							</a>
						</div>
					</> : <>
						<div
							class={`col text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
							style='text-overflow: ellipsis;'
						>
							<a
								href={`/workouts/tracking/`}
								class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'danger'}`}
							>
								<i class='far fa-dot-circle' style={`font-size: ${FONTSIZE_ICON};`} /><br />
								<Text id='footer.workouts-start'>Start</Text>
							</a>
						</div>
					</>}
					{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
				</> : <>
					{user && user.id && <img src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
				</>}

				<div
					class={`col text-center p-2 ${path.match(/\/dogs/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
					style='text-overflow: ellipsis;'
				>
					<a href='/dogs/' class={`text-${path.match(/\/dogs/) ? 'primary' : 'muted'}`}>
						<i class='fas fa-dog' style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.dogs'>Hundene</Text>
					</a>
				</div>

				<div
					class={`text-center py-2 px-2 ${path.match(/(^\/users\/profile)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden d-flex justify-content-center`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href='/users/profile' class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'secondary'}`}>
						<div
							class={`position-relative text-center border rounded-circle imageRounded imageRoundedThumb2 ml-1`}
							style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
						>
							{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
						</div>
						<Text id='footer.profile'>Profile</Text>
					</a>

					{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
				</div>

			</div>
			{isCordova ? <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</> : <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</>}
		</>);
	}
}

@observer
class WorkingDog extends Component {
	render() {
		return (<>
		</>);
	}
}

@observer
class ActiveDog extends Component {
	render() {
		return (<>
		</>);
	}
}

@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDashboard = () => {
		const { appState } = this.props.stores;
		appState.toggleDashboard();
	}

	search = () => {
		const { searchText } = this.state;
		this.toggleDashboard();
		route(`/search/${searchText}`);
	}

    render() {
		const { currentUrl, showMenu = true } = this.props;
		const { searchText } = this.state;
		const { appState, userStore, workoutStore } = this.props.stores;
		const { currentTeam } = workoutStore;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
		const {
			path, isCordova, apiServer, webocketUrl, apiServerChanged, showDashoard,
			showDrawer, drawerComponent, drawerProps = {},
			showDrawer2, drawerComponent2, drawerProps2 = {},
			showDrawer3, drawerComponent3, drawerProps3 = {},
			drawerHeightMedium,
			darkmode,
		} = appState;

		let pathFixed = path.replace(/^\//, '');
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\w{8,}-\w{4,}-\w{3,}-\w{3,}-\w{12,}/, 'uuid');
		pathFixed = pathFixed.replace(/\d+/g, 'id');
		// 8086094d-fbc3-44af-acc0-7c1ae9b5dce7
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\//g, '.');

		if (pathFixed === '') {
			pathFixed = 'frontpage';
		}

		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
        const image = mu.getImage({ user, team: activeTeam });

		if (/^\/wizard/.test(currentUrl)) {
			return <>
				{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
			</>;
		}

		return (<>
			{[1, 2, 3, 4, 5].map(i => {
				return (<Fragment key={`drawer-container-${i}`}>
					{appState[`showDrawer${i}`] && <>
						<Drawer
							drawer={i}
							stores={this.props.stores}
							height={appState[`drawerProps${i}`].height}
							drawerComponent={appState[`drawerComponent${i}`]}
							onCloseClick={appState[`drawerProps${i}`].onCloseClick}
							borderRadius={appState[`drawerProps${i}`].borderRadius}
							showCloseButton={appState[`drawerProps${i}`].showCloseButton}
							showBackButton={appState[`drawerProps${i}`].showBackButton}
							backButtonText={appState[`drawerProps${i}`].backButtonText}
						>
							{/* <RaceLiveAddResult stores={this.props.stores} /> */}
							{appState[`drawerComponent${i}`] === 'inboxDetail' &&
								<InboxDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// fromuser={appState[`drawerProps${i}`].fromuser}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									headerMargin={`calc(100vh - ${drawerHeightMedium} + 3px)`}
									showBack={false}
								/>
							}
							{appState[`drawerComponent${i}`] === 'profileFavorites' &&
								<Favorites
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'imageFullscreen' &&
								<ImageFullscreen
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// src={appState[`drawerProps${i}`].src}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicTeam' &&
								<TeamsPublicDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									teamid={appState[`drawerProps${i}`].teamId || appState[`drawerProps${i}`].id}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicDog' &&
								<DogPublicDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									dogid={appState[`drawerProps${i}`].dogId || appState[`drawerProps${i}`].id}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// dogs={appState[`drawerProps${i}`].dogs}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicStoryMenu' &&
								<StoryPublicMenu
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// story={appState[`drawerProps${i}`].story}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicStory' &&
								<StoryPublicDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									story={appState[`drawerProps${i}`].id}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicStoryList' && <>
								<div class={`h-100`} style={`margin-bottom: 0; margin-top: 0;`}>
									<div
										class={`fixed-top w-100 pb-2 pt-2 d-flex justify-content-between appHeader border-bottom px-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
										style='height: 40px;'
									>
										<div class='w-25'>&nbsp;</div>
										<div class='w-50 text-center font-weight-bold'><Text id='profile.your-posts'>Your posts</Text></div>
										<div class='w-25 text-right pr-4 text-primary'>&nbsp;</div>
									</div>
									<div class='w-100 h-100 bg-light' style='margin-top: 40px;'>
										<div class='container-fluid'>
											<StoryPublicFeed
												stores={this.props.stores}
												{...appState[`drawerProps${i}`]}
												showBackButton={false}
												teamid={appState[`drawerProps${i}`].teamId || appState[`drawerProps${i}`].id}
												skipWorkouts={true}
												drawerLevel={i}
												showTopRanked={false}
												showTeamConfirm={false}
												showTeamSuggestions={false}
												marginTop={'0px;'}
												marginBottom={'0px;'}
											/>
										</div>
									</div>
								</div>
							</>}
							{appState[`drawerComponent${i}`] === 'publicWorkoutMenu' &&
								<WorkoutPublicMenu
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// workout={appState[`drawerProps${i}`].workout}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicWorkout' &&
								<WorkoutPublicDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									workout={appState[`drawerProps${i}`].id}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'publicWorkoutMap' &&
								<WorkoutPublicMap
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									workout={appState[`drawerProps${i}`].id}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									dogs={appState[`drawerProps${i}`].dogs}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutDetail' &&
								<WorkoutDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									workout={appState[`drawerProps${i}`].id}
									// onLinkClick={appState[`drawerProps${i}`].onLinkClick}
									// onDogClick={appState[`drawerProps${i}`].onDogClick}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutEdit' &&
								<WorkoutEdit
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									workout={appState[`drawerProps${i}`].id}
									// onLinkClick={appState[`drawerProps${i}`].onLinkClick}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutMap' &&
								<WorkoutMap
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									workout={appState[`drawerProps${i}`].id}
									drawerLevel={i}
									// dogs={appState[`drawerProps${i}`].dogs}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutFilter' &&
								<WorkoutFilter
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutElevationDetail' &&
								<WorkoutElevationDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutLoadIndexDetail' &&
								<WorkoutLoadIndexDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutWeatherDetail' &&
								<WorkoutWeatherDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}

							{appState[`drawerComponent${i}`] === 'workoutAvgSpeedDetail' &&
								<WorkoutAvgSpeedDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutCaloriesDetail' &&
								<WorkoutCaloriesDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutDistanceDetail' &&
								<WorkoutDistanceDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutDurationDetail' &&
								<WorkoutDurationDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutEquipmentDetail' &&
								<WorkoutEquipmentDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutRestDetail' &&
								<WorkoutRestDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutWorkLoadDetail' &&
								<WorkoutWorkLoadDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									// applyFilter={appState[`drawerProps${i}`].applyFilter}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}

							{appState[`drawerComponent${i}`] === 'workoutStats' &&
								<WorkoutStats
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutGoals' &&
								<WorkoutGoals
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutMonthlyActivities' &&
								<WorkoutMonthlyActivities
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutTrainingLog' &&
								<WorkoutTrainingLog
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutIntensity' &&
								<WorkoutIntensity
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workoutFitnessProgress' &&
								<WorkoutFitnessProgress
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'workout' &&
								<Workout
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
									// submenu={appState[`drawerProps${i}`].submenu}
									// showSegmentController={appState[`drawerProps${i}`].showSegmentController}
								/>
							}
							{appState[`drawerComponent${i}`] === 'chooseTeam' &&
								<ChooseTeam
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'addContent' &&
								<AddContent
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'userMenu' &&
								<UserMenu
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									drawerLevel={i}
									// counters={appState[`drawerProps${i}`].counters}
								/>
							}
							{appState[`drawerComponent${i}`] === 'editUser' &&
								<div class='container-fluid pt-3'>
									<EditUser
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										// field={appState[`drawerProps${i}`].field}
										// type={appState[`drawerProps${i}`].type}
										// title={appState[`drawerProps${i}`].title}
										// help={appState[`drawerProps${i}`].help}
										user={appState[`drawerProps${i}`].id}
										skipImageList={true}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'editStory' &&
								<div class='container-fluid pt-3'>
									<EditStory
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// story={appState[`drawerProps${i}`].story}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'deleteStory' &&
								<div class='container-fluid pt-3'>
									<DeleteStory
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// story={appState[`drawerProps${i}`].story}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'reportStory' &&
								<div class='container-fluid pt-3'>
									<ReportStory
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// story={appState[`drawerProps${i}`].story}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'removeStory' &&
								<div class='container-fluid pt-3'>
									<RemoveStory
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// story={appState[`drawerProps${i}`].story}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'newTeam' &&
								<div class='container-fluid pt-3'>
									<EditTeam
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										marginTop={'0px;'}
										marginBottom={'0px;'}
										team={'new'}
										// callback={appState[`drawerProps${i}`].callback}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'teamsFollowing' &&
								<TeamsFollowers
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									teamid={appState[`drawerProps${i}`].teamId || appState[`drawerProps${i}`].id}
									submenu={'following'}
									pageTitle={'Following'}
									// callback={appState[`drawerProps${i}`].callback}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'teamsFollowers' &&
								<TeamsFollowers
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									teamid={appState[`drawerProps${i}`].teamId || appState[`drawerProps${i}`].id}
									submenu={'followers'}
									pageTitle={'Followers'}
									// callback={appState[`drawerProps${i}`].callback}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'editTeam' &&
								<div class='container-fluid pt-3'>
									<EditTeam
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										// field={appState[`drawerProps${i}`].field}
										// type={appState[`drawerProps${i}`].type}
										// title={appState[`drawerProps${i}`].title}
										// help={appState[`drawerProps${i}`].help}
										team={appState[`drawerProps${i}`].id}
										skipImageList={true}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'newDog' &&
								<div class='container-fluid pt-3'>
									<DogEdit
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										marginTop={'0px;'}
										marginBottom={'0px;'}
										dog={'new'}
										// team={appState[`drawerProps${i}`].team}
										// callback={appState[`drawerProps${i}`].callback}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'newDogLog' &&
								<div class='container-fluid pt-3'>
									<DogAddLog
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										marginTop={'0px;'}
										marginBottom={'0px;'}
										// dog={appState[`drawerProps${i}`].dog}
										// team={appState[`drawerProps${i}`].team}
										// callback={appState[`drawerProps${i}`].callback}
										// showLog={appState[`drawerProps${i}`].showLog}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'editDog' &&
								<div class='container-fluid pt-3'>
									<DogEdit
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										// field={appState[`drawerProps${i}`].field}
										// type={appState[`drawerProps${i}`].type}
										// title={appState[`drawerProps${i}`].title}
										// help={appState[`drawerProps${i}`].help}
										dog={appState[`drawerProps${i}`].id}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'dogDetail' &&
								<div class='container-fluid pt-3'>
									<DogDetail
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										dog={appState[`drawerProps${i}`].id}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'addDogs' &&
								<div class='container-fluid pt-3'>
									<DogAddDogs
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										// callback={appState[`drawerProps${i}`].callback}
										// search={appState[`drawerProps${i}`].search}
										// object={appState[`drawerProps${i}`].object}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'editWorkout' &&
								<div class='container-fluid pt-3'>
									<WorkoutEdit
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										// field={appState[`drawerProps${i}`].field}
										// type={appState[`drawerProps${i}`].type}
										// title={appState[`drawerProps${i}`].title}
										// help={appState[`drawerProps${i}`].help}
										workout={appState[`drawerProps${i}`].id}
										drawerLevel={i}
										// limitImageList={appState[`drawerProps${i}`].imageLimit || 3}
										marginTop={'0px;'}
										marginBottom={'0px;'}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'newWorkout' &&
								<div class='container-fluid pt-3'>
									<WorkoutEdit
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										showBackButton={false}
										workout={'new'}
										marginTop={'0px;'}
										marginBottom={'0px;'}
										// callback={appState[`drawerProps${i}`].callback}
										// date={appState[`drawerProps${i}`].date}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'deleteWorkout' &&
								<div class='container-fluid pt-3'>
									<DeleteWorkout
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// workout={appState[`drawerProps${i}`].workout}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'reportWorkout' &&
								<div class='container-fluid pt-3'>
									<ReportWorkout
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// workout={appState[`drawerProps${i}`].workout}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'removeWorkout' &&
								<div class='container-fluid pt-3'>
									<RemoveWorkout
										stores={this.props.stores}
										{...appState[`drawerProps${i}`]}
										// workout={appState[`drawerProps${i}`].workout}
										// callback={appState[`drawerProps${i}`].callback}
										// setFocus={appState[`drawerProps${i}`].setFocus}
										drawerLevel={i}
									/>
								</div>
							}
							{appState[`drawerComponent${i}`] === 'newStory' &&
								<StoryAdd
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									team={'new'}
									// callback={appState[`drawerProps${i}`].callback}
									// forceShowAddImage={appState[`drawerProps${i}`].forceShowAddImage}
									// setFocus={appState[`drawerProps${i}`].setFocus}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'storyAddLocations' &&
								<StoryAddLocations
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// callback={appState[`drawerProps${i}`].callback}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'storyAddTags' &&
								<StoryAddTags
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// callback={appState[`drawerProps${i}`].callback}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'storyAdvanced' &&
								<StoryAdvanced
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// callback={appState[`drawerProps${i}`].callback}
									drawerLevel={i}
								/>
							}
							{appState[`drawerComponent${i}`] === 'comments' &&
								<Comments
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// object={appState[`drawerProps${i}`].object}
									// type={appState[`drawerProps${i}`].type}
									// callback={appState[`drawerProps${i}`].callback}
									// setFocus={appState[`drawerProps${i}`].setFocus}
									// holdingArrayName={appState[`drawerProps${i}`].holdingArrayName}
									// holdingStore={appState[`drawerProps${i}`].holdingStore}
									drawerLevel={i}
								/>
							}

							{appState[`drawerComponent${i}`] === 'widgetProfileDogList' &&
								<WidgetProfileDogList
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetProfileDogDetail' &&
								<WidgetProfileDogDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// id={appState[`drawerProps${i}`].id}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetProfileTeamList' &&
								<WidgetProfileTeamList
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetProfileTeamDetail' &&
								<WidgetProfileTeamDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
									// id={appState[`drawerProps${i}`].id}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetProfileLastTrophyList' &&
								<WidgetProfileLastTrophyList
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetProfileLastDogLogList' &&
								<WidgetProfileLastDogLogList
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutKeyFiguresList' &&
								<WidgetWorkoutKeyFiguresList
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutFitnessDetail' &&
								<WidgetWorkoutFitnessDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutIntensityDetail' &&
								<WidgetWorkoutIntensityDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutTotalKmDetail' &&
								<WidgetWorkoutTotalKmDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutTotalElevationDetail' &&
								<WidgetWorkoutTotalElevationDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutTotalDurationDetail' &&
								<WidgetWorkoutTotalDurationDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutTotalCaloriesDetail' &&
								<WidgetWorkoutTotalCaloriesDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutTotalRestDetail' &&
								<WidgetWorkoutTotalRestDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutGoalsDetail' &&
								<WidgetWorkoutGoalsDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutProgessDetail' &&
								<WidgetWorkoutProgessDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetWorkoutAvgSpeedDetail' &&
								<WidgetWorkoutAvgSpeedDetail
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
							{appState[`drawerComponent${i}`] === 'widgetPublicUser' &&
								<WidgetUserProfile
									stores={this.props.stores}
									{...appState[`drawerProps${i}`]}
									showBackButton={false}
									drawerLevel={i}
									// user={appState[`drawerProps${i}`].user}
									// team={appState[`drawerProps${i}`].team}
									// teams={appState[`drawerProps${i}`].teams}
									marginTop={'0px;'}
									marginBottom={'0px;'}
								/>
							}
						</Drawer>
					</>}
				</Fragment>);
			})}

			{showDashoard && <div class={`container-fluid border-bottom border-top bg-light fixed-bottom ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style='z-index: 99999;'>
				<button
					class='btn btn-lg btn-danger position-absolute'
					style='top: 5px; right: 5px; z-index: 10000;'
					type='button'
					onClick={this.toggleDashboard}
				>
					<i class='fas fa-times' />
				</button>
				<div class='row'>
					<div class='col-12 text-center py-3'>
						<h5><Text id='footer.dashboard'>Dashboard</Text></h5>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/^\/users\/profile/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a
							href='/users/profile'
							class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'danger'}`}
							onClick={this.toggleDashboard}
						>
							<div
								class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
								style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
								{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
							</div>
							<Text id='footer.user-profile'>Profile</Text>
						</a>
					</div>


					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/teams/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/teams/' class={`text-${path.match(/\/teams/) ? 'primary' : 'secondary'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-users' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='footer.teams'>Teamene</Text>
						</a>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/tracks/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/tracks/' class={`text-${path.match(/\/tracks/) ? 'primary' : 'muted'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-map-marked-alt' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='footer.tracks'>Rundene</Text>
						</a>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/users\//) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/users/' class={`text-${path.match(/\/users\//) ? 'primary' : 'muted'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-user' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='header.profile'>Din profil</Text>
						</a>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/^\/settings/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/settings/' class={`text-${path.match(/^\/settings/) ? 'primary' : 'success'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-cog' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='users.settings'>Settings</Text>
						</a>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/teams\/connect/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/teams/connect' class={`text-${path.match(/\/teams\/connect/) ? 'primary' : 'muted'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-project-diagram' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='header.connect-team'>Follow teams</Text>
						</a>
					</div>

					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/support/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='https://themusher.no/v2/support/faq-frequently-asked-questions/13' data-native class={`text-${path.match(/\/support/) ? 'primary' : 'muted'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-question-circle' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='header.support'>Support</Text>
						</a>
					</div>
				</div>
				<div class='row'>
					<div
						class={`col-6 col-sm-4 p-4 text-center p-2 ${path.match(/\/app-races/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/app-races/list' class={`text-${path.match(/\/app-races/) ? 'primary' : 'muted'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-flag-checkered' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='header.races'>Races</Text>
						</a>
					</div>

					{isVeterinary && <div
						class={`col-6 col-sm-4 p-4 text-center text-primary p-2 ${path.match(/\/veterinary/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/veterinary/' class={`text-${path.match(/\/veterinary/) ? 'primary' : 'info'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-syringe' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='footer.veterenary'>Vet</Text>
						</a>
					</div>}

					{isAdmin && <div
						class={`col-6 col-sm-4 p-4 text-center text-primary p-2 ${path.match(/\/admin/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/admin/' class={`text-${path.match(/\/admin/) ? 'primary' : 'danger'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-user-shield' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='footer.admin'>Admin</Text>
						</a>
					</div>}

					{isAdmin && <div
						class={`col-6 col-sm-4 p-4 text-center text-primary p-2 ${path.match(/\/news/) ? '' : ''} text-nowrap overflow-hidden d-flex align-items-center justify-content-center`}
						style='text-overflow: ellipsis;'
					>
						<a href='/news/' class={`text-${path.match(/\/news/) ? 'primary' : 'danger'}`} onClick={this.toggleDashboard}>
							<i class='fas fa-paragraph' style={`font-size: ${FONTSIZE_LARGE_ICON};`} /><br />
							<Text id='footer.news'>News</Text>
						</a>
					</div>}

					{user && user.id && path && <img src={`${apiServer}/api/pageview/dashboard/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
				</div>
				<div class='row'>
					<div class='col-12 d-flex justify-content-center py-3'>
						<Localizer>
							<input
								class='form-control form-control-lg'
								type='search'
								placeholder={<Text id='header.search-placeholder'>Søk etter det meste...</Text>}
								value={searchText}
								onInput={linkState(this, 'searchText')}
							/>
						</Localizer>
						<button
							class='btn btn-lg btn-outline-success'
							type='button'
							onClick={this.search}
						><Text id='header.search'>Søk</Text></button>
					</div>
				</div>
			</div>}

			{showMenu && <>
				<div
					class={`navbar-themed fixed-bottom pb-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
					style={`
						font-size: ${FONTSIZE_MENU};
					`}
				>
					{user.activeTeam && user.activeTeam.teamType >= 100 ? <>
						{user.activeTeam && user.activeTeam.teamType === 100 && <>
							{/* <Canicross stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} /> */}
							<MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} />
						</>}
						{user.activeTeam && user.activeTeam.teamType === 200 && <>
							{/* Working dog */}
							<MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} />
						</>}
						{user.activeTeam && user.activeTeam.teamType === 300 && <>
							{/* Active dog */}
							<MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} />
						</>}
					</> : <>
						<MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} />
					</>}

					{isAdmin && apiServerChanged && <div class='position-absolute badge badge-primary' style='bottom: 2px; right: 0;'>
						<span class='font-weight-lighter'>apiServer:</span> {apiServer} -
						<span class='font-weight-lighter'>webocketUrl:</span> {webocketUrl} -
						<span class='font-weight-lighter'>showMenu:</span> {JSON.stringify(showMenu)} -

					</div>}

				</div>
			</>}
		</>);
	}
}

export default Navigation;
