import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const initialState = {
	requestSent: {},
    confirmed: {},
    deleted: {},
    submenu: 'notfollowing',
};

@observer
class TeamLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.updateTimer = {};
    }

    requestFollowTeam = async (e) => {
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const teamId = parseInt(e.target.parentElement.dataset.team || e.target.dataset.team, 10);
        teamStore.followRequest(teamId, user);
        const { requestSent } = this.state;
        requestSent[teamId] = true;
        this.setState({ requestSent });
    }

    confirmRequest = async (e) => {
        const { callback = () => {} } = this.props;
        const { team, user } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        const { confirmed, deleted } = this.state;
		confirmed[`${team}-${user}`] = true;
        delete deleted[`${team}-${user}`];
        this.setState({ confirmed, deleted });
        await teamStore.confirmFollowRequest({
            team: parseInt(team, 10),
            user: parseInt(user, 10),
        });
        callback();
    }

    deleteRequest = async (e) => {
        const { callback = () => {} } = this.props;
        const { team, user } = e.target.closest('button').dataset;
        const { teamStore } = this.props.stores;
        const { deleted, confirmed } = this.state;
		deleted[`${team}-${user}`] = true;
        delete confirmed[`${team}-${user}`];
        this.setState({ deleted, confirmed });
        await teamStore.deleteFollowRequest({
            team: parseInt(team, 10),
            user: parseInt(user, 10),
        });
        callback();
    }

    unFollowTeam = (e) => {
        const { teamStore, userStore } = this.props.stores;
        const { user } = userStore;
        const teamId = parseInt(e.target.parentElement.dataset.team || e.target.dataset.team, 10);
        teamStore.unollowTeam(teamId);
        this.setState({ unfollowed: true });
    }

    getUnblockButton = (userId, myTeamId) => {
        const { confirmed } = this.state;
        const isConfirmed = confirmed[`${myTeamId}-${userId}`];
        return <>
            {isConfirmed ? <>
                <small class='text-success'>
                    <i class='fas fa-check' /> <Text id='teams.connect.confirmed'>Confirmed</Text>
                </small>
            </> : <>
                <button class='btn btn-sm btn-block btn-success text-overflow mt-1'
                    onClick={this.confirmRequest}
                    data-team={myTeamId}
                    data-user={userId}>
                    <Text id='teams.connect.confirm'>Confirm</Text>
                </button>
            </>}
        </>;
    }

    getButton = (team) => {
        const { requestSent } = this.state;
        const { userStore } = this.props.stores;
        const { user } = userStore;

        const isYourTeam = team.members && team.members?.indexOf(user.id) > -1;
        const isFollowing = team.followers && team.followers?.indexOf(user.id) > -1;
        const isBlocked = team.blockedFollowers && team.blockedFollowers?.indexOf(user.id) > -1;
        const hasRequestedFollow = requestSent[team.id] || team.followRequests && team.followRequests?.findIndex(e => e.id === user.id) > -1;

        if (isYourTeam) {
            return (
                <i class='fas fa-home' />
            );
        }
        if (!team.public) {
            return (<>
                <small class='text-muted'>
                    <Text id='teams.not-public'>Ikke offentlig</Text>
                </small>
            </>);
        }
        if (isFollowing) {
            return (<>
                <button
                    class={`btn btn-sm btn-block btn-outline-secondary float-right`}
                    onClick={this.unFollowTeam}
                    disabled='true'
                    data-team={team.id}
                >
                    <i class='fas fa-link mr-2' />
                    <Text id='teams.connect.is-following'>Følger</Text>
                </button>
                <button
                    class={`btn btn-sm btn-block btn-outline-danger float-right`}
                    onClick={this.unFollowTeam}
                    disabled='true'
                    data-team={team.id}
                >
                    <Text id='teams.connect.unfollow'>Unfollow</Text>
                </button>
            </>);
        }
        if (isBlocked) {
            return (<></>);
            // return (
            //     <button
            //         class={`btn btn-sm btn-block btn-outline-secondary float-right`}
            //         disabled='true'
            //     >
            //         <i class='fas fa-hand-paper mr-2' />
            //         <Text id='teams.connect.is-blocked'>Blokkert</Text>
            //     </button>
            // );
        }
        if (hasRequestedFollow) {
            return (
                <button
                    class={`btn btn-sm btn-block btn-outline-secondary float-right`}
                    disabled='true'
                >
                    <i class='fas fa-hourglass-half mr-2' />
                    <Text id='teams.connect.has-requested'>Venter på godkjenning</Text>
                </button>
            );
        }
        return (
            <button
                class={`btn btn-sm btn-block btn-outline-success float-right`}
                onClick={this.requestFollowTeam}
                data-team={team.id}
            >
                <i class='far fa-hand-point-right mr-2' />
                <Text id='teams.connect.follow'>Følg</Text>
            </button>
        );
    }

	render() {
        const { deleted = {}, unfollowed } = this.state;
        const { team, isBlocked, myTeamId, showBlockButton } = this.props;
        const { storyStore } = this.props.stores;

        const user = storyStore.findPublicUser(team.user) || team.user || {};
        let image;
        if (team.image && team.image.s3SmallLink) {
            image = team.image;
        } else if (team.images && team.images[0] && team.images[0].s3SmallLink) {
            image = team.images[0];
        } else if (user.image && user.image.s3SmallLink) {
            image = user.image;
        } else if (user.images && user.images[0] && user.images[0].s3SmallLink) {
            image = user.images[0];
        }
        let shortname;
        let name;
        let username;
        if (team && team.name) {
            shortname = mu.displayNameShort(team);
            name = mu.displayName(team);
            username = mu.displayName(user);
        } else if (user && user.id) {
            shortname = mu.displayNameShort(user);
            name = mu.displayName(user);
        }

        const isDeleted = deleted[`${myTeamId}-${user.id}`];

        return (
            <div class='col-12 mb-2 pb-2 border-bottom'
                style={`${unfollowed ? 'text-decoration: line-through; opacity: 0.3;' : ''}`}
            >
                <div class='d-flex position-relative'>
                    <div
                        class={`text-center border rounded-circle imageRounded imageRoundedMedium`}
                        style={image ? `background-image: url("${image.s3MediumLink}"); background-size: cover;` : ''}
                    >
                        {!image && <div class='text-muted' style='font-size: 20px; padding-top: 3px;'>{shortname}</div>}
                    </div>
                    <div class='flex-grow-1 pl-3 text-overflow' style='line-height: 1.2em;'>
                        <h5 class='mb-0'>
                            {team.public ? <>
                                <a href={`/teams/public/${team.uuidv4}`}>{name}</a>
                            </> : <>
                                {name}
                            </>}
                        </h5>
                        <div>
                            {team.place ? `${team.place}, ` : ''} {team.country} &nbsp;
                        </div>
                        <div>
                            <small class='text-muted'>
                                {team && team.dogs && <span class=''><i class='fas fa-dog' /> {team.dogs.length}</span>}
                                {team && team.followers && <span class='ml-2'><i class='fas fa-user' /> {team.followers.length}</span>}
                                {username && <>
                                    <small class='ml-2 text-muted'>
                                        {username}
                                    </small>
                                </>}
                                {showBlockButton && <>
                                    {isDeleted ? <>
                                        <small class='text-danger ml-3'>
                                            <i class='fas fa-ban' /> <Text id='teams.connect.deleted'>Deleted</Text>
                                        </small>
                                    </> : <>
                                        <button class='btn btn-sm btn-link text-overflow p-0 text-muted ml-3'
                                            style='font-size: 0.8em;'
                                            onClick={this.deleteRequest}
                                            data-team={myTeamId}
                                            data-user={user.id}
                                        >
                                            <i class='fas fa-ban' /> <Text id='teams.connect.block'>Blokkere</Text>
                                        </button>
                                    </>}
                                </>}
                            </small>
                        </div>
                    </div>
                    <div>
                        {isBlocked ? <>
                            {this.getUnblockButton(user.id, myTeamId)}
                        </> : <>
                            {this.getButton(team)}
                        </>}
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamLine;
