import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

@observer
class WidgetBody extends Component {
    render() {
        const { appState } = this.props.stores;
        const { darkmode } = appState;
        const {
            children,
            marginTop = '40px',
            paddingBottom = '100px;'
            // classNames = `${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`,
        } = this.props;
        return (<>
            <div
                class='w-100 h-100 bg-light'
                style={`margin-top: ${marginTop}; padding-bottom: ${paddingBottom}; min-height: calc(100vh - ${marginTop});`}>
                {children}
            </div>
        </>);
    }
}

export default WidgetBody;
