import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import Images from '../../components/form/images';
import Files from '../../components/form/files';
import Dogs from '../../components/dog/dogs';
import Achievements from '../../components/form/achievements';
import Links from '../../components/form/links';

import ScrollIntoView from '../../components/util/scrollIntoView';

const initialState = {
};

@observer
class Ambitions extends Component {
    toggleType(typeId) {
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: object.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { teamAmbitions, user } = userStore;
        const { language = 'en' } = user;

        const { saved, object, field, title, icon, help } = this.props;
        const value = toJS(object[field]);
        return (
            <div class={`form-group ${saved[`${field}.${object.id}`] ? 'is-valid' : ''} mt-4`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        {teamAmbitions && teamAmbitions.map(ambition => (
                            <div class='col-12 mb-2'>
                                <div class={`border rounded-pill-both p-2 text-center ${value === ambition.id ? 'bg-primary' : ''}`}
                                    style={`border-bottom: 3px solid ${ambition.colorNonstop} !important;`}
                                    onClick={() => this.toggleType(ambition.id)}
                                >
                                    {language === 'no' ? ambition.name : (ambition.lang[language] || ambition.name)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Types extends Component {
    toggleType(typeId) {
        const { object, type, field, updateField, isNew } = this.props;
        let value = toJS(object[field]);
        if (value === typeId) {
            value = null;
        } else {
            value = typeId;
        }
        updateField({ id: object.id, field, value, type, isNew });
    }

    render() {
        const { userStore } = this.props.stores;
        const { teamTypes, user } = userStore;
        const { language = 'en' } = user;
        const { saved, object, field, title, icon, help } = this.props;
        const value = object[field];
        return (
            <div class={`form-group ${saved[`${field}.${object.id}`] ? 'is-valid' : ''} mt-4`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <div class='container-fluid px-0'>
                    <div class='row'>
                        {teamTypes && teamTypes.reverse().map(teamType => (
                            <div class='col-12 mb-2'>
                                <div class={`border rounded-pill-both p-2 text-center ${value === teamType.id ? 'bg-primary' : ''}`}
                                    style={`border-bottom: 3px solid ${teamType.colorNonstop} !important;`}
                                    onClick={() => this.toggleType(teamType.id)}
                                >
                                    {language === 'no' ? teamType.name : (teamType.lang[language] || teamType.name)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class Input extends Component {
    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { teamStore } = this.props.stores;
        const { team } = this.props;
        const { name } = e.target.closest('button').dataset;
        const imageName = name;
        teamStore.removeImage({
            id: team.id,
            name: imageName,
        });
    }

    removeSponsorImage = async (e) => {
        const { teamStore } = this.props.stores;
        const { team } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        teamStore.removeSponsorImage({
            id: team.id,
            name: imageName,
        });
    }

    removeMainImage = () => {
        const { team, field, updateField } = this.props;
        updateField({ id: team.id, field, value: {} });
    }

    render() {
        const { saved, team, field, icon, title, type, help, onInput, updateField, datalist = [], showImages = true, showFiles = true, addToImages = true, rows = 10, limitImageList, showMissingInfo = true, detailsOpen, skipImageList } = this.props;
        const value = team[field];
        if (type === 'datalist') {
            return (
                <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={team.id}
                        data-field={field}
                        onInput={onInput}
                    />
                    <datalist id={`list-${field}`} >
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }

        if (type === 'select') {
            return (
                <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>

                    <select
                        list={`list-${field}`}
                        class={`form-control ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}
                        id={`${field}Input`}
                        aria-describedby={`${field}Help`}
                        value={value}
                        data-id={team.id}
                        data-field={field}
                        onInput={onInput}
                        autocomplete='off'
                    >
                        <option value=''>-- <Text id='teams.choose-country'>Velg land</Text> --</option>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </select>
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }


        if (type === 'image') {
            const images = field === 'imagesSponsor' ? team.imagesSponsor : team.images;
            return (
                <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>

                    {!skipImageList && <div class='container-fluid'>
                        <div class='row'>
                            {team[field] && team[field].s3SmallLink && <div class='col-3 p-1'>
                                <img src={team[field].s3SmallLink} class='img-fluid border rounded-lg' onError={this.handleImageErrored} />
                                <button class='btn btn-sm btn-link'
                                    onClick={this.removeMainImage}
                                    data-name={team[field].name}
                                ><i class='fas fa-trash' /> Del</button>
                                <span class='badge badge-success'>Main</span>
                            </div>}
                            {showImages && <>
                                {images && images.length > 0 && images.slice(0, limitImageList || images.length).map(img => (
                                    <div class='col-3 p-1'>
                                        <img
                                            src={img.s3SmallLink}
                                            alt={`May be an image of: ${mu.imageKeywords(img).join(', ')}`}
                                            class='img-fluid'
                                            onError={this.handleImageErrored}
                                        />

                                        <button class='btn btn-sm btn-link'
                                            onClick={field === 'imagesSponsor' ? this.removeSponsorImage : this.removeImage}
                                            data-name={img.name}
                                        ><i class='fas fa-trash' /> Del</button>
                                    </div>
                                ))}
                            </>}
                        </div>
                    </div>}
                    <Images saved={saved} object={team} field={field} updateField={updateField} addToImages={addToImages} autoOpen={false} stores={this.props.stores} key={`image-${field}`} />
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'toggle') {
            return (
                <div class={`row ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <div class='col-12 pt-2 d-flex align-items-center position-relative' style='font-size: 1.2em;'>
                        <span class='flex-grow-1' style='line-height: 1.0em;'>
                            {icon && <i class={`${icon} text-muted`} />} {title}
                        </span>
                        <div class='custom-control custom-switch custom-switch-lg mt-2 ml-3' style="transform: scale(1.8);">
                            <input type='checkbox'
                                class='custom-control-input'
                                id={`${field}Input`}
                                value={value === 1 ? 0 : 1}
                                data-id={team.id}
                                data-field={field}
                                onInput={onInput}
                                checked={value == 1 ? 'checked' : ''}
                            />
                            <label class='custom-control-label' for={`${field}Input`} />
                        </div>
                    </div>
                    <div class='col-12 pb-4 d-flex align-items-center position-relative'>
                        {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                            <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                    </div>
                </div>
            );
        }
        if (type === 'textarea') {
            return (
                <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <textarea type='text' class={`form-control ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={team.id}
                        data-field={field}
                        onInput={onInput}
                        rows={rows}
                    />
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    {showFiles && <>
                        <div class='container-fluid'>
                            <div class='row'>

                                {(!team.files || team.files?.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-file' /></div>
                                        <h3><Text id='teams.no-files-title'>No files attached to this dog!</Text></h3>
                                        <Text id='teams.no-files-text'>You can upload files to this dog inside edit. These files can be of any type. Ie: Certificates, test, etc.</Text>
                                    </div>
                                </>}

                                {team.files && team.files.map(file => (
                                    <div class='col-12 p-1'>
                                        <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                        <span class='mr-2'>{file.name}</span>
                                        <span class='font-weight-lighter mr-2'>{util.formatBytes(file.size, 1)}</span>
                                        <button class='btn btn-sm btn-link float-right p-0'
                                            onClick={this.removeFile}
                                            data-name={file.name}
                                        ><i class='fas fa-trash-alt' /> Del</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <Files saved={saved} object={team} field={field} updateField={updateField} />
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (field === 'links') {
            return (
                <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Links saved={saved} stores={this.props.stores} object={team} field={field} updateField={updateField} />
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (field === 'achievements') {
            return (
                <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Achievements saved={saved} stores={this.props.stores} object={team} field={field} updateField={updateField} showMissingInfo={showMissingInfo} detailsOpen={detailsOpen} />
                    {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        return (
            <div class={`form-group ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <input type='text' class={`form-control ${saved[`${field}.${team.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                    value={value}
                    data-id={team.id}
                    data-field={field}
                    onInput={onInput}
                />
                {saved[`${field}.${team.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='teams.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class TeamsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            newTeam: {
                type: 300,
            },
            submenu: 'info',
        };
        this.updateTimer = {};
    }

    async loadTeam() {
		const { teamStore } = this.props.stores;
        const { team, field } = this.props;
        if (team === 'new') {
            this.setState({ createNew: true });
        } else if (field) {
            await teamStore.load(team, false, { addData: [] });
        } else {
            await teamStore.load(team, false, { addData: ['dogs', 'users'] });
        }
    }

	resetDog() {
		this.setState({
            newTeam: {},
            createNew: false,
        });
	}

	insertTeam = async () => {
		const { newTeam } = this.state;
        const { callback } = this.props;
		const { teamStore, userStore } = this.props.stores;
		await teamStore.insert({ ...newTeam });
        await userStore.getInfo({ forceLoad: true });
        this.resetDog();
        if (util.isFunction(callback)) {
            callback();
        } else {
            route(`/teams/`);
        }
	}

    updateField = (e) => {
        const { id, field, type, elementtype, isNew } = e.target.dataset;
        const value = e.target.value;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
    }

    updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const { teamStore, userStore } = this.props.stores;
        let newValue = value;

        let localObjectValue = value;
        const { team } = teamStore;
        if (field === 'images') {
            if (Array.isArray(team.images) && Array.isArray(value)) {
                localObjectValue = [...team.images, ...value];
            } else {
                localObjectValue = [...team.images];
            }
        }
        if (field === 'imagesSponsor') {
            if (Array.isArray(team.imagesSponsor)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...team.imagesSponsor, ...value];
                } else {
                    localObjectValue = [...team.imagesSponsor, { ...value }];
                }
            }
        }
        if (field === 'achievements') {
            if (Array.isArray(team.achievements)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...team.achievements, ...value];
                } else {
                    localObjectValue = [...team.achievements, value];
                }
            }
        }
        if (field === 'links') {
            if (Array.isArray(team.links)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...team.links, ...value];
                } else {
                    localObjectValue = [...team.links, value];
                }
            }
        }
        if (field === 'files') {
            if (Array.isArray(team.files)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...team.files, ...value];
                } else {
                    localObjectValue = [...team.files, value];
                }
            }
        }
        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
        }
        // console.log('updateFieldValue', id, field, value, datatype, localObjectValue, newValue);

        if (isNew) {
            const { newTeam = {} } = this.state;
            newTeam[field] = value;
            this.setState({ newTeam });
            teamStore.updateObjectKeyValue('newTeam', field, value);
        } else {
            teamStore.updateField(id, field, localObjectValue);
            userStore.updateFieldByName({ namePlural: 'teams', name: 'team', id, field, value: localObjectValue });
            if (skipTimer) {
                this.doUpdateField(id, field, newValue, localObjectValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue, localObjectValue);
                }, 750);
            }
        }
    }

    updateDogTeam = async ({ value = [], singleValue, dog }) => {
        const { dogStore, teamStore } = this.props.stores;
        const { team } = teamStore;

        if (value?.indexOf(singleValue) > -1) {
            dogStore.addTeam({ id: singleValue, team: team.id });
            teamStore.addTeamLocal({ dog: { id: singleValue } });
        } else if (singleValue) {
            // console.log('removeTeam', { id: singleValue, team: team.id });
            dogStore.removeTeam({ id: singleValue, team: team.id });
            teamStore.removeTeamLocal({ dog: { id: singleValue } });
        }
    }

    async doUpdateField(id, field, value, localValue) {
        const { teamStore } = this.props.stores;
        await teamStore.saveField(id, field, value);
        teamStore.updateObjectKeyValue('publicTeam', field, localValue);
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

	componentDidMount() {
        this.loadTeam();
    }

	render() {
        const { createNew, newTeam, submenu } = this.state;
		const { teamStore, userStore } = this.props.stores;
		const { insertStatus, saved, team, dogs } = teamStore;
        const { countries } = userStore;
        const { name } = team;
        const { field, title, help, icon, type, limitImageList, skipImageList } = this.props;
        const currentTeam = userStore.getCurrentTeam();

        if (field) {
            if (util.isArray(field)) {
                return field.map((fieldObj, idx) => {
                    return (
                        <Input
                            saved={saved}
                            team={team}
                            field={fieldObj.field}
                            icon={fieldObj.icon}
                            type={fieldObj.type}
                            stores={this.props.stores}
                            title={fieldObj.title}
                            help={fieldObj.help}
                            onInput={this.updateField}
                            updateField={this.updateFieldValue}
                            skipImageList={skipImageList}
                            limitImageList={limitImageList}
                            showMissingInfo={false}
                            detailsOpen={true}
                        />
                    );
                });
            }
            return <>
                <Input
                    saved={saved}
                    team={team}
                    field={field}
                    icon={icon}
                    type={type}
                    stores={this.props.stores}
                    title={title}
                    help={help}
                    onInput={this.updateField}
                    updateField={this.updateFieldValue}
                    skipImageList={skipImageList}
                    limitImageList={limitImageList}
                    showMissingInfo={false}
                    detailsOpen={true}
                />
            </>;
        }

        if (createNew) {
            return (
                <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                    <button class='btn btn-link pl-0 fixed-top text-primary'
                        style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                        onClick={this.back}>
                        <i class='fas fa-arrow-circle-left' />
                    </button>
                    <div class='row'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>

                            <h5 style='padding-left: 35px;'><Text id='teams.add-new'>Legg til nytt team</Text></h5>
                            <div class='form-group'>
                                <label for='nameInput'><Text id='teams.name'>Teamnavn</Text></label>
                                <input type='text' class='form-control' id='nameInput' aria-describedby='nameHelp'
                                    onInput={linkState(this, 'newTeam.name')}
                                    value={newTeam.name}
                                />
                                <small id='nameHelp' class='form-text text-muted'><Text id='teams.name-help'>Navn på teamet. A-laget, B-laget, gjengen på tur, etc.</Text></small>
                            </div>

                            <Localizer>
                                <Input stores={this.props.stores} saved={saved} team={newTeam} field='public' icon='fas fa-eye' type='toggle'
                                    title={<Text id='teams.public'>Synlig offentlig</Text>}
                                    help={<Text id='teams.public-help'>Skal teamet være mulig å følge? Alle som vil følge må godkjennes av dere først.</Text>}
                                    onInput={linkState(this, 'newTeam.public')}
                                />

                                <Types stores={this.props.stores} saved={saved} object={newTeam} type='number' field='type' isNew={true}
                                    value={newTeam.type}
                                    title={<Text id='teams.type'>Type</Text>}
                                    icon='fas fa-dog'
                                    help={<Text id='teams.type-help'>Type team</Text>}
                                    updateField={this.updateFieldValue}
                                />
                             </Localizer>

                            <button type='button' class='btn btn-primary'
                                onClick={this.insertTeam}
                            >
                                <Text id='teams.save-new'>Registrer nytt team</Text>
                            </button>

                            {insertStatus && <div class='alert alert-success mt-4 col-12' role='alert'>
                                <Text id='teams.saved'>Teamet er lagret.</Text>
                            </div>}

                        </div>
                    </div>
                </div>
            );
        }

		return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <button class='btn btn-link pl-0 fixed-top text-primary'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                    onClick={this.back}>
                    <i class='fas fa-arrow-circle-left' />
                </button>
				<div class='row'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='clearfix'>
                            <div class={`text-center border rounded-circle imageRounded float-right`} style={team.image ? `background-image: url("${team.image.s3SmallLink}");` : ''}>
                                {!team.image && <i class='fas fa-dog text-muted mt-3' style='font-size: 40px;' />}
                            </div>
                            <h5 style='padding-left: 35px;'><Text id='teams.edit'>Endre</Text>: {name}</h5>
                        </div>
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            <div class='col-12 my-3'>
                                <div class='row'>
                                    <div class='w-100 overflow-hidden'>
                                        <div
                                            class='d-flex flex-row flex-nowrap pb-2 px-1'
                                            style='overflow: auto; scroll-snap-type: x mandatory;'
                                        >
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'info' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='info'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-info-circle' /> <Text id='teams.info'>Info</Text></button>
                                                {submenu === 'info' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'status' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='status'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-thermometer' /> <Text id='teams.status'>Status</Text></button>
                                                {submenu === 'status' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'internet' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='internet'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-hashtag' /> <Text id='teams.internet'>Sosialemedier</Text></button>
                                                {submenu === 'internet' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='pictures'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-images' /> <Text id='teams.pictures'>Pictures</Text></button>
                                                {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'dogs' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='dogs'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-users' /> <Text id='teams.dogs-menu'>Dogs</Text></button>
                                                {submenu === 'dogs' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'achievements' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='achievements'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-clock' /> <Text id='teams.achievements'>Achievements</Text></button>
                                                {submenu === 'achievements' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'links' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='links'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-link' /> <Text id='teams.links'>Links</Text></button>
                                                {submenu === 'links' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'descriptionWorkoutplan' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='descriptionWorkoutplan'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-link' /> <Text id='teams.descriptionWorkoutplan'>Workoutplan</Text></button>
                                                {submenu === 'descriptionWorkoutplan' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-none ${submenu === 'sponsors' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='sponsors'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-link' /> <Text id='teams.sponsors'>Sponsors</Text></button>
                                                {submenu === 'sponsors' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                            <div class='col px-0 pt-2'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                                    style='text-overflow: ellipsis; overflow: hidden;'
                                                    data-menu='advanced'
                                                    onClick={this.chooseSubmenu}
                                                ><i class='fas fa-cogs' /> <Text id='teams.log'>Advanced</Text></button>
                                                {submenu === 'advanced' && <ScrollIntoView horizontal={'center'} vertical={'end'} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>

                        {submenu === 'info' && <>
                             <Localizer>
                                <Input saved={saved} team={team} field='name' icon='fas fa-heading'
                                    title={<Text id='teams.name'>Teamnavn</Text>}
                                    help={<Text id='teams.name-help'>Navn på teamet. A-laget, B-laget, gjengen på tur, etc.</Text>}
                                    onInput={this.updateField} />


                                <Input saved={saved} team={team} field='description' icon='fas fa-paragraph' type='textarea'
                                    title={<Text id='teams.description'>Beskrivelse</Text>}
                                    help={<Text id='teams.description-help'>Beskrivelse av teamet.</Text>}
                                    onInput={this.updateField} />

                                <Input saved={saved} team={team} field='address' icon='fas fa-address-card'
                                    title={<Text id='teams.address'>Adresse</Text>}
                                    help={<Text id='teams.address-help'>Adressen til teamet.</Text>}
                                    onInput={this.updateField} />
                                <Input saved={saved} team={team} field='postalcode' icon='fas fa-address-card'
                                    title={<Text id='teams.postalcode'>Postnr</Text>}
                                    help={<Text id='teams.postalcode-help'>Postnummer til teamet.</Text>}
                                    onInput={this.updateField} />
                                <Input saved={saved} team={team} field='place' icon='fas fa-address-card'
                                    title={<Text id='teams.place'>Sted</Text>}
                                    help={<Text id='teams.place-help'>Stedet til teamet.</Text>}
                                    onInput={this.updateField} />

                                <Input saved={saved} team={team} field='country' icon='fas fa-address-card'
                                    datalist={countries.map(e => [e.name, e.name])} type='select'
                                    title={<Text id='teams.country'>Land</Text>}
                                    help={<Text id='teams.country-help'>Hjemlandet til teamet.</Text>}
                                    onInput={this.updateField} />

                            </Localizer>
                        </>}

                        {submenu === 'descriptionWorkoutplan' && <>
                             <Localizer>
                                <Input saved={saved} team={team} field='descriptionWorkoutplan' icon='fas fa-paragraph' type='textarea'
                                    title={<Text id='teams.descriptionWorkoutplan'>Workoutplan description</Text>}
                                    help={<Text id='teams.descriptionWorkoutplan-help'>Description of your workoutplan and philosophy.</Text>}
                                    onInput={this.updateField}
                                    rows={20}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'sponsors' && <>
                             <Localizer>
                                <Input saved={saved} team={team} field='sponsors' icon='fas fa-paragraph' type='textarea'
                                    title={<Text id='teams.sponsors'>Sponsors</Text>}
                                    help={<Text id='teams.sponsors-help'>List your sponsors.</Text>}
                                    onInput={this.updateField}
                                    rows={20}
                                />

                                <Input saved={saved} team={team} field='imagesSponsor' type='image' icon='fas fa-camera'
                                    stores={this.props.stores}
                                    title={<Text id='teams.sponsor-photo'>Sponsor logos</Text>}
                                    help={<Text id='teams.sponsor-photo-help'>Logos from sponsors.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                    showImages={true}
                                    addToImages={false}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'internet' && <>
                            <Localizer>
                                <Input saved={saved} team={team} field='url' icon='fas fa-link'
                                    title={<Text id='teams.url'>Link til nettside</Text>}
                                    help={<Text id='teams.url-help'>Link til nettside for teamet.</Text>}
                                    onInput={this.updateField} />
                                <Input saved={saved} team={team} field='facebook' icon='fab fa-facebook'
                                    title={<Text id='teams.facebook'>Facebook</Text>}
                                    help={<Text id='teams.facebook-help'>Link til Facebookside.</Text>}
                                    onInput={this.updateField} />
                                <Input saved={saved} team={team} field='instagram' icon='fab fa-instagram'
                                    title={<Text id='teams.instagram'>Instagram</Text>}
                                    help={<Text id='teams.instagram-help'>Instagram brukernavn.</Text>}
                                    onInput={this.updateField} />
                                <Input saved={saved} team={team} field='snapchat' icon='fab fa-snapchat'
                                    title={<Text id='teams.snapchat'>Snapchat</Text>}
                                    help={<Text id='teams.snapchat-help'>Snapchat bruker.</Text>}
                                    onInput={this.updateField} />
                            </Localizer>
                        </>}

                        {submenu === 'status' && <>
                            <Localizer>
                                <Input stores={this.props.stores} saved={saved} team={team} field='public' icon='fas fa-eye' type='toggle'
                                    title={<Text id='teams.public'>Synlig offentlig</Text>}
                                    help={<Text id='teams.public-help'>Skal teamet være mulig å følge? Alle som vil følge må godkjennes av dere først.</Text>}
                                    onInput={this.updateField} />

                                <Ambitions stores={this.props.stores} saved={saved} object={team} type='number' field='ambitions'
                                    title={<Text id='teams.ambitions'>Ambisjoner</Text>}
                                    icon='fas fa-hand-rock'
                                    help={<Text id='teams.ambitions-help'>Ambisjonsnivå til teamet.</Text>}
                                    updateField={this.updateFieldValue} />

                                <Types stores={this.props.stores} saved={saved} object={team} type='number' field='type'
                                    title={<Text id='teams.type'>Type</Text>}
                                    icon='fas fa-dog'
                                    help={<Text id='teams.type-help'>Type team</Text>}
                                    updateField={this.updateFieldValue} />
                             </Localizer>
                        </>}

                        {submenu === 'pictures' && <>
                            <Localizer>
                                <Input saved={saved} team={team} field='image' type='image' icon='fas fa-portrait'
                                    stores={this.props.stores}
                                    title={<Text id='teams.profile-photo'>Profilbilde</Text>}
                                    help={<Text id='teams.profile-photo-help'>Profilbilde av hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                    showImages={false}
                                />
                                <Input saved={saved} team={team} field='imageHeader' type='image'
                                    showImages={false}
                                    addToImages={false}
                                    stores={this.props.stores}
                                    title={<Text id='teams.header-photo'>Toppbilde</Text>}
                                    help={<Text id='teams.header-photo-help'>Bilde av teamet.</Text>}
                                    onInput={this.updateField} updateField={this.updateFieldValue}
                                />

                                <Input saved={saved} team={team} field='imageOther' type='image' icon='fas fa-camera'
                                    stores={this.props.stores}
                                    title={<Text id='teams.photo'>Bilde</Text>}
                                    help={<Text id='teams.photo-help'>Bilder av hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                    showImages={true}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'dogs' && <>
                            <Dogs
                                stores={this.props.stores}
                                type='array'
                                elementtype='number'
                                field='dogs'
                                icon='fas fa-dog'
                                saved={team.saved}
                                object={{ dogs: dogs.map(e => e.id) }}
                                title={<Text id='teams.dogs'>Hunder</Text>}
                                help={<Text id='teams.dogs-help'>Hvilke hunder er med i teamet?</Text>}
                                updateField={this.updateDogTeam} filter={this.dogFilter}
                                showPositions={false}
                                currentTeam={currentTeam}
                            />
                        </>}

                        {submenu === 'achievements' && <>
                            <Localizer>
                                <Input saved={saved} team={team} field='achievements' type='text' icon='fas fa-clock'
                                    stores={this.props.stores}
                                    title={<Text id='teams.achievements'>Bragder</Text>}
                                    help={<Text id='teams.achievements-help'>Bragder til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'links' && <>
                            <Localizer>
                                <Input saved={saved} team={team} field='links' type='text' icon='fas fa-link'
                                    stores={this.props.stores}
                                    title={<Text id='teams.links'>Linker</Text>}
                                    help={<Text id='teams.links-help'>Linker til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}

                        {submenu === 'advanced' && <>
                            <Localizer>
                                <Input saved={saved} team={team} field='files' type='file' icon='fas fa-file'
                                    stores={this.props.stores}
                                    title={<Text id='teams.files'>Files</Text>}
                                    help={<Text id='teams.files-help'>Filer til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </>}


                        {/* <Input saved={saved} team={team} field='members' title='Medlemmer' help='Handlere, kjørere som er medlem av teamet.' onInput={this.updateField} /> */}
                        {/* <Input saved={saved} team={team} field='dogs' title='Hunder' help='Hunder som er med i teamet.' onInput={this.updateField} /> */}
                        {/* <Input saved={saved} team={team} field='tracks' title='Runder' help='Treningsrundene til teamet.' onInput={this.updateField} /> */}

					</div>
				</div>
			</div>
		);
	}
}

export default TeamsEdit;
