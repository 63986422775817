import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import linkState from 'linkstate';

import Images from '../form/images';

import mu from '../../lib/musher-util';

@observer
class CommentsAdd extends Component {
     constructor(props) {
        super(props);
        this.state = {
            comment: {
                body: '',
                images: [],
            },
            showAddImage: false,
            sendButtonEnabled: true,
        };
        this.inputRef = {};
    }

    beforeImagesUploaded = () => {
        this.setState({ sendButtonEnabled: false });
    }

    afterImagesUploaded = () => {
        this.setState({ sendButtonEnabled: true });
    }

    comment = async () => {
        const { sendButtonEnabled } = this.state;
        if (!sendButtonEnabled) {
            return false;
        }
        const {
            object,
            type,
            callback = () => {},
            inputRefObject = {},
            holdingArrayName,
            holdingStore,
        } = this.props;
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;
        const { comment, showAddImage } = this.state;
        this.setState({ sendButtonEnabled: false });

        let response;
        switch (type) {
            case 'story':
                response = await storyStore.comment({ id: object.id, comment });
                break;
            case 'workout':
                response = await workoutStore.comment({ id: object.id, comment });
                break;
            case 'news':
                response = await newsStore.comment({ id: object.id, comment });
                break;
            case 'race':
                response = await raceStore.comment({ id: object.id, comment });
                break;
            case 'track':
                response = await trackStore.comment({ id: object.id, comment });
                break;
        }

        switch(holdingStore) {
            case 'storyStore':
                storyStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            case 'workoutStore':
                workoutStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            case 'teamStore':
                teamStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            case 'newsStore':
                newsStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            case 'raceStore':
                raceStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            case 'trackStore':
                trackStore.commentAdd({ id: object.id, data: response, holdingArrayName });
                break;
            default:
                break;
        }

        if (response && response.id) {
            this.setState({
                comment: {
                    body: '',
                    images: [],
                },
            }, () => {
                mu.resizeTextarea(inputRefObject[object.id]);
                if (showAddImage) {
                    this.addImage();
                }
            });
            callback({ object, response });
        }
        this.setState({ sendButtonEnabled: true });
    }

    onTextareaFocus = (e) => {
        mu.resizeTextarea(e);
        this.setState({ showExtraInput: true });
    }

    onTextareaBlur = (e) => {
        if (e.target.value === '') {
            this.setState({ showExtraInput: false });
        }
    }

    addImages = ({ value }) => {
        // updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        const { comment } = this.state;
        comment.images.push(...value);
        this.setState({ comment });
    }

    addImage = () => {
        const { showAddImage = false } = this.state;
        this.setState({
            showAddImage: !showAddImage,
        });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    render() {
        const { inputRef, object } = this.props;
        const { comment, showAddImage, sendButtonEnabled, showExtraInput } = this.state;
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const displayNameShort = mu.displayNameShort(user);
        const displayName = mu.displayName(user);
        const userImage = user.image || (user.images ? user.images[0] : undefined);

        return (
            <div>
                <div class='d-flex mb-0 position-relative w-100'>
                    <div
                        class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                        style={`${userImage ? `background-image: url('${userImage.s3SmallLink}'); background-size: cover;` : ''} border-width: 3px !important;`}
                    >
                        {/* {!user.image && <span class='font-weight-lighter' style='font-size: 15px;'>{displayNameShort}</span>} */}
                        {!user.image && <div class='text-muted' style='font-size: 15px; padding-top: 5px; padding-left: 1px;'><i class='fa-thin fa-user' /></div>}
                    </div>
                    <div class='col pl-3' style='line-height: 1.2em;'>

                        <div class='d-flex bd-highlight'>
                            <div class='p-0 w-100 bd-highlight'>
                                <label class='sr-only' for='inlineFormInput'><Text id='comments.comment'>Kommentar</Text></label>
                                <div class='position-relative'>
                                    <Localizer>
                                        <textarea
                                            class='form-control-plaintext px-2 border-0'
                                            type='text'
                                            placeholder={<Text id='comments.write-comment'>Skriv en kommentar...</Text>}
                                            onInput={linkState(this, 'comment.body')}
                                            onKeyUp={mu.resizeTextarea}
                                            onFocus={this.onTextareaFocus}
                                            onBlur={this.onTextareaBlur}
                                            style='height: 2.3em;'
                                            value={comment.body}
                                            ref={inputRef}
                                        />
                                    </Localizer>

                                    {showExtraInput && <div class='position-absolute' style='top: 8px; right: 10px; font-size: 1.2em;'>
                                        <i class='fas fa-image pointerCursor'
                                            data-id={object.id}
                                            onClick={this.addImage}
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div class='p-0 pl-2 flex-shrink-1 bd-highlight'>
                                {showExtraInput && <button type='button' class={`btn btn-${sendButtonEnabled ? 'primary' : 'secondary'} mb-2`} disabled={!sendButtonEnabled}
                                    onClick={this.comment}
                                ><i class='fas fa-paper-plane' /></button>}
                            </div>
                        </div>

                    </div>
                </div>
                {showAddImage && <>
                    <div class='col-12 text-center'>
                        <div class='container-fluid'>
                            <div class='row'>
                                {util.isArray(comment.images) && comment.images.map(img => (
                                    <div class='col-3 p-1'>
                                        <img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                        <button class='btn btn-sm btn-link'
                                            onClick={this.removeImage}
                                            data-name={img.name}
                                        ><i class='fas fa-trash' /> <Text id='comments.remove-image'>Remove</Text></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Images object={comment} updateField={this.addImages} before={this.beforeImagesUploaded} after={this.afterImagesUploaded} autoOpen={true} stores={this.props.stores} />
                    </div>
                </>}
            </div>
        );
    }
}

export default CommentsAdd;
