import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

const RED_DAYS = [0, 6];

@observer
class HistoryCalendar extends Component {

    async loadHistorySummary(props = this.props) {
        this.setState({ isLoading: true });
		const { userStore, workoutStore } = this.props.stores;

        const currentTeamObject = userStore.getCurrentTeam();
		const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentMonth = new Date().getMonth() + 1;
        const { month = currentMonth, year = util.getYear(), team = currentTeam, dog = {}, callback = () => {} } = props;
        await userStore.getFeed({ month, year, team, dog: dog.id });

        const { historyType } = this.state;
        callback(historyType);

        this.setState({ isLoading: false });
    }

    setHistoryType = (e) => {
        const { callback = () => {} } = this.props;
        const { historyType: prevHistoryType } = this.state;
        let historyType = e.target.dataset.historytype || e.target.parentElement.dataset.historytype;

        if (historyType === prevHistoryType) {
            this.setState({ historyType: null })
            callback(null);
        } else {
            this.setState({ historyType });
            callback(historyType);
        }
    }

    chooseDay = (e) => {
        const { year: yyyy, month: mm, day: dd } = this.state;
        const { setDay = () => {} } = this.props;
		const day = parseInt(e.target.parentElement.dataset.day || e.target.dataset.day, 10);
		const month = parseInt(e.target.parentElement.dataset.month || e.target.dataset.month, 10);
		const year = parseInt(e.target.parentElement.dataset.year || e.target.dataset.year, 10);
		// console.log({ day, month, year });

        if (year === yyyy && month === mm && day === dd) {
            this.setState({
                year: null,
                month: null,
                day: null,
            });
            setDay(null, null, null);
        } else {
            this.setState({
                year,
                month,
                day,
            });
            setDay(year, month, day);
        }
    }

    componentDidMount() {
        this.loadHistorySummary();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.month !== this.props.month
            || nextProps.year !== this.props.year
            || nextProps.dog !== this.props.dog
            || nextProps.team !== this.props.team) {
            this.loadHistorySummary(nextProps);
        }
    }

    render() {
        const { historyType, day: chosenDD, month: chosenMM, year: chosenYYYY } = this.state;
        const filterHistories = historyType ? (e) => e.historyType === historyType : () => true;

        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en' } = user;

        const HISTORY_TYPE_MAP = mu.getHistoryMap(language);
        const DAYS = mu.getDays(language);
        const MONTHS = mu.getMonths(language);

		const { darkmode } = appState;
        const currentDay = new Date().getDate();
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = util.getYear(), dog, basehref } = this.props;

        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);
        const { month: nextMonth, year: nextYear } = mu.getNextMonth(monthNum, yearNum);
        const { month: prevMonth, year: prevYear } = mu.getPrevMonth(monthNum, yearNum);

        const daysOfMonth = util.getDaysInMonth(month, year);
        const weeksInMonth = util.getWeeksInMonth(month, year);
        const firstWeek = weeksInMonth[0].week;
        const daysOfWeek = util.getDaysInWeek(firstWeek, year);

        return (
            <>
                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-4'>
                    <a href={`${basehref || '/workouts/month/'}${nextMonth}/${nextYear}/skipScroll`} class='float-right btn btn-sm btn-link'><i class='fas fa-arrow-right' /></a>
                    <a href={`${basehref || '/workouts/month/'}${prevMonth}/${prevYear}/skipScroll`} class='float-left btn btn-sm btn-link'><i class='fas fa-arrow-left' /></a>
                    <h5><Text id='dog.calendar.month'>Måned</Text> - {MONTHS[month - 1]} {year}</h5>
                    {dog && <div>{dog.name}</div>}
                    <small>{util.isoDate(daysOfMonth[0], false, false, true)} - {util.isoDate(daysOfMonth[daysOfMonth.length - 1], false, false, true)}</small>
                </div>

                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-4 mb-4'>
                    <table class='table table-striped table-sm'>
                        <thead>
                            <tr>
                                <th class='text-muted'><small><Text id='dog.calendar.week'>Uke</Text></small></th>
                                {daysOfWeek.map(day => <th><small>{DAYS[day.getDay()]}</small></th>)}
                                {/* <th class='text-muted'>Totalt</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {weeksInMonth && weeksInMonth.map(weekObj => {
                                const daysOfWeek = util.getDaysInWeek(weekObj.week, year, month);
                                return (
                                    <tr>
                                        <td class='text-right text-muted pt-3'>
                                            <small>
                                                {weekObj.week}
                                            </small>
                                        </td>
                                        {daysOfWeek.map(day => {
                                            const yyyy = day.getFullYear();
                                            const mm = day.getMonth() + 1;
                                            const dd = day.getDate()
                                            const histories = userStore.findHistory(yyyy, mm, dd);
                                            const endHistories = userStore.findEndHistory(yyyy, mm, dd);
                                            // { "id": "5f59d861eaa7385aaef6b399", "date": "2020-09-09T19:00:00.000Z", "year": 2020, "month": 9,
                                            // "day": 9, "createdDate": "2020-09-10T07:40:17.834Z", "title": "Spiste ikke opp alt",
                                            // "historyType": "foodLeftover", "images": [], "dogId": 4, "dogName": "Glenn", "dogShortname": "",
  											const isChosen = (chosenYYYY == yyyy && chosenMM == mm && chosenDD == dd);
											const isToday =  (currentYear == yyyy) && (currentMonth == mm) && (currentDay == dd);
											const isDayOff = RED_DAYS.indexOf(day.getDay()) > -1;

                                            return (
                                                <td class={`text-center font-weight-light position-relative pt-3 ${isChosen ? 'calendarIsChosen' : ''} ${isToday ? 'calendarIsToday' : ''} ${isDayOff ? 'calendarIsWeekend' : ''}`}
                                                    style={`font-size: 0.8em;`}
                                                    onClick={this.chooseDay} data-day={dd} data-month={mm} data-year={yyyy}
                                                >
                                                    <div class='position-absolute text-muted' style='top: 0px; right: 2px; font-size: 0.7em;'>
                                                        {day.getDate()}
                                                    </div>

                                                    {histories && histories.length > 0 ? <>
                                                        {histories.filter(filterHistories).map((history, idx) => {
                                                            if (!history.historyType) {
                                                                return (
                                                                    <div class='rounded-circle position-absolute border'
                                                                        style={`background-color: #808080; top: 50%; left: ${idx * 5}px; width: 15px; height: 15px;`}
                                                                    />
                                                                );
                                                            }
                                                            const bgColor = mu.getHistoryTypeColor(history.historyType);
                                                            return (
                                                                <div class='rounded-circle position-absolute border'
                                                                    style={`background-color: ${bgColor}; top: 50%; left: ${idx * 5}px; width: 15px; height: 15px;`}
                                                                />
                                                            );
                                                        })}
                                                        {histories.length > 1 && <span class='position-absolute text-danger'
                                                            style='bottom: 0; right: 0px; font-size: 0.7em;'>{histories.length}</span>}
                                                    </>: '-'}

                                                    {endHistories && endHistories.length > 0 ? <>
                                                        {endHistories.filter(filterHistories).map((history, idx) => {
                                                            if (!history.historyType) {
                                                                return (
                                                                    <div class='rounded position-absolute border border-white'
                                                                        style={`background-color: #808080; top: 50%; left: ${idx * 5}px; width: 15px; height: 15px;`}
                                                                    />
                                                                );
                                                            }
                                                            const bgColor = mu.getHistoryTypeColor(history.historyType);
                                                            return (
                                                                <div class='rounded position-absolute border border-white'
                                                                    style={`background-color: ${bgColor}; top: 50%; left: ${idx * 5}px; width: 15px; height: 15px;`}
                                                                />
                                                            );
                                                        })}
                                                        {endHistories.length > 1 && <span class='position-absolute text-danger'
                                                            style='bottom: 0; right: 0px; font-size: 0.7em;'>{endHistories.length}</span>}
                                                    </>: '-'}

                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-4 mb-4'>
                    <div class='clearfix'>
                        <small>
                            {mu.getHistoryPri().map(key => {
                                const bgColor = mu.getHistoryTypeColor(key);
                                return (
                                    <span class={`float-left mr-2 ${historyType === key ? 'font-weight-bold' : 'font-weight-lighter'}`} onClick={this.setHistoryType} data-historytype={key}>
                                        <div class='rounded-circle border float-left mr-1'
                                            style={`background-color: ${bgColor}; width: 15px; height: 15px;`}
                                        /> {HISTORY_TYPE_MAP[key]}
                                    </span>
                                );
                            })}
                        </small>
                    </div>
                    <div class='clearfix'>
                        <small>
                            {mu.getHistoryPri(2).map(key => {
                                const bgColor = mu.getHistoryTypeColor(key);
                                return (
                                    <span class={`float-left mr-2 ${historyType === key ? 'font-weight-bold' : 'font-weight-lighter'}`} onClick={this.setHistoryType} data-historytype={key}>
                                        <div class='rounded-circle border float-left mr-1'
                                            style={`background-color: ${bgColor}; width: 15px; height: 15px;`}
                                        /> {HISTORY_TYPE_MAP[key]}
                                    </span>
                                );
                            })}
                        </small>
                    </div>
                    <div class='clearfix'>
                        <small>
                            {mu.getHistoryPri(3).map(key => {
                                const bgColor = mu.getHistoryTypeColor(key);
                                return (
                                    <span class={`float-left mr-2 ${historyType === key ? 'font-weight-bold' : 'font-weight-lighter'}`} onClick={this.setHistoryType} data-historytype={key}>
                                        <div class='rounded-circle border float-left mr-1'
                                            style={`background-color: ${bgColor}; width: 15px; height: 15px;`}
                                        /> {HISTORY_TYPE_MAP[key]}
                                    </span>
                                );
                            })}
                        </small>
                    </div>
                </div>
            </>
        );
    }
}

export default HistoryCalendar;
