import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import LineGraph from '../../components/lineGraph';

@withText((props) => ({
    week: <Text id='workout.graph.week' />,
    distance: <Text id='workout.graph.distance' />,
    duration: <Text id='workout.graph.duration'>Duration</Text>,
    rest: <Text id='workout.graph.rest'>Rest</Text>,
    ascend: <Text id='workout.graph.ascend' />,
    plan: <Text id='workout.graph.plan' />,
    speedAvg: <Text id='workout.graph.speedavg'>Speed</Text>,
    loadIndex: <Text id='workout.graph.loadindex'>Loadindex</Text>,

    avgDogs: <Text id='workout.graph.avgdogs'>Avg dogs</Text>,
    avgImages: <Text id='workout.graph.avgimages'>Avg images</Text>,
    sledWeight: <Text id='workout.graph.sledweight'>Sled weight</Text>,
    rating: <Text id='workout.graph.rating'>Rating</Text>,
    temperature: <Text id='workout.graph.temperature'>Temperature</Text>,
}))
@observer
class WorkoutGraph extends Component {
    render() {
        const { userStore, workoutPlanStore } = this.props.stores;
        const {
            user,
			workoutSummary,
			graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
			graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
            avgDayDistance, avgDayElevation,
            avgDayDuration, avgDaySpeedAvg, avgDayLoadIndex,
            avgDayAvgDogs, avgDayAvgImages, avgDaySledWeight,
            avgDayRest, avgDayRating, avgDayTemperature,
		} = userStore;
        const { settings = {} } = user;
        const { graphPlanWeekDistanceCurrent } = workoutPlanStore;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const {
            isLoading,
            week,
            distance,
            duration,
            rest,
            ascend,
            plan,
            speedAvg,
            loadIndex,
            avgDogs,
            avgImages,
            sledWeight,
            rating,
            temperature,
            year = currentYear,
            month = currentMonth,
            showExtra = false,
            showHeader = true,
            showElevation = true,
            scrollToRight = true,
        } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        // const summaryMonth = workoutPlanStore.findWorkoutPlanSummaryMonth(year, monthNum) || {};
        const seasonWeeks = util.weekRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        const seasonDays = util.dayRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);

        const xTicksAll = seasonWeeks.map(wo => wo.week);
        const parts = Math.floor(xTicksAll.length / 5);
        const xTicks = [];
        for (let i = 0, l = xTicksAll.length; i < l; i += parts) {
            xTicks.push(xTicksAll[i]);
        }

        const xTicksAllDays = seasonDays.map(wo => `${wo.day}.${wo.month}`);
        const partsDays = Math.floor(xTicksAllDays.length / 5);
        const xTicksDays = [];
        for (let i = 0, l = xTicksAllDays.length; i < l; i += partsDays) {
            xTicksDays.push(xTicksAllDays[i]);
        }

        return (
            <>
                {/* <h5><Text id='workout.graph.title'>Treningsstatistikk</Text> {seasonStart} - {seasonStart + 1}</h5> */}
                <div class='w-100 mt-3 mb-3 pt-3'>
                    {/* <xmp>graphWorkoutSummaryWeekDistanceCurrent: {JSON.stringify(graphWorkoutSummaryWeekDistanceCurrent, null, 4)}</xmp>
                    <xmp>graphWorkoutSummaryWeekDistancePrev: {JSON.stringify(graphWorkoutSummaryWeekDistancePrev, null, 4)}</xmp> */}
                    {showHeader && <h5 class='text-center'><Text id='workout.graph.total-distance'>Total distance</Text></h5>}
                    <LineGraph
                        stores={this.props.stores}
                        width={500}
                        height={200}
                        showXTicks={1}
                        xTicksVal={val => `${week} ${val}`}
                        xTicks={xTicks}
                        paddingTop={30}
                        lineFromXzero={true}
                        lineFromXmax={true}
                        xTicksLink={'/workouts/week/'}

                        dataLeft={[graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev, graphPlanWeekDistanceCurrent]}
                        legendLeft={[`${seasonStart} ${distance}`, `${seasonStart - 1} ${distance}`, `${seasonStart} ${plan}`]}
                        dataFillLeft={[1]}
                        // yMaxLeft={30}
                        // yMinLeft={0}
                        showYTicksLeft={1}
                        yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                        unitLeft={settings.imperial ? ['mi', 'mi', 'mi'] : ['km', 'km', 'km']}
                        avgWindowLeft={[0, 0, 0]}
                        smoothLeft={[1, 1, 1]}

                        dataRight={showElevation ? [graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev] : []}
                        legendRight={showElevation ? [`${seasonStart} ${ascend}`, `${seasonStart - 1} ${ascend}`] : []}
                        showYTicksRight={showElevation ? 1 : 0}
                        yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                        unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                        avgWindowRight={[0, 0]}
                        smoothRight={[1, 1]}
                        scrollToRight={scrollToRight}
                        // marker={week}
                    />
                </div>

                {!isLoading && graphWorkoutSummaryWeekDistanceCurrent && graphWorkoutSummaryWeekDistanceCurrent.length < 3 && (
                    <div class='mb-5 py-3 border-top border-bottom'>
                        <div class='text-left' style='line-height: 1.0em;'>
                            <div class='display-4 float-left text-warning mr-3'>
                                <i class='fas fa-paw' />
                            </div>
                            <small>
                                <Text id='workout.graph.too-little-data'>Foreløpig er det litt lite data for å kunne vise noen fine grafer.
                                Etterhvert som du legger inn flere treninger så vil dette bli en fin tabell og graf som viser progresjonen din.</Text>
                            </small>
                        </div>
                    </div>
                )}
                {showExtra && <>
                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-rating'>Average rating/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataLeft={[avgDayRating]}
                            legendLeft={[`${seasonStart} ${rating}`]}
                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            // yTicksLeftPostfix={settings.imperial ? 'mph' : 'km/t'}
                            // unitLeft={settings.imperial ? ['mph', 'mph', 'mph'] : ['km/t', 'km/t', 'km/t']}
                            avgWindowLeft={[0, 0, 0]}
                            smoothLeft={[0, 0, 0]}

                            dataRight={[avgDayTemperature]}
                            legendRight={[`${seasonStart} ${temperature}`]}
                            showYTicksRight={1}
                            yTicksRightPostfix={settings.imperial ? '°F' : '°C'}
                            // unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowRight={[0, 0]}
                            smoothRight={[0, 0]}

                            // marker={week}
                        />
                    </div>

                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-distance'>Average distance/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataLeft={[avgDayDistance]}
                            legendLeft={[`${seasonStart} ${distance}`]}
                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                            unitLeft={settings.imperial ? ['mi', 'mi', 'mi'] : ['km', 'km', 'km']}
                            avgWindowLeft={[0, 0, 0]}
                            smoothLeft={[0, 0, 0]}

                            dataRight={[avgDayElevation]}
                            legendRight={[`${seasonStart} ${ascend}`]}
                            showYTicksRight={1}
                            yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                            unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowRight={[0, 0]}
                            smoothRight={[0, 0]}

                            // marker={week}
                        />
                    </div>

                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-duration'>Average duration/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            paddingLeft={50}
                            yTicksLeftWidth={'50px'}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataLeft={[avgDayDuration]}
                            legendLeft={[`${seasonStart} ${duration}`]}
                            yTicksLeftVal={val => `${util.secToHms(val)}`}

                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            // yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                            // unitLeft={settings.imperial ? ['mi', 'mi', 'mi'] : ['km', 'km', 'km']}
                            avgWindowLeft={[0, 0, 0]}
                            smoothLeft={[0, 0, 0]}

                            dataRight={[avgDayRest]}
                            legendRight={[`${seasonStart} ${rest}`]}
                            showYTicksRight={1}
                            yTicksRightVal={val => `${util.secToHms(val)}`}
                            // yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                            // unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowRight={[0, 0]}
                            smoothRight={[0, 0]}

                            // marker={week}
                        />
                    </div>

                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-speed'>Average speed/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataLeft={[avgDaySpeedAvg]}
                            legendLeft={[`${seasonStart} ${speedAvg}`]}
                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            yTicksLeftPostfix={settings.imperial ? 'mph' : 'km/t'}
                            unitLeft={settings.imperial ? ['mph', 'mph', 'mph'] : ['km/t', 'km/t', 'km/t']}
                            avgWindowLeft={[0, 0, 0]}
                            smoothLeft={[0, 0, 0]}

                            dataRight={[avgDayLoadIndex]}
                            legendRight={[`${seasonStart} ${loadIndex}`]}
                            showYTicksRight={1}
                            // yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                            // unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowRight={[0, 0]}
                            smoothRight={[0, 0]}

                            // marker={week}
                        />
                    </div>

                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        <h5 class='text-center'><Text id='workout.graph.avg-dogs'>Average dogs/day</Text></h5>
                        <LineGraph
                            stores={this.props.stores}
                            width={500}
                            height={200}
                            showXTicks={1}
                            xTicksVal={val => `${val}`}
                            xTicks={xTicksDays}
                            paddingTop={30}
                            lineFromXzero={true}
                            lineFromXmax={true}
                            // xTicksLink={'/workouts/week/'}

                            dataLeft={[avgDayAvgDogs, avgDayAvgImages]}
                            legendLeft={[`${seasonStart} ${avgDogs}`, `${seasonStart} ${avgImages}`]}
                            // dataFillLeft={[1]}
                            // yMaxLeft={30}
                            // yMinLeft={0}
                            showYTicksLeft={1}
                            // yTicksLeftPostfix={settings.imperial ? 'mph' : 'km/t'}
                            // unitLeft={settings.imperial ? ['mph', 'mph', 'mph'] : ['km/t', 'km/t', 'km/t']}
                            avgWindowLeft={[0, 0, 0]}
                            smoothLeft={[0, 0, 0]}

                            dataRight={[avgDaySledWeight]}
                            legendRight={[`${seasonStart} ${sledWeight}`]}
                            showYTicksRight={1}
                            // yTicksRightPostfix={settings.imperial ? 'ft' : 'm'}
                            // unitRight={settings.imperial ? ['ft', 'ft'] : ['m', 'm']}
                            avgWindowRight={[0, 0]}
                            smoothRight={[0, 0]}

                            // marker={week}
                        />
                    </div>

                    {/* <xmp>{JSON.stringify(avgDayDuration, null, 4)}</xmp> */}
                </>}
            </>
        );
    }
}

export default WorkoutGraph;
