import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import PubSub, { topics } from '../../lib/pubsub';
import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import WorkoutGraph from '../../components/workout/graph';
import WorkoutCalendar from '../../components/workout/calendar';
import DogHistory from '../../components/dog/history';
import DogAddLog from '../../components/dog/addlog';
import DogCalendar from '../../components/dog/calendar';
import DogStatus from '../../components/dog/status';
import FormGraph from '../../components/dog/formGraph';
import PremiumBadge from '../../components/premium/badge';
import ImageScroller from '../../components/imageScroller';

import Modal from '../../components/modal';
import WorkoutTotals from '../../components/workout/totals';
import WorkoutStatus from '../../components/workout/status';
import Ad from '../../components/ad/';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Images from '../../components/form/images';
import Files from '../../components/form/files';

import ScrollIntoView from '../../components/util/scrollIntoView';
import Field from '../../components/util/field';

import FastList from '../../components/team/fastList';

const initialState = {
    submenu: 'stats',
    offset: 0,
	limit: 100,
};

@observer
class Input extends Component {
    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        dogStore.removeImage({
            id: dog.id,
            name: imageName,
        });
    }

    removeFile = async (e) => {
        const { dogStore } = this.props.stores;
        const { dog } = this.props;
        const fileName = e.target.parentElement.dataset.name || e.target.dataset.name;
        dogStore.removeFile({
            id: dog.id,
            name: fileName,
        });
    }

    render() {
        const { saved, dog, type = 'text', datalist = [], min, max, step, field, title, icon, help, onInput, updateField, refName, showImages = false, showFiles = false } = this.props;
        let value = dog[field];
        let maxValue = max;
        if (type === 'date') {
            if (value) {
                value = util.isoDate(dog[field], false, false, true);
            }
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    {showFiles && <>
                        <div class='container-fluid'>
                            <div class='row'>

                                {(!dog.files || dog.files.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-file' /></div>
                                        <h3><Text id='dogs.no-files-title'>No files attached to this dog!</Text></h3>
                                        <Text id='dogs.no-files-text'>You can upload files to this dog inside edit. These files can be of any type. Ie: Certificates, test, etc.</Text>
                                    </div>
                                </>}

                                {dog.files && dog.files.map(file => (
                                    <div class='col-12 p-1'>
                                        <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                        <span class='mr-2'>{file.name}</span>
                                        <span class='font-weight-lighter mr-2'>{util.formatBytes(file.size, 1)}</span>
                                        <button class='btn btn-sm btn-link float-right p-0'
                                            onClick={this.removeFile}
                                            data-name={file.name}
                                        ><i class='fas fa-trash-alt' /> Del</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <Files saved={saved} object={dog} field={field} updateField={updateField} />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'image') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>

                    {showImages && <>
                        <div class='container-fluid'>
                            <div class='row'>
                                {dog.images && dog.images.map(img => (
                                    <div class='col-3 p-1'>
                                        <img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                        <button class='btn btn-sm btn-link'
                                            onClick={this.removeImage}
                                            data-name={img.name}
                                        ><i class='fas fa-trash' /> Remove</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <Images saved={saved} object={dog} field={field} updateField={updateField} autoOpen={false} stores={this.props.stores} />
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>
                    <input list={`list-${field}`} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={dog.id}
                        data-field={field}
                        onInput={onInput}
                        // onInput={e => console.log(e)}
                        // onChange={e => console.log(e)}
                    />
                    <datalist id={`list-${field}`}>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'select') {
            return (
                <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label>

                    <select type={type} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                        value={value}
                        data-id={dog.id}
                        data-field={field}
                        onInput={onInput}
                    >
                        <option value=''>-- Velg kjønn --</option>
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </select>
                    {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        return (
            <div class={`form-group ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={icon} />} {title}</label> {refName}
                <input type={type} class={`form-control ${saved[`${field}.${dog.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                    min={min}
                    max={maxValue}
                    step={step}
                    value={value}
                    data-id={dog.id}
                    data-field={field}
                    onInput={onInput}
                />
                {saved[`${field}.${dog.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dogs.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class DogsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
			newHistory: {
				date: util.isoDate(new Date(), false, false, true),
				time: util.isoTime(new Date()),
			},
            ...initialState,
            submenu: props.submenu || 'stats',
        };
        this.updateTimer = {};
    }

    async loadAll(props = this.props) {
        this.setState({ isLoading: true });
        await Promise.all([
            this.loadDog(props),
            this.loadWorkoutPlans(props),
        ]);
        this.setState({ isLoading: false });
    }

    loadDog = async (props = this.props) => {
		const { dogStore, userStore } = props.stores;
        const dogId = parseInt(props.dog, 10);
        await Promise.all([
            // dogStore.load(dogId, false, { addData: ['vaccineStatuses', 'historyStatuses', 'allDogs'] }),
            dogStore.load(dogId, false, { addData: ['team', 'user', 'workoutSummary', 'vaccineStatuses', 'historyStatuses', 'vaccineLogStatuses', 'allDogs'] }),
            userStore.getWorkoutSummary({ dog: dogId }),
        ]);

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);
        dogStore.findWorkoutSummaryCurrenSeason(dogId, seasonStart) || {};
        dogStore.findWorkoutSummaryPreviousSeason(dogId, seasonStart - 1) || {};


        const { dog } = dogStore;
        if (dog && dog.team) {
            this.loadTeam(dog.team);
        }
        if (dog && dog.motherChipId) {
            dogStore.loadExternalDogs([dog.motherChipId, dog.fatherChipId]);
        }
        dogStore.plotFormGraph();
    }

    async loadTeam(team) {
		const { teamStore } = this.props.stores;
        await teamStore.load(team);
        teamStore.plotFormGraph();
    }

    loadWorkoutPlans = async (props = this.props) => {
		const { year } = props;

		const currentMonth = new Date().getMonth() + 1;
		const currentYear = util.getYear();
		const seasonStart = parseInt(year || (currentMonth < 8 ? currentYear - 1 : currentYear), 10);
		const { workoutPlanStore, dogStore } = props.stores;
		const { dog } = dogStore;

		workoutPlanStore.updateItem('workoutPlans', []);
		workoutPlanStore.updateItem('workoutPlan', {});
		workoutPlanStore.resetGraphPlanWeekDistanceCurrent();

		if (dog.team) {
			await workoutPlanStore.load({ query: {
				team: dog.team,
				seasonYear: seasonStart,
				seasonMonth: 6,
			} });
			const { workoutPlans } = workoutPlanStore;
			const workoutPlanCurrent = workoutPlans[0] || {};
            if (workoutPlanCurrent.id) {
                await workoutPlanStore.load(workoutPlanCurrent.id);
                workoutPlanStore.prepareWeekGraphs({ year });
            } else {
                workoutPlanStore.updateKeyValue('workoutPlan', {});
                workoutPlanStore.updateKeyValue('workoutPlans', []);
                workoutPlanStore.resetGraphPlanWeekDistanceCurrent();
            }
		}
	}

	showModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const img = e.target.dataset.image;
        const imgXXL = e.target.dataset.imagexxl;
		const thumb = e.target.dataset.thumb;
        const name = e.target.dataset.name;
        const title = e.target.dataset.title;
        const date = e.target.dataset.date;
        this.setState({
            displayModal: true,
            currentImage: img,
            currentImageXXL: imgXXL,
            thumb,
            name,
            title,
            date,
        });
    }

    closeModal = () => {
        this.setState({
            displayModal: false,
        });
    }

    setHistoryType = (historyType) => {
		this.setState({ historyType });
    }

	deleteDog = async () => {
		const { dogStore } = this.props.stores;
		const { dog } = dogStore;
        await dogStore.saveField(dog.id, 'offline', 1);
		PubSub.publish(topics.STATUS_MESSAGE, {
			message: 'Hunden er slettet',
			type: 'success',
			icon: 'fas fa-check',
		});
        route(`/dogs/list`);
	}

    updateField = (e) => {
        const { id, field, type, elementtype, isNew } = e.target.dataset;
        const value = e.target.value;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isNew });
    }

    updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const { dogStore } = this.props.stores;
        let newValue = value;

        let localObjectValue = value;
        const { dog } = dogStore;
        if (field === 'images') {
            if (Array.isArray(dog.images) && Array.isArray(value)) {
                localObjectValue = [...dog.images, ...value];
            }
        }
        if (field === 'files') {
            if (Array.isArray(dog.files)) {
                if (Array.isArray(value)) {
                    localObjectValue = [...dog.files, ...value];
                } else {
                    localObjectValue = [...dog.files, value];
                }
            }
        }

        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }

        if (isNew) {
            dogStore.updateObjectKeyValue('newDog', field, value);
        } else {
            dogStore.updateField(id, field, localObjectValue);
            if (skipTimer) {
                this.doUpdateField(id, field, newValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue);
                }, 500);
            }
        }
    }

    async doUpdateField(id, field, value) {
        const { dogStore } = this.props.stores;
        await dogStore.saveField(id, field, value);
    }

	chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });

        // const { dogStore } = this.props.stores;
        // const { dog } = dogStore;
        // route(`/dogs/${dog.id}/${submenu}`);
	}

    onEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
		const { id } = e.target.closest('button').dataset;
        const { drawerHeightMedium } = appState;
        appState.openDrawer3('editDog', {
			id,
			height: drawerHeightMedium,
		});
	}

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

    toggleCalendar = async () => {
        const { showCalendar = false } = this.state;
        this.setState({ showCalendar: !showCalendar });
    }

    renderContent = ({ obj, idx }) => {
        const { appState } = this.props.stores;
        const { path } = appState;
        const { hashtag } = this.props;

        return (<>
			<DogHistory
				key={obj.id}
				stores={this.props.stores}
				line={obj}
			/>
        </>);
    }

	componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.dog !== this.props.dog) {
            this.loadAll(nextProps);
        }
    }

	render() {
		const { displayModal, currentImage, currentImageXXL, thumb, name, title, date, historyType, submenu, showCalendar = false } = this.state;
        const { month, year, showBackButton = true, marginBottom, marginTop } = this.props;
        // const monthNum = parseInt(month, 10);
        // const yearNum = parseInt(year, 10);
        const filterHistories = historyType ? (e) => {
            // if (monthNum && yearNum) {
            //     return e.historyType === historyType && e.month === monthNum && e.year === yearNum;
            // }
            return e.historyType === historyType;
        } : () => true;
		const { dogStore, userStore, workoutPlanStore, workoutStore, appState } = this.props.stores;
        const { darkmode } = appState;
        const { workoutPlan, workoutPlans = [] } = workoutPlanStore;

        const currentTeamObject = userStore.getCurrentTeam();
		const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const { historyFeed, isAdmin, user } = userStore;
        const { language = 'en' } = user;

		const { dog, vaccineStatuses, historyStatuses, workoutSummary, workoutSummaryCurrentSeason = {}, workoutSummaryPreviousSeason = {}, saved } = dogStore;
        const dogStatus = userStore.findDogstatus({ status: dog.status, language });
        const dogStatuses = dog.statuses ? dog.statuses.map(e => userStore.findDogstatus({ status: e, language })) : [];
        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        // const teamName = userStore.findTeam(dog.team);
        // const teamNames = dog.teams ? dog.teams.map(e => userStore.findTeam(e)) : [];

		// const hasHistory = historyFeed && historyFeed.length > 0;
		const hasHistory = dog.history && dog.history.length > 0;

        const teamNames = [];
        if (userStore.findTeam(dog.team)) {
            teamNames.push(userStore.findTeam(dog.team));
        }
        if (dog.teams) {
            dog.teams.forEach((e) => {
                if (userStore.findTeam(e)) {
                    teamNames.push(userStore.findTeam(e));
                }
            });
        }

        const dogVaccineLogStatuses = dogStore.findVaccineLogStatuses(dog.chipId);

        const dogMother = dogStore.findDog(dog.mother);
        const dogFather = dogStore.findDog(dog.father);

        const children = dogStore.findChildren(dog.chipId);
        const siblings = dogStore.findSiblings(dog.id, dog.motherChipId, dog.birth);
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = dog.birth ? Math.ceil(mu.daysUntilNextBirthday(dog.birth)) : 365;

		return (
			<div class='container-fluid'  style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <div class='d-flex justify-content-between mb-2'>
                    {showBackButton && <div>
                        <button class='btn btn-link pl-0 fixed-top text-primary'
                            type='button'
                            style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                            onClick={this.back}>
                            <i class='fas fa-arrow-circle-left' />
                        </button>
                    </div>}
                    <div>
                        <h5 class='text-muted'>&nbsp;</h5>
                    </div>
                    <div>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary float-left ml-3'
                            data-id={dog.id}
                            onClick={this.onEditClick}
						>
							<i class='fas fa-edit' /> <Text id='dogs.edit'>Endre</Text>
						</button>
                    </div>
                </div>
				{displayModal && <Modal close={this.closeModal} image={currentImage} imagexxl={currentImageXXL} thumb={thumb} name={name} title={title} date={date} />}

				<div class='row'>
					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <Field value={dog.name} darkmode={darkmode} type='dog' width={600} height={300} forceShowLoader={false}>
                            <div class='d-flex mb-1'>
                                {/* {JSON.stringify(dog, null, 4)} */}
                                <div class={`text-center border rounded-circle imageRounded`} style={dog.image ? `background-image: url("${dog.image.s3SmallLink}");` : ''}>
                                    {!dog.image && <i class='fas fa-dog text-muted mt-3' style='font-size: 40px;' />}
                                </div>
                                <div class='flex-grow-1 pl-3 pr-2'
                                    style={`border-right: 4px solid ${userStore.findHarness(dog.harness, 'colorNonstop')} !important;`}
                                >
                                    <span class='float-right'>
                                        {daysUntilBirthday < 7 && <>
                                            <span class='text-muted mr-2'>
                                                <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                            </span>
                                        </>}
                                        {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                        {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                                    </span>
                                    <h5>
                                        {dog.shortname || dog.name} {dog.shortname && dog.shortname !== '' && <> - <span class='font-weight-lighter'>{dog.name}</span></>}
                                        {hasBirthday && <>
                                            <i class='fas fa-birthday-cake ml-3' />
                                        </>}
                                    </h5>

                                    <div>
                                        {dog.birth && <>
                                            <small class='text-muted'>
                                                <i class='fas fa-hand-holding-heart' /> {util.isoDate(dog.birth, false, false, true)}
                                            </small>
                                        </>}
                                    </div>

                                    <div>
                                        {teamNames && teamNames.length > 0 && <small class='font-weight-lighter'>
                                            {teamNames.map((name, idx) => <>{idx > 0 ? ', ' : ''}{name}</>)}
                                        </small>}
                                    </div>

                                    <div>
                                        <small class='text-muted'>
                                            {dog.chipId && dog.chipId.length > 5 && <><i class='fas fa-microchip' /> {dog.chipId}</>}
                                            {dog.weight > 0 && <><i class='fas fa-balance-scale ml-2' /> {util.format(dog.weight, 1)} kg</>}
                                            {dog.bodyScore > 0 && <><i class='fas fa-dog ml-2' /> {util.format(dog.bodyScore, 1)}</>}
                                        </small>
                                    </div>

                                    <div>
                                        {vaccineStatuses && vaccineStatuses.filter(e => e.dogId === dog.id).map(v => (
                                            <span class={`badge badge-success mr-2`} title={util.isoDate(v.endDate, false, false, true)}>
                                                {v.vaccineType}
                                                <span class='font-weight-lighter ml-3'>
                                                    <i class='fas fa-calendar-check' /> {util.isoDate(v.endDate, false, false, true)}
                                                </span>
                                            </span>
                                        ))}
                                    </div>

                                    <div>
                                        {historyStatuses && historyStatuses.filter(e => e.dogId === dog.id).map(v => (
                                            <span class={`badge badge-warning mr-2`} title={util.isoDate(v.endDate, false, false, true)}>{v.title}</span>
                                        ))}
                                    </div>

                                    <div>
                                        {dog.specialNeeds && <span class={`badge badge-warning mr-2`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                        {dogStatus && dogStatus.class && <span class={`badge badge-${dogStatus.class} mr-2`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                                        {dogStatuses && dogStatuses.map(status => <>
                                            {status && status.class && <span class={`badge badge-${status.class} mr-2`}>{status.icon && <i class={status.icon} />} {status.name}</span>}
                                        </>)}
                                        {dogPosition && dogPosition.class && <span class={`badge badge-${dogPosition.class || 'primary'} mr-2`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>}
                                    </div>

                                </div>
                            </div>
                        </Field>
                    </div>

                    {/* <Ad stores={this.props.stores} url={this.props.url} /> */}

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
                        <div class='row'>
                            <div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-0 px-1'
									style='overflow: auto; scroll-snap-type: x mandatory; line-height: 1.0em; height: 56px;'
                                >
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'info' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='info'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-pen-nib' /> <Text id='dogs.info'>Info</Text></button>
                                        {/* {submenu === 'info' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'stats' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='stats'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-tachometer-alt' /> <Text id='dogs.stats'>Stats</Text></button>
                                        {/* {submenu === 'stats' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='pictures'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-images' /> <Text id='dogs.pictures'>Pictures</Text></button>
                                        {/* {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'workouts' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='workouts'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-running' /> <Text id='dogs.workouts'>Workouts</Text></button>
                                        {/* {submenu === 'workouts' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'fitness' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='fitness'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-chart-line' /> <Text id='dogs.fitness'>Fitness</Text></button>
                                        {/* {submenu === 'fitness' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'relations' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='relations'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-users' /> <Text id='dogs.relations'>Relations</Text></button>
                                        {/* {submenu === 'relations' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'log' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='log'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-history' /> <Text id='dogs.log'>Log</Text></button>
                                        {/* {submenu === 'log' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'trophy' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='trophy'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-award' /> <Text id='dogs.trophy'>Trophy case</Text></button>
                                        {/* {submenu === 'trophy' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'achievements' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='achievements'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-clock' /> <Text id='dogs.achievements'>Achievements</Text></button>
                                        {/* {submenu === 'achievements' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'links' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='links'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-link' /> <Text id='dogs.links'>Links</Text></button>
                                        {/* {submenu === 'links' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'veterinary' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='veterinary'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-syringe' /> <Text id='dogs.veterinary'>Veterenary</Text></button>
                                        {/* {submenu === 'veterinary' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'advanced' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='advanced'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-cogs' /> <Text id='dogs.advanced'>Advanced</Text></button>
                                        {/* {submenu === 'advanced' && <ScrollIntoView horizontal={'center'} />} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {submenu === 'info' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <h5><Text id='dogs.info-about'>Informasjon om</Text> {dog.name}</h5>

                            {!dog.description && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fas fa-pen-nib' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='dogs.add-description'>Gå inn på endring av hunden for å legge inn en beskrivelse.</Text>
                                    </h3>
                                </div>
                            </>}
                            <Markdown markdown={mu.replaceImages(dog.description, dog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                        </div>
                    </div>
                </>}

                {submenu === 'stats' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <WorkoutGraph
                                stores={this.props.stores}
                                isLoading={false}
                                key={`graph-${workoutPlan?.id}-${workoutPlans?.length}`}
                            />
                            <WorkoutTotals stores={this.props.stores}
                                currentTeam={dog.team}
                                currentSeason={workoutSummaryCurrentSeason}
                                prevSeason={workoutSummaryPreviousSeason}
                                key={`totals-${dog.id}-${Object.keys(workoutSummaryCurrentSeason).length}-${Object.keys(workoutSummaryPreviousSeason).length}`}
                            />
                            <WorkoutStatus stores={this.props.stores}
                                dog={dog.id}
                                key={`status-${dog.id}-${Object.keys(workoutSummaryCurrentSeason).length}-${Object.keys(workoutSummaryPreviousSeason).length}`}
                            />
                        </div>

                        {workoutSummaryCurrentSeason.positions && <>
                            <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                                <table class='table table-sm table-striped'>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Lead</th>
                                            <th>Point</th>
                                            <th>Team</th>
                                            <th>Wheel</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th><Text id='workouts.distance'>Distance</Text></th>
                                            <td class='text-center'><Distance stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'lead', 'distanceKm') || 0} /> </td>
                                            <td class='text-center'><Distance stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'point', 'distanceKm') || 0} /> </td>
                                            <td class='text-center'><Distance stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'team', 'distanceKm') || 0} /> </td>
                                            <td class='text-center'><Distance stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'wheel', 'distanceKm') || 0} /> </td>
                                        </tr>
                                        <tr>
                                            <th><Text id='workouts.duration'>Duration</Text></th>
                                            <td class='text-center'><Duration stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'lead', 'duration') || 0} /></td>
                                            <td class='text-center'><Duration stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'point', 'duration') || 0} /></td>
                                            <td class='text-center'><Duration stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'team', 'duration') || 0} /></td>
                                            <td class='text-center'><Duration stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'wheel', 'duration') || 0} /></td>
                                        </tr>
                                        <tr>
                                            <th><Text id='workouts.ascend'>Ascend</Text></th>
                                            <td class='text-center'><Elevation stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'lead', 'elevation') || 0} /></td>
                                            <td class='text-center'><Elevation stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'point', 'elevation') || 0} /></td>
                                            <td class='text-center'><Elevation stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'team', 'elevation') || 0} /></td>
                                            <td class='text-center'><Elevation stores={this.props.stores} value={util.asNumber(workoutSummaryCurrentSeason, 'positions', 'wheel', 'elevation') || 0} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}
                    </div>
                </>}

                {submenu === 'pictures' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <h5><Text id='dogs.pictures-of'>Bilder av</Text> {dog.name}</h5>
                            <div class='row'>

                                {(!dog.images || dog.images?.length === 0) && <>
                                    <div class='text-center text-muted'>
                                        <div class='display-1'><i class='fas fa-images' /></div>
                                        <h3><Text id='dogs.no-images-title'>No images for this dog!</Text></h3>
                                        <Text id='dogs.no-images-text'>You can add more images to this dog. Puppy pictures, workout pictures, lazy summer pictures, etc. All is a nice way to create a better profile for this dog.</Text>
                                    </div>
                                </>}

                                <ImageScroller stores={this.props.stores} images={dog.images} />
                            </div>
                        </div>

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-2 mt-5 bg-light py-4 border rounded-lg'>
                            <Input saved={saved} dog={dog} field='imageOther' type='image' icon='fas fa-camera'
                                stores={this.props.stores}
                                title={<Text id='dogs.photo'>Bilde</Text>}
                                help={<Text id='dogs.photo-help'>Bilder av hunden.</Text>}
                                onInput={this.updateField} updateField={this.updateFieldValue}
                                showImages={false}
                            />
                        </div>
                    </div>
                </>}

                {submenu === 'workouts' && <>
                    <div class='row mt-4'>
                        <WorkoutCalendar
                            stores={this.props.stores}
                            dog={dog}
                            basehref={`/dogs/${dog.id}/`}
                            month={month}
                            year={year}
                            key={`calendar-${workoutPlan.id}-${workoutPlans?.length}`}
                        />
                    </div>
                </>}

                {submenu === 'relations' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <div class='container-fluid mb-5 mt-4'>
                                <div class='row mt-2'>
                                    <div class='col text-center'>
                                        <small class='text-muted'><Text id='dogs.birth'>Født</Text></small>
                                        <h3>
                                            {util.isoDate(dog.birth, false, false, true)}
                                        </h3>
                                    </div>
                                    {dog.deceased && <div class='col text-center border-left'>
                                        <small class='text-muted'><Text id='dogs.deceased'>Død</Text></small>
                                        <h3>
                                            {util.isoDate(dog.deceased, false, false, true)}
                                        </h3>
                                    </div>}
                                </div>
                                <div class='row mt-2'>
                                    <div class='col text-center'>
                                        <small class='text-muted'><Text id='dogs.team'>Team</Text></small>
                                        <h3>
                                            {dog.team && userStore.findTeam(dog.team)}
                                        </h3>
                                    </div>
                                </div>
                                <div class='row mt-2'>
                                    <div class='col text-center'>
                                        <small class='text-muted'><Text id='dogs.mother'>Mor</Text></small>
                                        {dogMother ? <DogStatus stores={this.props.stores} dog={dogMother} fullWidth={true} /> : <>
                                            <h3>
                                                {dog.motherName}
                                            </h3>
                                            <div>
                                                {dog.motherChipId && <small><i class='fas fa-microchip' /> {dog.motherChipId}</small>}
                                            </div>
                                        </>}
                                    </div>
                                    <div class='col text-center border-left'>
                                        <small class='text-muted'><Text id='dogs.father'>Far</Text></small>
                                        {dogFather ? <DogStatus stores={this.props.stores} dog={dogFather} fullWidth={true} /> : <>
                                            <h3>
                                                {dog.fatherName}
                                            </h3>
                                            <div>
                                                {dog.fatherChipId && <small><i class='fas fa-microchip' /> {dog.fatherChipId}</small>}
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                <div class='row mt-2'>
                                    <div class='col text-center'>
                                        <small class='text-muted'><Text id='dogs.siblings'>Søsken</Text></small>
                                        <div class='row'>
                                            {siblings && siblings.map(d => <DogStatus stores={this.props.stores} dog={d} />)}
                                        </div>
                                    </div>
                                </div>
                                <div class='row mt-2'>
                                    <div class='col text-center'>
                                        <small class='text-muted'><Text id='dogs.children'>Avkom</Text></small>
                                        <div class='row'>
                                            {children && children.map(d => <DogStatus stores={this.props.stores} dog={d} />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                {submenu === 'log' && <>
                    <div class='row mt-1'>
                        <div class='offset-0 col-12 mt-1'>
                            <div class='container-fluid'>
                                <div class='row'>
                                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                                        <h5 class='mt-3'><Text id='dogs.log-for'>Logg for</Text> {dog.name}</h5>
                                    </div>

                                    {/* {showCalendar ? <>
                                        <DogCalendar
                                            stores={this.props.stores}
                                            basehref={`/dogs/${dog.id}/history/`}
                                            dog={dog}
                                            month={month}
                                            year={year}
                                            callback={this.setHistoryType}
                                            key={`calendar-history-${dog.id}-${util.getNestedValue(dog, 'history.length')}`}
                                        />
                                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-4'>
                                            <button class='btn btn-block btn-link' onClick={this.toggleCalendar}>
                                                <i class='fas fa-angle-up' /> <Text id='dogs.hide-calendar'>Hide Calendar</Text>
                                            </button>
                                        </div>
                                    </> : <>
                                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-4'>
                                            <button class='btn btn-block btn-link' onClick={this.toggleCalendar}>
                                                <i class='fas fa-angle-down' /> <Text id='dogs.show-calendar'>Show Calendar</Text>
                                            </button>
                                        </div>
                                    </>} */}

                                    {!hasHistory && <div class='text-muted col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                                        <Text id='dogs.log-for-is-empty' fields={{ name: dog.name }}>Loggen til {dog.name} er tom</Text>
                                    </div>}
                                    {/* {hasHistory && historyFeed.filter(filterHistories).map(line => (
                                        <DogHistory stores={this.props.stores} line={line} />
                                    ))} */}

                                    <FastList
                                        data={dog.history && dog.history.reverse()}
                                        wrapperClassNames={'row pb-4'}
                                        contentClassNames={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 my-1 position-relative rounded rounded-lg py-3 bg-${darkmode ? 'black' : 'white'} shadow-sm`}
                                        renderContent={this.renderContent}
                                        // handleIntersection={handleIntersection}
                                        // sessionid={sessionid}
                                    />

                                    {/* {dog.history && dog.history.reverse().map(line => (
                                        <DogHistory stores={this.props.stores} line={line} />
                                    ))} */}

                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                {submenu === 'trophy' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {(!dog.trophys || dog.trophys?.length === 0) && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'><i class='fas fa-award' /></div>
                                    <h3><Text id='dogs.no-trophys-title'>No trophys for this dog!</Text></h3>
                                    <Text id='dogs.no-trophys-text'>You have to add more workouts, pictures, log entries for dogs, etc to earn trophys.</Text>
                                </div>
                            </>}

                            <div class='row'>
                                {dog.trophys && dog.trophys.reverse().map(file => {
                                    const img = mu.getTrophyImage(file.name);
                                    if (!img) {
                                        return '';
                                    }
                                    const textHeader = file.descriptionHeader && file.descriptionHeader[language] ? file.descriptionHeader[language] : '';
                                    const text = file.description && file.description[language] ? file.description[language] : '';
                                    return (
                                        <div class='col-4 mt-3'>
                                            <div class='mb-2'>
                                                <img src={img} class='img-fluid' />
                                            </div>
                                            <strong>{textHeader}</strong>
                                            {/* <small class='font-weight-lighter mr-2'>
                                                {util.isoDate(file.createdDate, false, false, true)}
                                            </small> */}
                                            <small>
                                                <details>
                                                    <summary><Text id='dogs.trophy-details'>Details</Text></summary>
                                                    <Markdown markdown={mu.replaceImages(text, dog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                </details>
                                            </small>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </>}

                {submenu === 'fitness' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <FormGraph stores={this.props.stores} />
                        </div>
                    </div>
                </>}

                {submenu === 'veterinary' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <h5><Text id='dogs.vetenary-approved-vaccines'>Vetenary approved vaccines</Text></h5>

                            {(!dogVaccineLogStatuses || dogVaccineLogStatuses?.length === 0) && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fas fa-syringe' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='dogs.add-veterinary'>Kontakt din veterinær og be om at vaksinene godkjennes i appen.</Text>
                                    </h3>
                                </div>
                            </>}

                            <div class=''>
                                {dogVaccineLogStatuses && dogVaccineLogStatuses.map((vac) => {
                                    return (
                                        <div class='col-12 mt-2 p-3 border rounded-lg bg-light'>

                                            <span class={`float-right badge badge-${vac.inKarens ? 'warning' : 'success'} ml-2 mb-1`}>
                                                <span class='font-weight-lighter ml-2'>
                                                    <i class='fas fa-calendar-check' /> {util.isoDate(vac.vaccineAgens.durationEnd, false, false, true)}
                                                    {vac.inKarens && <>
                                                        <br />
                                                        (<Text id='dogs.vaccine-karens'>karens</Text>: {util.format(vac.karensDaysLeft, 0)} <Text id='dogs.vaccine-karens-days-left'>days left</Text>)
                                                    </>}
                                                </span>
                                            </span>

                                            <h5>{vac.vaccineAgens.name}</h5>
                                            {/* <span class='font-weight-lighter'>
                                                <Text id='dogs.vaccine-name'>Vaksinenavn</Text>:
                                            </span> {vac.vaccineName}<br /> */}
                                            <span class='font-weight-lighter'>
                                                <Text id='dogs.vaccine-date'>Vaksinedato</Text>:
                                            </span> {util.isoDate(vac.date, false, false, true)}<br />
                                            <span class='font-weight-lighter'>
                                                <Text id='dogs.vaccine-veterinary'>Veterinær</Text>:
                                            </span> {vac.veterinaryFirstname} {vac.veterinaryLastname} &lt;{vac.veterinaryEmail}&gt;<br />
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </>}

                {submenu === 'achievements' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {(!dog.achievements || dog.achievements?.length === 0) && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'><i class='fas fa-clock' /></div>
                                    <h3><Text id='dogs.no-achievements-title'>No achievements for this dog!</Text></h3>
                                    <Text id='dogs.no-achievements-text'>You can add achievements to this dog. Ie. Memories, trips, tests, prices, races, etc.</Text>
                                </div>
                            </>}

                            {dog.achievements && dog.achievements.map(file => (
                                <div class='col-12 p-1'>
                                    <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                    <span class='mr-2'>{file.name}</span>
                                    <span class='mr-2'>{file.url}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>}

                {submenu === 'links' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {(!dog.links || dog.links?.length === 0) && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'><i class='fas fa-link' /></div>
                                    <h3><Text id='dogs.no-links-title'>No links for this dog!</Text></h3>
                                    <Text id='dogs.no-links-text'>You can add links to this dog. Ie. Links to Facebook page, Instagram page, blogg, etc.</Text>
                                </div>
                            </>}

                            {dog.links && dog.links.map(file => (
                                <div class='col-12 p-1'>
                                    <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                    <span class='mr-2'>{file.name}</span>
                                    <span class='mr-2'>{file.url}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>}

                {submenu === 'advanced' && <>
                    <div class='row mt-4'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {(!dog.files || dog.files?.length === 0) && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'><i class='fas fa-file' /></div>
                                    <h3><Text id='dogs.no-files-title'>No files attached to this dog!</Text></h3>
                                    <Text id='dogs.no-files-text'>You can upload files to this dog inside edit. These files can be of any type. Ie: Certificates, test, etc.</Text>
                                </div>
                            </>}
                            {dog.files && dog.files.map(file => (
                                <div class='d-flex'>
                                    <span class='font-weight-lighter mr-2'>{util.isoDate(file.createdDate, false, false, true)}</span>
                                    <span class='mr-2'><a href={file.s3Link} target='_blank' rel='noreferrer'>{file.name}</a></span>
                                    <span class='font-weight-lighter mr-2'>{util.formatBytes(file.size, 1)}</span>
                                </div>
                            ))}
                        </div>

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mb-2 mt-5 bg-light py-4 border rounded-lg'>
                            <Localizer>
                                <Input saved={saved} dog={dog} field='files' type='file' icon='fas fa-file'
                                    stores={this.props.stores}
                                    title={<Text id='dogs.files'>Files</Text>}
                                    help={<Text id='dogs.files-help'>Filer til denne hunden.</Text>}
                                    onInput={this.updateField}
                                    updateField={this.updateFieldValue}
                                />
                            </Localizer>
                        </div>

                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <div class='mt-5 pt-5 mb-5'>
                                <button class='btn btn-sm btn-danger mt-5 float-right'
                                    onClick={this.deleteDog}
                                >
                                    <i class='fas fa-trash' /> <Text id='dogs.delete'>Slett</Text> {dog.name}
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
			</div>
		);
	}
}

export default DogsDetail;
