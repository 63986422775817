import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class TeamNavigation extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetProfileLastTrophyList', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Profile'
        });
    }

    render() {

        const { submenu, height, isOverflow, isExpanded, filterText, sessionid, handleIntersection, key } = this.state;
        const { userStore, appState, trophyStore } = this.props.stores;
        const { darkmode } = appState;
		const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
		const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;

        const team = userStore.getCurrentTeam();
        // const lastTrophys = team.trophys?.length > 0 ? team.trophys.sort(mu.fieldSorter(['-createdDate', 'name'])).splice(0, 3) : []

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'}>
            <div class='w-100 position-relative px-3 pb-3 pt-3'>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation'>
                    <span><i class='fa-light fa-paw' /> Dogs</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> People</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Activities</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Stats</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Photos</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Team Trophies</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Team Workout plans</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Journal</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
                <div class='d-flex flex-row justify-content-between align-items-center appNavigation mt-3 pt-3 border-top'>
                    <span><i class='fa-light fa-paw' /> Memories</span>
                    <span><i class='fa-solid fa-angle-right' /></span>
                </div>
            </div>
        </Widget>);
    }
}

export default TeamNavigation;
