import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import ProfileHeadV1 from '../../components/widget/profile/head-v1.js';
import ProfileHeadV2 from '../../components/widget/profile/head-v2.js';
import ProfileDog from '../../components/widget/profile/dog.js';
import ProfileTeam from '../../components/widget/profile/team.js';
import ProfileOtherSections from '../../components/widget/profile/other-sections.js';

import ProfileLastDogLog from '../../components/widget/profile/last-dog-log.js';
import ProfileLastPost from '../../components/widget/profile/last-post.js';
import ProfileLastTrophy from '../../components/widget/profile/last-trophy.js';

import WorkoutTotalKm from '../../components/widget/workout/total-km.js';
import WorkoutFitness from '../../components/widget/workout/fitness.js';
import WorkoutKeyFigures from '../../components/widget/workout/key-figures.js';

import WorkoutGoals from '../../components/widget/workout/goals.js';
import WorkoutIntensity from '../../components/widget/workout/intensity.js';
import WorkoutProgress from '../../components/widget/workout/progress.js';
import WorkoutTotalCalories from '../../components/widget/workout/total-calories.js';
import WorkoutTotalElevation from '../../components/widget/workout/total-elevation.js';
import WorkoutTotalDuration from '../../components/widget/workout/total-duration.js';
import WorkoutTotalRest from '../../components/widget/workout/total-rest.js';
import WorkoutAvgSpeed from '../../components/widget/workout/avg-speed.js';

import DogProfileHead from '../../components/widget/dog/profile-head.js';
import DogNavigation from '../../components/widget/dog/navigation.js';

import TeamProfileHead from '../../components/widget/team/profile-head.js';
import TeamNavigation from '../../components/widget/team/navigation.js';
import TeamPresentation from '../../components/widget/team/presentation.js';

import mu from '../../lib/musher-util';

@observer
class WidgetRouterContent extends Component {
    render() {
        const { widget, key } = this.props;
        if (!widget) {
            return null;
        }
        switch (widget.key) {
            case 'profile-head-v1':
                return <ProfileHeadV1 stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-head-v2':
                return <ProfileHeadV2 stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-dog':
                return <ProfileDog stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-team':
                return <ProfileTeam stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-other-sections':
                return <ProfileOtherSections stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-last-dog-log':
                return <ProfileLastDogLog stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-last-post':
                return <ProfileLastPost stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'profile-last-trophy':
                return <ProfileLastTrophy stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;

            case 'workout-total-km':
                return <WorkoutTotalKm stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-fitness':
                return <WorkoutFitness stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-key-figures':
                return <WorkoutKeyFigures stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-goals':
                return <WorkoutGoals stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-intensity':
                return <WorkoutIntensity stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-progress':
                return <WorkoutProgress stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-total-calories':
                return <WorkoutTotalCalories stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-total-elevation':
                return <WorkoutTotalElevation stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-total-duration':
                return <WorkoutTotalDuration stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-total-rest':
                return <WorkoutTotalRest stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'workout-avg-speed':
                return <WorkoutAvgSpeed stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;

            case 'dog-profile-head':
                return <DogProfileHead stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'dog-navigation':
                return <DogNavigation stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;

            case 'team-profile-head':
                return <TeamProfileHead stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'team-navigation':
                return <TeamNavigation stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;
            case 'team-presentation':
                return <TeamPresentation stores={this.props.stores} {...widget.settings} {...this.props} key={`${widget.key}-${key}`} />;

            default:
                return <>Unknown widget: {widget.key}</>;
        }
    }
}

@observer
class WidgetRouter extends Component {
    render() {
        const { widgets, key } = this.props;
        if (!widgets) {
            return null;
        }
        return (<>
            {widgets.map(widget => {
                // if (widget.settings?.skipGridWrapper) {
                //     return (<>
                //         <WidgetRouterContent widget={widget} key={key} stores={this.props.stores} />
                //     </>);
                // }
                return (<>
                    {/* <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-0 px-0'>
                        <div class='row'> */}
                    <WidgetRouterContent widget={widget} key={key} stores={this.props.stores} {...this.props} />
                        {/* </div>
                    </div> */}
                </>);
            })}
        </>);
    }
}

export default WidgetRouter;
