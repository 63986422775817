import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';

import mu from '../lib/musher-util';

import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

function normalizeRange(val, min, max, newMin, newMax) {
    return newMin + (val - min) * (newMax - newMin) / (max - min);
}

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o=o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

class RaceStore extends StoreModel {
    constructor() {
        super('race', {
            namePlural: 'races',
            sort: 'startDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/races/',
                    params: {
                        limit: 15,
                        sort: 'startDate',
                    },
                },
                load: {
                    url: '/api/races/',
                    params: {},
                },
                save: {
                    url: '/api/races/',
                    params: {},
                },
            },
        });
    }

    @observable newRace = {};

    @observable raceInProgress = [];

    @observable signup = {};

    @observable checkpoint = {};

    @observable newSignup = {};

    @observable signups = [];

    @observable race = {};

    @observable races = [];

    @observable raceUsers = [];

    @observable images = [];

    @observable classes = {};

    @observable teamList = [];

    @observable workoutSummary = [];

    @observable workoutResultsChecksum = '';

    @observable workoutTotal = [];

    @observable activeTeams = [];

    @observable waypoints = [];

    @observable waypointsScooter = [];

    @observable waypointsDNF = [];

    @observable waypointsFinish = [];

    @observable waypoints2 = [];

    @observable waypoints3 = [];

    @observable geoJSONs = [];

    @observable zoomToMarkers = [];

    @observable sortedContestants = [];

    @observable totalClasses = 0;

    @observable totalContestants = 0;

    @observable publicTeams = [];

    @observable publicUsers = [];

    @observable racetrackerData = [];

    @observable racetrackerGeocluster = [];

    @observable raceTrackerResults = {};

    @observable raceTrackerCounter = 0;

    @observable maxPoints = 0;

    @observable totalTrackLength = 0;

    @observable isAdmin = false;

    @observable isRaceAdmin = false;

    @observable isVeterinary = false;

    @observable showTrailForBib = false;

    @observable showRestLocationsForBib = [];

    @action
    setAdmin(isAdmin) {
        this.isAdmin = isAdmin;
        this.apiLoadUrl = '/api/adm-races/';
    }

    @action
    setRaceAdmin(isRaceAdmin) {
        this.isRaceAdmin = isRaceAdmin;
        this.apiLoadUrl = '/api/adm-races/';
    }

    @action
    setVeterinary(isVeterinary) {
        this.isVeterinary = isVeterinary;
        this.apiLoadUrl = '/api/adm-races/';
    }

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('RaceStore.cleanupMemory');
        }
        this.localUpdateField('raceInProgress', []);
        this.localUpdateField('signups', []);
        this.localUpdateField('races', []);
        this.localUpdateField('images', []);
        this.localUpdateField('teamList', []);
        this.localUpdateField('workoutSummary', []);
        this.localUpdateField('activeTeams', []);
        this.localUpdateField('waypoints', []);
        this.localUpdateField('zoomToMarkers', []);
        this.localUpdateField('publicTeams', []);
        this.localUpdateField('publicUsers', []);
        this.localUpdateField('racetrackerData', []);
        this.localUpdateField('racetrackerGeocluster', []);
        this.localUpdateField('maxPoints', 0);
        this.localUpdateField('totalTrackLength', 0);
        this.localUpdateField('waypoints', []);
        this.localUpdateField('waypointsDNF', []);
        this.localUpdateField('waypointsFinish', []);
        this.localUpdateField('waypoints2', []);
        this.localUpdateField('waypoints3', []);
        this.localUpdateField('geoJSONs', []);
    }

    @action
    cleanupMemoryClass() {
        if (isDevelopment) {
            console.log('RaceStore.cleanupMemoryClass');
        }
        // this.localUpdateField('raceInProgress', []);
        // this.localUpdateField('signups', []);
        // this.localUpdateField('races', []);
        // this.localUpdateField('images', []);
        this.localUpdateField('teamList', []);
        this.localUpdateField('racetrackerData', []);
        this.localUpdateField('racetrackerGeocluster', []);
        this.localUpdateField('waypoints', []);
        this.localUpdateField('waypointsDNF', []);
        this.localUpdateField('waypointsFinish', []);
        this.localUpdateField('waypoints2', []);
        this.localUpdateField('waypoints3', []);
        this.localUpdateField('geoJSONs', []);
        this.localUpdateField('maxPoints', 0);
        this.localUpdateField('totalTrackLength', 0);
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    localUpdateSignup(key, value) {
        this.signup[key] = value;
    }

    @action
    localUpdateCheckpoint(key, value) {
        this.checkpoint[key] = value;
    }

    @action
    findPublicTeam(team) {
        const idx = this.publicTeams?.findIndex(e => e.id === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByUuidv4(team) {
        const idx = this.publicTeams?.findIndex(e => e.uuidv4 === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByMembers(user) {
        if (!user) {
            return [];
        }
        const teams = this.publicTeams.filter(e => e.members?.indexOf(user) > -1);
        return teams;
    }

    @action
    findPublicUser(user) {
        if (!this.publicUsers) {
            return {};
        }
        const idx = this.publicUsers?.findIndex(e => e.id === user);
        if (idx > -1) {
            return this.publicUsers[idx];
        }
    }

    getTotalRaceTimes({ contestant, checkpoints, allResults, startDate }) {
        if (!contestant || !contestant || !allResults) {
            return null;
        }

        let totalMovingTime = 0;
        let totalDistance = 0;
        let totalInCpTime = 0;
        let lastEstTimeOut = startDate;
        let lastResOut;
        let lastResIn;

        checkpoints.forEach((cp, cpIdx) => {
            const resIn = allResults.slice().find(e => e.checkpoint === cp.id && e.direction === 'in') || {};
            const resOut = allResults.slice().find(e => e.checkpoint === cp.id && e.direction === 'out') || {};
            const resScratched = allResults.slice().find(e => e.checkpoint === cp.id && e.direction === 'scratched') || {};
            let timeInCp = 0;
            if (!cp.isStartLine && resIn && resOut && resIn.timestamp && resOut.timestamp) {
                timeInCp = mu.deltaSec(resIn.timestamp, resOut.timestamp);
            }
            let movingTime = 0;
            if (lastResOut && resIn && lastResOut.timestamp && resIn.timestamp) {
                movingTime = mu.deltaSec(lastResOut.timestamp, resIn.timestamp);
            }

            totalDistance += cp.distanceFromPrev ? parseInt(cp.distanceFromPrev, 10) : 0;
            totalMovingTime += movingTime ? movingTime : 0;
            totalInCpTime += timeInCp ? timeInCp : 0;
            lastEstTimeOut = resOut.timestamp;
            lastResOut = resOut;
            lastResIn = resIn;
        });
        const percentRest = totalMovingTime ? totalInCpTime / totalMovingTime * 100 : 0;
        return { totalMovingTime, totalDistance, totalInCpTime, percentRest };
    }

    @action
    sortContestantsByResult({ contestants, results, checkpoints, sortOrderResults = 'cpTimestamp', startDate }) {
        const maxStartTime = Math.max(...contestants.map(e => e.startTime ? new Date(e.startTime).getTime() : 0));
        const now = new Date().getTime();
        this.sortedContestants = contestants.slice().map(co => {
            const allResults = results.slice()
                .filter(e => parseInt(e.bib, 10) == co.bib)
                .sort(fieldSorter(['-timestamp']));
            const hasScratched = allResults?.findIndex(e => e.direction === 'scratched');
            const res = allResults[0] || {};
            const newCo = { ...co };
            newCo.maxStartTime = maxStartTime;
            newCo.startEpoch = newCo.startTime ? new Date(newCo.startTime).getTime() : 0;
            newCo.hasStarted = now > newCo.startEpoch;
            newCo.timeEqualization = Math.floor((newCo.startEpoch > 0 ? maxStartTime - newCo.startEpoch : 0) / 1000 / 60);

            const { totalMovingTime, totalDistance, totalInCpTime, percentRest } = this.getTotalRaceTimes({
                contestant: co,
                checkpoints,
                allResults,
                startDate,
            });
            if (res) {
                const cp = checkpoints.find(e => e.id === res.checkpoint) || {};
                newCo.cpId = res.checkpoint || null;
                newCo.cpDirection = res.direction || null;
                newCo.cpDogs = res.dogs || null;
                newCo.cpTimestamp = (cp.isStartLine ? co.startTime : res.timestamp) || null;
                newCo.cpSortOrder = hasScratched > -1 ? -100 + cp.sortOrder : cp.sortOrder || null;
                newCo.cpName = cp.name || null;
                newCo.cpNextName = cp.nextName || null;
                newCo.cpNextId = cp.nextId || null;
                newCo.totalMovingTime = totalMovingTime;
                newCo.totalDistance = totalDistance;
                newCo.totalInCpTime = totalInCpTime;
                newCo.percentRest = percentRest;
            } else {
                newCo.cpSortOrder = 0;
            }
            return newCo;
        }).sort(fieldSorter(['-cpSortOrder', '-cpDirection', sortOrderResults, 'bib']));

        // let cpIdx = 0;
        // let hasLeftCpPrev;
        // let bibIdxOutPrev;
        // let estTimeToCpMinutesPrev;
        // let hasScratchedPrev;

        // if (checkpoints && checkpoints.map((cp, idx) => {
        //     const res = results.slice().sort(fieldSorter(['timestamp'])).filter(e => parseInt(e.bib, 10) == co.bib && e.checkpoint === cp.id);
        //     const hasLeftCp = res.find(e => e.direction === 'out');
        //     const hasArrivedCp = res.find(e => e.direction === 'in');
        //     const hasScratched = res.find(e => e.direction === 'scratched');

        //     const allResultsIn = results.slice().sort(fieldSorter(['timestamp'])).filter(e => (e.direction === 'in' || e.direction === 'scratched') && e.checkpoint === cp.id);
        //     const bibIdxIn = allResultsIn?.findIndex(e => parseInt(e.bib, 10) == co.bib);

        //     const allResultsOut = results.slice().sort(fieldSorter(['timestamp'])).filter(e => e.direction === 'out' && e.checkpoint === cp.id);
        //     const bibIdxOut = allResultsOut?.findIndex(e => parseInt(e.bib, 10) == co.bib);

    }

    async loadPublicInteractions(race) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${race}/public-interactions`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('publicTeams', response.included.publicTeams);
                this.localUpdateField('publicUsers', response.included.publicUsers);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateRaceInProgress(data) {
        this.raceInProgress = data;
    }

    async loadRaceInProgress() {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/`, { publish: true, method: 'GET' }, { inProgress: 1 });
        switch (response.status) {
            case 200:
                this.updateRaceInProgress(response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    countAll() {
        this.totalClasses = 0;
        this.totalContestants = 0;
        if (this.race.classes) {
            this.totalClasses = this.race.classes.length;
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				// for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                if (currentClass.contestants) {
                    this.totalContestants += currentClass.contestants.length;
                }
            }
        }
    }

    async lockAllWorkoutsInRace({ id, classid }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/lock-all-workouts/${id}`, { publish: true, method: 'PATCH' }, { classid });
        switch (response.status) {
            case 200:
                console.log({ response });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    hasVaccines(team) {
        let hasVaccines = false;
        if (team && team.dogs) {
            team.dogs.forEach((dog) => {
                if (team.vaccines) {
                    const dogVaccines = team.vaccines.filter(v => v.chipId === dog.chipId);
                    if (dogVaccines && dogVaccines.length > 0) {
                        hasVaccines = true;
                    }
                }
            });
        }
        return hasVaccines;
    }

    hasValidVaccines(vaccines, dog, agens) {
        let hasValidVaccines = false;
        if (vaccines && vaccines.length > 0) {
            const dogVaccines = vaccines.filter(v => v.chipId === dog.chipId);
            if (dogVaccines && dogVaccines.length > 0) {
                const validVaccines = dogVaccines.filter(v => {
                    return v.vaccineAgens.name === agens && v.durationDaysLeft > 0 && v.karensDaysLeft === 0;
                });
                // console.log({ validVaccines });
                if (validVaccines && validVaccines.length > 0) {
                    hasValidVaccines = true;
                }
                // Parvovirus,Hepatitt, Valpesyke
            }
        }
        return hasValidVaccines;
    }

    @action
    getAllImages() {
        const images = [];
		if (this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
					const musher = currentClass.contestants[j];
					const currentTeam = this.getTeamById(musher.team);
					const currentStages = this.getWorkoutSummaryByTeam(musher.team);
					const currentResults = this.getWorkoutResultsByTeam(currentStages, currentClass.distance);
					if (currentResults && currentResults.images) {
						for (let k = 0, n = currentResults.images.length; k < n; k += 1) {
							const image = toJS(currentResults.images[k]);
							image.class = toJS(currentClass);
							image.musher = toJS(musher);
							image.currentTeam = toJS(currentTeam);
                            image.date = new Date(image.createdDate);
							images.push(image);
						}
					}
					if (currentTeam && currentTeam.images) {
						for (let k = 0, n = currentTeam.images.length; k < n; k += 1) {
							const image = toJS(currentTeam.images[k]);
							image.class = toJS(currentClass);
							image.musher = toJS(musher);
							image.currentTeam = toJS(currentTeam);
                            image.date = new Date(image.createdDate);
							images.push(image);
						}
					}
				}
			}
		}
        if (this.race.userImages) {
            for (let i = 0, l = this.race.userImages.length; i < l; i += 1) {
                const userImage = this.race.userImages[i] || [];
                const image = {
                    title: userImage.title,
                    body: userImage.body,
                    createdDate: userImage.date,
                    class: {},
                    musher: {},
                    currentTeam: {},
                    ...userImage.image
                }
                image.date = new Date(userImage.date);
                images.push(image);
            }
        }
        this.images = images.sort((a, b) => a.date - b.date).reverse();
    }

    getPreviousCheckpoint({ currentClass, checkpointId }) {
        if (currentClass && currentClass.checkpoints) {
            const cpIdx = currentClass.checkpoints?.findIndex(e => e.id === checkpointId);
            if (cpIdx > 0) {
                return currentClass.checkpoints[cpIdx - 1];
            }
        }
    }

    @action
    sortCps() {
        if (this.race.classes) {
            this.race.classes = this.race.classes.sort(fieldSorter(['distance']));
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
                if (currentClass && currentClass.checkpoints) {
                    // currentClass.checkpoints.sort(fieldSorter(['sortOrder']));
                    currentClass.checkpoints = currentClass.checkpoints.sort((a, b) => a.sortOrder - b.sortOrder);
// console.log(currentClass.checkpoints.map(e => e.sortOrder));
                }
            }
        }
    }

    @action
    getAllClassesSortedBy() {
        const classes = {};
        if (this.race.classes) {
            this.race.classes = this.race.classes.sort(fieldSorter(['distance']));
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
                currentClass.totalMushers = 0;
                currentClass.totalFinished = 0;
                if (currentClass && currentClass.checkpoints) {
                    currentClass.checkpoints = currentClass.checkpoints.sort((a, b) => a.sortOrder - b.sortOrder);
                }
                if (currentClass.checkpoints && currentClass.checkpoints.length > 0) {
                    let prevCp;
                    const raceDistance = currentClass.checkpoints.map(e => e.distanceFromPrev ? e.distanceFromPrev : 0).reduce((acc, e) => acc + e, 0);
                    let totalDistance = 0;
                    currentClass.checkpoints.forEach((cp, idx) => {
                        totalDistance += cp.distanceFromPrev ? cp.distanceFromPrev : 0;
                        cp.totalDistance = totalDistance;
                        cp.distanceToFinish = raceDistance - totalDistance;
                        const nextCp = currentClass.checkpoints[idx + 1] ? currentClass.checkpoints[idx + 1] : {};
                        cp.distanceToNext = nextCp.distanceFromPrev;
                        if (nextCp) {
                            cp.nextId = nextCp.id;
                            cp.nextName = nextCp.name;
                            cp.nextSortOrder = nextCp.sortOrder;
                        }
                        if (prevCp) {
                            cp.prevId = prevCp.id;
                            cp.prevName = prevCp.name;
                            cp.prevSortOrder = prevCp.sortOrder;
                        }
                        prevCp = { ...cp };
                    });
                }

                if (currentClass.results && currentClass.results.length > 0) {
                    currentClass.results.sort(fieldSorter(['timestamp'])).forEach((res, idx) => {
                        if (res.direction === 'in') {
                            const cp = currentClass.checkpoints ? currentClass.checkpoints.find(e => e.id === res.checkpoint) : null;
                            if (cp) {
                                const prevResOut = currentClass.results.find(e => e.direction === 'out' && e.checkpoint === cp.prevId && e.bib === res.bib);
                                if (prevResOut) {
                                    res.legTime = mu.deltaSec(prevResOut.timestamp, res.timestamp);
                                }
                            }
                        }
                    });
                }

				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
					const musher = currentClass.contestants[j];
					const currentTeam = this.getTeamById(musher.team);
					const currentStages = this.getWorkoutSummaryByTeam(musher.team, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
					const totals = this.getTotalsByTeamId(musher.team);
					const currentResults = this.getWorkoutResultsByTeam(currentStages, currentClass.distance);
                    const completed = currentResults.distanceKm / currentClass.distance * 100;
                    const speedAvg = currentResults.speedAvg || 0;
                    let raceDistance = completed >= 99 ? currentClass.distance : currentResults.distanceKm;
                    const hasStarted = completed > 0 ? 1 : 0;
                    let isDone = completed >= 99 ? 1 : 0;

                    currentClass.totalMushers += 1;
                    if (isDone) {
                        currentClass.totalFinished += 1;
                    }

                    // const normalizedDuration = currentResults.duration - (currentResults.loadIndex);
                    // const normalizedDuration = normalizeRange(val, 0, max, 0, newMax);currentResults.duration - (currentResults.loadIndex);
                    const loadIndex = currentResults.loadIndex;
                    const weightedTotalKcal = currentResults.weightedTotalKcal;
                    const flatTotalKcal = currentResults.flatTotalKcal;
                    const duration = currentResults.duration;

                    const normalizedDuration = (currentResults.duration - currentResults.rest) / completed * 100;
                    const normalizedKcal = weightedTotalKcal / completed * 100;
                    const normalizedFlatKcal = flatTotalKcal / completed * 100;

                    // const weightedNormalizedDuration = normalizedDuration - (normalizedKcal  - normalizedFlatKcal + currentResults.elevation);
                    let weightedNormalizedDuration = normalizedDuration - (currentResults.elevation / 2);

                    const totalKcalRelated = currentResults.totalKcalRelated;
                    let percentHarder;
                    if (weightedTotalKcal && flatTotalKcal) {
                        percentHarder = weightedTotalKcal / flatTotalKcal * 100;
                    }

                    const totalWorkoutTimeUsedSec = totals.totalWorkoutTimeUsedSec;
                    const finishedDate = new Date(totals.firstEpoch + totals.totalWorkoutTimeUsedSec * 1000);

                    // TODO: THIS IS DEMO ONLY!
                    const rests = [];
                    const restsSplit = [];
                    const totalCompleted = mu.randomIntFromInterval(1, 30)
                    for (let a = 0, b = 10; a < b; a += 1) {
                        rests.push(mu.randomIntFromInterval(1, 20));
                        restsSplit.push(mu.randomIntFromInterval(15, 95));
                    }

                    if (['DNS', 'DNF', 'DNQ', 'DQ', 'DC'].indexOf(musher.raceStatus) > -1) {
                        isDone = 0;
                        raceDistance = 0;
                        weightedNormalizedDuration = 0;
                    }

					if (musher) {
                        if (!classes[currentClass.id] || !classes[currentClass.id].contestants) {
                            classes[currentClass.id] = {
                                contestants: [],
                            };
                        }
                        classes[currentClass.id].contestants.push({
                            musher,
                            currentTeam,
                            currentStages,
                            currentResults,
                            completed,
                            raceDistance,
                            hasStarted,
                            isDone,
                            duration,
                            normalizedDuration,
                            normalizedKcal,
                            normalizedFlatKcal,
                            weightedNormalizedDuration,
                            totalKcalRelated,
                            weightedTotalKcal,
                            flatTotalKcal,
                            loadIndex,
                            percentHarder,
                            speedAvg,
                            rests,
                            restsSplit,
                            totalCompleted,
                            totalWorkoutTimeUsedSec,
                            finishedDate,
                        });
					}
				}
			}
		}

        const classKeys = Object.keys(classes);
        for (let i = 0, l = classKeys.length; i < l; i += 1) {
            const key = classKeys[i];
            const currentClass = classes[key];
            if (this.race.nonstop) {
                currentClass.contestants = currentClass.contestants.sort(fieldSorter(['isDone', 'raceDistance', '-totalWorkoutTimeUsedSec'])).reverse();
            } else {
                // currentClass.contestants = currentClass.contestants.sort(fieldSorter(['isDone', 'raceDistance', '-weightedNormalizedDuration'])).reverse();
                currentClass.contestants = currentClass.contestants.sort(fieldSorter(['isDone', 'raceDistance', '-normalizedDuration'])).reverse();
            }

            // currentClass.contestants = currentClass.contestants.sort(fieldSorter(['isDone', 'speedAvg', '-weightedNormalizedDuration'])).reverse();
            // currentClass.contestants = currentClass.contestants.sort(fieldSorter(['isDone', '-weightedNormalizedDuration'])).reverse();
        }

        this.classes = classes;
    }

    @action
    removeClassLocal({ id, classId }) {
        if (util.isArray(this.race.classes)) {
            const idx = this.race.classes?.findIndex(e => e.id === classId);
            if (idx > -1) {
                this.race.classes.splice(idx, 1);
            }
        }
        const widx = this.races?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.races[widx].classes?.findIndex(e => e.id === classId);
            if (idx > -1) {
                this.races[widx].classes.splice(idx, 1);
            }
        }
    }

    async removeClass({ id, classId: removeClassById }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { removeClassById });
        switch (response.status) {
            case 202:
                this.removeClassLocal({ id, classId: removeClassById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addClassLocal({ id, newClass }) {
        if (util.isArray(this.race.classes)) {
            this.race.classes.push(newClass);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.races[idx].classes)) {
                this.races[idx].classes = [];
            }
            this.races[idx].classes.push(newClass);
        }
    }

    async addClass({ id, newClass }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { newClass });
        switch (response.status) {
            case 202:
                this.addClassLocal({ id, newClass });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateClassesLocal(id, classes) {
        // console.log('updateClassesLocal', id, classes);
        this.race.classes = classes;
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.races[idx].classes = classes;
        }
    }

    @action
    updateClassesResultLocal(classId, resultId, field, value) {
        // console.log('updateClassesResultLocal', classId, resultId, field, value);
        const classIdx = this.race.classes?.findIndex(e => e.id === classId);
        // console.log({ classIdx });
        if (classIdx > -1 && this.race.classes[classIdx] && this.race.classes[classIdx].results) {
            const resultIdx = this.race.classes[classIdx].results?.findIndex(e => e.id === resultId);
            // console.log({ resultIdx });
            if (resultIdx > -1) {
                this.race.classes[classIdx].results[resultIdx][field] = value;
            }
        }
    }

    async editClass({ id, data }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { ...data });
        switch (response.status) {
            case 202:
                // console.log({ response });
                this.updateClassesLocal(id, response.data.classes);
                // this.addCommentToRace({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }



    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    @action
    removeArticleLocal({ id, articleId }) {
        if (util.isArray(this.race.articles)) {
            const idx = this.race.articles?.findIndex(e => e.id === articleId);
            if (idx > -1) {
                this.race.articles.splice(idx, 1);
            }
        }
        const widx = this.races?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.races[widx].articles?.findIndex(e => e.id === articleId);
            if (idx > -1) {
                this.races[widx].articles.splice(idx, 1);
            }
        }
    }

    async removeArticle({ id, articleId: removeArticleById }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { removeArticleById });
        switch (response.status) {
            case 202:
                this.removeArticleLocal({ id, articleId: removeArticleById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addArticleLocal({ id, newArticle }) {
        if (util.isArray(this.race.articles)) {
            this.race.articles.push(newArticle);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.races[idx].articles)) {
                this.races[idx].articles = [];
            }
            this.races[idx].articles.push(newArticle);
        }
    }

    async addArticle({ id, newArticle }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { newArticle });
        switch (response.status) {
            case 202:
                this.addArticleLocal({ id, newArticle });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateArticlesLocal(id, articles) {
        console.log('updateArticlesLocal', id, articles);
        this.race.articles = articles;
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.races[idx].articles = articles;
        }
    }

    async editArticle({ id, data }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { ...data });
        switch (response.status) {
            case 202:
                // console.log({ response });
                this.updateArticlesLocal(id, response.data.articles);
                // this.addCommentToRace({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    @action
    removeUserImageLocal({ id, userImageId }) {
        if (util.isArray(this.race.userImages)) {
            const idx = this.race.userImages?.findIndex(e => e.id === userImageId);
            if (idx > -1) {
                this.race.userImages.splice(idx, 1);
            }
        }
        const widx = this.races?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.races[widx].userImages?.findIndex(e => e.id === userImageId);
            if (idx > -1) {
                this.races[widx].userImages.splice(idx, 1);
            }
        }
    }

    async removeUserImage({ id, userImageId: removeUserImageById }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { removeUserImageById });
        switch (response.status) {
            case 202:
                this.removeUserImageLocal({ id, userImageId: removeUserImageById });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addUserImageLocal({ id, newUserImage }) {
        if (util.isArray(this.race.userImages)) {
            this.race.userImages.push(newUserImage);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.races[idx].userImages)) {
                this.races[idx].userImages = [];
            }
            this.races[idx].userImages.push(newUserImage);
        }
    }

    async addUserImage({ id, newUserImage }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { newUserImage });
        switch (response.status) {
            case 202:
                this.addUserImageLocal({ id, newUserImage });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateUserImagesLocal(id, userImages) {
        console.log('updateUserImagesLocal', id, userImages);
        this.race.userImages = userImages;
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.races[idx].userImages = userImages;
        }
    }

    async editUserImage({ id, data }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { ...data });
        switch (response.status) {
            case 202:
                // console.log({ response });
                this.updateUserImagesLocal(id, response.data.userImages);
                // this.addCommentToRace({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


    @action
    addSignup(signup) {
        this.signups.push(signup);
    }

    @action
    resetSignup() {
        this.signups = [];
    }

    @action
    addResultLocal({ id, classId, newResult }) {
        this.signup.id = util.randomPassword();
        if (util.isArray(this.race.classes)) {
            const classIdx = this.race.classes?.findIndex(e => e.id === classId);
            if (classIdx > -1) {
                if (util.isUndefined(this.race.classes[classIdx].results)) {
                    this.race.classes[classIdx].results = [];
                }
                this.race.classes[classIdx].results.push(newResult);
            }
        }
        // const idx = this.races?.findIndex(e => e.id === id);
        // if (idx > -1) {
        //     const classIdx = this.races[idx].classes?.findIndex(e => e.id === classId);
        //     if (classIdx > -1) {
        //         if (util.isUndefined(this.races[idx].classes[classIdx].results)) {
        //             this.races[idx].classes[classIdx].results = [];
        //         }
        //         this.races[idx].classes[classIdx].results.push(newResult);
        //     }
        // }
    }

    async addResult({ id, classId, newResult }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, {
            raceClassId: classId,
            raceClassResultNew: { ...newResult },
        });
        switch (response.status) {
            case 202:
                this.addResultLocal({ id, classId, newResult });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addCheckpointLocal({ id, classId, newCheckpoint }) {
        this.signup.id = util.randomPassword();
        if (util.isArray(this.race.classes)) {
            const classIdx = this.race.classes?.findIndex(e => e.id === classId);
            if (classIdx > -1) {
                if (util.isUndefined(this.race.classes[classIdx].checkpoints)) {
                    this.race.classes[classIdx].checkpoints = [];
                }
                this.race.classes[classIdx].checkpoints.push(newCheckpoint);
            }
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            const classIdx = this.races[idx].classes?.findIndex(e => e.id === classId);
            if (classIdx > -1) {
                if (util.isUndefined(this.races[idx].classes[classIdx].checkpoints)) {
                    this.races[idx].classes[classIdx].checkpoints = [];
                }
                this.races[idx].classes[classIdx].checkpoints.push(newCheckpoint);
            }
        }
    }

    async addCheckpoint({ id, classId, newCheckpoint }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, {
            raceClassId: classId,
            raceClassCheckpointNew: { ...newCheckpoint },
        });
        switch (response.status) {
            case 202:
                this.addCheckpointLocal({ id, classId, newCheckpoint });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addContestantLocal({ id, classId, newContestant }) {
        this.signup.id = util.randomPassword();
        if (util.isArray(this.race.classes)) {
            const classIdx = this.race.classes?.findIndex(e => e.id === classId);
            if (classIdx > -1) {
                if (util.isUndefined(this.race.classes[classIdx].contestants)) {
                    this.race.classes[classIdx].contestants = [];
                }
                this.race.classes[classIdx].contestants.push(newContestant);
            }
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            const classIdx = this.races[idx].classes?.findIndex(e => e.id === classId);
            if (classIdx > -1) {
                if (util.isUndefined(this.races[idx].classes[classIdx].contestants)) {
                    this.races[idx].classes[classIdx].contestants = [];
                }
                this.races[idx].classes[classIdx].contestants.push(newContestant);
            }
        }
    }

    async addContestant({ id, classId, newContestant }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, {
            raceClassId: classId,
            raceClassNewContestant: { ...newContestant },
        });
        switch (response.status) {
            case 202:
                this.addContestantLocal({ id, classId, newContestant });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async addMultipleContestants({ id, classId, contestants }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, {
            raceClassId: classId,
            raceClassNewMultipleContestants: contestants,
        });
        switch (response.status) {
            case 202:
                // contestants.forEach(newContestant => this.addContestantLocal({ id, classId, newContestant }));
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async editContestant({ id, classId, bib, field, value }) {
        const contestant = this.getMusherByBibId(bib);
        const { id: contestantId } = contestant;
        const data = {
            raceClassId: classId,
            contestantId,
            [`raceClassContestant${util.ucfirst(field)}`]: value,
        };
        this.editClass({
            id,
            data,
        });
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.race.images)) {
            const idx = this.race.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.race.images.splice(idx, 1);
            }
        }
        const widx = this.races?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.races[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.races[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    getTeamById(id) {
        const idx = this.teamList?.findIndex(e => e.id === id);
        if (idx > -1) {
            return this.teamList[idx];
        }
        return {};
    }

    getClassByTeamId(id) {
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (id === musher.team) {
                        return currentClass;
                    }
                }
            }
        }
        return {};
    }

    getClassByBib(bib) {
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (bib === musher.bib) {
                        return currentClass;
                    }
                }
            }
        }
        return {};
    }

    getClassById(id) {
        if (this.race && this.race.classes) {
            const currentClass = this.race.classes.find(e => e.id === id);
            return currentClass;
        }
        return {};
    }

    getSignupByUserEmail(email) {
        const result = [];
        if (this.raceInProgress && this.raceInProgress.length > 0) {
			for (let i = 0, l = this.raceInProgress.length; i < l; i += 1) {
                const currentRace = this.raceInProgress[i];

                if (currentRace.classes) {
                    for (let j = 0, m = currentRace.classes.length; j < m; j += 1) {
                        const currentClass = currentRace.classes[j] || [];
                        const musher = currentClass.contestants.find(e => e.email === email);
                        if (musher && musher.email) {
                            result.push({
                                race: currentRace,
                                class: currentClass,
                                musher,
                            });
                        }
                    }
                }

            }
        }
        return result;
    }

    getMusherByTeamId(id) {
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (id === musher.team) {
                        return musher;
                    }
                }
            }
        }
        return {};
    }

    getMusherById(id) {
        const inputId = id;
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (inputId === musher.id) {
                        return musher;
                    }
                }
            }
        }
        return {};
    }

    getMusherByBibId(id) {
        const inputBib = parseInt(id, 10);
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (musher && inputBib === musher.bib) {
                        return musher;
                    }
                }
            }
        }
        return {};
    }

    getMusherByBibEmail(email) {
        if (this.race && this.race.classes) {
			for (let i = 0, l = this.race.classes.length; i < l; i += 1) {
				const currentClass = this.race.classes[i] || [];
				for (let j = 0, m = currentClass.contestants.length; j < m; j += 1) {
                    const musher = currentClass.contestants[j];
                    if (email === musher.email) {
                        return musher;
                    }
                }
            }
        }
        return {};
    }

    getDogPositionByChipId(chipId, classId, contestantId) {
        // console.log({ chipId, classId, contestantId });
        if (this.race && this.race.classes) {
            if (classId) {
                const currentClass = this.race.classes.find(e => e.id === classId);
                // console.log({ currentClass });
                if (currentClass && currentClass.contestants) {
                    const currentContestant = currentClass.contestants.find(e => e.id === contestantId);
                    // console.log('bib', currentContestant.bib);
                    const dogLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                        'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'R1', 'R2'].find(e => {
                        return currentContestant[`dog${e}`] === chipId;
                    });
                    return dogLetter;
                }
            }
        }
    }

    getTrackIdsByClass(classId) {
        if (this.race && this.race.classes) {
            if (classId) {
                return this.race.classes.filter(e => e.track).filter(e => e.id === classId).map(e => e.track);
            }
            return this.race.classes.filter(e => e.track).map(e => e.track);
        }
        return [];
    }

    getTotalsByTeamId(teamId, type = [2]) {
        if (util.isArray(this.workoutTotal)) {
            const workoutTotal = this.workoutTotal.filter(e => e.team === parseInt(teamId, 10) && type.includes(e.type));
            if (workoutTotal.length > 0) {
                return workoutTotal[0];
            }
        }
        return {};
    }

    getRaceClassById(id) {
        if (this.race.classes && this.race.classes.length > 0) {
            const idx = this.race.classes?.findIndex(e => e.id === id);
            if (idx > -1) {
                return this.race.classes[idx];
            }
        }
        return {};
    }

    @action
    updateTeamList(teamList, concat) {
        if (util.isArray(teamList)) {
            if (concat) {
                this.teamList = this.teamList.concat(teamList);
            } else {
                this.teamList = teamList;
            }
        }
    }

    @action
    getWorkoutSummaryByTeam(teamId, type = [2]) {
        const workoutSummary = this.workoutSummary.filter(e => e.team === teamId && type.includes(e.type));
        return workoutSummary;
    }

    getActiveTeamById(teamId) {
        const idx = this.activeTeams?.findIndex(e => e.team === teamId);
        if (idx > -1) {
            if (!this.activeTeams[idx].currentLocation) {
                this.activeTeams[idx].currentLocation = {
                    coords: {},
                    battery: {},
                };
            }
            return this.activeTeams[idx];
        }
        return {};
    }

    @action
    getWorkoutSummaryActiveTeams(teamIds, focusOnMushers, type = [2]) {
        this.activeTeams = this.workoutSummary.filter(e => e.isInProgress > 0 && e.team && type.includes(e.type)).map((line) => {
            if (!line.currentLocation) {
                line.currentLocation = {
                    coords: {},
                    battery: {},
                };
            }
            return line;
        }).sort((a, b) => a.distance - b.distance).reverse()
        this.waypoints = [];
        this.zoomToMarkers = [];

        if (this.activeTeams && this.activeTeams.length > 0) {
            for (let i = 0, l = this.activeTeams.length; i < l; i += 1) {
                const line = this.activeTeams[i];
                const team = this.getTeamById(line.team);
                const musher = this.getMusherByTeamId(line.team);
                const user = team.members ? team.members.find(m => m.email === musher.email) : {};
                const image = mu.getImage({ user, team, priority: 'user' });
                const currentSpeed = util.format(line.currentLocation.coords.speed * 3.6, 1);
                const heading = mu.windDirection(line.currentLocation.coords.heading);
                if (line.currentLocation.coords.latitude && line.currentLocation.coords.longitude) {
                    let color = line.isInProgress ? '#6DEB38' : '#F90012';
                    const lastUpdated = line.currentLocation.timestamp;
                    const lastUpdatedEpoch = Math.floor(new Date(lastUpdated).getTime() / 1000);
                    const now = Math.floor(new Date().getTime() / 1000);
                    const secSinceLastUpdate = now - lastUpdatedEpoch;
                    line.secSinceLastUpdate = secSinceLastUpdate;
                    if (secSinceLastUpdate > 1800) {
                        color = '#F90012';
                    } else if (secSinceLastUpdate > 900) {
                        color = '#FC8B08';
                    } else {
                        color = '#6DEB38';
                    }

                    // : `${team.name} (🐕 ${line.avgDogs})`,
                    const title = `${musher.firstname} ${musher.lastname}`;
                    // : `Current speed: ${currentSpeed} km/t<br /> Distance: ${util.format(line.distanceKm, 1)} km<br /> 🧭 ${heading} - ${line.currentLocation.coords.altitude} moh`,
                    const body = `${currentSpeed} km/t, ${util.format(line.distanceKm, 1)} km 🧭 ${heading}`;
                    const wp = {
                        lat: line.currentLocation.coords.latitude,
                        lng: line.currentLocation.coords.longitude,
                        bib: musher.bib,
                        rank: line.rank || i + 1,
                        email: musher.email,
                        image,
                        icon: null,
                        title,
                        body,
                        color,
                    };
                    this.waypoints.push(wp);
                    if (focusOnMushers && focusOnMushers.length > 0) {
                        if (focusOnMushers?.indexOf(musher.id) > -1) {
                            this.waypoints.push(wp);
                            this.zoomToMarkers.push(wp);
                        }
                    } else if (teamIds) {
                        if (teamIds?.indexOf(team.id) > -1) {
                            this.zoomToMarkers.push(wp);
                        }
                    } else {
                        this.zoomToMarkers.push(wp);
                    }
                }
            }
        }
    }

    @action
    toggleTrailForBib(bib) {
        if (this.showTrailForBib === bib) {
            this.showTrailForBib = null;
        } else {
            this.showTrailForBib = bib;
        }
    }

    @action
    toggleRestLocationsForBib(bib) {
        const index = this.showRestLocationsForBib.indexOf(bib)
        if (index >= 1) {
            this.showRestLocationsForBib.splice(index, 1);
        } else {
            this.showRestLocationsForBib.push(bib);
        }
    }

    @action
    getRaceTrackerActiveTeams(teamIds, focusOnMushers, bibs = [], loadAllStatuses) {
        // {
        //     "_id" : ObjectId("61e572e7146bf909b41dbc21"),
        //     "id" : 251,
        //     "__v" : 0,
        //     "bib" : 2,
        //     "createdDate" : ISODate("2022-01-17T13:45:11.292Z"),
        //     "data" : {
        //         "bib" : 2,
        //         "timestamp" : "2022-01-17T13:43:11.000Z",
        //         "lat" : 52.203575,
        //         "long" : 0.13172999999999999,
        //         "speed" : 0,
        //         "race" : "test_mush"
        //     },
        //     "lat" : 52.203575,
        //     "long" : 0.13172999999999999,
        //     "race" : "test_mush",
        //     "timestamp" : 1642426991,
        //     "updatedDate" : ISODate("2022-01-17T13:45:11.292Z"),
        //     "uuidv4" : "c6ca3ce3-b562-430e-81cf-e528f60e262f"
        // }
        // this.racetrackerData
        this.raceTrackerCounter += 1;
        this.waypoints = [];
        this.waypointsScooter = [];
        this.waypointsDNF = [];
        this.waypointsFinish = [];
        this.zoomToMarkers = [];
        this.geoJSONs = [];
        try {
            if (this.racetrackerData && this.racetrackerData.length > 0) {
                for (let i = 0, l = this.racetrackerData.length; i < l; i += 1) {
                    const line = this.racetrackerData[i];
                    if (line.meta && line.meta.geoJSON) {
                        this.geoJSONs.push(line.meta.geoJSON);
                    }
                    // if (line.points) {
                    //     const newPoints = [];
                    //     allEpochs.forEach((epoch) => {
                    //         const el = line.points.find(e => e[2] === epoch);
                    //         if (el) {
                    //             newPoints.push(el);
                    //         } else {
                    //             newPoints.push([null, null, epoch]);
                    //         }
                    //     });
                    //     line.points = newPoints;
                    // }
                    const musher = this.getMusherByBibId(line.bib);
                    const team = this.getTeamById(musher.team);
                    const user = team.members ? team.members.find(m => m?.email === musher.email) : {};
                    const image = mu.getImage({ user, team, priority: 'user' });
                    const currentSpeed = util.format(line.speed * 3.6, 1);
                    if (line.lat && line.long) {
                        let color = line.isInProgress ? '#6DEB38' : '#F90012';
                        const lastUpdatedEpoch = line.timestamp;
                        const now = Math.floor(new Date().getTime() / 1000);
                        const secSinceLastUpdate = now - lastUpdatedEpoch;
                        line.secSinceLastUpdate = secSinceLastUpdate;
                        if (secSinceLastUpdate > 86400) {
                            color = mu.getRankingColor(i + 1);
                        } else if (secSinceLastUpdate > 1800) {
                            color = '#F90012';
                        } else if (secSinceLastUpdate > 900) {
                            color = '#FC8B08';
                        } else {
                            color = '#6DEB38';
                        }
                        // : `${team.name} (🐕 ${line.avgDogs})`,
                        const fname = musher.firstname || '';
                        const title = `${fname.charAt(0)}. ${musher.lastname}`;
                        // : `Current speed: ${currentSpeed} km/t<br /> Distance: ${util.format(line.distanceKm, 1)} km<br /> 🧭 ${heading} - ${line.currentLocation.coords.altitude} moh`,
                        const body = `${currentSpeed} km/t, ${util.format(line.distanceKm, 1)} km`;
                        const wp = {
                            lat: line.lat,
                            lng: line.long,
                            icon: null,
                            image,
                            bib: line.bib,
                            rank: line.rank || this.waypoints.length + 1,
                            email: line.email,
                            flag: musher.flag,
                            title,
                            body,
                            color,
                            totalDistanceOnTrack: line.totalDistanceOnTrack,
                            totalDistanceToNextCp: line.totalDistanceToNextCp,
                            totalDistanceToPrevCp: line.totalDistanceToPrevCp,
                            prevCpOutTimestamp: line.prevCpOutTimestamp,
                            timeUsedOnCurrentStage: line.timeUsedOnCurrentStage,
                            speed: line.speed,
                            bearing: line.bearing,
                            finishTime: line.finishTime,
                            startTime: line.startTime,
                            raceTime: line.raceTime,
                            restTime: line.restTime,
                            speeds: line.speeds,
                            states: line.states,
                            currentState: line.currentState,
                            currentAvgSpeed: line.currentAvgSpeed,
                            timestamp: line.timestamp,
                            last10Points: line.last10Points,
                            bearingTrack: line.bearingTrack,
                            deviation: line.deviation,
                            secSinceLastUpdate: line.secSinceLastUpdate,
                            theoreticalDistanceKm: line.theoreticalDistanceKm,
                            theoreticalPoint: line.theoreticalPoint,
                            theoreticalPassedPoints: line.theoreticalPassedPoints,
                            theoreticalDistanceFromLastTrackPointKm: line.theoreticalDistanceFromLastTrackPointKm,
                            hasCrossedFinishLine: line.hasCrossedFinishLine,
                            hasStartedRace: line.hasStartedRace,
                            hasScratched: line.hasScratched,
                            scratchedTime: line.scratchedTime,
                            isScooter: line.isScooter,
                            prevTrackPoint: line.prevTrackPoint,
                        };
                        if (!loadAllStatuses && ['DNS', 'DNF', 'DNQ', 'DQ'].indexOf(musher.raceStatus) > -1) {
                            // Skip this musher
                            this.waypointsDNF.push(wp);
                        // } else if (['OK'].indexOf(musher.raceStatus) > -1) {
                        //     // Skip this musher on the map.
                        //     this.waypointsFinish.push(wp);
                        } else if (focusOnMushers && focusOnMushers.length > 0) {
                            if (focusOnMushers?.indexOf(musher.id) > -1) {
                                if (this.raceTrackerCounter <= 1) {
                                    this.zoomToMarkers.push(wp);
                                }
                                this.waypoints.push(wp);
                            }
                            if (bibs && bibs.length > 0 && bibs?.indexOf(line.bib) > -1) {
                                this.waypoints.push(wp);
                            }
                        // } else if (teamIds) {
                        //     if (teamIds?.indexOf(team.id) > -1) {
                        //         this.waypoints.push(wp);
                        //         this.zoomToMarkers.push(wp);
                        //     }
                        } else if (bibs && bibs.length > 0) {
                            if (bibs?.indexOf(line.bib) > -1) {
                                this.waypoints.push(wp);
                                if (this.raceTrackerCounter <= 1) {
                                    this.zoomToMarkers.push(wp);
                                }
                            }
                        } else {
                            this.waypoints.push(wp);
                            if (this.raceTrackerCounter <= 1) {
                                this.zoomToMarkers.push(wp);
                            }
                        }
                        if (wp.isScooter) {
                            this.waypointsScooter.push(wp);
                        }
                    }
                }

                if (this.waypoints && this.waypoints.length > 0) {
                    this.waypoints.forEach((wp, idx) => {
                        wp.distanceToFinish = this.totalTrackLength - wp.totalDistanceOnTrack;
                        wp.distanceToFirst = this.waypoints[0].finishTime < 9999999999
                            ? wp.distanceToFinish : this.waypoints[0].totalDistanceOnTrack - wp.totalDistanceOnTrack;
                        wp.diffToFirstFinishTime = wp.finishTime - this.waypoints[0].finishTime;
                        if (idx > 0 && this.waypoints[idx - 1]) {
                            wp.distanceToForward = this.waypoints[idx - 1].totalDistanceOnTrack - wp.totalDistanceOnTrack;
                        } else {
                            wp.distanceToForward = 0;
                        }
                        if (idx < this.waypoints.length - 1 && this.waypoints[idx + 1]) {
                            wp.distanceToBehind = wp.totalDistanceOnTrack - this.waypoints[idx + 1].totalDistanceOnTrack;
                        } else {
                            wp.distanceToBehind = 0;
                        }
                    });
                }
            }
            // if (this.racetrackerGeocluster && this.racetrackerGeocluster.length > 0) {
            //     this.waypoints2 = [];
            //     this.waypoints3 = [];
            //     for (let i = 0, l = this.racetrackerGeocluster.length; i < l; i += 1) {
            //         const line = this.racetrackerGeocluster[i];
            //         if (line) {
            //             const wp = {
            //                 lat: line.centroid[0],
            //                 lng: line.centroid[1],
            //             };
            //             this.waypoints2.push(wp);
            //             if (line && line.elements && util.isArray(line.elements)) {
            //                 line.elements.forEach(e => {
            //                     if (e) {
            //                         const p = {
            //                             lat: e[0],
            //                             lng: e[1],
            //                         };
            //                         this.waypoints3.push(p);
            //                     }
            //                 });
            //             }
            //         }
            //     }
            // }
		} catch (err) {
			console.log(err);
		}
    }

    @action
    updateWaypointPosition(historyIdx) {
        if (this.racetrackerData && this.racetrackerData.length > 0) {
            let lastSpeedKph;
            let lastBearing;
            let lastTotalDistanceOnTrack;
            let lastFinishTime;
            if (this.waypoints && this.waypoints.length > 0) {
                this.waypoints.forEach((wp) => {
                    const line = this.racetrackerData.find(e => e.bib === wp.bib);
                    if (line && line.points && line.points.length > 0) {
                        if (line.points[historyIdx] && line.points[historyIdx][0]) {
                            wp.lat = line.points[historyIdx][0];
                            wp.lng = line.points[historyIdx][1];
                            if (line.points[historyIdx][7] && line.points[historyIdx][7] > 0) {
                                wp.totalDistanceOnTrack = line.points[historyIdx][7];
                                lastTotalDistanceOnTrack = wp.totalDistanceOnTrack
                            } else {
                                wp.totalDistanceOnTrack = lastTotalDistanceOnTrack;
                            }
                            if (line.points[historyIdx][8] && line.points[historyIdx][8] > 0) {
                                wp.finishTime = line.points[historyIdx][8];
                                lastFinishTime = wp.finishTime;
                            } else {
                                wp.finishTime = lastFinishTime;
                            }
                            if (line.points[historyIdx][5]) {
                                wp.speed = line.points[historyIdx][5];
                                wp.bearing = line.points[historyIdx][6];
                                lastSpeedKph = wp.speed;
                                lastBearing = wp.bearing;
                            } else {
                                wp.speed = lastSpeedKph;
                                wp.bearing = lastBearing;
                            }
                        }
                    }
                });
                this.waypoints = this.waypoints.sort((a, b) => {
                    if (a.totalDistanceOnTrack && b.totalDistanceOnTrack) {
                        return b.totalDistanceOnTrack - a.totalDistanceOnTrack;
                    }
                    return 0;
                });

                this.waypoints.forEach((wp, idx) => {
                    wp.distanceToFinish = this.totalTrackLength - wp.totalDistanceOnTrack;
                    wp.distanceToFirst = this.waypoints[0].finishTime < 9999999999
                        ? wp.distanceToFinish : this.waypoints[0].totalDistanceOnTrack - wp.totalDistanceOnTrack;
                    wp.diffToFirstFinishTime = wp.finishTime - this.waypoints[0].finishTime;
                    if (idx > 0 && this.waypoints[idx - 1]) {
                        wp.distanceToForward = this.waypoints[idx - 1].totalDistanceOnTrack - wp.totalDistanceOnTrack;
                    } else {
                        wp.distanceToForward = 0;
                    }
                    if (idx < this.waypoints.length - 1 && this.waypoints[idx + 1]) {
                        wp.distanceToBehind = wp.totalDistanceOnTrack - this.waypoints[idx + 1].totalDistanceOnTrack;
                    } else {
                        wp.distanceToBehind = 0;
                    }
                });
            }
        }
    }

    findWaypointByBib(bib) {
        if (this.waypoints && this.waypoints.length > 0) {
            const idx = this.waypoints?.findIndex(e => e.bib === bib);
            const waypoint = { ...this.waypoints[idx] };
            waypoint.rank = idx + 1;
            waypoint.distanceToFinish = this.totalTrackLength - waypoint.totalDistanceOnTrack;
            waypoint.distanceToFirst = this.waypoints[0].totalDistanceOnTrack - waypoint.totalDistanceOnTrack;
            if (idx > 0 && this.waypoints[idx - 1]) {
                waypoint.distanceToForward = this.waypoints[idx - 1].totalDistanceOnTrack - waypoint.totalDistanceOnTrack;
            }
            if (idx < this.waypoints.length - 1 && this.waypoints[idx + 1]) {
                waypoint.distanceToBehind = waypoint.totalDistanceOnTrack - this.waypoints[idx + 1].totalDistanceOnTrack;
            }
            return waypoint;
        }
        return {};
    }

    findNearestMushers(bib) {
        let inFront = {};
        let behind = {};
        if (this.waypoints && this.waypoints.length > 0) {
            const idx = this.waypoints?.findIndex(e => e.bib === bib);
            if (idx > 0) {
                inFront = { ...this.waypoints[idx - 1] };
            }
            if (idx < this.waypoints.length - 1) {
                behind = { ...this.waypoints[idx + 1] };
            }
        }
        return { inFront, behind };
    }

    getRaceTrackerTimestamp(idx) {
        if (this.racetrackerData && this.racetrackerData.length > 0) {
            const sortedArray = this.racetrackerData.slice(0, 15).sort((a, b) => {
                if (a.points && b.points) {
                    return a.points.length - b.points.length;
                }
                return 0;
            });
            const line = sortedArray.shift();
            if (line && line.points && line.points[idx]) {
                return line.points[idx][2];
            }
            return null;
        }
    }

    getWorkoutResultsByTeam(stages, classDistanceKm, type = [2]) {
        let distanceKm = 0;
        let elevation = 0;
        let duration = 0;
        let rest = 0;
        let loadIndex = 0;
        let totalKcalRelated = 0;
        let weightedTotalKcal = 0;
        let flatTotalKcal = 0;
        let speedAvg = 0;
        let avgDogs = 0;
        let images = [];
        let normalizedDuration = 0;
        let weightedNormalizedDuration = 0;
        let percentHarder;
        let completed = 0;
        let lastUpdated;
        let isLocked;
        let id;

        if (util.isArray(stages)) {
            const filteredStages = stages.filter(e => type.includes(e.type));
            distanceKm = filteredStages.map(e => e.distanceKm).reduce((acc, e) => acc + e, 0);
            elevation = filteredStages.map(e => e.elevation).reduce((acc, e) => acc + e, 0);
            duration = filteredStages.map(e => e.duration).reduce((acc, e) => acc + e, 0);
            rest = filteredStages.map(e => e.rest).reduce((acc, e) => acc + e, 0);
            loadIndex = filteredStages.map(e => e.loadIndex).reduce((acc, e) => acc + e, 0);
            totalKcalRelated = filteredStages.map(e => e.totalKcalRelated).reduce((acc, e) => acc + e, 0);
            weightedTotalKcal = filteredStages.map(e => e.weightedTotalKcal).reduce((acc, e) => acc + e, 0);
            flatTotalKcal = filteredStages.map(e => e.flatTotalKcal).reduce((acc, e) => acc + e, 0);
            speedAvg = filteredStages.map(e => e.speedAvg).reduce((acc, e) => acc + e, 0) / filteredStages.length;
            avgDogs = filteredStages.map(e => e.avgDogs).reduce((acc, e) => acc + e, 0) / filteredStages.length;
            for (let i = 0, l = filteredStages.length; i < l; i += 1) {
                const stage = filteredStages[i];
                images = images.concat(stage.images);
            }
            // lastUpdated = Math.max(filteredStages.map(e => new Date(e.updatedDate)));
            lastUpdated = filteredStages.map(e => new Date(e.updatedDate)).sort().pop();
            isLocked = filteredStages.map(e => e.isLocked).pop();
            id = filteredStages.map(e => e.id).pop();

            if (classDistanceKm) {
                completed = distanceKm / classDistanceKm * 100;
                normalizedDuration = duration / completed * 100;
                weightedNormalizedDuration = normalizedDuration - (loadIndex * 2);
                if (weightedTotalKcal && flatTotalKcal) {
                    percentHarder = weightedTotalKcal / flatTotalKcal * 100;
                }
            }


        }
        return {
            distanceKm,
            elevation,
            duration,
            rest,
            loadIndex,
            totalKcalRelated,
            weightedTotalKcal,
            flatTotalKcal,
            speedAvg,
            avgDogs,
            images: images.flat(),
            normalizedDuration,
            weightedNormalizedDuration,
            percentHarder,
            lastUpdated,
            isLocked,
            id,
        };
    }

    @action
    updateWorkoutSummary(workoutSummary) {
        if (util.isArray(workoutSummary)) {
            this.workoutSummary = workoutSummary;
        }
    }

    async loadTeams(idin, concat, skipUser, skipDogs) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/teams`, { publish: true, method: 'GET' }, { idin, skipUser, skipDogs });
        switch (response.status) {
            case 200:
                this.updateTeamList(response.data, concat);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateRaceTrackerData(data) {
        if (util.isArray(data)) {
            this.racetrackerData = data;
        }
    }

    @action
    updateRaceTrackerGeocluster(data) {
        if (util.isArray(data)) {
            this.racetrackerGeocluster = data;
        }
    }

    @action setMaxPoints(data) {
        this.maxPoints = data;
    }

    @action setTotalTrackLength(data) {
        this.totalTrackLength = data;
    }

    async getRaceTrackerData(raceTrackerRace, opt = {}) {
        try {
            const response = await util.fetchApi(`/api/racetracker/${raceTrackerRace}`, { publish: true, method: 'GET' }, { ...opt });
            switch (response.status) {
                case 200:
                    this.updateRaceTrackerData(response.data);
                    if (response.included && response.included.geocluster) {
                        this.updateRaceTrackerGeocluster(response.included.geocluster);
                    }
                    if (response.included && response.included.maxPoints) {
                        this.setMaxPoints(response.included.maxPoints);
                    }
                    if (response.included && response.included.totalTrackLength) {
                        this.setTotalTrackLength(response.included.totalTrackLength);
                    }
                    return response;
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
		} catch (err) {
			console.log(err);
		}
    }

    async loadWorkoutSummary(teamin, raceId) {
        this.localUpdateField('workoutSummary', []);
        this.localUpdateField('workoutTotal', []);
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/workout-summary`, { publish: true, method: 'GET' }, { teamin, raceId });
        switch (response.status) {
            case 200:
                this.updateWorkoutSummary(response.data);
                if (response.included) {
                    this.localUpdateField('workoutTotal', response.included.workoutTotal);
                    this.localUpdateField('workoutResultsChecksum', response.included.workoutResultsChecksum);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToRace({ id, data }) {
        if (util.isArray(this.race.likes)) {
            this.race.likes.push(data);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.races[idx].likes)) {
                this.races[idx].likes = [];
            }
            this.races[idx].likes.push(data);
        }
    }

    @action
    addLikeToComment({ id, commentId, data }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                comment.likes.push(data);
            }
        }
    }

    @action
    addLikeToCommentReply({ id, commentId, replyId, data }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    reply.likes.push(data);
                }
            }
        }
    }

    @action
    addCommentToRace({ id, data }) {
        if (util.isArray(this.race.comments)) {
            this.race.comments.push(data);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this.races[idx].comments)) {
                this.races[idx].comments = [];
            }
            this.races[idx].comments.push(data);
        }
    }

    @action
    addCommentToRaceComment({ id, commentId, data }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    @action
    editComment({ id, commentId, data }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            comment.comment = data;
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                comment.comment = data;
            }
        }
    }

    @action
    deleteComment({ id, commentId }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            this.race.comments.splice([commentIdx], 1);
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                this.races[idx].comments.splice([commentIdx], 1);
            }
        }
    }

    @action
    editCommentReply({ id, commentId, replyId, data }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            if (util.isDefined(comment.comments)) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                reply.comment = data;
            }
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
    }

    @action
    deleteCommentReply({ id, commentId, replyId }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentId);
            const comment = this.race.comments[commentIdx];
            if (util.isDefined(comment.comments)) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                comment.comments.splice(replyIdx, 1)
            }
        }
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this.races[idx].comments)) {
                const commentIdx = this.races[idx].comments?.findIndex(e => e.id === commentId);
                const comment = this.races[idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
    }

    async likeRace({ id }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/like/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToRace({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeRaceComment({ id, commentId }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async likeRaceCommentReply({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToCommentReply({ id, commentId, replyId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentRace({ id, comment }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToRace({ id, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async commentReplyRace({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.addCommentToRaceComment({ id, commentId, data: response.data });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async editCommentRace({ id, commentId, comment }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}/${commentId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.editComment({ id, commentId, data: comment });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async deleteCommentRace({ id, commentId }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}/${commentId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.deleteComment({ id, commentId });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async editCommentReplyRace({ id, commentId, replyId, comment }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}/${commentId}/${replyId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.editCommentReply({ id, commentId, replyId, data: comment });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async deleteCommentReplyRace({ id, commentId, replyId }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/comment/${id}/${commentId}/${replyId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.deleteCommentReply({ id, commentId, replyId });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    updateraceTranslation({ id, field, value, targetLang }) {
        const idx = this.races?.findIndex(e => e.id === id);
        if (idx > -1) {
            this.races[idx][`${field}${targetLang}`] = value;
        }
        this.race[`${field}${targetLang}`] = value;
    }

    // getTranslations({ race, targetLang, field }) {
    //     if (race.translations) {
    //         const idx = race.translations?.findIndex((e) => {
    //             return e.language === targetLang && e.field === field;
    //         });
    //         if (idx > -1) {
    //             return race.translations[idx].text;
    //         }
    //     }
    //     return null;
    // }

    async translate({ race, user, field = 'body' }) {
        const { id, language = 'no', body: text } = race;
        const { language: userLanguage = 'en' } = user;

        const sourceLang = language === 'undefined' ? 'no' : language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = mu.getTranslation({ object: race, targetLang, field });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}/translate/${sourceLang}/${targetLang}/${field}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateraceTranslation({
                            id: race.id,
                            field,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    /* Comment translations */
    @action
    updateCommentTranslation({ id, commentid, value, targetLang, field = 'comment' }) {
        const raceIdx = this.races?.findIndex(e => e.id === id);
        if (raceIdx > -1) {
            const race = this.races[raceIdx];
            if (util.isDefined(race.comments)) {
                const commentIdx = race.comments?.findIndex(e => e.id === commentid);
                const comment = race.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, raceIdx, commentIdx, comment });

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        return e.language === targetLang && e.field === field;
                    });
                    comment.translations[translationIdx] = translationObj;
                } else {
                    comment.translations = [translationObj];
                }
            }
        }
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentid);
            const comment = this.race.comments[commentIdx];
            // console.log({ id, commentid, value, targetLang, this.raceIdx, commentIdx, comment });

            const translationObj = {
                field,
                text: value,
                language: targetLang,
            };

            if (util.isDefined(comment.translations)) {
                const translationIdx = comment.translations?.findIndex((e) => {
                    return e.language === targetLang && e.field === field;
                });
                comment.translations[translationIdx] = translationObj;
            } else {
                comment.translations = [translationObj];
            }
        }
    }

    @action
    updateCommentReplyTranslation({ id, commentid, replyid, value, targetLang, field = 'comment' }) {
        const raceIdx = this.races?.findIndex(e => e.id === id);
        if (raceIdx > -1) {
            const race = this.races[raceIdx];
            if (util.isDefined(race.comments)) {
                const commentIdx = race.comments?.findIndex(e => e.id === commentid);
                const comment = race.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, raceIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentid);
            const comment = this.race.comments[commentIdx];
            // console.log({ id, commentid, value, targetLang, this.raceIdx, commentIdx, comment });

            if (util.isDefined(comment.comments)) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                const reply = comment.comments[replyIdx];
                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(reply.translations)) {
                    const translationIdx = reply.translations?.findIndex((e) => {
                        return e.language === targetLang && e.field === field;
                    });
                    reply.translations[translationIdx] = translationObj;
                } else {
                    reply.translations = [translationObj];
                }
            }
        }
    }

    getCommentTranslations({ race, commentid, targetLang, field = 'comment' }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentid);
            const comment = this.race.comments[commentIdx];

            if (util.isDefined(comment.translations)) {
                const translationIdx = comment.translations?.findIndex((e) => {
                    // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                    return e.language === targetLang && e.field === field;
                });
                if (translationIdx > -1) {
                    return comment.translations[translationIdx].text;
                }
            }
        }
        return null;
    }

    getCommentReplyTranslations({ race, commentid, replyid, targetLang, field = 'comment' }) {
        if (util.isDefined(this.race.comments)) {
            const commentIdx = this.race.comments?.findIndex(e => e.id === commentid);
            const comment = this.race.comments[commentIdx];

            if (util.isDefined(comment.comments)) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                const reply = comment.comments[replyIdx];

                if (util.isDefined(reply.translations)) {
                    const translationIdx = reply.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return reply.translations[translationIdx].text;
                    }
                }
            }
        }
        return null;
    }

    async translateComment({ race, user, commentid }) {
        const { id, language = 'no' } = race;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(race.comments)) {
            return null;
        }
        const commentIdx = race.comments?.findIndex(e => e.id === commentid);
        const comment = race.comments[commentIdx];
        const text = comment.comment;

        const sourceLang = comment.language === 'undefined' ? 'no' : comment.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;
        const translatedText = this.getCommentTranslations({ race, commentid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}/${commentid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentTranslation({
                            id: race.id,
                            commentid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    async translateCommentReply({ race, user, commentid, replyid }) {
        const { id, language = 'no' } = race;
        const { language: userLanguage = 'en' } = user;

        if (!util.isDefined(race.comments)) {
            return null;
        }
        const commentIdx = race.comments?.findIndex(e => e.id === commentid);
        const comment = race.comments[commentIdx];
        const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
        const reply = comment.comments[replyIdx];
        const text = reply.comment;

        const sourceLang = reply.language === 'undefined' ? 'no' : reply.language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = this.getCommentReplyTranslations({ race, commentid, replyid, targetLang });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/${id}/${commentid}/${replyid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateCommentReplyTranslation({
                            id: race.id,
                            commentid,
                            replyid,
                            value: response.data.translatedText,
                            targetLang,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    PubSub.publish(topics.LOG_OUT);
                    route('/');
                    break;
            }
        }
    }

    async sendInboxMessage({ body = '', raceId, raceClassId }) {
        const response = await util.fetchApi(`/api/${this.isRaceAdmin ? 'adm-races': 'races'}/message/${raceId}/${raceClassId}`, { publish: true, method: 'POST' }, { body });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                return response;
        }
    }

    @action
    updateRaceTrackerResults(data) {
        this.raceTrackerResults = data;
        this.raceTrackerResultsHeaders = [];
        if (data.list && data.list.Fields && util.isArray(data.list.Fields)) {
            data.list.Fields.forEach((field, idx) => {
                this.raceTrackerResultsHeaders[idx] = field.Label;
            });
        }
    }

    raceTrackerMapLine(line) {
        const data = {};
        if (this.raceTrackerResultsHeaders) {
            this.raceTrackerResultsHeaders.forEach((field, idx) => {
                data[field] = line[idx + 1];
            });
        }
        return data;
    }

    async getRacetrackerResults(apiurl) {
        const response = await util.fetchApi(`/api/racetracker/api/${encodeURIComponent(apiurl)}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateRaceTrackerResults(response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
            default:
                return response;
        }
    }
}

const store = new RaceStore();
export default store;
