import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';


import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; // mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class WorkoutCaloriesDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore, workoutStore } = this.props.stores;
        const { types, equipments, language = 'en' } = userStore;
        const { darkmode, isCordova } = appState;
        const { workout } = workoutStore;
        const { gpxInfo } = workout;
        const { weightedTotalKcal = 0, loadIndex = 0 } = gpxInfo;
        const kcalMultiplier = gpxInfo.totalKcalRelated / 100;

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
				<Localizer>
					<WidgetHead
						stores={this.props.stores}
						title={<Text id='workout.workout-elevation-detail'>Workout Calories</Text>}
						// right={<i class='fa-solid fa-pen-to-square' />}
						// onRightClick={this.onEditClick}
						// right2={<i class='fa-solid fa-upload' />}
						// onRight2Click={this.exportWorkout}
					/>
				</Localizer>
				{/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} p-3`} paddingBottom={0}>

                    <div class='col-12 '>
                        <div class='row mt-4'>
                            <div class='col text-center'>
                                <small class='text-muted'>
                                    <Text id='workouts.energy-consumption-help'>Energiforbruk er regnet ut fra 1,25 kcal / kg kroppsvekt / km.</Text>
                                </small>
                            </div>
                        </div>
                        <div class='row mt-2'>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-balance-scale ml-2' /> <Text id='workouts.energy-consumption-total'>Energiforbruk totalt</Text></small>
                                <h3>
                                    {util.format(workout.kcalBurnedTotal, 0)} <Text id='workout.kcal'>kcal</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalBurnedTotal * kcalMultiplier, 0)} <Text id='workout.kcal'>kcal</Text> <br />
                                </small>}
                            </div>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-balance-scale ml-2' /> <Text id='workouts.energy-consumption'>Energiforbruk pr hund</Text></small>
                                <h3>
                                    {util.format(workout.kcalBurned, 0)} kcal
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(weightedTotalKcal, 0)} <Text id='workout.kcal'>kcal</Text> <br />
                                </small>}
                            </div>
                        </div>
                        <div class='row mt-2'>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-piggy-bank ml-2' /> <Text id='workouts.energy-consumption-fat-total'>Fett totalt</Text></small>
                                <h3>
                                    {util.format(workout.kcalFatGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalFatGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-piggy-bank ml-2' /> <Text id='workouts.energy-consumption-fat'>Fett pr hund</Text></small>
                                <h3>
                                    {util.format(workout.kcalFatGrams / 1000, 1)} <Text id='workout.kg'>kg</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalFatGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                        </div>
                        <div class='row mt-2'>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-drumstick-bite ml-2' /> <Text id='workouts.energy-consumption-meat-total'>Kjøtt totalt</Text></small>
                                <h3>
                                    {util.format(workout.kcalMeatGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalMeatGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-drumstick-bite ml-2' /> <Text id='workouts.energy-consumption-meat'>Kjøtt pr hund</Text></small>
                                <h3>
                                    {util.format(workout.kcalMeatGrams / 1000, 1)} kg
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalMeatGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                        </div>
                        <div class='row mt-2'>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-fish ml-2' /> <Text id='workouts.energy-consumption-salmon-total'>Kjøtt totalt</Text></small>
                                <h3>
                                    {util.format(workout.kcalSalmonGramsTotal / 1000, 1)} <Text id='workout.kg'>kg</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalSalmonGramsTotal / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                            <div class='col  text-center'>
                                <small class='text-muted'><i class='fas fa-fish ml-2' /> <Text id='workouts.energy-consumption-salmon'>Kjøtt pr hund</Text></small>
                                <h3>
                                    {util.format(workout.kcalSalmonGrams / 1000, 1)} <Text id='workout.kg'>kg</Text>
                                </h3>
                                {kcalMultiplier > 0 && <small class='text-muted' style='line-height: 0.8em;'>
                                    <Text id='workouts.kcal-calculated'>Beregnet:</Text> {util.format(workout.kcalSalmonGrams / 1000 * kcalMultiplier, 1)} <Text id='workout.kg'>kg</Text>
                                </small>}
                            </div>
                        </div>
                    </div>

                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutCaloriesDetail;
