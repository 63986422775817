import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class ProfileHeadV2 extends Component {
    onDogClick = (e, dogId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawer2('publicDog', { dogId, height: drawerHeightLarge });
	}

    onWorkoutClick = (e, workoutId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('workoutDetail', {
            id: parseInt(workoutId, 10),
            onLinkClick: this.toggleDrawer,
            onDogClick: this.onDogClick,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: false,
        });
	}

    onUserClick = (e, userid) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onFavoritesClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('profileFavorites', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            drawerLevel: 1,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            backButtonText: 'Profile'
        });
    }

    onPostsClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('publicStoryList', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            drawerLevel: 1,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            backButtonText: 'Profile'
        });
    }

    onFollowersClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowers', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 1,
            backButtonText: 'Profile'
        });
    }

    onFollowingClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const currentTeam = userStore.getCurrentTeam();
        appState.openDrawer('teamsFollowing', {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightLarge,
            marginTop: 0,
            showBackButton: true,
            showCloseButton: false,
            teamId: currentTeam.uuidv4,
            drawerLevel: 1,
            backButtonText: 'Profile'
        });
    }

    editTeamField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editTeam').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editTeam', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    editUserField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('.editUser').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer('editUser', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    goToUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { url } = e.target.closest('i').dataset;
        route(url);
    }

    render() {
        const { userStore, teamStore, appState, workoutStore } = this.props.stores;
        const { darkmode } = appState;
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const { id } = this.props;
        const team = userStore.findTeam(parseInt(id, 10), true);

        const teamImage = mu.getImage({ team });
        const userImage = mu.getImage({ user, fallback: './assets/paw.png' });
        const headerImage = mu.getImage({ team, field: 'imageHeader', size: 's3XXLargeLink', fallback: './assets/BG_mountain_small_profile.png' });

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'}>
            <div class='d-flex justify-content-center rounded-lg'>
                <div
                    class='w-100 position-relative editTeam d-flex justify-content-center'
                    style={`
                        height: 180px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-color: #a0a0a0; ${headerImage ? `background-image: url("${headerImage}");` : ''}
                        width: 100% !important;
                        border-radius: 10px !important;
                    `}
                    // onClick={this.editTeamField}
                    data-field='imageHeader'
                    data-type='image'
                    data-title='Top image'
                    data-id={team.id}
                >
                    <div class='d-flex position-relative align-items-center'>
                        <div class='text-center editUser'
                            onClick={this.editUserField}
                            data-field='image'
                            data-type='image'
                            data-title='User Image'
                            data-id={user.id}
                        >
                            <div
                                class={`text-center border rounded-circle imageRounded imageRoundedMediumPlus ${darkmode ? 'border-darkmode' : 'border-lightmode'}`}
                                style={`${teamImage ? `
                                    background-image: url("${teamImage}");
                                    background-size: cover;
                                ` : ''}`}>
                                {!teamImage && <i class='fas fa-dog text-muted mt-4 ml-2' style='font-size: 45px;' />}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class='mt-2 pb-2 d-flex justify-content-center rounded-lg'>
                <div
                    class='col-12'
                >
                    <div class='d-flex justify-content-between pl-2'>
                        <div>&nbsp;</div>
                        <div class='profileSmallName'>
                            {team.name}
                        </div>
                        <div>
                            <i
                                class='fa-solid fa-pen-to-square text-primary'
                                onClick={this.goToUrl}
                                data-url={`/teams/edit/${team.id}`}
                            />
                        </div>
                    </div>
                    <div class='d-flex justify-content-between'>
                        <div class='text-center' onClick={this.onFavoritesClick}>
                            <span class='profileSmallHeader'><Text id='team.dogs'>dogs</Text></span><br />
                            <span class='profileMediumNumbers'>{team?.totalDogs || 0}</span>
                        </div>
                        <div class='text-center' onClick={this.onPostsClick}>
                            <span class='profileSmallHeader'><Text id='team.total-members'>Members</Text></span><br />
                            <span class='profileMediumNumbers'>{team?.members?.length || 0}</span>
                        </div>
                        <div class='text-center ml-1' onClick={this.onFollowersClick}>
                            <span class='profileSmallHeader'><Text id='team.total-followers'>Followers</Text></span><br />
                            <span class='profileMediumNumbers'>{team?.followers ? team.followers.length : 0}</span>
                        </div>
                        <div class='text-center ml-1' onClick={this.onActivitiesClick}>
                            <span class='profileSmallHeader'><Text id='team.total-workouts'>Activities</Text></span><br />
                            <span class='profileMediumNumbers'>{team?.totalWorkouts || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Widget>);
    }
}

export default ProfileHeadV2;
