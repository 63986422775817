import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import mu from '../lib/musher-util';

configure({ enforceActions: 'always' });

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o=o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

class MessageStore extends StoreModel {
    constructor() {
        super('message', {
            namePlural: 'messages',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/messags/',
                    params: {
                        limit: 15,
                        sort: 'createdDate',
                    },
                },
                load: {
                    url: '/api/messages/',
                    params: {},
                },
                save: {
                    url: '/api/messages/',
                    params: {},
                },
            },
        });
    }

    @observable newMessage = {};

    @observable unread = 0;

    @observable message = {};

    @observable conversationUser = {};

    @observable messages = [];

    @observable users = [];

    @observable loadMore = () => {};

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    addNewMessage(message = {}) {
        this.messages.push(message);
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.ad.images)) {
            const idx = this.ad.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.ad.images.splice(idx, 1);
            }
        }
        const widx = this.ads?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.ads[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.ads[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/ads/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getUnReadMessages() {
        const response = await util.fetchApi(`/api/messages/unread`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('unread', response.data);
                if (response.data > 0) {
                    mu.tapticNotification('success');
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadUsers({ search, limit }) {
        this.localUpdateField('users', []);
        const response = await util.fetchApi(`/api/messages/users`, { publish: true, method: 'GET' }, { search, limit });
        switch (response.status) {
            case 200:
                this.localUpdateField('users', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async sendMessage({ message, to }) {
        const response = await util.fetchApi(`/api/messages/${to}/new`, { publish: true, method: 'POST' }, { ...message });
        switch (response.status) {
            case 201:
                this.addNewMessage(response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadConversation({ to, search, limit, offset }) {
        if (offset === 0) {
            this.localUpdateField('messages', []);
            this.localUpdateField('conversationUser', []);
        }
        const response = await util.fetchApi(`/api/messages/${to}`, { publish: true, method: 'GET' }, { search, limit, offset });
        switch (response.status) {
            case 200:
                if (offset > 0) {
                    let messages = toJS(this.messages);
                    messages.push(...response.data);
                    const allMessages = messages.slice().sort(fieldSorter(['sentDate']));
                    this.localUpdateField('messages', allMessages);
                } else {
                    const messages = response.data.slice().sort(fieldSorter(['sentDate']));
                    this.localUpdateField('messages', messages);
                    if (response.included && response.included.user) {
                        this.localUpdateField('conversationUser', response.included.user);
                    }
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async checkForNewMessages({ to, search, limit, offset, cutoffDate }) {
        const response = await util.fetchApi(`/api/messages/${to}`, { publish: true, method: 'GET' }, { search, limit, offset, cutoffDate });
        switch (response.status) {
            case 200:
                if (response.data.length > 0) {
                    let messages = toJS(this.messages);
                    messages.push(...response.data);
                    const allMessages = messages.slice().sort(fieldSorter(['sentDate']));
                    this.localUpdateField('messages', allMessages);
                }
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async loadConversationList({ search, limit, offset }) {
        const response = await util.fetchApi(`/api/messages/`, { publish: true, method: 'GET' }, { search, limit, offset });
        switch (response.status) {
            case 200:
                this.localUpdateField('users', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    localMarkAsRead(user = {}) {
        if (util.isArray(this.messages)) {
            this.messages.filter(e => e.user === user.uuidv4).forEach(e => {
                if (!e.readDate) {
                    e.readDate = new Date();
                }
            });
        }
    }

    async markAsRead({ to, user }) {
        const response = await util.fetchApi(`/api/messages/${to}/markallread`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 202:
                this.localMarkAsRead({ to });
                this.getUnReadMessages(user);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    @action
    addLikeToMessage({ messageId, data }) {
        const idx = this.messages?.findIndex(e => e.uuidv4 === messageId);
        if (idx > -1) {
            const message = this.messages[idx];
            if (util.isUndefined(message.likes)) {
                message.likes = [];
            }
            message.likes.push(data);
        }
    }

    async likeMessage({ messageId }) {
        const response = await util.fetchApi(`/api/messages/${messageId}/like`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.addLikeToMessage({ messageId, data: response.data });
                // mu.tapticWeakBoom();
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new MessageStore();
export default store;
