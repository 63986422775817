import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import style from '../style.css';
import mu from '../../lib/musher-util';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';
import Duration from '../../components/data/duration';

@observer
class WorkoutTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async loadAll(props = this.props) {
        await this.loadTeams(props);
        // await Promise.all([
        //     this.loadTeams(props),
        // ]);
    }

    async loadTeams(props = this.props) {
		const { teamStore } = props.stores;
        const { type } = props;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const { month = currentMonth, year = currentYear, currentTeam } = props;
        const workoutType = type === 'all' ? null : type;


		await teamStore.load({
            query: {
                team: currentTeam,
                skipMusherWorkoutSummary: true,
                year,
                month,
                type: workoutType,
            },
            addData: ['workoutSummary'],
        });
	}

	componentDidMount() {
		this.loadAll();
	}

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.year !== this.props.year
            || nextProps.currentTeam !== this.props.currentTeam
            || nextProps.type !== this.props.type
        ) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { teamStore } = this.props.stores;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear, currentTeam, currentSeason: currentSeasonSummary, prevSeason: prevSeasonSummary } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const { workoutSummary } = teamStore;
        const prevSeason = prevSeasonSummary || ((currentTeam ? teamStore.findWorkoutSummary(currentTeam, seasonStart - 1) : teamStore.findWorkoutSummaryTotal(seasonStart - 1)) || {});
        const currentSeason = currentSeasonSummary || ((currentTeam ? teamStore.findWorkoutSummary(currentTeam, seasonStart) : teamStore.findWorkoutSummaryTotal(seasonStart)) || {});

        return (
            <div class='container-fluid mb-2'>
                <div class='row mt-2 border-top'>
                    <div class='col-2 text-center py-2'>
                        &nbsp;
                    </div>
                    <div class='col-5 text-center py-2'>
                        <span class='text-muted'>{seasonStart}-{seasonStart + 1}</span>
                    </div>
                    <div class='col-5 text-center border-left py-2'>
                        <span class='text-muted'>{seasonStart - 1}-{seasonStart}</span>
                    </div>
                </div>
                <div class='row mt-0'>
                    <div class='col-2 text-left text-muted py-2'>
                        <h3>
                            <small><small><small>
                                <nobr>Distance</nobr>
                            </small></small></small>
                        </h3>
                    </div>
                    <div class='col-5 text-center py-2'>
                        <h3>
                            <Distance stores={this.props.stores} value={currentSeason.distanceKm || 0} smallUnit={true} />
                        </h3>
                    </div>
                    <div class='col-5 text-center border-left py-2'>
                        <h3 class='text-muted'>
                            <Distance stores={this.props.stores} value={prevSeason.distanceKm || 0} smallUnit={true} />
                        </h3>
                    </div>
                </div>
                <div class='row mt-0'>
                    <div class='col-2 text-left text-muted'>
                        <h3>
                            <small><small><small>
                                <nobr>Elevation</nobr>
                            </small></small></small>
                        </h3>
                    </div>
                    <div class='col-5 text-center'>
                        <h3>
                            <Elevation stores={this.props.stores} value={currentSeason.elevation || 0} smallUnit={true} />
                        </h3>
                    </div>
                    <div class='col-5 text-center border-left'>
                        <h3 class='text-muted'>
                            <Elevation stores={this.props.stores} value={prevSeason.elevation || 0} smallUnit={true} />
                        </h3>
                    </div>
                </div>
                <div class='row mt-0'>
                    <div class='col-2 text-left text-muted'>
                        <h3>
                            <small><small><small>
                                <nobr>Duration</nobr>
                            </small></small></small>
                        </h3>
                    </div>
                    <div class='col-5 text-center'>
                        <h3>
                            <Duration stores={this.props.stores} value={currentSeason.duration || 0} smallUnit={true} />
                        </h3>
                    </div>
                    <div class='col-5 text-center border-left'>
                        <h3 class='text-muted'>
                            <Duration stores={this.props.stores} value={prevSeason.duration || 0} smallUnit={true} />
                        </h3>
                    </div>
                </div>
                <div class='row mt-0'>
                    <div class='col-2 text-center text-muted'>
                        <h3>
                            <small><small><small>
                                <nobr>Avg speed</nobr>
                            </small></small></small>
                        </h3>
                    </div>
                    <div class='col-5 text-center'>
                        <h3>
                            <Speed stores={this.props.stores} value={currentSeason.speedAvg || 0} smallUnit={true} />
                        </h3>
                    </div>
                    <div class='col-5 text-center border-left'>
                        <h3 class='text-muted'>
                            <Speed stores={this.props.stores} value={prevSeason.speedAvg || 0} smallUnit={true} />
                        </h3>
                    </div>
                </div>
                <div class='row mt-0 border-bottom'>
                    <div class='col-2 text-center text-muted'>
                        <h3>
                            <small><small><small>
                                <nobr>Total activities</nobr>
                            </small></small></small>
                        </h3>
                    </div>
                    <div class='col-5 text-center'>
                        <h3>
                            {currentSeason.count || 0}
                        </h3>
                    </div>
                    <div class='col-5 text-center border-left'>
                        <h3 class='text-muted'>
                            {prevSeason.count || 0}
                        </h3>
                    </div>
                </div>

            </div>
        );
    }
}

export default WorkoutTotals;
