import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import CommentReply from './commentReply';
import CommentsAdd from './commentsAdd';

import Widget from '../../components/widget';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import style from '../style.css';
import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

const initialState = {
    showAddComment: {},
    showAllReplies: {},
    viewImageIdx: {},
};

const MAX_COMMENTS = 100;

@observer
class Comments extends Component {
     constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            showAllComments: props.displayAllComments,
        };
        this.inputRef = {};
    }

    likeComment = async (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;

        const { callback = () => {}, type, object, holdingStore, holdingArrayName } = this.props;
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;

        let response;
        switch (type) {
            case 'story':
                response = await storyStore.commentLike({ id: object.id, commentId });
                break;
            case 'workout':
                response = await workoutStore.commentLike({ id: object.id, commentId });
                break;
            case 'news':
                response = await newsStore.commentLike({ id: object.id, commentId });
                break;
            case 'race':
                response = await raceStore.commentLike({ id: object.id, commentId });
                break;
            case 'track':
                response = await trackStore.commentLike({ id: object.id, commentId });
                break;
        }

        switch(holdingStore) {
            case 'storyStore':
                storyStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'workoutStore':
                workoutStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'teamStore':
                teamStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'newsStore':
                newsStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'raceStore':
                raceStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            case 'trackStore':
                trackStore.commentAddLike({ id: object.id, data: response, commentId, holdingArrayName });
                break;
            default:
                break;
        }

		if (response && response.id) {
            callback({ type: 'likeComment', object, commentId, response });
		}
        return true;
    }

    likeReply = async (e) => {
        const replyId = e.target.parentElement.dataset.id || e.target.dataset.id;
        const commentId = e.target.parentElement.dataset.comment || e.target.dataset.comment;

        const { callback = () => {}, type, object, holdingStore, holdingArrayName } = this.props;
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;

        let response;
        switch (type) {
            case 'story':
                response = await storyStore.commentReplyLike({ id: object.id, commentId, replyId });
                break;
            case 'workout':
                response = await workoutStore.commentReplyLike({ id: object.id, commentId, replyId });
                break;
            case 'news':
                response = await newsStore.commentReplyLike({ id: object.id, commentId, replyId });
                break;
            case 'race':
                response = await raceStore.commentReplyLike({ id: object.id, commentId, replyId });
                break;
            case 'track':
                response = await trackStore.commentReplyLike({ id: object.id, commentId, replyId });
                break;
        }

        switch(holdingStore) {
            case 'storyStore':
                storyStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            case 'workoutStore':
                workoutStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            case 'teamStore':
                teamStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            case 'newsStore':
                newsStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            case 'raceStore':
                raceStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            case 'trackStore':
                trackStore.commentReplyAddLike({ id: object.id, data: response, commentId, replyId, holdingArrayName });
                break;
            default:
                break;
        }

        if (response && response.id) {
            callback({ type: 'likeReply', object, commentId, replyId, response });
		}
    }

    unlikeComment = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    unlikeReply = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    replyComment = async (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;

        const { showAddComment } = this.state;
        showAddComment[commentId] = !showAddComment[commentId];
		this.setState({
			showAddComment,
		}, () => {
            if (this.inputRef[commentId]) {
                this.inputRef[commentId].focus();
            }
		});
    }

    toggleComments = () => {
		const { showAllComments } = this.state;
		this.setState({
			showAllComments: !showAllComments,
		});
	}

    toggleReplies = (e) => {
        const commentId = e.target.parentElement.dataset.id || e.target.dataset.id;
		const { showAllReplies } = this.state;
        showAllReplies[commentId] = !showAllReplies[commentId];
		this.setState({
			showAllReplies,
		});
	}

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { replyid, commentid } = e.target.closest('button').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language: targetLang } = user;

        const { showOriginal = {}, translatedText = {} } = this.state;
        const key = `${commentid}${replyid ? `-${replyid}`: ''}`;
        showOriginal[key] = 0;

        const { type, object, holdingStore, holdingArrayName } = this.props;
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;

        if (replyid) {
            let response;
            switch (type) {
                case 'story':
                    response = await storyStore.commentReplyTranslate({ object, user, commentid, replyid, holdingArrayName });
                    break;
                case 'workout':
                    response = await workoutStore.commentReplyTranslate({ object, user, commentid, replyid, holdingArrayName });
                    break;
                case 'news':
                    response = await newsStore.commentReplyTranslate({ object, user, commentid, replyid, holdingArrayName });
                    break;
                case 'race':
                    response = await raceStore.commentReplyTranslate({ object, user, commentid, replyid, holdingArrayName });
                    break;
                case 'track':
                    response = await trackStore.commentReplyTranslate({ object, user, commentid, replyid, holdingArrayName });
                    break;
            }

            switch(holdingStore) {
                case 'storyStore':
                    storyStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = storyStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                case 'workoutStore':
                    workoutStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = workoutStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                case 'teamStore':
                    teamStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = teamStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                case 'newsStore':
                    newsStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = newsStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                case 'raceStore':
                    raceStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = raceStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                case 'trackStore':
                    trackStore.commentReplyTranslateUpdate({ ...response });
                    translatedText[key] = trackStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
                    break;
                default:
                    break;
            }
        } else {
            let response;
            switch (type) {
                case 'story':
                    response = await storyStore.commentTranslate({ object, user, commentid, holdingArrayName });
                    break;
                case 'workout':
                    response = await workoutStore.commentTranslate({ object, user, commentid, holdingArrayName });
                    break;
                case 'news':
                    response = await newsStore.commentTranslate({ object, user, commentid, holdingArrayName });
                    break;
                case 'race':
                    response = await raceStore.commentTranslate({ object, user, commentid, holdingArrayName });
                    break;
                case 'track':
                    response = await trackStore.commentTranslate({ object, user, commentid, holdingArrayName });
                    break;
            }

            //     commentTranslateUpdate({ id, commentid, value, targetLang, field = 'comment', holdingArrayName }) {

            switch(holdingStore) {
                case 'storyStore':
                    storyStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = storyStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                case 'workoutStore':
                    workoutStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = workoutStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                case 'teamStore':
                    teamStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = teamStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                case 'newsStore':
                    newsStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = newsStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                case 'raceStore':
                    raceStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = raceStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                case 'trackStore':
                    trackStore.commentTranslateUpdate({ ...response });
                    translatedText[key] = trackStore.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName, debug: true });
                    break;
                default:
                    break;
            }
        }
        this.setState({ showOriginal, translatedText });
    }

    getTranslation = ({ object, commentid, replyid, targetLang }) => {
        const { storyStore, workoutStore, teamStore, newsStore, raceStore, trackStore } = this.props.stores;
        const { holdingStore, holdingArrayName } = this.props;

        if (replyid) {
            switch(holdingStore) {
                case 'storyStore':
                    return storyStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                case 'workoutStore':
                    return workoutStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                case 'teamStore':
                    return teamStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                case 'newsStore':
                    return newsStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                case 'raceStore':
                    return raceStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                case 'trackStore':
                    return trackStore.commentReplyTranslateGet({ id: object.id, commentid, replyid, object, targetLang, holdingArrayName });
                default:
                    break;
            }
        } else {
            switch(holdingStore) {
                case 'storyStore':
                    return storyStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                case 'workoutStore':
                    return workoutStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                case 'teamStore':
                    return teamStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                case 'newsStore':
                    return newsStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                case 'raceStore':
                    return raceStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                case 'trackStore':
                    return trackStore.commentTranslateGet({ id: object.id, commentid, object, targetLang, holdingArrayName });
                default:
                    break;
            }
        }
    }

    showOriginal = (e) => {
        const { replyid, commentid } = e.target.closest('button').dataset;
        e.preventDefault();
        e.stopPropagation();
        const { showOriginal = {} } = this.state;
        showOriginal[`${commentid}${replyid ? `-${replyid}`: ''}`] = 1;
        this.setState({ showOriginal });
    }

    displayTeam = (e, teamId) => {
        const { onTeamClick, drawerLevel } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onTeamClick)) {
            onTeamClick(e, teamId);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('publicTeam', {
                teamId,
                height: drawerHeightMedium,
            }, drawerLevel ? drawerLevel + 1 : 3);
        }
	}

    openInbox = (e, fromuser) => {
        const { onUserClick, drawerLevel } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (util.isFunction(onUserClick)) {
            onUserClick(e, fromuser);
        } else {
            const { appState } = this.props.stores;
            const { drawerHeightMedium } = appState;
            appState.openDrawer('inboxDetail', {
                fromuser,
                height: drawerHeightMedium,
            }, drawerLevel ? drawerLevel + 1 : 3);
        }
    }

    displayUser = (e, user, team, teams) => {
        const { drawerLevel } = this.props;
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetPublicUser', {
            user,
            team,
            teams,
            height: drawerHeightLarge,
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Comments',
        }, drawerLevel ? drawerLevel + 1 : 3);
	}

    showOriginalBody = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginalBody: 1 });
    }

    render() {
        const { showAddComment, showAllComments, showAllReplies, viewImageIdx, showOriginal = {}, translatedText = {}, showOriginalBody } = this.state;
		const { object, callback = () => {}, col = 12, offset = 0, compact = false, type, holdingArrayName, holdingStore } = this.props;
        const { userStore, storyStore, teamStore, workoutStore, appState } = this.props.stores;
        const { user: currentUser, isAdmin } = userStore;
        const { darkmode } = appState;

        const { language = 'en' } = currentUser;

        let filteredComments = [];
        let totalComments = 0;
        let diplayedComments = 0;
        let max_comments;
        if (object.comments) {
            totalComments = object.comments.length;
            if (showAllComments) {
                filteredComments = object.comments;
            } else {
                // filteredComments = object.comments.slice(0, MAX_COMMENTS);
                max_comments = compact ? 0 : MAX_COMMENTS;
                filteredComments = object.comments.slice(Math.max(totalComments - max_comments, 0));
            }
            diplayedComments = filteredComments.length;
        }

        let team;
        let user;
        let teams;
        if (holdingStore === 'teamStore') {
            team = teamStore.findPublicTeam(object.team) || {};
            user = teamStore.findPublicUser(object.user) || {};
            teams = teamStore.findPublicTeamByMembers(object.user);
        } else if (holdingStore === 'storyStore') {
            team = storyStore.findPublicTeam(object.team) || {};
            user = storyStore.findPublicUser(object.user) || {};
            teams = storyStore.findPublicTeamByMembers(object.user);
        } else if (holdingStore === 'workoutStore') {
            team = workoutStore.findPublicTeam(object.team) || {};
            user = workoutStore.findPublicUser(object.user) || {};
            teams = workoutStore.findPublicTeamByMembers(object.user);
        }

        const image = mu.getImage({ user, team, priority: 'user', object });
        let shortname;
        let name;
        let username;
        let userLink;
        if (user && user.id) {
            shortname = mu.displayNameShort(user);
            name = mu.displayName(user);
        }

        let body;
        let translation;
        const field = object.body ? 'body' : 'comment';
        if (!showOriginalBody) {
            translation = mu.getTranslation({
                object,
                targetLang: language,
                field,
            });
        }
        body = translation || object[field];
        // console.log({ translation, body });

        const { components = [], finalBody = '' } = mu.getInlineComponents(body || '');

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={<Text id='comments.all'>Comments</Text>} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    <div class={`col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 border-bottom pb-3 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <div class='d-flex position-relative pt-3'>
                            <div
                                class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                                style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                onClick={e => this.displayUser(e, user, team, teams)}
                            >
                                {/* {!image && <div class='text-muted' style='font-size: 20px; padding-top: 10px;'>{shortname}</div>} */}
                                {!image && <div class='text-muted' style='font-size: 20px; padding-top: 9px; padding-left: 0px;'><i class='fa-thin fa-user' /></div>}
                            </div>
                            <div
                                class='flex-grow-1 pl-3 mr-2'
                            >
                                <div class='float-right'>
                                    {translation && <>
                                        {!showOriginalBody && translation ? <>
                                            <button
                                                type='button'
                                                class='btn btn-sm btn-link text-muted p-0'
                                                onClick={this.showOriginalBody}
                                            ><Text id='home.translate-show-original'>Show original</Text></button>
                                        </> : <>
                                            {/* <button
                                                type='button'
                                                class='btn btn-sm btn-link text-muted p-0'
                                                onClick={this.translate}
                                            ><Text id='home.translate-to'>See translation</Text></button> */}
                                        </>}
                                    </>}
                                </div>
                                {type === 'story' && <span onClick={e => this.displayUser(e, user, team, teams)}>
                                    <Markdown markdown={`<b>${name}</b> ${mu.replaceImages(body, object.images, language, false)}`} markedOpts={mu.getMarkdownOptions()} />
                                </span>}
                                {type === 'workout' && <>
                                    <span onClick={e => this.displayUser(e, user, team, teams)}>
                                        <Markdown markdown={`<b>${name}</b> ${mu.replaceImages(object.name, object.images, language, false)}`} markedOpts={mu.getMarkdownOptions()} />
                                    </span>
                                    <Markdown markdown={`${mu.replaceImages(body, object.images, language, false)}`} markedOpts={mu.getMarkdownOptions()} />
                                </>}
                            </div>

                            {/* <br />type: {type} */}
                        </div>
                    </div>
                    <div class={`col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 pt-3 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                        {filteredComments?.length === 0 && <>
                            <div class='text-center text-muted pb-5 pt-3'>
                                <Text id='comments.no-comments'>No comments. Be the first to add a comment.</Text>
                            </div>
                        </>}
                        {/* {showAllComments && <div>
                            <button class='btn btn-sm btn-link py-0 mb-2'
                                onClick={this.toggleComments}
                            >
                                <Text id='comments.hide-all-comments'>Skjul alle kommentarene.</Text>
                            </button>
                        </div>} */}
                        {object && filteredComments && filteredComments.reverse().map(comment => {
                            const { user } = comment;
                            let team;
                            let teams;
                            if (holdingStore === 'teamStore') {
                                team = teamStore.findPublicTeam(comment.team) || {};
                                // user = teamStore.findPublicUser(comment.user) || {};
                                teams = teamStore.findPublicTeamByMembers(comment.user);
                            } else if (holdingStore === 'storyStore') {
                                team = storyStore.findPublicTeam(comment.team) || {};
                                // user = storyStore.findPublicUser(comment.user) || {};
                                teams = storyStore.findPublicTeamByMembers(comment.user);
                            } else if (holdingStore === 'workoutStore') {
                                team = workoutStore.findPublicTeam(comment.team) || {};
                                // user = workoutStore.findPublicUser(comment.user) || {};
                                teams = workoutStore.findPublicTeamByMembers(comment.user);
                            }

                            const displayNameShort = mu.displayNameShort(user);
                            const displayName = mu.displayName(user);
                            const userImage = user.image || (user.images ? user.images[0] : undefined);
                            const hasLikedComment = comment.likes && comment.likes?.findIndex(e => e.user === currentUser.id) > -1;

                            let filteredReplises = [];
                            let totalReplies = 0;
                            let diplayedReplies = 0;
                            if (comment.comments) {
                                totalReplies = comment.comments.length;
                                if (showAllReplies[comment.id]) {
                                    filteredReplises = comment.comments;
                                } else {
                                    filteredReplises = comment.comments.slice(0, max_comments);
                                }
                                diplayedReplies = filteredReplises.length;
                            }

                            const images = [];
                            if (comment.images) {
                                const imgs = toJS(comment.images);
                                images.push(...imgs);
                            }
                            const imageIdx = viewImageIdx[comment.id] ? viewImageIdx[comment.id] : 0;
                            const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

                            const { currentLocationGeocode = {} } = comment;

                            const translation = this.getTranslation({
                                object,
                                commentid: comment.id,
                                targetLang: language,
                            }) || translatedText[comment.id];

                            let body = translation || comment.comment;
                            if (showOriginal[comment.id]) {
                                body = comment.comment;
                            }

                            return (
                                <div class='row mt-3' key={`comment-${comment.id}`}>
                                    <div class='col-12 d-flex mb-2 position-relative'>
                                        <a href={`/inbox/${user.uuidv4}`} onClick={(e) => this.openInbox(e, user.uuidv4)}>
                                        <div
                                            class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                                            style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                            onClick={e => this.displayUser(e, user, team, teams)}
                                        >
                                            {/* {!user.image && <div class='text-muted' style='font-size: 15px; padding-top: 5px;'>{displayNameShort}</div>} */}
                                            {!image && <div class='text-muted' style='font-size: 15px; padding-top: 5px; padding-left: 1px;'><i class='fa-thin fa-user' /></div>}

                                        </div>
                                        </a>
                                        <div
                                            class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'
                                        >
                                            <div class='rounded rounded-lg'>
                                                <div class='float-right text-primary'>
                                                    {hasLikedComment ? <>
                                                        <i
                                                            class='fa-solid fa-heart'
                                                            data-id={comment.id}
                                                            onClick={this.unlikeComment}
                                                        />
                                                    </> : <>
                                                        <i
                                                            class='fa-regular fa-heart'
                                                            data-id={comment.id}
                                                            onClick={this.likeComment}
                                                        />
                                                    </>}
                                                </div>
                                                <div onClick={e => this.displayUser(e, user, team, teams)}>
                                                    <Markdown markdown={mu.replaceImages(`<b>${displayName}</b> ${body}`, comment.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                                </div>
                                                <div class='container-fluid px-3 mt-1'>
                                                    {currentImage && <div class='clearfix row'>
                                                        <div
                                                            class={`col-12 px-0 overflow-hidden`}
                                                            style={`max-height: 30vh;`}
                                                        >
                                                            <img loading='lazy' class={`img-fluid`} style='min-width: 100px;' src={currentImage.s3LargeLink} />
                                                        </div>
                                                    </div>}
                                                    {images && images.length > 1 && <div class='clearfix d-flex flex-wrap row'>
                                                        {images.map((img, idx) => {
                                                            return (<Fragment key={`image-${img.id}`}>
                                                                <div
                                                                    class={`col px-0 overflow-hidden`}
                                                                    style={`max-height: 100px; min-width: 100px;`}
                                                                >
                                                                    <img loading='lazy' class={`img-fluid border`} style='min-width: 100px;' src={img.s3SmallLink} onClick={this.viewImage} data-id={comment.id} data-idx={idx} />
                                                                </div>
                                                            </Fragment>);
                                                        })}
                                                    </div>}
                                                </div>
                                            </div>
                                            <div>
                                                {/* <button
                                                    type='button'
                                                    class={`btn btn-sm btn-link py-0 ${hasLikedComment ? 'text-primary' : ''}`}
                                                    onClick={this.likeComment}
                                                    data-id={comment.id}
                                                >
                                                    <Text id='comments.like'>Lik</Text>
                                                </button>
                                                - */}
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link p-0 disabled'
                                                >
                                                    {util.formatDistance(comment.createdDate, new Date(), { locale: 'no-NB' })}
                                                    {comment.updatedDate && <>
                                                        <span class='font-weight-light ml-2'>(Edited {util.formatDistance(comment.updatedDate, new Date(), { locale: 'no-NB' })})</span>
                                                    </>}
                                                </button>

                                                <span class='mx-2'>·</span>
                                                <button
                                                    type='button'
                                                    class='btn btn-sm btn-link p-0'
                                                    onClick={this.replyComment}
                                                    data-id={comment.id}
                                                >
                                                    <Text id='comments.comment-reply'>Reply</Text>
                                                </button>

                                                {comment.language !== language && <>
                                                    <span class='mx-2'>·</span>
                                                    {!showOriginal[comment.id] && translation ? <>
                                                        <button
                                                            type='button'
                                                            class='btn btn-sm btn-link p-0'
                                                            data-commentid={comment.id}
                                                            onClick={this.showOriginal}
                                                        >
                                                            <Text id='home.translate-show-original'>Show original</Text>
                                                        </button>
                                                    </> : <>
                                                        <button
                                                            type='button'
                                                            class='btn btn-sm btn-link p-0'
                                                            onClick={this.translate}
                                                            data-commentid={comment.id}
                                                        >
                                                            <Text id='home.translate-to'>See translation</Text>
                                                        </button>
                                                    </>}
                                                </>}

                                                {isAdmin && currentLocationGeocode && currentLocationGeocode.formatted_address && <>
                                                    <span class='mx-2'>·</span>
                                                    <small class='text-muted'>
                                                        <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formatted_address}
                                                    </small>
                                                </>}
                                            </div>
                                        </div>
                                        {/* {comment.likes && comment.likes.length > 0 && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                            <i class='fas fa-thumbs-up text-primary' /> {comment.likes.length}
                                        </small>} */}
                                    </div>
                                    <div class='col-11 offset-1 mb-2'>

                                        {comment && filteredReplises && filteredReplises.map(reply => {
                                            const { user } = reply;
                                            let team;
                                            let teams;
                                            if (holdingStore === 'teamStore') {
                                                team = teamStore.findPublicTeam(reply.team) || {};
                                                // user = teamStore.findPublicUser(reply.user) || {};
                                                teams = teamStore.findPublicTeamByMembers(reply.user);
                                            } else if (holdingStore === 'storyStore') {
                                                team = storyStore.findPublicTeam(reply.team) || {};
                                                // user = storyStore.findPublicUser(reply.user) || {};
                                                teams = storyStore.findPublicTeamByMembers(reply.user);
                                            } else if (holdingStore === 'workoutStore') {
                                                team = workoutStore.findPublicTeam(reply.team) || {};
                                                // user = workoutStore.findPublicUser(reply.user) || {};
                                                teams = workoutStore.findPublicTeamByMembers(reply.user);
                                            }

                                            const displayNameShort = mu.displayNameShort(user);
                                            const displayName = mu.displayName(user);

                                            const userImage = user.image || (user.images ? user.images[0] : undefined);
                                            const hasLikedReply = reply.likes && reply.likes?.findIndex(e => e.user === currentUser.id) > -1;

                                            const replyTranslation = this.getTranslation({
                                                object,
                                                commentid: comment.id,
                                                replyid: reply.id,
                                                targetLang: language,
                                            }) || translatedText[`${comment.id}-${reply.id}`];

                                            let replyBody = replyTranslation || reply.comment;
                                            if (showOriginal[`${comment.id}-${reply.id}`]) {
                                                replyBody = reply.comment;
                                            }

                                            return (
                                                <div class='row mt-2' key={`reply-${reply.id}`}>
                                                    <div class='col-12 d-flex mb-2 position-relative'>
                                                        <div
                                                            class={`text-center border rounded-circle imageRounded imageRoundedSmall`}
                                                            style={`${userImage ? `background-image: url("${userImage.s3SmallLink}"); background-size: cover;` : ''} border-width: 3px !important;`}
                                                            onClick={e => this.displayUser(e, user, team, teams)}
                                                        >
                                                            {!user.image && <div class='text-muted' style='font-size: 15px; padding-top: 5px; padding-left: 1px;'><i class='fa-thin fa-user' /></div>}
                                                        </div>
                                                        <div
                                                            class='flex-grow-1 pl-3 pt-0' style='line-height: 1.2em;'
                                                        >
                                                            <div class='float-right text-primary'>
                                                                {hasLikedReply ? <>
                                                                    <i
                                                                        class='fa-solid fa-heart'
                                                                        data-comment={comment.id}
                                                                        data-id={reply.id}
                                                                        onClick={this.unlikeReply}
                                                                    />
                                                                </> : <>
                                                                    <i
                                                                        class='fa-regular fa-heart'
                                                                        data-comment={comment.id}
                                                                        data-id={reply.id}
                                                                        onClick={this.likeReply}
                                                                    />
                                                                </>}
                                                            </div>
                                                            <div class='' onClick={e => this.displayUser(e, user, team, teams)}>
                                                                <Markdown markdown={mu.replaceImages(`<b>${displayName}</b> ${replyBody}`, reply.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                                                            </div>
                                                            <small>
                                                                {/* <button
                                                                    type='button'
                                                                    class={`btn btn-sm btn-link p-0 ${hasLikedReply ? 'text-primary' : ''}`}
                                                                    onClick={this.likeReply}
                                                                    data-comment={comment.id}
                                                                    data-id={reply.id}
                                                                >
                                                                    <Text id='comments.like'>Lik</Text>
                                                                </button> */}
                                                                {/* <span class='mx-2'>·</span> */}
                                                                <button type='button' class='btn btn-sm btn-link p-0 disabled'>
                                                                    {util.formatDistance(reply.createdDate, new Date(), { locale: 'no-NB' })}
                                                                </button>
                                                                <span class='mx-2'>·</span>
                                                                <button
                                                                    type='button'
                                                                    class='btn btn-sm btn-link p-0'
                                                                    onClick={this.replyComment}
                                                                    data-id={comment.id}
                                                                >
                                                                    <Text id='comments.comment-reply'>Reply</Text>
                                                                </button>
                                                                {reply.language !== language && <>
                                                                    <span class='mx-2'>·</span>
                                                                    {!showOriginal[`${comment.id}-${reply.id}`] && replyTranslation ? <>
                                                                        <button
                                                                            type='button'
                                                                            class='btn btn-sm btn-link p-0'
                                                                            data-commentid={comment.id}
                                                                            data-replyid={reply.id}
                                                                            onClick={this.showOriginal}
                                                                        >
                                                                            <Text id='home.translate-show-original'>Show original</Text>
                                                                        </button>
                                                                    </> : <>
                                                                        <button
                                                                            type='button'
                                                                            class='btn btn-sm btn-link p-0'
                                                                            onClick={this.translate}
                                                                            data-commentid={comment.id}
                                                                            data-replyid={reply.id}
                                                                        >
                                                                            <Text id='home.translate-to'>See translation</Text>
                                                                        </button>
                                                                    </>}
                                                                </>}
                                                            </small>
                                                        </div>
                                                        {/* {reply.likes && <small class='position-absolute bg-light shadow-sm rounded-lg py-0 px-1' style='bottom: 20px; right: 15px;'>
                                                            <i class='fas fa-thumbs-up text-primary' /> {reply.likes.length}
                                                        </small>} */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {totalReplies - diplayedReplies > 0 && <div>
                                            <button
                                                type='button'
                                                class='btn btn-sm btn-link py-0'
                                                onClick={this.toggleReplies}
                                                data-id={comment.id}
                                            >
                                                <Text id='comments.show-more' fields={{ more: totalReplies - diplayedReplies }}>
                                                    Vis {totalReplies - diplayedReplies} flere kommentarer...
                                                </Text>
                                            </button>
                                        </div>}

                                    </div>
                                    {showAddComment[comment.id] && <div class='col-11 offset-1 d-flex mb-2 position-relative'>
                                        <CommentReply
                                            type={type}
                                            stores={this.props.stores}
                                            object={object}
                                            callback={callback}
                                            commentId={comment.id}
                                            inputRef={c => this.inputRef[comment.id] = c}
                                            inputRefObject={this.inputRef}
                                            holdingArrayName={holdingArrayName}
                                            holdingStore={holdingStore}
                                        />
                                    </div>}
                                </div>
                            );
                        })}
                        {totalComments - diplayedComments > 0 && <div>
                            <button
                                type='button'
                                class='btn btn-sm btn-link py-0'
                                onClick={this.toggleComments}
                            >
                                <Text id='comments.show-more' fields={{ more: totalComments - diplayedComments }}>
                                    Vis {totalComments - diplayedComments} flere kommentarer...
                                </Text>
                            </button>
                        </div>}
                        {showAllComments && <div>
                            <button
                                type='button'
                                class='btn btn-sm btn-link py-0'
                                onClick={this.toggleComments}
                            >
                                <Text id='comments.hide-all-comments'>Skjul alle kommentarene.</Text>
                            </button>
                        </div>}
                    </div>

                    <div class={`row fixed-bottom px-3 py-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <div class={`col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3`}>
                            <CommentsAdd
                                stores={this.props.stores}
                                type={type}
                                object={object}
                                inputRef={c => this.inputRef[object.id] = c}
                                inputRefObject={this.inputRef}
                                col='10'
                                offset='2'
                                key={`story-comment-${object.id}`}
                                holdingArrayName={holdingArrayName}
                                holdingStore={holdingStore}
                            />
                        </div>
                    </div>
                </WidgetBody>
            </div>
        </>);
    }
}

export default Comments;
