import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import DogSimplePublic from '../../components/dog/simplePublic';
import DogScroller from '../../components/dog/scroller';

import mu from '../../lib/musher-util';

const STOP_STATUSES = [2, 9];
const WARN_STATUSES = [4, 8];


function getFatigueLevel(wo, team) {
    if (team) {
        if (STOP_STATUSES.indexOf(team.status) > -1) {
            return 'danger';
        } else if (WARN_STATUSES.indexOf(team.status) > -1) {
            return 'warning';
        }
    }
    if (wo) {
        if (wo.distanceKm > 50) {
            return 'danger';
        } else if (wo.distanceKm > 30) {
            return 'warning';
        }
    }
    return 'success';
}

@observer
class Team extends Component {
    render() {
        const {
            team = {},
            teams = [],
            showTeam,
            showInfo,
            showName = true,
            showPosition = true,
            showAge = false,
            className,
            size,
            onTeamClick = () => {},
        } = this.props;
		const { userStore, appState } = this.props.stores;
        const { darkmode } = appState;
        const { isAdmin, user, dogs: allDogs } = userStore;
        const { language = 'en' } = user;
        const dogs = userStore.findDogsByTeam(team.id);

        let containerWidth = '92px';
        let imageClasses = '';
        let fontSize = '35px';
        if (size === 'small') {
            containerWidth = '50px';
            imageClasses = 'imageRoundedSmall';
            fontSize = '20px';
        } else if (size === 'medium') {
            containerWidth = '60px';
            imageClasses = 'imageRoundedMedium';
            fontSize = '25px';
        }

        const teamImage = team.image?.s3SmallLink;

        return (
            <div
                class={`mb-2 position-relative ${className} clearfix`}
                style={`width: ${containerWidth}`}
                onTouchstart={(e) => { e.stopPropagation(); }}
                onTouchend={(e) => { e.stopPropagation(); }}
                onTouchmove={(e) => { e.stopPropagation(); }}
            >

                <div class={`d-flex flex-column rounded-lg position-relative ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    style='
                        border-radius: 10px !important;
                    '
                >
                    <div
                        class='w-100 d-flex justify-content-center'
                        style={`
                            height: 150px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-color: #a0a0a0; ${teamImage ? `background-image: url("${teamImage}");` : ''}
                            width: 100% !important;
                            border-top-left-radius: 10px !important;
                            border-top-right-radius: 10px !important;
                        `}
                    />
                    <div
                        class='w-100 bg-light text-left px-2 py-2 d-flex justify-content-between'
                    >
                        <div class='text-overflow'>
                            <nobr>
                                <a href={`/teams/public/${team.uuidv4}`} onClick={(e) => onTeamClick(e, team.uuidv4, teams)} class='stretched-link'>
                                    {showName && <>
                                        <span class={`mb-1 font-weight-normal ${darkmode ? 'text-white' : 'text-dark'}`}>
                                            <nobr>
                                                {team.shortname || team.name}
                                            </nobr>
                                        </span>
                                    </>}
                                </a>
                            </nobr>
                        </div>

                        {team.totalDogs && <>
                            <div class='text-muted'>
                                <nobr>
                                    <i class='fa-regular fa-dog' /> {team.totalDogs}
                                </nobr>
                            </div>
                        </>}

                    </div>
                    {/* <div
                        class='w-100 bg-light text-left px-2 pb-1'
                        style='
                            border-bottom-left-radius: 10px !important;
                            border-bottom-right-radius: 10px !important;
                            height: 90px;
                        '
                    >
                        <DogScroller stores={this.props.stores} dogs={dogs.map(d => d.id)} allDogs={allDogs} onDogClick={this.onDogClick} />
                    </div> */}
                </div>

            </div>
        );
    }
}

export default Team;
