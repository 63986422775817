import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';

import MiniGraph from '../../components/workout/miniGraph';
import Gauge from '../../components/gauge';

import WorkoutStatus from '../../components/workout/status';
import WorkoutGraph from '../../components/workout/graph';
import WorkoutTypeHeader from '../../components/workout/typeHeader';

import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

function kmToMiles(value) {
    return util.format(0.621371 * value, 1);
}

@observer
class WorkoutGoals extends Component {
    openDrawer = e => {
		e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
		const { page, submenu } = e.target.closest('.widget-container').dataset;

        appState.openDrawer2(page, {
            onLinkClick: this.toggleDrawer,
            height: drawerHeightMedium,
            submenu,
        });
    }

    toggleDrawer = (e) => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const {
            showHeader = true,
        } = this.props;

        const { userStore, workoutPlanStore } = this.props.stores;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;
        const { language = 'en', settings = {} } = user;
        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const currentDay = new Date().getDate();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm = 0,
			elevation: weekElevation = 0,
			duration: weekDuration = 0,
			speedAvg: weekSpeedAvg = 0,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

        const planWeekDistanceKm = workoutPlanStore.sumWorkoutPlanWeek(currentYear, currentWeek, 'distance');
        const planMonthDistanceKm = workoutPlanStore.sumWorkoutPlanMonth(parseInt(year, 10), parseInt(month, 10)) || 0;
        const planToDateDistanceKm = workoutPlanStore.sumWorkoutPlanDaysBack(365, `${seasonStart}-08-01`);
        const planSeasonDistanceKm = workoutPlanStore.sumWorkoutPlanSeason(currentYear);

		const summaryMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'distanceKm', true) || 0;
        const workoutSummaryMonth = userStore.findWorkoutSummaryMonth(currentYear, currentMonth - 1, 'all') || {};

        const weekToGo = planWeekDistanceKm - weekDistanceKm;
        const monthToGo = planMonthDistanceKm - summaryMonth;
        const seasonToGo = planToDateDistanceKm - workoutSeasonTotal?.distanceKm;

        const { workoutPlan, workoutPlans } = workoutPlanStore;


		let percentComplete;
		if (planWeekDistanceKm) {
			percentComplete = Math.floor(weekDistanceKm / planWeekDistanceKm * 100);
		}
		let percentCompleteMonth;
		if (planMonthDistanceKm) {
			percentCompleteMonth = Math.floor(summaryMonth / planMonthDistanceKm * 100);
		}

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.goals'>Goals</Text>
                        </h3>
                    </div>}

                    <WorkoutTypeHeader stores={this.props.stores} />

                    <Widget stores={this.props.stores} onClick={this.openDrawer} page='workout' submenu='workoutplan'>
                        <h5>
                            <Text id='workouts.this-week'>Current week</Text>
                            <span class='mx-2'>·</span>
                            <Distance stores={this.props.stores} value={planWeekDistanceKm} smallUnit={false} />
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={weekDistanceKm} smallUnit={false} />
                                </h5>
                            </div>
                            {weekToGo < 0 ? <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.infront-plan'>In front of plan</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={Math.abs(weekToGo)} smallUnit={false} />
                                    </h5>
                                </div>
                            </> : <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.to-go'>To go</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={weekToGo} smallUnit={false} />
                                    </h5>
                                </div>
                            </>}
                            <div class='col d-flex justify-content-center'>
                                {planWeekDistanceKm >= 0 && (
                                    <Gauge
                                        key={`gauge-${percentComplete}`}
                                        radius={55}
                                        percent={percentComplete}
                                        prefix='%'
                                        total={settings.imperial ? `${kmToMiles(planWeekDistanceKm)} mi` : `${util.format(planWeekDistanceKm, 0)} km`}
                                        completed={settings.imperial ? `${kmToMiles(weekDistanceKm)} mi` : `${util.format(weekDistanceKm, 0)} km`}
                                    />
                                )}
                            </div>
                        </div>
                        <div class='text-right text-muted mt-2'>
                            <small>
                                <Text id='workout.see-workoutplan'>See workoutplan</Text>
                            </small>
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores} onClick={this.openDrawer} page='workout' submenu='workoutplan'>
                        <h5>
                            <Text id='workouts.this-week'>Current month</Text>
                            <span class='mx-2'>·</span>
                            <Distance stores={this.props.stores} value={planMonthDistanceKm} smallUnit={false} />
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={summaryMonth} smallUnit={false} />
                                </h5>
                            </div>
                            {monthToGo < 0 ? <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.infront-plan'>In front of plan</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={Math.abs(monthToGo)} smallUnit={false} />
                                    </h5>
                                </div>
                            </> : <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.to-go'>To go</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={monthToGo} smallUnit={false} />
                                    </h5>
                                </div>
                            </>}
                            <div class='col d-flex justify-content-center'>
                                {planMonthDistanceKm >= 0 && (
                                    <Gauge
                                        key={`gauge-${percentCompleteMonth}`}
                                        radius={55}
                                        percent={percentCompleteMonth}
                                        prefix='%'
                                        total={settings.imperial ? `${kmToMiles(planMonthDistanceKm)} mi` : `${util.format(planMonthDistanceKm, 0)} km`}
                                        completed={settings.imperial ? `${kmToMiles(summaryMonth)} mi` : `${util.format(summaryMonth, 0)} km`}
                                    />
                                )}
                            </div>
                        </div>
                        <div class='text-right text-muted mt-2'>
                            <small>
                                <Text id='workout.see-workoutplan'>See workoutplan</Text>
                            </small>
                        </div>
                    </Widget>


                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    <Widget stores={this.props.stores} onClick={this.openDrawer} page='workout' submenu='workoutplan'>
                        <h5>
                            <Text id='workouts.status-this-season'>Current Season</Text>
                            <span class='mx-2'>·</span>
                            <Distance stores={this.props.stores} value={planSeasonDistanceKm} smallUnit={false} />
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={workoutSeasonTotal?.distanceKm} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.to-go'>To go</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={seasonToGo < 0 ? 0 : seasonToGo} smallUnit={false} />
                                </h5>
                            </div>
                            {seasonToGo < 0 ? <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.infront-plan'>In front of plan</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={Math.abs(seasonToGo)} smallUnit={false} />
                                    </h5>
                                </div>
                            </> : <>
                                <div class='col'>
                                    <small class='text-muted'>
                                        <Text id='workout.behind-plan'>Behind plan</Text>
                                    </small>
                                    <h5 class='font-weight-light'>
                                        <Distance stores={this.props.stores} value={seasonToGo} smallUnit={false} />
                                    </h5>
                                </div>
                            </>}
                        </div>
                        <WorkoutStatus
                            stores={this.props.stores}
                            currentTeam={currentTeam}
                            year={year}
                            showHeader={false}
                            showAddButton={false}
                        />
                        <div class='text-right text-muted mt-2'>
                            <small>
                                <Text id='workout.see-workoutplan'>See workoutplan</Text>
                            </small>
                        </div>
                    </Widget>

                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    <Widget stores={this.props.stores}>
                        <h5>
                            <Text id='workouts.details-this-season'>Season details</Text>
                        </h5>
                        <WorkoutGraph
                            stores={this.props.stores}
                            isLoading={false}
                            key={`graph-${workoutPlan.id}-${workoutPlans.length}`}
                            showHeader={false}
                            showElevation={false}
                        />
                    </Widget>

                </div>
            </div>
        </>);
    }
}

export default WorkoutGoals;
