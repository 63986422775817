import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const initialState = {};

@observer
class DisplayLikesAndComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    toggleViews = () => {
        const { showViewList } = this.state;
        const { isTeamAdmin, isAdmin } = this.props;
        if (isTeamAdmin || isAdmin) {
            this.setState({
                showViewList: !showViewList,
            });
        }
    }

    render() {
        const { showViewList } = this.state;
        const { storyStore, teamStore, workoutStore } = this.props.stores;
        const { views, object, isTeamAdmin, isAdmin,
            type = 'story', onTeamClick = () => {}, onUserClick = () => {} } = this.props;

        const viewUsersArrays = [];
        Object.entries(object.statistics).forEach(([year, yearObject]) => {
            // console.log({ year, yearObject });
            Object.entries(yearObject).forEach(([week, weekObject]) => {
                // console.log({ week, weekObject });
                if (weekObject.viewUsers) {
                    viewUsersArrays.push(weekObject.viewUsers);
                }
            });
        });
        // const viewUsers = viewUsersArrays.flat().slice(0, 50);
        // console.log([].concat(...arr));
        const viewUsers = [].concat(...viewUsersArrays).slice(0, 50);

		return (<>
            <div class='d-flex justify-content-end ml-2 text-overflow'>
                <nobr>
                    <small class='text-muted' onClick={this.toggleViews}>
                        {views} <Text id='story.views'>views</Text>
                    </small>
                </nobr>
            </div>
            {showViewList && <div class='mt-3 mb-3'>
                {viewUsers && viewUsers.map((userId) => {
                    let user;
                    let teams;
                    if (type === 'story') {
                        user = storyStore.findPublicUser(userId);
                        teams = storyStore.findPublicTeamByMembers(userId);
                    } else if (type === 'team') {
                        user = teamStore.findPublicUser(userId);
                        teams = teamStore.findPublicTeamByMembers(userId);
                    } else if (type === 'workout') {
                        user = workoutStore.findPublicUser(userId);
                        teams = workoutStore.findPublicTeamByMembers(userId);
                    }
                    if (!user) {
                        return '';
                    }
                    let publicTeam;
                    if (util.isArray(teams)) {
                        publicTeam = teams.find(e => e.public === 1);
                    }
                    const image = mu.getImage({ user, team: publicTeam, priority: 'user' });
                    const shortname = mu.displayNameShort(user);
                    const name = mu.displayName(user, true);
                    return (<div class='d-flex flex-row position-relative font-weight-normal mb-1'>
                        {publicTeam ? <>
                            <a href={`/teams/public/${publicTeam.uuidv4}`} title={publicTeam.name} onClick={(e) => onTeamClick(e, publicTeam.uuidv4)} class='stretched-link'>
                                <div
                                    class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                    style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''}`}
                                    >
                                    {!image && <div class='' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                                </div>
                            </a>
                            <div class='ml-2 '>
                                <small>
                                    {name} <span class='font-weight-normal'>- {publicTeam.name}</span>
                                </small>
                            </div>
                        </> : <>
                            <div
                                class={`text-center border rounded-circle imageRounded imageRoundedThumb`}
                                style={`${image ? `background-image: url("${image}"); background-size: cover;` : ''} opacity: 0.5;`}
                            >
                                {!image && <div class='text-muted' style='font-size: 12px; padding-top: 3px;'>{shortname}</div>}
                            </div>
                            <div class='ml-2'>
                                <small>
                                    {name}
                                </small>
                            </div>
                        </>}
                    </div>);
                })}
                {views > viewUsers.length && <>
                    <small class='text-muted'>
                        <Text id='story.limited-to'>Limited to max 50 users.</Text>
                    </small>
                </>}
            </div>}
		</>);
	}
}

export default DisplayLikesAndComments;
