import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const initialState = {};

@observer
class PremiumBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    render() {
        return (
            <span class='badge badge-pill badge-warning float-right'><i class='fas fa-award mr-2' /> <Text id='premium.title'>Premium</Text></span>

        );
    }
}

export default PremiumBadge;
