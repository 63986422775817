import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

function kmtToMph(value) {
    return 0.621371 * value;
}

function kmToMiles(value) {
    return 0.621371 * value;
}

function meterToFeet(value) {
    return 3.28084 * value;
}

class WorkoutPlanStore extends StoreModel {
    constructor() {
        super('workoutPlan', {
            namePlural: 'workoutPlans',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/workoutplans/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/workoutplans/',
                    params: {},
                },
                save: {
                    url: '/api/workoutplans/',
                    params: {},
                },
            },
        });
    }

    @observable newPlan = {};

    @observable tips = {};

    @observable workoutPlan = {};

    @observable newWorkoutPlan = {};

    @observable workoutPlans = [];

    @observable graphPlanWeekDistanceCurrent = [];

    @observable graphPlanDaysDistanceCurrent = [];

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('WorkoutPlanStore.cleanupMemory');
        }
        this.localUpdateField('workoutPlans', []);
        this.localUpdateField('graphPlanWeekDistanceCurrent', []);
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    // @action
    // afterLoad(response) {
    //     console.log(response);
    // }

    @action
    findWorkoutPlanSummaryDay(year, month, day, currentMonth) {
        const wp = this.workoutPlan;
        if (!wp || !wp.days) {
            return null;
        }
        const idx = wp.days?.findIndex(e => e.year === year
            && e.month === month && e.day === day
            && (currentMonth ? e.month === currentMonth : true)
        );
        // console.log({ year, month, day, idx, wp: wp.days[idx] });
        if (idx > -1) {
            return toJS(wp.days[idx]);
        }
    }

    @action
    findWorkoutPlanSummaryWeek(year, week) {
        const wp = this.workoutPlan;
        if (!wp || !wp.weeks) {
            return null;
        }
        const idx = wp.weeks?.findIndex(e => e.year === year && e.week === week);
        // console.log({ year, week, idx, wp: wp.weeks[idx] });
        // console.log({ wp });

        if (idx > -1) {
            return toJS(wp.weeks[idx]);
        }
    }

    @action
    findWorkoutPlanSummaryMonth(year, month) {
        const wp = this.workoutPlan;
        if (!wp || !wp.months) {
            return null;
        }
        const idx = wp.months?.findIndex(e => e.year === year && e.month === month);
        if (idx > -1) {
            return toJS(wp.months[idx]);
        }
    }

    @action
    sumWorkoutPlanMonth(year, month, field = 'distance') {
        const wp = this.workoutPlan;
        if (!wp || !wp.days) {
            return null;
        }
        let result = wp.days.filter(e => e.year === year && e.month === month && e[field] > 0)
            .map(e => e[field])
            .reduce((acc, e) => acc + e, 0);
        if (result === 0) {
            const weeksInMonth = util.getWeeksInMonth(month, year);
            const weeks = weeksInMonth.map(e => e.week);
            result = wp.weeks.filter(e => e.year === year && weeks?.indexOf(e.week) > -1 && e[field] > 0)
                .map(e => e[field])
                .reduce((acc, e) => acc + e, 0);
        }
        if (result === 0) {
            result = wp.months.filter(e => e.year === year && e.month === month && e[field] > 0)
                .map(e => e[field])
                .reduce((acc, e) => acc + e, 0);
        }
        return result;
    }

    @action
    sumWorkoutPlanWeek(year, week, field = 'distance') {
        const wp = this.workoutPlan;
        if (!wp || !wp.days) {
            return null;
        }
        let result = wp.days.filter(e => e.year === year && e.week === week && e[field] > 0)
            .map(e => e[field])
            .reduce((acc, e) => acc + e, 0);

        if (result === 0) {
            result = wp.weeks.filter(e => e.year === year && e.week === week && e[field] > 0)
                .map(e => e[field])
                .reduce((acc, e) => acc + e, 0);
        }
        return result;
    }

    @action
    sumWorkoutPlanDaysBack(daysBack = 7, dateBack) {
        let days = util.getDaysInRange(new Date(), -daysBack, true);
        if (dateBack) {
            const today = new Date();
            const from = util.parseInputDate(dateBack);
            const difference = today.getTime() - from.getTime();
            const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
            days = util.getDaysInRange(new Date(), -totalDays, true);
        }
        const wp = this.workoutPlan;
        if (!wp || !wp.days) {
            return null;
        }
        const result = wp.days.filter((e) => {
            const idx = days?.findIndex(d => d.day === e.day && d.month === e.month && d.year === e.year);
            if (idx > -1 && e.distance > 0) {
                return true;
            }
            return false;
        })
            .map(e => e.distance)
            .reduce((acc, e) => acc + e, 0);
        return result;
    }

    @action
    sumWorkoutPlanSeason(year) {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = parseInt(year || util.getYear(), 10);
        const seasonStart = parseInt(currentMonth < 8 ? currentYear - 1 : currentYear, 10);
        // const prevYear = currentYear - 1;

        const seasonWeeks = util.weekRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        // const prevSeasonWeeks = util.weekRange(`${prevYear}-08-01`, `${prevYear + 1}-07-31`);

        if (!this.workoutPlan || !this.workoutPlan.days) {
            return 0;
        }

        // eslint-disable-next-line no-case-declarations
        const result = seasonWeeks.map(w => {
            const distanceDay = this.workoutPlan.days
                .filter(e => e.week === w.week && e.year === w.year)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            const distanceWeek = this.workoutPlan.weeks
                .filter(e => e.week === w.week && e.year === w.year)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            return distanceDay || distanceWeek || 0;
        });
        return result.reduce((partialSum, a) => partialSum + a, 0);;
    }

    @action
    prepareWeekGraphs({ year, imperial }) {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = parseInt(year || util.getYear(), 10);
        const seasonStart = parseInt(currentMonth < 8 ? currentYear - 1 : currentYear, 10);
        // const prevYear = currentYear - 1;

        const seasonWeeks = util.weekRange(`${seasonStart}-08-01`, `${seasonStart + 1}-07-31`);
        // const prevSeasonWeeks = util.weekRange(`${prevYear}-08-01`, `${prevYear + 1}-07-31`);

        if (!this.workoutPlan || !this.workoutPlan.days) {
            return null;
        }

        // eslint-disable-next-line no-case-declarations
        const graphPlanWeekDistanceCurrent = seasonWeeks.map((w, idx) => {
            const distanceDay = this.workoutPlan.days
                .filter(e => e.week === w.week && e.year === w.year)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            const distanceWeek = this.workoutPlan.weeks
                .filter(e => e.week === w.week && e.year === w.year)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            return {
                x: idx,
                // y: imperial ? kmToMiles(distanceDay || distanceWeek || 0) : (distanceDay || distanceWeek || 0),
                y: distanceDay || distanceWeek || 0,
                week: w.week,
                year: w.year,
            };
        });
        // console.log({ graphPlanWeekDistanceCurrent });
        this.graphPlanWeekDistanceCurrent = graphPlanWeekDistanceCurrent;
    }

    @action
    prepareDaysGraphs({ month, year, imperial }) {
        if (!this.workoutPlan || !this.workoutPlan.days) {
            return null;
        }
        const daysInMonth = util.daysInMonth(month, year);
        const days = util.range(1, daysInMonth);
        let total = 0;

        // eslint-disable-next-line no-case-declarations
        const graphPlanDaysDistanceCurrent = days.map((day, idx) => {
            const distanceDay = this.workoutPlan.days
                .filter(e => e.year === year && e.month === month && e.day === day)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            const distanceWeek = this.workoutPlan.weeks
                .filter(e => e.year === year && e.month === month && e.day === day)
                .map(e => e.distance || 0)
                .reduce((acc, val) => acc + val, 0);
            total += distanceDay || distanceWeek || 0;
            return {
                x: idx,
                // y: imperial ? kmToMiles(distanceDay || distanceWeek || 0) : (distanceDay || distanceWeek || 0),
                y: total,
                day,
                month,
                year,
            };
        });
        // console.log({ graphPlanWeekDistanceCurrent });
        this.graphPlanDaysDistanceCurrent = graphPlanDaysDistanceCurrent;
    }

    @action resetGraphPlanWeekDistanceCurrent() {
        this.graphPlanWeekDistanceCurrent = [];
    }

    async createWorkoutPlan({ newPlan }) {
        const response = await util.fetchApi(`/api/workoutplans/`, { publish: true, method: 'POST' }, { ...newPlan, doMerge: 1 });
        switch (response.status) {
            case 201:
                return response.data;
            case 409:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }
}

const store = new WorkoutPlanStore();
export default store;
