import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import style from './style.css';

const initialState = {
    mouseData: {},
};

@observer
class Gauge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.svgRef = null;
    }

    render() {
        const {
            radius = 50,
            heightAdd = 0,
            color = 'success',

            showPercentText = true,
            percent = 0,
            prefix = '',

            showCenterText= false,
            centerText,
            centerTextSize,
            centerTextX,
            centerTextY,

            total = '',
            totalY = '90%',
            totalX = '50%',
            totalTextAnchor = 'middle',
                        
            completed = '',
            completedY = '65%',
            completedX = '50%',
            completedTextAnchor = 'middle',
        } = this.props;
        const strokeWidth = radius * 0.3;
        const innerRadius = radius - strokeWidth / 2;

        const strokeWidthGauge = radius * 0.3;

        const circumference = innerRadius * 2 * Math.PI;
        const arc = circumference * (270 / 360);
        const dashArray = `${arc} ${circumference}`;
        const transform = `rotate(135, ${radius}, ${radius})`;

        const percentNormalized = Math.min(Math.max(percent, 0), 100);
        const offset = arc - (percentNormalized / 100) * arc;

        const textSize = centerTextSize || radius / 2.5;
        const textSizeSmall = radius / 5;

        return (
            <>
                <svg xmlns='http://www.w3.org/2000/svg' class={style.svg}
                    // viewBox={`0 0 ${radius * 2} ${radius * 2}`}
                    width={radius * 2}
                    height={radius * 2 + heightAdd}
                    preserveAspectRatio='none'
                    ref={c => this.svgRef = c}
                >

                    <circle
                        class={`${style.gaugeBackground}`}
                        cx={radius}
                        cy={radius}
                        r={innerRadius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={dashArray}
                        strokeLinecap='round'
                        transform={transform}
                    />
                    
                    <circle
                        class={`${style[color]} ${style.gauge}`}
                        cx={radius}
                        cy={radius}
                        r={innerRadius}
                        stroke='url(#grad)'
                        strokeDasharray={dashArray}
                        strokeDashoffset={offset}
                        strokeLinecap='round'
                        strokeWidth={strokeWidthGauge}
                        style={{
                            transition: 'stroke-dashoffset 0.3s',
                        }}
                        transform={transform}
                    />
                     
                    {showPercentText && <text
                        class={`${style.gaugeText} ${style[color]}`}
                        x={centerTextX || '50%'}
                        y={centerTextY || '50%'}
                        dominant-baseline='middle'
                        text-anchor='middle'
                        font-size={textSize}
                    >{percent} {prefix}</text>}

                    {showCenterText && <text
                        class={`${style.gaugeText} ${style[color]}`}
                        x={centerTextX || '50%'}
                        y={centerTextY || '50%'}
                        dominant-baseline='middle'
                        text-anchor='middle'
                        font-size={textSize}
                    >{centerText}</text>}

                    {completed && <text
                        class={`${style.gaugeText} ${style[color]}`}
                        x={completedX}
                        y={completedY}
                        dominant-baseline='middle'
                        text-anchor={completedTextAnchor}
                        font-size={textSizeSmall}
                    >{completed}</text>}

                    <text
                        class={`${style.gaugeText} ${style.secondary}`}
                        x={totalX}
                        y={totalY}
                        dominant-baseline='middle'
                        text-anchor={totalTextAnchor}
                        font-size={textSizeSmall}
                    >{total}</text>

                </svg>
            </>
        );
    }
}

export default Gauge;
