import { h, Component, createRef } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';

import Images from '../form/images';

import mu from '../../lib/musher-util';

@observer
class StoryEdit extends Component {
    constructor(props) {
        super(props);
        this.updateTimer = {};
        this.textareaRef = createRef();
        this.state = {
            sendButtonEnabled: true,
            storyBody: props.story.body,
        };
    }

    saveStory = async (e) => {
        const { id } = e.target.closest('button').dataset;
        const { storyBody: body } = this.state;
        const { storyStore } = this.props.stores;
        const { callback = () => true } = this.props;
        // saveField(id, field, value, updateMemory) {
        await storyStore.saveField(parseInt(id, 10), 'body', body, true);
        storyStore.publicFeedUpdate(parseInt(id, 10), 'body', body);
        this.setState({
            storyBody: '',
            storyType: 0,
		});
        callback();
    }

    saveStoryField = async (e) => {
        const { story } = this.props;
        const { id } = story;
        const { value, name } = e.target;
        const { subfield, subvalue } = e.target.dataset;
        const { sendButtonEnabled } = this.state;
        if (!sendButtonEnabled) {
            return false;
        }
        const { storyStore } = this.props.stores;
        storyStore.publicFeedUpdate(id, name, value);
        clearTimeout(this.updateTimer[name]);
        this.updateTimer[name] = setTimeout(async () => {
            this.doUpdateField(id, name, value, {
                query: {
                    [subfield]: subvalue,
                },
            });
        }, 1000);
    }

    updateFieldValue = ({ id, field, value, datatype, elementtype, skipTimer = false }) => {
        const { story } = this.props;
        const { storyStore } = this.props.stores;
        let newValue = value;
        let localObjectValue = value;
        if (field === 'images') {
            if (Array.isArray(story.images) && Array.isArray(value)) {
                localObjectValue = [...story.images, ...value];
            }
        }

        storyStore.updateField(id, field, localObjectValue);
        storyStore.publicFeedUpdate(id, field, localObjectValue);

        if (skipTimer) {
            this.doUpdateField(id, field, newValue);
        } else {
            clearTimeout(this.updateTimer[field]);
            this.updateTimer[field] = setTimeout(async () => {
                this.doUpdateField(id, field, newValue);
            }, 500);
        }
    }

    async doUpdateField(id, name, value, opts = {}) {
        this.setState({ sendButtonEnabled: false });
        const { storyStore } = this.props.stores;
        await storyStore.saveField(parseInt(id, 10), name, value, true, {
            query: { ...opts.query },
        });
        this.setState({ sendButtonEnabled: true });
    }

    beforeImagesUploaded = () => {
        this.setState({ sendButtonEnabled: false });
    }

    afterImagesUploaded = () => {
        this.setState({ sendButtonEnabled: true });
    }

    removeImage = async (e) => {
        const { storyStore } = this.props.stores;
        const { story } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        storyStore.removeImage({
            id: story.id,
            name: imageName,
        });
    }

    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    componentDidMount() {
        this.textareaRef.current.focus();
    }

    render() {
        const { storyBody, storyType, sendButtonEnabled } = this.state;
        const { userStore } = this.props.stores;
        const { isAdmin } = userStore;

        const { story } = this.props;
        const { id } = story;

        return (<>
            <div class='container-fluid px-0 mt-1'>
                <div class='row'>
                    <div class='col-12 text-center mt-2'>
                        <div class='container-fluid'>
                            <div class='row d-flex align-items-end'>
                                {util.isArray(story.images) && story.images.map((img, idx) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div class='col-3 p-1'>
                                        <img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
                                        <button
                                            type='button'
                                            class='btn btn-sm btn-link'
                                            onClick={this.removeImage}
                                            data-name={img.name}
                                        ><i class='fas fa-trash' /> Del</button>
                                        <small>
                                            <input
                                                class='form-control'
                                                type='text'
                                                name={`images.title`}
                                                data-subfield={`name`}
                                                data-subvalue={story.images[idx].name}
                                                onInput={this.saveStoryField}
                                                value={story.images[idx].title}
                                            />
                                        </small>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Images object={story} updateField={this.updateFieldValue} before={this.beforeImagesUploaded} after={this.afterImagesUploaded} autoOpen={false} stores={this.props.stores} />
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 mt-2'>
                        <label><Text id='story.content'>Content</Text></label>
                        <div class='border rounded rounded-lg position-relative'>
                            <Localizer>
                                <textarea
                                    class='form-control-plaintext px-2'
                                    type='text'
                                    placeholder={<Text id='story.add-content'>Add your text here...</Text>}
                                    onInput={linkState(this, 'storyBody')}
                                    // onKeyUp={mu.resizeTextarea}
                                    onFocus={mu.resizeTextarea}
                                    style='height: 2.3em;'
                                    value={storyBody || story.body}
                                    ref={this.textareaRef}
                                />
                            </Localizer>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 mt-2'>
                        {isAdmin && <>
                            <div class='col-12 mt-2'>
                                <div class='form-group'>
                                    <small>
                                        <input
                                            type='checkbox'
                                            id={`pinned-${story.id}`}
                                            name='pinned'
                                            onInput={this.saveStoryField}
                                            value={story.pinned ? 'false' : util.isoDate()}
                                            checked={story.pinned ? 'checked' : ''}
                                            />
                                        <label class='ml-2' for={`pinned-${story.id}`}>Pinned to top.</label>
                                    </small>
                                </div>
                            </div>
                        </>}

                        {isAdmin && <>
                            <label><Text id='story.type'>Type</Text></label>
                            <div class='form-group'>
                                <small>
                                    <select
                                        class='custom-select custom-select-sm'
                                        id={story.id}
                                        name='type'
                                        onInput={this.saveStoryField}
                                        value={storyType || story.type}
                                    >
                                    <option value=''>-- <Text id='story.type-choose'>Velg type</Text> --</option>
                                        <option value={1} selected={story.type == 1 ? 'selected' : ''}>✍️ <Text id='story.type-regular'>Vanlig</Text></option>
                                        <option value={2} selected={story.type == 2 ? 'selected' : ''}>🚀 <Text id='story.type-ad'>Annonse</Text></option>
                                        <option value={3} selected={story.type == 3 ? 'selected' : ''}>📄 <Text id='story.type-article'>Artikkel</Text></option>
                                    </select>
                                </small>
                            </div>
                        </>}

                        {story.type >= 2 && <>
                            <label><Text id='story.url-text'>URL text</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='urlText'
                                        onInput={this.saveStoryField}
                                        value={story.urlText}
                                    />
                                </small>
                            </div>

                            <label><Text id='story.url'>URL</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='url'
                                        onInput={this.saveStoryField}
                                        value={story.url}
                                    />
                                </small>
                            </div>
                        </>}

                        {story.type === 2 && <>
                            <label><Text id='story.code'>Code</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='code'
                                        onInput={this.saveStoryField}
                                        value={story.code}
                                    />
                                </small>
                            </div>

                            <label><Text id='story.country'>Country</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='country'
                                        onInput={this.saveStoryField}
                                        value={story.country}
                                    />
                                </small>
                            </div>

                            <label><Text id='story.region'>Region</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='region'
                                        onInput={this.saveStoryField}
                                        value={story.region}
                                    />
                                </small>
                            </div>

                            <label><Text id='story.currency'>Currency</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='currency'
                                        onInput={this.saveStoryField}
                                        value={story.currency}
                                    />
                                </small>
                            </div>

                            <label><Text id='story.price'>Price</Text></label>
                            <div class='form-group'>
                                <small>
                                    <input
                                        class='form-control'
                                        type='text'
                                        name='price'
                                        onInput={this.saveStoryField}
                                        value={story.price}
                                    />
                                </small>
                            </div>
                        </>}
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 mt-2'>
                        <button type='button' class={`btn btn-block btn-${sendButtonEnabled ? 'primary' : 'secondary'} mb-2`} disabled={!sendButtonEnabled}
                            onClick={this.saveStory}
                            data-id={story.id}
                        ><i class='fas fa-paper-plane' /> <Text id='story.edit-save'>Save changes</Text></button>
                    </div>
                </div>
            </div>
        </>)
    }
}

export default StoryEdit;
