import { h, Component, createRef } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import StoryComments from './comments';
import StoryAddComment from './commentAdd';
import StoryPoll from './storyPoll';
import ImageScroller from '../imageScroller';
import LikesAndComments from '../feedback/likesAndComments';
import Views from '../feedback/views';
import PublicTeam from '../team/public';

import Field from '../../components/util/field';

import mu from '../../lib/musher-util';

const initialState = {
	showAddComment: {},
	showTeamSetting: {},
    viewImageIdx: {},
    likeStory: {},
};

@observer
class StoryTypeRegular extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewImageIdx: {},
        };
    }

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    showStoryDetail = (e) => {
        if (e.target.localName === 'a') {
            return false;
        }
        const { id } = e.target.closest('#storyDetail').dataset;
        if (id) {
            route(`/stories/${id}`);
        }
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        const translatedText = await storyStore.translate({ story, user });
        this.setState({ showOriginal: 0, translatedText });
    }

    showOriginal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginal: 1 });
    }

    setSelectedImage = (imageIdx) => {
        this.setState({ selectedImageIdx: imageIdx });
    }

    render() {
        const { viewImageIdx, showOriginal, translatedText, selectedImageIdx = 0 } = this.state;
        const { userStore, storyStore, appState } = this.props.stores;
        const { story, likeStory, likeRef } = this.props;
        const { darkmode } = appState;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;

        const images = [];
        if (story.images) {
            const imgs = toJS(story.images);
            images.push(...imgs);
        }
        const imageIdx = viewImageIdx[story.id] ? viewImageIdx[story.id] : 0;
        const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

        const translation = mu.getTranslation({
            object: story,
            targetLang: language,
            field: 'body',
        }) || translatedText;
        let body = translation || story.body;
        if (showOriginal) {
            body = story.body;
        }
        const { components = [], finalBody = '' } = mu.getInlineComponents(body || '');

        return (<>
            <div class='container-fluid px-0 mt-1'>
                <div class='row'>
                    <ImageScroller stores={this.props.stores} images={images} id={story.id} dblClick={likeStory} likeRef={likeRef} callback={this.setSelectedImage} />
                </div>
            </div>
            <div class='row mt-2'>
                <div class='clearfix col-12 overflow-hidden' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                    <div id='storyDetail' onClick={this.showStoryDetail} data-id={story.id}>
                        {story.subType > 0 ? <>

                        </> : <>
                            <Markdown markdown={mu.replaceImages(finalBody, story.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                            {story.language !== language && <>
                                {!showOriginal && translation ? <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-link text-muted'
                                        onClick={this.showOriginal}
                                    ><i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text></button>
                                </> : <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-link text-muted'
                                        onClick={this.translate}
                                    ><i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text></button>
                                </>}
                            </>}
                        </>}
                    </div>
                    {components && components.map((component, idx) => {
                        if (component.name === 'poll') {
                            return <StoryPoll
                                stores={this.props.stores}
                                story={story}
                                storyId={story.id}
                                params={component.params}
                                opts={component.opts}
                                content={component.content}
                                selectedImageIdx={selectedImageIdx}
                            />;
                        }
                    })}
                </div>
            </div>
        </>);
    }
}

@observer
class StoryTypeAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewImageIdx: {},
        };
    }

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    showStoryDetail = (e) => {
        if (e.target.localName === 'a') {
            return false;
        }
        const { id } = e.target.closest('#storyDetail').dataset;
        if (id) {
            route(`/stories/${id}`);
        }
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        this.setState({ showOriginal: 0 });
        await storyStore.translate({ story, user });
    }

    showOriginal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginal: 1 });
    }

    getTranslation = () => {
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;
        const translatedText = mu.getTranslation({
            object: story,
            targetLang: language,
            field: 'body',
        });
        if (translatedText) {
            this.setState({ showOriginal: 0 });
        }
    }

    componentWillMount() {
        this.getTranslation();
    }

    render() {
        const { viewImageIdx, showOriginal } = this.state;
        const { userStore, storyStore } = this.props.stores;
        const { story, likeStory, likeRef } = this.props;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;

        const images = [];
        if (story.images) {
            const imgs = toJS(story.images);
            images.push(...imgs);
        }
        const imageIdx = viewImageIdx[story.id] ? viewImageIdx[story.id] : 0;
        const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

        const translation = mu.getTranslation({
            object: story,
            targetLang: language,
            field: 'body',
        });
        let body = translation || story.body;
        if (showOriginal) {
            body = story.body;
        }

        return (<>
            <div class='container-fluid px-0 mt-1'>
                <div class='row'>
                    <ImageScroller stores={this.props.stores} images={images} id={story.id} dblClick={likeStory} likeRef={likeRef} />
                </div>
            </div>

            <div class='row mt-2'>
                <div class='clearfix col-12 overflow-hidden' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                    {/* <div id='storyDetail' onClick={this.showStoryDetail} data-id={story.id}> */}
                    <div id='storyDetail' data-id={story.id}>
                        <Markdown markdown={mu.replaceImages(body, story.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                        {story.language !== language && <>
                            {!showOriginal && translation ? <>
                                <button type='button' class='btn btn-sm btn-link' onClick={this.showOriginal}><i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text></button>
                            </> : <>
                                <button type='button' class='btn btn-sm btn-link' onClick={this.translate}><i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text></button>
                            </>}
                        </>}
                    </div>
                </div>
                {!!(story.url || story.code || story.price > 0) && <>
                    <div class='clearfix col-12  py-2' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                        <div class='row'>
                            {story.url && <>
                                <div class='col-12 text-left' style='font-size: 1.0em;'>
                                    <a href={mu.asUrl(story.url)} target='_blank' rel='noreferrer'>
                                        <i class='fas fa-link mr-2' />
                                        {story.urlText ? <>
                                            {story.urlText}
                                        </> : <>
                                            <Text id='story.ad-url-readmore'>Les mer...</Text>
                                        </>}
                                    </a>
                                </div>
                            </>}
                            {story.code && <>
                                <div class='col text-left' style='font-size: 1.2em;'>
                                    <nobr>
                                        <span class='font-weight-lighter'><Text id='story.ad-campaign-code'>Kampanjekode</Text>:</span> {story.code}
                                    </nobr>
                                </div>
                            </>}
                            {story.price > 0 && <>
                                <div class='col text-right' style='font-size: 1.2em;'>
                                    <nobr>
                                        {story.currencty && <span class='font-weight-lighter'>{story.currency}</span>} {util.format(story.price, 2)}
                                    </nobr>
                                </div>
                            </>}
                        </div>
                    </div>
                </>}
            </div>
        </>);
    }
}

@observer
class StoryTypeArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewImageIdx: {},
        };
    }

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    showStoryDetail = (e) => {
        if (e.target.localName === 'a') {
            return false;
        }
        const { id } = e.target.closest('#storyDetail').dataset;
        if (id) {
            route(`/stories/${id}`);
        }
    }

    translate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        this.setState({ showOriginal: 0 });
        await storyStore.translate({ story, user });
    }

    showOriginal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showOriginal: 1 });
    }

    getTranslation = () => {
        const { story } = this.props;
        const { userStore, storyStore } = this.props.stores;
        const { user } = userStore;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;
        const translatedText = mu.getTranslation({
            object: story,
            targetLang: language,
            field: 'body',
        });
        if (translatedText) {
            this.setState({ showOriginal: 0 });
        }
    }

    componentWillMount() {
        this.getTranslation();
    }

    render() {
        const { viewImageIdx, showOriginal } = this.state;
        const { userStore, storyStore } = this.props.stores;
        const { story, likeStory, likeRef } = this.props;
        const { user: currentUser } = userStore;
		const { language = 'en' } = currentUser;

        const images = [];
        if (story.images) {
            const imgs = toJS(story.images);
            images.push(...imgs);
        }
        const imageIdx = viewImageIdx[story.id] ? viewImageIdx[story.id] : 0;
        const currentImage = images && images[imageIdx] ? images[imageIdx] : null;

        const translation = mu.getTranslation({
            object: story,
            targetLang: language,
            field: 'body',
        });
        let body = translation || story.body;
        if (showOriginal) {
            body = story.body;
        }

        return (<>

            <div class='container-fluid px-0 mt-1'>
                <div class='row'>
                    <ImageScroller stores={this.props.stores} images={images} id={story.id} dblClick={likeStory} likeRef={likeRef} />
                </div>
            </div>

            <div class='row mt-2'>
                <div class='clearfix col-12 overflow-hidden' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                    <div id='storyDetail' onClick={this.showStoryDetail} data-id={story.id}>
                        <Markdown markdown={mu.replaceImages(body, story.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                        {story.language !== language && <>
                            {!showOriginal && translation ? <>
                                <button type='button' class='btn btn-sm btn-link' onClick={this.showOriginal}><i class='fas fa-cog' />  <Text id='home.translate-show-original'>Show original</Text></button>
                            </> : <>
                                <button type='button' class='btn btn-sm btn-link' onClick={this.translate}><i class='fas fa-cog' /> <Text id='home.translate-to'>See translation</Text></button>
                            </>}
                        </>}
                    </div>
                </div>
                {!!(story.url || story.code || story.price) && <>
                    <div class='clearfix col-12  py-2' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                        <div class='row'>
                            {story.url && <>
                                <div class='col-12 text-left' style='font-size: 1.0em;'>
                                    <a href={mu.asUrl(story.url)} target='_blank' rel='noreferrer'>
                                        <i class='fas fa-link mr-2' />
                                        {story.urlText ? <>
                                            {story.urlText}
                                        </> : <>
                                            <Text id='story.ad-url-readmore'>Les mer...</Text>
                                        </>}
                                    </a>
                                </div>
                            </>}
                        </div>
                    </div>
                </>}
            </div>

            {/* Flere artikler fra:... */}
        </>);
    }
}

@observer
class StoryPublicFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
		this.inputRef = {};
        // this.ref = createRef();
    }

    toggleDate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

	likeStory = async (e) => {
        const { story } = this.props;
        const { id } = story;
        const storyId = id;
		const { storyStore } = this.props.stores;

        const { likeStory } = this.state;
        likeStory[storyId] = true;
        const likeIds = Object.keys(likeStory);
        this.setState({ likeStory, likeIds });
        setTimeout(() => {
            const { likeStory } = this.state;
            delete likeStory[storyId];
            const likeIds = Object.keys(likeStory);
            this.setState({ likeStory, likeIds });
        }, 1200);

		const response = await storyStore.likeStory({ id: storyId });
        if (response && response.status === 200) {
            // Done
		}
	}

	toggleAddComment = (e) => {
		const storyId = e.target.closest('button').dataset.id;
		const { showAddComment } = this.state;
        if (showAddComment[storyId]) {
            delete showAddComment[storyId];
        } else {
            showAddComment[storyId] = true;
        }
		this.setState({
			showAddComment,
		}, () => {
			if (this.inputRef[storyId]) {
				this.inputRef[storyId].focus();
			}
		});
	}

    closeAllAddComments = () => {
        this.setState({
			showAddComment: {},
		});
    }

	toggleShowTeamSetting = (e) => {
		const storyId = e.target.closest('button').dataset.id;
		const { showTeamSetting } = this.state;
        if (showTeamSetting[storyId]) {
            delete showTeamSetting[storyId];
        } else {
            showTeamSetting[storyId] = true;
        }
		this.setState({
			showTeamSetting,
		});
	}

    viewImage = (e) => {
		const { id, idx } = e.target.closest('img').dataset;
        const { viewImageIdx } = this.state;
        viewImageIdx[id] = idx;
        this.setState({ viewImageIdx });
    }

    async loadStoriesPublicFeed(props = this.props) {
        const { hashtag } = props;
		const { storyStore } = props.stores;
		await storyStore.loadPublicFeed({ hashtag });
	}

    toggleAdminDetails = () => {
        const { showAdminDetails } = this.state;
        this.setState({
            showAdminDetails: !showAdminDetails,
        });
        this.setState({
            showEdit: {},
            showMenu: {},
		});
    }

    showStoryDetail = (e) => {
        if (e.target.localName === 'a') {
            return false;
        }
        const { id } = e.target.closest('#storyDetail').dataset;
        if (id) {
            route(`/stories/${id}`);
        }
    }

    shareLink = (e) => {
        const { url, title, text } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        appState.shareUrl({ url, title, text });
    }

    displayTeam = (e, teamId, isPublic) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isPublic) {
            return false;
        }
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
	}

    openInbox = (e, fromuser) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
    }

    favoriteStory = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value, isfavorite } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (isfavorite) {
            await userStore.removeField({ email: user.email, field: 'favoriteStory', value: parseInt(value, 10) });
        } else {
            await userStore.updateUserArray({ email: user.email, field: 'favoriteStory', value: parseInt(value, 10) });
        }
    }

    onMenuClick = (e, story) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('publicStoryMenu', { story, height: drawerHeightSmall });
    }

    render() {
        const {
            showAddComment,
            showDate,
            showTeamSetting,
            likeStory,
            likeIds,
            showAdminDetails
        } = this.state;
        const { story, showAllComments, sessionid, onTeamClick = () => {}, onDogClick = () => {}, onUserClick = () => {} } = this.props;
        const { userStore, storyStore, appState } = this.props.stores;
        const { user: currentUser = {}, isAdmin } = userStore;
        const { darkmode, hasShareApi } = appState;

        const team = storyStore.findPublicTeam(story.team) || {};
        const user = storyStore.findPublicUser(story.user) || {};
        const image = mu.getImage({ user, team, priority: 'user', object: story });

        const isTeamAdmin = (team && team.members) ? team.members.some(m => m === currentUser.id) : false;

        let shortname;
        let name;
        let username;
        let userLink;
        if (team && team.name) {
            shortname = mu.displayNameShort(team);
            name = mu.displayName(team);
            username = mu.displayName(user);
            if (user.settings && currentUser.uuidv4 !== user.uuidv4) {
                userLink = user.settings.hideProfile ? null : `/inbox/${user.uuidv4}`;
            }
        } else if (user && user.id) {
            shortname = mu.displayNameShort(user);
            name = mu.displayName(user);
        }
        const { likes, comments, reports = [] } = story;
        const hasLikedStory = story.likes && story.likes?.findIndex(e => e.user === currentUser.id) > -1;
        const totalFollowers = team.followers ? team.followers.length : 0;

        const isYourTeam = team.members && team.members?.indexOf(currentUser.id) > -1;
        const isFollowing = team.followers && team.followers?.indexOf(currentUser.id) > -1;
        const hasRequestedFollow = team.followRequests && team.followRequests?.findIndex(e => e.id === currentUser.id) > -1;

        const { currentLocationGeocode = {}, yrWeather = [] } = story;
        const views = mu.getViews(story.statistics);
        const adminData = mu.getAdminData(story.statistics);

        return (<>
            <Field value={story.title} darkmode={darkmode} type='story' width={570} height={285} viewBox='0 0 800 400'>
                <span class='float-right font-weight-lighter text-muted'>
                    <i class='fas fa-ellipsis-h' onClick={e => this.onMenuClick(e, story)} />
                </span>

                <div class='d-flex position-relative'>
                    <div
                        class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                        style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
                        onClick={e => this.displayTeam(e, team.uuidv4, team.public)}
                    >
                        {!image && <div class='text-muted' style='font-size: 20px; padding-top: 3px;'>{shortname}</div>}
                    </div>
                    <div class='flex-grow-1 pl-3 mr-2' style='line-height: 1.2em;'>
                        <div
                            class='mb-1 position-relative'
                            style={`${story.offline ? 'text-decoration: line-through;' : ''}`}
                        >
                            {team.public ? <>
                                <a
                                    href={`/teams/public/${team.uuidv4}`}
                                    onClick={e => this.displayTeam(e, team.uuidv4, team.public)}
                                    class={`${darkmode ? 'text-light' : 'text-dark'}`}
                                >
                                    {name}
                                </a>
                            </> : <>
                                {name}
                            </>}

                            {isYourTeam && !team.public && <button type='button' class='btn btn-sm btn-link text-muted p-0 ml-1'
                                data-id={story.id}
                                onClick={this.toggleShowTeamSetting}
                            >
                                <i class='fas fa-exclamation-triangle' />
                            </button>}

                            {story.subType === 1 && <>
                                <span class='text-muted ml-2' onClick={e => onDogClick(e, story.dogUuidv4)}>
                                    <Text id='story.dog-picture-added'>added picture of </Text> {story.dogName}
                                </span>
                            </>}

                            {story.subType === 2 && <>
                                <span class='text-muted ml-2' onClick={e => onTeamClick(e, story.teamUuidv4)}>
                                    <Text id='story.team-picture-added'>added new picture to the team</Text>
                                </span>
                            </>}

                        </div>
                        <div class='mb-2' style={`${story.offline ? 'text-decoration: line-through;' : ''}`}>
                            {story.type === 2 ? <>
                                <small class='text-muted'><Text id='story.sponsored'>Sponsored</Text> -</small>
                            </> : <>
                                {story.date && <small class='text-muted' onClick={this.toggleDate}>
                                    {showDate ? util.formatDate(story.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' }) : util.formatDistance(story.date, new Date(), { locale: 'no-NB' })}
                                </small>}
                            </>}

                            {isAdmin && reports && reports.length > 0 && <>
                                <span class='ml-2'>·</span>
                                <small class='text-danger ml-2'>
                                    <i class='fas fa-flag' /> {reports.length}
                                </small>
                            </>}

                            {story.pinned && <>
                                <span class='ml-2'>·</span>
                                <small class='text-muted'>
                                    <i class='fas fa-thumbtack' />
                                </small>
                            </>}

                            <span class='ml-2'>·</span>

                            {story.public === 1 ? <>
                                <small class='ml-2 text-muted'>
                                    <i class='fas fa-globe-europe' />
                                </small>
                            </> : <>
                                <small class='ml-2 text-muted'>
                                    <i class='fas fa-users' />
                                    {/* ({util.format(totalFollowers)}) */}
                                </small>
                            </>}
                            {/* {username && <>
                                <small class='ml-2 text-muted'>
                                    {userLink ? <>
                                        <i class='fas fa-user' /> <a href={userLink} onClick={e => this.openInbox(e, user.uuidv4)}>{username}</a>
                                    </> : <>
                                        <i class='fas fa-user' /> {username}
                                    </>}
                                </small>
                            </>} */}
                            {currentLocationGeocode && currentLocationGeocode.formattedCounty && <>
                                <span class='ml-2'>·</span>
                                <small class='text-muted ml-2'>
                                    <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formattedLocality || currentLocationGeocode.formattedCounty}
                                </small>
                            </>}
                        </div>
                        {showAdminDetails && isAdmin && <div
                            class='d-flex flex-row flex-nowrap bg-light border-top border-bottom py-2'
                            style='overflow: auto;'
                        >
                            <small class='text-muted mr-2'>Adm:</small>
                            {reports && reports.length > 0 && <>
                                <small class='text-danger mr-2'>
                                    <i class='fas fa-flag' /> {reports.length}
                                </small>
                            </>}
                            {currentLocationGeocode && currentLocationGeocode.formatted_address && <>
                                <small class='text-muted'>
                                    <i class='fas fa-map-marker-alt' /> {currentLocationGeocode.formatted_address}
                                </small>
                            </>}
                            {user.deviceInfo && <>
                                <span class='badge badge-secondary ml-2 float-right font-weight-light'>
                                    {['platform', 'manufacturer', 'model'].map(e => <>
                                        <span class='ml-2 text-white'>
                                            {user.deviceInfo[e]}
                                        </span>
                                    </>)}
                                </span>
                            </>}

                            {adminData && <>
                                <small class='ml-2 text-muted'>
                                    V: {adminData.count}
                                </small>
                                <small class='ml-2 text-muted'>
                                    R: {adminData.ranking} {util.isNumber(adminData.rankingTrend) && <><i class={mu.getClassForTrend(adminData.rankingTrend)} /></>}
                                </small>
                                <small class='ml-2 text-muted'>
                                    D: {util.format(adminData.durationAvg / 1000, 1)}s {util.isNumber(adminData.durationAvgTrend) && <><i class={mu.getClassForTrend(adminData.durationAvgTrend)} /></>}
                                </small>
                                {story.statisticsCurrent && story.statisticsCurrent.totalInteractions > 0 && <small class='ml-2 text-muted'>
                                    S: {util.format(story.statisticsCurrent.totalInteractions, 0)}
                                </small>}
                                {story.shadowTags && story.shadowTags.map(tag => <>
                                    <span class='badge badge-secondary ml-2 float-right font-weight-light'>{tag}</span>
                                </>)}
                            </>}
                            {/* {story.tags && <>
                                {util.isArray(story.tags) && story.tags.map(tag => <>
                                    <small class='ml-2 text-muted'>
                                        {tag}
                                    </small>
                                </>)}
                            </>} */}
                        </div>}
                    </div>
                </div>

                {(!story.type || story.type == 1) && <StoryTypeRegular
                    stores={this.props.stores}
                    story={story}
                    key={`story-regular-view-${story.id}`}
                    likeStory={this.likeStory}
                    likeRef={likeStory}
                    keys={likeIds}
                />}
                {story.type == 2 && <StoryTypeAd
                    stores={this.props.stores}
                    story={story}
                    key={`story-ad-view-${story.id}`}
                    likeStory={this.likeStory}
                    likeRef={likeStory}
                    keys={likeIds}
                />}
                {story.type == 3 && <StoryTypeArticle
                    stores={this.props.stores}
                    story={story}
                    key={`story-article-view-${story.id}`}
                    likeStory={this.likeStory}
                    likeRef={likeStory}
                    keys={likeIds}
                />}

                <div class='d-flex justify-content-between mt-2'>
                    <div class='mr-2'>
                        <LikesAndComments
                            stores={this.props.stores}
                            likes={likes}
                            comments={comments}
                            type='story'
                            onTeamClick={onTeamClick}
                            onUserClick={onUserClick}
                        />
                    </div>
                    <div class='d-flex justify-content-between align-items-center'>
                        {views > 0 && <>
                            <Views
                                stores={this.props.stores}
                                views={views}
                                object={story}
                                type='story'
                                isTeamAdmin={isTeamAdmin}
                                isAdmin={isAdmin}
                                onTeamClick={onTeamClick}
                                onUserClick={onUserClick}
                            />
                        </>}
                        <div class='ml-2'>
                            <i
                                class={`fa-${currentUser.favoriteStory?.indexOf(story.id) > -1 ? 'solid' : 'regular'} fa-star`}
                                style='font-size: 1.1em;'
                                onClick={this.favoriteStory}
                                data-value={story.id}
                                data-isfavorite={currentUser.favoriteStory?.indexOf(story.id) > -1}
                            />
                        </div>
                    </div>
                </div>

                {showTeamSetting[story.id] && <>
                    <small>
                        <div class='alert alert-info' role='alert'>
                            <span class='display-4 float-left'>
                                <i class='fas fa-exclamation-triangle mr-3 mb-3' />
                            </span>
                            <p>
                                <Text id='story.alert-text-1'>Your team is not visible and it's not possible to request friendship with your team.</Text>
                            </p>
                            <p>
                                <Text id='story.alert-text-2'>If you want to make your team visible so other can request to follow your team, you can find the setting here:</Text>
                            </p>
                            <p>
                                <a href={`/teams/edit/${team.id}`}><Text id='story.alert-text-3'>Edit team</Text></a>
                            </p>
                        </div>
                    </small>
                </>}

                <div>
                    <div class='row'>
                        <div class='col text-center'>
                            <button
                                type='button'
                                class={`storyImage btn btn-sm btn-outline-${hasLikedStory ? 'primary' : 'secondary'} btn-lg btn-block ${hasLikedStory ? 'text-primary' : ''} text-overflow`}
                                data-id={story.id}
                                onClick={this.likeStory}
                            >
                                <i class={`${hasLikedStory ? 'text-primary fas' : 'far'} fa-thumbs-up`} /> {hasLikedStory ? <Text id='comments.has-liked'>Liked</Text> : <Text id='comments.like'>Lik</Text>}
                            </button>
                        </div>
                        <div class='col text-center'>
                            <button
                                type='button'
                                class='btn btn-sm btn-outline-secondary btn-block text-overflow'
                                data-id={story.id}
                                onClick={this.toggleAddComment}
                            >
                                <i class='fas fa-comment' /> <Text id='comments.comment-add'>Kommentar</Text>
                            </button>
                        </div>
                        {hasShareApi ? <>
                            <div class='col text-center'>
                                <button
                                    type='button'
                                    class='btn btn-sm btn-outline-secondary btn-block text-overflow'
                                    onClick={this.shareLink}
                                    data-url={`https://themusher.app/share/story/${story.uuidv4}`}
                                    data-title={`title: ${story.title}`}
                                >
                                    <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                </button>
                            </div>
                        </> : <>
                            <div class='col text-center'>
                                <a class='btn btn-sm btn-outline-secondary btn-block text-overflow text-secondary'
                                    href={`https://themusher.app/share/story/${story.uuidv4}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    data-native
                                >
                                    <i class='fas fa-share' /> <Text id='story.share'>Del</Text>
                                </a>
                            </div>
                        </>}

                    </div>
                </div>

                <div class='mt-2'>
                    <StoryComments
                        stores={this.props.stores}
                        story={story}
                        col='10'
                        offset='2'
                        compact={false}
                        showAllComments={showAllComments}
                        initCall={this.closeAllAddComments}
                    />
                    {showAddComment[story.id] && <StoryAddComment
                        stores={this.props.stores}
                        story={story}
                        inputRef={c => this.inputRef[story.id] = c}
                        inputRefObject={this.inputRef}
                        col='10'
                        offset='2'
                        key={`story-comment-${story.id}`}
                    />}
                </div>
            </Field>
        </>);
    }
}

export default StoryPublicFeed;
