import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import Images from '../../components/form/images';
import Files from '../../components/form/files';

const initialState = {};

@observer
class Input extends Component {
    handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    removeImage = async (e) => {
        const { userStore } = this.props.stores;
        const { user } = this.props;
        const imageName = e.target.parentElement.dataset.name || e.target.dataset.name;
        userStore.removeImage({
            id: user.id,
            name: imageName,
        });
    }

    removeMainImage = () => {
        const { user, field, updateField } = this.props;
        updateField({ id: user.id, field, value: {} });
    }

    toggleSetting = async (field) => {
        const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;
        await userStore.updateSetting({ [`settings.${field}`]: settings[field] === 1 ? 0 : 1 }, { [field]: settings[field] === 1 ? 0 : 1 });
    }

    toggleNotificationTopic = async (field) => {
		const { userStore } = this.props.stores;
        const { user } = userStore;
        const { notificationTopics = {} } = user;
        const value = notificationTopics[field] ? 0 : 1;
        await userStore.setNotificationTopic({
            field: `notificationTopics.${field}`,
            value,
        });
    }

    render() {
        // const { saved, dog, type = 'text', datalist = [], min, max, step, field, title, icon, help, onInput, updateField } = this.props;
        const { saved, user, type = 'text', field, title, icon, help, onInput, updateField, skipImageList, limitImageList } = this.props;
        const value = user[field];
        const byMd5 = e => user.image ? e.md5 !== user.image.md5 : true;

        if (type === 'image') {
            return (
                <div class={`form-group ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>

                    {!skipImageList && <div class='container-fluid'>
                        <div class='row'>
                            {user.image && user.image.s3SmallLink && <div class='col-3 p-1'>
                                <img src={user.image.s3SmallLink} class='img-fluid border rounded-lg' onError={this.handleImageErrored} />
                                <button class='btn btn-sm btn-link'
                                    onClick={this.removeMainImage}
                                    data-name={user.image.name}
                                ><i class='fas fa-trash' /> Del</button>
                                <span class='badge badge-success'>Main</span>
                            </div>}
                            {user.images && user.images.filter(byMd5).slice(0, limitImageList || user.images.length).map(img => (
                                <div class='col-3 p-1'>
                                    <img
                                        src={img.s3SmallLink}
                                        alt={`May be an image of: ${mu.imageKeywords(img).join(', ')}`}
                                        class='img-fluid'
                                        onError={this.handleImageErrored}
                                    />
                                    <button class='btn btn-sm btn-link'
                                        onClick={this.removeImage}
                                        data-name={img.name}
                                    ><i class='fas fa-trash' /> Del</button>
                                </div>
                            ))}
                        </div>
                    </div>}
                    <Images saved={saved} object={user} field={field} updateField={updateField} autoOpen={false} stores={this.props.stores} key={`image-${field}`} />
                    {saved[`${field}.${user.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='users.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'file') {
            return (
                <div class={`form-group ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                    <Files saved={saved} object={user} field={field} updateField={updateField} />
                    {saved[`${field}.${user.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='users.changes-saved'>Endringene er lagret</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                </div>
            );
        }
        if (type === 'toggle') {
            return (
                <div class={`row ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`}>
                    <div class='col-12 py-2 d-flex align-items-center position-relative' style='font-size: 1.2em;'>
                        <span class='flex-grow-1' style='line-height: 1.0em;'>
                            {icon && <i class={`${icon} text-muted`} />} {title}
                        </span>
                        <div class='custom-control custom-switch custom-switch-lg mt-2 ml-3' style="transform: scale(1.8);">
                            <input type='checkbox'
                                class='custom-control-input'
                                id={`${field}Input`}
                                value={value === 1 ? 0 : 1}
                                data-id={user.id}
                                data-field={field}
                                onInput={() => this.toggleSetting(field)}
                                checked={user.settings[field]}
                            />
                            <label class='custom-control-label' for={`${field}Input`} />
                        </div>
                        {saved[`${field}.${user.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='users.changes-saved'>Endringene er lagret</Text></div> :
                            <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                    </div>
                </div>
            );
        }

        if (type === 'notificationTopic') {
            return (
                <div class={`row ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`}>
                    <div class='col-12 py-2 d-flex align-items-center position-relative' style='font-size: 1.2em;'>
                        <span class='flex-grow-1' style='line-height: 1.0em;'>
                            {icon && <i class={`${icon} text-muted`} />} {title}
                        </span>
                        <div class='custom-control custom-switch custom-switch-lg mt-2 ml-3' style="transform: scale(1.8);">
                            <input type='checkbox'
                                class='custom-control-input'
                                id={`${field}Input`}
                                value={value === 1 ? 0 : 1}
                                data-id={user.id}
                                data-field={field}
                                onInput={() => this.toggleNotificationTopic(field)}
                                checked={user.notificationTopics[field]}
                            />
                            <label class='custom-control-label' for={`${field}Input`} />
                        </div>
                        {saved[`${field}.${user.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='users.changes-saved'>Endringene er lagret</Text></div> :
                            <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
                    </div>
                </div>
            );
        }

        return (
            <div class={`form-group ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`}>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>
                <input type='text' class={`form-control ${saved[`${field}.${user.id}`] ? 'is-valid' : ''}`} id={`${field}Input`} aria-describedby={`${field}Help`}
                    value={value}
                    data-id={user.id}
                    data-field={field}
                    onInput={onInput}
                />
                {saved[`${field}.${user.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='users.changes-saved'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}
            </div>
        );
    }
}

@observer
class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.updateTimer = {};
    }

    async loadUser() {
		const { userStore } = this.props.stores;
        userStore.getInfo();
    }

    updateField = (e) => {
        const { id, field, type, elementtype } = e.target.dataset;
        const value = e.target.value;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype });
    }

    updateFieldValue = ({ id, field, value, datatype, elementtype, skipTimer = false }) => {
        const { userStore } = this.props.stores;
        let newValue = value;

        let localObjectValue = value;
        const { user } = userStore;
        if (field === 'images') {
            if (Array.isArray(user.images) && Array.isArray(value)) {
                localObjectValue = [...user.images, ...value];
            }
        }

        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }


        userStore.updateField(id, field, localObjectValue);
        if (skipTimer) {
            this.doUpdateField(id, field, newValue);
        } else {
            clearTimeout(this.updateTimer[field]);
            this.updateTimer[field] = setTimeout(async () => {
                this.doUpdateField(id, field, newValue);
            }, 750);
        }
    }

    async doUpdateField(id, field, value) {
        const { userStore } = this.props.stores;
        await userStore.saveField(id, field, value);
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

	componentDidMount() {
        this.loadUser();
    }

	render() {
		const { userStore } = this.props.stores;
		const { insertStatus, saved, user } = userStore;
        const { email } = user;
        const { field, title, help, icon, type, limitImageList, skipImageList } = this.props;

        if (field) {
            if (util.isArray(field)) {
                return field.map((fieldObj, idx) => {
                    return (
                        <Input
                            saved={saved}
                            user={user}
                            field={fieldObj.field}
                            icon={fieldObj.icon}
                            type={fieldObj.type}
                            stores={this.props.stores}
                            title={fieldObj.title}
                            help={fieldObj.help}
                            onInput={this.updateField}
                            updateField={this.updateFieldValue}
                            skipImageList={skipImageList}
                            limitImageList={limitImageList}
                            showMissingInfo={false}
                            detailsOpen={true}
                        />
                    );
                });
            }
            return <>
                <Input
                    saved={saved}
                    user={user}
                    field={field}
                    icon={icon}
                    type={type}
                    stores={this.props.stores}
                    title={title}
                    help={help}
                    onInput={this.updateField}
                    updateField={this.updateFieldValue}
                    skipImageList={skipImageList}
                    limitImageList={limitImageList}
                />
            </>;
        }

		return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <button class='btn btn-link pl-0 fixed-top text-primary'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                    onClick={this.back}>
                    <i class='fas fa-arrow-circle-left' />
                </button>
				<div class='row'>
					<div class='offset-0 col-12 offset-sm-2 col-sm-8 offset-lg-4 col-lg-4' style='padding-left: 35px;'>
                        <div>
                            <h5><Text id='users.edit'>Endre</Text>: {email}</h5>

                            <Input saved={saved} user={user} field='username' icon='fas fa-user'
                                title={<Text id='users.username'>Brukernavn</Text>}
                                help={<Text id='users.username-help'>Ditt brukernavn på The Musher</Text>}
                                onInput={this.updateField} />

                            <div class='row'>
                                <div class='col-6'>
                                    <Input saved={saved} user={user} field='firstname'
                                        title={<Text id='users.firstname'>Fornavn</Text>}
                                        help={<Text id='users.firstname-help'>Ditt fornavn</Text>}
                                        onInput={this.updateField} />
                                </div>
                                <div class='col-6'>
                                    <Input saved={saved} user={user} field='lastname'
                                        title={<Text id='users.lastname'>Etternavn</Text>}
                                        help={<Text id='users.lastname-help'>Ditt etternavn</Text>}
                                        onInput={this.updateField} />
                                </div>
                            </div>

                            <Input saved={saved} user={user} field='cellphone' icon='fas fa-mobile'
                                title={<Text id='users.cellphone'>Mobil</Text>}
                                help={<Text id='users.cellphone-help'>Ditt mobilnummer</Text>}
                                onInput={this.updateField} />

                            <Input saved={saved} user={user} field='url' icon='fas fa-link'
                                title={<Text id='users.url'>Hjemmeside</Text>}
                                help={<Text id='users.url-help'>Din hjemmeside</Text>}
                                onInput={this.updateField} />

                            <Input saved={saved} user={user} field='facebook' icon='fab fa-facebook-square'
                                title={<Text id='users.facebook'>Facebook</Text>}
                                help={<Text id='users.facebook-help'>Din Facebook profil</Text>}
                                onInput={this.updateField} />

                            <Input saved={saved} user={user} field='instagram' icon='fab fa-instagram'
                                title={<Text id='users.instagram'>Instagram</Text>}
                                help={<Text id='users.instagram-help'>Din Instagram profil</Text>}
                                onInput={this.updateField} />

                            <Input saved={saved} user={user} field='snapchat' icon='fab fa-snapchat'
                                title={<Text id='users.snapchat'>Snapchat</Text>}
                                help={<Text id='users.snapchat-help'>Din Snapchat profil</Text>}
                                onInput={this.updateField} />

                            <Input saved={saved} user={user} field='image' icon='fas fa-camera' type='image'
                                stores={this.props.stores}
                                title={<Text id='users.photos'>Bilder</Text>}
                                help={<Text id='users.photos-help'>Bilder av deg.</Text>}
                                onInput={this.updateField} updateField={this.updateFieldValue} />
                        </div>
					</div>

					{insertStatus && <div class='alert alert-success mt-4 col-12' role='alert'>
						<Text id='users.saved'>Profilen er lagret.</Text>
					</div>}
				</div>
			</div>
		);
	}
}

export default UserEdit;
