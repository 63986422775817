import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';


import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';
import Calories from '../../components/data/calories';
import Intensity from '../../components/data/intensity';
import Fitness from '../../components/data/fitness';

import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import LineGraph from '../../components/lineGraph';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; // mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);

@withText((props) => ({
    week: <Text id='workout.graph.week' />,
    distance: <Text id='workout.graph.distance' />,
    duration: <Text id='workout.graph.duration'>Duration</Text>,
    rest: <Text id='workout.graph.rest'>Rest</Text>,
    ascend: <Text id='workout.graph.ascend' />,
    plan: <Text id='workout.graph.plan' />,
    speedAvg: <Text id='workout.graph.speedavg'>Speed</Text>,
    loadIndex: <Text id='workout.graph.loadindex'>Loadindex</Text>,

    avgDogs: <Text id='workout.graph.avgdogs'>Avg dogs</Text>,
    avgImages: <Text id='workout.graph.avgimages'>Avg images</Text>,
    sledWeight: <Text id='workout.graph.sledweight'>Sled weight</Text>,
    rating: <Text id='workout.graph.rating'>Rating</Text>,
    temperature: <Text id='workout.graph.temperature'>Temperature</Text>,
}))
@observer
class WorkoutEquipmentDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { calendarMonth, calendarYear } = this.state;
        const {
            showHeader = true,
        } = this.props;

        const { userStore, appState, workoutStore } = this.props.stores;
        const { workoutType = 'all' } = appState;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;
        const { language = 'en', settings = {} } = user;

        const currentTeamObject = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const currentTeam = currentTeamObject.id;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm,
			elevation: weekElevation,
			duration: weekDuration,
			speedAvg: weekSpeedAvg,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
            graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
            graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
            graphAvgWorkoutStatsDistance,
            graphAvgWorkoutStatsElevation,
            graphAvgWorkoutStatsSpeed,
            graphAvgWorkoutStatsDuration,
            graphAvgWorkoutStatsRealDuration,
            graphAvgWorkoutStatsLoadIndex,
            graphAvgWorkoutStatsCount,
            workoutDistinctTypes,
            workoutSeasonEquipment,
		} = userStore;

        const { workout } = workoutStore;
        const { darkmode, isCordova } = appState;

        const workoutEquipment = userStore.findEquipment({ equipment: workout.equipment, language });
		const workoutEquipmentIcon = userStore.findEquipment({ equipment: workout.equipment, field: 'icon' });

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
				<Localizer>
					<WidgetHead
						stores={this.props.stores}
						title={<Text id='workout.workout-elevation-detail'>Workout Equipment</Text>}
						// right={<i class='fa-solid fa-pen-to-square' />}
						// onRightClick={this.onEditClick}
						// right2={<i class='fa-solid fa-upload' />}
						// onRight2Click={this.exportWorkout}
					/>
				</Localizer>
				{/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} p-3`} paddingBottom={0}>
                    <div class='display-4 text-center'>
                        <i class={workoutEquipmentIcon} /> {workoutEquipment}
                    </div>
                    <div class='w-100 mt-3 mb-4 mt-5 pt-5 border-top'>
                        {workoutSeasonEquipment.filter(e => e.season === seasonStart).map((wo, idx) => {
                            const percentDuration = wo.duration / workoutSeasonTotal.duration * 100;
                            const bgColor = mu.getRankingColor(idx + 1);
                            return (<>
                                <div class='row mt-3'>
                                    {/* <div class='col-12 border-top'>
                                        <xmp>{JSON.stringify(wo, null, 2)}</xmp>
                                    </div> */}
                                    <div class='col'>
                                        <i
                                            class={`${userStore.findEquipment({ equipment: wo.equipment, language, field: 'icon' })} text-muted`}
                                            style={`font-size: 2.0em;`}
                                        /><br />
                                        <span class='text-muted'>
                                            {userStore.findEquipment({ equipment: wo.equipment, language }) || 'No equipment'}
                                        </span>
                                    </div>
                                    <div class='col d-flex justify-content-end flex-column '>
                                        <div class='text-right' style={`font-size: 2.0em; line-height: 1.1em;`}>
                                            <Duration stores={this.props.stores} value={wo.duration} smallUnit={true} />
                                        </div>
                                        <div class='text-right text-muted' style={`font-size: 0.9em;`}>
                                            <Distance stores={this.props.stores} value={wo.distanceKm} smallUnit={true} />
                                        </div>
                                    </div>
                                </div>
                                <div class='row mb-2'>
                                    <div class='col-12'>
                                        <div class='progress' style='height: 3px;'>
                                            <div
                                                class='progress-bar'
                                                role='progressbar'
                                                style={`width: ${percentDuration}%; background-color: ${bgColor} !important;`}
                                                aria-valuenow={percentDuration}
                                                aria-valuemin='0'
                                                aria-valuemax='100'></div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>

                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutEquipmentDetail;
