import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Duration from '../../components/data/duration';
import Temperature from '../../components/data/temperature';

import Widget from '../../components/widget';
import MiniGraph from '../../components/workout/miniGraph';
import ScrollIntoView from '../../components/util/scrollIntoView';

import WorkoutTotals from '../../components/workout/totals';
import WorkoutTypeHeader from '../../components/workout/typeHeader';
import WorkoutCalendar from '../../components/workout/calendar';

import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();

@observer
class WorkoutStats extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onNavClick = (e, month, year) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            calendarMonth: month,
            calendarYear: year,
        });
    }

    chooseDay = ({ e, date, day, month, year, workoutid }) => {
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;

        if (workoutid) {
            appState.openDrawer2('workoutDetail', {
                onLinkClick: this.toggleDrawer,
                height: drawerHeightMedium,
                id: parseInt(workoutid, 10),
            });
        } else {
            this.newWorkout(date);
        }
    }

    newWorkout = (date) => {
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('newWorkout', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            team: user.activeTeam?.team || user.team,
            date,
        });
	}

    setDates = () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const { month = currentMonth, year = currentYear } = this.props;
        this.setState({
            calendarMonth: month,
            calendarYear: year,
        });
    }

    componentWillMount() {
        this.setDates();
    }

    render() {
        const { calendarMonth, calendarYear } = this.state;
        const {
            showHeader = true,
        } = this.props;

        const { userStore, appState } = this.props.stores;
        const { workoutType = 'all' } = appState;
        const { workoutSeasonTotal } = userStore;

        const { user } = userStore;
        const { language = 'en', settings = {} } = user;

        const currentTeamObject = userStore.getCurrentTeam();
        const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

        const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const { month = currentMonth, year = currentYear } = this.props;
        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm,
			elevation: weekElevation,
			duration: weekDuration,
			speedAvg: weekSpeedAvg,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;


        const {
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
            graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
            graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
            graphAvgWorkoutStatsDistance,
            graphAvgWorkoutStatsElevation,
            graphAvgWorkoutStatsSpeed,
            graphAvgWorkoutStatsDuration,
            graphAvgWorkoutStatsRealDuration,
            graphAvgWorkoutStatsLoadIndex,
            graphAvgWorkoutStatsCount,
            workoutDistinctTypes,
            workoutSeasonEquipment,
		} = userStore;

        return (<>
            <div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    {showHeader && <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'>
                            <Text id='workout.stats'>Statistics</Text>
                        </h3>
                    </div>}

                    <WorkoutTypeHeader stores={this.props.stores} />

                    {/* <Widget stores={this.props.stores}>
                        <h5>
                            <Text id='workouts.this-week'>This week</Text>
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={weekDistanceKm} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.duration'>Duration</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Duration stores={this.props.stores} value={weekDuration} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.elevation'>Elevation</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Elevation stores={this.props.stores} value={weekElevation} smallUnit={false} />
                                </h5>
                            </div>
                        </div>
                    </Widget> */}

                    {workoutSeasonEquipment && workoutSeasonEquipment.length > 0 && <>
							<Widget stores={this.props.stores} onClick={this.openDrawer} page='workoutStats'>
								{/*
									[
										{
											"equipment": 3,
											"count": 13,
											"distanceKm": 81.17872189077062,
											"elevation": 1559.6087737624316,
											"duration": 35729,
											"speedAvg": 8.944069042801798,
											"loadIndex": 42.888888888888886,
											"avgDogs": 4.538461538461538,
											"id": 248
										}
										...
									]
								*/}

								<h5>
									<Text id='workouts.equipment-this-season'>Equipment this season</Text>
								</h5>
								{workoutSeasonEquipment.map((wo, idx) => {
									const percentDuration = wo.duration / workoutSeasonTotal.duration * 100;
									const bgColor = mu.getRankingColor(idx + 1);
									return (<>
										<div class='row mt-3'>
											{/* <div class='col-12 border-top'>
												<xmp>{JSON.stringify(wo, null, 2)}</xmp>
											</div> */}
											<div class='col'>
												<i
													class={`${userStore.findEquipment({ equipment: wo.equipment, language, field: 'icon' })} text-muted`}
													style={`font-size: 2.0em;`}
												/><br />
												<span class='text-muted'>
                                                    {userStore.findEquipment({ equipment: wo.equipment, language }) || 'No equipment'}
                                                </span>
											</div>
											<div class='col d-flex justify-content-end flex-column '>
												<div class='text-right' style={`font-size: 2.0em; line-height: 1.1em;`}>
													<Duration stores={this.props.stores} value={wo.duration} smallUnit={true} />
												</div>
												<div class='text-right text-muted' style={`font-size: 0.9em;`}>
													<Distance stores={this.props.stores} value={wo.distanceKm} smallUnit={true} />
												</div>
											</div>
										</div>
										<div class='row mb-2'>
											<div class='col-12'>
												<div class='progress' style='height: 3px;'>
													<div
														class='progress-bar'
														role='progressbar'
														style={`width: ${percentDuration}%; background-color: ${bgColor} !important;`}
														aria-valuenow={percentDuration}
														aria-valuemin='0'
														aria-valuemax='100'></div>
												</div>
											</div>
										</div>
									</>);
								})}
							</Widget>
						</>}


                    <Widget stores={this.props.stores}>
                        <h5>
                           <i class='fa-regular fa-clock text-muted mr-2' /> <Text id='workouts.duration-this-season'>Duration this season</Text>
                        </h5>
                        <MiniGraph
                            stores={this.props.stores}
                            legendLeft={[`${seasonStart}`, `${seasonStart - 1}`]}
                            dataLeft={[graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev]}
                            yTicksLeftVal={val => util.secToHms(val, true)}
                            showDebug={false}
                        />
                    </Widget>

                    <Widget stores={this.props.stores}>
                        <h5>
                            <i class='fa-solid fa-road text-muted mr-2' /> <Text id='workouts.distance-this-season'>Distance this season</Text>
                        </h5>
                        <MiniGraph
                            stores={this.props.stores}
                            legendLeft={[`${seasonStart}`, `${seasonStart - 1}`]}
                            dataLeft={[graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev]}
                            yTicksLeftVal={val => val}
                            yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                            showDebug={false}
                        />
                    </Widget>

                    <Widget stores={this.props.stores}>
                        <h5>
                            <i class='fa-solid fa-mountain text-muted mr-2' /> <Text id='workouts.elevation-this-season'>Elevation this season</Text>
                        </h5>
                        <MiniGraph
                            stores={this.props.stores}
                            legendLeft={[`${seasonStart}`, `${seasonStart - 1}`]}
                            dataLeft={[graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev]}
                            yTicksLeftVal={val => val}
                            yTicksLeftPostfix={settings.imperial ? 'ft' : 'm'}
                            showDebug={false}
                        />
                    </Widget>

                    {/* <Widget stores={this.props.stores}>
                        <h5>
                            <i class='fa-solid fa-gauge text-muted mr-2' /> <Text id='workouts.avg-speed-this-season'>Avg speed this season</Text>
                        </h5>
                        <MiniGraph
                            stores={this.props.stores}
                            legendLeft={[`${seasonStart}`, `${seasonStart - 1}`]}
                            dataLeft={[graphAvgWorkoutStatsSpeed]}
                            yTicksLeftVal={val => val}
                            yTicksLeftPostfix={settings.imperial ? 'ft' : 'm'}
                            showDebug={false}
                        />
                    </Widget> */}

                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 py-2 bg-light'>
                        <h5>
                            <Text id='workouts.season-totals'>This season</Text>
                        </h5>
                        <div class='row'>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.distance'>Distanse</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Distance stores={this.props.stores} value={workoutSeasonTotal.distanceKm} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.duration'>Duration</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Duration stores={this.props.stores} value={workoutSeasonTotal.duration} smallUnit={false} />
                                </h5>
                            </div>
                            <div class='col'>
                                <small class='text-muted'>
                                    <Text id='workout.elevation'>Elevation</Text>
                                </small>
                                <h5 class='font-weight-light'>
                                    <Elevation stores={this.props.stores} value={workoutSeasonTotal.elevation} smallUnit={false} />
                                </h5>
                            </div>
                        </div>
                    </div> */}

                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    <Widget stores={this.props.stores}>
                        <h5>
                            <Text id='workouts.season-totals'>This season</Text>
                        </h5>
                        <div>
                            <WorkoutTotals
								stores={this.props.stores}
								currentTeam={currentTeam}
								year={year}
								month={month}
                                type={workoutType}
							/>
                        </div>
                    </Widget>

                    {/* widget - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    <Widget stores={this.props.stores}>
                        <h5>
                            <Text id='workouts.month-detail'>Month details</Text>
                        </h5>
                        <div>
                            <WorkoutCalendar stores={this.props.stores}
                                year={calendarYear}
                                month={calendarMonth}
                                chooseDayFunction={this.chooseDay}
                                key={`calendar-${currentTeam}`}
                                hideTable={false}
                                hideSummary={true}
                                skipLoadSummary={true}
                                onNavClick={this.onNavClick}
                                classNames={'col-12'}
                            />
                        </div>
                    </Widget>

                </div>
            </div>
        </>);
    }
}

export default WorkoutStats;
