import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';

@observer
class ProfileLastPost extends Component {
    render() {
        return (<Widget stores={this.props.stores} {...this.props}>
            ProfileLastPost
        </Widget>);
    }
}

export default ProfileLastPost;
