import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import BarGraph from '../../components/barGraph';

@observer
class MiniGraph extends Component {
    render() {
        const {
            legendLeft = [],
            legendRight = [],
            dataLeft = [],
            dataRight = [],
            showDebug,
            xVal,
            xTicksVal = val => val,
            xTicksCnt = 12,
            yTicksLeftVal = val => val,
            yTicksRightVal = val => val,
            yTicksLeftPostfix,
            xTicksType = 'week',
            showYTicksLeft = true,
            showXTicks = 1,
            width = 350,
            height = 150,
            paddingLeft = 60,
            yTicksLeftWidth = '50px',
            periode,
        } = this.props;

        const firstData = dataLeft && dataLeft[0] ? dataLeft[0] : [];
        const xTicks = [];
        if (firstData.length) {
            const partSize = Math.ceil(firstData.length / (xTicksCnt - 1));
            const parts = Math.floor(firstData.length / partSize);
            if (showDebug) {
                console.log({ firstData, parts, partSize });
            }
            for (let i = 0, l = firstData.length; i < l; i += partSize) {
                if (xTicksType === 'day')  {
                    xTicks.push(`${firstData[i].day}`);
                } else if (xTicksType === 'month')  {
                    xTicks.push(`${firstData[i].year} ${firstData[i].month}`);
                } else {
                    xTicks.push(`${firstData[i].year} w${firstData[i].week}`);
                }
            }
            if (xTicksType === 'day')  {
                xTicks.push(`${firstData[firstData.length - 1].day}`);
            } else if (xTicksType === 'month')  {
                xTicks.push(`${firstData[firstData.length - 1].year} ${firstData[firstData.length - 1].month}`);
            } else {
                xTicks.push(`${firstData[firstData.length - 1].year} w${firstData[firstData.length - 1].week}`);
            }
        }
        return (
            <>
                {showDebug && <div class='w-100'>
                    <xmp style='height: 300px; overflow: scroll;'>
                        {JSON.stringify(dataLeft, null, 4)}
                    </xmp>
                </div>}
                <div class='w-100'>
                    {/* <xmp>graphWorkoutSummaryWeekDistanceCurrent: {JSON.stringify(graphWorkoutSummaryWeekDistanceCurrent, null, 4)}</xmp>
                    <xmp>graphWorkoutSummaryWeekDistancePrev: {JSON.stringify(graphWorkoutSummaryWeekDistancePrev, null, 4)}</xmp> */}
                    <BarGraph
                        stores={this.props.stores}
                        width={width}
                        height={height}
                        showXTicks={showXTicks}
                        tickCount={3}
                        xVal={xVal}
                        xTicksVal={xTicksVal}
                        xTicks={xTicks}
                        paddingTop={0}
                        paddingLeft={paddingLeft}
                        lineFromXzero={false}
                        lineFromXmax={false}
                        // xTicksLink={'/workouts/week/'}

                        dataLeft={dataLeft}
                        legendLeft={legendLeft}
                        // yMaxLeft={30}
                        // yMinLeft={0}
                        showYTicksLeft={showYTicksLeft}
                        yTicksLeftWidth={yTicksLeftWidth}
                        yTicksLeftPostfix={yTicksLeftPostfix}
                        // yTicksLeftPostfix={'km'}
                        // unitLeft={['km', 'km', 'km']}
                        yTicksLeftVal={yTicksLeftVal}
                        avgWindowLeft={[0, 0]}
                        smoothLeft={[1, 1]}

                        showValueLeftLine={false}

                        dataRight={dataRight}
                        legendRight={legendRight}
                        // dataRight={[graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev]}
                        // legendRight={[`${seasonStart} ${ascend}`, `${seasonStart - 1} ${ascend}`]}
                        showYTicksRight={dataRight.length > 0 ? 1 : 0}
                        // yTicksRightPostfix={'m'}
                        // unitRight={['m', 'm']}
                        yTicksRightVal={yTicksRightVal}
                        avgWindowRight={[0, 0]}
                        smoothRight={[0, 0]}

                        scrollToRight={true}
                        // marker={week}
                        periode={periode}
                    />
                </div>

            </>
        );
    }
}

export default MiniGraph;
