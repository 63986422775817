import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import Gauge from '../../../components/gauge';

import mu from '../../../lib/musher-util';

@observer
class WorkoutGoals extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetWorkoutGoalsDetail', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Analysis'
        });
    }

    render() {
        const { userStore, appState, workoutStore, workoutPlanStore, teamStore,
			stravaActivityStore, garminActivityDetailsStore } = this.props.stores;
        const { user } = userStore;
        const { settings = {} } = user;

        const currentDay = new Date().getDate();
        const currentWeek = util.getWeek(new Date());
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const planWeekDistanceKm = workoutPlanStore.sumWorkoutPlanWeek(currentYear, currentWeek, 'distance') || 0;

        const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm = 0,
			elevation: weekElevation = 0,
			duration: weekDuration = 0,
			speedAvg: weekSpeedAvg = 0,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

		let percentComplete;
		if (planWeekDistanceKm) {
			percentComplete = Math.floor(weekDistanceKm / planWeekDistanceKm * 100);
		}

        return (<Widget stores={this.props.stores} {...this.props} onClick={this.onClick}>
            <div class={`widgetHeader`}>
                <i class='fa-solid fa-bullseye-pointer widgetHeaderIcon mr-2' />
                <Text id='workouts.goals'>goals</Text><br />
                <div class='widgetContentText text-center d-flex justify-content-center'>
                    {planWeekDistanceKm >= 0 && (
                        <Gauge
                            key={`gauge-${percentComplete}`}
                            radius={55}
                            percent={percentComplete}
                            prefix='%'
                            total={settings.imperial ? `${mu.kmToMiles(planWeekDistanceKm)} mi` : `${util.format(planWeekDistanceKm, 0)} km`}
                            completed={settings.imperial ? `${mu.kmToMiles(weekDistanceKm)} mi` : `${util.format(weekDistanceKm, 0)} km`}
                        />
                    )}
                </div>
            </div>
        </Widget>);
    }
}

export default WorkoutGoals;
