import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class AdStore extends StoreModel {
    constructor() {
        super('ad', {
            namePlural: 'ads',
            sort: 'createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/ads/',
                    params: {
                        limit: 15,
                        sort: 'createdDate',
                    },
                },
                load: {
                    url: '/api/ads/',
                    params: {},
                },
                save: {
                    url: '/api/ads/',
                    params: {},
                },
            },
        });
    }

    @observable newAd = {};

    @observable ad = {};

    @observable ads = [];

    @observable tags = [];

    @observable adViews = {};

    @observable adViewsMonth = {};

    @observable adClicks = {};

    @observable adClicksMonth = {};

    @observable viewCounts = {};

    @observable clickCounts = {};

    @observable time = new Date().getTime();

    @action
    setTime() {
        this.time = new Date().getTime();
    }

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.ad.images)) {
            const idx = this.ad.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.ad.images.splice(idx, 1);
            }
        }
        const widx = this.ads?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.ads[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.ads[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/ads/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async logView({ id, user }) {
        const response = await util.fetchApi(`/api/ads/${id}/view`, { publish: true, method: 'PATCH' }, { user });
        switch (response.status) {
            case 202:
                return response;
        }
    }

    async logClick({ id, user }) {
        const response = await util.fetchApi(`/api/ads/${id}/click`, { publish: true, method: 'PATCH' }, { user });
        switch (response.status) {
            case 202:
                return response;
        }
    }

    @action
    updateAdViews(id, views) {
        this.adViews[id] = views;
    }

    @action
    updateAdClicks(id, views) {
        this.adClicks[id] = views;
    }

    @action
    groupByMonth(adId) {
        this.adViewsMonth = {};
        this.adClicksMonth = {};
        this.adViews[adId].forEach((obj) => {
            const key = `${obj.year}-${util.padDate(obj.month)}`;
            if (!this.adViewsMonth[key]) {
                this.adViewsMonth[key] = 0;
            }
            this.adViewsMonth[key] += obj.y;
        });
        if (this.adClicks[adId]) {
            this.adClicks[adId].forEach((obj) => {
                const key = `${obj.year}-${util.padDate(obj.month)}`;
                if (!this.adClicksMonth[key]) {
                    this.adClicksMonth[key] = 0;
                }
                this.adClicksMonth[key] += obj.y;
            });
        }
    }

    async loadById({ id, showall }) {
        const response = await util.fetchApi(`/api/ads/${id}`, { publish: true, method: 'GET' }, { showall });
        switch (response.status) {
            case 200:
                if (response.included) {
                    this.updateAdViews(id, response.included.adViews[id]);
                    this.updateAdClicks(id, response.included.adClicks[id]);
                    this.groupByMonth(id);
                }
                return response.data;
        }
    }
}

const store = new AdStore();
export default store;
