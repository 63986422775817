import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ApplicationStore extends StoreModel {
    constructor() {
        super('application', {
            namePlural: 'applications',
            sort: 'createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/applications/',
                    params: {
                        limit: 15,
                        sort: 'createdDate',
                    },
                },
                load: {
                    url: '/api/applications/',
                    params: {},
                },
                save: {
                    url: '/api/applications/',
                    params: {},
                },
            },
        });
    }

    @observable newApplication = {};

    @observable application = {};

    @observable applications = [];

    @action
    removeImageLocal({ id, name }) {
        if (util.isArray(this.application.images)) {
            const idx = this.application.images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.application.images.splice(idx, 1);
            }
        }
        const widx = this.applications?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this.applications[widx].images?.findIndex(e => e.name === name);
            if (idx > -1) {
                this.applications[widx].images.splice(idx, 1);
            }
        }
    }

    async removeImage({ id, name: removeImageByName }) {
        const response = await util.fetchApi(`/api/applications/${id}`, { publish: true, method: 'PATCH' }, { removeImageByName });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, name: removeImageByName });
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

}

const store = new ApplicationStore();
export default store;
