import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import mu from '../../lib/musher-util';

const STOP_STATUSES = [2, 9];
const WARN_STATUSES = [4, 8];


function getFatigueLevel(wo, dog) {
    if (dog) {
        if (STOP_STATUSES.indexOf(dog.status) > -1) {
            return 'danger';
        } else if (WARN_STATUSES.indexOf(dog.status) > -1) {
            return 'warning';
        }
    }
    if (wo) {
        if (wo.distanceKm > 50) {
            return 'danger';
        } else if (wo.distanceKm > 30) {
            return 'warning';
        }
    }
    return 'success';
}

@observer
class Dog extends Component {
    render() {
        const {
            dog,
            showWorkout,
            showTeam,
            showNeeds,
            showInfo,
            showName = true,
            showPosition = true,
            showAge = false,
            className,
            size,
            onDogClick = () => {},
        } = this.props;
		const { userStore, dogStore } = this.props.stores;
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;

        if (!dog) {
            if (size === 'small') {
                return (
                    <div class={`mb-1 position-relative ${className}`} style='width: 50px;'>
                        <div class={`text-center border rounded-circle imageRounded imageRoundedSmall text-muted pt-2  mx-auto`}>
                            <span class='font-weight-lighter' style='font-size: 20px;'>&nbsp;</span>
                        </div>
                        {showName && <div class='text-center'>
                            &nbsp;
                        </div>}
                    </div>
                );
            }
            if (size === 'medium') {
                return (
                    <div class={`mb-1 position-relative ${className}`} style='width: 60px;'>
                        <div class={`text-center border rounded-circle imageRounded imageRoundedMedium text-muted pt-2  mx-auto`}>
                            <span class='font-weight-lighter' style='font-size: 25px;'>&nbsp;</span>
                        </div>
                        {showName && <div class='text-center'>
                            &nbsp;
                        </div>}
                    </div>
                );
            }
            return (
                <div class={`mb-2 position-relative ${className}`} style='width: 92px;'>
                    <div class={`text-center border rounded-circle imageRounded text-muted pt-2  mx-auto`}>
                        <span class='font-weight-lighter' style='font-size: 35px;'>&nbsp;</span>
                    </div>
                    {showName && <div class='text-center'>
                        &nbsp;
                    </div>}
                </div>
            );
        }

        const dogStatus = userStore.findDogstatus({ status: dog.status, language });
        const dogPosition = userStore.findDogposition({ position: dog.position, language });
        const workoutSummary = dogStore.findCurrentWorkoutSummary(dog.id);
        const hasBirthday = mu.hasBirthday(dog.birth);
        const daysUntilBirthday = Math.ceil(mu.daysUntilNextBirthday(dog.birth));

        if (size === 'small') {
            return (
                <div class={`mb-1 position-relative ${className}`} style='width: 50px;'>
                    <div
                        class={`text-center border border-${getFatigueLevel(workoutSummary, dog)} rounded-circle imageRounded imageRoundedSmall mx-auto text-muted pt-2`}
                        style={`${dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''} border-width: 2px !important;`}
                    >
                        {!dog.image && <span class='font-weight-lighter' style='font-size: 20px;'>{util.ucfirst(dog.name, true)}</span>}
                    </div>
                </div>
            );
        }
        if (size === 'medium') {
            return (
                <div class={`mb-1 position-relative ${className}`} style='width: 60px;'>
                    <div
                        class={`text-center border border-${getFatigueLevel(workoutSummary, dog)} rounded-circle imageRounded imageRoundedMedium mx-auto text-muted pt-2`}
                        style={`${dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''} border-width: 2px !important;`}
                    >
                        {!dog.image && <span class='font-weight-lighter' style='font-size: 20px;'>{util.ucfirst(dog.name, true)}</span>}
                    </div>
                </div>
            );
        }

        return (
            <div class={`mb-2 position-relative ${className}`} style='width: 92px;'>
                {/* {JSON.stringify(dog, null, 4)} */}
                {showPosition && dogPosition && <small class='position-absolute' style='left:0px; top: 50px;'>
                    <span class={`badge badge-${dogPosition.class || 'primary'} mr-3`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>
                </small>}
                <div
                    class={`text-center border border-${getFatigueLevel(workoutSummary, dog)} rounded-circle imageRounded mx-auto text-muted pt-2`}
                    style={`${dog.image ? `background-image: url("${dog.image.s3SmallLink}"); background-size: cover;` : ''} border-width: 2px !important;`}
                >
                    {/* {!dog.image && <i class='fas fa-dog mt-3' style='font-size: 40px;' />} */}
                    {!dog.image && <span class='font-weight-lighter' style='font-size: 35px;'>{util.ucfirst(dog.name, true)}</span>}
                </div>
                <div class='text-center' style='line-height: 1.0em;'>
                    <small>
                        {/* <span class='float-right'>
                            {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                            {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                        </span> */}
                        {showName && <a class='stretched-link' href={`/dogs/${dog.id}`} onClick={e => onDogClick(e, dog.uuidv4)}>
                            <h5 class='mb-1 font-weight-light' style='line-height: 1.0em; font-size: 1.3em;'>
                                {dog.shortname || dog.name}
                                {hasBirthday && <>
                                    <i class='fas fa-birthday-cake ml-3' />
                                </>}
                            </h5>
                        </a>}

                        {dog.birth && showAge && <>
                            <span>
                                {daysUntilBirthday < 7 && <>
                                    <span class='text-muted mr-2'>
                                        <i class='fas fa-birthday-cake' /> in {daysUntilBirthday} days
                                    </span>
                                </>}
                                {dog.birth && <span class='mr-2'>{util.age(dog.birth, dog.deceased)}</span>}
                                {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                            </span>
                        </>}

                        {showTeam && <><span class='font-weight-light'>{userStore.findTeam(dog.team)}</span><br /></>}

                        {showWorkout && <small class='text-muted'>
                            {workoutSummary && workoutSummary.distanceKm > 0 && <span class='mr-2'><i class='fas fa-road' /> {util.format(workoutSummary.distanceKm, 0)}km</span>}
                            {workoutSummary && workoutSummary.elevation > 0 && <span class='mr-2'><i class='fas fa-mountain' /> {util.format(workoutSummary.elevation, 0)}m</span>}
                            {workoutSummary && workoutSummary.duration > 0 && <span class='mr-2'><i class='fas fa-clock' /> {util.secToHms(workoutSummary.duration, true)}</span>}
                            {workoutSummary && workoutSummary.speedAvg > 0 && <span class='mr-2'><i class='fas fa-tachometer-alt' /> {util.format(workoutSummary.speedAvg, 1)}km/t</span>}
                        </small>}

                        {showInfo && <small class='text-muted'>
                            {dog.weight && <><i class='fas fa-balance-scale ml-2' /> {util.format(dog.weight, 1)} kg</>}
                            {dog.bodyScore && <><i class='fas fa-dog ml-2' /> {util.format(dog.bodyScore, 1)}</>}
                        </small>}

                        {showNeeds && <div class='clearfix pb-0 pt-1'>
                            {dog.specialNeeds && <span class={`badge badge-warning mr-2`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                            {dogStatus && <span class={`badge badge-${dogStatus.class} mr-2`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                        </div>}
                    </small>
                </div>
            </div>
        );
    }
}

export default Dog;
