import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import DogSimpleV2 from '../../../components/dog/simple-v2';
import WidgetRouter from '../../../components/widget/router';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileDogDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: `dog-detail-${new Date().getTime()}`,
        };
    }

    async loadAll(props = this.props) {
        this.setState({ isLoading: true });
        await Promise.all([
            this.loadDog(props),
            this.loadWorkoutPlans(props),
        ]);
        this.setState({ isLoading: false });
    }

    loadDog = async (props = this.props) => {
		const { dogStore, userStore } = props.stores;
        const dogId = parseInt(props.id, 10);
        await Promise.all([
            // dogStore.load(dogId, false, { addData: ['vaccineStatuses', 'historyStatuses', 'allDogs'] }),
            dogStore.load(dogId, false, { addData: ['team', 'user', 'workoutSummary', 'vaccineStatuses', 'historyStatuses', 'vaccineLogStatuses', 'allDogs'] }),
            userStore.getWorkoutSummary({ dog: dogId }),
        ]);

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const { month = currentMonth, year = currentYear } = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);
        dogStore.findWorkoutSummaryCurrenSeason(dogId, seasonStart) || {};
        dogStore.findWorkoutSummaryPreviousSeason(dogId, seasonStart - 1) || {};


        const { dog } = dogStore;
        if (dog && dog.team) {
            this.loadTeam(dog.team);
        }
        if (dog && dog.motherChipId) {
            dogStore.loadExternalDogs([dog.motherChipId, dog.fatherChipId]);
        }
        dogStore.plotFormGraph();
    }

    loadTeam = async (team) => {
		const { teamStore } = this.props.stores;
        await teamStore.load(team);
        teamStore.plotFormGraph();
    }

    loadWorkoutPlans = async (props = this.props) => {
		const { year } = props;

		const currentMonth = new Date().getMonth() + 1;
		const currentYear = util.getYear();
		const seasonStart = parseInt(year || (currentMonth < 8 ? currentYear - 1 : currentYear), 10);
		const { workoutPlanStore, dogStore } = props.stores;
		const { dog } = dogStore;

		workoutPlanStore.updateItem('workoutPlans', []);
		workoutPlanStore.updateItem('workoutPlan', {});
		workoutPlanStore.resetGraphPlanWeekDistanceCurrent();

		if (dog.team) {
			await workoutPlanStore.load({ query: {
				team: dog.team,
				seasonYear: seasonStart,
				seasonMonth: 6,
			} });
			const { workoutPlans } = workoutPlanStore;
			const workoutPlanCurrent = workoutPlans[0] || {};
            if (workoutPlanCurrent.id) {
                await workoutPlanStore.load(workoutPlanCurrent.id);
                workoutPlanStore.prepareWeekGraphs({ year });
            } else {
                workoutPlanStore.updateKeyValue('workoutPlan', {});
                workoutPlanStore.updateKeyValue('workoutPlans', []);
                workoutPlanStore.resetGraphPlanWeekDistanceCurrent();
            }
		}
	}

    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { key } = this.state;
        const { appState, userStore } = this.props.stores;
        const { drawerLevel, id } = this.props;
        const { darkmode } = appState;

        const team = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const dog = userStore.findDog(parseInt(id, 10));

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={dog.name} right={<Text id='dogs.add'>Add dog</Text>} onRightClick={this.newDog} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br />
                    id: {id}<br /> */}

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='row'>
                            <WidgetRouter widgets={team.widgetsDogs} stores={this.props.stores} key={key} id={id} />
                        </div>
                    </div>

                    {/* <DogSimpleV2
                        key={`dog-id-${dog.id}`}
                        stores={this.props.stores}
                        dog={dog}
                        showAge={true}
                        className={'w-100'}
                        onDogClick={this.onDogClick}
                    /> */}
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileDogDetail;
