import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import mu from '../../../lib/musher-util';



@observer
class ProfileLastDogLog extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetProfileLastDogLogList', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Profile'
        });
    }

    render() {
        const { imageElementScale } = this.state;

        const { submenu, height, isOverflow, isExpanded, filterText, sessionid, handleIntersection, key } = this.state;
        const { userStore, appState, dogStore } = this.props.stores;
        const { darkmode } = appState;
		const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
		const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;

        const team = userStore.getCurrentTeam();
        const dogs = userStore.findDogsByTeam(team.id);

        const { history = [] } = dogStore;

        if (dogs.length === 0) {
            return (<></>);
        }

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} headerRight={'View all'} onHeaderClick={this.onClick} onClick={this.onClick}>
            <div class='w-100 position-relative px-3 pb-3 pt-2'>
                {history.slice(0, 3).map(line => {
                    const dogImage = line.dogImage?.s3SmallLink;
                    return (<>
                        <div
                            class={`col-12 clearfix rounded-lg mb-3 pl-0 d-flex bg-light`}
                            style='
                                border-radius: 20px !important;
                            '
                            key={`history-id-${line.uuidv4}`}
                        >
                            <div
                                class={`text-muted bg-secondary px-3 bg-dark d-flex justify-content-center align-items-center`}
                                style='
                                    border-radius: 20px 0 0 20px !important;
                                    font-size: 20px;
                                    line-height: 20px;
                                    font-weight: 900;
                                '
                            >
                                <i class={mu.resolveJournalIcon(line.historyType)} />
                            </div>
                            <div class='d-flex justify-content-left flex-column p-2'>
                                <small class='text-muted'>
                                    {/* {util.formatDate(line.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' })} */}
                                    {util.formatDistance(line.date, new Date(), { locale: 'no-NB' })}
                                </small>
                                <span>{line.dogShortname || line.dogName}: {line.title}</span>
                            </div>
                        </div>
                    </>);
                })}
            </div>
        </Widget>);
    }
}

export default ProfileLastDogLog;
