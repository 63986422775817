import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

@observer
class Modal extends Component {
    toggleDate = () => {
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

    touchStart = (e) => {
        e.stopPropagation();
        this.setState({
            startY: e.touches[0].pageY,
        })
    }

    touchEnd = (e) => {
        e.stopPropagation();
        this.setState({
            startY: undefined,
            readyToRefresh: false,
        })
    }

    touchMove = (e) => {
        e.stopPropagation();
        const { close } = this.props;
        const { startY, refreshing } = this.state;
        const scrollDistance = 30;
        const y = e.touches[0].pageY;
        if (this.appContainer) {
            const { scrollTop } = this.appContainer;
            if (scrollTop === 0 && y > startY + scrollDistance && !refreshing) {
                close();
            }
        }
    }

    render() {
        const { showDate } = this.state;
        const { image, imagexxl, close, thumb, name, title, date } = this.props;
        return (
            <div class='fixed-top bg-dark text-center pt-2' style='width: 100vw; height: 100vh;'>
                <button class='btn btn-secondary float-right mr-2' onClick={close}>X</button>

                    <div class='d-flex mb-2 px-2'>
                        {/* {JSON.stringify(dog, null, 4)} */}
                        {thumb && <div class={`text-center border rounded-circle imageRounded imageRoundedSmall`} style={thumb ? `background-image: url("${thumb}");` : ''}>
                            &nbsp;
                        </div>}
                        <div class='flex-grow-1 pl-3 pr-2 text-left' style='line-height: 1.3em;'>
                            {name && <strong class='mr-2'>{name}:</strong>}
                            {title}<br />
                            <small class='text-muted'>
                                {date && <small onClick={this.toggleDate}>
                                    {showDate ? util.formatDate(date, { locale: 'no-NB' }) : util.formatDistance(date, new Date(), { locale: 'no-NB' })}
                                </small>}
                            </small>
                        </div>
                    </div>


                <img src={image}
                    class='img-fluid align-middle mt-2 mb-2'
                    onClick={close}
                    onTouchstart={this.touchStart}
                    onTouchend={this.touchEnd}
                    onTouchmove={this.touchMove}
                    ref={c => this.appContainer = c}
                />

                <a target='_blank' rel='noopener noreferrer' href={imagexxl || image} download={imagexxl || image}>
                    <i class='fas fa-download' /> <Text id='modal.download'>Last ned bildet</Text>
                </a>
            </div>
        );
    }
}

export default Modal;
