import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetProfileLastTrophyList extends Component {
    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    render() {
        const { appState, trophyStore, userStore } = this.props.stores;
        const { drawerLevel } = this.props;
        const { darkmode } = appState;

        const { trophies = [] } = trophyStore;
        const team = userStore.getCurrentTeam();

        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;
		const { teams, settings = {}, language = 'en', currentLocationGeocode = {}, yrWeather = [] } = user;

        const lastTrophy = trophies.reverse().find(t => {
            const hasTrophy = team.trophys?.findIndex(e => e.name === t.key) > -1;
            return hasTrophy;
        });
        let lastTrophyImg;
        if (lastTrophy) {
            lastTrophyImg = mu.getTrophyImage(lastTrophy.key);
        }

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={<Text id='profile.trophys'>All Trophies</Text>} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br /> */}

                    <Widget stores={this.props.stores} onClick={this.openDrawer} classNamesPadding='p-0' page='workoutStats' header={`My greates achivement`}>
                        <div class='container-fluid'>
                            <div class='row'>
                                <div class='col-12'>
                                    <img src={lastTrophyImg} class='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores} onClick={this.openDrawer} classNamesPadding='p-0' page='workoutStats' header={`Trophies 2022`}>
                        <div class='container-fluid'>
                            <div class='row'>
                                {trophies.map((trophy, idx) => {
                                    const hasTrophy = team.trophys?.findIndex(e => e.name === trophy.key) > -1;
                                    const img = mu.getTrophyImage(trophy.key);
                                    if (!img) {
                                        return '';
                                    }
                                    const textHeader = trophy.descriptionHeader && trophy.descriptionHeader[language] ? trophy.descriptionHeader[language] : '';
                                    const text = trophy.description && trophy.description[language] ? trophy.description[language] : '';
                                    const colWidth = 4;
                                    //     <strong>{textHeader}</strong>
                                    //     {/* <small class='font-weight-lighter mr-2'>
                                    //         {util.isoDate(file.createdDate, false, false, true)}
                                    //     </small> */}
                                    //     <small>
                                    //         <details>
                                    //             <summary><Text id='dogs.trophy-details'>Details</Text></summary>
                                    //             <Markdown markdown={mu.replaceImages(text, dog.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                    //         </details>
                                    //     </small>
                                    return (<>
                                        <div class={`col-${colWidth} clearfix`} key={`trophy-id-${trophy.uuidv4}`}>
                                            <div
                                                class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center position-relative p-2`}
                                                style={`
                                                    ${!hasTrophy ? 'filter: grayscale(100%) opacity(0.20);' : ''};
                                                `}
                                            >
                                                <img src={img} class='img-fluid' />
                                                {/* {JSON.stringify(trophy)} */}
                                            </div>
                                        </div>
                                    </>);
                                })}
                            </div>
                        </div>
                    </Widget>

                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetProfileLastTrophyList;
