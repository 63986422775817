import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

const initialState = {
	showAddComment: false,
};

@withText(() => ({
    subject: <Text id='ad.subject'>Annonseforespørsel</Text>,
    body: <Text id='ad.body'>Hei, Jeg ønsker å vite mer om annonsering i appen The Musher.</Text>,
}))

@observer
class Ad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.updateTimer = {};
		this.inputRef = {};
        this.apiServer = util.getApiServer();
    }

	async loadAd(props = this.props) {
		this.setState({ isLoading: true });
		const { adStore, workoutStore, userStore, appState } = this.props.stores;
        const { section } = this.props;
        const { path: url = '' } = appState;
        // const { currentTeam } = workoutStore;
        // const { user } = userStore;
        // const { language = 'en' } = user;
        // let team = {};
        // let ambitions;
        // let type;
        // let postalcode;
        // let place;
        // let country;
        // if (currentTeam) {
            // team = userStore.findTeam(currentTeam, true);
            // if (team && team.id) {
            //     ambitions = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
            //     type = userStore.findTeamType({ type: team.type, language });
            //     postalcode = team.postalcode;
            //     place = team.place;
            //     country = team.country;
            // }
        // }
        // console.log({ currentTeam, team, ambitions,  type, postalcode, place, country });
        // const { url = '' } = this.props;
        let page = url.replace(/\d+$/, 'detail');
        page = url.replace(/\/\d+/, '/detail');

        // props: { "url": "/" }
        // props: { "url": "/workouts/" }
        // props: { "url": "/workouts/4708" }
        // props: { "url": "/dogs" }
        // props: { "url": "/dogs/10" }

		await adStore.load({ query: { page, section }});
        adStore.setTime();
		this.setState({ isLoading: false });
	}

    logClick = async () => {
        const { adStore, userStore } = this.props.stores;
        const { ads = [] } = adStore;
        let id = ads.length > 0 ? ads[0].id : null;
        const { user = {} } = userStore;
        await adStore.logClick({ id, user: user.id });
    }

	componentDidMount() {
		this.loadAd();
	}

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.path !== this.props.path) {
            this.loadAd(nextProps);
        }
    }

    render() {
        const { adStore, userStore, appState } = this.props.stores;
        const { subject, body, section } = this.props;
        const { path } = appState;
        const { user = {} } = userStore;
        const { ads = [], time, adViews, adClicks } = adStore;
        if (!user || !user.id || !ads || ads?.length === 0) {
            if (path.match(/\/(home)/i)) {
                return <>
                    <div class='w-100'>
                        <div class='row'>
                            <div class='col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative'>
                                <div class='border rounded-lg p-4 my-3'>
                                    <span class='float-right display-4 text-warning'>
                                        <i class='far fa-smile-wink' />
                                    </span>
                                    <h5><Text id='ad.heading'>Vil du annonsere her?</Text></h5>
                                    <div><Text id='ad.teaser'>Det er super enkelt :)</Text></div>
                                    <Localizer>
                                        <a href={`mailto:post@themusher.no?subject=${subject}: ${path}: ${section}&body=${body}`} target='_blank' rel='noreferrer'>
                                            <i class='fas fa-paper-plane' /> <Text id='ad.linktext'>Ta kontakt med The Musher</Text>
                                        </a>
                                    </Localizer>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
            }
            return <></>;
        }
        const ad = ads[0];
        return (
            <>
                <div class='w-100'>
                    <div class='row'>
                        <div class='col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative'>
                            <div>
                                <small><small class='text-muted'>
                                    <Text id='ad.ad'>Annonse</Text>:
                                    <img src={`${this.apiServer}/api/ads/${ad.id}/view?user=${user.id}&time=${time}`} style='width: 1; height: 1; opacity: 0.1;' />
                                </small></small>
                            </div>
                            {ad.target ? <>
                                <a
                                    class='stretched-link'
                                    href={`${ad.url}`}
                                    target={ad.target || '_blank'}
                                    rel='noopener noreferrer'
                                    onClick={this.logClick}
                                    native
                                >
                                    {ad.images && ad.images[0] && <>
                                        <img src={ad.images[0].s3LargeLink} class='img-fluid rounded-lg' />
                                    </>}
                                </a>
                            </> : <>
                                <a
                                    class='stretched-link'
                                    href={`${ad.url}`}
                                    onClick={this.logClick}
                                >
                                    {ad.images && ad.images[0] && <>
                                        <img src={ad.images[0].s3LargeLink} class='img-fluid rounded-lg' />
                                    </>}
                                </a>
                            </>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Ad;
