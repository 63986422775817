import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

@observer
class ImageFullscreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
		const { src } = this.props;
        const { appState } = this.props.stores;
        const { darkmode } = appState;

		return (<>
            <div class={`h-100`} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead
                        stores={this.props.stores}
                        title={<Text id='image.fullscreen'>Image</Text>}
                        // right={<Text id='dog.save'>Save</Text>}
                        // onRightClick={this.insertDog}
                    />
                </Localizer>
                <WidgetBody stores={this.props.stores} classNames={`h-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                    <div class='h-100 d-flex justify-content-center align-items-center'>
                        <img src={src} class='img-fluid' />
                    </div>
                </WidgetBody>
            </div>
        </>);
	}
}

export default ImageFullscreen;
