import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import WidgetBody from '../../../components/widget/body';
import WidgetHead from '../../../components/widget/head';
import WidgetPrefix from '../../../components/widget/prefix';
import WidgetSegmentControl from '../../../components/widget/segment-control';

import Speed from '../../../components/data/speed';
import Elevation from '../../../components/data/elevation';
import Weight from '../../../components/data/weight';
import Distance from '../../../components/data/distance';
import Duration from '../../../components/data/duration';
import Temperature from '../../../components/data/temperature';
import Calories from '../../../components/data/calories';
import Intensity from '../../../components/data/intensity';
import Fitness from '../../../components/data/fitness';

import MiniGraph from '../../../components/workout/miniGraph';
import MiniBarGraph from '../../../components/workout/miniBarGraph';

import WorkoutKeyFigures from '../../../components/widget/workout/key-figures.js';

import mu from '../../../lib/musher-util';

const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = 0; //mu.marginBottom();

@observer
class WidgetWorkoutKeyFiguresList extends Component {
    constructor(props) {
        super(props);
        const currentWeek = util.getWeek(new Date());
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        this.state = {
            periode: 'week',
            periodeWeek: currentWeek,
            periodeMonth: currentMonth,
            periodeYear: currentYear,
        };
    }

    loadAll = async (props = this.props) => {
		const { limit = 5, offset } = this.state;
		const { userStore, messageStore } = props.stores;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		await Promise.all([
			// this.loadWorkouts({ year, month, limit, offset }),
			this.loadWorkoutSummary({ yyyy: currentYear, mm: currentMonth }),
			this.loadWorkoutPlans({ year: currentYear }),
			// userStore.getNotifications(),
			// messageStore.getUnReadMessages(),
			// this.loadStravaActivities(),
			// this.loadGarminActivities(),
			// this.loadTeams(props),
		]);
	}

    loadWorkouts = async ({ limit, offset }) => {
		const { workoutStore, userStore } = this.props.stores;
        const { user } = userStore;
        const team = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const append = offset > 0;
		await workoutStore.load({
			query: { team: team.id },
			limit,
			offset,
			addData: ['publicTeams', 'publicUsers'],
		}, append);
	}

    loadWorkoutPlans = async ({ year }) => {
		this.setState({ isLoading: true });

		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		const seasonStart = parseInt(year || (currentMonth < 8 ? currentYear - 1 : currentYear), 10);
		const { workoutPlanStore, workoutStore, userStore } = this.props.stores;
		// const { currentTeam } = workoutStore;

		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		workoutPlanStore.updateItem('workoutPlans', []);
		workoutPlanStore.updateItem('workoutPlan', {});

		await workoutPlanStore.load({
			query: {
				team: currentTeam.id,
				seasonYear: seasonStart,
				seasonMonth: 6,
			},
			addData: ['tips'],
		});

		const { workoutPlans } = workoutPlanStore;
		const workoutPlanCurrent = workoutPlans[0] || {};
		if (workoutPlanCurrent.id) {
			await workoutPlanStore.load(workoutPlanCurrent.id);
			workoutPlanStore.prepareWeekGraphs({ year });
			workoutPlanStore.prepareDaysGraphs({
				year: currentYear,
				month: currentMonth,
			});
		} else {
			workoutPlanStore.updateKeyValue('workoutPlan', {});
			workoutPlanStore.updateKeyValue('workoutPlans', []);
			workoutPlanStore.resetGraphPlanWeekDistanceCurrent();
		}
		this.setState({ isLoading: false });
	}

    loadWorkoutSummary = async ({ yyyy, mm, type }) => {
		const { userStore, workoutStore } = this.props.stores;

		const currentDay = new Date().getDate();
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
		// const { month = currentMonth, year = currentYear } = props;

        const year = yyyy || currentYear;
        const month = mm || currentMonth;

		const prevMonthDate = new Date(currentYear, currentMonth - 2, 1);
		const prevMonth = prevMonthDate.getMonth() + 1;
        const prevYear = prevMonthDate.getFullYear();

		// const { currentTeam } = workoutStore;
		const { user } = userStore;
		const currentTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const workoutType = type === 'all' ? null : type;

        await userStore.getWorkoutSummary({
			team: currentTeam.id,
            daysBack: 365,
			year,
			month,
			type: workoutType,
		});
    }

    componentDidMount() {
		// const { appState } = this.props.stores;
		this.loadAll();
		// this.setLoadMore();
	}

    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            team: user.activeTeam?.team || user.team,
            borderRadius: '',
        });
	}

    handlePeriodeChange = periode => {
        this.setState({ periode });
    }

    handleSegmentChange = value => {
        const { periode } = this.state;
        if (periode === 'week') {
            this.setState({ periodeWeek: value });
        } else if (periode === 'month') {
            this.setState({ periodeMonth: value });
        } else if (periode === 'year') {
            this.setState({ periodeYear: value });
            this.loadWorkoutSummary({ yyyy: value });
        }
    }

    getCurrentDistance = () => {
        const { userStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const {
            graphWorkoutSummaryDayDistanceCurrent, graphWorkoutSummaryDayDistancePrev,

            graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
            graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			graphWorkoutSummaryWeekLoadIndexLastPeriode,
			graphworkoutSummaryDaysCurrentMonth,
			graphworkoutSummaryDaysCurrentMonthPrevMonth,
			graphworkoutSummaryDaysCurrentMonthPrevSeason,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
			workoutDistinctTypes,
			workoutSeasonEquipment,
		} = userStore;
        if (periode === 'week') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDistanceCurrent });
            const data = graphWorkoutSummaryDayDistanceCurrent.filter(e => e.week === periodeWeek).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'month') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDistanceCurrent });
            const data = graphWorkoutSummaryDayDistanceCurrent.filter(e => e.month === periodeMonth).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'year') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDistanceCurrent });
            const data = graphWorkoutSummaryWeekDistanceCurrent.filter(e => e.seasonStart === periodeYear).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        return graphWorkoutSummaryWeekDistanceCurrent;
    }

    getCurrentElevation = () => {
        const { userStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const {
            graphWorkoutSummaryDayElevationCurrent, graphWorkoutSummaryDayElevationPrev,
            graphWorkoutSummaryWeekElevationCurrent,
		} = userStore;
        if (periode === 'week') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayElevationCurrent });
            const data = graphWorkoutSummaryDayElevationCurrent.filter(e => e.week === periodeWeek).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'month') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayElevationCurrent });
            const data = graphWorkoutSummaryDayElevationCurrent.filter(e => e.month === periodeMonth).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'year') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayElevationCurrent });
            const data = graphWorkoutSummaryWeekElevationCurrent.filter(e => e.seasonStart === periodeYear).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        return graphWorkoutSummaryWeekElevationCurrent;
    }

    getCurrentSpeedAvg = () => {
        const { userStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const {
            graphWorkoutSummaryDaySpeedAvgCurrent, graphWorkoutSummaryDaySpeedAvgPrev,
            graphWorkoutSummaryWeekSpeedAvgCurrent,
		} = userStore;
        if (periode === 'week') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDaySpeedAvgCurrent });
            const data = graphWorkoutSummaryDaySpeedAvgCurrent.filter(e => e.week === periodeWeek).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'month') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDaySpeedAvgCurrent });
            const data = graphWorkoutSummaryDaySpeedAvgCurrent.filter(e => e.month === periodeMonth).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'year') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDaySpeedAvgCurrent });
            const data = graphWorkoutSummaryWeekSpeedAvgCurrent.filter(e => e.seasonStart === periodeYear).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        return graphWorkoutSummaryWeekSpeedAvgCurrent;
    }

    getCurrentDuration = () => {
        const { userStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const {
            graphWorkoutSummaryDayDurationCurrent, graphWorkoutSummaryDayDurationPrev,
            graphWorkoutSummaryWeekDurationCurrent,
		} = userStore;
        if (periode === 'week') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDurationCurrent });
            const data = graphWorkoutSummaryDayDurationCurrent.filter(e => e.week === periodeWeek).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        if (periode === 'month') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDurationCurrent });
            const data = graphWorkoutSummaryDayDurationCurrent.filter(e => e.month === periodeMonth).map((e, idx) => ({ ...e, x: idx }));
            // console.log('month', {data});
            return data;
        }
        if (periode === 'year') {
            // console.log({ periode, periodeWeek, graphWorkoutSummaryDayDurationCurrent });
            const data = graphWorkoutSummaryWeekDurationCurrent.filter(e => e.seasonStart === periodeYear).map((e, idx) => ({ ...e, x: idx }));
            // console.log({season, data});
            return data;
        }
        return graphWorkoutSummaryWeekDurationCurrent;
    }

    render() {
        const { appState, workoutPlanStore, userStore, teamStore } = this.props.stores;
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const { drawerLevel } = this.props;
        const { darkmode } = appState;

        const currentDay = new Date().getDate();
		const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const {
			month = currentMonth,
			year = currentYear,
			showSegmentController = true,
			marginTop = MARGIN_TOP,
			marginBottom = MARGIN_BOTTOM,
		} = this.props;

        const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const planMonthDistanceKm = workoutPlanStore.sumWorkoutPlanMonth(parseInt(year, 10), parseInt(month, 10)) || 0;
		const planWeekDistanceKm = workoutPlanStore.sumWorkoutPlanWeek(currentYear, currentWeek, 'distance') || 0;
		const summaryMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'distanceKm', true) || 0;
		const totalCountMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'count', true) || 0;
		const totalDistanceKmMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'distanceKm', true) || 0;
		const totalDurationMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'duration', true) || 0;
		const totalSpeedavgMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'speedAvg', true) || 0;
		const totalElevationMonth = userStore.findWorkoutSummaryMonth(parseInt(year, 10), parseInt(month, 10), 'elevation', true) || 0;

		const workoutSummaryCurrentWeek = userStore.findWorkoutSummaryWeek(currentYear, currentWeek, 'all') || {};
		const {
			year: weekYear,
			week: weekWeek,
			month: weekMonth,
			count: weekCount,
			distanceKm: weekDistanceKm = 0,
			elevation: weekElevation = 0,
			duration: weekDuration = 0,
			speedAvg: weekSpeedAvg = 0,
			loadIndex: weekLoadIndex = 0,
			avgDogs: weekAvgDogs,
		} = workoutSummaryCurrentWeek;

		let percentComplete;
		if (planWeekDistanceKm) {
			percentComplete = Math.floor(weekDistanceKm / planWeekDistanceKm * 100);
		}

        const {
            // graphWorkoutSummaryWeekDistanceCurrent, graphWorkoutSummaryWeekDistancePrev,
            graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev,
			graphWorkoutSummaryWeekDurationCurrent, graphWorkoutSummaryWeekDurationPrev,
			graphWorkoutSummaryMonthDurationCurrent, graphWorkoutSummaryMonthDurationPrev,
			graphWorkoutSummaryWeekLoadIndexCurrent, graphWorkoutSummaryWeekLoadIndexPrev,
			graphWorkoutSummaryWeekLoadIndexLastPeriode,
			graphworkoutSummaryDaysCurrentMonth,
			graphworkoutSummaryDaysCurrentMonthPrevMonth,
			graphworkoutSummaryDaysCurrentMonthPrevSeason,
			workoutSummaryWeekAvgLoadLastPeriode = 1,
			workoutCurrentWeek,
			workoutDistinctTypes,
			workoutSeasonEquipment,
            workoutSummaryMonth,
            workoutSummaryWeek,
            workoutSeasonTotal,
		} = userStore;

        const graphWorkoutDistanceCurrent = this.getCurrentDistance();
        const graphWorkoutElevationCurrent = this.getCurrentElevation();
        const graphWorkoutSpeedAvgCurrent = this.getCurrentSpeedAvg();
        const graphWorkoutDurationCurrent = this.getCurrentDuration();
        // const graphWorkoutDistancePrev = this.getCurrentDistance('previous');

        const { user } = userStore;
        const { language = 'en', settings = {} } = user;
        const currentTeamObject = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const currentTeam = currentTeamObject.id;
        // const currentSeason = (currentTeam ? teamStore.findWorkoutSummary(currentTeam, seasonStart) : teamStore.findWorkoutSummaryTotal(seasonStart)) || {};
        let currentSeason = workoutSeasonTotal;
        if (periode === 'month') {
            currentSeason = workoutSummaryMonth.find(e => e.year === periodeYear && e.month === periodeMonth) || {};
        } else if (periode === 'week') {
            currentSeason = workoutSummaryWeek.find(e => e.year === periodeYear && e.week === periodeWeek) || {};
        }
        // console.log({ currentSeason, workoutSeasonTotal });

        const startWeek = util.validRange(periodeWeek - 1, 1, 51);
        const endWeek = util.validRange(startWeek + 2, 1, 53);
        const weeks = util.range(startWeek, endWeek);

        const startMonth = util.validRange(periodeMonth - 1, 1, 10);
        const endMonth = util.validRange(startMonth + 2, 1, 12);
        const months = util.range(startMonth, endMonth);

        const startYear = util.validRange(periodeYear - 1, 2019, currentYear - 2);
        const endYear = util.validRange(startYear + 2, 2019, currentYear);
        const years = util.range(startYear, endYear);

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <Localizer>
                    <WidgetHead stores={this.props.stores} title={<Text id='workout.key-figures'>Key figures</Text>} />
                </Localizer>
                <WidgetBody stores={this.props.stores}>
                    <WidgetSegmentControl stores={this.props.stores} callbackPrimary={this.handlePeriodeChange} callbackSecondary={this.handleSegmentChange} />

                    <WidgetPrefix stores={this.props.stores}>
                        <span>{periodeYear}</span>
                        {periode === 'week' && <span class='ml-2'>w{periodeWeek}</span>}
                        {periode === 'month' && <span class='ml-2'>{mu.getMonth(periodeMonth, language)}</span>}
                    </WidgetPrefix>

                    <WorkoutKeyFigures
                        stores={this.props.stores}
                        year={periodeYear}
                        month={periodeMonth}
                        currentSeason={currentSeason}
                        headerRight={''}
                        // header={`Key figures ${periodeYear} ${periode === 'week' ? `w${periodeWeek}` : ''} ${periode === 'month' ? mu.getMonth(periodeMonth, language) : ''}`}
                        header={`Key figures`}
                    />

                    <Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} header={`Distance`} onClick={this.onClick}>
                        <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                            <MiniBarGraph
                                stores={this.props.stores}
                                // dataLeft={[graphWorkoutDistanceCurrent, graphWorkoutDistancePrev]}
                                dataLeft={[graphWorkoutDistanceCurrent]}
                                yTicksLeftVal={val => val}
                                yTicksLeftPostfix={settings.imperial ? 'mi' : 'km'}
                                showDebug={false}
                                periode={periode}
                            />
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} header={`Duration`} onClick={this.onClick}>
                        <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                            <MiniBarGraph
                                stores={this.props.stores}
                                // dataLeft={[graphWorkoutDistanceCurrent, graphWorkoutDistancePrev]}
                                dataLeft={[graphWorkoutDurationCurrent]}
                                yTicksLeftVal={val => util.secToHms(val, true)}
                                showDebug={false}
                                periode={periode}
                                // xVal={o => o.x}
                            />
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} header={`Avg Speed`} onClick={this.onClick}>
                        <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                            <MiniBarGraph
                                stores={this.props.stores}
                                // dataLeft={[graphWorkoutDistanceCurrent, graphWorkoutDistancePrev]}
                                dataLeft={[graphWorkoutSpeedAvgCurrent]}
                                yTicksLeftVal={val => val}
                                yTicksLeftPostfix={settings.imperial ? 'mph' : 'km/h'}
                                showDebug={false}
                                periode={periode}
                                // xVal={o => o.x}
                            />
                        </div>
                    </Widget>

                    <Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} header={`Elevation`} onClick={this.onClick}>
                        <div class={`px-3 pb-3 pt-1 d-flex justify-content-between`}>
                            <MiniBarGraph
                                stores={this.props.stores}
                                // dataLeft={[graphWorkoutDistanceCurrent, graphWorkoutDistancePrev]}
                                dataLeft={[graphWorkoutElevationCurrent]}
                                yTicksLeftVal={val => val}
                                yTicksLeftPostfix={settings.imperial ? 'ft' : 'm'}
                                showDebug={false}
                                periode={periode}
                                // xVal={o => o.x}
                            />
                        </div>
                    </Widget>

                    {/* Widget content...<br />
                    drawerLevel: {drawerLevel}<br />
                    MARGIN_TOP: {MARGIN_TOP}<br />
                    MARGIN_BOTTOM: {MARGIN_BOTTOM}<br /> */}
                </WidgetBody>
            </div>
        </>);
    }
}

export default WidgetWorkoutKeyFiguresList;
