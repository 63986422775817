import { observable, configure, action, computed } from 'mobx';
import StoreModel from 'preact-storemodel';

configure({ enforceActions: 'always' });

class WaypointStore extends StoreModel {
    constructor() {
        super('waypoint', {
            namePlural: 'waypoints',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/waypoints/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/waypoints/',
                    params: {},
                },
                save: {
                    url: '/api/waypoints/',
                    params: {},
                },
            },
        });
    }

    @observable waypoint = {};

    @observable waypoints = [];
}

const store = new WaypointStore();
export default store;
