import { h, Component } from 'preact';
import { Text, Localizer, withText } from 'preact-i18n';

class ReadyToRefresh extends Component {
    render() {
        return (
            <div class='fixed-top text-center' style='padding-top: 90px;'>
                <i class='fas fa-arrow-alt-circle-down mr-2' />
                <Text id='refresh.pull-to-update'>Dra for å oppdatere siden...</Text>
            </div>
        );
    }
}

export default ReadyToRefresh;
