import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class AddContent extends Component {
	constructor(props) {
        super(props);
        this.state = {
            menuLinks: [
                {
                    name: <Text id='settings.create-post'>Post</Text>,
                    func: this.newStory,
                    icon: 'fa-solid fa-table-cells',
                },
                {
                    name: <Text id='settings.create-dog-log'>Dog log entry</Text>,
                    // href: '/create/dogLogEntry',
                    func: this.newDogLogEntry,
                    icon: 'fa-solid fa-paw',
                },
                {
                    name: <Text id='settings.create-dog'>Dog</Text>,
                    // href: '/create/dogLogEntry',
                    func: this.newDog,
                    icon: 'fa-solid fa-dog',
                },
                {
                    name: <Text id='settings.create-workout'>Workout</Text>,
                    func: this.newWorkout,
                    icon: 'fa-solid fa-person-running',
                },
                {
                    hasToHaveCordova: true,
                    name: <Text id='settings.create-gps-tracking'>GPS Tracking</Text>,
                    href: '/workouts/tracking/',
                    icon: 'fa-solid fa-location-arrow',
                },
            ],
        };
    }

    newDog = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('newDog', {
            height: drawerHeightSmall,
            callback: this.toggleDrawer,
            team: user.activeTeam?.team || user.team,
        });
	}

    newDogLogEntry = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightMedium } = appState;
        const team = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

        appState.openDrawer2('newDogLog', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            onCloseClick: this.toggleDrawer,
            dog: user.activeDog,
            team,
            showLog: true,
        });
	}

    newStory = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('newStory', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            onCloseClick: this.toggleDrawer,
            forceShowAddImage: true,
            setFocus: true,
        });
    }

    newWorkout = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightMedium } = appState;
        appState.openDrawer2('newWorkout', {
            height: drawerHeightMedium,
            callback: this.toggleDrawer,
            onCloseClick: this.toggleDrawer,
            team: user.activeTeam?.team || user.team,
        });
	}

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    toggleDrawer2 = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer2(false);
    }

    render() {
        const { menuLinks } = this.state;
        const { appState } = this.props.stores;
        const { darkmode, isCordova } = appState;

        return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3 text-center'>
                        <h3 class='m-0'><Text id='settings.create'>Create</Text></h3>
                    </div>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3' style='font-size: 1.5em;'>
                        {menuLinks && menuLinks.map((link, idx) => {
                            if (link.hasToHaveCordova && !isCordova) {
                                return '';
                            }
                            return (<>
                                <div class={`row ${idx > 0 ? 'border-top' : ''} teamRow`}>
                                    <div class={`col-12 py-3 d-flex align-items-center position-relative`}>
                                        <div class='text-center' style='width: 55px;'>
                                            <i class={`${link.icon}`} />
                                        </div>
                                        <span class={`flex-grow-1`} style='line-height: 1.0em;'>
                                            {link.func ? <>
                                                <button onClick={link.func} class={`${darkmode ? 'text-white' : 'text-dark'} btn btn-link btn-block text-left p-0`} style='font-size: 1.0em;'>
                                                    {link.name}
                                                </button>
                                            </> : <>
                                                <a href={link.href} class={`${darkmode ? 'text-white' : 'text-dark'} stretched-link`} onClick={this.toggleDrawer}>
                                                    {link.name}
                                                </a>
                                            </>}
                                        </span>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default AddContent;
