import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';


import WidgetRouter from '../../components/widget/router';
import WidgetBody from '../../components/widget/body';
import WidgetHead from '../../components/widget/head';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class WorkoutLoadIndexDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { menuLinks } = this.state;
        const { appState, userStore, workoutStore } = this.props.stores;
        const { types, equipments, language = 'en' } = userStore;
        const { darkmode, isCordova } = appState;
        const { filter } = workoutStore;

        return (<>
            <div class={``} style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
				<Localizer>
					<WidgetHead
						stores={this.props.stores}
						title={<Text id='workout.workout-elevation-detail'>Workout Load Index</Text>}
						// right={<i class='fa-solid fa-pen-to-square' />}
						// onRightClick={this.onEditClick}
						// right2={<i class='fa-solid fa-upload' />}
						// onRight2Click={this.exportWorkout}
					/>
				</Localizer>
				{/* <WidgetBody stores={this.props.stores} classNames={`container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}> */}
				<WidgetBody stores={this.props.stores} classNames={`bg-light`} paddingBottom={0}>
                    content
                </WidgetBody>
            </div>
        </>);
    }
}

export default WorkoutLoadIndexDetail;
