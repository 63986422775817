import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import PublicImages from '../../components/team/publicImages';
import DogSimplePublic from '../../components/dog/simplePublic';

import mu from '../../lib/musher-util';
const MARGIN_TOP = 0; //mu.marginTop(false);
const MARGIN_BOTTOM = mu.marginBottom();
const SUB_MENU_MARGIN_TOP = mu.subMenuMarginTop();
const MARGIN_TOP_BACK = mu.marginTopBack(true);


@observer
class UserMenu extends Component {
	constructor(props) {
        super(props);
        this.state = {
            menuLinks: [
                {
                    name: <Text id='users.settings'>Settings</Text>,
                    href: '/settings/',
                    icon: 'fa-solid fa-gear',
                },
                {
                    name: <Text id='header.inbox'>Messages</Text>,
                    href: '/inbox',
                    icon: 'fa-solid fa-paper-plane',
                    counter: 'inbox',
                },
                {
                    name: <Text id='header.notifications'>Notifications</Text>,
                    href: '/users/notifications',
                    icon: 'fa-solid fa-bell',
                    counter: 'notifications',
                },
                {
                    name: <Text id='header.dogs'>Dogs</Text>,
                    href: '/dogs/',
                    icon: 'fa-solid fa-dog',
                },
                {
                    name: <Text id='header.teams'>Teams</Text>,
                    href: '/teams/',
                    icon: 'fa-solid fa-people-group',
                },
                {
                    name: <Text id='header.tracks'>Tracks</Text>,
                    href: '/tracks/',
                    icon: 'fa-solid fa-map-location',
                },
                {
                    name: <Text id='header.workouts'>Workouts</Text>,
                    href: '/workouts/',
                    icon: 'fa-solid fa-person-running',
                },
                {
                    name: <Text id='header.connect-team'>Follow other teams</Text>,
                    href: '/teams/connect',
                    icon: 'fa-solid fa-circle-nodes',
                },
                {
                    name: <Text id='header.races'>Races</Text>,
                    href: '/app-races/list',
                    icon: 'fa-solid fa-flag-checkered',
                },
                {
                    name: <Text id='header.support'>Support</Text>,
                    href: 'https://themusher.no/v2/support/faq-frequently-asked-questions/13',
                    native: true,
                    icon: 'fa-solid fa-circle-question',
                },
                {
                    name: <Text id='header.vaccines'>Vaccines</Text>,
                    href: '/vaccines/',
                    icon: 'fa-solid fa-syringe',
                },
                {
                    name: <Text id='header.veterinary'>Veterinary</Text>,
                    href: '/veterinary/',
                    icon: 'fa-solid fa-user-doctor',
                    isVeterinary: true,
                },
                {
                    name: <Text id='header.news'>News</Text>,
                    href: '/news/',
                    icon: 'fa-solid fa-rss',
                    visible: true,
                    isAdmin: true,
                },
                {
                    name: <Text id='header.admin'>Admin</Text>,
                    href: '/admin/',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    isAdmin: true,
                },
            ],
        };
    }

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    render() {
        const { counters = {} } = this.props;
        const { submenu, menuLinks } = this.state;
        const { appState, userStore } = this.props.stores;
        const { darkmode } = appState;

        const { isAdmin, isVeterinary, user } = userStore;

        return (
			<div class='container-fluid' style={`margin-bottom: ${MARGIN_BOTTOM}; margin-top: ${MARGIN_TOP};`}>
                <div class='row mt-0'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3' style='font-size: 1.5em;'>
                        {menuLinks && menuLinks.map((link, idx) => {
                            if (link.isAdmin && !isAdmin) {
                                return '';
                            }
                            if (link.isVeterinary && !isVeterinary) {
                                return '';
                            }
                            return (<>
                                <div class={`row ${idx > 0 ? 'border-top' : ''} teamRow`}>
                                    <div class={`col-12 py-3 d-flex align-items-center position-relative`}>
                                        <div class='text-center' style='width: 55px;'>
                                            <i class={`${link.icon}`} />
                                        </div>
                                        <span class={`flex-grow-1`} style='line-height: 1.0em;'>
                                            <a href={link.href} class={`${darkmode ? 'text-white' : 'text-dark'} stretched-link`} onClick={this.toggleDrawer} native={link.native}>
                                                {link.name}
                                            </a>
                                        </span>

                                        {counters[link.counter] > 0 && <>
                                            <span
                                                class='badge badge-pill badge-danger '
                                            >{counters[link.counter] > 9 ? '9+' : counters[link.counter]}</span>
                                        </>}
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserMenu;
