import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { toJS } from 'mobx';
import Markdown from 'preact-markdown';

import TeamConnectBoxes from '../../components/team/connect-boxes';
import TeamConnectConfirm from '../../components/team/connect-confirm';

import Ad from '../../components/ad/';

import FastList from '../../components/team/fastList';

import Map from '../../components/map/mapbox';

import Overlay from '../../components/overlay/';
import TeamsPublicDetail from '../../routes/teams/publicDetail';
import DogPublicDetail from '../../routes/dogs/publicDetail';

import ImageScroller from '../../components/imageScroller';
import DogScroller from '../../components/dog/scroller';
import WorkoutComments from '../../components/workout/comments';
import WorkoutAddComment from '../../components/workout/commentAdd';
import WorkoutInfoGraph from '../../components/workout/infoGraph';

import PublicTeam from '../../components/team/public';

import LikesAndComments from '../../components/feedback/likesAndComments';
import Views from '../../components/feedback/views';

import Speed from '../../components/data/speed';
import Elevation from '../../components/data/elevation';
import Weight from '../../components/data/weight';
import Distance from '../../components/data/distance';
import Temperature from '../../components/data/temperature';

import ScrollIntoView from '../../components/util/scrollIntoView';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

const initialState = {
    sessionid: new Date().getTime(),
};

@observer
class WorkoutMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.mapContainer = null;
    }

    async loadWorkout({ props = this.props }) {
		const { workoutStore } = props.stores;
        const { workout: workoutid } = props;
        if (workoutid) {
            await workoutStore.load(workoutid, false, {});
        }
	}

    toggleDate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { showDate } = this.state;
        this.setState({ showDate: !showDate });
    }

    componentDidMount() {
        this.loadWorkout(this.props);
	}

	componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps.viewmenu, this.props.viewmenu);
        if (nextProps.workoutid !== this.props.workoutid) {
            this.loadWorkout(nextProps);
        }
    }

    render() {
        const {
            mapHeight = '600px',
            showDate,
        } = this.state;

        const {
            displayAllComments = 'true',
            marginTop,
            marginBottom,
        } = this.props;
        const { workoutStore, teamStore, storyStore, userStore, appState } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { language = 'en' } = user;
        const { darkmode, hasShareApi } = appState;
        const { mapColorMode, mapRangeMin, mapRangeMax, isCordova, viewmode, drawerHeightMedium } = appState;

        const { workout, currentWeatherMarkers } = workoutStore;

        const { likes, comments, reports = [] } = workout;

		const team = userStore.findTeam(workout.team, true) || {};
		const teamAmbition = userStore.findTeamAmbition({ ambition: team?.ambitions, language });
        const teamType = userStore.findTeamType({ type: team.type, language });
        const workoutType = userStore.findWorkoutType({ type: workout.type, language });
		const workoutTypeIcon = userStore.findWorkoutType({ type: workout.type, field: 'icon' });
		const track = userStore.findTrack(workout.track);
		const workoutEquipment = userStore.findEquipment({ equipment: workout.equipment, language });
		const workoutEquipmentIcon = userStore.findEquipment({ equipment: workout.equipment, field: 'icon' });

		const dogs = userStore.findDogs(workout.dogs);
		const mushers = userStore.findMushers(workout.mushers);
		const intensity = userStore.findIntensity({ code: workout.intensity, language }) || {};
		const workoutNavigation = workoutStore.getWorkoutNavigation(workout.id) || {};
		const hasLikedWorkout = workout.likes && workout.likes?.findIndex(e => e.user === user.id) > -1;
		const { gpxInfo = {}, gpx = { gpxInfo: {} } } = workout;
		const { weightedTotalKcal = 0, loadIndex = 0 } = gpxInfo;
		const loadMultiplier = loadIndex / 100;
		const kcalMultiplier = gpxInfo.totalKcalRelated / 100;
        const teamNames = workout.teams ? workout.teams.map(e => userStore.findTeam(e)) : [];
        let batteryUsage;
        if (workout && workout.gpxInfo && workout.gpxInfo.batteryLevelStart) {
            batteryUsage = (workout.gpxInfo.batteryLevelStart - workout.gpxInfo.battegryLevelEnd) * 100;
        }

		const images = [];
        if (workout.mapImage) {
            images.push({
                ...workout.mapImage,
                type: 'map',
            });
        }
        if (workout.images) {
            const imgs = toJS(workout.images);
            images.push(...imgs);
        }

		let showReimport = false;

		const musherImages = [];
        if (workout.mushers && workout.mushers.length > 1) {
            workout.mushers.slice(1, 10).map((musher) => {
				const u = userStore.findMusher(musher);
                if (u && u.images && u.images[0]) {
                    musherImages.push(u.images[0]);
                }
            });
        }

        const image = mu.getImage({ user, team, object: workout, priority: 'user' });

        let shortname;
        let name;
        let username;
        let userLink;
        if (user) {
            shortname = mu.displayNameShort(user);
            name = mu.displayName(user);
            username = mu.displayName(user);
            if (user.settings && user.uuidv4 !== user.uuidv4) {
                userLink = user.settings.hideProfile ? null : `/inbox/${user.uuidv4}`;
            }
        }

        const { currentLocationGeocode = {}, yrWeather = [] } = workout;
        const views = mu.getViews(workout.statistics);
        const adminData = mu.getAdminData(workout.statistics);

        return (<>
			<div class={`container-fluid p-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} h-100`} style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}>
                <div class='row mt-0 h-100 position-relative'>
                    <div
                        class='col-12 my-0 h-100'
                        ref={c => this.mapContainer = c}
                    >
                            {util.checkNested(workout, 'gpxInfo', 'centerCoords') ? <>
                                <div
                                    class='clearfix h-100'
                                    style={`height: ${drawerHeightMedium} !important;`}
                                >
                                    <Map
                                        stores={this.props.stores}
                                        mapId={`${workout.id}-${mapHeight}`}
                                        // geojson={workout.gpx.geoJSON}
                                        geojsonExternal={workout.uuidv4}
                                        geojsonExternalTrack={workout.track}
                                        skipGeojson={false}
                                        center={workout.gpxInfo?.centerCoords}
                                        startMarker={workout.startMarker}
                                        endMarker={workout.endMarker}
                                        markers={currentWeatherMarkers}
                                        gpxInfo={workout.gpxInfo}
                                        height={mapHeight}
                                        mapColorMode={mapColorMode}
                                        mapRangeMin={mapRangeMin}
                                        mapRangeMax={mapRangeMax}
                                        // waypoints2={waypoints2}
                                        // waypoints3={waypoints3}
                                        key={`workout-map-${workout.id}`}
                                        fullscreenMap={true}
                                        showRange={true}
                                        show3dMap={false}
                                    />
                                </div>
                            </> : <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fa-solid fa-map-location-dot' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='workout.no-gpx-title'>No gps data for this workout.</Text>
                                    </h3>
                                </div>
                            </>}
                    </div>
                    <div
                        class={`position-absolute ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} border rounded rounded-lg p-2`}
                        style='bottom: 35px; left: 50%; z-index: 99999; width: 95vw; transform: translate(-50%,0);'
                    >
                        <div class='d-flex position-relative'>
                            <div class='text-center'>
                                <div
                                    class={`position-relative text-center border rounded-circle imageRounded imageRoundedMedium`}
                                    style={image ? `background-image: url("${image}"); background-size: cover;` : ''}>
                                    {!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
                                </div>
                            </div>
                            <div class='flex-grow-1 pl-3 text-overflow' style='line-height: 1.2em;'>
                                {/* <span class='badge badge-pill badge-secondary float-right'>{teamType}</span> */}
                                <h5 class='mb-1 position-relative' style='line-height: 1.0em;'>
                                    {team.name}
                                </h5>

                                <div class='d-flex flex-row flex-nowrap' style='overflow: auto;'>
                                    {workout.date && <small class='text-muted' onClick={this.toggleDate}>
                                        {showDate ? util.formatDate(workout.date, { locale: 'no-NB', hour12: false, hour: '2-digit', minute: '2-digit' })
                                            : util.formatDistance(workout.date, new Date(), { locale: 'no-NB' })}
                                    </small>}
                                    {username && <>
                                        <small class='ml-2 text-muted'>
                                            <i class='fas fa-user' /> {username}
                                        </small>
                                    </>}
                                    <small class='text-muted'>
                                        {workout.equipment > 0 && <><i class={`${workoutEquipmentIcon} ml-2`} /> {workoutEquipment}</>}
                                        {/* {workout.distanceKm > 0 && <><i class='fas fa-road ml-2' /> {displayDistance(workout.distanceKm)}km</>}
                                        {workout.elevation > 0 && <><i class='fas fa-mountain ml-2' /> {util.format(workout.elevation, 0)}m</>}
                                        {workout.speedAvg > 0 && <><i class='fas fa-tachometer-alt ml-2' /> {util.format(workout.speedAvg, 1)}km/t</>} */}
                                        {workout.dogs > 0 && <><i class='fas fa-dog ml-2' /> {workout.dogs.length}</>}
                                        {workout.track > 0 && <><i class={`fas fa-map-marked text-${workout.track > 0 ? 'success' : 'danger'} ml-2`} /></>}
                                        {/* {workout.dogsLeft > 0 && <><i class={`fas fa-crosshairs text-${workout.dogsLeft && workout.dogsRight ? 'success' : 'danger'} ml-2`} /></>} */}
                                        {workout.sledWeight > 0 && <>
                                            <i class='fas fa-sleigh ml-2' /> <Weight stores={this.props.stores} value={workout.sledWeight} />
                                        </>}
                                        {workout.temperature !== 0 && <>
                                            <i class='fas fa-temperature-low ml-2' /> <Temperature stores={this.props.stores} value={workout.temperature} />
                                        </>}
                                        {workout.dogs && workout.dogs.length > 0 && <>
                                            <i class='fas fa-dog ml-2' /> {util.format(workout.dogs.length, 0)}
                                        </>}
                                        {/* {workout.kcalBurnedTotal && <><i class='fas fa-balance-scale' /> {util.format(workout.kcalBurnedTotal)} kcal</>}
                                        {workout.kcalMeatGramsTotal && <><i class='fas fa-drumstick-bite ml-2' /> {util.format(workout.kcalMeatGramsTotal / 1000, 1)} kg</>}
                                        {workout.kcalSalmonGramsTotal && <><i class='fas fa-fish ml-2' /> {util.format(workout.kcalSalmonGramsTotal / 1000, 1)} kg</>}
                                        {workout.kcalFatGramsTotal && <><i class='fas fa-piggy-bank ml-2' /> {util.format(workout.kcalFatGramsTotal / 1000, 1)} kg</>} */}
                                        {currentLocationGeocode && currentLocationGeocode.formattedCounty && <>
                                            <i class='fas fa-map-marker-alt ml-2' /> {currentLocationGeocode.formattedCounty}
                                        </>}
                                        {workout.moonIllumination && workout.moonIllumination.phaseIcon && <span class='ml-2'>{workout.moonIllumination.phaseIcon}</span>}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <strong><Markdown markdown={mu.replaceImages(workout.name, workout.images, language)} markedOpts={mu.getMarkdownOptions()} /></strong>


                    </div>
                </div>
            </div>
        </>);
    }
}

export default WorkoutMap;
