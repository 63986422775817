import { h, Component, createRef } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

import PubSub, { topics } from '../../lib/pubsub';

import Story from './publicStory';

import TeamConnectBoxes from '../team/connect-boxes';
import TeamConnectConfirm from '../team/connect-confirm';

import Ad from '../ad/';

import FastList from '../team/fastList';

import Overlay from '../overlay/';
import TeamsPublicDetail from '../../routes/teams/publicDetail';
import DogPublicDetail from '../../routes/dogs/publicDetail';

import TeamPublicFeedLine from '../team/publicFeedLine';

import mu from '../../lib/musher-util';

const MARGIN_TOP = mu.marginTop(false);

const initialState = {
    loading: false,
    limit: 10,
    offset: 0,
    idxConfirm: 0,
    idxSuggestion: 4,
    idxAd: 5,
    sessionid: new Date().getTime(),
};

const dataFields = ['id', 'type', 'user', 'team', 'title'];

// const fieldSorter = (fields) => (a, b) => fields.map(o => {
//     let dir = 1;
//     if (o[0] === '-') { dir = -1; o=o.substring(1); }
//     return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
// }).reduce((p, n) => p ? p : n, 0);

@observer
class StoryPublicFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
		this.inputRef = {};
		this.ref = {};
    }

    loadStoriesPublicFeed = async ({ props = this.props, limit, offset }) => {
        const {
            hashtag, skiptags, teamid, path, story, skipWorkouts,
            showTopRanked = true, showTeamConfirm = true, showTeamSuggestions = true,
            showAds = false,
        } = props;
        const { hashtags } = this.props;
		const { storyStore } = props.stores;
        let sort;
        // if (path.match(/\/home-wall/)) {
        //     sort = 'statistics.ranking';
        // }
		await storyStore.loadPublicFeed({ hashtag, hashtags, skiptags, teamid, skipWorkouts, story, limit, offset, sort, clearStore: false });
        if (!offset) {
            // this.insertWorkouts();
            if (showTopRanked) {
                this.insertTopRanked();
            }
            if (showAds) {
                this.insertAds();
            }
            if (showTeamConfirm) {
                this.insertTeamConfirm();
            }
            if (showTeamSuggestions) {
                this.insertTeamSuggestions();
            }
        }
	}

    reloadStories = async () => {
        const { appState } = this.props.stores;
        let { limit, offset } = this.state;
        offset = 0;
        this.setState({ offset });
        await this.loadStoriesPublicFeed({ props: this.props, limit, offset })
        appState.appContainerScrollTop();
    }

    async checkForNewStories({ props = this.props, limit, offset }) {
        const { hashtag, hashtags, skiptags, skipWorkouts, teamid, path } = props;
		const { storyStore } = props.stores;
		await storyStore.checkForNewStories({ hashtag, hashtags, skiptags, skipWorkouts, teamid });
    }

    setCheckForNewStories = (props = this.props) => {
        this.checkForNewStoriesTimer = setInterval(() => this.checkForNewStories({ props }), 60 * 1000);
    }

    clearCheckForNewStories = () => {
        clearInterval(this.checkForNewStoriesTimer);
    }

    // insertWorkouts = () => {
    //     const { storyStore } = this.props.stores;
    //     const { publicWorkouts, publicFeed } = storyStore;
    //     if (publicWorkouts.length) {
    //         const finalArray = publicFeed.concat(publicWorkouts).sort(fieldSorter(['-date']));
    //         storyStore.localUpdateField('publicFeed', finalArray);
    //     }
    // }

    insertTopRanked = () => {
        const { storyStore } = this.props.stores;
        const { publicFeed, topRankedStories } = storyStore;
        const topRankedRandomized = util.shuffleArray(topRankedStories);
        const feed = [...publicFeed];

        const totalInserts = 3;
        const pinToTopDiff = 86400;
        let startPosition = 0;
        const now = new Date();
        feed.forEach((e, idx) => {
            const date = util.parseInputDate(e.date);
            const deltaSec = Math.floor((now.getTime() - date.getTime()) / 1000);
            if (deltaSec < pinToTopDiff) {
                startPosition = idx + 1;
            }
        });
        const endPosition = feed.length;

        if (endPosition > startPosition + totalInserts) {
            for (let i = 0, l = totalInserts; i < l; i += 1) {
                const randomPlace = mu.randomInt(startPosition, endPosition);
                if (util.isObject(topRankedRandomized[i])) {
                    feed.splice(randomPlace, 0, topRankedRandomized[i]);
                }
            }
            storyStore.localUpdateField('publicFeed', feed);
        }
    }

    insertAds = () => {
        const { idxAd } = this.state;
        const { storyStore } = this.props.stores;
        const { publicFeed } = storyStore;
        const feed = [...publicFeed];
        feed.splice(idxAd, 0, { type: 'ad', wrapperClassNames: 'row', contentClassNames: 'col-12' });
        storyStore.localUpdateField('publicFeed', feed);
    }

    insertTeamConfirm = () => {
        const { idxConfirm } = this.state;
        const { storyStore, teamStore } = this.props.stores;
        const { hasFollowRequests } = teamStore;
        if (hasFollowRequests) {
            const { publicFeed } = storyStore;
            const feed = [...publicFeed];
            feed.splice(idxConfirm, 0, { type: 'teamConfirm', wrapperClassNames: 'row', contentClassNames: 'col-12' });
            storyStore.localUpdateField('publicFeed', feed);
        }
    }

    insertTeamSuggestions = () => {
        const { idxSuggestion } = this.state;
        const { storyStore } = this.props.stores;
        const { publicFeed } = storyStore;
        const feed = [...publicFeed];
        feed.splice(idxSuggestion, 0, { type: 'teamSuggestions', wrapperClassNames: 'row', contentClassNames: 'col-12' });
        storyStore.localUpdateField('publicFeed', feed);
    }

    setLoadMore = () => {
        const { storyStore } = this.props.stores;
        storyStore.setLoadMore(() => {
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset });
            this.loadStoriesPublicFeed({ limit, offset });
        });
    }

    unsetLoadMore = () => {
        const { storyStore } = this.props.stores;
        storyStore.setLoadMore(() => {});
    }

    // Overlay functions
    onTeamClick = (e, teamId) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicTeam', { teamId, height: drawerHeightMedium });
    }

    onUserClick = (e, fromuser) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('inboxDetail', { fromuser, height: drawerHeightMedium });
    }

    onDogClick = (e, dogId, dogs) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, teamStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('publicDog', { dogId, height: drawerHeightMedium });
    }
    // /Overlay functions

    // Scroll views timer
    setupIntersection = () => {
        this.setState({
            handleIntersection: mu.intersectionObserverCallback,
        });
        const { storyStore } = this.props.stores;
        this.scrollViewTimer = setInterval(() => storyStore.postScrollview(mu.getIntersectionObserverHistory()), 30 * 1000);
    }

    componentWillMount() {
        this.setupIntersection();
    }

	componentDidMount() {
        const { limit } = this.state;
        this.loadStoriesPublicFeed({ limit, offset: 0 });
        this.setLoadMore();
        this.setCheckForNewStories()
	}

    componentWillUnmount() {
        this.unsetLoadMore();
        const { storyStore } = this.props.stores;
        storyStore.postScrollview(mu.getIntersectionObserverHistory());
        clearInterval(this.scrollViewTimer);
        // storyStore.cleanupMemory();
        this.clearCheckForNewStories();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hashtag !== this.props.hashtag || nextProps.path !== this.props.path) {
            const { limit } = this.state;
            this.loadStoriesPublicFeed({ props: nextProps, limit, offset: 0 });
        }
    }

    renderContent = ({ obj, idx }) => {
        const { appState } = this.props.stores;
        const { path } = appState;
        const { hashtag } = this.props;

        if (obj.type === 'ad') {
            return(<>
                <Ad stores={this.props.stores} path={path} />
            </>);
        }
        if (obj.type === 'teamConfirm') {
            return(<>
                <TeamConnectConfirm stores={this.props.stores} />
            </>);
        }
        if (obj.type === 'teamSuggestions') {
            return(<>
                <TeamConnectBoxes stores={this.props.stores} />
            </>);
        }
        if (obj.type === 'workout') {
            return (<>
                <TeamPublicFeedLine
                    stores={this.props.stores}
                    feed={obj}
                    idx={idx}
                    onTeamClick={this.onTeamClick}
                    onUserClick={this.onUserClick}
                    onDogClick={this.onDogClick}
                    holdingStore={'story'}
                />
            </>);
        }
        return (<>
            <Story
                stores={this.props.stores}
                story={obj}
                hashtag={hashtag}
                key={`story-detail-${obj.id}`}
                onTeamClick={this.onTeamClick}
                onDogClick={this.onDogClick}
                onUserClick={this.onUserClick}
            />
        </>);
    }

    render() {
        const { loading, handleIntersection, sessionid } = this.state;
        const { storyStore, appState } = this.props.stores;
        const { hashtags } = this.props;
        const { publicFeed, publicTags, hasNewStories, publicDogs } = storyStore;
        const { darkmode } = appState;

        return (<>
            {/* <div class='row bg-light pt-1'>
                <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                    <div class='bg-light d-flex flex-row flex-nowrap' style='overflow: auto;'>
                        {util.isArray(publicTags) && publicTags.length > 0 && publicTags.slice(0, 20).map((tag) => {
                            const tagLinkReady = tag.tag.replace(/^#/, '');
                            return (<>
                                <span class='badge badge-pill badge-secondary mr-2 mb-1'>
                                    <a href={`/stories/tag/${tagLinkReady}`} class='text-white'>
                                        {tag.tag}
                                    </a>
                                </span>
                            </>);
                        })}
                    </div>
                </div>
            </div> */}

            <div class='w-100'>
                {hasNewStories && <>
                    <div
                        class={`col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 d-flex justify-content-center fixed-top`}
                        style={`margin-top: ${MARGIN_TOP};`}
                    >
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill'
                            onClick={this.reloadStories}
                        >
                            New stories
                        </button>
                    </div>
                </>}
                <FastList
                    data={publicFeed}
                    wrapperClassNames={'row pb-1'}
                    contentClassNames={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 my-1 position-relative rounded rounded-lg py-3 bg-${darkmode ? 'black' : 'white'} shadow-sm`}
                    renderContent={this.renderContent}
                    dataFields={dataFields}
                    handleIntersection={handleIntersection}
                    sessionid={sessionid}
                />

                {loading && <div class='d-flex justify-content-center'>
                    <div class='spinner-border text-primary' role='status'>
                        <span class='sr-only'>Loading...</span>
                    </div>
                </div>}
            </div>
        </>);
    }
}

export default StoryPublicFeed;
