import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import ContentLoader from 'react-content-loader';
import util from 'preact-util';

const initialState = {};

@observer
class Field extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            animationId: util.makeId(6),
        };
    }

    // https://danilowoz.com/create-content-loader/#gallery
    // https://skeletonreact.com/#gallery
    render(props = this.props) {
        const { animationId } = this.state;
        const {
            value,
            className,
            style = 'width: 100%;',
            width = 800,
            height = 15,
            darkmode,
            children,
            type,
            forceShowLoader,
            classNames = '',
        } = props;
        const {
            viewBox = `0 0 ${width} ${height}`,
        } = props;

        let startColor = '#f5f6f7';
        let endColor = '#e0e0e0';

        if (darkmode) {
            startColor = '#606060';
            endColor = '#202020';
        }

        if (value === '{placeholder}' || forceShowLoader) {
        // if (value) {
            if (type === 'workout') {
                return (
                    <div style='overflow: hidden;' class={`${classNames}`}>
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={height}
                            viewBox={viewBox}
                            backgroundColor={startColor}
                            foregroundColor={endColor}
                        >
                            <rect x='70' y='8' rx='3' ry='3' width='88' height='6' />
                            <rect x='70' y='26' rx='3' ry='3' width='52' height='6' />
                            <rect x='0' y='70' rx='3' ry='3' width='410' height='6' />
                            <rect x='0' y='86' rx='3' ry='3' width='380' height='6' />
                            <rect x='0' y='102' rx='3' ry='3' width='178' height='6' />
                            <circle cx='30' cy='30' r='30' />
                        </ContentLoader>
                    </div>
                );
            }
            if (type === 'story') {
                return (
                    <div style='overflow: hidden;' class={`${classNames}`}>
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={height}
                            viewBox={viewBox}
                            backgroundColor={startColor}
                            foregroundColor={endColor}
                        >
                            <path d='M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z' />
                            <ellipse cx='27.53' cy='26.15' rx='27.53' ry='26.15' />
                            <rect x='69.36' y='0.5' width='87.36' height='16.48' rx='4.5' />
                            <rect x='0.53' y='328.35' width='87.36' height='16.48' rx='4.5' />
                            <rect x='95.84' y='328.35' width='87.36' height='16.48' rx='4.5' />
                            <rect x='195.38' y='328.35' width='304.45' height='16.48' rx='4.5' />
                            <rect x='412.47' y='358.52' width='87.36' height='16.48' rx='4.5' />
                            <rect x='291.22' y='358.52' width='113.31' height='16.48' rx='4.5' />
                            <rect x='0.53' y='358.52' width='282.21' height='16.48' rx='4.5' />
                            <rect x='69.36' y='25.22' width='164.67' height='27.07' rx='3.83' />
                        </ContentLoader>
                    </div>
                );
            }
            if (type === 'dog') {
                return (
                    <div style='overflow: hidden;' class={`${classNames}`}>
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={height}
                            viewBox={viewBox}
                            backgroundColor={startColor}
                            foregroundColor={endColor}
                        >
                            <rect x='310' y='8' rx='3' ry='3' width='30' height='6' />
                            <rect x='70' y='8' rx='3' ry='3' width='88' height='6' />
                            <rect x='70' y='26' rx='3' ry='3' width='52' height='6' />
                            <rect x='0' y='70' rx='3' ry='3' width='410' height='6' />
                            <rect x='0' y='86' rx='3' ry='3' width='380' height='6' />
                            <rect x='0' y='102' rx='3' ry='3' width='178' height='6' />
                            <rect x='0' y='118' rx='3' ry='3' width='208' height='6' />
                            <circle cx='30' cy='30' r='30' />
                        </ContentLoader>
                    </div>
                );
            }
            if (type === 'dogList') {
                return (
                    <div style='overflow: hidden;' class={`${classNames}`}>
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={height}
                            viewBox={viewBox}
                            backgroundColor={startColor}
                            foregroundColor={endColor}
                        >
                            <rect x='70' y='8' rx='3' ry='3' width='88' height='6' />
                            <rect x='70' y='26' rx='3' ry='3' width='52' height='6' />
                            <circle cx='30' cy='30' r='30' />
                        </ContentLoader>
                    </div>
                );
            }
            if (type === 'dogSimple') {
                return (
                    <div style='overflow: hidden;' class={`${classNames}`}>
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={height}
                            viewBox={viewBox}
                            backgroundColor={startColor}
                            foregroundColor={endColor}
                        >
                            <rect x='0' y='100' rx='3' ry='3' width='90' height='6' />
                            <circle cx='45' cy='45' r='45' />
                        </ContentLoader>
                    </div>
                );
            }


            return (
                <div style='overflow: hidden;' class={`${classNames}`}>
                    <svg
                        aria-labelledby={`${animationId}-aria`}
                        role='img'
                        viewBox={viewBox}
                        width={width}
                        height={height}
                        style={style}
                        class={className}
                    >
                        <title id={`${animationId}-aria`}>Loading...</title>
                        <rect
                            role='presentation'
                            x='0'
                            y='0'
                            width='100%'
                            height='100%'
                            clipPath={`url(#${animationId}-diff)`}
                            style={`fill: url('#${animationId}-animated-diff');`}
                        />
                        <defs role='presentation'>
                            <clipPath id={`${animationId}-diff`}>
                                <rect x='0' y='0' rx='5' ry='5' width={width} height={height} />
                            </clipPath>
                            <linearGradient id={`${animationId}-animated-diff`}>
                                <stop offset='0%' stopColor={startColor} stopOpacity='1'>
                                    <animate attributeName='offset' values='-2; -2; 1' keyTimes='0; 0.25; 1' dur='2s' repeatCount='indefinite' />
                                </stop>
                                <stop offset='50%' stopColor={endColor} stopOpacity='1'>
                                    <animate attributeName='offset' values='-1; -1; 2' keyTimes='0; 0.25; 1' dur='2s' repeatCount='indefinite' />
                                </stop>
                                <stop offset='100%' stopColor={startColor} stopOpacity='1'>
                                    <animate attributeName='offset' values='0; 0; 3' keyTimes='0; 0.25; 1' dur='2s' repeatCount='indefinite' />
                                </stop>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            );
        }
        if (children) {
            return (<>
                {children}
            </>);
        }
        return (<>
            {value}
        </>);
    }
}

export default Field;
