import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import Speed from '../../../components/data/speed';
import Elevation from '../../../components/data/elevation';
import Weight from '../../../components/data/weight';
import Distance from '../../../components/data/distance';
import Duration from '../../../components/data/duration';
import Temperature from '../../../components/data/temperature';
import Calories from '../../../components/data/calories';
import Intensity from '../../../components/data/intensity';
import Fitness from '../../../components/data/fitness';

import mu from '../../../lib/musher-util';

@observer
class WorkoutFitness extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetWorkoutFitnessDetail', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Analysis'
        });
    }

    render() {
        const { workoutStore, workoutPlanStore, teamStore, userStore } = this.props.stores;
        const { currentForm, currentFitness = 0, currentFatigue, formTrend, fitnessTrend = 0, fatigueTrend } = teamStore;

        let fitnessMessage = `Team is in good shape and resting well.`;
		let fitnessBackground = 'bg-success';
		if (currentFitness > 50) {
			// Default message
			if (currentFatigue > currentFitness) {
                fitnessMessage = `Team has a high fatigue. Consider some rest days!`;
                fitnessBackground = 'bg-warning';
            }
		} else if (currentFitness < 50 && currentFitness >= 25) {
			fitnessMessage = `Team is in ok shape.`;
			fitnessBackground = 'bg-warning';
		} else if (currentFitness < 25 && currentFitness >= 10) {
			fitnessMessage = `Team is not in good shape. Be careful when training.`;
			fitnessBackground = 'bg-warning';
		} else if (currentFitness < 10 && currentFitness > 0) {
			fitnessMessage = `Team is out of shape. Take it easy!`;
			fitnessBackground = 'bg-danger';
		} else {
			fitnessMessage = `Team has no workout data yet. Track workouts to show fitness data.`;
			fitnessBackground = 'bg-secondary';
		}

        return (<Widget stores={this.props.stores} {...this.props} onClick={this.onClick}>
            <div class={`widgetHeader`}>
                <i class='fa-solid fa-dumbbell widgetHeaderIcon mr-2' />
                <Text id='workouts.fitness'>Fitness</Text><br />
                <div class='widgetContentText text-center'>
                    <Fitness stores={this.props.stores} value={currentFitness} smallUnit={true} />
                </div>
            </div>
        </Widget>);
    }
}

export default WorkoutFitness;
