import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

@observer
class WidgetSegmentControl extends Component {
    constructor(props) {
        super(props);
        const currentWeek = util.getWeek(new Date());
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        this.state = {
            periode: 'week',
            periodeWeek: currentWeek,
            periodeMonth: currentMonth,
            periodeYear: currentYear,
        };
    }

    chooseSegmentPrimary = (e) => {
        const {
            callbackPrimary = () => {},
        } = this.props;
        const { periode } = e.target.closest('.appSegmentControllerPrimary div').dataset;
        // console.log({ e, periode });
        this.setState({ periode });
        callbackPrimary(periode);
    }

    chooseSegmentWeek = (e) => {
        const {
            callbackSecondary = () => {},
        } = this.props;
        const { value } = e.target.closest('.appSegmentControllerSecondary div').dataset;
        // console.log({ e, value });
        this.setState({ periodeWeek: parseInt(value, 10) });
        callbackSecondary(parseInt(value, 10));
    }

    chooseSegmentMonth = (e) => {
        const {
            callbackSecondary = () => {},
        } = this.props;
        const { value } = e.target.closest('.appSegmentControllerSecondary div').dataset;
        // console.log({ e, value });
        this.setState({ periodeMonth: parseInt(value, 10) });
        callbackSecondary(parseInt(value, 10));
    }

    chooseSegmentYear = (e) => {
        const {
            callbackSecondary = () => {},
        } = this.props;
        const { value } = e.target.closest('.appSegmentControllerSecondary div').dataset;
        // console.log({ e, value });
        const yyyy = parseInt(value, 10);
        this.setState({ periodeYear: yyyy });
        callbackSecondary(parseInt(value, 10));
    }

    render() {
        const { periode, periodeWeek, periodeMonth, periodeYear} = this.state;
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en', settings = {} } = user;
        const { darkmode } = appState;
        const {
            callbackPrimary = () => {},
            callbackSecondary = () => {},
        } = this.props;

        const currentDay = new Date().getDate();
		const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const startWeek = util.validRange(periodeWeek - 1, 1, 51);
        const endWeek = util.validRange(startWeek + 2, 1, 53);
        const weeks = util.range(startWeek, endWeek);

        const startMonth = util.validRange(periodeMonth - 1, 1, 10);
        const endMonth = util.validRange(startMonth + 2, 1, 12);
        const months = util.range(startMonth, endMonth);

        const startYear = util.validRange(periodeYear - 1, 2019, currentYear - 2);
        const endYear = util.validRange(startYear + 2, 2019, currentYear);
        const years = util.range(startYear, endYear);

        return (<>
            <div class='p-3'>
                <div
                    class={`w-100 d-flex justify-content-between align-items-center ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg appSegmentControllerPrimary`}
                    style='padding: 3px;'
                >
                    <div class={`text-center py-1 ${periode === 'week' && 'bg-light rounded-lg'}`} style='width: 33%;' onClick={this.chooseSegmentPrimary} data-periode='week'>
                        Week
                    </div>
                    <div class={`text-center py-1 ${periode === 'month' && 'bg-light rounded-lg'}`} style='width: 33%;' onClick={this.chooseSegmentPrimary} data-periode='month'>
                        Month
                    </div>
                    <div class={`text-center py-1 ${periode === 'year' && 'bg-light rounded-lg'}`} style='width: 33%;' onClick={this.chooseSegmentPrimary} data-periode='year'>
                        Year
                    </div>
                </div>
            </div>
            <div class='px-3 pt-0 pb-3'>
                <div
                    class={`w-100 d-flex justify-content-between align-items-center appSegmentControllerSecondary`}
                    style='padding: 3px;'
                >
                    {periode === 'week' && <>
                        {weeks.map(week => <>
                            <div class={`text-center py-1 ${periodeWeek === week && 'border-bottom border-primary'}`} style='width: 33%;' onClick={this.chooseSegmentWeek} data-value={week}>
                                Week {week}
                            </div>
                        </>)}
                    </>}
                    {periode === 'month' && <>
                        {months.map(month => <>
                            <div class={`text-center py-1 ${periodeMonth === month && 'border-bottom border-primary'}`} style='width: 33%;' onClick={this.chooseSegmentMonth} data-value={month}>
                                {mu.getMonth(month, language)}
                            </div>
                        </>)}
                    </>}
                    {periode === 'year' && <>
                        {years.map(year => <>
                            <div class={`text-center py-1 ${periodeYear === year && 'border-bottom border-primary'}`} style='width: 33%;' onClick={this.chooseSegmentYear} data-value={year}>
                                {year}
                            </div>
                        </>)}
                    </>}
                </div>
            </div>
        </>);
    }
}

export default WidgetSegmentControl;
