import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

class AdminStore extends StoreModel {
    constructor() {
        super('admin', {
            namePlural: 'admins',
            sort: '-createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/admins/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/admins/',
                    params: {},
                },
                save: {
                    url: '/api/admins/',
                    params: {},
                },
            },
        });
    }

    @observable admin = {};

    @observable admins = [];

    @observable stats = [];

    @observable totalDogs = [];

    @observable totalWorkoutPlans = [];

    @observable totalUsers = [];

    @observable totalUsersGarmin = [];

    @observable totalUsersStrava = [];

    @observable totalUsersProminentDisclosure = [];

    @observable totalUsersVeterinaryAllowed = [];

    @observable totalUsersInboxEmails = [];

    @observable totalUsersImperial = [];

    @observable totalUsersDarkmode = [];

    @observable totalUsersFirebase = [];

    @observable totalTeams = [];

    @observable totalTracks = [];

    @observable totalWorkouts = [];

    @observable totalDistance = [];

    @observable totalDuration = [];

    @observable avgDistance = [];

    @observable avgDogs = [];

    @observable avgDuration = [];

    @observable avgSpeed = [];

    @observable avgKey = new Date().getTime();

    @observable totalKey = new Date().getTime();

    @observable accAvgKey = new Date().getTime();

    @observable accTotalKey = new Date().getTime();

    @observable adminHistory = [];

    @observable veterinaryHistory = [];

    @observable pageViews = {};

    @observable pageViewsKeys = [];

    @observable heatmap = [];

    @observable demographicsLanguage = [];

    @observable demographicsDarkmode = [];

    @observable demographicsFirebase = [];

    @observable demographicsImperial = [];

    @observable demographicsVeterinary = [];

    @observable demographicsLocation = [];

    @observable demographicsActivities = [];

    @observable demographicsActivitiesPeriodes = [];

    @observable demographicsTeamWorkouts = [];

    @observable demographicsTeamFollowers = [];

    @observable demographicsTeamDogs = [];

    @observable storyViews = [];

    @observable storyLikes = [];

    @observable workoutCount = [];

    @observable storyCount = [];

    @observable storyComments = [];

    @observable storyDurationAvg = [];

    @observable searchCount = [];

    @observable publicHeatmap = [];

    @observable inviteResults = [];

    @observable errors = [];

    @observable user = {};

    @observable teams = [];

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('AdminStore.cleanupMemory');
        }
        this.localUpdateField('stats', []);
        this.localUpdateField('totalDogs', []);
        this.localUpdateField('totalWorkoutPlans', []);
        this.localUpdateField('totalUsers', []);
        this.localUpdateField('totalUsersGarmin', []);
        this.localUpdateField('totalUsersStrava', []);

        this.localUpdateField('totalUsersProminentDisclosure', []);
        this.localUpdateField('totalUsersVeterinaryAllowed', []);
        this.localUpdateField('totalUsersInboxEmails', []);
        this.localUpdateField('totalUsersImperial', []);
        this.localUpdateField('totalUsersDarkmode', []);
        this.localUpdateField('totalUsersFirebase', []);

        this.localUpdateField('totalTeams', []);
        this.localUpdateField('totalTracks', []);
        this.localUpdateField('totalWorkouts', []);
        this.localUpdateField('totalDistance', []);
        this.localUpdateField('totalDuration', []);
        this.localUpdateField('avgDistance', []);
        this.localUpdateField('avgDogs', []);
        this.localUpdateField('avgDuration', []);
        this.localUpdateField('avgSpeed', []);
        this.localUpdateField('adminHistory', []);
        this.localUpdateField('pageViews', {});
        this.localUpdateField('heatmap', []);
        this.localUpdateField('demographicsLanguage', []);
        this.localUpdateField('demographicsDarkmode', []);
        this.localUpdateField('demographicsFirebase', []);
        this.localUpdateField('demographicsImperial', []);
        this.localUpdateField('demographicsVeterinary', []);
        this.localUpdateField('demographicsLocation', []);
        this.localUpdateField('demographicsActivities', []);
        this.localUpdateField('demographicsActivitiesPeriodes', []);
        this.localUpdateField('demographicsTeamWorkouts', []);
        this.localUpdateField('demographicsTeamFollowers', []);
        this.localUpdateField('storyViews', []);
        this.localUpdateField('storyLikes', []);
        this.localUpdateField('storyCount', []);
        this.localUpdateField('workoutCount', []);
        this.localUpdateField('storyComments', []);
        this.localUpdateField('storyDurationAvg', []);
        this.localUpdateField('searchCount', []);
        this.localUpdateField('publicHeatmap', []);
        this.localUpdateField('inviteResults', []);
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    async getStats({ limit }) {
        const response = await util.fetchApi(`/api/admin/stats`, { publish: true, method: 'GET' }, { limit });
        switch (response.status) {
            case 200:
                this.localUpdateField('stats', response.data);
                // eslint-disable-next-line no-case-declarations
                const stats = response.data;
                stats.reverse();

                // eslint-disable-next-line no-case-declarations
                const totalDogs = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.dogs || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalWorkoutPlans = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.workoutPlans || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalUsers = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.users || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersGarmin = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersGarmin || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersStrava = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersStrava || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalUsersProminentDisclosure = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersProminentDisclosure || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersVeterinaryAllowed = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersVeterinaryAllowed || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersInboxEmails = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersInboxEmails || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersImperial = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersImperial || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersDarkmode = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersDarkmode || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersFirebase = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersFirebase || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalUsersCellphone = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersCellphone|| 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersUsername = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersUsername || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersFirstname = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersFirstname|| 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersLastname = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersLastname || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersFacebook = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersFacebook || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersInstagram = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersInstagram|| 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersSnapchat = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersSnapchat || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersUrl = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersUrltrava || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersImages = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersImagesva || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalUsersHideProfile = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersHideProfile || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersHideInvite = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersHideInvite || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersSummaryEmail = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersSummaryEmail || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersInfoEmail = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersInfoEmail || 0,
                        epoch: date.getTime(),
                    };
                });
                // eslint-disable-next-line no-case-declarations
                const totalUsersPartnerEmail = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.usersPartnerEmail || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalTeams = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.teams || 0,
                        epoch: date.getTime(),
                    };
                });


                // eslint-disable-next-line no-case-declarations
                const totalTeamsAmbitions = {};
                stats.forEach((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    if (util.isObject(e.teamAmbitionsCounts)) {
                        const keys = Object.keys(e.teamAmbitionsCounts);
                        keys.forEach((key) => {
                            if (!totalTeamsAmbitions[key]) {
                                totalTeamsAmbitions[key] = [];
                            }
                            totalTeamsAmbitions[key].push({
                                x: idx,
                                y: e.teamAmbitionsCounts[key] || 0,
                                epoch: date.getTime(),
                            });
                        });
                    }
                });

                // eslint-disable-next-line no-case-declarations
                const totalTeamsTypes = {};
                stats.forEach((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    if (util.isObject(e.teamTypesCounts)) {
                        const keys = Object.keys(e.teamTypesCounts);
                        keys.forEach((key) => {
                            if (!totalTeamsTypes[key]) {
                                totalTeamsTypes[key] = [];
                            }
                            totalTeamsTypes[key].push({
                                x: idx,
                                y: e.teamTypesCounts[key] || 0,
                                epoch: date.getTime(),
                            });
                        });
                    }
                });

                // eslint-disable-next-line no-case-declarations
                const userDeviceInfoPlatformCounts = {};
                stats.forEach((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    if (util.isObject(e.userDeviceInfoPlatformCounts)) {
                        const keys = Object.keys(e.userDeviceInfoPlatformCounts);
                        keys.forEach((key) => {
                            if (!userDeviceInfoPlatformCounts[key]) {
                                userDeviceInfoPlatformCounts[key] = [];
                            }
                            userDeviceInfoPlatformCounts[key].push({
                                x: idx,
                                y: e.userDeviceInfoPlatformCounts[key] || 0,
                                epoch: date.getTime(),
                            });
                        });
                    }
                });

                // eslint-disable-next-line no-case-declarations
                const userDeviceInfoManufacturerCounts = {};
                stats.forEach((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    if (util.isObject(e.userDeviceInfoManufacturerCounts)) {
                        const keys = Object.keys(e.userDeviceInfoManufacturerCounts);
                        keys.forEach((key) => {
                            if (!userDeviceInfoManufacturerCounts[key]) {
                                userDeviceInfoManufacturerCounts[key] = [];
                            }
                            userDeviceInfoManufacturerCounts[key].push({
                                x: idx,
                                y: e.userDeviceInfoManufacturerCounts[key] || 0,
                                epoch: date.getTime(),
                            });
                        });
                    }
                });


                // eslint-disable-next-line no-case-declarations
                const totalTracks = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.tracks || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalWorkouts = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.workouts || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalDistance = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.totalDistance || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgSpeedTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const avgSpeedEquipment = {};

                // eslint-disable-next-line no-case-declarations
                const totalDistanceEquipment = {};
                // eslint-disable-next-line no-case-declarations
                const avgDistanceEquipment = {};
                // eslint-disable-next-line no-case-declarations
                const avgDurationEquipment = {};
                // eslint-disable-next-line no-case-declarations
                const avgDurationTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const avgDistanceTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const avgElevationTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const totalDurationTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const totalDistanceTeamSize = {};
                // eslint-disable-next-line no-case-declarations
                const totalElevationTeamSize = {};
                for (let i = 0, l = stats.length; i < l; i += 1) {
                    const stat = stats[i];
                    const date = util.parseInputDate(stat.createdDate);
                    if (stat.totalDistanceEquipment) {
                        for (let j = 0, m = stat.totalDistanceEquipment.length; j < m; j += 1) {
                            const eqStat = stat.totalDistanceEquipment[j];
                            if (!totalDistanceEquipment[eqStat.equipment]) {
                                totalDistanceEquipment[eqStat.equipment] = []
                            }
                            totalDistanceEquipment[eqStat.equipment].push({
                                x: i,
                                y: eqStat.totalDistance || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgDistanceEquipment) {
                        for (let j = 0, m = stat.avgDistanceEquipment.length; j < m; j += 1) {
                            const eqStat = stat.avgDistanceEquipment[j];
                            if (!avgDistanceEquipment[eqStat.equipment]) {
                                avgDistanceEquipment[eqStat.equipment] = []
                            }
                            avgDistanceEquipment[eqStat.equipment].push({
                                x: i,
                                y: eqStat.avgDistance || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgDurationEquipment) {
                        for (let j = 0, m = stat.avgDurationEquipment.length; j < m; j += 1) {
                            const eqStat = stat.avgDurationEquipment[j];
                            if (!avgDurationEquipment[eqStat.equipment]) {
                                avgDurationEquipment[eqStat.equipment] = []
                            }
                            avgDurationEquipment[eqStat.equipment].push({
                                x: i,
                                y: eqStat.avgDuration || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgSpeedEquipment) {
                        for (let j = 0, m = stat.avgSpeedEquipment.length; j < m; j += 1) {
                            const eqStat = stat.avgSpeedEquipment[j];
                            if (!avgSpeedEquipment[eqStat.equipment]) {
                                avgSpeedEquipment[eqStat.equipment] = []
                            }
                            avgSpeedEquipment[eqStat.equipment].push({
                                x: i,
                                y: eqStat.avgSpeed || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgSpeedTeamSize) {
                        for (let j = 0, m = stat.avgSpeedTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.avgSpeedTeamSize[j];
                            if (!avgSpeedTeamSize[eqStat.teamSize]) {
                                avgSpeedTeamSize[eqStat.teamSize] = []
                            }
                            avgSpeedTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.avgSpeed || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgDurationTeamSize) {
                        for (let j = 0, m = stat.avgDurationTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.avgDurationTeamSize[j];
                            if (!avgDurationTeamSize[eqStat.teamSize]) {
                                avgDurationTeamSize[eqStat.teamSize] = []
                            }
                            avgDurationTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.avgDuration || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgDistanceTeamSize) {
                        for (let j = 0, m = stat.avgDistanceTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.avgDistanceTeamSize[j];
                            if (!avgDistanceTeamSize[eqStat.teamSize]) {
                                avgDistanceTeamSize[eqStat.teamSize] = []
                            }
                            avgDistanceTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.avgDistance || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.avgElevationTeamSize) {
                        for (let j = 0, m = stat.avgElevationTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.avgElevationTeamSize[j];
                            if (!avgElevationTeamSize[eqStat.teamSize]) {
                                avgElevationTeamSize[eqStat.teamSize] = []
                            }
                            avgElevationTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.avgElevation || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.totalDurationTeamSize) {
                        for (let j = 0, m = stat.totalDurationTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.totalDurationTeamSize[j];
                            if (!totalDurationTeamSize[eqStat.teamSize]) {
                                totalDurationTeamSize[eqStat.teamSize] = []
                            }
                            totalDurationTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.totalDuration || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.totalDistanceTeamSize) {
                        for (let j = 0, m = stat.totalDistanceTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.totalDistanceTeamSize[j];
                            if (!totalDistanceTeamSize[eqStat.teamSize]) {
                                totalDistanceTeamSize[eqStat.teamSize] = []
                            }
                            totalDistanceTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.totalDistance || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                    if (stat.totalElevationTeamSize) {
                        for (let j = 0, m = stat.totalElevationTeamSize.length; j < m; j += 1) {
                            const eqStat = stat.totalElevationTeamSize[j];
                            if (!totalElevationTeamSize[eqStat.teamSize]) {
                                totalElevationTeamSize[eqStat.teamSize] = []
                            }
                            totalElevationTeamSize[eqStat.teamSize].push({
                                x: i,
                                y: eqStat.totalElevation || 0,
                                epoch: date.getTime(),
                            });
                        }
                    }
                }

                // eslint-disable-next-line no-case-declarations
                const totalDuration = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.totalDuration || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const totalElevation = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.totalElevation || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgDistance = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgDistance || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgDogs = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgDogs || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgDuration = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgDuration || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgSpeed = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgSpeed || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgElevation = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgElevation || 0,
                        epoch: date.getTime(),
                    };
                });

                // eslint-disable-next-line no-case-declarations
                const avgTemperature = stats.map((e, idx) => {
                    const date = util.parseInputDate(e.createdDate);
                    return {
                        x: idx,
                        y: e.avgTemperature || 0,
                        epoch: date.getTime(),
                    };
                });

                this.localUpdateField('totalDogs', totalDogs);
                this.localUpdateField('totalUsers', totalUsers);
                this.localUpdateField('totalUsersGarmin', totalUsersGarmin);
                this.localUpdateField('totalUsersStrava', totalUsersStrava);

                this.localUpdateField('totalUsersProminentDisclosure', totalUsersProminentDisclosure);
                this.localUpdateField('totalUsersVeterinaryAllowed', totalUsersVeterinaryAllowed);
                this.localUpdateField('totalUsersInboxEmails', totalUsersInboxEmails);
                this.localUpdateField('totalUsersImperial', totalUsersImperial);
                this.localUpdateField('totalUsersDarkmode', totalUsersDarkmode);
                this.localUpdateField('totalUsersFirebase', totalUsersFirebase);

                this.localUpdateField('totalUsersCellphone', totalUsersCellphone);
                this.localUpdateField('totalUsersUsername', totalUsersUsername);
                this.localUpdateField('totalUsersFirstname', totalUsersFirstname);
                this.localUpdateField('totalUsersLastname', totalUsersLastname);
                this.localUpdateField('totalUsersFacebook', totalUsersFacebook);
                this.localUpdateField('totalUsersInstagram', totalUsersInstagram);
                this.localUpdateField('totalUsersSnapchat', totalUsersSnapchat);
                this.localUpdateField('totalUsersUrl', totalUsersUrl);
                this.localUpdateField('totalUsersImages', totalUsersImages);


                this.localUpdateField('totalUsersHideProfile', totalUsersHideProfile);
                this.localUpdateField('totalUsersHideInvite', totalUsersHideInvite);
                this.localUpdateField('totalUsersSummaryEmail', totalUsersSummaryEmail);
                this.localUpdateField('totalUsersInfoEmail', totalUsersInfoEmail);
                this.localUpdateField('totalUsersPartnerEmail', totalUsersPartnerEmail);

                this.localUpdateField('totalTeams', totalTeams);

                this.localUpdateField('totalTeamsAmbitions', totalTeamsAmbitions);
                this.localUpdateField('totalTeamsTypes', totalTeamsTypes);

                this.localUpdateField('userDeviceInfoPlatformCounts', userDeviceInfoPlatformCounts);
                this.localUpdateField('userDeviceInfoManufacturerCounts', userDeviceInfoManufacturerCounts);

                this.localUpdateField('totalTracks', totalTracks);
                this.localUpdateField('totalWorkoutPlans', totalWorkoutPlans);

                this.localUpdateField('totalWorkouts', totalWorkouts);
                this.localUpdateField('totalDistance', totalDistance);
                this.localUpdateField('totalDistanceEquipment', totalDistanceEquipment);
                this.localUpdateField('totalDuration', totalDuration);
                this.localUpdateField('totalElevation', totalElevation);

                this.localUpdateField('totalDurationTeamSize', totalDurationTeamSize);
                this.localUpdateField('totalDistanceTeamSize', totalDistanceTeamSize);
                this.localUpdateField('totalElevationTeamSize', totalElevationTeamSize);

                this.localUpdateField('avgDistance', avgDistance);
                this.localUpdateField('avgDistanceEquipment', avgDistanceEquipment);
                this.localUpdateField('avgDogs', avgDogs);
                this.localUpdateField('avgDuration', avgDuration);
                this.localUpdateField('avgSpeed', avgSpeed);
                this.localUpdateField('avgElevation', avgElevation);
                this.localUpdateField('avgTemperature', avgTemperature);

                this.localUpdateField('avgSpeedTeamSize', avgSpeedTeamSize);
                this.localUpdateField('avgSpeedEquipment', avgSpeedEquipment);
                this.localUpdateField('avgDistanceTeamSize', avgDistanceTeamSize);
                this.localUpdateField('avgElevationTeamSize', avgElevationTeamSize);
                this.localUpdateField('avgDurationTeamSize', avgDurationTeamSize);
                this.localUpdateField('avgDurationEquipment', avgDurationEquipment);

                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getStatsAvg() {
        const response = await util.fetchApi(`/api/admin/stats-avg`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('avgKey', new Date().getTime());
                this.localUpdateField('avgSpeedWeek', response.data.avgSpeedWeek);
                this.localUpdateField('avgDistanceWeek', response.data.avgDistanceWeek);
                this.localUpdateField('avgLoadIndexWeek', response.data.avgLoadIndexWeek);
                this.localUpdateField('avgDistanceTeamTypeWeek', response.data.avgDistanceTeamTypeWeek);
                this.localUpdateField('avgDistanceTeamAmbitionsWeek', response.data.avgDistanceTeamAmbitionsWeek);
                this.localUpdateField('avgDistanceTeamTypeAmbitionWeek', response.data.avgDistanceTeamTypeAmbitionWeek);

                this.localUpdateField('avgAscendWeek', response.data.avgAscendWeek);
                this.localUpdateField('avgDurationWeek', response.data.avgDurationWeek);
                this.localUpdateField('avgRealDurationWeek', response.data.avgRealDurationWeek);
                this.localUpdateField('avgTeamsizeWeek', response.data.avgTeamsizeWeek);
                this.localUpdateField('totalWorkoutsWeek', response.data.totalWorkoutsWeek);

                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getStatsTotal() {
        const response = await util.fetchApi(`/api/admin/stats-total`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('totalKey', new Date().getTime());
                this.localUpdateField('totalWorkoutsWeek', response.data.totalWorkoutsWeek);

                this.localUpdateField('totalDistanceWeek', response.data.totalDistanceWeek);
                this.localUpdateField('totalAscendWeek', response.data.totalAscendWeek);
                this.localUpdateField('totalDurationWeek', response.data.totalDurationWeek);
                this.localUpdateField('totalDogsrunningWeek', response.data.totalDogsrunningWeek);

                this.localUpdateField('totalDogsWeek', response.data.totalDogsWeek);
                this.localUpdateField('totalDogsWeekAcc', response.data.totalDogsWeekAcc);
                this.localUpdateField('totalUsersWeek', response.data.totalUsersWeek);
                this.localUpdateField('totalUsersWeekNumber', response.data.totalUsersWeekNumber);
                this.localUpdateField('totalUsersMonthNumber', response.data.totalUsersMonthNumber);
                this.localUpdateField('totalUsersWeekAcc', response.data.totalUsersWeekAcc);


                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getAdminHistory() {
        const response = await util.fetchApi(`/api/admin/admin-history`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('adminHistory', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getVeterinaryHistory() {
        const response = await util.fetchApi(`/api/admin/veterinary-history`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('veterinaryHistory', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async sendNotification({ title, topic, message, url, urlList, language, query, workoutQuery, doSend }) {
        const response = await util.fetchApi(`/api/admin/notifications`, { publish: true, method: 'POST' }, {
            title,
            topic,
            message,
            url,
            urlList,
            language,
            query,
            workoutQuery,
            doSend,
        });
        switch (response.status) {
            case 201:
                PubSub.publish(topics.STATUS_MESSAGE, {
                    message: `Notification sent to all users. ${response.message}`,
                    type: 'success',
				    icon: 'fas fa-exclamation-triangle',
                });
                break;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getPageViews() {
        const response = await util.fetchApi(`/api/pageview/summary`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('pageViews', response.data);
                this.localUpdateField('pageViewsKeys', response.included.arrayViews);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getFixWorkoutGpxInfo() {
        const response = await util.fetchApi(`/api/admin/fix-all-gpxinfo-workouts`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getHeatmap() {
        const response = await util.fetchApi(`/api/admin/heatmap`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('heatmap', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getDemographics() {
        const response = await util.fetchApi(`/api/admin/demographics`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('demographicsLanguage', response.data.language);
                this.localUpdateField('demographicsDarkmode', response.data.darkmode);
                this.localUpdateField('demographicsFirebase', response.data.firebase);
                this.localUpdateField('demographicsImperial', response.data.imperial);
                this.localUpdateField('demographicsVeterinary', response.data.veterinary);
                this.localUpdateField('demographicsLocation', response.data.location);
                this.localUpdateField('demographicsActivities', response.data.activities);
                this.localUpdateField('demographicsActivitiesPeriodes', response.data.activitiesPeriodes);
                this.localUpdateField('demographicsTeamWorkouts', response.data.teamWorkouts);
                this.localUpdateField('demographicsTeamFollowers', response.data.teamFollowers);
                this.localUpdateField('demographicsTeamDogs', response.data.teamDogs);
                this.localUpdateField('storyViews', response.data.storyViews);
                this.localUpdateField('storyLikes', response.data.storyLikes);
                this.localUpdateField('storyCount', response.data.storyCount);
                this.localUpdateField('workoutCount', response.data.workoutCount);
                this.localUpdateField('storyComments', response.data.storyComments);
                this.localUpdateField('storyDurationAvg', response.data.storyDurationAvg);
                this.localUpdateField('searchCount', response.data.searchCount);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getPublicHeatmap(raceId, teamIdIn) {
        const response = await util.fetchApi(`/api/workouts/heatmap-homerun-race/`, { publish: true, method: 'GET' }, { raceId, teamIdIn });
        switch (response.status) {
            case 200:
                this.localUpdateField('publicHeatmap', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getUserInviteResults() {
        const response = await util.fetchApi(`/api/admin/invite-results`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('inviteResults', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getErrors() {
        const response = await util.fetchApi(`/api/errors/`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('errors', response.data);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

    async getUserByEmail(email) {
        const response = await util.fetchApi(`/api/admin/user/${email}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.localUpdateField('user', response.data);
                this.localUpdateField('teams', response.included.teams);
                return response;
            case 401:
                PubSub.publish(topics.LOG_OUT);
                route('/');
                break;
        }
    }

}

const store = new AdminStore();
export default store;
