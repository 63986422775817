import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

function displayDuration(duration) {
	return util.secToHms(duration, true);
}

@observer
class Duration extends Component {
    render() {
		const { value = 0, smallUnit } = this.props;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		const { settings = {} } = user;

		if (value <= 0) {
			return '--:--';
		}

		return (<>
			<nobr>
	            {displayDuration(value)}
			</nobr>
	    </>);
    }
}

export default Duration;
