import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';

import Speed from '../../../components/data/speed';
import Elevation from '../../../components/data/elevation';
import Weight from '../../../components/data/weight';
import Distance from '../../../components/data/distance';
import Duration from '../../../components/data/duration';
import Temperature from '../../../components/data/temperature';
import mu from '../../../lib/musher-util';

@observer
class WorkoutTotalElevation extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('widgetWorkoutTotalElevationDetail', {
            height: drawerHeightLarge,
            callback: this.toggleDrawer,
            borderRadius: '',
            showCloseButton: false,
            showBackButton: true,
            backButtonText: 'Analysis'
        });
    }

    render() {
        const { workoutStore, workoutPlanStore, teamStore, userStore } = this.props.stores;
        const { workouts, cordovaActiveTrackingParams, isRunning, totalDistance, currentSpeed, totalTime } = workoutStore;

        const currentDay = new Date().getDate();
		const currentWeek = util.getWeek(new Date());
		const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

		const {
			month = currentMonth,
			year = currentYear,
		} = this.props;
		const seasonStart = parseInt(month < 8 ? year - 1 : year, 10);

        const { user } = userStore;
        const currentTeamObject = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
		const currentTeam = currentTeamObject.id;
        const currentSeason = (currentTeam ? teamStore.findWorkoutSummary(currentTeam, seasonStart) : teamStore.findWorkoutSummaryTotal(seasonStart)) || {};

        return (<Widget stores={this.props.stores} {...this.props} onClick={this.onClick}>
            <div class='widgetHeader'>
                <i class='fa-solid fa-mountain widgetHeaderIcon mr-2' />
                <Text id='workouts.elevation'>Elevation</Text><br />
                <div class='widgetContentText text-center'>
                    <Elevation stores={this.props.stores} value={currentSeason.elevation || 0} smallUnit={true} />
                </div>
            </div>
        </Widget>);
    }
}

export default WorkoutTotalElevation;
