import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer, withText } from 'preact-i18n';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import mu from '../../lib/musher-util';

const placeholder = {
    id: 0,
    name: 'empty',
};

const getPlaceholder = () => {
    const newPlaceholder = { ...placeholder };
    newPlaceholder.unique= `${new Date().getTime()}-${util.randomPassword()}`;
    return newPlaceholder;
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#808080" : "",
    maxWidth: 92,
    height: 100,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderStyle: isDraggingOver ? "dashed" : "",
    // background: isDraggingOver ? "#a0a0a0" : "",
    // padding: grid,
    width: 110,
});

class List extends Component {
  render() {
    const { provided, snapshot, innerRef, children, className } = this.props;
    return (
        <div class={`h-100 border ${className}`} {...provided.droppableProps} ref={innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
        >
            {children}
        </div>
    );
  }
}


class Dog extends Component {
    removeThisPlaceholder = () => {
        const { removePlaceholder = () => {}, idx, list } = this.props;
        removePlaceholder({ idx, list });
    }

    render() {
        const { provided, snapshot, innerRef, dog } = this.props;
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={innerRef}
                class='mx-2 my-2 p-2'
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                {dog.id === 0 ? (
                    <>
                        <div>
                            <div class={`text-center border rounded-circle imageRounded text-muted pt-2`}>
                                <span class='font-weight-lighter' style='font-size: 35px;'>&nbsp;</span>
                            </div>
                            <div class='text-center'>
                                <button class='btn btn-sm btn-link float-right p-0' onClick={this.removeThisPlaceholder}>
                                    <i class='fas fa-trash-alt' />
                                </button>
                                &nbsp;
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <div class={`text-center border rounded-circle imageRounded text-muted pt-2`} style={`${dog.image ? `background-image: url("${dog.image.s3ThumbLink}");` : ''}`}>
                                {/* {!dog.image && <i class='fas fa-dog' style='font-size: 20px;' />} */}
                                {!dog.image && <span class='font-weight-lighter' style='font-size: 35px;'>{util.ucfirst(dog.name, true)}</span>}
                            </div>
                            <div class='text-center'>
                                <small>
                                    {dog.shortname || dog.name}<br />
                                </small>

                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}


const initialState = {
    filterby: util.get('dogs-filter'),
};

@observer
class Dogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dogsLeft: [],
            dogsRight: [],
            ...initialState,
        };
    }

    toggleDog = async (e) => {
        const { id } = e.target.closest('#dogContainer').dataset;
        const dogId = parseInt(id, 10);
        const { object, type, elementtype, field, updateField, isNew, showPositions } = this.props;
        const value = toJS(object[field]) || [];
        const idx = value?.indexOf(dogId);
        let action;
        if (idx > -1) {
            value.splice(idx, 1);
            action = 'remove';
        } else {
            value.push(dogId);
            action = 'add';
        }
        await updateField({ id: object.id, field, value, type, elementtype, isNew, skipTimer: true, singleValue: dogId });
        this.setState({
            filterText: '',
        });
        if (showPositions) {
            this.dndDogs({ dogId, action });
        }
    }

    findAllDogsInTeams = () => {
        const { dogStore } = this.props.stores;
        const { dogs = [] } = dogStore;
        const { object, currentTeam = {} } = this.props;
        const selectedTeams = toJS(object.teams || []);
        const currentTeamId = currentTeam.id;
        const filter = (e) => {
            if (e.team === currentTeamId || (e.teams && e.teams?.indexOf(currentTeamId) > -1)) {
                return true;
            }
            if (selectedTeams?.indexOf(e.team) > -1) {
                return true;
            }
            if (e.teams) {
                return e.teams.some(r => selectedTeams?.indexOf(r) >= 0);
            }
            return false;
        };
        const allDogsInTeam = dogs.filter(filter).map(e => e.id);
        return allDogsInTeam;
    }

    addAllDogsInTeam = async () => {
        const { object, type, elementtype, field, updateField, isNew } = this.props;
        const allDogsInTeam = this.findAllDogsInTeams();
        await updateField({ id: object.id, field, value: allDogsInTeam, type, elementtype, isNew, skipTimer: true });
    }

    // toggleFilter = () => {
    //     const { showAll } = this.state;
    //     this.setState({
    //         showAll: showAll ? 0 : 1,
    //     });
    //     this.loadDog();
    // }

    // toggleSelectedFilter = () => {
    //     const { showOnlySelected } = this.state;
    //     this.setState({
    //         showOnlySelected: showOnlySelected ? 0 : 1,
    //     });
    // }

    dndDogs = ({ dogId, action }) => {
        const { dogStore } = this.props.stores;
        const { dogs = [] } = dogStore;
        const { object } = this.props;
        const dogFilter = object.dogs ? (e) => object.dogs?.indexOf(e.id) > -1 : () => false;
        const allDogs = toJS(dogs);
        const filterLeft = (dogId) => dogId === 0 || (allDogs.find(a => a.id === dogId) && object.dogsRight?.indexOf(dogId) === -1);
        const filterRight = (dogId) => dogId === 0 || (allDogs.find(a => a.id === dogId) && object.dogsLeft?.indexOf(dogId) === -1);

        const defaultDogsRight = allDogs.filter(dogFilter);
        const n = Math.ceil(defaultDogsRight.length / 2);
        const defaultDogsLeft = defaultDogsRight.splice(0, n);

        let dogsLeft = [];
        if (object.dogsLeft && object.dogsLeft.length > 0) {
            dogsLeft = object.dogsLeft.filter(filterLeft)
                .map((dogId) => {
                    const dog = allDogs.find(e => e.id === dogId) || getPlaceholder();
                    // console.log('dogId', dogId, dog);
                    return dog;
                });
        }

        let dogsRight = [];
        if (object.dogsRight && object.dogsRight.length > 0) {
            dogsRight = object.dogsRight.filter(filterRight)
                .map((dogId) => {
                    const dog = allDogs.find(e => e.id === dogId) || getPlaceholder();
                    // console.log('dogId', dogId, dog);
                    return dog;
                });
        }


        if (action === 'add') {
            const dog = dogs.find(e => e.id === dogId);
            if (dog) {
                if (dogsLeft.length > dogsRight.length) {
                    dogsRight.push(dog);
                } else {
                    dogsLeft.push(dog);
                }
            }
        }
        if (action === 'remove') {
            const idxLeft = dogsLeft?.findIndex(e => e.id === dogId);
            if (idxLeft > -1) {
                dogsLeft.splice(idxLeft, 1);
            }
            const idxRight = dogsRight?.findIndex(e => e.id === dogId);
            if (idxRight > -1) {
                dogsRight.splice(idxRight, 1);
            }
        }
        // I owe Mariska Kleintjens a beer for this one:
        if (dogsLeft?.length === 0 && dogsRight?.length === 0) {
            dogsLeft = defaultDogsLeft;
            dogsRight = defaultDogsRight;
        }
        if (action) {
            this.setState({
                dogsLeft,
                dogsRight,
                dogPositionUpdated: true,
            }, () => this.updateDogPositions());

        } else {
            this.setState({
                dogsLeft,
                dogsRight,
            });
        }
    }

    addPlaceholderLeft = () => {
        const { dogsLeft = [] } = this.state;
        dogsLeft.push(getPlaceholder());
        this.setState({
            dogsLeft,
            dogPositionUpdated: true,
        });
    }

    addPlaceholderRight = () => {
        const { dogsRight = [] } = this.state;
        dogsRight.push(getPlaceholder());
        this.setState({
            dogsRight,
            dogPositionUpdated: true,
        });
    }

    removePlaceholder = ({ idx, list }) => {
        if (list === 'dogsLeft') {
            const { dogsLeft = [] } = this.state;
            dogsLeft.splice(idx, 1);
            this.setState({
                dogsLeft,
                dogPositionUpdated: true,
            });
        }
        if (list === 'dogsRight') {
            const { dogsRight = [] } = this.state;
            dogsRight.splice(idx, 1);
            this.setState({
                dogsRight,
                dogPositionUpdated: true,
            });
        }
    }

    async loadDog(props = this.props) {
        const { showPositions } = this.props;
		const { dogStore } = props.stores;
        await dogStore.load({ query: { deceased: 0 }, addData: ['team', 'user', 'workoutSummary', 'vaccineStatuses', 'historyStatuses', 'vaccineLogStatuses', 'allDogs'] });

        if (showPositions) {
            this.dndDogs({});
        }
        this.getFilteredDogs();
    }

    clearAllDogs = async () => {
        const { object, type, elementtype, field, updateField, isNew } = this.props;
        await updateField({ id: object.id, field, value: [], type, elementtype, isNew, skipTimer: true });
        this.resetDogPositions();
    }

    toggleSearchField = () => {
        const { showSearchField } = this.state;
        this.setState({
            showSearchField: showSearchField ? null : 1,
        });
    }

    getList = id => this.state[id];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            this.setState({
                [source.droppableId]: items,
                dogPositionUpdated: true,
            });
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                dogsLeft: result.dogsLeft,
                dogsRight: result.dogsRight,
                dogPositionUpdated: true,
            });
        }
    }

    updateDogPositions = async () => {
        const { dogsLeft = [], dogsRight = [] } = this.state;
        const { object, updateField, isNew } = this.props;
        await updateField({ id: object.id, field: 'dogsLeft', value: dogsLeft.map(e => e.id), isNew, datatype: 'array' });
        await updateField({ id: object.id, field: 'dogsRight', value: dogsRight.map(e => e.id), isNew, datatype: 'array' });
        this.setState({ dogPositionUpdated: false });
    }

    resetDogPositions = async () => {
        const { dogStore } = this.props.stores;
        const { dogs = [] } = dogStore;
        const { object, updateField, isNew } = this.props;
        const dogFilter = (e) => object.dogs?.indexOf(e.id) > -1;
        const allDogs = toJS(dogs);

        const dogsRight = allDogs.filter(dogFilter);
        const n = Math.ceil(dogsRight.length / 2);
        const dogsLeft = dogsRight.splice(0, n);
        await updateField({ id: object.id, field: 'dogsLeft', value: dogsLeft.map(e => e.id), isNew, datatype: 'array' });
        await updateField({ id: object.id, field: 'dogsRight', value: dogsRight.map(e => e.id), isNew, datatype: 'array' });
        this.setState({
            dogsLeft,
            dogsRight,
            dogPositionUpdated: false,
        });
    }

    clearDogPositions = async () => {
        const { object, updateField, isNew } = this.props;
        const dogsRight = [];
        const dogsLeft = [];
        await updateField({ id: object.id, field: 'dogsLeft', value: dogsLeft, isNew, datatype: 'array' });
        await updateField({ id: object.id, field: 'dogsRight', value: dogsRight, isNew, datatype: 'array' });
        this.setState({
            dogsLeft,
            dogsRight,
            dogPositionUpdated: false,
        });
    }

    changeFilter = (e) => {
		const value = e.target.value;
		this.setState({
			filterby: value,
		});
        util.set('dogs-filter', value);
        this.loadDog();
	}

    getFilteredDogs = (props = this.props) => {
        const { filterby, filterText } = this.state;
        const { object, currentTeam = {}, filter = () => true } = props;
        const { dogStore } = this.props.stores;
		const { dogs = [] } = dogStore;
        let dogFilter = filter;
        if (object.dogs) {
            switch (filterby) {
                case 'all':
                    if (filterText) {
                        dogFilter = (e) => e.name.match(new RegExp(filterText, 'i')) || object.dogs?.indexOf(e.id) > -1;
                    } else {
                        dogFilter = () => true;
                    }
                    break;
                case 'onlySelected':
                    if (filterText) {
                        dogFilter = (e) => e.name.match(new RegExp(filterText, 'i')) || object.dogs?.indexOf(e.id) > -1;
                    } else {
                        dogFilter = (e) => object.dogs?.indexOf(e.id) > -1;
                    }
                    break;
                case 'onlyTeam':
                    if (filterText) {
                        dogFilter = (e) => e.name.match(new RegExp(filterText, 'i')) || object.dogs?.indexOf(e.id) > -1;
                    } else {
                        dogFilter = (e) => {
                            if (e.teams && e.teams?.indexOf(currentTeam.id) > -1) {
                                return true;
                            }
                            if (object.dogs?.indexOf(e.id) > -1) {
                                return true;
                            }
                            return false;
                        };
                    }
                    break;
                case 'leadOnly':
                    dogFilter = (e) => {
                        if (object.dogs?.indexOf(e.id) > -1) {
                            return true;
                        }
                        if (util.getNestedValue(e, 'positions.lead.distanceKm') > 0) {
                            return true;
                        }
                        return false;
                    };
                    break;
                case 'pointOnly':
                    dogFilter = (e) => {
                        if (object.dogs?.indexOf(e.id) > -1) {
                            return true;
                        }
                        if (util.getNestedValue(e, 'positions.point.distanceKm') > 0) {
                            return true;
                        }
                        return false;
                    };
                    break;
                case 'teamOnly':
                    dogFilter = (e) => {
                        if (object.dogs?.indexOf(e.id) > -1) {
                            return true;
                        }
                        if (util.getNestedValue(e, 'positions.team.distanceKm') > 0) {
                            return true;
                        }
                        return false;
                    };
                    break;
                case 'wheelOnly':
                    dogFilter = (e) => {
                        if (object.dogs?.indexOf(e.id) > -1) {
                            return true;
                        }
                        if (util.getNestedValue(e, 'positions.wheel.distanceKm') > 0) {
                            return true;
                        }
                        return false;
                    };
                    break;
            }
        }
        let filteredDogs = dogs.filter(dogFilter);
        this.setState({
            filteredDogs,
        })
    }


    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.filter !== this.props.filter) {
            this.getFilteredDogs(nextProps);
        }
    }

    componentDidMount() {
        this.loadDog();
    }

    render() {
        const { filterby, filterText, showSearchField, dogsLeft = [], dogsRight = [], dogPositionUpdated, filteredDogs = [] } = this.state;
        const { userStore, dogStore } = this.props.stores;
		const { dogs = [] } = dogStore;
        const { isAdmin, user } = userStore;
        const { language = 'en' } = user;

        const { saved = {}, object, field, title, icon, help, showPositions = false, currentTeam = {} } = this.props;
        const value = toJS(object[field]) || [];

        const allDogsInTeam = this.findAllDogsInTeams();
        const teamNames = object.teams ? object.teams.map(e => userStore.findTeam(e)) : [];

        return (
            <div class={`form-group  mb-5 pb-1 border-bottom ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                <span class='float-right' onClick={this.toggleSearchField}>
                    <i class='fas fa-search' />
                </span>
                <button class='btn btn-sm btn-link float-right mr-5' onClick={this.clearAllDogs}>
                    <i class='fas fa-eraser' /> <Text id='dog.dogs.remove-all-dogs'>Fjern alle hundene</Text>
                </button>
                <label for={`${field}Input`}>{icon && <i class={`${icon} text-muted`} />} {title}</label>

                <div class='container-fluid px-0'>
                    {showSearchField && <div class='row'>
                        <div class='col-12'>
                            <Localizer>
                                <input
                                    class='form-control form-control-sm'
                                    type='text'
                                    placeholder={<Text id='dog.dogs.search-for-dogs'>Søk etter hund</Text>}
                                    value={filterText}
                                    onInput={linkState(this, 'filterText')}
                                />
                            </Localizer>
                        </div>
                    </div>}
                    <div class='row'>
                        <div class='col-12'>
                            <div class='form-row align-items-center'>
                                <div class='col-auto my-2 mx-auto'>
                                    <select class='custom-select'
                                        style='background-color: inherit; border: none; padding-top: 0; padding-bottom: 0; height: 1.4em; font-size: 0.8em;'
                                        onInput={this.changeFilter}
                                    >
                                        <option value='' selected={!filterby}>-- <Text id='dog.dogs.choose-filter'>Choose filter</Text> ---</option>
                                        <option value='all' selected={filterby === 'all'}><Text id='dog.dogs.show-all'>Vis alle</Text></option>
                                        <option value='onlySelected' selected={filterby === 'onlySelected'}><Text id='dog.dogs.show-only-selected'>Vis kun valgte</Text></option>
                                        <option value='onlyTeam' selected={filterby === 'onlyTeam'}><Text id='dog.dogs.show-only-team-dogs'>Vis kun hundene i teamet</Text></option>
                                        <option value='leadOnly' selected={filterby === 'leadOnly'}><Text id='dog.dogs.show-lead-dogs-only'>Vis kun lederhunder</Text></option>
                                        <option value='pointOnly' selected={filterby === 'pointOnly'}><Text id='dog.dogs.show-point-dogs-only'>Vis kun pointhunder</Text></option>
                                        <option value='teamOnly' selected={filterby === 'teamOnly'}><Text id='dog.dogs.show-team-dogs-only'>Vis kun teamhunder</Text></option>
                                        <option value='wheelOnly' selected={filterby === 'wheelOnly'}><Text id='dog.dogs.show-wheel-dogs-only'>Vis kun wheelhunder</Text></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        {allDogsInTeam.length > 0 && <>
                            <button class='btn btn-primary btn-block'
                                onClick={this.addAllDogsInTeam}
                            >
                               <i class='fas fa-plus mr-2' /> <Text id='dog.dogs.add-all-in-team' fields={{ total: allDogsInTeam.length }}>Add all dogs in team</Text>{currentTeam.name ? `: ${currentTeam.name}` : ''}
                               {teamNames && teamNames.length > 0 && <span class='font-weight-lighter'>
                                    , {teamNames.map((name, idx) => <>{idx > 0 ? ', ' : ''}{name}</>)}
                                </span>}
                            </button>
                        </>}

                        {util.isArray(dogs) && filteredDogs.map(dog => {
                            const dogStatus = userStore.findDogstatus({ status: dog.status, language });
                            const dogPosition = userStore.findDogposition({ position: dog.position, language });
                            const dogHistoryStatuses = dogStore.findHistoryStatuses(dog.id);
                            return (
                                <div class='col-6 col-sm-6 col-md-6 col-lg-6 mb-1'>
                                    <div class={`border rounded px-3 pt-2 pb-1 ${value?.indexOf(dog.id) > -1 ? 'bg-success' : ''} position-relative`}
                                        onClick={this.toggleDog}
                                        id='dogContainer'
                                        data-id={dog.id}
                                        style='padding: .3rem !important; line-height: 1.1em; cursor: pointer; pointer-events: inherit;'
                                    >
                                        {dogPosition && <small class='position-absolute' style='left:5px; top: 32px;'>
                                            <span class={`badge badge-${dogPosition.class || 'primary'} mr-3`}>{dogPosition.icon && <i class={dogPosition.icon} />} {dogPosition.name}</span>
                                        </small>}

                                        {filterby === 'leadOnly' && util.getNestedValue(dog, 'positions.lead.distanceKm') > 0 && <small class='position-absolute' style='left:5px; top: 32px;'>
                                            <span class={`badge badge-secondary mr-3`}><Text id='dogs.sort-lead'>Lead</Text>: {util.format(util.getNestedValue(dog, 'positions.lead.distanceKm'), 0)}km</span>
                                        </small>}
                                        {filterby === 'pointOnly' && util.getNestedValue(dog, 'positions.point.distanceKm') > 0 && <small class='position-absolute' style='left:5px; top: 32px;'>
                                            <span class={`badge badge-secondary mr-3`}><Text id='dogs.sort-point'>Point</Text>: {util.format(util.getNestedValue(dog, 'positions.point.distanceKm'), 0)}km</span>
                                        </small>}
                                        {filterby === 'teamOnly' && util.getNestedValue(dog, 'positions.team.distanceKm')> 0 && <small class='position-absolute' style='left:5px; top: 32px;'>
                                            <span class={`badge badge-secondary mr-3`}><Text id='dogs.sort-team'>Team</Text>: {util.format(util.getNestedValue(dog, 'positions.team.distanceKm'), 0)}km</span>
                                        </small>}
                                        {filterby === 'wheelOnly' && util.getNestedValue(dog, 'positions.wheel.distanceKm') > 0 && <small class='position-absolute' style='left:5px; top: 32px;'>
                                            <span class={`badge badge-secondary mr-3`}><Text id='dogs.sort-wheel'>Wheel</Text>: {util.format(util.getNestedValue(dog, 'positions.wheel.distanceKm'), 0)}km</span>
                                        </small>}

                                        <div class='d-flex'>
                                            <div class={`text-center border rounded-circle imageRounded imageRoundedSmall text-muted pt-2`} style={`${dog.image ? `background-image: url("${dog.image.s3ThumbLink}");` : ''}`}>
                                                {/* {!dog.image && <i class='fas fa-dog' style='font-size: 20px;' />} */}
                                                {!dog.image && <span class='font-weight-lighter' style='font-size: 20px;'>{util.ucfirst(dog.name, true)}</span>}
                                            </div>
                                            <div class='flex-grow-1 pl-2 pr-1'
                                                style={`border-right: 4px solid ${userStore.findHarness(dog.harness, 'colorNonstop')} !important; line-height: 0.9em;`}
                                            >
                                                <small>
                                                    <small class='float-right'>
                                                        {dog.birth && <span class='mr-1'>{util.age(dog.birth, dog.deceased)}</span>}
                                                        {dog.gender === 'female' ? <i class='fas fa-venus' /> : <i class='fas fa-mars' />}
                                                    </small>
                                                    {dog.shortname || dog.name}<br />
                                                    <div style='line-height: 0.8em;'><small>{userStore.findTeam(dog.team)}</small></div>
                                                </small>
                                                <small class='float-right'>
                                                    {dogStatus && dogStatus.id && <span class={`badge badge-${dogStatus.class} mr-2 font-weight-light`}>{dogStatus.icon && <i class={dogStatus.icon} />} {dogStatus.name}</span>}
                                                    {dog.specialNeeds && <span class={`badge badge-warning mr-2 font-weight-light`}><i class='fas fa-exclamation-triangle' /> {dog.specialNeeds}</span>}
                                                    {dogHistoryStatuses && dogHistoryStatuses.map(v => (
                                                        <span class={`badge badge-warning mr-2`} title={util.isoDate(v.endDate, false, false, true)}>{v.title}</span>
                                                    ))}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {saved[`${field}.${object.id}`] ? <div class={`valid-feedback`} style='display: block;'><Text id='dog.dogs.saved-changes'>Endringene er lagret</Text></div> :
                    <small id={`${field}Help`} class='form-text text-muted'>{help}</small>}


                {showPositions && <>
                    <div
                        class='container-fluid px-0 clearfix'
                    >
                        <div class='row'>
                            <div class='col-12'>
                                <h5><Text id='dog.dogs.dogs-positions'>Posisjoner i spannet</Text></h5>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <div class='row'>
                                <div class='col-6 pr-0' style='min-height: 100px;'>
                                    <Droppable droppableId="dogsLeft">
                                        {(provided, snapshot) => (
                                            <List provided={provided} snapshot={snapshot} innerRef={provided.innerRef} className='ml-auto'>
                                                {dogsLeft && dogsLeft.map((dog, idx) =>
                                                    <Draggable key={`${dog.id}-${dog.unique}`} draggableId={`dragId-${dog.id}-${dog.unique}`} index={idx}>
                                                        {provided => (
                                                            <Dog provided={provided} snapshot={snapshot} innerRef={provided.innerRef} dog={dog} removePlaceholder={this.removePlaceholder} idx={idx} list='dogsLeft' />
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </List>
                                        )}
                                    </Droppable>
                                </div>
                                <div class='col-6 pl-0' style='min-height: 100px;'>
                                    <Droppable droppableId="dogsRight">
                                        {(provided, snapshot) => (
                                            <List provided={provided} snapshot={snapshot} innerRef={provided.innerRef} className='mr-auto'>
                                                {dogsRight && dogsRight.map((dog, idx) =>
                                                    <Draggable key={`${dog.id}-${dog.unique}`} draggableId={`dragId-${dog.id}-${dog.unique}`} index={idx}>
                                                        {provided => (
                                                            <Dog provided={provided} snapshot={snapshot} innerRef={provided.innerRef} dog={dog} removePlaceholder={this.removePlaceholder} idx={idx} list='dogsRight' />
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </List>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </DragDropContext>
                        <div class='row mb-3 mt-2'>
                            <div class='col-6 pr-0'>
                                <button class='btn btn-sm btn-outline-secondary' onClick={this.addPlaceholderLeft}>
                                    <i class='fas fa-plus-circle' /> <Text id='dog.dogs.addplaceholder'>Add placeholder</Text>
                                </button>
                            </div>
                            <div class='col-6 pl-0'>
                                <button class='float-right btn btn-sm btn-outline-secondary' onClick={this.addPlaceholderRight}>
                                    <i class='fas fa-plus-circle' /> <Text id='dog.dogs.addplaceholder'>Add placeholder</Text>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class='container-fluid px-0'>
                        <div class='row'>
                            <div class='col-6'>
                                <button class={`btn btn-sm btn-${dogPositionUpdated ? 'primary' : 'secondary'}`} onClick={this.updateDogPositions}>
                                    <i class='fas fa-save' /> <Text id='dog.dogs.saveDogPositions'>Save dog positions</Text>
                                </button>
                            </div>
                            <div class='col-6'>
                                <button class={`float-right btn btn-sm btn-warning`} onClick={this.resetDogPositions}>
                                    <i class='fas fa-sync' /> <Text id='dog.dogs.resetDogPositions'>Reset dog positions</Text>
                                </button>
                            </div>
                        </div>
                        <div class='row mt-2'>
                            <div class='col-6'>
                                &nbsp;
                            </div>
                            <div class='col-6'>
                                <button class={`float-right btn btn-sm btn-link`} onClick={this.clearDogPositions}>
                                    <i class='fas fa-eraser' /> <Text id='dog.dogs.clearDogPositions'>Clear dog positions</Text>
                                </button>
                            </div>
                        </div>
                    </div>
                </>}

            </div>
        );
    }
}

export default Dogs;
