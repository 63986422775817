import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import DogSimplePublic from './simplePublic';

@observer
class DogScroller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageIdx: 0,
        };
    }

    scrollImages = (e) => {
        const { dogs } = this.props;

        const { scrollLeft, scrollWidth, clientWidth } = e.target;
        const imageStep = clientWidth;
        const imageIdx = scrollLeft / imageStep;
        // console.log({ e, imageStep, imageIdx, scrollLeft, scrollWidth, clientWidth });

        const nearestInt = Math.round(imageIdx);
        const diff = Math.abs(nearestInt - imageIdx);
        // console.log({ nearestInt, diff });
        if (Number.isInteger(imageIdx) || diff < 0.1) {
            this.setState({
                imageIdx: nearestInt,
            });
        }
    }

    sortByName = (a, b) => {
        const { allDogs = [] } = this.props;
        const dogA = allDogs.find(e => e.id === a);
        const dogB = allDogs.find(e => e.id === b);
        if (dogA && dogB) {
            if (dogA.name > dogB.name) {
                return 1;
            }
        }
        return -1;
    }

    render() {
        const { imageIdx } = this.state;
        const { dogs, allDogs = [], size = 'medium', onDogClick = () => {} } = this.props;

        return (
            <div class='w-100'>
                <div
                    class='d-flex flex-row flex-nowrap no-scrollbar'
                    style='overflow: auto; scroll-snap-type: x mandatory;'
                    onScroll={this.scrollImages}
                >
                    {dogs && dogs.sort(this.sortByName).map((dogId, idx) => {
                        const dog = allDogs.find(e => e.id === dogId);
                        if (!dog) {
                            return '';
                        }
                        return (
                            <div class='col clearfix position-relative p-0 mx-2'>
                                <div
                                    class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-center`}
                                    style={`
                                        flex-wrap: wrap;
                                        overflow-y: hidden;
                                    `}
                                    onTouchstart={(e) => { e.stopPropagation(); }}
                                    onTouchend={(e) => { e.stopPropagation(); }}
                                    onTouchmove={(e) => { e.stopPropagation(); }}
                                >
                                    <DogSimplePublic
                                        stores={this.props.stores}
                                        dog={dog}
                                        dogs={allDogs.filter(d => dogs?.indexOf(d.id) > -1).sort((a, b) => a.name > b.name ? 1 : -1)}
                                        className='mx-auto'
                                        showName={true}
                                        showPosition={false}
                                        size={size}
                                        onDogClick={onDogClick}
                                    />
                                    {/* <xmp>{JSON.stringify(img, null, 4)}</xmp> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default DogScroller;
