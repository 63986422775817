import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import mu from '../../lib/musher-util';

@observer
class AddTags extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    addTag = e => {
        const { value } = e.target.closest('div').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel } = this.props;
        const { callback = () => {} } = this.props;
        callback([value]);
        appState.toggleDrawer(false, drawerLevel);
    }

    render() {
        const { appState } = this.props.stores;
        const { darkmode } = appState;

        return (
            <>
                <div
                    class={`position-absolute text-center w-100 appHeader ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                    style={`
                        top: 24px;
                        left: 0px;
                    `}
                >
                    <Text id='story.tags'>Tags</Text>
                </div>
                <div class={`w-100 container-fluid ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`} style='margin-top: 75px;'>
                    <div class='row'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <div
                                class=' rounded rounded-lg position-relative bg-light d-flex flex-row justify-content-center align-items-center pl-3'
                                style=' border-radius: 10px !important;'
                            >
                                <i class='fa-regular fa-magnifying-glass text-muted' />
                                <Localizer>
                                    <input
                                        class='form-control-plaintext px-2'
                                        type='text'
                                        placeholder={<Text id='story.search-locations'>Search for dogs and people</Text>}
                                        // onInput={linkState(this, 'story.body')}
                                        // value={story.body}
                                    />
                                </Localizer>
                            </div>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-5'>
                            <div class='row border-bottom pb-2'>
                                <div
                                    class='col-12 mt-2'
                                    onClick={this.addTag}
                                    data-value='Fnugg'
                                >
                                    <span class='header3'>Fnugg</span><br />
                                    <small class='text-muted'>Dog</small>
                                </div>
                            </div>
                            <div class='row border-bottom pb-2'>
                                <div
                                    class='col-12 mt-2'
                                    onClick={this.addTag}
                                    data-value='Blitz'
                                >
                                    <span class='header3'>Blitz</span><br />
                                    <small class='text-muted'>Dog</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddTags;
