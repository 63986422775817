import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkState from 'linkstate';
import { Text, Localizer } from 'preact-i18n';

import mu from '../../lib/musher-util';

const VACCINE_TYPES = mu.getVaccineTypes();

import PubSub, { topics } from '../../lib/pubsub';
import Images from '../../components/form/images';
import Dogs from '../../components/dog/dogs';

const initialState = {};

@observer
class DogAddLog extends Component {
	constructor(props) {
        super(props);
        this.state = {
			showLog: false,
			okToAddLog: true,
			newHistory: {
				date: util.isoDate(new Date(), false, false, true),
				time: util.isoTime(new Date()),
                dogs: [],
			},
			...initialState,
        };
		this.inputRef = {};
    }

	async loadDog(props = this.props) {
        const { dog } = props;
        if (dog && dog.id) {
            const { newHistory } = this.state;
            newHistory.dogs = [dog.id];
            this.setState({ newHistory });
        }
    }

    async loadFeed() {
		const { userStore } = this.props.stores;
        await userStore.getFeed({});
    }

	toggleLog = () => {
		const { showLog } = this.state;
		this.setState({
            showLog: !showLog,
        });
	}

	resetLog = () => {
		this.setState({
			newHistory: {
				date: util.isoDate(new Date(), false, false, true),
				time: util.isoTime(new Date()),
                dogs: [],
			},
			showLog: false,
		});
	}

	addImages = (obj) => {
		// updateField({ id: object.id, field, value: file });
		// console.log('addImages', obj.value);
		if (Array.isArray(obj.value)) {
			const { newHistory } = this.state;
			if (Array.isArray(newHistory.images)) {
				newHistory.images = [...newHistory.images, ...obj.value];
			} else {
				newHistory.images = obj.value;
			}
			this.setState({ newHistory });
		}
	}

	handleImageErrored = (e) => {
        const image = e.target;
        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

	imageUploadStatus = (status) => {
		this.setState({ okToAddLog: status });
	}

	insertLog = async () => {
		const { newHistory } = this.state;
		const { dogStore, userStore } = this.props.stores;
		const { callback } = this.props;
		const { dog } = dogStore;
		const { user } = userStore;

		if (!newHistory.title && !newHistory.comment) {
			PubSub.publish(topics.STATUS_MESSAGE, {
				message: 'Mangler tittel på logginnlegg',
				type: 'warning',
				icon: 'fas fa-exclamation-triangle',
			});
			return false;
		}

		if (!newHistory.id && newHistory?.dogs?.length === 0) {
			PubSub.publish(topics.STATUS_MESSAGE, {
				message: 'Mangler hunder til logginnlegg',
				type: 'warning',
				icon: 'fas fa-exclamation-triangle',
			});
			return false;
		}

		newHistory.date = `${newHistory.date} ${newHistory.time}`;
		await dogStore.save({
			id: dog.id || newHistory.dogs[0],
			history: {
				user: {
					id: user.id,
					email: user.email,
					firstname: user.firstname,
					lastname: user.lastname,
				},
				...newHistory,
			},
		});
        this.resetLog();
		await this.loadDog();
        await this.loadFeed();
		if (util.isDefined(callback)) {
			callback();
		}
	}

    updateField = ({ id, field, value, type, elementtype, isNew }) => {
        const { newHistory } = this.state;
        newHistory[field] = value;
        this.setState({ newHistory });
    }

	setHistoryType = (e) => {
		const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'en' } = user;
        const HISTORY_TYPE_MAP = mu.getHistoryMap(language);

		const { newHistory } = this.state;
		const value = e.target.value;
		newHistory.historyType = value;
		if (!newHistory.title) {
			newHistory.title = HISTORY_TYPE_MAP[value];
		}
		if (newHistory.historyType === 'maturity') {
			newHistory.endDate = util.isoDate(util.addDays(newHistory.date, 7 * 3), false, false, true);
		}
		if (newHistory.historyType === 'mating' || newHistory.historyType === 'unwantedMating') {
			newHistory.endDate = util.isoDate(util.addDays(newHistory.date, 63), false, false, true);
		}
		this.setState({ newHistory });
	}

	setVaccineType = (e) => {
		const { newHistory } = this.state;
		const value = e.target.value;
		newHistory.vaccineType = value;
		newHistory.title += ` - ${VACCINE_TYPES[value].name}`;
		newHistory.endDate = util.isoDate(util.addDays(newHistory.date, VACCINE_TYPES[value].duration), false, false, true);
		this.setState({ newHistory });
	}

	setFocus = (e) => {
        this.setState({ hasFocus: true });
        mu.resizeTextarea(e.target);
    }

    componentWillMount() {
        this.resetLog();
        this.loadDog();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.dog.id !== this.props.dog.id) {
            this.resetLog();
            this.loadDog(nextProps);
        }
    }

	render() {
		const { newHistory, showLog, okToAddLog } = this.state;
		const { dog = {}, className = 'btn-sm btn-secondary float-left', showLog: inputShowLog, team } = this.props;
		const { workoutStore, userStore } = this.props.stores;

		const currentTeamObject = userStore.getCurrentTeam();
		const currentTeam = currentTeamObject ? currentTeamObject.id : undefined;

		if (showLog || inputShowLog) {
			return (
				<div class='col-12 px-0 border rounded '>
					{!inputShowLog && <>
						<button
							type='button'
							class={`'btn ${className}`}
							onClick={this.toggleLog}
						>
							<i class='fas fa-plus' /> {showLog ? <Text id='dog.addlog.hidelog'>Skjul logg</Text> : <Text id='dog.addlog.newlog'>Ny logg</Text>}
						</button>
						<br />
					</>}

					{(showLog  || inputShowLog) && <div class='mb-2 py-4 px-2'>
						<h5><Text id='dog.addlog.addlog'>Legg til logg</Text></h5>

						<Localizer>
	                        <Dogs
								stores={this.props.stores}
								object={newHistory}
								type='array'
								elementtype='number'
								field='dogs'
								icon='fas fa-dog'
								title={<Text id='dog.addlog.dogs'>Hunder</Text>}
								help={<Text id='dog.addlog.dogs-help'>Hvilke hunder gjelder dette?</Text>}
								updateField={this.updateField} filter={(e) => dog.id === e.id || newHistory.dogs?.indexOf(e.id) > -1}
								currentTeam={team || currentTeam}
							/>
						</Localizer>

						<div class='row'>
							<div class='form-group col'>
								<label for='dateInput'><i class='fas fa-calendar-alt' /> <Text id='dog.addlog.date'>Dato</Text></label>
								<input type='date' class='form-control' id='dateInput' aria-describedby='dateHelp'
									onInput={linkState(this, 'newHistory.date')}
									value={newHistory.date}
									autoComplete='off'
								/>
								<small id='dateHelp' class='form-text text-muted'><Text id='dog.addlog.date-help'>Dato for logglinje.</Text></small>
							</div>
							<div class='form-group col'>
								<label for='timeInput'><i class='fas fa-clock' /> <Text id='dog.addlog.time'>Klokka</Text></label>
								<input type='time' class='form-control' id='timeInput' aria-describedby='timeHelp'
									onInput={linkState(this, 'newHistory.time')}
									value={newHistory.time}
									autoComplete='off'
								/>
								<small id='timeHelp' class='form-text text-muted'><Text id='dog.addlog.time-help'>Tid for logglinje.</Text></small>
							</div>
						</div>

						<div class='form-group'>
							<label for='typeInput'><i class='fas fa-folder-open' /> <Text id='dog.addlog.type'>Type hendelse</Text></label>
							<select class='form-control' id='typeInput' aria-describedby='typeHelp'
								// onInput={linkState(this, 'newHistory.historyType')}
								onInput={this.setHistoryType}
								value={newHistory.historyType}
								autoComplete='off'
							>
								<option value=''>-- <Text id='dog.addlog.choose-type'>Velg type</Text> --</option>
								<option value='other'><Text id='dog.addlog.other'>Annet</Text></option>
								<option value='stoolProblem'><Text id='dog.addlog.stoolProblem'>Avføringsproblem</Text></option>
								<option value='bodyScore'><Text id='dog.addlog.bodyScore'>Bodyscore</Text></option>
								<option value='diarrhea'><Text id='dog.addlog.diarrhea'>Diarè</Text></option>
								<option value='foodLeftover'><Text id='dog.addlog.foodLeftover'>Ikke spist opp maten</Text></option>
								<option value='animalchiropractor'><Text id='dog.addlog.animalchiropractor'>Dyrekiropraktor</Text></option>
								<option value='clawClip'><Text id='dog.addlog.clawClip'>Kloklipp</Text></option>
								<option value='maturity'><Text id='dog.addlog.maturity'>Løpetid</Text></option>
								<option value='massage'><Text id='dog.addlog.massage'>Massasje</Text></option>
								<option value='newStraw'><Text id='dog.addlog.newStraw'>Ny halm/treull</Text></option>
								<option value='update'><Text id='dog.addlog.update'>Oppdatering</Text></option>
								<option value='deworming'><Text id='dog.addlog.deworming'>Ormekur</Text></option>
								<option value='mating'><Text id='dog.addlog.mating'>Parring</Text></option>
								<option value='injury'><Text id='dog.addlog.injury'>Skade</Text></option>
								<option value='fight'><Text id='dog.addlog.fight'>Slåsskamp</Text></option>
								<option value='sick'><Text id='dog.addlog.sick'>Syk</Text></option>
								<option value='unwantedMating'><Text id='dog.addlog.unwantedMating'>Tjuvparring</Text></option>
								<option value='vaccine'><Text id='dog.addlog.vaccine'>Vaksine</Text></option>
								<option value='weight'><Text id='dog.addlog.weight'>Veiing</Text></option>
								<option value='veterinarianVisit'><Text id='dog.addlog.veterinarianVisit'>Vetrinær besøk</Text> </option>
							</select>
							<small id='nameHelp' class='form-text text-muted'><Text id='dog.addlog.type-help'>Type hendelse. Vetrinær besøk, vaksinering, syk, løpetid, etc.</Text></small>
						</div>
						{(['vaccine'].indexOf(newHistory.historyType) > -1) && <div class='form-group'>
							<label for='vaccineTypeInput'><i class='fas fa-hourglass-end' /> <Text id='dog.addlog.vaccine-type'>Vaksinetype</Text></label>
							<select class='form-control' id='vaccineTypeInput' aria-describedby='vaccineTypeHelp'
								// onInput={linkState(this, 'newHistory.historyType')}
								onInput={this.setVaccineType}
								value={newHistory.vaccineType}
								autoComplete='off'
							>
								<option value=''>-- <Text id='dog.addlog.choose-vaccine'>Velg vaksinetype</Text> --</option>
								<option value='rabies'><Text id='dog.addlog.rabies'>Rabies (3 år)</Text></option>
								<option value='deworming'><Text id='dog.addlog.deworming'>Ormekur</Text></option>
								<option value='BbPi'><Text id='dog.addlog.BbPi'>BbPi - Nesevaksine (1 år)</Text></option>
								<option value='DHP'><Text id='dog.addlog.DHP'>DHP (3 år)</Text></option>
								<option value='DHPPi'><Text id='dog.addlog.DHPPi'>DHPPi (3 år)</Text></option>
							</select>
							<small id='vaccineTypeHelp' class='form-text text-muted'><Text id='dog.addlog.vaccine-help'>Hvilken vaksinetype er dette?</Text></small>
						</div>}

						<div class='form-group'>
							<label for='nameInput'><Text id='dog.addlog.title'>Tittel</Text></label>
							<input type='text' class='form-control' id='nameInput' aria-describedby='nameHelp'
								onInput={linkState(this, 'newHistory.title')}
								value={newHistory.title}
								autoComplete='off'
								required={true}
							/>
							<small id='nameHelp' class='form-text text-muted'><Text id='dog.addlog.title-help'>Tittel på logglinje. Vetrinær besøk, Vaksinering, Syk, Profilbilde, etc.</Text></small>
						</div>

						{(['vaccine', 'maturity', 'unwantedMating', 'mating'].indexOf(newHistory.historyType) > -1) && <div class='form-group'>
							<label for='endDateInput'><i class='fas fa-hourglass-end' /> <Text id='dog.addlog.duration'>Varighet</Text></label>
							<input type='date' class='form-control' id='endDateInput' aria-describedby='endDateHelp'
								onInput={linkState(this, 'newHistory.endDate')}
								value={newHistory.endDate}
								autoComplete='off'
							/>
							<small id='endDateHelp' class='form-text text-muted'><Text id='dog.addlog.duration-help'>Når går denne vaksinen ut?</Text></small>
						</div>}

						{(newHistory.historyType === 'weight' || newHistory.historyType === 'bodyScore') && <div class='form-group'>
							<label for='weightInput'><i class='fas fa-balance-scale' /> <Text id='dog.addlog.weight-of-dog'>Vekt</Text></label>
							<input type='text' class='form-control' id='weightInput' aria-describedby='weightHelp'
								onInput={linkState(this, 'newHistory.weight')}
								value={newHistory.weight}
								autoComplete='off'
							/>
							<small id='weightHelp' class='form-text text-muted'><Text id='dog.addlog.weight-of-dog-help'>Vekten til hunden.</Text></small>
						</div>}
						{(newHistory.historyType === 'weight' || newHistory.historyType === 'bodyScore') && <div class='form-group'>
							<label for='bodyScoreInput'><i class='fas fa-balance-scale' /> <Text id='dog.addlog.bodyscore'>Bodyscore</Text></label>
							<input type='text' class='form-control' id='bodyScoreInput' aria-describedby='bodyScoreHelp'
								onInput={linkState(this, 'newHistory.bodyScore')}
								value={newHistory.bodyScore}
								autoComplete='off'
							/>
							<small id='bodyScoreHelp' class='form-text text-muted'><Text id='dog.addlog.bodyscore-help'>Bodyscore til hunden.</Text></small>
						</div>}

						{(newHistory.historyType === 'veterinarianVisit' || newHistory.historyType === 'vaccine') && <div class='form-group'>
							<label for='costInput'><i class='fas fa-money-bill-alt' /> <Text id='dog.addlog.cost'>Kostnad</Text></label>
							<input type='text' class='form-control' id='costInput' aria-describedby='costHelp'
								onInput={linkState(this, 'newHistory.cost')}
								value={newHistory.cost}
								autoComplete='off'
								pattern='[0-9]{3}' title='Kun tall. Ikke noen andre tegn eller symboler.'
							/>
							<small id='costHelp' class='form-text text-muted'><Text id='dog.addlog.cost-help'>Kostnad for denne hendelsen.</Text></small>
						</div>}


						<div class='form-group'>
							<label for='commentInput'><i class='fas fa-quote-right' /> <Text id='dog.addlog.commet'>Kommentar</Text></label>
							<textarea
								type='text'
								class='form-control'
								id='commentInput'
								aria-describedby='commentHelp'
								onInput={linkState(this, 'newHistory.comment')}
								value={newHistory.comment}
								autoComplete='off'

								onKeyUp={mu.resizeTextarea}
								onFocus={this.setFocus}
								style='height: 3.3em;'
								ref={c => this.inputRef['newComment'] = c}
							/>
							<small id='commentHelp' class='form-text text-muted'><Text id='dog.addlog.comment-help'>Kommentar til loggen. Skriv mer om hva som hendte.</Text></small>
						</div>

						<div class='container-fluid'>
							<div class='row'>
								<div class='row-12'>
									<label for='imageInput'><i class='fas fa-camera' /> <Text id='dog.addlog.images'>Bilder</Text></label>
								</div>
							</div>
							<div class='row'>
								{newHistory.images && newHistory.images.map(img => (
									<div class='col-3 p-1'>
										<img src={img.s3SmallLink} class='img-fluid' onError={this.handleImageErrored} />
									</div>
								))}
							</div>
						</div>
						<Images object={newHistory} field='images' updateField={this.addImages} uploadStatus={this.imageUploadStatus} autoOpen={false} stores={this.props.stores} />

						<button type='button' class='btn btn-success'
							onClick={this.insertLog}
							disabled={!okToAddLog}
						>
							{okToAddLog ? <Text id='dog.addlog.addlog'>Legg til logg</Text> : <Text id='dog.addlog.please-wait'>vent litt...</Text>}
						</button>
						<button type='button' class='btn btn-danger float-right'
							onClick={this.resetLog}
						>
							<Text id='dog.addlog.cancel'>Glem det</Text>
						</button>
					</div>}

				</div>
			);
		}

		return (
			<button
				type='button'
				class={`'btn ${className}`}
				onClick={this.toggleLog}
			>
				<i class='fas fa-plus' /> {showLog ? <Text id='dog.addlog.hidelog'>Skjul logg</Text> : <Text id='dog.addlog.newlog'>Ny logg</Text>}
			</button>
		);
	}
}

export default DogAddLog;
