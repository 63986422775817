import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Widget from '../../../components/widget';
import TeamSimpleV2 from '../../../components/team/simple-v2';

import mu from '../../../lib/musher-util';

@observer
class ProfileTeam extends Component {
    onClick = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const el = e.target.closest('.teamDetail');
        const id = el?.dataset?.id;
        const { appState, userStore } = this.props.stores;
        const { user } = userStore;
        const { drawerHeightLarge } = appState;
        if (id) {
            appState.openDrawer('widgetProfileTeamDetail', {
                height: drawerHeightLarge,
                callback: this.toggleDrawer,
                borderRadius: '',
                showCloseButton: false,
                showBackButton: true,
                backButtonText: 'Profile',
                id,
            });
        } else {
            appState.openDrawer('widgetProfileTeamList', {
                height: drawerHeightLarge,
                callback: this.toggleDrawer,
                borderRadius: '',
                showCloseButton: false,
                showBackButton: true,
                backButtonText: 'Profile',
            });
        }
    }

    render() {
        const { imageElementScale } = this.state;
        const { userStore, teamStore, appState, workoutStore } = this.props.stores;
        const { darkmode } = appState;
        const currentTeam = userStore.getCurrentTeam();
        const { user, emailMessage, emailError, emailMessageIcon, emailErrorIcon, isAdmin } = userStore;

        const teamImage = mu.getImage({ team: currentTeam });
        const userImage = mu.getImage({ user });
        const headerImage = mu.getImage({ team: currentTeam, field: 'imageHeader', size: 's3XXLargeLink', fallback: './assets/homerun-race-logo.png' });
        const { teams = [] } = userStore;

        if (teams.length <= 1) {
            return (<></>);
        }

        let teamColWidth = 12;
        if (teams.length >= 4) {
            teamColWidth = 8;
        } else if (teams.length === 3) {
            teamColWidth = 8;
        } else if (teams.length === 2) {
            teamColWidth = 10;
        }

        // totalWorkouts
        // totalFollowers
        const sortedTeams = teams.sort(mu.fieldSorter(['-totalFollowers', '-totalWorkouts', 'name']));

        return (<Widget stores={this.props.stores} {...this.props} classNamesPadding={'p-0'} headerRight={'View all'} onHeaderClick={this.onClick} onClick={this.onClick}>
             <div class='w-100 position-relative px-3 pb-3 pt-2'>
                <div
                    class='d-flex flex-row flex-nowrap no-scrollbar'
                    style={`
                        overflow-x: ${imageElementScale > 1 && teams.length === 1 ? 'visible !important' : 'auto'};
                        overflow-y: ${imageElementScale > 1 ? 'visible !important' : 'auto'};
                        scroll-snap-type: x mandatory;
                    `}
                    onScroll={this.scrollImages}
                    ref={c => this.imageScrollerRef = c}
                >
                    {sortedTeams && sortedTeams.map(team => <>
                        <div
                            class={`col-${teamColWidth} clearfix p-0 mx-2 teamDetail`}
                            key={`team-id-${team.id}`}
                            data-id={team.id}
                        >
                            <div
                                class={`w-100 h-100 text-center rounded-lg imageContainer d-flex justify-content-center align-items-start position-relative px-0`}
                                style={`
                                    scroll-snap-align: start;
                                    flex-wrap: wrap;
                                `}
                                // overflow: ${imageElementScale > 1 ? 'visible !important' : 'hidden'};
                                onTouchstart={(e) => { e.stopPropagation(); }}
                                onTouchend={(e) => { e.stopPropagation(); }}
                                onTouchmove={(e) => { e.stopPropagation(); }}
                            >
                                <TeamSimpleV2
                                    key={`team-id-${team.id}`}
                                    stores={this.props.stores}
                                    team={team}
                                    teams={teams}
                                    showAge={true}
                                    className={'w-100'}
                                    onTeamClick={this.onTeamClick}
                                />
                            </div>
                        </div>
                    </>)}
                </div>
            </div>

        </Widget>);
    }
}

export default ProfileTeam;
