import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route, Router } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import PubSub, { topics } from '../../lib/pubsub';
import mu from '../../lib/musher-util';
const MARGIN_TOP = mu.marginTop(false);
const MARGIN_TOP_BACK = mu.marginTopBack(false);
const MARGIN_BOTTOM = mu.marginBottom();

import DogSimplePublic from '../../components/dog/simplePublic';
import ImageScroller from '../../components/imageScroller';
// import TeamConnectConfirm from '../../components/team/connect-confirm';
import TeamLine from '../../components/team/publicLine';
import PublicTeam from '../../components/team/public';
import PublicImages from '../../components/team/publicImages';

import Overlay from '../../components/overlay/';
import DogPublicDetail from '../dogs/publicDetail';

import MiniViewGraph from '../../components/dog/miniViewGraph';

import ScrollIntoView from '../../components/util/scrollIntoView';

const initialState = {
    newTeam: {},
    submenu: 'main',
    limit: 15,
    offset: 0,
};

@observer
class TeamsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.updateTimer = {};
        this.time = new Date().getTime();
        this.apiServer = util.getApiServer();
    }

    async loadPublicView(props) {
		this.setState({ isLoading: true });
        const { teamid } = props;
		const { teamStore } = this.props.stores;
        await teamStore.getPublicViews(teamid);
		this.setState({ isLoading: false });
	}

    async loadAll(props = this.props) {
        const { limit, offset } = this.state;
        await this.loadPublicTeam({ limit, offset, props });
        await this.loadPublicView(props);
    }

    async loadPublicTeam({ limit, offset, props = this.props }) {
		const { teamStore } = props.stores;
        const { teamid } = props;
        await teamStore.loadPublicDetail({ id: teamid, imagesLimit: limit, imagesOffset: offset });
    }

    async loadMoreImages({ limit, offset, props = this.props }) {
		const { teamStore } = props.stores;
        const { teamid } = props;
        await teamStore.loadMoreImages({ id: teamid, imagesLimit: limit, imagesOffset: offset, skipAll: true });
    }

    // Overlay functions
    onDogClick = (e, dogId) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel } = this.props;
        const { appState, teamStore } = this.props.stores;
        const { publicTeam } = teamStore;
        // const { dogs } = publicTeam;
        const { drawerHeightMedium } = appState;
        if (drawerLevel === 3) {
            appState.openDrawer3('publicDog', { dogId, height: drawerHeightMedium });
        } else {
            appState.openDrawer2('publicDog', { dogId, height: drawerHeightMedium });
        }
	}

    onUserClick = (e, userid) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    // /Overlay functions

    subscribeNotificationTopic = async (field) => {
		const { userStore } = this.props.stores;
        const { user } = userStore;
        const { notificationTopics = {} } = user;
        const value = 1;
        await userStore.setNotificationTopic({
            field: `notificationTopics.${field}`,
            value,
        });
    }

    chooseSubmenu = (e) => {
		const submenu = e.target.parentElement.dataset.menu || e.target.dataset.menu;
		this.setState({ submenu });
	}

    toggleDrawer = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer(false);
    }

    editTeamField = (e, props) => {
        e.preventDefault();
        e.stopPropagation();
        const { field, type, title, help, id, imageLimit, height } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        // console.log({ field, type, title, help, id, imageLimit });
        const { drawerHeightSmall } = appState;
        appState.openDrawer2('editTeam', {
            field,
            type,
            title,
            help,
            id: parseInt(id, 10),
            imageLimit: parseInt(imageLimit, 10),
            height: drawerHeightSmall,
        });
	}

    setLoadMore = () => {
        const { teamStore } = this.props.stores;
        teamStore.setLoadMore(() => {
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset });
            this.loadMoreImages({ limit, offset });
        });
    }

    unsetLoadMore = () => {
        const { teamStore } = this.props.stores;
        teamStore.setLoadMore(() => {});
    }

    favoriteTeam = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { value, isfavorite } = e.target.closest('i').dataset;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (isfavorite) {
            await userStore.removeField({ email: user.email, field: 'favoriteTeam', value: parseInt(value, 10) });
        } else {
            await userStore.updateUserArray({ email: user.email, field: 'favoriteTeam', value: parseInt(value, 10) });
        }
    }

    back = () => {
        const { history } = this.props.stores;
        history.goBack();
    }

	componentDidMount() {
        const { back = this.back } = this.props;
        const { appState } = this.props.stores;
        appState.setSwipeRight(() => {
            appState.setSwipeRight();
            back();
        });
        this.setLoadMore();
        this.loadAll();
    }

    componentWillUnmount() {
        this.unsetLoadMore();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if (nextProps.teamid !== this.props.teamid) {
            this.loadAll(nextProps);
        }
    }

	render() {
        const { submenu } = this.state;
		const { teamStore, userStore, appState } = this.props.stores;
        const { darkmode } = appState;
        const { user, isAdmin } = userStore;
        const { language = 'en' } = user;
		const { publicTeam = {}, publicTeamFollowers, publicViewsGraphData, publicViewsGraphLegend, publicImages } = teamStore;
        const isTeamAdmin = (publicTeam && publicTeam.members) ? publicTeam.members.some(m => m.id === user.id) : false;

        const { marginTop, marginBottom, back = this.back, hideTeam = () => {}, showBackButton = true } = this.props;

        const defaultImage = {
            s3XXLargeLink: './assets/homerun-race-logo.png',
        };

        const {
            dogs = [],
            users = [],
            image,
            imageHeader,
            publicWorkouts,
            publicWorkoutDetails,
            publicWorkoutSummary,
            publicTrophys,
            publicMemories,
            publicDogs,
        } = publicTeam;

        const headerImage = imageHeader && imageHeader.s3XXLargeLink ? imageHeader : defaultImage;
        const teamImage = image && image.s3LargeLink ? image : null;
        const teamAmbition = userStore.findTeamAmbition({ ambition: publicTeam?.ambitions, language });
        const teamType = userStore.findTeamType({ type: publicTeam.type, language });
        const isYourTeam = publicTeam.members && publicTeam.members?.findIndex(e => e.id === user.id) > -1;
        const isFollowing = publicTeam && publicTeam.followers && publicTeam.followers?.indexOf(user.id) > -1;
        const hasRequestedFollow = publicTeam.followRequests && publicTeam.followRequests?.findIndex(e => e.id === user.id) > -1;

        const { notificationTopics = {} } = user;
        const topic = `team-${publicTeam.id}`;

		return (<>
			<div
                class={`container-fluid pb-5 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                style={`margin-bottom: ${marginBottom || MARGIN_BOTTOM}; margin-top: ${marginTop || MARGIN_TOP};`}
            >
                {showBackButton && <button
                    type='button'
                    class='btn btn-link pl-0 fixed-top text-primary'
                    style={`top: ${MARGIN_TOP_BACK}; left: 5px; font-size: 35px; width: 45px;`}
                    onClick={back}>
                    <i class='fas fa-arrow-circle-left' />
                </button>}
                <div class='row mb-0'>
                    <div
                        class='w-100 position-relative'
                        style={`
                            height: 25vh;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-color: #a0a0a0;
                            ${headerImage ? `background-image: url("${headerImage.s3XXLargeLink}");` : ''}
                        `}
                    >
                        {isTeamAdmin && <>
                            <button
                                type='button'
                                class='btn btn-sm btn-secondary position-absolute'
                                style={`bottom: 5px; right: 5px; opacity: 0.8; border-radius: 50%;`}
                                onClick={this.editTeamField}
                                data-field='imageHeader'
                                data-type='image'
                                data-title='Header image for the team'
                                data-id={publicTeam.id}
                                data-height='60vh'
                            >
                                <i class='fa-solid fa-camera text-white' />
                            </button>

                            <a class='btn btn-sm btn-primary position-absolute'
                                style={`top: 5px; left: 10px;`}
                                href={`/teams/edit/${publicTeam.id}`}
                                onClick={this.toggleDrawer}
                            >
                                <i class='fas fa-edit' /> <Text id='teams.edit'>Endre</Text>
                            </a>
                        </>}
                    </div>
					<div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                        <div class='row'>
                            <div class='col-3 col-sm-4 col-md-3 col-lg-4 position-relative'>

                                <div
                                    class={`text-center border border-white rounded-circle position-absolute imageRounded imageRoundedResponsive imageRoundedResponsivePosition`}
                                    style={`
                                        left: 20px;
                                        ${teamImage ? `background-image: url("${teamImage.s3LargeLink}");
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 50% 50%;` : ''}
                                    `}
                                >
                                    {!teamImage && <i class='fas fa-users text-muted mt-3' style='font-size: 40px;' />}
                                </div>
                                &nbsp;
                                {isTeamAdmin && <>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-secondary position-absolute'
                                        style={`bottom: 0px; left: 20px; opacity: 0.8; border-radius: 50%;`}
                                        onClick={this.editTeamField}
                                        data-field='image'
                                        data-type='image'
                                        data-title='Profile image for the team'
                                        data-id={publicTeam.id}
                                        data-height='60vh'
                                    >
                                        <i class='fa-solid fa-camera ' />
                                    </button>
                                </>}
                            </div>
                            <div class='col-9 col-sm-8 col-md-9 col-lg-8' style='line-height: 1.2em;'>
                                {!isYourTeam && !isFollowing && !hasRequestedFollow && publicTeam.public > 0 && <div class='float-right'>
                                    <PublicTeam
                                        stores={this.props.stores}
                                        team={publicTeam}
                                        buttonOnly={true}
                                        simple={true}
                                        classNames={'btn btn-link text-primary'}
                                    />
                                </div>}

                                <h5 class='my-0'>{publicTeam.name}</h5>
                                <small class='text-muted'>
                                    {publicTeam.place ? `${publicTeam.place}, ` : ''}{publicTeam.country}
                                </small><br />
                                <div class='d-flex justify-content-between'>
                                    <div>
                                        <small class=''>
                                            {publicTeam.followers ? publicTeam.followers.length : 0} <Text id='teams.followers'>followers</Text>
                                        </small>
                                        <small class=''>
                                            , {publicTeam.dogs ? publicTeam.dogs.length : 0} <Text id='teams.dogs-menu'>dogs</Text>
                                        </small>
                                    </div>
                                    <div class='ml-2'>
                                        <i
                                            class={`fa-${user.favoriteTeam?.indexOf(publicTeam.id) > -1 ? 'solid' : 'regular'} fa-star`}
                                            style='font-size: 1.1em;'
                                            onClick={this.favoriteTeam}
                                            data-value={publicTeam.id}
                                            data-isfavorite={user.favoriteTeam?.indexOf(publicTeam.id) > -1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='row mt-2'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 my-1'>
						<div class='row'>
                            <div class='w-100 overflow-hidden'>
                                <div
                                    class='d-flex flex-row flex-nowrap pb-0 px-1'
                                    style='overflow: auto; scroll-snap-type: x mandatory; line-height: 1.0em; height: 56px;'
                                >
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-left rounded-none ${submenu === 'main' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='main'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fa-solid fa-rss' /> <Text id='teams.main'>Main</Text></button>
                                        {/* {submenu === 'main' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'presentation' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='presentation'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-chalkboard-teacher' /> <Text id='teams.presentation'>Presentation</Text></button>
                                        {/* {submenu === 'presentation' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'pictures' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='pictures'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-images' /> <Text id='teams.pictures'>Pictures</Text></button>
                                        {/* {submenu === 'pictures' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    {!!publicDogs && <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'dogs' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='dogs'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-dog' /> <Text id='teams.dogs-menu'>Dogs</Text></button>
                                        {/* {submenu === 'dogs' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                    <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'workoutplan' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='workoutplan'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-calendar-alt' /> <Text id='workouts.workoutplan'>Treningsplan</Text></button>
                                        {/* {submenu === 'workoutplan' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>
                                    {!!publicTrophys && <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-none ${submenu === 'trophy' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='trophy'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-award' /> <Text id='teams.trophy'>Trophy case</Text></button>
                                        {/* {submenu === 'trophy' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                    {!!publicMemories && <div class='col px-0 pt-0'>
                                        <button
                                            type='button'
                                            class={`btn btn-block rounded-pill-right rounded-none ${submenu === 'achievements' ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`}
                                            style='text-overflow: ellipsis; overflow: hidden;'
                                            data-menu='achievements'
                                            onClick={this.chooseSubmenu}
                                        ><i class='fas fa-clock' /> <Text id='teams.achievements'>Memories</Text></button>
                                        {/* {submenu === 'achievements' && <ScrollIntoView horizontal={'center'} vertical={'end'} />} */}
                                    </div>}
                                </div>
                            </div>
						</div>
					</div>
                </div>


                {/* <TeamConnectConfirm stores={this.props.stores} /> */}

                {submenu === 'main' && <>
                    <PublicImages stores={this.props.stores} publicImages={publicImages} />
                </>}

                {submenu === 'presentation' && <>
                    {!publicTeam.description && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fa-solid fa-align-center' /></div>
                            <h3><Text id='teams.no-description'>No description for this team!</Text></h3>
                        </div>
                    </>}

                    <div class='d-flex justify-content-center mb-2'>
                        {teamType && <span class='badge badge-pill badge-light'>{teamType}</span>}
                        {teamAmbition && <span class='badge badge-pill badge-light ml-2'>{teamAmbition}</span>}
                    </div>

                    <div class='row mt-1'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <Markdown markdown={mu.replaceImages(publicTeam.description, publicTeam.images, language, false)} markedOpts={mu.getMarkdownOptions()} />
                        </div>
                    </div>

                    {publicTeam.sponsors && <div class='row mt-1'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <h5 class='font-weight-lighter text-muted border-bottom'><Text id='teams.sponsors'>Sponsors</Text></h5>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {isYourTeam && !publicTeam.sponsors && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fas fa-pen-nib' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='teams.add-sponsors'>Gå inn på endring av teamet for å legge inn sponsorer.</Text>
                                    </h3>
                                </div>
                            </>}
                            <Markdown
                                markdown={mu.replaceImages(publicTeam.sponsors, publicTeam.imagesSponsor, language, false)}
                                markedOpts={mu.getMarkdownOptions()}
                            />
                        </div>
                    </div>}

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editTeamField}
                            data-field='description'
                            data-type='textarea'
                            data-title='Description'
                            data-id={publicTeam.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-pen text-white' />
                        </button>
                    </>}
               </>}

                {submenu === 'pictures' && <>
                    {(!publicTeam.images || publicTeam.images?.length === 0) && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                            <div class='text-center text-muted'>
                                <div class='display-1'><i class='fa-solid fa-camera' /></div>
                                <h3><Text id='teams.no-images-title'>No images for this team!</Text></h3>
                            </div>
                        </div>
                    </>}

                    {publicTeam.images && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative px-0`}>
                            <ImageScroller stores={this.props.stores} images={publicTeam.images.reverse()} />
                        </div>
                    </div>}

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editTeamField}
                            data-field='images'
                            data-type='image'
                            data-title='Team images'
                            data-id={publicTeam.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-camera text-white' />
                        </button>
                    </>}
                </>}

                {!!publicDogs && submenu === 'dogs' && <>
                    {(!dogs || dogs?.length === 0) && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 px-0'>
                            <div class='text-center text-muted'>
                                <div class='display-1'><i class='fas fa-dog' /></div>
                                <h3><Text id='teams.no-dogs-title'>No dogs for this team!</Text></h3>
                            </div>
                        </div>
                    </>}

                    {publicDogs && <div class='row mt-1'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <div class='row'>
                                {dogs && dogs.map(dog => (
                                    <DogSimplePublic
                                        key={`dog-id-${dog.id}`}
                                        stores={this.props.stores}
                                        dog={dog}
                                        showAge={true}
                                        className={'col'}
                                        onDogClick={this.onDogClick}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>}
                </>}

                {submenu === 'workoutplan' && <>
                    {!publicTeam.descriptionWorkoutplan && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fas fa-calendar-alt' /></div>
                            <h3><Text id='teams.no-workoutplan'>No workoutplan for this team!</Text></h3>
                        </div>
                    </>}

                    {publicTeam.descriptionWorkoutplan && <div class='row mt-1'>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            <h5 class='font-weight-lighter text-muted border-bottom'><Text id='teams.description-workoutplan'>Workoutplan</Text></h5>
                        </div>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                            {isYourTeam && !publicTeam.descriptionWorkoutplan && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fas fa-pen-nib' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='teams.add-description-workoutplan'>Gå inn på endring av teamet for å legge inn en treningsplan.</Text>
                                    </h3>
                                </div>
                            </>}
                            <Markdown
                                markdown={mu.replaceImages(publicTeam.descriptionWorkoutplan, publicTeam.images, language, false)}
                                markedOpts={mu.getMarkdownOptions()}
                            />
                        </div>
                    </div>}

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editTeamField}
                            data-field='descriptionWorkoutplan'
                            data-type='textarea'
                            data-title='Workoutplan description'
                            data-id={publicTeam.id}
                            data-height='60vh'
                        >
                            <i class='fa-solid fa-pen text-white' />
                        </button>
                    </>}
                </>}

                {!!publicTrophys && submenu === 'trophy' && <>
                    {(!publicTeam.trophys || publicTeam.trophys?.length === 0) && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fas fa-award' /></div>
                            <h3><Text id='teams.no-trophys-title'>No trophys for this team!</Text></h3>
                        </div>
                    </>}

                    {publicTeam.trophys && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                            <div class='row'>
                                {publicTeam.trophys && publicTeam.trophys.reverse().map(file => {
                                    const img = mu.getTrophyImage(file.name);
                                    if (!img) {
                                        return '';
                                    }
                                    const textHeader = file.descriptionHeader && file.descriptionHeader[language] ? file.descriptionHeader[language] : '';
                                    const text = file.description && file.description[language] ? file.description[language] : '';
                                    return (
                                        <div class='col-4 mt-3'>
                                            <div class='mb-2'>
                                                <img src={img} class='img-fluid' />
                                            </div>
                                            <strong>{textHeader}</strong>
                                            {/* <small class='font-weight-lighter mr-2'>
                                                {util.isoDate(file.createdDate, false, false, true)}
                                            </small> */}
                                            <small>
                                                <details>
                                                    <summary><Text id='teams.trophy-details'>Details</Text></summary>
                                                    <Markdown markdown={mu.replaceImages(text, publicTeam.images, language)} markedOpts={mu.getMarkdownOptions()} />
                                                </details>
                                            </small>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                </>}

                {!!publicMemories && submenu === 'achievements' && <>
                    {(!publicTeam.achievements || publicTeam.achievements?.length === 0) && <>
                        <div class='text-center text-muted'>
                            <div class='display-1'><i class='fas fa-clock' /></div>
                            <h3><Text id='teams.no-achievements-title'>No memories for this team!</Text></h3>
                        </div>
                    </>}

                    {publicTeam.achievements && <div class='row mt-1'>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                            <div class='row'>
                                {publicTeam.achievements && publicTeam.achievements.map(file => {
                                    return (
                                        <div class='col-12'>
                                            {file.date && <span class='font-weight-lighter mr-2'>{util.isoDate(file.date, false, false, true)}</span>}
                                            <span class='mr-2 font-weight-bold'>{file.name}</span>
                                            <span class='mr-2 font-weight-light'>{file.description}</span>
                                            {file.url && <span class='mr-2'><a href={mu.asUrl(file.url)} target='_blank' rel='noreferrer' native><i class='fas fa-link' /></a></span>}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}

                    {isTeamAdmin && <>
                        <button
                            type='button'
                            class='btn btn-lg btn-secondary position-absolute'
                            style={`bottom: 20px; right: 20px; opacity: 0.8; z-index: 1000; border-radius: 50%;`}
                            onClick={this.editTeamField}
                            data-field='achievements'
                            data-type='text'
                            data-title='Add memories'
                            data-id={publicTeam.id}
                            data-height='60vh'
                        >
                            <i class='fas fa-clock text-white' />
                        </button>
                    </>}
                </>}

                {/* {util.isArray(publicTeamFollowers) && <div class='row mt-5'>
                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <h5 class='font-weight-lighter text-muted border-bottom'><Text id='teams.followers'>Followers</Text></h5>
                    </div>
                    <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                        {util.isArray(publicTeamFollowers) && publicTeamFollowers.map(t =>
                            <TeamLine stores={this.props.stores} team={t} myTeamId={publicTeam.id} showBlockButton={false} callback={this.loadTeam} />
                        )}
                    </div>
                </div>} */}

                <div class='row bg-light py-5 mt-3 px-0'>
                    {publicTeam.imagesSponsor && publicTeam.imagesSponsor.length > 0 && <>
                        <div class={`col-12 col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-0 position-relative`}>
                            <ImageScroller stores={this.props.stores} images={publicTeam.imagesSponsor} />
                        </div>
                    </>}

                    {(!notificationTopics || !notificationTopics[topic]) && <>
                        <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 mt-3'>
                            <button type='button' class='btn btn-outline-success btn-block' onClick={() => this.subscribeNotificationTopic(topic)}>
                                <i class='fas fa-rss' /> <Text id='team.notifications'>Subscribe to team notifications</Text>
                            </button>
                        </div>
                    </>}

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <div class='mx-2'>
                                <h3>
                                    <a class='btn btn-outline-success' onClick={this.toggleDrawer} href={`/stories/team/${publicTeam.uuidv4}`}>
                                        <i class='fas fa-rss' /> <Text id='story.all-stories'>Stories</Text>
                                    </a>
                                </h3>
                            </div>
                            {publicWorkouts && <div class='mx-2'>
                                <h3>
                                    <a class='btn btn-outline-primary' onClick={this.toggleDrawer} href={`/teams/workouts/team/${publicTeam.uuidv4}`}>
                                        <i class='fas fa-running' /> <Text id='story.all-workouts'>All workouts</Text>
                                    </a>
                                </h3>
                            </div>}
                        </div>
                    </div>

                    <div class='col-12 offset-0 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3'>
                        <div class='d-flex justify-content-center'>
                            {isYourTeam && !publicTeam.facebook && <>
                                <div class='text-center text-muted'>
                                    <div class='display-1'>
                                        <i class='fab fa-facebook' />
                                    </div>
                                    <h3 class='font-weight-lighter'>
                                        <Text id='teams.add-sosialmedia'>Gå inn på endring av teamet for å legge inn en sosialemedier.</Text>
                                    </h3>
                                </div>
                            </>}

                            {!!publicTeam.url && <div class='mx-2'>
                                <h3>
                                    <a href={mu.asUrl(publicTeam.url)} target='_blank' rel='noreferrer'>
                                        <i class='fas fa-link' />
                                    </a>
                                </h3>
                            </div>}
                            {!!publicTeam.facebook && <div class='mx-2'>
                                <h3>
                                    <a href={mu.asFacebookLink(publicTeam.facebook)} target='_blank' rel='noreferrer'>
                                        <i class='fab fa-facebook' />
                                    </a>
                                </h3>
                            </div>}
                            {!!publicTeam.instagram && <div class='mx-2'>
                                <h3>
                                    <a href={mu.asInstagramLink(publicTeam.instagram)} target='_blank' rel='noreferrer'>
                                        <i class='fab fa-instagram' />
                                    </a>
                                </h3>
                            </div>}
                            {!!publicTeam.snapchat && <div class='mx-2'>
                                <h3>
                                    <a href={mu.asSnapchatLink(publicTeam.snapchat)} target='_blank' rel='noreferrer'>
                                        <i class='fab fa-snapchat' />
                                    </a>
                                </h3>
                            </div>}
                        </div>
                    </div>

                    {(isAdmin || isTeamAdmin) && <>
                        <h5 class='font-weight-lighter text-muted border-bottom mt-3'><Text id='teams.visits'>Team page visits</Text></h5>
                        <MiniViewGraph stores={this.props.stores}
                            dataLeft={publicViewsGraphData}
                            legendLeft={['team']}
                            key={`minigraph-team-${publicTeam.uuidv4}`}
                        />
                    </>}
                </div>

                {publicTeam.uuidv4 && <img
                    src={`${this.apiServer}/api/teams/${publicTeam.uuidv4}/view?user=${user.id}&time=${this.time}`}
                    style='width: 1; height: 1; opacity: 0.1;'
                />}
			</div>
		</>);
	}
}

export default TeamsDetail;
