import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class ScrollIntoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.container = null;
    }

    componentDidMount() {
        const { horizontal, vertical = 'nearest' } = this.props;
        const props = {
            behavior: 'smooth',
        };
        if (horizontal) {
            props.inline = horizontal;
        }
        if (vertical) {
            props.block = vertical;
        }
		this.container.scrollIntoView(props);
	}

    render() {
        return (
            <div style='height: 1px;' ref={c => this.container = c}></div>
        );
    }
}

export default ScrollIntoView;
