import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/musher-util';

import LineGraph from '../../components/lineGraph';

@withText((props) => ({
    week: <Text id='workout.graph.week' />,
    distance: <Text id='workout.graph.distance' />,
    ascend: <Text id='workout.graph.ascend' />,
    plan: <Text id='workout.graph.plan' />,
}))
@observer
class WorkoutInfoGraph extends Component {
    render() {
        const { showDebug, workout } = this.props;
        const { gpx = {}, gpxInfo } = workout;
        const { gpxInfo: subGpxInfo = {} } = gpx;

        const finalGpxInfo = {
            ...subGpxInfo,
            ...gpxInfo,
        };
        const { gpsSpeeds = [] } = finalGpxInfo;
        const { speeds = gpsSpeeds, elevations = [], temperatures = [], climbs = [], distances = [], ecor = [] } = finalGpxInfo;

        let dataLeft = [];
        let dataLeft2 = [];
        let dataLeft3 = [];
        let dataRight = [];
        let dataRight3 = [];
        const legendLeft = [];
        const legendLeft2 = [];
        const legendLeft3 = [];
        const legendRight = [];
        const legendRight3 = [];
        const unitLeft = [];
        const unitLeft2 = [];
        const unitLeft3 = [];
        const unitRight = [];
        const unitRight3 = [];

        if (elevations.length > 0) {
            const smoothed = mu.smoothArray(elevations, 5);
            const data = smoothed.map((e, idx) => ({
                x: idx,
                y: e,
            }));
            dataLeft.push(data);
            legendLeft.push('Elevation');
            unitLeft.push('m');
        }
        if (speeds.length > 0) {
            const smoothed = mu.smoothArray(speeds, 3);
            const data = smoothed.map((e, idx) => ({
                x: idx,
                y: e * 3.6,
            }));
            dataRight.push(data);
            legendRight.push('Speed');
            unitRight.push('km/t');
        }
        if (temperatures.length > 0) {
            const smoothed = mu.smoothArray(temperatures, 2);
            const data = smoothed.map((e, idx) => ({
                x: idx,
                y: e,
            }));
            dataLeft2.push(data);
            legendLeft2.push('Temp');
            unitLeft2.push('°C');
        }
        if (climbs.length > 0) {
            const smoothed = mu.smoothArray(climbs, 2);
            const data = smoothed.map((e, idx) => ({
                x: idx,
                y: e,
            }));
            dataLeft3.push(data);
            legendLeft3.push('Climblevel');
            unitLeft3.push('%');
        }
        if (ecor.length > 0) {
            const smoothed = mu.smoothArray(ecor, 1);
            const data = smoothed.map((e, idx) => ({
                x: idx,
                y: e * 1000,
            }));
            dataRight3.push(data);
            legendRight3.push('ECOR (kcal/kg/km)');
            unitRight3.push('kcal/kg/km');
        }
        // if (distances.length > 0) {
        //     const smoothed = mu.smoothArray(distances, 30);
        //     const data = smoothed.map((e, idx) => ({
        //         x: idx,
        //         y: e * 1000,
        //     }));
        //     dataRight3.push(data);
        //     legendRight3.push('Distance');
        //     unitRight3.push('km');
        // }

        // const firstData = dataLeft[0] || [];
        const xTicks = [];
        if (workout.distanceKm > 0) {
            const xTicksRange = util.range(0, Math.ceil(finalGpxInfo.calculatedTotalDistance || workout.distanceKm), 1);
            const partSize = Math.ceil(xTicksRange.length / 5);
            const parts = Math.floor(xTicksRange.length / partSize);
            // if (showDebug) {
                // console.log({ xTicksRange, parts, partSize });
            // }
            for (let i = 0, l = xTicksRange.length; i < l; i += partSize) {
                xTicks.push(xTicksRange[i]);
            }
            xTicks.push(xTicksRange[xTicksRange.length - 1]);
        }
        // if (firstData.length) {
        //     const partSize = Math.ceil(firstData.length / 4);
        //     const parts = Math.floor(firstData.length / partSize);
        //     if (showDebug) {
        //         console.log({ firstData, parts, partSize });
        //     }
        //     for (let i = 0, l = firstData.length; i < l; i += partSize) {
        //         xTicks.push(firstData[i].epoch);
        //     }
        //     xTicks.push(firstData[firstData.length - 1].epoch);
        // }
        return (
            <>
                {dataLeft3.length > 0 && <div class='w-100 mt-3 mb-4'>
                    {/* <xmp>graphWorkoutSummaryWeekDistanceCurrent: {JSON.stringify(graphWorkoutSummaryWeekDistanceCurrent, null, 4)}</xmp>
                    <xmp>graphWorkoutSummaryWeekDistancePrev: {JSON.stringify(graphWorkoutSummaryWeekDistancePrev, null, 4)}</xmp> */}
                    <LineGraph
                        stores={this.props.stores}
                        width={1000}
                        height={200}
                        showXTicks={1}
                        xTicksVal={val => `${val}km`}
                        xTicks={xTicks}
                        paddingTop={30}
                        lineFromXzero={true}
                        lineFromXmax={true}
                        // xTicksLink={'/workouts/week/'}

                        dataLeft={dataLeft3}
                        legendLeft={legendLeft3}
                        dataFillLeft={[1]}
                        // yMaxLeft={20}
                        // yMinLeft={-20}
                        yMaxMinLeftRound={false}
                        showYTicksLeft={1}
                        yTicksLeftPostfix={' %'}
                        unitLeft={unitLeft3}
                        avgWindowLeft={[0]}
                        smoothLeft={[1]}
                        // marker={week}

                        dataRight={dataRight3}
                        legendRight={legendRight3}
                        // dataRight={[graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev]}
                        // legendRight={[`${seasonStart} ${ascend}`, `${seasonStart - 1} ${ascend}`]}
                        showYTicksRight={dataRight3.length > 0 ? 1 : 0}
                        yTicksRightVal={v => util.format(v / 1000, 2)}
                        // yTicksRightPostfix={'kcal/kg/km'}
                        unitRight={unitRight3}
                        avgWindowRight={[0, 0, 0]}
                        smoothRight={[1, 1, 1]}
                    />
                </div>}

            </>
        );
    }
}

export default WorkoutInfoGraph;
